import { successAlert } from 'pages/components/SweetAlert';
import { warningAlert } from 'pages/components/SweetAlert';
import { confirmAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react'
import { Button, Table, UncontrolledTooltip } from 'reactstrap';
import { deletePermiso } from 'services/PermisosService';
import ModalAgregarEditarP from './ModalAgregarEditarP';


const Permisos=(props)=> {
    const {permisos, traerGP_C_P} = props;
    const [Permiso, setPermiso] = useState(null);
    const [ModalEditP, setModalEditP] = useState(false);
    const [alertSweet, setAlert] = useState([]);

    const editarPermiso = (p)=>{
        setPermiso(p);
        setModalEditP(true); 
      }

    const confirmarEliminar= async(id)=>{
        confirmAlert(setAlert, eliminarPermiso, id , "Deseas eliminar este permiso?")
    }
    
    const eliminarPermiso = async(id)=>{
        let result = await deletePermiso(id)
        if(result != null){
                successAlert(setAlert, "Permiso Eliminado!", "")
                traerGP_C_P()
                return
        }else{
            warningAlert(setAlert,'No se pudo eliminar el Permiso')
        }
    };

    useEffect(() => {
        if(!ModalEditP){setPermiso(null)}
    })

  return (
      <>
      {alertSweet}
        <Table className="align-items-center" responsive>
            <thead className="thead-light text-center mt-5">
            <tr>
                <th scope="col">NOMBRE</th>
                <th scope="col">DESCRIPCION</th>
                <th scope="col">EDITAR</th>
                <th scope="col">ELIMINAR</th>
            </tr>
            </thead>
            <tbody>
            {permisos?
            permisos.map((p, index)=>{
                return (
                <tr key={index}>
                    <td>
                        <div className="text-center">
                            <span className="mb-0 text-sm">
                                <b>{p.nombre}</b>
                            </span>
                        </div>
                    </td>
                    <td>
                        <div className="text-center">
                            <span className="mb-0 text-sm">
                                {p.slug}
                            </span>
                        </div>
                    </td>
                    <td>
                        <div className="d-flex align-items-center text-center ">
                            <div className=" text-center">
                                <Button className="btn-icon btn-3 btn-md btn-outline-success "  type="button" onClick={()=>{editarPermiso(p)}} >
                                
                                    <span className="btn-inner--icon">    
                                        <i className="fas fa-edit"></i>
                                    </span>
                                    
                                </Button>
                            </div>
                        </div>
                    </td>
                    <td>
                    <div className=" text-center">
                        <Button id="tooltip2" className="btn-icon btn-3 btn-sm btn-outline-danger rounded-circle"  type="button" onClick={()=>{confirmarEliminar(p.id)}}>
                                <span className="btn-inner--icon">
                                <i className="ni ni-fat-remove"/>
                                </span>    
                        </Button>
                        <UncontrolledTooltip
                        delay={0}
                        placement="bottom"
                        target="tooltip2"
                        >
                        Eliminar Permiso
                        </UncontrolledTooltip>
                        </div>
                    </td>
                        
                </tr>

                )
            })
            :
            <p>Cargando...</p>
            }
            </tbody>
        </Table>      
        {Permiso!=null?
        <ModalAgregarEditarP ModalAddP={ModalEditP} setModalAddP={setModalEditP} traerGP_C_P={traerGP_C_P} per={Permiso}/>
        :
        <></>
        }
        
      </>
  )
}

export default Permisos
