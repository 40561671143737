import React, { useEffect, useState } from 'react'
import { Input } from 'reactstrap'
import { getEstadosRegistros } from 'services/EstadosService';

const SelectorEstadoHistorico = props => {
    const {idEstado, cambiarIdEstado}=props;
    const [Estados, setEstados] = useState([])

    const cambiarValor =(e) => {
        cambiarIdEstado(e.target.value)
    }
    const traerEstados = async() => {
        let estados = await getEstadosRegistros();
        if (estados !== null) {
            setEstados(estados)
        }
    }
    useEffect(() => {
        traerEstados()
    }, [])
    
  return (
    <>
    <Input type='select' onChange={cambiarValor} value={idEstado}>
        <option value={''}>Estado</option>
        {Estados?
        Estados.map((c, index)=>{
            return(
                <option value={c.id} key={index}>{c.nombre}</option>
            )
        })
    :
    <option value={''}>Cargando...</option>}

    </Input>
    </>
  )
}

export default SelectorEstadoHistorico