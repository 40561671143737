import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Modal,
  Table
} from "reactstrap";
import SelectorCategorias from "./SelectorCategoria";



function CategoriaXCantidad(props) {
  const {
    accionContinuar, // obligatorio
    categoriasGuardadas,setCategoriasGuardadas, // Van juntos, op 1
    arrayCatsGuardadas, setArrayCatsGuardadas,  // Van juntos, op 2 
    tipoValue //obligatorio para que ande el selector
  } = props;
  const [Categoria, setCategoria] = useState("");
  const [Cantidad, setCantidad] = useState(0);
  const [defaultModal, setdefaultModal] = useState(false);

  const cambiarCantidad = event => {
    setCantidad(event.target.value);
  };

  const guardarCategoria = () => {
    //guardad categoria en string
    let cats;
    if(categoriasGuardadas){
      let nuevaCat = `${Categoria}` + ':' + `${Cantidad}`;
      if (categoriasGuardadas != "") {
        cats = `${categoriasGuardadas}` + ',' + nuevaCat
      } else {
        cats = nuevaCat
      }
      setCategoriasGuardadas(cats)
    }

    //guardar categorias en array
    if(arrayCatsGuardadas){
      setArrayCatsGuardadas(catsAnt => {
        let categoria = Categoria;
        let cantidad = Cantidad;
        let arrayCats = {
          categoria,
          cantidad
        }
        let aux = [];
        aux.push(...catsAnt);
        aux.push(arrayCats);
        setArrayCatsGuardadas(aux)
      })
    }

    setCategoria("");
    setCantidad(0);

  }

  const continuar = () => {
    accionContinuar()
    
    }

    return (
      <>
              <Card className="card-pricing border-0 text-center mb-4">
                <CardBody className="px-lg-7">
                  <h4 className=" ls-1 text-primary ">
                    Seleccione Categoria y Cantidad
                  </h4>
                  <SelectorCategorias Categoria={Categoria} setCategoria={setCategoria} tipoValue={tipoValue}/>    
                  <FormGroup >
                      <Input
                        value={Cantidad}
                        onChange={cambiarCantidad}
                        id="example-number-input"
                        type="number"
                      />
                  </FormGroup>
                  
                </CardBody>
                <CardFooter className="footerO bg-transparent">
                  <Button className="mb-3" color="primary" type="button" onClick={()=> setdefaultModal(true)}>
                    Ver Guardadas
                  </Button>
                </CardFooter>
                <CardFooter className="footerO bg-transparent">
                  <Button className="mb-3" color="primary" type="button" onClick={()=> {guardarCategoria()}}>
                    Guardar Categoria
                  </Button>
                  <Button className="mb-3" color="primary" type="button" onClick={()=> continuar()}>
                    Continuar
                  </Button>
                </CardFooter>
              </Card>

              <Modal
                className="modal-dialog-centered"
                isOpen={defaultModal}
                toggle={() => setdefaultModal(false)}
              >
                <div className="modal-header">
                  {arrayCatsGuardadas.length == 0?
                  <h6 className="modal-title" id="modal-title-default">
                    No hay categorias
                  </h6>
                  :
                  <h6 className="modal-title" id="modal-title-default">
                    Categorias Guardadas:
                  </h6>
                  }
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setdefaultModal(false)}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  {arrayCatsGuardadas.length == 0?
                  <></>
                  :
                  <Table className="align-items-center table-flush" hover responsive>
                    <thead className="thead-light">
                      <tr>
                        <th>Controlado</th>
                        <th>Categoria</th>
                        <th>Cantidad</th>
                      </tr>
                    </thead>
                    <tbody>
                      {arrayCatsGuardadas.map((cat, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <label className="custom-toggle">
                                <input type="checkbox" />
                                <span
                                  className="custom-toggle-slider rounded-circle"
                                  data-label-off="No"
                                  data-label-on="Si"
                                />
                              </label>
                            </td>
                            <td className="table-user">
                              <b>{cat.categoria}</b>
                            </td>
                            <td className="table-user">
                              <b>{cat.cantidad}</b>
                            </td>
                          </tr>
                        )
                      })}
                      
                    </tbody>
                  </Table>
                  }
                </div>
                <div className="modal-footer">
                  <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setdefaultModal(false)}
                  >
                    Cerrar
                  </Button>
                </div>
              </Modal>
      </>
    );
  }
  
  export default CategoriaXCantidad;