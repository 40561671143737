import React, { useState } from 'react'
import { Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import TableUsuarios from './components/TableUsuarios'
const Usuarios=()=> {
    const [ModalAgregarUsuario, setModalAgregarUsuario]= useState(false);
  return (
      <>
    <Card style={{width:"100%"}}>
        <CardTitle >
            <Row>
                <Col>
            <h1 className='mt-3 ml-3'>Usuarios</h1>
                </Col>
                <Col>
                <Button className="btn-lg btn-outline-info float-right mr-4 mt-4" color="primary" type="button" onClick={()=>setModalAgregarUsuario(true)}>
                              <span className="btn-inner--icon">
                              Agregar Usuario <i className="fas fa-user-plus"></i>
                              </span>
                    </Button>
                </Col>
            </Row>
        </CardTitle>
        <CardBody>
            <TableUsuarios
            ModalAgregarUsuario={ModalAgregarUsuario}
            setModalAgregarUsuario={setModalAgregarUsuario}
            />
        </CardBody>
    </Card>
      </>
  )
}

export default Usuarios
