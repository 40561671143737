import { urlGlobal2 } from './GlobalService.js';

export const getFacturaciones = async (params = null) => {
    let url = new URL(`${urlGlobal2}/facturacion/facturacionAutomatica`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try {
        let token = localStorage.getItem('token');
        let headers = { 'Content-Type': 'application/json', token }
        let method = 'GET';
        let resp = await fetch(url, { method, headers });
        let data = await resp.json();
        return data;
    }
    catch (error) {
        alert(error.message);
        return null;
    }
}
export async function postFacturacion(Fact) {
    let url = `${urlGlobal2}/facturacion/facturacionAutomatica`;
    try {
        let token = localStorage.getItem('token');
        let headers = { 'Content-Type': 'application/json', 'token': token }
        let method = 'POST';
        let body = JSON.stringify(Fact)
        const resp = await fetch(url, { headers, body, method });
        const data = await resp.json()
        return data;
    } catch (error) {
        return null;
    }
}

export async function postFacturacionManual(Fact) {
    let url = `${urlGlobal2}/facturacion/facturaManual`;
    try {
        let token = localStorage.getItem('token');
        let headers = { 'Content-Type': 'application/json', 'token': token }
        let method = 'POST';
        let body = JSON.stringify(Fact)
        const resp = await fetch(url, { headers, body, method });
        const data = await resp.json()
        return data;
    } catch (error) {
        return null;
    }
}

export async function putFacturacion(id, Fact) {
    let url = `${urlGlobal2}/facturacion/facturacionAutomatica/${id}`;
    try {
        let token = localStorage.getItem('token');
        let headers = { 'Content-Type': 'application/json', 'token': token }
        let method = 'PUT';
        let body = JSON.stringify(Fact)
        const resp = await fetch(url, { headers, body, method });
        const data = await resp.json()
        return data;
    } catch (error) {
        return null;
    }
}

export async function deleteFacturacion(id) {
    let url = `${urlGlobal2}/facturacion/facturacionAutomatica/${id}`;
    try {
        let token = localStorage.getItem('token');
        let headers = { 'Content-Type': 'application/json', 'token': token }
        let method = 'DELETE';
        const resp = await fetch(url, { headers, method });
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
}