import React, { useEffect, useState } from 'react';
import {
    Button,
    InputGroupAddon,
    InputGroup,
    Container,
    NavbarBrand,
    Navbar,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
  } from "reactstrap";
import { useHistory } from "react-router";
import { getRol } from 'services/RolesService';
import TablePermisosRol from '../components/TablePermisosRol';
import LoadingComponent from 'pages/components/Loading';
import { putAsignarPermisosRol } from 'services/RolesService';
import { successAlert } from 'pages/components/SweetAlert';
import { warningAlert } from 'pages/components/SweetAlert';
    
  const PermisosRol = props => {
    const history = useHistory ();
    const [Visible, setVisible] = useState(false);
    const [alertSweet, setAlert] = useState(null); 
    const [idRol, setId] = useState(null);
    const [Rol, setRol] = useState(null); 
    const [IdsP, setIdsP] = useState(null); 

    
    
    const Atras=() =>{
        history.replace(`/admin/dashboard/roles`)
    }

    const Guardar=async()=>{
      
      let rol = {
        roleId:parseInt(idRol),
        permisosIds:IdsP
      }
    let result = await putAsignarPermisosRol(rol)
    if(result != null){
      successAlert(setAlert, "Permisos Rol agregados correctamente!", "")
      traerRol()
  }else{
    warningAlert(setAlert, 'No se pudo agregar Permisos al Rol')
  }
  }

    const traerRol = async() => {
      setVisible(true)
      const rolId=props.match.params.idRol;
      setId(rolId);
      let rl = await getRol(null, rolId);
      if (rl != null && rl != '') {
        let idsP = []
        rl.permisos.map(p=>{
          idsP.push(p.id)
        })
        setIdsP(idsP)
        setRol(rl);
      }
      setVisible(false)
   }



    useEffect(() => {
      traerRol();
    }, [])
      
        
      
    return (
        <>
          {alertSweet}
          <LoadingComponent Visible={Visible}/>
          <Card style={{width:"100%"}}>
              <Navbar
                className="navbar-horizontal navbar-light bg-secondary"
                expand="lg"
              >
                <Container >
                  <NavbarBrand >
                    <InputGroup>
                      <InputGroupAddon addonType="append">
                        <Button className="ml-3" color="success" outline type="button" onClick={()=>Atras()}>
                          Atras
                        </Button>
                        <Button className="ml-3" color="info" outline type="button" onClick={()=>Guardar()}>
                          Guardar
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </NavbarBrand>
                </Container>
              </Navbar>
                {Rol?
                <CardBody>
                  <TablePermisosRol Rol={Rol} PermisosSeleccionados={IdsP} setPermisosSeleccionados={setIdsP}/>
                </CardBody>
                :
                <></>
                }
            </Card>

          
        </>
    )
}
export default PermisosRol;

