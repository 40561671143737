import SelectorEmpleados from 'pages/components/SelectorEmpleados';
import SelectorGenerico from 'pages/components/SelectorGenerico';
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react';
import {Button, Card, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap';
import { verificarToken } from 'services/AuthService';
import { getEmpleados } from 'services/EmpleadosService';
import { getPuntosVentas } from 'services/PuntosVentaService';
import { getRoles } from 'services/RolesService';
import { postUsuario } from 'services/UsuariosService';


const ModalAggUsuario = props => {
  
    const {ModalAgregarUsuario, setModalAgregarUsuario, traerUsuarios}=props;
    const [Empleado, setEmpleado] = useState("");
    const [PuntosVenta, setPuntosVenta] = useState([]);
    const [Roles, setRoles] = useState([]);
    const [Rol, setRol]= useState("");
    const [UNMS, setUNMS]= useState("");
    const [NombreUsuario, setNombreUsuario]= useState("");
    const [Contraseña, setContraseña]= useState("");
    const [Punto, setPunto]= useState("");
    const [ChatId, setChatId]= useState("");
    const [Alert, setAlert]= useState("");

    const cambiarChatId = event => {
        setChatId(event.target.value);
    };
    const cambiarContraseña = event => {
        setContraseña(event.target.value);
    };
    const cambiarNombreUsuario = event => {
        setNombreUsuario(event.target.value);
    };
    const cambiarUNMS = event => {
        setUNMS(event.target.value);
    };

    const AgregarUsuario = async()=>{
      let user = {
        usuario: NombreUsuario,
        unmsId: UNMS,
        puntoVentaId: Punto,
        chatId: ChatId,
        password: Contraseña,
        empleadoId:Empleado,
        roleId: Rol
      }
      if (validarUsuario(user)) {
        let result = await postUsuario(user)
        if(result != null){
          if(result.ok){
            successAlert(setAlert,'Usuario Agregado');
            recetearUsuario();
            setModalAgregarUsuario(false);
            traerUsuarios();
          }else{
            warningAlert(setAlert,result.mensaje)
          }
  
      }else{
          warningAlert(setAlert,'No se pudo agregar el Usuario')
      }
      }else{
        warningAlert(setAlert,'Campos incompletos o incorrectos!')
      }
}

const validarUsuario = (us) => {
  const requiredFields = [
    'usuario',
    'empleadoId',
    'password',
    'chatId',
    'roleId',
    'unmsId',
    'puntoVentaId'
  ];

  return requiredFields.every((field) => us[field]); //verifica si todos los elementos del array son true
};

const recetearUsuario = () => {
  setNombreUsuario("")
  setChatId("")
  setUNMS("")
  setPunto("")
  setRol("")
  setEmpleado("")
  setContraseña("")
}

const traerPuntosVenta=async()=>{
  let pv= await getPuntosVentas();
  if (pv !=null && pv !="") {
    setPuntosVenta(pv);
  }
}

const traerRoles=async()=>{
    let roles= await getRoles();
    if (roles !=null && roles !="") {
        setRoles(roles);
    }
}

useEffect(() => {
    traerPuntosVenta();
    traerRoles();
    
  }, [])
  return (
      <>
      {Alert}
      <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={ModalAgregarUsuario}
                            toggle={() => setModalAgregarUsuario(false)}
                          >
                          <div className="modal-body p-0">
                            <Card className="bg-secondary shadow border-0">
                              <CardHeader className="bg-transparent pb-5">
                                <div className="text-center mb-3"><b>Agregar Usuario</b>
                                <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => setModalAgregarUsuario(false)}
                                >
                                  <span aria-hidden={true}>×</span>
                                </button>
                                </div>
                                <div className="mt-2 mb-3">
                                <Form role="form">
                                <FormGroup className="mb-3" >Nombre de Usuario:
                                <Input 
                                placeholder="Nombre de usuario..." 
                                type="text"
                                value={NombreUsuario} 
                                onChange={cambiarNombreUsuario} 
                                />
                                </FormGroup>
                                <FormGroup>
                                    <label>Empleado:</label>
                                    <SelectorEmpleados 
                                    valor={Empleado} 
                                    cambiarValor={setEmpleado} 
                                    titulo={false}
                                    />
                                </FormGroup>
                                  <FormGroup className="mb-3" >Contraseña:
                                      <Input 
                                      placeholder="Contraseña..." 
                                      type="text"
                                      value={Contraseña} 
                                      onChange={cambiarContraseña} 
                                      />
                                  </FormGroup>
                                <FormGroup className="mb-3">Chat Id:
                                      <Input 
                                      placeholder="Chat Id..." 
                                      type="number" 
                                      value={ChatId} 
                                      onChange={cambiarChatId}
                                      />
                                  </FormGroup>
                                  <FormGroup > Rol:
                                      <SelectorGenerico 
                                      value={Rol} 
                                      setValor={setRol} 
                                      arrayElementos={Roles}
                                      />
                                  </FormGroup>
                                  <FormGroup className="mb-3" >UNMS ID:
                                      <Input 
                                      placeholder="UNMS ID..." 
                                      type="number"
                                      value={UNMS} 
                                      onChange={cambiarUNMS} 
                                      />
                                  </FormGroup>
                                  <FormGroup className="mb-3" >Punto de Venta Id:
                                      <SelectorGenerico 
                                      value={Punto} 
                                      setValor={setPunto} 
                                      arrayElementos={PuntosVenta}
                                      />
                                  </FormGroup>
                                  <div className="text-center">
                                    <Button
                                      className="my-4"
                                      color="primary"
                                      type="button"
                                      onClick={()=>AgregarUsuario()}
                                    >
                                      Agregar
                                    </Button>
                                
                                    <Button
                                      color="secondary"
                                      data-dismiss="modal"
                                      type="button"
                                      onClick={() => setModalAgregarUsuario(false)}
                                    >
                                      Cerrar
                                    </Button>                    
                                  </div>
                                </Form>                     
                                </div>                    
                              </CardHeader>                  
                            </Card>
                          </div>
                          </Modal>
      </>
  )
}

export default ModalAggUsuario
