import React, { useEffect, useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Row,
  Col,
  Button,
  Container,
  Navbar
} from "reactstrap";
import LoadingComponent from 'pages/components/Loading';
import { getGruposPermisosConPermisos } from 'services/PermisosService';
import Buscador from 'pages/components/Buscador';
import PermisosGrupo from './PermisosGrupo';

const TablePermisosRol=(props)=> {
    const {PermisosSeleccionados, setPermisosSeleccionados} = props
    const [Visible, setVisible] = useState(false);
    const [openedCollapses, setOpenedCollapses] = useState([])
    const [GPermisos, setGPermisos] = useState([]);

    const traerGPermisos=async()=>{
      setVisible(true)
      let gPermisos= await getGruposPermisosConPermisos();
      if (gPermisos !=null && gPermisos !="") {
          setGPermisos(gPermisos);
      }
      setVisible(false)
  }

  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  const collapsesToggle = collapse => {
    if (openedCollapses.includes(collapse)) {
      setOpenedCollapses([])
    } else {
      setOpenedCollapses([collapse]);
    }
  };


  useEffect(() => {
    traerGPermisos()
  }, []);

  return (
      <>
            <LoadingComponent Visible={Visible}/>
            <Card style={{width:"100%"}}>
                <Navbar
                        className="navbar-horizontal navbar-light "
                        expand="lg"
                    >
                      <Row style={{width:"100%", margin:"0rem"}}>
                        <Col>
                          <Buscador traerObjetos={traerGPermisos} setObjetos={setGPermisos} getObjetos={getGruposPermisosConPermisos}/>
                        </Col>
                        <Col></Col>
                      </Row>

                </Navbar>
                <div className="mt-5" style={{width:"100%"}}>
                {GPermisos?
                  GPermisos.map((gp, index)=>{
                    return (
                      <div className="accordion" style={{width:"100%"}} key={index}>
                        <Card className="card-plain">
                          <CardHeader
                            role="tab"
                            onClick={() => collapsesToggle(index)}
                            aria-expanded={openedCollapses.includes(
                              index
                            )}
                          >
                            <h4 className="mb-0">{gp.nombre}</h4>
                          </CardHeader>
                          <Collapse
                            role="tabpanel"
                            isOpen={openedCollapses.includes(index)}
                          >
                            <PermisosGrupo permisos={gp.permisos} 
                                PermisosSeleccionados={PermisosSeleccionados} 
                                setPermisosSeleccionados={setPermisosSeleccionados}
                            />
                          </Collapse>
                        </Card>
                      </div>  
                    )
                  })
                  :
                  <></>
                }
                </div>
            </Card>
      </>
  )
}

export default TablePermisosRol
