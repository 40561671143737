import React, { useEffect, useRef, useState } from "react";
import { Calendar } from "../../../../node_modules/@fullcalendar/core";
import dayGridPlugin from "../../../../node_modules/@fullcalendar/daygrid";
import interaction from "../../../../node_modules/@fullcalendar/interaction";
import {Button,Card,CardHeader,CardBody,Row,Col} from "reactstrap";
import ModalAggEvento from "./components/ModalAggEvento";
import ModalEditEvento from "./components/ModalEditEvento";
import { getEventos } from "services/EventosService";
import { getEsperas } from "services/EsperaServices";
import { useLocation } from "react-router";
import LoadingComponent from "pages/components/Loading";
import { findInUnms } from "services/ClientesServices";
import ModalAggMantenimiento from "./components/ModalAggMantenimiento";
import ModalEditMan from "./components/ModalEditarEventoMan";
import "../../../assets/css/argon-dashboard-pro-react.css"

const Agenda = (props) => {

  const [CurrentDate, setCurrentDate] = useState("");
  const [Calendario, setCalendario] = useState("");
  const [Events, setEventos] = useState([]);
  const [Evento, setEvento] = useState([]);
  const CalendarRef = useRef(null);
  const [ModalAgregarEvento, setModalAgregarEvento] = useState(false);
  const [ModalAgregarMantenimiento, setModalAgregarMantenimiento] = useState(false);
  const [ModalEditarEvento, setModalEditarEvento] = useState(false);
  const [ModalEditarMantenimiento, setModalEditarMantenimiento] = useState(false);
  const [Estado, setEstado] = useState("");
  const [Espera, setEspera] = useState("");
  const [Mantenimiento, setMantenimiento] = useState("")
  const [EsperaEdit, setEsperaEdit] = useState("")
  const [MantenimientoEdit, setMantenimientoEdit] = useState("");
  const location = useLocation();
  const [Visible, setVisible] = useState(false)
  


  
  const createCalendar = async() => {
    let estado = location.state;
    setVisible(true)
    if (estado !==null && estado!=="") {
      traerId();
      let eventos = await getEventos();
      setEventos(eventos)
      let calendario = new Calendar(CalendarRef.current,{
        plugins: [interaction, dayGridPlugin],
        defaultView: "dayGridMonth",
        selectable: true,
        selectHelper: true,
        editable: true, 
        events: eventos.data,
        select: info => {
          setEstado({
            startDate: info.startStr,
            endDate: info.endStr,
            radios: "bg-secondary"
          })
          if (estado.idEspera) {
            setModalAgregarEvento(true);
            
          } else {
            setModalAgregarMantenimiento(true)
          }
        },
        eventClick: ({ event }) => {
          setEvento({
            id: event.id,
            esperaId:event.extendedProps.esperaId,
            clienteId:event.extendedProps.clienteId,
            eventTitle: event.title,
            eventDescription: event.extendedProps.comentario,
            radios: event.classNames,
            event: event,
            empleado: event.extendedProps.empleado,
            tipoMantenimiento: event.extendedProps.tipoMantenimiento,
            tipoMantenimientoId: event.extendedProps.tipoMantenimientoId,
            hora: event.extendedProps.hora,
            turno: event.start,
            empleadoId: event.extendedProps.empleadoId,
            direccion: event.extendedProps.direccion
          },
          traerIdEsperaEdit(event),
          );
          
        }
      })
      calendario.render();
      setCalendario(calendario)
      setCurrentDate({currentDate: calendario.view.title});
      if (estado.idEspera) {
        setModalAgregarEvento(true);
        
      } else {
        setModalAgregarMantenimiento(true)
      }
    } else {
      let eventos = await getEventos();
      setEventos(eventos)
      let calendario = new Calendar(CalendarRef.current,{
        plugins: [interaction, dayGridPlugin],
        defaultView: "dayGridMonth",
        selectable: true,
        selectHelper: true,
        editable: true, 
        events: eventos.data,
        select: info => {
          setEstado({
            startDate: info.startStr,
            endDate: info.endStr,
            radios: "bg-secondary"
          })
        },
        eventClick: ({ event }) => {
          setEvento({
            id: event.id,
            esperaId:event.extendedProps.esperaId,
            clienteId:event.extendedProps.clienteId,
            eventTitle: event.title,
            eventDescription: event.extendedProps.comentario,
            radios: event.classNames,
            event: event,
            empleado: event.extendedProps.empleado,
            tipoMantenimiento: event.extendedProps.tipoMantenimiento,
            tipoMantenimientoId: event.extendedProps.tipoMantenimientoId,
            hora: event.extendedProps.hora,
            turno: event.start,
            empleadoId: event.extendedProps.empleadoId,
            direccion: event.extendedProps.direccion
          },
          traerIdEsperaEdit(event),
          );
          
        }
      })
      calendario.render();
      setCalendario(calendario)
      setCurrentDate({currentDate: calendario.view.title});
    }
    setVisible(false)
    };
    
    const changeView = (newView) => {
      Calendario.changeView(newView);
    setCurrentDate({
      currentDate: Calendario.view.title
    });
  };

  const traerId =async() => {
    let estado = location.state
    if(estado.idEspera!=null && estado.idEspera !==""){
      let esperaId= estado.idEspera;
      let params= {id:esperaId}
      let espid= await getEsperas(params)
      if (espid) {
        setEspera(espid);
      }
    }else if(estado.idCliente){
      let clienteId= estado.idCliente;
      let clienid= await findInUnms(clienteId)
      if (clienid) {
        setMantenimiento(clienid);
        
      }
    }
   }
   const traerIdEsperaEdit =async(event) => {
    if(event.extendedProps.esperaId!=null && event.extendedProps.esperaId !==""){
    let params= {id: event.extendedProps.esperaId,};
    let espid= await getEsperas(params)
    if (espid) {
      setEsperaEdit(espid);
      setModalEditarEvento(true)
    }
  }else if(event.extendedProps.clienteId){
    let params= event.extendedProps.clienteId;
    let espid= await findInUnms(params)
    if (espid) {
      setMantenimientoEdit(espid);
      setModalEditarMantenimiento(true)
    }
  }
   }

  useEffect(() => {
    
    createCalendar();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
      
  return (
      <>
      <LoadingComponent Visible={Visible}/>
      <Card className="card-calendar mt-3">
          <CardHeader className="bg-info">
            <Row className="align-items-center py-4">
              <Col lg="6">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0 mr-1">
                  {CurrentDate?.currentDate}
                </h6>
                {/* <Button
                  className="btn-default btn-md ml-3"
                  data-calendar-view="basicDay"
                  onClick={() =>{setModalAgregarMantenimiento(true)}}
                >
                  Añadir Evento
                </Button> */}
              </Col>
              <Col className="mt-3 mt-lg-0 text-lg-right" lg="6">
                <Button
                  className="fullcalendar-btn-prev btn-neutral"
                  color="default"
                  onClick={() => {
                    Calendario.prev()
                  }}
                  size="sm"
                >
                  <i className="fas fa-angle-left" />
                </Button>
                <Button
                  className="fullcalendar-btn-next btn-neutral"
                  color="default"
                  onClick={() => {
                    Calendario.next();
                  }}
                  size="sm"
                >
                  <i className="fas fa-angle-right" />
                </Button>
                <Button
                  className="btn-neutral"
                  color="default"
                  data-calendar-view="month"
                  onClick={() => {changeView("dayGridMonth")}}
                  size="sm"
                >
                  Mes
                </Button>
                <Button
                  className="btn-neutral"
                  color="default"
                  data-calendar-view="basicWeek"
                  onClick={() =>{changeView("dayGridWeek")}}
                  size="sm"
                >
                  Semana
                </Button>
                <Button
                  className="btn-neutral"
                  color="default"
                  data-calendar-view="basicDay"
                  onClick={() =>{changeView("dayGridDay")}}
                  size="sm"
                >
                  Dia
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="p-0">
            <div
              className="calendar"
              ref={CalendarRef}
            />
          </CardBody>
        </Card>
        {Espera?
        <ModalAggEvento 
        Estado={Estado}
        ModalAgregarEvento={ModalAgregarEvento}
        setModalAgregarEvento={setModalAgregarEvento}
        Calendario={Calendario}
        Events={Events}
        setEventos={setEventos}
        createCalendar={createCalendar}
        Espera={Espera}
        />
        :
        <></>}
        {Mantenimiento?
        <ModalAggMantenimiento 
        ModalAgregarMantenimiento={ModalAgregarMantenimiento}
        setModalAgregarMantenimiento={setModalAgregarMantenimiento}
        createCalendar={createCalendar}
        Mantenimiento={Mantenimiento}
        location={location.state}
        />
        :
        <></>}
        {Evento?
        <>
        <ModalEditEvento
        Evento={Evento}
        ModalEditarEvento={ModalEditarEvento}
        setModalEditarEvento={setModalEditarEvento}
        createCalendar={createCalendar}
        EsperaEdit={EsperaEdit}
        />
        <ModalEditMan 
        Evento={Evento}
        ModalEditarMantenimiento={ModalEditarMantenimiento}
        setModalEditarMantenimiento={setModalEditarMantenimiento}
        createCalendar={createCalendar}
        MantenimientoEdit={MantenimientoEdit}
        location={location.state}
        />
        </>
        :
        <>-</>}
      </>
  )
}

export default Agenda