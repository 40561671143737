import { urlGlobal2 } from './GlobalService.js';

export const getRolPermisos = async(params=null) =>{
    let url = new URL(`${urlGlobal2}/roles/get/permisos`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
        
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        return null;
    }
}