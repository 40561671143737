import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import { confirmAlert } from 'pages/components/SweetAlert';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Col, Collapse, Container, FormGroup, Label, Navbar, Row } from 'reactstrap';
import { getCaes } from 'services/CaesServices';
import { setQueryParams } from 'store/slices/caesSlice';
import * as Yup from 'yup';
const XLSX = require('xlsx');
export const NavbarCaes = (props) => {
    let { setdefaultModal } = props;
    const [Alert, setAlert] = useState(null)
    const [Filtros, setFiltros] = useState(null)

    const [Collap, setCollap] = useState(false);
    const tips = ['A', 'B'];
    const dispatch = useDispatch();

    const validationSchema = Yup.object({
        Desde: Yup.string().required('Desde es requerido'),
        Hasta: Yup.string().required('Hasta es requerido')
    });

    const aplicarFiltros = (values) => {

        if (validationSchema.isValidSync(values) === false) {
            alert("Seleccione un rango de fechas")
            return;
        }

        const queryParamsDto = {
            tipo: values.selectedTipo,
            importe: values.selectedImporte,
            cae: values.caeValue,
            facturaId: values.facturaIdValue,
            fechaInicioFacturacion: values.Desde,
            fechaFinFacturacion: values.Hasta,
        };
        setFiltros(queryParamsDto)

        Object.keys(queryParamsDto).forEach(key => (queryParamsDto[key] == null || queryParamsDto[key] === "") && delete queryParamsDto[key]);

        dispatch(setQueryParams(queryParamsDto));
    }

    const handleLimpiarFiltros = () => {
        dispatch(setQueryParams({}));
    }
    const confirmarExportacion = async () => {
        confirmAlert(setAlert, exportTableToExcel, null, "Expotar a Excel?")
    }


    const obtenerFormatoFecha = (creado) => {
        let fecha = new Date(creado);
        let dia = fecha.getDate();
        let mes = fecha.getMonth();
        let ano = fecha.getFullYear();
        let fechaFormateada = `${dia}/${mes + 1}/${ano}`;
        return fechaFormateada;
    }


    const exportTableToExcel = async (tableID, filename = '') => {
        // Crea un array de datos de ejemplo

        const data = [["cae", "creado", "fiscalizado", "tipo", "importe", "fecha", "fechaFacturacion", "nroFactura",]];

        let materiales = await getCaes(Filtros)
        materiales.forEach(m => {
            data.push([m.cae, obtenerFormatoFecha(m.creado), m.facturaId ? `${m.facturaId}` : "No asignado", m.tipo, m.importe, m.fecha, m.fechaFacturacion, m.nroFactura])
        })

        // Crea un objeto Workbook de SheetJS
        // Crea un objeto Workbook de SheetJS
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(data);

        // Agrega la hoja de cálculo al libro de trabajo
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja 1');

        // Convierte el objeto Workbook a un archivo Excel en formato XLSX
        let blob = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Crea un objeto Blob
        let blobObject = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Crea un objeto URL para el Blob
        let url = URL.createObjectURL(blobObject);

        // Crea un enlace de descarga y establece su atributo "href" para el objeto URL
        let a = document.createElement("a");
        a.href = url;
        a.download = `Caes-(${obtenerFormatoFecha(moment().format())})`;

        // Simula un clic en el enlace para iniciar la descarga
        a.click();

        // Limpia el objeto URL creado
        URL.revokeObjectURL(url);
    }



    return (
        <Navbar className="navbar-horizontal navbar-light bg-secondary" expand="md">
            {Alert}
            <Container fluid>
                <Col md={3} lg={3} xl={3} >
                    <Row>
                        {Filtros?
                        <>
                        <Button onClick={() => setdefaultModal(true)} className="btn-sm btn-outline-success" >
                            Ver Resumen
                        </Button>
                       
                        <Button className='btn-sm btn-outline-info' onClick={() => confirmarExportacion()}>
                            Exportar
                        </Button>
                        </>
                        :
                        <></>
                        }
                    </Row>

                </Col>
                <Col md={9} lg={9} xl={9}>
                    <div className="accordion">
                        <Formik
                            initialValues={{
                                selectedTipo: '',
                                selectedImporte: '',
                                Desde: '',
                                Hasta: '',
                                caeValue: '',
                                facturaIdValue: ''
                            }}
                            onSubmit={aplicarFiltros}
                        >
                            {({ handleSubmit, resetForm, errors, touched }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Card className="card-plain mt-4">
                                        <CardHeader
                                            role="tab"
                                            onClick={() => { setCollap(!Collap); }}
                                            aria-expanded={Collap}
                                        >
                                            <h3 className="mb-0 " style={{ fontWeight: "600" }}>Filtros</h3>
                                        </CardHeader>
                                        <Collapse
                                            role="tabpanel"
                                            isOpen={Collap}
                                        >
                                            <CardBody>


                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="monthSelect">Tipo de Factura:</Label>
                                                            <Field
                                                                as="select"
                                                                name="selectedTipo"
                                                                id="monthSelect"
                                                                className="form-control mb-2"
                                                            >
                                                                <option value="" disabled>Elige un tipo</option>
                                                                {tips.map((tip, index) => (
                                                                    <option key={index} value={tip}>{tip}</option>
                                                                ))}
                                                            </Field>
                                                            <Label for="yearInput">Importe:</Label>
                                                            <Field
                                                                type="number"
                                                                name="selectedImporte"
                                                                id="yearInput"
                                                                className="form-control"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="fromDate">Desde:</Label>
                                                            <Field
                                                                type="date"
                                                                name="Desde"
                                                                id="fromDate"
                                                                className="form-control mb-2"
                                                            />
                                                            {errors.Desde && touched.Desde ? <p>aaa</p> : null}
                                                            <Label for="toDate">Hasta:</Label>
                                                            <Field
                                                                type="date"
                                                                name="Hasta"
                                                                id="toDate"
                                                                className="form-control"
                                                            />
                                                            {errors.Hasta && touched.Hasta ? <div className="invalid-feedback">{errors.Hasta}</div> : null}
                                                        </FormGroup>
                                                    </Col>

                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="yearInput">CAE:</Label>
                                                            <Field type="text" name="caeValue" className="form-control" placeholder="Buscar por CAE" />
                                                            <Label for="yearInput" style={{ marginTop: '1rem' }}>Factura :</Label>
                                                            <Field
                                                                type="text"
                                                                name="facturaIdValue"
                                                                className="form-control"
                                                                placeholder="Buscar por FacturaID"
                                                            />

                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="justify-content-center">

                                                    <Col md={6}>
                                                        <Button
                                                            type="button"
                                                            block
                                                            color="warning"
                                                            size="sm"
                                                            outline
                                                            onClick={() => {
                                                                resetForm();
                                                                handleLimpiarFiltros();
                                                            }}
                                                        >
                                                            Limpiar filtros
                                                        </Button>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Button
                                                            type="submit"
                                                            block
                                                            color="primary"
                                                            size="sm"
                                                            outline
                                                        >
                                                            Filtrar
                                                        </Button>
                                                    </Col>
                                                </Row>

                                            </CardBody>
                                        </Collapse>
                                    </Card>

                                </Form>
                            )}
                        </Formik>
                    </div>
                </Col>
            </Container>
        </Navbar>
    );
}
