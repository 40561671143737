
import React from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup,  Modal } from 'reactstrap';

const ModalComentario=(props)=>{

    const {ModalComen, setModalComen,EgrSeleccionado}=props;

    return(
        <>
        {EgrSeleccionado?
        <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={ModalComen}
        toggle={() => setModalComen(false)}
      >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-center"><b>Comentario</b>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setModalComen(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
            </div>
            </CardHeader>  
            <CardBody>
            <Form role="form">
            <FormGroup className="mb-3 mt-5 " >
                    <p><b>{EgrSeleccionado.comentario}</b></p>
              </FormGroup>
              </Form> 
            </CardBody>
            <CardFooter>
            <div className="text-muted mt-2 mb-3">
              <div className="text-center">
                <Button
                  color="primary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => { setModalComen(false)}}
                >
                  Cerrar
                </Button>                    
              </div>        
            </div>     
            </CardFooter>
                               
                          
        </Card>
      </div>
      </Modal>
        :
        <>-</>}
        
        </>
    )
}
export default ModalComentario


