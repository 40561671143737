import React , { useState } from "react";
import {
    Container,
    Row
  } from "reactstrap";
import NavBarDashboard from "./components/NavBarDashboard";
import ContenedorSubVistasDashboard from "./components/ContenedorSubVistasDashboard";



function Dashboard(){

    const [vista, setVista] = useState("Usuarios");
  
          return (
            <>
               
                <Container className="mt-3" fluid>
                    <Row>
                          {/* llamo a la nav con Botones varios */}
                          <NavBarDashboard setVista={setVista}/>
                    </Row>
                    <Row>
                      <ContenedorSubVistasDashboard vista={vista}/>
                    </Row>
                </Container>
                
            </>
           
        )

}


export default Dashboard