import React, { useState } from 'react'
import { Button, Card, CardBody, CardHeader, Table } from 'reactstrap'
import ModalComentario from './ModalComentario';

const TableEgresos = props => {
  const {Egresos}=props;
  const [ModalComen, setModalComen] = useState(false);
  const [EgrSeleccionado, setEgrSeleccionado] = useState(null);

  const verComentario = (egr)=>{
    setEgrSeleccionado(egr);
    setModalComen(true); 
  }
  
  const obtenerFormatoFecha = (fechaIngreso)=>{
    let fecha = new Date(fechaIngreso);
    let dia = fecha.getDate();
    let mes = fecha.getMonth();
    let ano = fecha.getFullYear();
    let fechaFormateada = `${dia}/${mes+1}/${ano}`;
    return fechaFormateada;
  };
 
  return (
      <>
       <Card className="ml-4 mr-4 mt-3">
           <CardHeader><h2>Egresos</h2></CardHeader>
           <CardBody>
           <Table className="align-items-center" responsive>
          <thead className="thead-light text-center">
            <tr>
              <th scope="col">ID</th>
              <th scope="col">FECHA</th>
              <th scope="col">TIPO</th>
              <th scope="col">IMPORTE</th>
              <th scope="col">COMPROBANTE</th>
              <th scope="col">Comentario</th>
            </tr>
          </thead>
          {Egresos?
      Egresos.map((egg, index)=>{
        return(
          <tbody>
          <tr key={index}>
            <th scope="row">
            <div className="text-center">
                  <span className="mb-0 text-sm">
                    {egg.id}
                  </span>
            </div>
            </th>
            <td>
            <div className="text-center">
                  <span className="mb-0 text-sm">
                  {obtenerFormatoFecha(egg.creado)}
                  </span>
            </div>
            </td>
            <td>
            <div className="text-center">
                  <span className="mb-0 text-sm">
                  {egg.tipoEgreso?.nombre}
                  </span>
            </div>
            </td>
            <td>
            <div className="text-center">
                    <span className="mb-0 text-sm">
                    {egg.importe}
                    </span>
            </div>
            </td>
            <td>
            <div className="text-center">
                    <span className="mb-0 text-sm">
                    {egg.comprobanteId}
                    </span>
            </div>
            </td>
            <td>
            <div className="text-center">
                    <Button 
                    className="btn-sm btn-success" 
                    onClick={() => { verComentario(egg)}}
                    >
                      Ver
                    </Button>
            </div>
            </td>
          </tr>
          
          
  </tbody>
        )
})
    :
    <>-</>
    }
          
        </Table>
           </CardBody>
       </Card>
       {EgrSeleccionado !=null?
      <ModalComentario
      ModalComen={ModalComen}
      setModalComen={setModalComen}
      EgrSeleccionado={EgrSeleccionado}/>
      :
      <>-</>
       }
       
      </>
  )
}

export default TableEgresos