import React, { useEffect, useState } from 'react';
import {
    Button,
    InputGroupAddon,
    InputGroup,
    Container,
    NavbarBrand,
    Navbar,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
  } from "reactstrap";
import { useHistory } from "react-router";
import { getCasos } from 'services/CasosServices';
import EtiquetaEstado from '../components/EtiquetaEstado';
import { findInUnms } from 'services/ClientesServices';
import { putCaso } from 'services/CasosServices';
import { GuardInPages } from 'guards/Guard';
import { confirmAlert, successAlert } from "../../../components/SweetAlert";  
import { getComentarioCaso } from 'services/CasosServices';
import ModalAgregarComen from './components/ModalAgregarComentario';
    
  const CasoParticular = props => {
    const {idClient} = props;
    const [ModalAgregarComentario, setModalAgregarComentario] = useState(false)
    const history = useHistory ();
    const [alertSweet, setAlert] = useState(null); 
    const [idCaso, setId] = useState(null);
    const [CasoPar, setCasoPar] = useState(null); 
    const [Cliente, setCliente] = useState(null);
    const [ComentarioCaso, setComentarioCaso]= useState(null);
    
    
    const Atras=() =>{
        history.replace(`/admin/casos`)
    }
    
    const TerminarCaso= async() =>{
      let casoAux = {};
      Object.assign(casoAux, CasoPar)
            casoAux.estado = 2;
          
      let ter = await putCaso(casoAux);
          if (ter !== null && ter !== '') {
              
              traerCaso();
              successAlert(setAlert, "Caso Terminado!", "");
              
          }
    }


    const confirmarFinalizacion = () => {
      confirmAlert(setAlert, TerminarCaso, null,"Terminar Caso?")
    }

    const traerCaso = async() => {
    const casoId=props.match.params.idCaso;
    setId(casoId);
    let params = {id:casoId}
    let ca = await getCasos(params);
    if (ca !== null && ca !== '') {
    traerCliente(ca.clienteId)
    setCasoPar(ca);
    }
   }

    const obtenerFormatoFecha = (creado)=>{
      let fecha = new Date(creado);
      let dia = fecha.getDate();
      let mes = fecha.getMonth();
      let ano = fecha.getFullYear();
      let fechaFormateada = `${dia}/${mes+1}/${ano}`;
      return fechaFormateada;
    }

   const obtenerFormatoResuelto = (por, cuando) => {
      let formato = `Resuelto por: ${por} (${cuando})`;
      return formato
    }
   
   const traerCliente = async(id) => {
    let datos = await findInUnms(id)
    if(datos){
      setCliente(datos);
    }
    }
  
    const traerComentarioCaso = async() => {
      const casoId=props.match.params.idCaso;
      setId(casoId);
      let com = await getComentarioCaso(null, casoId);
      if (com !== null && com !== '') {
      traerCliente(com.clienteId)
      setComentarioCaso(com);
      }
     }
     const irCliente = (idCliente)=>{
      history.replace(`/admin/perfilClientes/${idCliente}`)
  
    }

        useEffect(() => {
          GuardInPages(history)
          traerCaso();
          traerComentarioCaso();
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
      
        
      
    return (
        <>
        {alertSweet}
        <Navbar
          className="navbar-horizontal navbar-light bg-secondary"
          expand="lg"
        >
          <Container >
            <NavbarBrand >
              <InputGroup>
           <InputGroupAddon addonType="append">
             <Button className="ml-3" color="success" outline type="button" onClick={()=>Atras()}>
               Atras
             </Button>
             <Button className="ml-3" color="info" outline type="button" onClick={()=>setModalAgregarComentario(true)}>
               Agregar Comentario
             </Button>
             <Button className="ml-3" color="danger" outline type="button" onClick={()=>confirmarFinalizacion()}>
               Terminar Caso
             </Button>
           </InputGroupAddon>
         </InputGroup>
            </NavbarBrand>
          </Container>
          </Navbar>
          
          {CasoPar?
          <>
          <Row className="mb-0">
          <Col lg="6">
            <Card className="card-stats mt-2 ml-3" style={{height:"8rem"}}>

              <CardBody>
                <Row>
                  <div className="col text-center">
                    <CardTitle className=" text-muted mb-0">
                      Caso ID: {CasoPar.id} ( {obtenerFormatoFecha(CasoPar.creado)} )
                    </CardTitle>
                    <span className="h3 font-weight-bold mt-3">Estado: {CasoPar.estado?
                     <EtiquetaEstado Estado={CasoPar.estado}/>  
                     :
                      <p>Sin estado</p>
                      }                  
                    </span>
                    
                    <p className="mt-3 mb-0 text-md">
                 
                     <span className="text-wrap ">
                      Creado por: {CasoPar.usuario?
                     CasoPar.usuario.usuario
                     
                     :
                          <>-</>
                          }
                     </span>
                     
                </p>
                  </div>               
                </Row>                               
              </CardBody>
            </Card>
          </Col>
          
          <Col lg="6">
            <Card className="card-stats mt-2 mr-3">

              <CardBody onClick={()=>irCliente(CasoPar.clienteId)}>
                
                <Row>
                  <div className="col text-center">
                    <CardTitle className="text-uppercase text-muted mb-0">
                      Cliente ID: {CasoPar.clienteId}
                    </CardTitle>
                      {Cliente?
                      
                      <span className="h3 font-weight-bold mb-0">
                        {Cliente.firstName} {Cliente.lastName}
                      </span>
                    :
                    <span className="h2 font-weight-bold mb-0">
                      -
                    </span>
                    }
                    {Cliente?
                    <p className="mt-3 mb-0 text-md">
                    
                        <span className="text-nowrap">
                        Telefono: {Cliente.phone}         
                        </span>
                    </p>
                    :
                    <span className="h2 font-weight-bold mb-0">
                      -
                    </span>
                    }
                  </div>
                  
                </Row>
                
              </CardBody>
            </Card>
          </Col>
        </Row> 
        <Row>
          <Col lg="12">
          <Card style={{width:"100%"}} className="text-center  ml-3 card-stats mr-6" >
            <CardHeader className="mt-0 mb-0">
              <b><u>Descripción del Caso</u></b>
            </CardHeader>
            <CardBody  >
             
            
            {CasoPar.enunciado} <br/>
            <div className="mt-3 mb-3">
                <img 
                src={CasoPar.archivo}
                alt="">
                </img>
            </div><br/>
            
            
            {CasoPar.estado===2?
              obtenerFormatoResuelto(CasoPar.resueltoPor, obtenerFormatoFecha(CasoPar.fechaResolucion) )
              :
              <>-</>
             
            
             }
          
            
            </CardBody>
          </Card>
          </Col>
        </Row>
        
        
        </>
        :
        <p>Cargando..</p>
          }
          {ComentarioCaso?
          
            ComentarioCaso.map((coment, index) => {
              return (
                <Row>
                  <Col>
                  <Card style={{width:"100%"}} className="text-center ml-3 mr-3 " key={index}>
                    <CardHeader>
                    Comentario creado por: <b>{coment.usuario?
                     coment.usuario.usuario
                     
                     :
                          <>-</>
                          }</b> ( {obtenerFormatoFecha(coment.creado)} )
                    </CardHeader>
                    <CardBody>
                      
                      <div className="mt-3 mb-3 text-md">
                        {coment.mensaje}
                      </div> <br/>
                        <div>
                        <img 
                        src={coment.archivo}
                        alt="">
                        </img>
                      </div>
                      
                    </CardBody>
                  </Card>
                  </Col>
                </Row>
              )
            }
            )
            :
            <>-</>
            
          }
       
        <ModalAgregarComen
        setModalAgregarComentario={setModalAgregarComentario}
        ModalAgregarComentario={ModalAgregarComentario}
        setAlert={setAlert}
        idCaso={idCaso}
        traerCaso={traerCaso}
        />
          </>
    )
}
export default CasoParticular;

