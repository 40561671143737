/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef, useState } from 'react'
// reactstrap components
import { Card, Container, Row } from "reactstrap";
import Maps from "../components/Maps";
import Marcador from "../components/Marcadores";
import { getEsperas } from 'services/EsperaServices';
// core components


function Mapa() {
  
  return (
    <>
      <Container className="mt-4" fluid>
        <Row>
          <div className="col">
            <Card className="border-0">
              <Maps/>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Mapa;
