import { urlGlobal2 } from "./GlobalService";


export async function postComprobante(comprobante){
    let url = `${urlGlobal2}/comprobantes`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(comprobante)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }