import React from 'react'
import EscanerMacs from 'pages/components/EscanerMacs';
import { Col, Container, Row } from 'reactstrap';

export default function ContainerEscanear(props) {
    const {setVistaMuestra, equipos, setEquipos} = props;

    const accionScanEquipos = () => {
        setVistaMuestra("categoria")
    }

    return (
        <Container className="mt-6" fluid>
            <Row>
                <Col></Col>
                <Col>
                    <EscanerMacs equipos={equipos} setEquipos={setEquipos} continuarScanEquipos={accionScanEquipos}/>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}
