import React, { useEffect, useState } from "react";
import {FormGroup,Input} from "reactstrap";
import { verificarToken } from "services/AuthService";
import { getValores } from "services/ValoresService";


function SelectorValores(props) {
    const {valor, cambiarValor} = props;
    const [Tipo, setTipo] = useState([]);

    const handleChange =(e) => {
         cambiarValor(e.target.value);
  };
    
  
  const traerTipo = async() => {
      let valores = await getValores()
      if (valores) {
          setTipo(valores)
        }
    }
    
    useEffect(() => {
      traerTipo();
    }, [])
    return (
      <>
                  <FormGroup>
                    {Tipo?
                    <Input id="exampleFormControlSelect1" type="select"
                      value={valor}
                      onChange={handleChange}
                    >
                      <option value={""}>Opciones...</option>
                      {Tipo.length !== 0 ?
                      
                      Tipo.valores.map((tm, index) => {
                        return (
                            <option key={index} value={tm.id} >{tm.nombre} ( $ {tm.importe} )</option>
                          )

                        })
                      :
                      <option value={""}>Cargando..</option>
                      }
                    </Input>
                    
                    :<></>}
                  </FormGroup>
      </>
    );
  }
  
  export default SelectorValores;