import React, { useState } from 'react'
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap';
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import {putEquipoRecibir} from 'services/EquiposService';

export default function ModalRecibirEquipos(props) {

  const {RecibirEquiposModal,setModalRecibirEquipos,equipos,traerEquiposEpleado,setEquipos} = props;
  const [mac,setMac] = useState("");
  const [AlertS, setAlertS] = useState(null);


  const RecibirEquipos = async() => {
    
    let eqEncontrado = equipos.find(eq => eq.macAddress === mac);
    if(eqEncontrado){
        let resp = await putEquipoRecibir(mac);
        if(resp.ok){
          successAlert(setAlertS, "Equipo recibido");
          setEquipos(equ => {
            let newequipos = equ.filter(eq => eq.macAddress !== mac);
            setEquipos(newequipos);
          })
        }
        else{
          warningAlert(setAlertS,resp.mensaje) 
        }
    }
    else{
        warningAlert(setAlertS, "El empleado no tiene este equipo revisa la MAC!")  
    }
    
  } 

  const cambiarMac = event => {
    setMac(event.target.value);
  };


  return (
    <>
        {AlertS}
         <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={RecibirEquiposModal}
            toggle={() => setModalRecibirEquipos(false)}
            >
            <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                <div className="text-center"><b>Recibir Equipos</b>
                <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setModalRecibirEquipos(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
                                </div>
                                <div className="text-muted mt-2 mb-3">
                                <Form role="form">
                                <FormGroup value={mac} onChange={cambiarMac}> Ingresa la mac:
                                    <InputGroup className="input-group-alternative">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                        
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input placeholder="Nombre..." type="text" />
                                    </InputGroup>
                                  </FormGroup>
                                 
                                
                            
                              
                            
                                  <div className="text-center">
                                    <Button
                                      className="my-4"
                                      color="primary"
                                      type="button"
                                      onClick={()=>RecibirEquipos()}
                                    >
                                      Agregar
                                    </Button>
                                                 
                                  </div>
                                </Form>                     
                                </div>                    
                              </CardHeader>                  
                            </Card>
                          </div>
                          </Modal>
    
    </>
  )
}
