import React, { useEffect, useState } from "react";

function BarcodeScanner(props) {
  const {setEquipoEscaneado} = props;
  let [escaneado, setEscaneado] = useState('');
  const [errorMAC, setErrorMAC] = useState('');

  const onDown = event => {
    
    if (event.code === "Enter") {
      if(escaneado){
        escaneado = (escaneado.replace(/:/gi, "")).replace(/ /g, "");
        var cadena = "ABCDEF0123456789";
        if (cadena.indexOf(escaneado[escaneado.length -1]) > -1 || escaneado === '') {
          
          setEquipoEscaneado(escaneado);
         
        }
        else{
            setErrorMAC(true);
        }
      }
      setEscaneado(()=>{
        escaneado = '';
      });
      return
    }
    if (event.key !== "Shift") {
      setEscaneado(()=>{
        escaneado += event.key 
      });
    }

  }

    const windF = () => {
      window.addEventListener("keydown", onDown)
    }
  
  // Bind and unbind events
  useEffect(() => {
    windF()
  });

  
  


    return (
      <>
      </>
    );
}


  
  
  export default BarcodeScanner;