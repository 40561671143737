import React, { useEffect, useState } from "react";
import {  Input } from "reactstrap";
import { verificarToken } from "services/AuthService";
import { getCategorias } from "services/CategoriasService";

function SelectorCategorias(props){
    const {Categoria, setCategoria, tipoValue, titulo} = props;
    const [categorias, setCategorias] = useState([]);
    
    const cambiarCategoria = event => {
        setCategoria(event.target.value);
    };

    const traerCategorias = async() => {
      let tk = null; 
      try {
        tk = localStorage.getItem('token');
      } catch (error) {
        return
      }
      let tkValido = await verificarToken(tk);
      if(!tkValido){
          return
      }
      let cats = await getCategorias(tk);
      if (cats !== null && cats !== '') {
        setCategorias(cats);
      }
    }
    
    useEffect(() => {
        traerCategorias();
    }, [])
    

    return(
        <>
                    <Input id="exampleFormControlSelect1" type="select" 
                        value={Categoria}
                        onChange={cambiarCategoria}
                    > {titulo?
                        <option value={''}>{titulo}</option>
                        :
                        <option value={''}>Opciones...</option>
                        
                    }
                        {categorias?
                        
                        categorias.map((ct, index) => {
                        if(tipoValue === "nombre"){return (
                            <option key={index} value={ct.nombre}>{ct.nombre}</option>
                            )
                        }else if(tipoValue === "id"){return (
                            <option key={index} value={ct.id}>{ct.nombre}</option>
                            )
                        }else if(tipoValue === "objeto"){return (
                            <option key={index} value={ct}>{ct.nombre}</option>
                            )
                        }
                        })
                        :
                        <option value={''}>Cargando..</option>
                        }
                    </Input>
        </>
    );
};

export default SelectorCategorias;