import LoadingComponent from "pages/components/Loading";
import React, { useEffect, useState } from "react";
import { Card, Table } from "reactstrap";
import { getEquipos } from "services/EquiposService";


function Equipos({ idEmpls }) {
  const [EquiposEmpleados, setEquiposEmpleados] = useState([]);
  const [Visible, setVisible] = useState(false)

  const tarerEquiposEmpleados = async () => {
    setVisible(true)
    let equipos = await getEquipos({ empleadoId: idEmpls });
    if (equipos) {
      setEquiposEmpleados(equipos)
    }
    setVisible(false)
  }

  
  const obtenerFormatoFecha = (creado)=>{
    let fecha = new Date(creado);
    let dia = fecha.getDate();
    let mes = fecha.getMonth();
    let ano = fecha.getFullYear();
    let fechaFormateada = `${dia}/${mes+1}/${ano}`;
    return fechaFormateada;
  }

  useEffect(() => {
    tarerEquiposEmpleados()
  }, [])

  return (
    <>
      <LoadingComponent Visible={Visible}/>
      <Card style={{ width: "100%" }} className="mt-4">
        <Table responsive>
          <thead className="text-center bg-info">
            <th>MAC</th>
            <th>CATEGORIA</th>
            <th>ULTIMA MODIFICACION</th>
          </thead>
          <tbody className="text-center">
            {EquiposEmpleados ?
              EquiposEmpleados.map((e) => {
                return (

                  <>
                    <tr key={e.id}>
                      <th>{e.macAddress}</th>
                      <td>{e.categoria?.nombre}</td>
                      <td>{obtenerFormatoFecha(e.ultimaModificacion)}</td>
                    </tr>
                  </>

                )
              })
              :
              <>
                <tr>
                  <td>Este empleado no tiene equipos registrados!</td>
                </tr>
              </>
            }
          </tbody>

        </Table>
      </Card>
    </>
  )
}


export default Equipos;