import { confirmAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react'
import { Button, Table, UncontrolledTooltip } from 'reactstrap';


const PermisosGrupo=(props)=> {
    const {permisos, PermisosSeleccionados, setPermisosSeleccionados} = props;
    const [alertSweet, setAlert] = useState([]);

    const actualizaCheck = (idP) => {
        setPermisosSeleccionados(idsAnt => {
          if(idsAnt.includes(idP)){
            let aux = []
            idsAnt.map(id=>{
              if(id != idP){
                aux.push(id)
              }
            })
            setPermisosSeleccionados(aux)
          }
          else{
            idsAnt.push(idP);
            setPermisosSeleccionados(idsAnt)
          }
        })
      }

    const isCheck = (id) => {
        if(PermisosSeleccionados.includes(id)){
            return true
        }else{return false}
    }
    

  return (
      <>
      {alertSweet}
        <Table className="align-items-center" responsive>
            <thead className="thead-light text-center mt-5">
            <tr>
                <th scope="col">NOMBRE</th>
                <th scope="col">DESCRIPCION</th>
                <th scope="col">EDITAR</th>
                <th scope="col">ELIMINAR</th>
            </tr>
            </thead>
            <tbody>
            {permisos?
            permisos.map((p, index)=>{
                return (
                <tr key={index}>
                    <td>
                        <div className="text-center">
                            <span className="mb-0 text-sm">
                                <b>{p.nombre}</b>
                            </span>
                        </div>
                    </td>
                    <td>
                        <div className="text-center">
                            <span className="mb-0 text-sm">
                                {p.slug}
                            </span>
                        </div>
                    </td>
                    <td>
                        <label className="custom-toggle">
                            <input type="checkbox"  onClick={()=>actualizaCheck(p.id)} checked={isCheck(p.id)}/>
                            <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Si"
                            />
                        </label>
                    </td>
                        
                </tr>

                )
            })
            :
            <p>Cargando...</p>
            }
            </tbody>
        </Table>      
        
      </>
  )
}

export default PermisosGrupo
