import React from 'react'
import { Card, CardBody, CardHeader, Table } from 'reactstrap'

const TableRetirosPagos = props => {
  const {Retiros}=props;

  const obtenerFormatoFecha = (fechaIngreso)=>{
    let fecha = new Date(fechaIngreso);
    let dia = fecha.getDate();
    let mes = fecha.getMonth();
    let ano = fecha.getFullYear();
    let fechaFormateada = `${dia}/${mes+1}/${ano}`;
    return fechaFormateada;
  };
  return (
      <>
       <Card className="ml-4 mr-4 mt-3">
           <CardHeader><h2>Retiros</h2></CardHeader>
           <CardBody>
           <Table className="align-items-center" responsive>
          <thead className="thead-light text-center">
            <tr>
              <th scope="col">ID</th>
              <th scope="col">FECHA</th>
              <th scope="col">IMPORTE</th>
              <th scope="col">RETIRADO POR</th>
            </tr>
          </thead>
          {Retiros?
          Retiros.map((rc, index)=>{
            return(
              <tbody>
                  <tr key={index}>
                    <th scope="row">
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                          {rc.id}
                          </span>
                    </div>
                    </th>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                          {obtenerFormatoFecha(rc.creado)}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                          $ {rc.importe}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                            <span className="mb-0 text-sm">
                            {rc.retiradoPor}
                            </span>
                    </div>
                    </td>
                  </tr>
                  
                  
          </tbody>
            )
          })
        :
        <>-</>
        }
          
        </Table>
           </CardBody>
       </Card>
       
      </>
  )
}

export default TableRetirosPagos