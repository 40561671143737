import React, { useState } from 'react'
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, Modal, Table } from 'reactstrap'
import SelectorEmpleados from 'pages/components/SelectorEmpleados';
import { postRegistroRetiro } from 'services/RegistrarService';
import Buscador from 'pages/components/Buscador';
import { getClientes } from 'services/ClientesServices';
import LoadingComponent from 'pages/components/Loading';
import { getMateriales } from 'services/MaterialesService';
import { getTiposMaterialesClientes } from 'services/TiposMaterialesService';
import BuscadorMaterialCliente from '../BuscadorMaterialesClientes';
import RetirarA from './RetirarAComponent';
import RetirarACliente from './RetirarACliente';
import RetirarAComponent from './RetirarAComponent';
import RetirarAObra from './RetirarAObra';

const ModalRegisterRetiro = ({ModalRegistrarRetiro, setModalRegistrarRetiro, traerMateriales}) => {
    const [Visible, setVisible] = useState(false)
    const [Materiales, setMateriales] = useState("");
    const [Empleado, setEmpleado] = useState("")
    const [Cantidad, setCantidad] = useState("");
    const [Cliente, setCliente] = useState("")
    const [Obra, setObra] = useState("")
    const [Alert, setAlert] = useState(null)
    const [ClienteSeleccionado, setClienteSeleccionado] = useState(false)
    const [ObraSeleccionada, setObraSeleccionada] = useState("")
    const [MaterialSeleccionado, setMaterialSeleccionado] = useState(false)
    const [MaterlSeleccionado, setMaterSeleccionado] = useState(false);
    const [Clientes, setClientes] = useState([])
    const [Obras, setObras] = useState([])
    const [Error, setError] = useState({})
    const [RetirarA, setRetirarA] = useState("")
  

      const reset = () => {
        setModalRegistrarRetiro(false);
        setClienteSeleccionado("");
        setEmpleado("");
        setCliente("")
        setClientes("")
        setMaterialSeleccionado("");
        setMaterialSeleccionado("");  
        setMaterSeleccionado(false)
        setRetirarA("")
        setObra("")
        setObras("")
        setMateriales("")
        setObraSeleccionada("")
      }


  return (
    <>
        {Alert}
        <LoadingComponent Visible={Visible}/>
        <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={ModalRegistrarRetiro}
        toggle={() => reset()}
        >
        <div>
        <Card>
            <CardHeader className="pb-3">
            <div className="text-center"><b>Registrar Retiro</b>
            <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => reset()}
        >
            <span aria-hidden={true}>×</span>
        </button>
            </div>
            </CardHeader> 
            {RetirarA === "" ? 
            <RetirarAComponent cambiarEstado={setRetirarA}/>
        :
        <>
        {RetirarA === "Cliente"?
        <>
        <RetirarACliente
        ClienteSeleccionado={ClienteSeleccionado}
        setClienteSeleccionado={setClienteSeleccionado}
        Cantidad={Cantidad}
        setCantidad={setCantidad}
        setError={setError}
        Error={Error}
        Empleado={Empleado}
        setEmpleado={setEmpleado}
        setAlert={setAlert}
        Cliente={Cliente}
        setCliente={setCliente}
        setMaterSeleccionado={setMaterSeleccionado}
        Materiales={Materiales}
        setMateriales={setMateriales}
        MaterialSeleccionado={MaterialSeleccionado}
        setMaterialSeleccionado={setMaterialSeleccionado}
        setModalRegistrarRetiro={setModalRegistrarRetiro}
        traerMateriales={traerMateriales}
        reset={reset}
        Clientes={Clientes}
        setClientes={setClientes}
        MaterlSeleccionado={MaterlSeleccionado}
        />
        </>
        :
        <>
        <RetirarAObra
        ObraSeleccionada={ObraSeleccionada}
        setObraSeleccionada={setObraSeleccionada}
        Cantidad={Cantidad}
        setCantidad={setCantidad}
        setError={setError}
        Error={Error}
        Empleado={Empleado}
        setEmpleado={setEmpleado}
        setAlert={setAlert}
        Obra={Obra}
        setObra={setObra}
        setMaterSeleccionado={setMaterSeleccionado}
        Materiales={Materiales}
        setMateriales={setMateriales}
        MaterialSeleccionado={MaterialSeleccionado}
        setMaterialSeleccionado={setMaterialSeleccionado}
        setModalRegistrarRetiro={setModalRegistrarRetiro}
        traerMateriales={traerMateriales}
        reset={reset}
        Obras={Obras}
        setObras={setObras}
        MaterlSeleccionado={MaterlSeleccionado}
        />
        </>
        }
        </>
        }
                       
        </Card>
        </div>
        </Modal></>
  )
}

export default ModalRegisterRetiro