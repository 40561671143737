import React from 'react'
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, Row, Table } from 'reactstrap'

const MaterialesVista = props => {
  return (
    <>
    <Container className='mt-6'>
    <Card>
        <CardHeader>
        <Row className='mb-2'>
            <Col>
            <h1>Materiales</h1>
            </Col>
            <Col>
            <Button className='btn-md btn-outline-info float-right'>ENTREGAR</Button>
            </Col>
        </Row>
        <Table>
            <thead className='bg-info'>
                <tr className='text-center'>
                    <th>NOMBRE</th>
                    <th>CODIGO PRODUCTO</th>
                    <th>CANTIDAD</th>
                    <th>ESTADO</th>
                    <th>SELECCIONAR</th>
                    <th>VER</th>
                </tr >
            </thead>
            <tbody>
                <tr className='text-center'>
                    <th>
                        Caños
                    </th>
                    <td>
                        #45884w
                    </td>
                    <td>
                        54
                    </td>
                    <td>
                        <Badge color='success'>En Cliente</Badge>
                    </td>
                    <td>
                        <label className="custom-toggle ml-6">
                            <input type="checkbox"/>
                            <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Si"
                            />
                        </label>
                    </td>
                    <td>
                        <Button className='btn-sm btn-outline-success'>Ver</Button>
                    </td>
                </tr>
                <tr className='text-center'>
                    <th>
                    Caños
                    </th>
                    <td>
                        #45884w
                    </td>
                    <td>
                    41
                    </td>
                    <td>
                    <Badge color='info'>En Empleado</Badge>
                    </td>
                    <td>
                    <label className="custom-toggle ml-6">
                            <input type="checkbox"/>
                            <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Si"
                            />
                        </label>
                    </td>
                    <td>
                    <Button className='btn-sm btn-outline-success'>Ver</Button>
                    </td>
                </tr >
                <tr className='text-center'>
                    <th>
                    Caños
                    </th>
                    <td>
                        #45884w
                    </td>
                    <td>
                        85
                    </td>
                    <td>
                    <Badge color='warning'>En Stock</Badge>
                    </td>
                    <td>
                    <label className="custom-toggle ml-6">
                            <input type="checkbox"/>
                            <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Si"
                            />
                        </label>
                    </td>
                    <td>
                    <Button className='btn-sm btn-outline-success'>Ver</Button>
                    </td>
                </tr >
                <tr className='text-center'>
                    <th>
                        Tuercas
                    </th>
                    <td>
                        #45884w
                    </td>
                    <td>
                    24
                    </td>
                    <td>
                    <Badge color='info'>En Empleado</Badge>
                    </td>
                    <td>
                    <label className="custom-toggle ml-6">
                            <input type="checkbox"/>
                            <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Si"
                            />
                        </label>
                    </td>
                    <td>
                    <Button className='btn-sm btn-outline-success'>Ver</Button>
                    </td>
                </tr >
                <tr className='text-center'>
                    <th>
                        Caños
                    </th>
                    <td>
                        #45884w
                    </td>
                    <td>
                        23
                    </td>
                    <td>
                    <Badge color='warning'>En Stock</Badge>
                    </td>
                    <td>
                    <label className="custom-toggle ml-6">
                            <input type="checkbox"/>
                            <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Si"
                            />
                        </label>
                    </td>
                    <td>
                    <Button className='btn-sm btn-outline-success'>Ver</Button>
                    </td>
                </tr>
                <tr className='text-center'>
                    <th>
                        Cables
                    </th>
                    <td>
                        #45884w
                    </td>
                    <td>
                        14
                    </td>
                    <td>
                    <Badge color='success'>En Cliente</Badge>
                    </td>
                    <td>
                    <label className="custom-toggle ml-6">
                            <input type="checkbox"/>
                            <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Si"
                            />
                        </label>
                    </td>
                    <td>
                    <Button className='btn-sm btn-outline-success'>Ver</Button>
                    </td>
                </tr >
                <tr className='text-center'>
                    <th>
                        Tornillos
                    </th>
                    <td>
                        #45884w
                    </td>
                    <td>
                        35
                    </td>
                    <td>
                    <Badge color='info'>En Empleado</Badge>
                    </td>
                    <td>
                    <label className="custom-toggle ml-6">
                            <input type="checkbox"/>
                            <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Si"
                            />
                        </label>
                    </td>
                    <td>
                    <Button className='btn-sm btn-outline-success'>Ver</Button>
                    </td>
                </tr >
                <tr className='text-center'>
                    <th>
                        Tuercas
                    </th>
                    <td>
                        #45884w
                    </td>
                    <td>
                    25
                    </td>
                    <td>
                    <Badge color='warning'>En Stock</Badge>
                    </td>
                    <td>
                    <label className="custom-toggle ml-6">
                            <input type="checkbox"/>
                            <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Si"
                            />
                        </label>
                    </td>
                    <td>
                    <Button className='btn-sm btn-outline-success'>Ver</Button>
                    </td>
                </tr >
                <tr className='text-center'>
                    <th>
                        Caños
                    </th>
                    <td>
                        #45884w
                    </td>
                    <td>
                        42
                    </td>
                    <td>
                    <Badge color='success'>En Cliente</Badge>
                    </td>
                    <td>
                    <label className="custom-toggle ml-6">
                            <input type="checkbox"/>
                            <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Si"
                            />
                        </label>
                    </td>
                    <td>
                    <Button className='btn-sm btn-outline-success'>Ver</Button>
                    </td>
                </tr >
                <tr className='text-center'>
                    <th>
                        Cables
                    </th>
                    <td>
                        #45884w
                    </td>
                    <td>
                        41
                    </td>
                    <td>
                    <Badge color='info'>En Empleado</Badge>
                    </td>
                    <td>
                    <label className="custom-toggle ml-6">
                            <input type="checkbox"/>
                            <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Si"
                            />
                        </label>
                    </td>
                    <td>
                    <Button className='btn-sm btn-outline-success'>Ver</Button>
                    </td>
                </tr >
            </tbody>
        </Table>
        </CardHeader>
        <CardBody>

        </CardBody>
    </Card>
    </Container>
    </>
  )
}

export default MaterialesVista