
import { warningAlert } from 'pages/components/SweetAlert'
import { successAlert } from 'pages/components/SweetAlert'
import { confirmAlert } from 'pages/components/SweetAlert'
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Row } from 'reactstrap'
import { postSolicitudesCambios } from 'services/PedidoCambiosService'
import SelectorValores from './components/SelectorValores'
import TableSolicitudes from './components/TableSolicitudes'
import moment from 'moment';
import NavSelector from './components/NavSelector'
import TableValores from './components/TableValores'
import { getCategoriasValores } from 'services/CategoriaValoresService'
import { getSolicitudesCambios } from 'services/PedidoCambiosService'
import { getValores } from 'services/ValoresService'


const Precios = props => {

  const [TipoMan, setTipoMan] = useState("");
  const [PrecioMan, setPrecioMan] = useState("")
  const [Alert, setAlert] = useState(null)
  const [FechaAplicacion, setFechaAplicacion] = useState("")
  const [Tabs, setTabs] = useState(1)
  const [Categorias, setCategorias] = useState([])
  const [Categoria, setCategoria] = useState("")
  const [TipoValor, setTipoValor] = useState("")

  const cambiarPrecioMantenimiento = event => {
    setPrecioMan(event.target.value);
    traerValores(TipoMan)
  };  
  const cambiarFechaAplicacion =(e) => {
   let fecha = moment(e.target.value).format('YYYY-MM-DD')
   setFechaAplicacion(fecha)
  }
  const confirmarMan = (ManId) => {
    if (validar(ManId) === true) {
      confirmAlert(setAlert, actualizarPrecioMan, ManId , 'Solicitar actualizar Precio?' )
    } else {
      warningAlert(setAlert, 'Seleccione el valor que quiere Actualizar')
    }
  }
  const validar = (arg) =>{
    if (!arg) {
      return false
    } else {
      return true
    }
  }
  const traerCategoriasValores = async() => {
    let cat = await getCategoriasValores()
    if (cat) {
      setCategorias(cat)
    }
  }
  const buscarCategoria = (id) =>{
    let devl = "-"
    Categorias.map((c)=>{
        if (c.id == id){
            devl = c.nombre
        }
})
setCategoria(devl)
    
  }
  const traerValores = async(idValor)=>{
    let soli = await getValores({id:idValor})
    if (soli) {
      setTipoValor(soli.valor?.nombre)
      buscarCategoria(soli.valor?.categoriaId)
    }
  }
  const actualizarPrecioMan = async(id)=>{
    let solicitud = {
        estado : FechaAplicacion? 1 : 3,
        tabla: "valores",
        fechaAplicacion: FechaAplicacion,
        cambios : JSON.stringify({nombre : TipoValor, importe: PrecioMan}),
        categoria : Categoria
    };
    let ev = await postSolicitudesCambios(solicitud);
        if (ev !== null && ev !=='') {
            successAlert(setAlert, "Solicitud Realizada!", "");     
            setTimeout(()=>{
              window.location.reload()
            }, 1300)

        }else{
            warningAlert(setAlert,'No se pudo realizar solicitud')
        }
        
    }
useEffect(() => {
  traerCategoriasValores()
}, [])
  return (
    <>
    {Alert}
    <Container className='text-center mt-9'>
    <NavSelector Tabs={Tabs} setTabs={setTabs}/>
   
        {Tabs === 1 ? 
         <Card>
         <CardHeader>
          <h1 className='mt-4 mb-4'>Actualizacion de Precios</h1>
           <Row className='mb-4 mr-4'>
             <Col><h3>Solicitud:</h3></Col>
             <Col><SelectorValores valor={TipoMan} cambiarValor={setTipoMan}/></Col>
             <Col>
               <Input 
                 placeholder='Nuevo Precio...'
                 value={PrecioMan}
                 onChange={cambiarPrecioMantenimiento}
                 type="number"
                 />
             </Col>
             <Col>
               <Input 
               type="date"
               value={FechaAplicacion}
               onChange={cambiarFechaAplicacion}
               />
             </Col>
             {PrecioMan && TipoMan?
             <Col><Button className='btn btn-outline-success' onClick={()=>confirmarMan(TipoMan)}>SOLICITAR</Button></Col>
             :
             <></>}
           </Row>
         </CardHeader>
         <CardBody>
           <Row className='mb-2'>
               <Col></Col>
               <Col></Col>
               
           </Row>
           
            <TableSolicitudes />
            </CardBody>
          </Card>
   
            :
            <>
            <TableValores Categorias={Categorias}/>
            </>
            }

</Container>
    </>
  )
}
export default Precios