import { urlGlobal2 } from './GlobalService';

export async function getCambiosEq(params=null){
    try {
      let url = new URL(`${urlGlobal2}/cambiosEquipos`)
      if (params != null) {
        url.search = new URLSearchParams(params).toString();
      }
      let token = localStorage.getItem('token');
      let method = 'GET';
      let headers = {'Content-Type':'application/json','token':token};
      let equipos = null;
      const resp = await fetch(url, {method,headers});
      const data = await resp.json();
      return data;    
    } catch (error) {
      alert(error)
    }
  }
  export const getNoAsignados = async(params=null) =>{
    let url = new URL(`${urlGlobal2}/servicios/noAsignados`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
        
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}
export const getAccessPoint = async(params=null) =>{
  let url = new URL(`${urlGlobal2}/ap`)
  if (params != null) {
      url.search = new URLSearchParams(params).toString();
      
  }
  try{
      let token = localStorage.getItem('token');
      let headers = {'Content-Type':'application/json', token}
      let method = 'GET';
      let resp = await fetch(url,{method, headers});
      let data = await resp.json();
      return data;
  }
  catch(error){
      alert(error.message);
      return null;
  }
}
export async function getIp(params=null){
  try {
    let url = new URL(`${urlGlobal2}/ap/mac`)
    if (params != null) {
      url.search = new URLSearchParams(params).toString();
    }
    let token = localStorage.getItem('token');
    let method = 'GET';
    let headers = {'Content-Type':'application/json','token':token};
    const resp = await fetch(url, {method,headers});
    const data = await resp.json();
    return data;    
  } catch (error) {
    alert(error)
  }
}

export async function postCambioEquipos(cme){
  try {
    
    let url = new URL(`${urlGlobal2}/cambiosequipos`)
    let token = localStorage.getItem('token');
    let method = 'POST';
    let body = JSON.stringify(cme)
    let headers = {'Content-Type':'application/json','token':token};
    const resp = await fetch(url, {method,headers,body});
    const data = await resp.json();
    return data;    
    
  } catch (error) {
    alert(error)
    return null
  }
}

export async function deleteCambio(id){
  let url = `${urlGlobal2}/cambiosequipos/${id}`;
  try {
      let token = localStorage.getItem('token');
      
      let headers = {'Content-Type':'application/json','token':token}
      let method= 'DELETE';
      const resp = await fetch(url, {headers, method});
      const data = await resp.json()
      return data;
  } catch (error) {
      
      return null;
  }
}