// ------- Servidor Prod -------
 export const urlGlobal = 'https://apiv2.globalnet.net.ar/api';
 export const urlGlobal2 = 'https://apiv2.globalnet.net.ar/api';

// ------- Servidor Test -------
 //export const urlGlobal = 'http://10.50.15.250:3003/api';
 //export const urlGlobal2 = 'http://10.50.15.250:3006/api';
 //export const urlGlobal = 'http://10.50.15.250:3006/api';

// ------- Servidor Local api1-------
// export const urlGlobal = 'http://localhost:3002/api';
// ------- Servidor Local api2-------
// export const urlGlobal = 'http://localhost:3006/api';
// export const urlGlobal2 = 'http://localhost:3006/api';

// -------- Api LAN----------------
// export const urlGlobal = 'http://192.168.0.37:3006/api';
// export const urlGlobal2 = 'http://192.168.0.37:3006/api';
// ------------------------------
//export const urlGlobal = 'http://54.212.66.56:3002/api';