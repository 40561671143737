import React, { useState } from 'react'
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap'
import SelectorCategoriaValor from './SelectorCategoriaValor';
import { postValores } from 'services/ValoresService';

const ModalAggValores = props => {
    const { ModalAgregarValores, setModalAgregarValores, traerValores, Categorias} = props;
    const [TipoValor, setTipoValor] = useState("");
    const [Nombre, setNombre] = useState("");
    const [Importe, setImporte] = useState("")
    const [Alert, setAlert] = useState(null)
    const [CategoriaId, setCategoriaId] = useState("")
    const cambiarImporte = (e) => {
        setImporte(e.target.value)
    }
    const cambiarNombre =(e) => {
        setNombre(e.target.value)
    }
    // const buscarCategoria = (nombre) =>{
    //     let devl = "-"
    //     Categorias.map((c)=>{
    //         if (c.nombre === nombre){
    //             devl = c.id
    //         }
    // })
    // setCategoriaId(devl);
    //   }
    const validar = (o) => {
        if (!o.nombre) {
            return false
        }
        if (!o.importe) {
            return false
        }
        if (!o.categoriaId) {
            return false
        }
        return true
    }
    // const establecerCategoria = ()=>{
    //     buscarCategoria(TipoValor)
    //     Agregar()
    // }
    const Agregar = async() => {
        let obj = {
            nombre : Nombre,
            importe : parseInt(Importe),
            categoriaId:parseInt(CategoriaId)
        }
        if (validar(obj)===true) {
            let valor = await postValores(obj)
            if (valor !== null) {
                successAlert(setAlert,"Valor Agregado")
                setImporte("")
                setNombre("")
                setTipoValor("")
                setModalAgregarValores(false)
                traerValores()
            } else {
                warningAlert(setAlert, "Error al agregar el Valor")
            }
            
        } else {
            warningAlert(setAlert, "Complete todos los campos!")
        }
    }
    const reset = ()=>{
        setImporte("")
        setNombre("")
        setTipoValor("")
        setCategoriaId("")
        setModalAgregarValores(false)
    }

  return (
    <>
            {Alert}
        <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={ModalAgregarValores}
        toggle={() => reset()}
        >
        <div>
        <Card>
            <CardHeader className="pb-3">
            <div className="text-center"><b>Agregar Valor</b>
            <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => reset()}
        >
            <span aria-hidden={true}>×</span>
        </button>
            </div>
            </CardHeader>  
            <CardBody>
            <div className=" mt-5 mb-3">
            <Form role="form">
            <FormGroup>
                <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Nombre:
                </label>
                <Input
                    type="text"
                    value={Nombre} 
                    onChange={cambiarNombre}
                    />
            </FormGroup>
            <FormGroup>
                <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Importe:
                </label>
                <Input
                    type="number"
                    value={Importe} 
                    onChange={cambiarImporte}
                    />
            </FormGroup>
            <FormGroup>
                <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Nombre:
                </label>
                <SelectorCategoriaValor valor={TipoValor} CambiarCatValor={setTipoValor} setCategoriaId={setCategoriaId}/>
            </FormGroup>
            </Form>                     
            </div>                    
            </CardBody>
            <CardFooter>
                <div className="text-center">
                <Button
                    className=""
                    color="primary"
                    type="button"
                    onClick={()=>Agregar()}
                    
                    >
                    Agregar
                </Button>
            
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => reset()}
                >
                    Cerrar
                </Button>                    
                </div>
                </CardFooter>                
        </Card>
        </div>
        </Modal>
    </>
  )
}

export default ModalAggValores