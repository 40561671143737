import React from 'react'
import { Badge, Button } from 'reactstrap'


function EtiquetaEstadoServ(props){
    const {Estado} = props
    if(Estado == 1){
        return(
            <Button className="btn-sm btn-success"> Activo
            </Button>
        )
    }else if(Estado == 3){
        return(
            <Button className="btn-sm btn-danger"> Inactivo
            </Button>
        )
    }else{
        return <></>
    }
}

export default EtiquetaEstadoServ;