import React from "react";
import { Input } from "reactstrap";

function SelectorServiciosAsignados(props){
    const {Asignado, setAsignado} = props;
    
    const cambiarAsignado = event => {
        setAsignado(event.target.value);
    };
    return(
        <>
                    <Input id="exampleFormControlSelect1" type="select" 
                        value={Asignado}
                        onChange={cambiarAsignado}
                    >
                        <option value={''}>Estado de Asignacion...</option>
                        <option value={true}>ASIGNADOS</option>
                        <option value={false}>NO ASIGNADOS</option>
                        
                    </Input>
        </>
    );
};

export default SelectorServiciosAsignados;