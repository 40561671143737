import React, { useEffect, useState } from 'react'
import { GuardInPages } from 'guards/Guard'
import { useHistory } from 'react-router'
import { Card, CardImg } from 'reactstrap'
import BienvenidoComponent from 'pages/components/Bienvenido'

export default function Home() {
    const history = useHistory()

    const [BienvenidoVisible, setBienvenidoVisible] = useState(true)

    const bienvenidoF = () => {
        setBienvenidoVisible(true)
        setTimeout(()=>{setBienvenidoVisible(false)}, 2000);
    }

    useEffect(() => {
        GuardInPages(history);
        bienvenidoF();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <BienvenidoComponent Visible={BienvenidoVisible}/>
            <Card>
                <CardImg
                alt="..."
                src={require("assets/img/home.jpg").default}
                top
                style={{height:"100%"}}
                />
                {/* <Row>
                    <Col></Col>
                    <Col>
                        <PropagateLoader color={"#0070C2"} loading={true} size={30}/>
                    </Col>
                    <Col></Col>
                </Row> */}
            </Card>
        </>
    )
}
