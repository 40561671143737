import LoadingComponent from 'pages/components/Loading'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Button, Row, Table } from 'reactstrap'
import { getTiposMaterialesObras } from 'services/TiposMaterialesService'

const TableMaterialesObra = ({ idObra, nombreObra }) => {
    const [Obra, setObra] = useState("")
    const history = useHistory()
    const [Visible, setVisible] = useState(false)

    const traerObra = async () => {
        setVisible(true)
        let obra = await getTiposMaterialesObras(null, idObra)
        if (obra) {
            setObra(obra.tmo)
        }
        setVisible(false)
    }

    const irAtras = () => {
        history.replace(`/admin/obras`)
    }

    useEffect(() => {
        traerObra()
    }, [])

    return (
        <>
            <LoadingComponent Visible={Visible} />
            <Table responsive>
                <thead >
                    <tr>
                        <th>
                            <Row>
                                <Button className='btn btn-outline-default btn md mx-3' onClick={() => irAtras()}>Atras</Button><h1>Materiales en Obra: {nombreObra}</h1>
                            </Row>
                        </th>
                    </tr>
                </thead>
                <thead className='text-center bg-info'>
                    <tr>
                        <th>NOMBRE</th>
                        <th>ID</th>
                        <th>CODIGO PRODUCTO</th>
                        <th>CANTIDAD</th>
                        <th>UNIDAD</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {Obra.length ?
                        Obra.map(o => {
                            return (
                                <tr key={o.idTipoMaterial}>
                                    <th>{o.tiposMateriale?.nombre}</th>
                                    <td>{o.idTipoMaterial}</td>
                                    <td>{o.tiposMateriale?.codigoProducto}</td>
                                    <td>{o.cantidad}</td>
                                    <td>{o.tiposMateriale?.unidad}</td>
                                </tr>
                            )
                        })
                        :
                        <>
                            <tr>
                                <td>
                                    Esta obra no tiene materiales asignados!
                                </td>
                            </tr>
                        </>
                    }
                </tbody>
            </Table>
        </>
    )
}

export default TableMaterialesObra