import React from 'react'
import { Badge } from 'reactstrap'


function EtiquetaEstadoFacturas(props){
    const {Estado} = props
    if(Estado == 1){
        return(
            <Badge className="badge-md" color="danger">FALTA PAGO</Badge>
        )
    }else if(Estado == 3){
        return(
            <Badge className="badge-md" color="success">PAGO</Badge>
        )
    }else{
        return <></>
    }
}

export default EtiquetaEstadoFacturas;