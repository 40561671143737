import React, { useState } from 'react'
import { Card, Container } from 'reactstrap';
import TableEquiposExcel from './components/TableEquiposExcel';

const EquiposExcel = props => {
  const [EquiposSeleccionados, setEquiposSeleccionados] = useState([]);
  const [ModalAgregarEquipo, setModalAgregarEquipo] = useState(false);
  
  return (
    <>
    <Container>
      <Card className='mt-6'>    
        <TableEquiposExcel
        EquiposSeleccionados={EquiposSeleccionados}
        setEquiposSeleccionados={setEquiposSeleccionados}
        ModalAgregarEquipo={ModalAgregarEquipo}
        setModalAgregarEquipo={setModalAgregarEquipo}
        />
      </Card>
    </Container>
    </>
  )
}


export default EquiposExcel