import SelectorServicios from 'pages/components/SelectorServicios';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react'
import { useHistory } from 'react-router';
import { Button, Card, CardBody, CardHeader, Form, FormGroup, Modal } from 'reactstrap'

const ModalSelecServicio = props => {

    const {ModalSeleccionarServicio, setModalSeleccionarServicio, planCliente, idClient}=props;
    const [Servicio, setServicio] = useState("");
    const history = useHistory ();

    const irAgenda=()=>{

        history.replace({
            pathname: '/admin/agenda',
            state:{
                idCliente: idClient,
                idServicio: Servicio
            }
        })
    }


  return (
    <>
        <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={ModalSeleccionarServicio}
            toggle={() => setModalSeleccionarServicio(false)}
            >
            
            <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalSeleccionarServicio(false)}
                >
                    <span aria-hidden={true}>×</span>
                </button>
                <div className="text-center"><b>Seleccionar Servicio</b>
                
                </div>
                </CardHeader>  
                <CardBody>
                <div className="text-muted mt-2 mb-3">
                <Form role="form">
                <FormGroup>
                    <label>Servicios:</label>
                    <SelectorServicios Valor={Servicio} setValor={setServicio} arrayElementos={planCliente}/>
                </FormGroup>

                    <div className="text-center">
                    <Button
                        className="my-4"
                        color="primary"
                        type="button"
                        onClick={()=>{irAgenda()}}
                    >
                        Seleccionar
                    </Button>
                
                    <Button
                        color="secondary"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModalSeleccionarServicio(false)}
                    >
                        Cerrar
                    </Button>                    
                    </div>
                </Form>                     
                </div>                    
                </CardBody>              
            </Card>
            
        </Modal>
    </>
  )
}

export default ModalSelecServicio