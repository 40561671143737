import { successAlert } from 'pages/components/SweetAlert';
import { warningAlert } from 'pages/components/SweetAlert';
import { confirmAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react'
import { Button, CardBody, CardHeader, Modal, Table } from 'reactstrap';
import { postEquiposMany } from 'services/EquiposService';
import { validarCaracteresMac } from 'validators/validators-equipos';

export const ModalAgregarEquiposSistema = (props) => {
    const {EquiposSeleccionados, setEquiposSeleccionados, ModalAggSistema, setModalAggSistema} = props;
    const [Alert, setAlert] = useState(null)


    const confirmar = (EquiposSelect)=>{
        let Novalido = EquiposSelect.find(es => validarCaracteresMac(es.macAddress).ok ===  false)
        if (Novalido) {
            warningAlert(setAlert, 'Una de las Macs no tiene el formato correcto!')
        } else {
            confirmAlert(setAlert, agregarEquipos, null, "Agregar los Equipos al sistema?")
        }
      }
    
   const agregarEquipos= async() => {
    let equips = {equipos: EquiposSeleccionados}
    let equipos = await postEquiposMany(equips)
    if (equipos) {
        successAlert(setAlert, 'Equipos agregados al sistema correctamente')
        setEquiposSeleccionados([])
        setModalAggSistema(false)
    } else {
        warningAlert(setAlert, 'Error al agregar los equipos al sistema')
    }
   }
  return (
    <>
    {Alert}
    <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={ModalAggSistema}
        toggle={() => setModalAggSistema(false)}
        >
        <div className="modal-body p-0">
            <CardHeader>
            <div className="text-center"><b>Equipos Seleccionados</b>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalAggSistema(false)}
                >
                <span aria-hidden={true}>×</span>
            </button>
            </div>
            </CardHeader>
            <CardBody>
                <Table>
                    <thead>
                        <tr className='text-center'>
                            <th>Mac</th>
                            <th>Categoria</th>
                            <th>EmpleadoId</th>
                        </tr>
                    </thead>
                    <tbody>
                        {EquiposSeleccionados?
                        EquiposSeleccionados.map((es, index)=>{
                            return(
                                <tr key={index} className='text-center'>
                                    <th>{es.macAddress}</th>
                                    <td>{es.categoriaId}</td>
                                    <td>{es.empleadoId}</td>
                                </tr>
                            )
                        })
                    :<>-</>}
                    </tbody>
                </Table>                    
            </CardBody>
                <div className="text-center">
                <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={()=>confirmar(EquiposSeleccionados)}
                    >
                    Agregar al Sistema
                </Button>
            
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => { setModalAggSistema(false)}}
                >
                    Cancelar
                </Button>                    
                </div>
            </div>                    
        </Modal>
    </>
  )
}
