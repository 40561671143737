import React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Row, Table } from 'reactstrap'

const Tendidos = props => {
  return (
    <>
    <Container className='mt-6'>
    <Card>
        <CardHeader>
        <Row className='mb-2'>
            <Col>
            <h1>Tendidos</h1>
            </Col>
            <Col>
            <Button className='btn-md btn-outline-info float-right'>Agregar Tendido</Button>
            </Col>
        </Row>
        <Table>
            <thead className='bg-info'>
                <tr className='text-center'>
                    <th>ID</th>
                    <th>TENDIDO</th>
                    <th>CODIGO</th>
                </tr >
            </thead>
            <tbody>
                <tr className='text-center'>
                    <th>
                        1
                    </th>
                    <td>
                        aaabbbccc
                    </td>
                    <td>
                    #45884w
                    </td>
                </tr>
                <tr className='text-center'>
                    <th>
                        2
                    </th>
                    <td>
                    aaabbbccc
                    </td>
                    <td>
                    #45884w
                    </td>
                </tr >
                <tr className='text-center'>
                    <th>
                        3
                    </th>
                    <td>
                    aaabbbccc
                    </td>
                    <td>
                    #45884w
                    </td>
                </tr >
                <tr className='text-center'>
                    <th>
                        4
                    </th>
                    <td>
                    aaabbbccc
                    </td>
                    <td>
                    #45884w
                    </td>
                </tr >
                <tr className='text-center'>
                    <th>
                        5
                    </th>
                    <td>
                    aaabbbccc
                    </td>
                    <td>
                    #45884w
                    </td>
                </tr>
                <tr className='text-center'>
                    <th>
                        6
                    </th>
                    <td>
                    aaabbbccc
                    </td>
                    <td>
                    #45884w
                    </td>
                </tr >
                <tr className='text-center'>
                    <th>
                        7
                    </th>
                    <td>
                    aaabbbccc
                    </td>
                    <td>
                    #45884w
                    </td>
                </tr >
                <tr className='text-center'>
                    <th>
                        8
                    </th>
                    <td>
                    aaabbbccc
                    </td>
                    <td>
                    #45884w
                    </td>
                </tr >
                <tr className='text-center'>
                    <th>
                        9
                    </th>
                    <td>
                    aaabbbccc
                    </td>
                    <td>
                    #45884w
                    </td>
                </tr >
                <tr className='text-center'>
                    <th>
                        10
                    </th>
                    <td>
                    aaabbbccc
                    </td>
                    <td>
                    #45884w
                    </td>
                </tr >
            </tbody>
        </Table>
        </CardHeader>
        <CardBody>

        </CardBody>
    </Card>
    </Container>
    </>
  )
}

export default Tendidos