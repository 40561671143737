import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap'
import { putTipoMateriales } from 'services/TiposMaterialesService';

const ModalEditMaterial = ({MaterialSeleccionado, ModalEditarMaterial, setModalEditarMaterial, traerMateriales}) => {
    const [Alert, setAlert] = useState(null);
    const [Nombre, setNombre] = useState("");
    const [Codigo, setCodigo] = useState("");
    const [Minimo, setMinimo] = useState("");
    const [Unidad, setUnidad] = useState("");
    const [MaterialId, setMaterialId] = useState("")

    
    const cambiarNombre = (e)=> {
        setNombre(e.target.value)
    }
    const cambiarCodigo = (e)=> {
        setCodigo(e.target.value)
    }
    
    const cambiarUnidad = (e)=> {
        setUnidad(e.target.value)
    }
    const establecerTipo=()=>{
        setNombre(MaterialSeleccionado.tiposMateriale.nombre);
        setCodigo(MaterialSeleccionado.tiposMateriale.codigoProducto)
        setMinimo(MaterialSeleccionado.stockMinimo)
        setUnidad(MaterialSeleccionado.tiposMateriale.unidad)
        setMaterialId(MaterialSeleccionado.idTipoMaterial)
    }
    const editar = async()=> {
        let material = {
            id: parseInt(MaterialId),
            nombre: Nombre,
            codigoProducto: Codigo,
            unidad: Unidad
        }
        let editar = await putTipoMateriales(material)
        if (editar) {
            successAlert(setAlert,"Tipo de Material Actualizado!")
            reset()
            traerMateriales()
        }
    }
    const reset =()=>{
        setNombre("");
        setCodigo("");
        setUnidad("")
        setMaterialId("")
        setModalEditarMaterial(false)
    }
    useEffect(() => {
        establecerTipo()
    }, [ModalEditarMaterial])
    
  return (
    <>
    {Alert}
        <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={ModalEditarMaterial}
        toggle={() => reset()}
        >
        <div>
        <Card>
            <CardHeader className="pb-3">
            <div className="text-center"><b>Editar Tipo Material</b>
            <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => reset()}
        >
            <span aria-hidden={true}>×</span>
        </button>
            </div>
            </CardHeader>  
            <CardBody>
            <div className=" mt-5 mb-3">
            <Form role="form">
            <FormGroup>
                <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Nombre:
                </label>
                <Input
                    type="text"
                    value={Nombre}
                    onChange={cambiarNombre}
                    />
            </FormGroup>
            <FormGroup>
                <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Codigo:
                </label>
                <Input
                    type="text"
                    value={Codigo}
                    onChange={cambiarCodigo}
                    />
            </FormGroup>
            <FormGroup>
                <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Unidad:
                </label>
                <Input
                    type="text"
                    value={Unidad}
                    onChange={cambiarUnidad}
                    />
            </FormGroup>
            </Form>                     
            </div>                    
            </CardBody>
            <CardFooter>
                <div className="text-center">
                <Button
                    className=""
                    color="primary"
                    type="button"
                    onClick={()=>editar()}
                    
                    >
                    Actualizar
                </Button>
            
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => reset()}
                >
                    Cancelar
                </Button>                    
                </div>
                </CardFooter>                
        </Card>
        </div>
        </Modal>
    </>
  )
}

export default ModalEditMaterial