import React from "react";
import { Container, Row } from "reactstrap";


function PagosSueldos(){

    return (
        <>
        <Container className="mt-5" fluid>
              <Row className="mt-5">
                <h2>Sueldosssss</h2>
              </Row>
        </Container>
        </>
    )
}


export default PagosSueldos;