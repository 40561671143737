import React, { useEffect, useState } from 'react'
import {Button} from "reactstrap";
import {warningAlert, successAlert, confirmAlert} from "../../../components/SweetAlert"
import {GetAuthUser} from "../../../../services/AuthService";

export default function ButtonGroups(props) {
  
  
  const {setModalEntregarEquipos,setModalRecibirEquipos,entregandoEquipos,setEntregandoEquipos,equipos,empleadoId} = props;
  const [Alert, setAlert] = useState(null);
  const [authUser,setAuthUser] = useState({});
  
  
  const comenzarEntregar = () => {
    if(equipos.length === 0 || entregandoEquipos){
        if(!entregandoEquipos){
            setEntregandoEquipos(true);
        }

        setModalEntregarEquipos(true);
    }
    else{
        warningAlert(setAlert,"Este empleado tiene equipos asignados, antes de asignarles nuevos debes quitarle los que tiene");
    }


  }

  const VerBotones = () => {
        if(authUser.empleadoId !== parseInt(empleadoId)){
        
            return true;
        }
        else{

            return false
        }
         
  }



  useEffect(() => {
     
      GetAuthUser().then(user => {
       
        setAuthUser(user.usuario);
      })

  },[])



  return (
    <>
    {Alert}
                      {
                            VerBotones()?

                              <div className="d-flex align-items-center">
                              {
                                  entregandoEquipos?
                                  <div>
                                  <Button className="btn-icon btn-3 btn-md btn-success mr-6" color="primary" type="button" onClick={()=>comenzarEntregar()}>
                                  
                                      <span className="btn-inner--icon">
                                      Entregar Equipos
                                      </span>
                                  </Button>
                                
                                  </div>
      
      
                                  :
      
                                  <div>
                                  <Button className="btn-icon btn-3 btn-md btn-outline-info mr-6" color="primary" type="button" onClick={()=>comenzarEntregar()}>
                                  
                                      <span className="btn-inner--icon">
                                      Entregar Equipos
                                      </span>
                                  </Button>
                                
                                </div>
      
                              }
                              
      
                                <div>
                                  <Button className="btn-icon btn-3 btn-md btn-outline-info mr-6" color="primary" type="button" onClick={()=>setModalRecibirEquipos(true)}>
                                  
                                      <span className="btn-inner--icon">
                                      Recibir Equipos
                                      </span>
                                  </Button>
                                
                                </div>
      
                           
                              </div>

                              :null




                      }
                    
        
    
    </>
  )
}
