import React, { useEffect, useState } from 'react'
import { Button, Modal, Table } from 'reactstrap'


export default function ModalNoAgregados(props){
    const {NoAgregadosModal, setNoAgregadosModal, EquiposErrorAgg} = props


    useEffect(() => {
    }, [])

    return(
        <Modal
        className="modal-dialog-centered"
        isOpen={NoAgregadosModal}
        toggle={() => setNoAgregadosModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Equipos no agregados:
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setNoAgregadosModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {EquiposErrorAgg.length == 0?
          <></>
          :
          <Table className="align-items-center table-flush" hover responsive>
            <thead className="thead-light">
              <tr>
                <th>Controlado</th>
                <th>Equipos</th>
                <th>Errores</th>
              </tr>
            </thead>
            <tbody>
              {EquiposErrorAgg.map((eq, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <label className="custom-toggle">
                        <input type="checkbox" />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="No"
                          data-label-on="Si"
                        />
                      </label>
                    </td>
                    <td className="table-user">
                      <b>{eq.mac}</b>
                    </td>
                    <td className="table-user">
                      <b>{eq.aviso}</b>
                    </td>
                  </tr>

                )

              })}
              
            </tbody>
          </Table>
          }
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setNoAgregadosModal(false)}
          >
            Cerrar
          </Button>
        </div>
      </Modal>
    )
}