export function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  

export function validateUser(tkn) {
  if (tkn !== undefined && tkn !== null) {

    return true;
      //navigation.navigate('Agregar Cliente');
  } else {

    return false;
      //alert("Usuario o contraseña incorrectos");
  }
}