import React , { useEffect, useState } from "react";
import { updateFiscal,ReconectarCliente} from "services/ClientesServices";
import { Button, Card, CardHeader, CardBody, Input, Container, Row, Col, Badge, UncontrolledTooltip } from "reactstrap";
import { useHistory } from "react-router";
import '../../../../assets/estilos/style.css';
import LoadingComponent from "pages/components/Loading";
import { successAlert } from "pages/components/SweetAlert";
import { warningAlert } from "pages/components/SweetAlert";
import ModalCambiarContra from "../components/ModalCambiarContra";
import { confirmAlert } from "pages/components/SweetAlert";
import { putEliminarServicio } from "services/ServiciosServices";
import ModalEditarClt from "../components/ModalEditarCliente";
import ModalHacerFiscal from "../components/ModalHacerFiscal";
import { getRolPermisos } from "services/RolService";
import moment from "moment";
import ModalSelecServicio from "../components/ModalSelecServicio";
import ModalAggServicios from "../components/ModalAggServicios";

function General(props){
    //lo asignamos tal cual viene en ContenedorSubvistas
  const{datos} = props;
  const{planCliente} = props;
  const{idClient} = props;

  const [loading, setLoading] = useState(false);
  const [ModalEditarCliente,setModalEditarCliente] = useState(false);
  const [ModalCambiarContraseña, setModalCambiarContraseña] = useState(false);
  const [ModalFicalVisible,SetModalFiscalVisible] = useState(false);
  const [ModalSeleccionarServicio, setModalSeleccionarServicio] = useState(false);
  const [ModalAgregarServicio, setModalAgregarServicio] = useState(false)
  const [Permisos, setPermisos] = useState([]);
  
  const history = useHistory ();
  const [alertSweet, setAlert] = useState(null); 

  const irServicios = (idServicio)=>{
    history.replace(`/admin/serviciosCliente/${idClient}/${idServicio}`)

  }

    const EstadoServicio =(serv)=>{
        if(serv.suspensionReasonId == null){
            return true;
          }
          else{
            return false;
          }

    }


      const renderizarValor=(unValor)=>{
        if(unValor!=null || unValor!=undefined)
        {
          return unValor;
        }
        else{
          let valor='No definido';
          return valor;
        }
      }

  

    const confirmRecinectar= async()=>{
        confirmAlert(setAlert, GenerarReconexionCliente,1 ,"Deseas reconectar este cliente?")
    }


    const GenerarReconexionCliente = async() => {
        try{
            let data = await ReconectarCliente(idClient);
            if(data.ok){
                successAlert(setAlert,data.mensaje, "")
            }
            else{
                warningAlert(setAlert,data.mensaje)
            }


        }catch(error){
            warningAlert(setAlert,error.message)
        }
    }




      const confirmarEliminar= async(id)=>{
        confirmAlert(setAlert, eliminarServicio, id , "Deseas eliminar este Servicio?")
      }
      const eliminarServicio = async(id)=>{
        
      let result = await putEliminarServicio(id)
      if(result != null){
              successAlert(setAlert, "Servicio Eliminado!", "")
              
              return
    }else{
        warningAlert(setAlert,'No se pudo eliminar el Servicio')
    }
    };
    const traerPermisos= async()=>{

        let permisos= await getRolPermisos();
        if(permisos !=null && permisos!=''){
          setPermisos(permisos)
        }
      }
    const tienePermiso=(nombrePermiso)=>{
    return Permisos.includes(nombrePermiso);
    }


    const findAttribute = (key,attributes) => {
        let a = "";   
        if(attributes && attributes.length > 0){
            attributes.map(at => {
                if(at.key === key){a=at.value}
            })    
        }
        
        return a;
    }
    const TieneDeuda=(id)=>{
    if (datos.accountBalance<0) {
        warningAlert(setAlert, "Solicitud de Mantenimiento Rechazada", "Este cliente tiene una deuda pendiente")
        
    } else {
        setModalSeleccionarServicio(true)
    }
    }

    useEffect(() => {
        traerPermisos();
      }, []) 

    return (
        <>
        {alertSweet}
        <LoadingComponent Visible={loading}/>
        <div className="mt-5">
        <Row>
          <Col md="7" sm="12">
               <Container >
                   <Row>
                       <Card className="cardGeneral">
                       <CardBody>
                           {/* SALDOS */}
                           <Row>
                           <Col className="cardClienteEsp" lg="5" md="5" sm="12" >
                               <h5 className="card-title text-muted mb-0">SALDO DE LA CUENTA</h5>
                               <span className=" h1 font-weight-bold mb-0"> $ {datos?.accountBalance}</span>
                           </Col>
                           <Col className="cardClienteEsp" lg="3" md="3" sm="12" >
                               <h5 className="card-title text-muted mb-0">CREDITO</h5>
                               <span className=" h2 font-weight-bold mb-0"> $ {datos?.accountCredit}</span>
                           </Col>
                           <Col className="" lg="4" md="4" sm="12" >
                               <h5 className="card-title text-muted mb-0">PENDIENTES</h5>
                               <span className=" h2 font-weight-bold mb-0 colorRojo"> $ {datos?.accountOutstanding}</span>
                           </Col>
                           </Row>
                           </CardBody>
                       </Card>
                   </Row>

                    {/* PAGOS */}
                   <Row>
                       <Card className="cardGeneral">
                       <CardBody>
                           {planCliente?
                            planCliente.map((planCt, index)=>{
                                return(
                                <Row index={index}>
                                <Col className="" lg="2" md="2" sm="12" >
                                    <h5 className="card-title text-muted mb-0"><i className="ni ni-credit-card"></i> PAGO ESPERADO</h5>
                                </Col>
                                <Col className="cardClienteEsp" lg="4" md="4" sm="12" >
                                    <span className=" h3 font-weight-bold mb-0"> $ {renderizarValor(planCt.totalPrice)}/mes</span>
                                </Col>

                                <Col className="" lg="3" md="3" sm="12" >
                                    <h5 className="card-title text-muted mb-0">ACTIVO DESDE</h5>
                                </Col>
                                <Col className="" lg="3" md="3" sm="12" >
                                <span className=" h3 font-weight-bold mb-0"> {moment(planCt.activeFrom).format('DD/MM/YYYY')}</span>
                                </Col>
                                </Row>
                                )                                
                            })
                           :
                            <></>
                           }

                           </CardBody>
                       </Card>
                   </Row>

                    {/* SERVICIOS */}
                    <Row>
                        <Card className="cardGeneral">
                        <CardHeader>
                           <Row>
                               <Col>
                               <h5 className="card-title text-muted mb-0 mt-2" ><i className="ni ni-credit-card"></i> SERVICIOS</h5>
                               </Col>


                               <Col className="justify-content-center" lg="2" md="2" sm="12" >
                                    <Button type="button" 
                                    color="success" 
                                    className="mt-2"  
                                    onClick={()=>confirmRecinectar()}
                                    >
                                        Reconectar
                                    </Button> 
                                   
                              
                            </Col>
                            <Col className="justify-content-center" lg="2" md="2" sm="12" >
                            <Button type="button" 
                                    color="success" 
                                    className="mt-2 align-items-center"  
                                    onClick={()=>setModalAgregarServicio(true)}
                                    >
                                        <i className="fas fa-plus"></i>
                                    </Button> 
                            </Col>
                            </Row> 
                        </CardHeader>
                        <CardBody >
                                    {planCliente?
                                        planCliente.map((planCt, index)=>{
                                            return(
                            <Row>
                            <Col className="" lg="10" md="10" sm="12">
                                    

                                                                <Row className="mt-2" index={index} onClick={()=>irServicios(planCt.id)}>
                                                                    <Col className="d-flex align-items-center" lg="1" md="1" sm="12" >
                                                                        <span className="text-success"> ● </span>
                                                                    </Col>
                                                                    <Col className="d-flex align-items-center" lg="9" md="9" sm="12" >
                                                                        <Row>
                                                                            <div className="col-auto d-flex align-items-center">
                                                                                <span className=" h3 font-weight-bold mb-0"> {renderizarValor(planCt?.servicePlanName)}</span>
                                                                            </div>
                                                                            <div className="col-auto d-flex align-items-center">
                                                                                <h5 className="card-title text-muted mb-0">-1 MES</h5>
                                                                            </div>
                                                                        </Row>
                                                                        <Row className="mt-1">
                                                                            <Col className="d-flex align-items-center cardClienteEsp" lg="6" md="6" sm="12" >
                                                                                <h5 className="card-title text-muted mb-0">PLAN DE SERVICIO</h5>
                                                                            </Col>
                                                                            <Col className="d-flex align-items-center" lg="6" md="6" sm="12" >
                                                                                <span className="h5 font-weight-bold mb-0"> CONECTADO A: {datos?.firstName}, {datos?.lastName}</span>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col className="d-flex align-items-center" lg="2" md="2" sm="12" >
                                                                        <span className="h2 font-weight-bold mb-0"> {renderizarValor(planCt?.totalPrice)}</span>
                                                                    </Col>

                                                                    {/* Verificamos si el servicio esta activo */}
                                                                    <Col className="d-flex align-items-center" lg="1" md="1" sm="12" ></Col>
                                                                    
                                                                    <Col className="d-flex align-items-center" lg="4" md="4" sm="12" >
                                                                    {
                                                                        EstadoServicio(planCt)?
                                                                        <Badge className="badge-lg" color="success">Servicio Activo</Badge>
                                                                        :
                                                                        <Badge className="badge-lg" color="danger">Servicio Suspendido</Badge>
                                                                    }
                                                                    </Col>
                                                                    
                                                                    
                                                                    
                                                                </Row>
                                                            
                                                            

                            </Col>
                            <Col>
                            <Button className="btn-icon btn-3 btn-sm btn-danger mt-3 ml-2"  type="button" onClick={()=>{confirmarEliminar(planCt.id)}} id="tooltip611234743">
                              <span className="btn-inner--icon">
                              <i className="ni ni-fat-remove"/>
                              </span>
                          </Button>
                              <UncontrolledTooltip
                                delay={0}
                                placement="bottom"
                                target="tooltip611234743"
                                >
                                Eliminar Servicio
                                </UncontrolledTooltip>
                              
                            </Col>
                            
                            
                            </Row>
                             )
                            })
                            :
                            <></>
                            }

                            </CardBody>
                        </Card>
                    </Row>
               </Container>
          </Col>

          <Col lg="5" md="5" sm="12">
            <Container className="container-fluid">
            <Row>
                <Card>
                    <CardHeader className="text-center">
                        <Row>
                            <Col lg="2" md="3" sm="12"></Col>
                            <Col lg="8" md="7" sm="12">
                                <Row>
                                    <Col>
                                    <h3 className="float-right">{datos?.firstName}, </h3>
                                    </Col>
                                    <Col>
                                    <h3 className="float-left">{datos?.lastName}</h3>
                                    </Col>
                                    
                                </Row>
                                <Row className="mt-4">
                                     <Col >
                                        <h3 className="float-right">ID:</h3>
                                     </Col>
                                     <Col >
                                     <h3 className="float-left">{datos?.id}</h3>
                                     </Col>
                                </Row>
                                <Row className="mt-2 mb-3">
                                    <Col>
                                    {Permisos && tienePermiso('Editar-Clientes')?
                                        <Button type="button" color="default" className="btn btn-default btn-md" onClick={()=>setModalCambiarContraseña(true)}>Cambiar Contraseña</Button>
                                        :
                                        <></>
                                        } 
                                    </Col>
                                    <Col>
                                    <Button type="button" color="default" className="btn btn-default btn-md" onClick={()=>TieneDeuda(datos?.id)}> Agregar Mantenimiento</Button>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                     <Input value={datos?.note}></Input>
                                </Row>
                            </Col>
                            
                            <Col className="justify-content-center" lg="2" md="2" sm="12">
                            {Permisos && tienePermiso('Editar-Clientes')?
                            <Button className="btn btn-slack btn-icon-only" type="button" onClick={() => setModalEditarCliente(true)}>
                                    <span className=" btn-inner--icon">
                                            <i className="fas fa-user-edit"></i>
                                    </span>
                            </Button>
                            :
                            <></>
                            }
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Container className="container-fluid">
                            <Row className="mt-4">
                                    <Col lg="5" md="5" sm="12">
                                         <span className="h3 font-weight-bold mb-0">Fecha de Registro</span>
                                    </Col>
                                    <Col lg="7" md="7" sm="12">
                                         <span className="h3 font-weight-bold mb-0"> {datos.registrationDate? moment(datos.registrationDate).format('DD/MM/YYYY') :null}</span>
                                    </Col>
                            </Row>
                            <Row className="mt-4">
                                    <Col lg="5" md="5" sm="12">
                                        <span className="h3 font-weight-bold mb-0">Cuenta Bancaria</span>
                                    </Col>
                                    <Col lg="7" md="7" sm="12">
                                        <span className="h3 font-weight-bold mb-0"> -</span>
                                    </Col>
                            </Row>
                            <Row className="mt-4">
                                    <Col lg="5" md="5" sm="12">
                                        <span className="h3 font-weight-bold mb-0">ID de Cliente</span>
                                    </Col>
                                    <Col lg="7" md="7" sm="12">
                                        <span className="h3 font-weight-bold mb-0"> {datos?.userIdent}</span>
                                    </Col>
                            </Row>
                            <Row className="mt-4">
                                    <Col lg="5" md="5" sm="12">
                                        <span className="h3 font-weight-bold mb-0">Instalado por:</span>
                                    </Col>
                                    <Col lg="7" md="7" sm="12">
                                        <span className="h3 font-weight-bold mb-0"> 
                                        
                                            { 
                                                findAttribute('ingresadopor',datos.attributes)
                                            }     
                                        </span>
                                    </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col lg="5" md="5" sm="12">
                                            <span className="h3 font-weight-bold mb-0">Dirección de Facturación:</span>
                                        </Col>
                                        <Col lg="7" md="7" sm="12">
                                            <span className="h3 font-weight-bold mb-0"> 
                                            
                                                { 
                                                    datos?.street1
                                                }     
                                            </span>
                                 </Col>           

                            </Row>
                            {planCliente?
                            planCliente.map((planCt, index)=>{
                                return(
                                    <Row index={index} className="mt-4">
                                            <Col lg="5" md="5" sm="12">
                                                <span className="h3 font-weight-bold mb-0">Dirección Servicio:</span>
                                            </Col>
                                            <Col lg="7" md="7" sm="12">
                                                <span className="h3 font-weight-bold mb-0"> {renderizarValor(planCt.fullAddress)}</span>
                                            </Col>
                                    </Row>
                                )
                            })
                            :
                            <></>
                            }
                            <Row className="mt-4">
                                    <Col lg="5" md="5" sm="12">
                                        <span className="h3 font-weight-bold mb-0">Es Fiscal:</span>
                                    </Col>
                                    <Col lg="7" md="7" sm="12">
                                        {
                                        findAttribute('fiscal',datos.attributes) === "1"?
                                            <span className="h3 font-weight-bold mb-0">SI</span>
                                        :
                                            <span className="h3 font-weight-bold mb-0">NO</span>
                                        }
                                      
                                    </Col>
                            </Row>
                            <Row className="mt-4">
                                    <Col lg="5" md="5" sm="12">
                                        <span className="h3 font-weight-bold mb-0">Tipo Factura:</span>
                                    </Col>
                                    <Col lg="7" md="7" sm="12">
                                        {
                                        findAttribute('tipofactura',datos.attributes)
                                        }
                                      
                                    </Col>
                            </Row>

                            <Row className="mt-4">
                                    <Col lg="5" md="5" sm="12">
                                        <span className="h3 font-weight-bold mb-0">Tipo Documento:</span>
                                    </Col>
                                    <Col lg="7" md="7" sm="12">
                                        {
                                        findAttribute('tipodocumento',datos.attributes)
                                        }
                                      
                                    </Col>
                            </Row>
                            <Row className="mt-5">
                                    <Col lg="2" md="4" sm="12">
                                    </Col>
                                    <Col lg="8" md="4" sm="12" className="text-center">
                                    <Button className="btn-info btn-md" onClick={()=>SetModalFiscalVisible(true)}>
                                        Cambiar Estado Fiscal
                                    </Button>
                                    </Col>
                                    <Col lg="2" md="4" sm="12">
                                    </Col>
                            </Row>
                        </Container>
                    </CardBody>

                </Card>
            </Row>
            </Container>
          </Col>
        </Row>
      </div>
      
      {/* EDITAR CLIENTE MODAL */}
      <ModalEditarClt
      setModalEditarCliente={setModalEditarCliente}
      ModalEditarCliente={ModalEditarCliente}
      idClient={idClient}
      setAlert={setAlert}
      />
      {/* MODAL CAMBIAR CONTRASEÑA */}
      <ModalCambiarContra
      ModalCambiarContraseña={ModalCambiarContraseña}
      setModalCambiarContraseña={setModalCambiarContraseña}
      clientId={idClient}
      />

      <ModalHacerFiscal ModalFicalVisible={ModalFicalVisible} 
      SetModalFiscalVisible={SetModalFiscalVisible} 
      idClient={idClient}></ModalHacerFiscal>

      <ModalSelecServicio
      planCliente={planCliente}
      ModalSeleccionarServicio={ModalSeleccionarServicio}
      setModalSeleccionarServicio={setModalSeleccionarServicio}
      idClient={idClient}
      />
      <ModalAggServicios
      ModalAgregarServicio={ModalAgregarServicio}
      setModalAgregarServicio={setModalAgregarServicio}
      idClient={idClient}
      setAlert={setAlert}
      datos={datos}
      />



  </>

    )
}


export default General;