import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Form, FormGroup, Input, Alert, Modal } from 'reactstrap';
import classnames from "classnames";
import { successAlert } from 'pages/components/SweetAlert';
import { warningAlert } from 'pages/components/SweetAlert';
import { putEditarEvento } from 'services/EventosService';
import { confirmAlert } from 'pages/components/SweetAlert';
import { deleteEvento } from 'services/EventosService';
import { putEditarEspera } from 'services/EsperaServices';
import SelectorEmpleados from '../../../components/SelectorEmpleados';
const ModalEditEvento = (props) => {
    const {createCalendar, ModalEditarEvento, setModalEditarEvento, Evento, EsperaEdit}= props;
    const [Radios, setRadios] = useState("");
    const [Descripcion, setDescripcion] = useState("");
    const [Titulo, setTitulo] = useState("");
    const [AlertM, setAlert] = useState("");
    const [Instalador, setInstalador] = useState("")
    const [Turno, setTurno] = useState("")
    const EditarEvento = async() => {

        let evento = {
            id: Evento.id,
            comentario: Evento.eventDescription,
            esperaId:Evento.esperaId,
            start: Evento.event.start
        }
        if(Radios !== ""){
          evento.className = Radios
        }
        if(Titulo !== ""){
          evento.title = Titulo
        }
        if(Instalador !== ""){
          evento.empleadoId = Instalador
        }
        if(Descripcion !== ""){
          evento.comentario = Descripcion
        }
        if(Turno !== ""){
          let sepF = Turno.split('-');
          let fecha = new Date(parseInt(sepF[0]), parseInt(sepF[1])-1, parseInt(sepF[2]));
          evento.start = fecha
        }
        let eved = await putEditarEvento(evento)
        if (eved !== null && eved !== '') {
          successAlert(setAlert, "Evento Editado!")
          setModalEditarEvento(false)
          createCalendar();
        }else{
          warningAlert(setAlert, "No se pudo editar el Evento!")
        }
      }

    const cambiarDescripcion = event => {
        setDescripcion(event.target.value);
      };
    const cambiarTurno = event => {
      setTurno(event.target.value);
    };
    const cambiarTitulo = event => {
        setTitulo(event.target.value);
    };

    const confirmarEliminar= async(id)=>{
        confirmAlert(setAlert, eliminarEvento, id , "Deseas eliminar este Evento?")
      }

    const eliminarEvento = async(id)=>{
    let result = await deleteEvento(id)
    if(result !== null){
            successAlert(setAlert, "Evento Eliminado!", "");
            cambiarAsignado();
            reestablecer();
            setModalEditarEvento(false);
            createCalendar()
            return
    }else{
        warningAlert(setAlert,'No se pudo eliminar la Espera')
    }
    };

    const cambiarAsignado=async ()=>{
      let evn= { 
        id: EsperaEdit.id,
        Asignado : null }
      let evnt =await putEditarEspera (evn)
      if(evnt){
        
      }
    };

    const formatearFecha = (fechaTurno) => {
      let fecha = new Date(fechaTurno);
      let dia = fecha.getDate();
      let mes = fecha.getMonth();
      let ano = fecha.getFullYear();
      let fechaFormateada = "";
      if ((mes + 1) < 10 ) {
        if (dia < 10) {
          fechaFormateada = `${ano}-0${mes+1}-0${dia}`;
        } else {
          fechaFormateada = `${ano}-0${mes+1}-${dia}`;
        }
      } else if(dia <10){
        fechaFormateada = `${ano}-${mes+1}-0${dia}`;
      }else{
        fechaFormateada = `${ano}-${mes+1}-${dia}`;
      }
      return fechaFormateada;
    }

    const reestablecer=()=>{
      setTurno("");
      setInstalador("");
      setTitulo("");
      setDescripcion("");
      setRadios("");
    }

     useEffect(() => {
      
    }, []);

  return (
      <>
      {AlertM}
      
      <Modal
          isOpen={ModalEditarEvento}
          toggle={() => setModalEditarEvento(false)}
          className="modal-dialog-centered modal-secondary modal-sm"
        >
          <div className="modal-body">
            <Form className="edit-event--form">
              <FormGroup>
                <label className="form-control-label">Titulo del evento</label>
                <Input
                  className="form-control-alternative edit-event--title"
                  placeholder="Event Title"
                  type="text"
                  defaultValue={Evento.eventTitle}
                  onChange={cambiarTitulo}
                />
              </FormGroup>
                {EsperaEdit?
                <>
                {EsperaEdit.completado?
                <Alert className="alert-default">
                  <strong>Evento Finalizado!</strong> 
                </Alert>
                :<></>}
                <FormGroup >
                <h4>Apellido y Nombre:</h4>{EsperaEdit.apellido}, {EsperaEdit.nombre}<br/>
                <h4>Dirección:</h4>{EsperaEdit.direccion}<br/>
                <h4>Teléfono:</h4>{EsperaEdit.telefono}<br/>
                <h4>Ubicación:</h4> Lat: {EsperaEdit.lat}, Lng;{EsperaEdit.lng}
                <h4>Plan a Instalar:</h4> {EsperaEdit.planId}
                <h4>Instalador:</h4> {Evento.empleado?.nombre} {Evento.empleado?.apellido}
                </FormGroup>
                </>
              
              :
              <>-</>
              }
              <FormGroup>
                <label className="form-control-label">Cambiar Instalador</label>
                <SelectorEmpleados valor={Evento.empleadoId} cambiarValor={setInstalador}/>
              </FormGroup>
              <FormGroup>
                <label className="form-control-label">Turno:</label>
                <Input
                  type="date"
                  defaultValue={formatearFecha(Evento.turno)} 
                  onChange={cambiarTurno}
                />
              </FormGroup>
              <FormGroup>
                <label className="form-control-label d-block mb-3">
                  Identificador: 
                </label>
                <ButtonGroup
                  className="btn-group-toggle btn-group-colors event-tag mb-0"
                  data-toggle="buttons"
                >
                  <Button
                    className={classnames("bg-info", {
                      active: Radios=== "bg-info"
                    })}
                    color=""
                    type="button"
                    onClick={() => setRadios("bg-info" )}
                  />
                  <Button
                    className={classnames("bg-warning", {
                      active: Radios === "bg-warning"
                    })}
                    color=""
                    type="button"
                    onClick={() => setRadios("bg-warning")}
                  />
                  <Button
                    className={classnames("bg-danger", {
                      active: Radios === "bg-danger"
                    })}
                    color=""
                    type="button"
                    onClick={() => setRadios("bg-danger")}
                  />
                  <Button
                    className={classnames("bg-success", {
                      active: Radios === "bg-success"
                    })}
                    color=""
                    type="button"
                    onClick={() =>
                        setRadios("bg-success")
                    }
                  />
                  <Button
                    className={classnames("bg-primary", {
                      active: Radios === "bg-primary"
                    })}
                    color=""
                    type="button"
                    onClick={() => {setRadios("bg-primary")}}
                  />
                </ButtonGroup>
              </FormGroup>
              <FormGroup>
                <label className="form-control-label">Descripcion:</label>
                <Input
                  className="form-control-alternative edit-event--description textarea-autosize"
                  placeholder="Descripcion del Evento"
                  type="textarea"
                  defaultValue={Evento.eventDescription}
                  onChange={cambiarDescripcion}
                />
                <i className="form-group--bar" />
              </FormGroup>
              <input className="edit-event--id" type="hidden" />
            </Form>
          </div>
          <div className="modal-footer">
            <Button color="primary" 
            onClick={()=>EditarEvento()}
            >
              Editar
            </Button>
            <Button
              color="danger"
              onClick={() =>confirmarEliminar(Evento.id)}
            >
              Eliminar
            </Button>
            <Button
              className="ml-auto"
              color="link"
              onClick={() => setModalEditarEvento(false)}
            >
              Cerrar
            </Button>
          </div>
        </Modal>
      </>
  )
}

export default ModalEditEvento