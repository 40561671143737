import React, { useState } from 'react'
import SelectorCategorias from 'pages/components/SelectorCategoria'
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap'
import { postEquipos } from 'services/EquiposService'
import {warningAlert, successAlert, confirmAlert} from "../../../components/SweetAlert"
import LoadingComponent from 'pages/components/Loading'
import { postAsignarEquipos } from 'services/EquiposService'
import { AsignarServicio } from 'services/ServiciosServices'
import { putAsignar } from 'services/ServiciosServices'


export default function ModalAsignarServicio(props){
    const {
        ModalAsignarServ, setModalAsignarServ, ServiciosClientes, Asignado, traerServiciosClientes
      } = props
      const [Usuario, setUsuario] = useState("");
      const [Ip, setIp] = useState("");
      const [Alert, setAlert] = useState(null);
      const [Visible, setVisible] = useState(false);


    const cambiarIp = (e) => {
      setIp(e.target.value)
    }
    
    const asignar= async()=> {
      setVisible(true)
      let equipoAsignar = {
        viejo: "prueba.TM",
        nuevo:ServiciosClientes.radiusUsername,
        mac:ServiciosClientes.macEquipo,
        ip:Ip
        
      }
      let resp = await postAsignarEquipos(equipoAsignar);
      if(resp){
        asignarEq(ServiciosClientes);
      }
      setVisible(false)

    }
    
    const asignarEq= async(ServiciosClientes)=>{
      let resp = await putAsignar(ServiciosClientes);
    if(resp){
      setIp("");
      successAlert(setAlert,"Equipo asignado correctamente");
      setModalAsignarServ(false);
      traerServiciosClientes()
    }
    }


    const confirmarAsignar = ()=>{
      confirmAlert(setAlert, asignar, null, "Seguro que desea asignar?")
    }

    return(
      <>
        {Alert}
        <LoadingComponent Visible={Visible}/>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={ModalAsignarServ}
          toggle={() => setModalAsignarServ(false)}
        >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-center"><b>Asignar Equipo</b>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalAsignarServ(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
              </div>
              <div className="text-muted mt-2 mb-3">
              <Form role="form">
              <FormGroup className="mb-3">Ip:
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      <i className="fas fa-satellite-dish"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Ip..." type="text"  onChange={cambiarIp} value={Ip}/>
                    
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={()=>confirmarAsignar()}
                  >
                    Asignar
                  </Button>
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalAsignarServ(false)}
                  >
                    Cerrar
                  </Button>                    
                </div>
              </Form>                     
              </div>                    
            </CardHeader>                  
          </Card>
        </div>
        </Modal>
      </>
    )
}