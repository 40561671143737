import SubirFotoComponent from 'pages/components/SubirFoto';
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap';
import { putEditarEmpleado } from 'services/EmpleadosService';
import SelectorTipoSueldo from './SelectorTipoSueldo';

const ModalEditEmpleado =(props)=> {
    const {ModalEditarEmpleado, setModalEditarEmpleado, traerEmpleados,EmpleadoSeleccionado}= props;
    

    const [Nombre, setNombre] = useState("");
    const [Apellido, setApellido] = useState("");
    const [Telefono, setTelefono] = useState("");
    const [Dni, setDni] = useState("");
    const [Email, setEmail] = useState("");
    const [Sueldo, setSueldo] = useState("");
    const [Ingreso, setIngreso] = useState("");
    const [TipoSueldo, setTipoSueldo] = useState("");
    const [Foto, setFoto] = useState("");
    const [Alert, setAlert] = useState(null);


    const cambiarNombre = event => {
        setNombre(event.target.value);
      };
      const cambiarApellido = event => {
        setApellido(event.target.value);
      };
      const cambiarTelefono = event => {
        setTelefono(event.target.value);
      };
      const cambiarEmail = event => {
        setEmail(event.target.value);
      };
      const cambiarSueldo = event => {
        setSueldo(event.target.value);
      };
      const cambiarIngreso = event => {
        setIngreso(event.target.value);
      };
      const cambiarDni = event => {
        setDni(event.target.value);
      };

  const actualizarEmpleado= async()=>{
    let sepF = Ingreso.split('-');
    let fecha = new Date(parseInt(sepF[0]), parseInt(sepF[1])-1, parseInt(sepF[2]));
    let EmpleadoAux = {}
    Object.assign(EmpleadoAux, EmpleadoSeleccionado)
    EmpleadoAux.nombre = Nombre;
    EmpleadoAux.apellido = Apellido;
    EmpleadoAux.telefono = Telefono;
    EmpleadoAux.email = Email;
    EmpleadoAux.dni = Dni;
    EmpleadoAux.sueldoMensual = Sueldo;
    EmpleadoAux.ingreso = fecha;
    EmpleadoAux.tiposueldo = TipoSueldo;
    EmpleadoAux.foto = Foto;
    if (validarAggEmpleado(EmpleadoAux)) {
        let valido = await putEditarEmpleado(EmpleadoAux);
        if(valido){
          successAlert(setAlert,'Empleado Actualizado');
          recetearEmpleado()
          setModalEditarEmpleado(false);
          traerEmpleados();
        }else{
          warningAlert(setAlert,'No se pudo Editar el Empleado')
      }
        
    }else{
        warningAlert(setAlert,"Complete todos los campos" )
    }
}

  const recetearEmpleado = () => {
    setNombre("")
    setApellido("")
    setTelefono("")
    setEmail("")
    setDni("")
    setSueldo("")
    setIngreso("")
    setTipoSueldo("")
    setFoto("")
  }


  const convertDate=(fecha) =>{
    let date = new Date(fecha);
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth()+1).toString();
    var dd  = date.getDate().toString();

    var mmChars = mm.split('');
    var ddChars = dd.split('');

    return yyyy + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + (ddChars[1]?dd:"0"+ddChars[0]);
  }

  const formatearFecha = (fechaTurno) => {
    let fecha = new Date(fechaTurno);
    let dia = fecha.getDate();
    let mes = fecha.getMonth();
    let ano = fecha.getFullYear();
    let fechaFormateada = "";
    if ((mes + 1) < 10 ) {
      if (dia < 10) {
        fechaFormateada = `${ano}-0${mes+1}-0${dia}`;
      } else {
        fechaFormateada = `${ano}-0${mes+1}-${dia}`;
      }
    } else if(dia <10){
      fechaFormateada = `${ano}-${mes+1}-0${dia}`;
    }else{
      fechaFormateada = `${ano}-${mes+1}-${dia}`;
    }
    return fechaFormateada;
  }


  const establecerEmpleado= () => {
    if (EmpleadoSeleccionado != null) {
      setNombre(EmpleadoSeleccionado.nombre);
      setApellido(EmpleadoSeleccionado.apellido);
      setTelefono(EmpleadoSeleccionado.telefono);
      setEmail(EmpleadoSeleccionado.email);
      setDni(EmpleadoSeleccionado.dni);
      setSueldo(EmpleadoSeleccionado.sueldoMensual);
      setTipoSueldo(EmpleadoSeleccionado.tiposueldo);
      setFoto(EmpleadoSeleccionado.foto)
      setIngreso(formatearFecha(EmpleadoSeleccionado.ingreso))
    }
  }
  const validarAggEmpleado = (empl)=>{
    if(!empl.nombre){
      //this.sweetAlertService.alertError('Ingrese un nombre');
      return false;
    }
    if(!empl.apellido){
      //this.sweetAlertService.alertError('Ingrese un apellido');
      return false;
    }
    if(!empl.telefono){
      //this.sweetAlertService.alertError('Ingrese un telefono');
      return false;
    }
    if(!empl.dni){
      //this.sweetAlertService.alertError('Ingrese un Dni');
      return false;
    }
    if(!empl.sueldoMensual){
      //this.sweetAlertService.alertError('Ingrese un sueldo Mensual');
      return false;
    }
    if(!empl.ingreso){
      //this.sweetAlertService.alertError('Ingresa la fecha de ingreso');
      return false;
    }
    if(!empl.tiposueldo){
      //this.sweetAlertService.alertError('Ingresa la fecha de ingreso');
      return false;
    }
    return true;
  }

  useEffect(() => {
  establecerEmpleado();

}, [ModalEditarEmpleado])

    return (
       <>
       {Alert}
        <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={ModalEditarEmpleado}
            toggle={() => {setModalEditarEmpleado(false)}}
          >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-transparent pb-5">

                    <div className="text-center">
                      <b>Editar Empleado</b>
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => {setModalEditarEmpleado(false)}}
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                    </div>
                    <div className="text-muted mt-2 mb-3">
                    <Form role="form">
                    <FormGroup className="mb-3" >Nombre:
                          <Input 
                          type="text"  
                          value={Nombre} 
                          onChange={cambiarNombre}/>
                      </FormGroup>
                      <FormGroup className="mb-3" >Apellido:
                          <Input 
                          type="text" 
                          value={Apellido} 
                          onChange={cambiarApellido}
                          />
                      </FormGroup>
                      <FormGroup className="mb-3" >DNI:
                          <Input 
                          type="number" 
                          value={Dni} 
                          onChange={cambiarDni}/
                          >
                      </FormGroup>
                    <FormGroup className="mb-3" >Telefono:
                          <Input 
                          type="number" 
                          value={Telefono} 
                          onChange={cambiarTelefono}/>
                      </FormGroup>
                      <FormGroup > Email:
                          <Input 
                          type="email" 
                          value={Email} 
                          onChange={cambiarEmail}
                          />
                      </FormGroup>
                      <FormGroup > Sueldo Mensual:
                          <Input 
                          type="number" 
                          value={Sueldo} 
                          onChange={cambiarSueldo}
                          />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="mb-3"
                          htmlFor="example-date-input"
                          >
                          Ingreso:
                        </label>
                        <Input
                          id="example-date-input"
                          type="date"
                          value={Ingreso} onChange={cambiarIngreso}
                        />
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="exampleFormControlSelect1">Tipo De Sueldo:</label>
                        <SelectorTipoSueldo Valor={TipoSueldo} setValor={setTipoSueldo}/>
                        </FormGroup>
                        <SubirFotoComponent setFoto={setFoto}/>
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={()=>actualizarEmpleado()}
                        >
                          Editar
                        </Button>
                        <Button
                          color="secondary"
                          data-dismiss="modal"
                          type="button"
                          onClick={() => {setModalEditarEmpleado(false)}}
                        >
                          Cerrar
                        </Button>                    
                      </div>
                    </Form>                     
                    </div>                    
                  </CardHeader>                  
                </Card>
              </div>
        </Modal>
       </>
    )
}


export default ModalEditEmpleado
