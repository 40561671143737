import SelectorEmpleados from 'pages/components/SelectorEmpleados'
import React, { useState } from 'react'
import BuscadorMaterialEmpleado from '../BuscadorMaterialesEmpleado'
import ModalCantidadAEntregar from '../ModalCantidadAEntregar'
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import { Badge, Button, Card, CardBody, CardFooter, Form, FormGroup, Input, Modal, Table } from 'reactstrap'
import { postRegistroUso } from 'services/RegistrarService';
import Buscador from 'pages/components/Buscador';
import { getClientes } from 'services/ClientesServices';
import { getTiposMaterialesEmpleados } from 'services/TiposMaterialesService';
import ModalRegistrarA from './ModalRegistrarA';
import EntregarAObra from './EntregarAObra';

const EntregarAEmpleado = ({EmpleadoSeleccionado,setEmpleadoSeleccionado,MaterlSeleccionado,setMaterSeleccionado, TMsSeleccionados, Cliente, setCliente,Clientes,setClientes,setAlert,Error,setError,Empleado,setEmpleado,reset,resetModal,Cantidad,setCantidad,setLoading,setTMSelec,TMSelec,setModalCantidad,ModalCantidad,traerMateriales,setTMsSeleccionados,agregarTmConCantidad,setNoAgregados, EntregarA, setEntregarA, Obra, setObra}) => {
    const [Materiales, setMateriales] = useState("");

    const establecerEmpleado = ()=> {
        setEmpleadoSeleccionado(true)
        traerMaterialesEmpleado()
    }
    const establecerMaterial = ()=> {
        setMaterSeleccionado(true)
    }

    const actualizaCheck = (tm) => {
        let resInclude = includeTM(tm.idTipoMaterial)
        if(resInclude){
            let aux = []
            TMsSeleccionados.map(tmMap=>{
                if(tmMap.idTipoMaterial != tm.idTipoMaterial){
                  aux.push(tmMap);
                }
            })
            setTMsSeleccionados(aux);
        }else{
          setTMSelec(tm);
          setModalCantidad(true);
        }
    }

    const agregarTodos = async() =>{
        if(!TMsSeleccionados || TMsSeleccionados.length===0){
            warningAlert(setAlert,"No seleccionó ningún material")
            return
        }
        if (EntregarA === "Cliente") {
            if(Cliente===""){
                warningAlert(setAlert,"Seleccione un cliente")
                return
            }
        } else if (EntregarA === "Obra") {
            if(Obra===""){
                warningAlert(setAlert,"Seleccione una Obra")
                return
            }
        }
        setLoading(true)
        let fallaron = []
        await Promise.all(TMsSeleccionados.map(async (material) => {
            let dev = await Agregar(material.idTipoMaterial, material.cantidad)
            if(dev.res===false){
                fallaron.push({material:material.tipoMaterial, mensaje:dev.mensaje})
            }
        }))
        if(fallaron.length===0){
            setLoading(false)
            successAlert(setAlert,"El uso de materiales se registro correctamente")
            setCliente("")
            setObra("")
            setEmpleado("")
            setEmpleadoSeleccionado(false)
            setMaterSeleccionado(false)
            reset()
            traerMateriales()
        }else{
            setNoAgregados(fallaron);
            setLoading(false)
            warningAlert(setAlert,"El uso de algunos materiales no se registro")
            
        }
    }


    const traerMaterialesEmpleado = async () => {
        let mater = await getTiposMaterialesEmpleados(null,  parseInt(Empleado))
        if (mater) {
            setMateriales(mater.tme)
        }
    }
    
    const recetClientes = async() => {
        setClientes("");
    }

    const Agregar = async(idTipoMaterial, cantidad) =>{
        let mUsado = {
            tipoMaterialId : parseInt(idTipoMaterial),
            cantidad : parseInt(cantidad),
            clienteId : Cliente? parseInt(Cliente) : null,
            obraId : Obra? parseInt(Obra) : null,
            empleadoId : parseInt(Empleado)
        }
        if (validarEntregar(mUsado)===true && validarMinimo(cantidad) === true) {
            let agg = await postRegistroUso(mUsado)
            if (agg !== null && agg !== "") {
                return {res:true, mensaje:"Uso registrado correctamente!"}
            } else {
                return {res:false, mensaje:"Error al registrar el uso!"}
            }
        } else {
            if (Error.message) {
                return {res:false, mensaje:Error.message}
            } else {
                return {res:false, mensaje:"Complete todos los campos!"}
            }
        }
    }
    const validarMinimo = (cantidad) => {
        if (cantidad <=0) {
            setError({message : "La Cantidad a registrar debe ser mayor a 0!"})
            return false
        }
        return true
    }
    const validarEntregar = (ev)=>{
        if(!ev.tipoMaterialId){
          return false;
        }
        if(!ev.cantidad){
          return false;
        }
        if(!ev.empleadoId){
            return false;
        }
        if(!ev.clienteId && !ev.obraId){
        return false;
        }
        return true;
    }

    const includeTM = (id) => {
        let aux = false;
        TMsSeleccionados.map(tm=>{
            if(tm.idTipoMaterial == id){
                aux = true
            }
        })
        return aux
    }
  return (
    <>
    <Card>

                {!EmpleadoSeleccionado?
            <>
            <CardBody>
            <div className=" mt-5 mb-3">
            <Form role="form">
                <label>Empleado:</label>
                <SelectorEmpleados valor={Empleado} cambiarValor={setEmpleado}/>
            </Form>                     
            </div>                    
            </CardBody>
            <CardFooter>
                <div className="text-center">
                <Button
                    className=""
                    color="primary"
                    type="button"
                    onClick={()=>establecerEmpleado()}
                    
                    >
                    Confirmar
                </Button>
            
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => (resetModal(), reset())}
                    >
                    Cerrar
                </Button>                    
                </div>
                </CardFooter>       
            </>
            :
            <>
            {!MaterlSeleccionado?
            <>
            <CardBody>
            <div className=" mt-5 mb-3">
            <Form role="form">
            <FormGroup>
            <BuscadorMaterialEmpleado setMateriales={setMateriales}  traerMateriales={traerMaterialesEmpleado} EmpleadoId={parseInt(Empleado)}/>
            </FormGroup>
            <FormGroup>
               <Table responsive className='text-center'>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Codigo</th>
                        <th>Cantidad</th>
                        <th>Seleccionar</th>
                    </tr>
                </thead>
                <tbody>
                {Materiales.length?
                    Materiales.map((m, index)=>{
                        return(
                            <tr key={index}>
                        <th>{m.tiposMateriale?.nombre}</th>
                        <td>{m.tiposMateriale?.codigoProducto}</td>
                        <td>{m.cantidad}</td>
                        <td>
                            <label className="custom-toggle">
                                <input type="checkbox" onChange={()=>actualizaCheck(m)}  checked={includeTM(m.idTipoMaterial)}/>
                                <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Si"
                                />
                            </label>
                        </td>
                    </tr>

                )
                })
                :
                <>
                <tr>
                    <td>

                <h5>No se encontro el material Buscado!</h5>
                    </td>
                </tr>
                </>}
                </tbody>
               </Table>
            </FormGroup>
            </Form>                     
            </div>                    
            </CardBody>
            <CardFooter>
                <div className="text-center">
                <Button
                    className=""
                    color="primary"
                    type="button"
                    onClick={()=>establecerMaterial()}
                    
                    >
                    Confirmar
                </Button>
            
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => (resetModal(), reset())}
                    >
                    Cerrar
                </Button>                    
                </div>
                </CardFooter>
                 </>
            :
            <>
            {EntregarA === ""?
            <ModalRegistrarA cambiarEstado={setEntregarA}/>
           : 
           <>
           {EntregarA === "Cliente"?
           <>
           <CardBody>
            <div className=" mt-5 mb-3">
            <Form role="form">
            <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Busque y Seleccione cliente:
            </label>
            <Buscador getObjetos={getClientes} setObjetos={setClientes} traerObjetos={recetClientes}/>
            {Clientes?
                    <Table className='text-center'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>NOMBRE</th>
                                <th>SELECCIONAR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Clientes?
                            Clientes.map((cl, index)=>{
                                return(
                                    <tr key={index}>
                                        <th>{cl.id}</th>
                                        <td>{cl.firstName} {cl.lastName}</td>
                                        <td>
                                            <Input
                                                onClick={()=>setCliente(cl.id)}
                                                className="custom-control-input"
                                                id={`customRadio${index}`}
                                                name="customRadio"
                                                type="radio"
                                                />
                                            <label className="custom-control-label" htmlFor={`customRadio${index}`}>
                                            </label>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <>
                        </>}
                        </tbody>
                    </Table>
                    :
                    <Badge
                    className='mt-3'
                    color="info"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                    >
                    Busque para ver los clientes
                    </Badge>
                    }
            </Form>                     
            </div>                    
            </CardBody>
           </>
            :
            <>
            <EntregarAObra setObra={setObra} Obra={Obra}/>
            </>
            }
            </>
           }
            <CardFooter>
                <div className="text-center">
                <Button
                    className=""
                    color="primary"
                    type="button"
                    onClick={()=>agregarTodos()}
                    
                    >
                    Registrar Uso 
                </Button>
            
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => (resetModal(), reset())}
                    >
                    Cerrar
                </Button>                    
                </div>
                </CardFooter>  
            </>}
              
            </>
            }         
            </Card>
            <ModalCantidadAEntregar ModalCantidad={ModalCantidad} 
            setModalCantidad={setModalCantidad} 
            Cantidad={Cantidad} setCantidad={setCantidad}
            agregarTmConCantidad={agregarTmConCantidad}
            TMSelec={TMSelec}
        />  
    </>
  )
}

export default EntregarAEmpleado