import React, { useState } from 'react'
import { Button, Col, Row, Table } from 'reactstrap'
import { ModalAgregarEquiposSistema } from './ModalAgregarEquiposSistema'
import ModalSeleccionarExcel from './ModalSeleccionarExcel'

const TableEquiposExcel = props => {
const {EquiposSeleccionados, setEquiposSeleccionados, setModalEntregarEquipo, ModalAgregarEquipo} = props
  const [equipos,setEquipos] = useState([]);
  const [ModalExcel, setModalExcel] = useState(false);
  const [ModalAggSistema, setModalAggSistema] = useState(false)

  const actualizaCheck = (equipo) => {
    setEquiposSeleccionados(equiposAnt => {
    let filtro = equiposAnt.find(e=> e.macAddress === equipo.mac)
      if(filtro){
         let filtrado = equiposAnt.filter(e=> e.macAddress !== equipo.mac)
            setEquiposSeleccionados(filtrado)
      }
      else{
        equiposAnt.push({estadoId: 2, macAddress: equipo.mac, categoriaId: equipo.categoriaId, empleadoId: equipo.empleadoId});
        setEquiposSeleccionados(equiposAnt)
      }
    })
  }
  const seleccionarTodos = (et) => {
    let arr = []
    et.map(eq => {
    arr.push({estadoId: 2, macAddress: eq.mac, categoriaId: eq.categoriaId, empleadoId: eq.empleadoId})
    })
    setEquiposSeleccionados(arr)
  }
  return (
    <>
    <Row className='mt-3 mb-3 ml-3 mr-3'>
        <Col><Button className='btn-md btn-outline-info' onClick={()=> setModalExcel(true)}> Seleccionar Archivo </Button></Col>
        <Col><Button className='btn-md btn-outline-info float-right' onClick={()=> setModalAggSistema(true)}> Agregar Al Sistema </Button></Col>
    </Row>
    <Table responsive>
        <thead className='text-center'>
            <tr>
                <th scope="col" >Mac Address</th>
                <th scope="col">Tipo Equipo</th>
                <th scope="col">Empleado a Asignar</th>
                <th scope="col">Seleccionar</th>
            </tr>
        </thead>
        <tbody className='text-center'>
            {equipos?
            equipos.map((e, index)=>{
                return(
                <tr key={index} className="text-center">
                    <th>{e.mac}</th>
                    <td>{e.categoriaId}</td>
                    <td>{e.empleadoId}</td>
                    <td>
                        <label className="custom-toggle ml-8" >
                            <input type="checkbox"  defaultChecked onClick={()=>actualizaCheck(e)}/>
                            
                            <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Si"
                            />
                        </label>
                    </td>
                </tr>
                )
            })
            :
            <>-</>
            }
        </tbody>
    </Table>
    <ModalSeleccionarExcel 
    setEquipos={setEquipos} 
    ModalExcel={ModalExcel}
    setModalExcel={setModalExcel}
    seleccionarTodos={seleccionarTodos}
    />
    <ModalAgregarEquiposSistema 
    setModalAggSistema={setModalAggSistema}
    ModalAggSistema={ModalAggSistema}
    EquiposSeleccionados={EquiposSeleccionados}
    setEquiposSeleccionados={setEquiposSeleccionados}
    />
    </>
  )
}

export default TableEquiposExcel