import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ModalNoAgregados from "./components/ModalNoAgregados";
import ContainerCategoria from "./components/ContainerCategoria";
import ContainerEscanear from "./components/ContainerEscanear";
import { GuardInPages } from "guards/Guard";
import TipoEquipos from "./components/TipoEquipo";
import CategoriaXCantidad from "pages/components/CategoriaXCantidad";
import { Col, Container, Row } from "reactstrap";



function StockMultiple(){
    const history = useHistory()

    const [equipos, setEquipos] = useState([]);
    const [vistaMuestra, setVistaMuestra] = useState("tipoEquipos");
    const [tieneMac, setTieneMac] = useState(true)
    const [alertSweet, setAlert] = useState(null);
    const [EquiposErrorAgg, setEquiposErrorAgg] = useState([]);
    const [NoAgregadosModal, setNoAgregadosModal] = useState(false);
    const [arrayCatsGuardadas, setArrayCatsGuardadas] = useState([]);
    
    const accionContinuar = () => {
        setTieneMac(false)
        setVistaMuestra("categoria");
        setEquipos(arrayCatsGuardadas)
    }

    useEffect(() => {
        GuardInPages(history)
    }, [])

    if(vistaMuestra === "tipoEquipos"){
        return(
            <>
              <TipoEquipos setVistaMuestra={setVistaMuestra}/>  
            </>
        )
    }else if(vistaMuestra === "sinMac"){
        return(
            <>
                <Container className="mt-6" fluid>
                    <Row>
                        <Col></Col>
                        <Col>
                            <CategoriaXCantidad 
                            accionContinuar={accionContinuar} 
                            arrayCatsGuardadas={arrayCatsGuardadas} setArrayCatsGuardadas={setArrayCatsGuardadas} 
                            tipoValue={"objeto"}
                            />  
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
            </>

        )
    }else if(vistaMuestra === "escanear"){
        return(
            <>
              <ContainerEscanear setVistaMuestra={setVistaMuestra} equipos={equipos} setEquipos={setEquipos}/>  
            </>
        )
    }else if(vistaMuestra === "categoria"){
        return (
            <>
                {alertSweet}
                <ContainerCategoria setAlert={setAlert} setEquiposErrorAgg={setEquiposErrorAgg} EquiposErrorAgg={EquiposErrorAgg}
                setNoAgregadosModal={setNoAgregadosModal} equipos={equipos} tieneMac={tieneMac}/>

                <ModalNoAgregados NoAgregadosModal={NoAgregadosModal} setNoAgregadosModal={setNoAgregadosModal} EquiposErrorAgg={EquiposErrorAgg}/>
            </>
        )
    }else{
        return(
            <></>
        )
    }
    
}

export default StockMultiple;