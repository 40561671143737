import React, { useState } from 'react'
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, Label, Modal, Row, Table } from 'reactstrap'
import SelectorEmpleados from 'pages/components/SelectorEmpleados';
import { recibirMaterial } from 'services/AlmacenesService';
import { getTiposMaterialesEmpleados } from 'services/TiposMaterialesService';
import BuscadorMaterialEmpleado from './BuscadorMaterialesEmpleado';

const ModalRecibir = ({ModalRecibirMaterial, setModalRecibirMaterial, traerMateriales, idAlmacen}) => {
    const [Material, setMaterial] = useState("");
    const [Materiales, setMateriales] = useState([])
    const [Alert, setAlert] = useState(null)
    const [Empleado, setEmpleado] = useState("");
    const [EmpleadoSeleccionado, setEmpleadoSeleccionado] = useState(false);
    const [MaterialSeleccionado, setMaterialSeleccionado] = useState(null);
    const [Cantidad, setCantidad] = useState("")
    const [MaterlSeleccionado, setMaterSeleccionado] = useState(false);
    const [Error, setError] = useState({});
    
    const recibir = async() =>{
        let material = {
            tipoMaterialId: parseInt(MaterialSeleccionado.idTipoMaterial), 
            cantidad: parseInt(Cantidad), 
            empleadoId: parseInt(Empleado), 
            almacenId : parseInt(idAlmacen)
        }
        
        if (validarRecibir(material)===true && validarMinimo(Cantidad) === true) {
            let rec = await recibirMaterial(material)
            if (rec) {
                successAlert(setAlert,"Material recibido!")
                setEmpleado("")
                setMaterialSeleccionado("")
                setEmpleadoSeleccionado(false)
                setModalRecibirMaterial(false)
                setMaterSeleccionado(false);
                setCantidad("")
                traerMateriales()
    
            } else {
                warningAlert(setAlert, "Error al recibir el Material!")
            }
            
        } else {
            if (Error.message) {
                warningAlert(setAlert, `${Error.message}`)
            } else {
                warningAlert(setAlert, "Complete todos los campos!")
    
            }
        }
    }
    const establecerEmpleado = ()=> {
        setEmpleadoSeleccionado(true);
        traerMaterialesEmpleado()
    }
    const establecerMaterial = ()=> {
        setMaterSeleccionado(true)
    }
    const cambiarCantidad = (e) => {
        setCantidad(e.target.value)
    }
    const traerMaterialesEmpleado = async () => {
        let mater = await getTiposMaterialesEmpleados(null, parseInt(Empleado))
        if (mater) {
            setMateriales(mater.tme)
        }
    }

    const validarMinimo = () => {
        if (Cantidad <=0) {
            setError({message : "La Cantidad a recibir debe ser mayor a 0!"})
            return false
        }
        return true
    }
    const validarRecibir = (ev)=>{
        if(!ev.tipoMaterialId){
          return false;
        }
        if(!ev.cantidad){
          return false;
        }
        if(!ev.empleadoId){
            return false;
          }
        return true;
      }

      const checkeado = (id) => {
        if (MaterialSeleccionado) {
            return id == MaterialSeleccionado.idTipoMaterial
        }
        return false
    }

    const seleccionarMaterial = (material) => {
        if(MaterialSeleccionado && MaterialSeleccionado.idTipoMaterial == material.idTipoMaterial){
            setMaterialSeleccionado(null);
        }else{
            setMaterialSeleccionado(material);
        }
    }



  return (
    <>
        {Alert}
        <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={ModalRecibirMaterial}
        toggle={() => (setModalRecibirMaterial(false), setEmpleadoSeleccionado(""), setEmpleado(""), setCantidad(""), setMaterSeleccionado(false))}
        >
        <div>
        <Card>
            <CardHeader className="pb-3">
            <div className="text-center"><b>Recibir Material</b>
            <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => (setModalRecibirMaterial(false), setEmpleadoSeleccionado(""), setEmpleado(""), setCantidad(""), setMaterSeleccionado(false))}
        >
            <span aria-hidden={true}>×</span>
        </button>
            </div>
            </CardHeader>  
            {!EmpleadoSeleccionado?
            <>
            <CardBody>
            <div className=" mt-5 mb-3">
            <Form role="form">
                <label>Empleado:</label>
                <SelectorEmpleados valor={Empleado} cambiarValor={setEmpleado}/>
            </Form>                     
            </div>                    
            </CardBody>
            <CardFooter>
                <div className="text-center">
                <Button
                    className=""
                    color="primary"
                    type="button"
                    onClick={()=>establecerEmpleado()}
                    
                    >
                    Confirmar
                </Button>
            
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => (setModalRecibirMaterial(false), setEmpleadoSeleccionado(""), setEmpleado(""), setCantidad(""), setMaterSeleccionado(false))}
                >
                    Cerrar
                </Button>                    
                </div>
                </CardFooter>        
                </> 
                 :
                 <>
                 {!MaterlSeleccionado?
                <>
                <CardBody>
           <div className=" mt-5 mb-3">
           <Form role="form">
           <FormGroup>
           <BuscadorMaterialEmpleado setMateriales={setMateriales}  traerMateriales={traerMaterialesEmpleado} EmpleadoId={parseInt(Empleado)}/>
           </FormGroup>
           <FormGroup>
              <Table responsive className='text-center'>
               <thead>
                   <tr>
                       <th>Nombre</th>
                       <th>Cantidad</th>
                       <th>Seleccionar</th>
                   </tr>
               </thead>
               <tbody>
                   {Materiales.length?
                   Materiales.map((m, index)=>{
                       return(
                   <tr key={index}>
                       <th>{m.tiposMateriale?.nombre}</th>
                       <td>{m.cantidad}</td>
                       <td>
                       <label className="custom-toggle" >
                           <input type="checkbox" onChange={()=>seleccionarMaterial(m)}
                           checked={checkeado(m.idTipoMaterial)}
                           />
                           <span
                           className="custom-toggle-slider rounded-circle"
                           data-label-off="No"
                           data-label-on="Si"
                           />
                       </label>
                       </td>
                   </tr>

                       )
                   })
               :
               <>
               <tr>
                   <td>

               <h5>No se encontro el material buscado!</h5>
                   </td>
               </tr>
               </>}

               </tbody>
              </Table>
           </FormGroup>
           </Form>                     
           </div>                    
           </CardBody>
           <CardFooter>
               <div className="text-center">
               <Button
                   className=""
                   color="primary"
                   type="button"
                   onClick={()=>establecerMaterial()}
                   
                   >
                   Confirmar
               </Button>
           
               <Button
                   color="secondary"
                   data-dismiss="modal"
                   type="button"
                   onClick={() => (setModalRecibirMaterial(false), setEmpleadoSeleccionado(""), setEmpleado(""), setCantidad(""), setMaterSeleccionado(false))}
               >
                   Cerrar
               </Button>                    
               </div>
               </CardFooter>
                </>
                :
                <>
                <CardBody>
           <div className=" mt-5 mb-3">
           <Form role="form">
           <FormGroup>
            <label>Cantidad:</label>
              <Input type='number' onChange={cambiarCantidad}/>
           </FormGroup>
           </Form>                     
           </div>                    
           </CardBody>
           <CardFooter>
               <div className="text-center">
               <Button
                   className=""
                   color="primary"
                   type="button"
                   onClick={()=>recibir()}
                   
                   >
                   Recibir
               </Button>
           
               <Button
                   color="secondary"
                   data-dismiss="modal"
                   type="button"
                   onClick={() => (setModalRecibirMaterial(false), setEmpleadoSeleccionado(""), setEmpleado(""), setCantidad(""), setMaterSeleccionado(false))}
               >
                   Cerrar
               </Button>                    
               </div>
               </CardFooter></>}
                </>
                }       
        </Card>
        </div>
        </Modal></>
  )
}

export default ModalRecibir