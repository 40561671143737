// *** cierre caja *** *** cierre caja *** *** cierre caja *** *** cierre caja ***
import { urlGlobal, urlGlobal2 } from './GlobalService';

// GET * GET * GET * GET

export async function getCierreCaja(puntoVentaId,params=null){
    try {
      let url = new URL(`${urlGlobal2}/cierreCaja/puntoVenta/${puntoVentaId}`)
      if (params != null) {
        url.search = new URLSearchParams(params).toString();
      }
      let token = localStorage.getItem('token');
      let method = 'GET';
      let headers = {'Content-Type':'application/json','token':token};
      let equipos = null;
      const resp = await fetch(url, {method,headers});
      const data = await resp.json();
      return data;    
    } catch (error) {
      alert(error)
    }
  }
  export const getCierresCajaEgresos = async(params=null, id ) =>{
    let url = new URL(`${urlGlobal2}/cierreCaja/${id}`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}
export async function putEditarCierre(cce){
  let url = `${urlGlobal2}/cierreCaja`;
  try {
      let token = localStorage.getItem('token');
      
      let headers = {'Content-Type':'application/json','token':token}
      let method= 'PUT';
      let body = JSON.stringify(cce)
      const resp = await fetch(url, {headers, body, method});
      const data = await resp.json()
      return data;
  } catch (error) {
      
      return null;
  }
}
export const cierreCajaByFecha = async(params=null, puntoVentaId ) =>{
  let url = new URL(`${urlGlobal2}/cierreCaja/puntoVenta/${puntoVentaId}`)
  if (params != null) {
      url.search = new URLSearchParams(params).toString();
  }
  try{
      let token = localStorage.getItem('token');
      let headers = {'Content-Type':'application/json', token}
      let method = 'GET';
      let resp = await fetch(url,{method, headers});
      let data = await resp.json();
      return data;
  }
  catch(error){
      alert(error.message);
      return null;
  }
}