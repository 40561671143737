import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react'
import { Button, Card, CardBody, CardHeader, Form, FormGroup, Input, InputGroup, Modal } from 'reactstrap';
import { postFacturacionManual } from 'services/FacturacionService';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ModalAggFacturacionManual = ({ setModalSeleccionar,isOpen }) => {
   
   
    const [Alert, setAlert] = useState(null)

    const validationSchema = Yup.object().shape({
        quantity: Yup.number().required('Cantidad es obligatorio').min(1, 'Cantidad debe ser al menos 1'),
        amount: Yup.number().required('Monto es obligatorio').min(1, 'Monto debe ser al menos 1'),
      });
    
      const formik = useFormik({
        initialValues: {
          quantity: 0,
          amount: 0
        },
        validationSchema,
        onSubmit: async (values) => {
          try{
            await postFacturacionManual(values);
            resetearFacturacion();
            successAlert(setAlert, 'Facturacion Agregada');
          }catch(error){
            warningAlert(setAlert, 'Error al agregar Facturacion');
          }
          
       
        }
      });

    const resetearFacturacion = () => {
        formik.resetForm();
    }

    const cerrarModal = () => {
        formik.resetForm();
        setModalSeleccionar(false);
    }

    return (
        <>
         <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={isOpen}
                toggle={() => {}}
            >
                <div className="modal-body p-0">
               
            {Alert}
            <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                    <div className="text-center">
                        <b>Agregar Facturacion</b>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => cerrarModal()}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="mt-2 mb-3">
                        <Form role="form" onSubmit={formik.handleSubmit}>
                            <FormGroup className="mb-3">
                                Cantidad:
                                <InputGroup className="input-group-alternative mt-2">
                                    <Input 
                                        type="number" 
                                        {...formik.getFieldProps('quantity')}
                                    />
                                    {formik.touched.quantity && formik.errors.quantity ? (
                                        <div style={{ color: 'red' }}>{formik.errors.quantity}</div>
                                    ) : null}
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                Monto:
                                <InputGroup className="input-group-alternative mt-2">
                                    <Input 
                                        type="number" 
                                        {...formik.getFieldProps('amount')}
                                    />
                                    {formik.touched.amount && formik.errors.amount ? (
                                        <div style={{ color: 'red' }}>{formik.errors.amount}</div>
                                    ) : null}
                                </InputGroup>
                            </FormGroup>
    
                            <div className="text-center">
                                <Button className="my-4" color="primary" type="submit">
                                    Agregar
                                </Button>
                                <Button
                                    color="secondary"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => cerrarModal()}
                                >
                                    Cancelar
                                </Button>
                            </div>
                        </Form>
                    </div>
                </CardBody>
            </Card>
                </div>                    
            </Modal>
        </>
    );
    
}

export default ModalAggFacturacionManual
