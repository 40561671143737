import React, { useEffect, useState } from 'react'
import { Button, Table } from 'reactstrap';
import EtiquetaServAsignado from './EtiquetaServAsignado';
import ModalEditarServicio from './ModalEditarServ.js';




export default function TableServicios(props){
    const {ServiciosClientes, traerServiciosClientes} = props
    const [alertSweet, setAlert] = useState(null);
    const [ModalEditarServ, setModalEditarServ] = useState(false);
    const [ServSeleccionado, setServSeleccionado] = useState(null)

    const obtenerFormatoFecha = (fechaIngreso)=>{
        let fecha = new Date(fechaIngreso);
        let dia = fecha.getDate();
        let mes = fecha.getMonth();
        let ano = fecha.getFullYear();
        let fechaFormateada = `${dia}/${mes+1}/${ano}`;
        return fechaFormateada;
    }
    
    const editarServicio = (serv)=>{
        setModalEditarServ(true); 
    }
    
      useEffect(() => {
        
      }, [])

    return(
        <>
            {alertSweet}
            <Table className="align-items-center" responsive>
                <thead className="thead-light text-center">
                <tr>
                    <th scope="col">SERVICIO ID</th>
                    <th scope="col">CLIENTE ID</th>
                    <th scope="col">USUARIO</th>
                    <th scope="col">MAC EQUIPO</th>
                    <th scope="col">FECHA</th>
                    <th scope="col">ASIGNADO</th>
                    <th scope="col">EDITAR</th>
                </tr>
                </thead>
                <tbody>
                {ServiciosClientes?
                ServiciosClientes.map((sc, index)=>{
                    return (
                    <tr key={index}>
                        <td>
                        <div className="text-center">
                                <span className="mb-0 text-sm">
                                    <b>{sc.id}</b>
                                </span>
                            </div>
                        </td>
                        <td>
                        <div className="text-center">
                                <span className="mb-0 text-sm">
                                    {sc.clientId}
                                </span>
                        </div>
                        </td>
                        <td>
                        <div className="text-center">
                                <span className="mb-0 text-sm">
                                    {sc.radiusUsername
                                    }
                                </span>
                        </div>
                        </td>
                        <td>
                        <div className="text-center">
                                <span className="mb-0 text-sm">
                                    {sc.macEquipo
                                    }
                                </span>
                        </div>
                        </td>
                        <td>
                        <div className="text-center">
                                <span className="mb-0 text-sm">
                                    {obtenerFormatoFecha(sc.creado)}
                                </span>
                        </div>
                        </td>
                        <td>
                            <div className="text-center">
                                <EtiquetaServAsignado  setAlert={setAlert} ServiciosClientes={sc} Asignado={sc.asignado} traerServiciosClientes={traerServiciosClientes}/>
                            </div>
                        </td>
                        <td>
                        <div className="text-center">
                                <Button className="btn-default"   onClick={()=>{setServSeleccionado(sc);editarServicio(sc)}}>
                                Editar
                                </Button>
                        </div>
                        </td>
                        
                    
                    </tr>

                    )
                })
                :
                <p>Cargando...</p>
                }
                </tbody>
            </Table>
            {ServSeleccionado!=null?
                <ModalEditarServicio 
                    ModalEditarServ={ModalEditarServ}
                    setModalEditarServ={setModalEditarServ}
                    traerServiciosClientes={traerServiciosClientes}
                    setAlert={setAlert}
                    ServSelec={ServSeleccionado}
                />
            
                :
                <></>
            }
        </>
        
    )
}