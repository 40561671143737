
import { useCallback, useEffect, useState } from "react";
import {Button,Table,Modal, Badge} from "reactstrap";

export const ModalDiferencias = ({
  Caes,
  resumenPagos,
  defaultModal,
  setdefaultModal,
}) => {
    // resumen = conteo de montos
    const [Diferencia,setDiferencia] = useState([]);

    const compararPorImporte = (a, b) => {
      return b.importe - a.importe;
    }

    const GenerarResumenCaes = useCallback((caes) => {
      if (caes.length === 0) { return [] }
      let resumen = [];
      caes.forEach(d => {
        let encontro = false;
        resumen.forEach(r => {
          if (r.importe === d.importe) {
            r.cantidad = r.cantidad + 1
            encontro = true;
          }
        });
        if (!encontro) {
          resumen.push(
            {
              importe: d.importe,
              cantidad: 1
            }
          )
        }
      });
      resumen.sort(compararPorImporte);
      return resumen;
  }, []);

  const CalcularDiferenciasContraPagos = useCallback((resumenPagos, resumenCaes) => {
    let diferencias = [];
    resumenPagos.forEach((r) => {
      let cantR = r.cantidad;
      while (true) {
        let caeIndex = resumenCaes.findIndex((c) => {
          let restaComp = c.importe - r.monto;
          restaComp = restaComp.toFixed(2);
          return Math.abs(restaComp) <= 0.01
        });
        if (caeIndex === -1) {
          break;
        }
        let cae = resumenCaes.splice(caeIndex, 1)[0]
        cantR = cantR - cae.cantidad;
      }
      let diferencia = {
        importe: r.monto,
        cantidad: cantR,
      };

      diferencias.push(diferencia);
    });
    return diferencias;
  }, []);

  const AgregarImportesFaltantesEnPagos = useCallback((resumenCaes, diferencias) => {
    resumenCaes.forEach((c) => {
      let dif = diferencias.find((d) => {
        let restaComp = d.importe - c.importe
        restaComp = restaComp.toFixed(2);
        return Math.abs(restaComp) <= 0.01
      });
      if (!dif) {
        let nuevaDiferencia = {
          importe: c.importe,
          cantidad: c.cantidad * -1,
        };
        diferencias.push(nuevaDiferencia);
      } else {
        dif.cantidad = dif.cantidad - c.cantidad;
      }
    });
  }, []);

  const RealizarDifecia = useCallback((cs, resumenPagos) => {
    let resumenCaes = GenerarResumenCaes(cs);
    let diferencias = CalcularDiferenciasContraPagos(resumenPagos, resumenCaes);
    AgregarImportesFaltantesEnPagos(resumenCaes, diferencias);
    diferencias.sort(compararPorImporte);
    return diferencias;
  }, [GenerarResumenCaes, CalcularDiferenciasContraPagos, AgregarImportesFaltantesEnPagos]);



    useEffect(() => {
      if(Caes && resumenPagos){
            let diferencia = RealizarDifecia(Caes, resumenPagos);
            setDiferencia(diferencia);
      }
    }, [Caes,resumenPagos, RealizarDifecia])




    return(
        <Modal
        size="lg"
        className="modal-dialog-centered"
        isOpen={defaultModal}
        toggle={() => setdefaultModal(false)}
      >
        <div className="modal-header">
              <h4 className="modal-title" id="modal-title-default">
                Diferencia entre Pagos y Caes
              </h4>
        </div>
        <div className="modal-body">
         
          
          <Table className="align-items-center table-flush" hover responsive>
            <thead className="thead-light">
              <tr>
                <th>Importe</th>
                <th>Cantidad</th>
                <th>Faltante</th>
              </tr>
            </thead>
            <tbody>
                  {
                      Diferencia?
                      
                      Diferencia.map((rs, index) =>{

                          return(
                          <tr key={index}>
                              <td className="table-user">
                              <b>$ {rs.importe}</b>
                              </td>
                              <td className="table-user">
                              <b>{rs.cantidad}</b>
                              </td>
                              <td className="table-user">
                              {rs.cantidad < 0 ? 
                                  <Badge color="info" pill>
                                  Pagos
                                  </Badge>
                              : 
                                <>
                                {rs.cantidad > 0 ?
                                  <Badge color="warning" pill>
                                  caes
                                  </Badge>
                                  :
                                  <></>
                                  }
                                </>
                              }
                              </td>
                          </tr>
                          )
                     
                      })
                     
                      :null


                  }
                 

                

             
              
            </tbody>
          </Table>
          
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setdefaultModal(false)}
          >
            Cerrar
          </Button>
        </div>
      </Modal>
    )
}