import { warningAlert } from 'pages/components/SweetAlert'
import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, Col, Input, Row } from 'reactstrap'
import { getEgresos } from 'services/EgresosService'
import TableEgresos from './components/TableEgresos'

const Egresos = props => {
    const [Egresos, setEgresos] = useState([]);
    const [Desde, setDesde] = useState("");
    const [Hasta, setHasta] = useState("");
    const [Alert, setAlert] = useState(null)
    
    const traerEgresos = async()=>{
        let params ={page:1}
        let egresos = await getEgresos(params);
        if (egresos !== null && egresos !== "") {
            setEgresos(egresos)
        }
    }
    const filtrar = async()=>{
        let params = {fechaInicio: Desde, fechaFin: Hasta}
        let egresos = await getEgresos(params);
        if (!Desde || !Hasta) {
            warningAlert(setAlert, 'Seleccione ambas fechas!')
        }else if (Desde >=Hasta) {
            warningAlert(setAlert, 'La fecha final es menor a la fecha inicial seleccionada!')
        }
        if (egresos !== null && egresos !== "") {
            setEgresos(egresos)
        }
    }
    const cambiarDesde = event => {
        setDesde(event.target.value);
      };
    const cambiarHasta = event => {
    setHasta(event.target.value);
    };
useEffect(() => {
    traerEgresos();
    //eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

  return (
    <>
    {Alert}
    <Card className='mt-3 mr-3 ml-3'>
        <CardHeader>
            <Row>
                <h1 className='mt-3 ml-3'>Egresos</h1>
            </Row>
            <Row>
                <Col>
                <Row>
                <Col>
                    <label>Desde:</label>
                    <Input
                    id="example-date-input"
                    type="date"
                    value={Desde} onChange={cambiarDesde}
                    />
                </Col>
                <Col>
                    <label>Hasta:</label>
                    <Input
                    id="example-date-input"
                    type="date"
                    value={Hasta} onChange={cambiarHasta}
                    />
                </Col>
                <Col>
                <div className='mt-2'>
                <Button className='btn-outline-success float-left mt-4'onClick={()=>filtrar()}>
                    Filtrar
                </Button>
                </div>
                </Col>
                </Row>
                </Col>
            </Row>
        </CardHeader>
     <TableEgresos 
     Egresos={Egresos}
     getEgresos={getEgresos}
     traerEgresos={traerEgresos}
     setEgresos={setEgresos}
     />
    </Card>
    </>
  )
}
export default Egresos