import React from "react";
import {
  Button,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";




function VerCuenta(){
   
    return (
        <>
        
        Hola cuenta
          </>
          )
}


export default VerCuenta;
