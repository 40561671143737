import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Button, Card, CardBody, CardHeader, Row } from 'reactstrap'
import TableHistorico from './components/TableHistorico'

const Historico = props => {
  const location = useLocation();
  const history = useHistory();
  const idTipoMaterial = location.state? location.state.tipoMaterialId : null;
  const nombre = location.state? location.state.nombre : null;
  const almacenId = location.state? location.state.idAlmacen : null
  const irAtras = () => {
    let AlmacenId = location.state.idAlmacen
    let Almacenes = location.state.Almacenes
    if (Almacenes === true) {
      history.replace(`/admin/almacenes`)
    } else {
      history.replace(`/admin/stock/${AlmacenId}`)
    }
  }
  
  return (
    <>
    <Card>
        <CardHeader>
          <Row>
            {idTipoMaterial?
            <>
            <Button className='btn btn-md btn-default' onClick={()=>irAtras()}>Atras</Button>
            </>
            :
            <></>}
        <h1>Historico:  </h1>  {nombre?   <h1> {  nombre}</h1>: <></>}
          </Row>
        </CardHeader>
        <CardBody>
            <TableHistorico idTipoMaterial={idTipoMaterial} AlmacenId={almacenId}/>
        </CardBody>
    </Card>
    
    </>
  )
}


export default Historico