import { urlGlobal2 } from './GlobalService';


// *** ESTADOS *** *** ESTADOS *** *** ESTADOS *** *** ESTADOS ***

export async function getEstados(params=null){
  try {
    let url = new URL(`${urlGlobal2}/estados`)
    if (params != null) {
      url.search = new URLSearchParams(params).toString();
    }
    let token = localStorage.getItem('token');
    let method = 'GET';
    let headers = {'Content-Type':'application/json','token':token};
    const resp = await fetch(url, {method,headers});
    const data = await resp.json();
    return data;    
  } catch (error) {
    alert(error)
  }
}
export async function getEstadosRegistros(params=null){
  try {
    let url = new URL(`${urlGlobal2}/estadosregistros`)
    if (params != null) {
      url.search = new URLSearchParams(params).toString();
    }
    let token = localStorage.getItem('token');
    let method = 'GET';
    let headers = {'Content-Type':'application/json','token':token};
    const resp = await fetch(url, {method,headers});
    const data = await resp.json();
    return data;    
  } catch (error) {
    alert(error)
  }
}

  
  
  export async function postEstados(token, estados){
    let output = null;
    let estadoCreado = null;
    await fetch(`${urlGlobal2}/estados`, {
        method: 'POST',
        headers: {
          'Content-Type':'application/json',
          'token':token
        },
        body:JSON.stringify(estados)
    })
        .then(response => output = response.json())
        .then(data => {
          if(data.ok){
            estadoCreado = data.mensaje;
          }else{
            estadoCreado = '';
          }
        })
        .catch((error) => alert(error));
    return estadoCreado;
  }
  