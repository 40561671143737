import SubirFotoComponent from 'pages/components/SubirFoto';
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap';
import { postRendiciones } from 'services/RendicionesServices';

const ModalAggRendicion = props => {
    const {ModalAgregarRendicion, setModalAgregarRendicion, traerRendiciones, idEmpls}= props;
    const [Monto, setMonto] = useState("");
    const [Foto, setFoto] = useState(null);
    const [Alert, setAlert] = useState(null)
    
    const cambiarMonto = event => {
      setMonto(event.target.value);
    };

    const AgregarRendiciones = async()=>{
    
        let rendi = {
          empleadoId: idEmpls,
          monto: Monto,
  
        }
      let result = await postRendiciones(rendi)
      if(result != null){
        setMonto("")
        setFoto("")
        setModalAgregarRendicion(false)
        traerRendiciones()
  
        successAlert(setAlert, "Rendicion agregada correctamente!", "");
    }else{
      warningAlert(setAlert,'No se pudo agregar rendicion')
    }
  }


  return (
    <>
    {Alert}
    <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={ModalAgregarRendicion}
          toggle={() => setModalAgregarRendicion(false)}
        >
        <div className="modal-body p-0">
            <CardHeader>
                <div className="text-center"><b>Nueva Rendiciones</b>
                    <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setModalAgregarRendicion(false)}
                            >
                        <span aria-hidden={true}>×</span>
                    </button>
              </div>
            </CardHeader>                  
            <CardBody>
                <Form role="form">
                <FormGroup >
                  Monto:
                    <Input placeholder="Monto" type="number" value={Monto} onChange={cambiarMonto} />
                </FormGroup>
                </Form>                     
                <SubirFotoComponent setFoto={setFoto}/>
            </CardBody>
            <CardFooter>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={()=>AgregarRendiciones()}
                  >
                    Agregar
                  </Button>
              
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalAgregarRendicion(false)}
                  >
                    Cerrar
                  </Button>                    
                </div>
            </CardFooter>
        </div>
        </Modal>
    </>
  )
}


export default ModalAggRendicion