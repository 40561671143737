import SelectorGenerico from 'pages/components/SelectorGenerico';
import { successAlert, warningAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, 
  InputGroupAddon, InputGroupText, Modal } from 'reactstrap';
import { putPermisos } from 'services/PermisosService';
import { getGruposPermisos } from 'services/PermisosService';
import { postPermisos } from 'services/PermisosService';


const ModalAgregarEditarP = (props) => {

    const {ModalAddP, setModalAddP, traerGP_C_P, per, setPermiso}=props;
    const [alertSweet, setAlert] = useState(null);
    const [nombre, setNombre] = useState("");
    const [slug, setSlug] = useState("");
    const [grupoPermisoId, setGrupoPermisoId] = useState(null);
    const [GP, setGP] = useState([]);
    
    const cambiarNombre = event => {
        setNombre(event.target.value);
    };

    const cambiarSlug = event => {
      setSlug(event.target.value);
    };

    const Accion = ()=>{
      if(per){EditarP()}else{AgregarP()}
    }

    const traerGP=async()=>{
      let gPermisos= await getGruposPermisos();
      if (gPermisos !=null && gPermisos !="") {  
        setGP(gPermisos);
          
      }
    }

    const AgregarP = async()=>{
        let p = {
          nombre,
          slug,
          grupoPermisoId
        }
      let result = await postPermisos(p)
      if(result != null){
        setNombre("")
        setSlug("")
        setGrupoPermisoId(null)
        setModalAddP(false)
        traerGP_C_P()
        successAlert(setAlert, "Permiso agregado correctamente!", "")
    }else{
      warningAlert(setAlert,'No se pudo agregar Permiso')
    }
    }

    const EditarP = async()=>{
      let p = {
        id:per.id,
        nombre,
        slug,
        grupoPermisoId
      }
    let result = await putPermisos(p)
    if(result != null){
      setNombre("")
      setSlug("")
      setGrupoPermisoId(null)
      setModalAddP(false)
      successAlert(setAlert, "Permiso Esita correctamente!", "")
      traerGP_C_P()
    }else{
    warningAlert(setAlert,'No se pudo editar Permiso')
  }
  }
  
  const establecerPermiso= () => {
    if (per != null) {
      setGrupoPermisoId(per.grupoPermisoId)
      setNombre(per.nombre)
      setSlug(per.slug)
    }
  }
  
  const cerrar = () => {
    setModalAddP(false);
  }

    useEffect(() => {
      establecerPermiso()
      traerGP();
    }, [])

  return(
      <>
      {alertSweet}
      <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={ModalAddP}
                            toggle={() => setModalAddP(false)}
                          >
                          <div className="modal-body p-0">
                            <Card className="bg-secondary shadow border-0">
                              <CardHeader className="bg-transparent pb-5">
                                <div className="text-center"><b>{per?"Editar Permiso":"Agregar Permiso"}</b>
                                <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => setModalAddP(false)}
                                >
                                  <span aria-hidden={true}>×</span>
                                </button>
                                </div>
                                <div className="text-muted mt-2 mb-3">
                                <Form role="form">
                                  <FormGroup className="mb-3" >Nombre:
                                    <InputGroup className="input-group-alternative mt-2">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                        <i className="fas fa-user"></i>
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input value={nombre} onChange={cambiarNombre} placeholder="Nombre" type="text" />
                                    </InputGroup>
                                  </FormGroup>
                                  <FormGroup className="mb-3 ">Descripcion:
                                        <Input
                                          value={slug} 
                                          onChange={cambiarSlug}
                                          className="form-control-lg"
                                          placeholder="Descripcion del Permiso"
                                          type="textarea"
                                          maxLength={160}
                                        />
                                  </FormGroup>
                                  {GP?              
                                  <FormGroup>
                                    <label htmlFor="exampleFormControlSelect1">GrupoPermisosId:</label>
                                    <SelectorGenerico Valor={grupoPermisoId} setValor={setGrupoPermisoId} arrayElementos={GP}/>
                                    <h6 >Se puede buscar apretando repetidas veces la letra inicial</h6>
                                  </FormGroup>
                                  :<></>} 
                                  <div className="text-center">
                                    <Button
                                      className="my-4"
                                      color="primary"
                                      type="button"
                                      onClick={()=>{Accion()}}
                                    >
                                     {per?"Editar":"Agregar"}
                                    </Button>
                                
                                    <Button
                                      color="secondary"
                                      data-dismiss="modal"
                                      type="button"
                                      onClick={() => {cerrar()}}
                                    >
                                      Cerrar
                                    </Button>                    
                                  </div>
                                </Form>                     
                                </div>                    
                              </CardHeader>                  
                            </Card>
                          </div>
                          </Modal>
      </>
  )
};

export default ModalAgregarEditarP;
