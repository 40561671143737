import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap'
import {warningAlert, successAlert, confirmAlert} from "../../../components/SweetAlert"
import LoadingComponent from 'pages/components/Loading'
import { postPagosCrm } from 'services/PagosService'
import {getDebt} from 'services/ClientesServices';

export default function ModalAPagos(props){
    const {
        ModalAgregarPagos, setModalAgregarPagos, idClient, traerPagosCliente, 
      } = props
      
      const [Alert, setAlert] = useState(null);
      const [Visible, setVisible] = useState(false);
      const [Importe, setImporte] = useState(null);
      const [Nota, setNota] = useState("");
     
      const cambiarImporte = (e) => {
        setImporte(e.target.value)
      }
      const cambiarNota = (e) => {
        setNota(e.target.value)
      }
      const confirmar = ()=>{
        confirmAlert(setAlert, AgregarPago, null, "Seguro que desea agregar un Pago?")
      }
      const AgregarPago= async()=> {
        setVisible(true)
        let pago = {
          clientId: parseInt(idClient),
          amount:Importe,
          note: Nota,
        }
        let resp = await postPagosCrm(pago);
        if(resp){
        setImporte(null);
        setNota("");
        successAlert(setAlert,"Pago agregado correctamente");
        setModalAgregarPagos(false);
        traerPagosCliente();
        
        }
        setVisible(false)
  
      }


    useEffect(() => {
      getDebt(idClient).then(data => {
        
        
        if(data.debt > 0){
          setImporte(data.debt)
        }
        else{
          setImporte(data.pagoAdelantado)
        }
        
        
      })
    },[])
      
    

    return(
      <>
        {Alert}
        <LoadingComponent Visible={Visible}/>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={ModalAgregarPagos}
          toggle={() => setModalAgregarPagos(false)}
        >
        <div className="modal-body p-0">
            <CardHeader>
              <div className="text-center"><b>Nuevo Pago</b>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalAgregarPagos(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
              </div>
              </CardHeader> 
              <CardBody>
              <Form role="form">
              
              <FormGroup className="mb-3">Importe a pagar:
                  <h2>$ {Importe}</h2>
              </FormGroup> 

                <FormGroup className="mt-5">Nota:
                    <Input placeholder="Nota..." type="textarea"onChange={cambiarNota} value={Nota} className="mt-2"/>
                </FormGroup> 
              </Form>    
              </CardBody>
              <CardFooter>
                  <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={()=>confirmar()}
                  >
                    Agregar
                  </Button>
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalAgregarPagos(false)}
                  >
                    Cerrar
                  </Button>                    
                </div>

              </CardFooter>
              </div>
        </Modal>
      </>
    )
}