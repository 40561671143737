import React, { useEffect, useState } from 'react'
import { Button, Media, Table } from 'reactstrap';
import { deleteCategoria } from 'services/CategoriasService';
import { getRolPermisos } from 'services/RolService';
import { confirmAlert, successAlert } from "../../../components/SweetAlert";
import jwt_decode from "jwt-decode";

export default function TableCategoriasEquipos(props){
    const {CategoriasEquipos, traerCategoriasEquipos, setCategoriaEquiposSeleccionada,  setVerModalEditarCategoriaEquipos} = props
    const [alertSweet, setAlert] = useState(null);
    const [Permisos, setPermisos] = useState([]);

    const editarCategoria = (cat) => {
        setCategoriaEquiposSeleccionada(cat);
        setVerModalEditarCategoriaEquipos(true);
    }

      const eliminarCategoria = async(id)=>{
          if(id){
              let remEq = await deleteCategoria(id);
              if(remEq){traerCategoriasEquipos();successAlert(setAlert, "Categoria eliminada correctamente")}
          }
      }

    const confirmarEliminacion = (idEquip) => {
        confirmAlert(setAlert, eliminarCategoria, idEquip, "¿Seguro que desea eliminar el equipo?")
        
    }
    const traerPermisos= async()=>{

        let permisos= await getRolPermisos();
        if(permisos !=null && permisos!=''){
          setPermisos(permisos)
        }
    }
    const tienePermiso=(nombrePermiso)=>{
        return Permisos.includes(nombrePermiso);
    }

    const esAdmin=()=>{
        let token = localStorage.getItem('token');
        let decod = jwt_decode(token)
        return decod.usuario.admin;
    }

    const corregirValor = (valor) => {
        if(valor == null){ 
            return "-"
        }else if (valor == true){
            return "Si"
        }else if (valor == false){
            return "No"
        }else{
            return valor
        }
    }

    useEffect(() => {
        traerPermisos();
      }, [])

    return(
        <>
            {alertSweet}
            <Table className="align-items-center" responsive>
                <thead className="thead-light">
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Punto de Reposicion</th>
                    <th scope="col">Formato Escaneo</th>
                    <th scope="col">Precio</th>
                    <th scope="col">Pago en Cuotas</th>
                    <th scope="col">Cantidad de Cuotas</th>
                    <th scope="col">Tiene Mac</th>
                    {Permisos && tienePermiso("Editar-General")?
                      <th scope="col">Editar</th>
                      :
                      <></>
                    }
                    {Permisos && tienePermiso("Eliminar-General")?
                    <th scope="col">Eliminar</th>
                    :
                    <></>
                  }
                </tr>
                </thead>
                <tbody>
                {CategoriasEquipos?
                CategoriasEquipos.map((cat, index)=>{
                    return (
                    <tr key={index}>
                        <td>
                            <Media className="align-items-center">
                                <span className="mb-0 text-sm">
                                    {cat.id}
                                </span>
                            </Media>
                        </td>
                        <td>
                        <Media>
                                <span className="mb-0 text-sm">
                                    {cat.nombre}
                                </span>
                        </Media>
                        </td>
                        <td>
                        <Media>
                                <span className="mb-0 text-sm">
                                    {cat.puntoReposicion}
                                </span>
                        </Media>
                        </td>
                        <td>
                        <Media>
                                <span className="mb-0 text-sm">
                                    {cat.formatoEscaneo}
                                </span>
                        </Media>
                        </td>
                        <td>
                        <Media>
                                <span className="mb-0 text-sm">
                                    {corregirValor(cat.precio)}
                                </span>
                        </Media>
                        </td>
                        <td>
                        <Media>
                                <span className="mb-0 text-sm">
                                    {corregirValor(cat.pagoEnCuotas)}
                                </span>
                        </Media>
                        </td>
                        <td>
                        <Media>
                                <span className="mb-0 text-sm">
                                    {corregirValor(cat.cantidadCuotas)}
                                </span>
                        </Media>
                        </td>
                        <td>
                        <Media>
                                <span className="mb-0 text-sm">
                                    {corregirValor(cat.tieneMac)}
                                </span>
                        </Media>
                        </td>
                        {Permisos && tienePermiso("Editar-General")?
                        <td className="align-items-center">
                            <div className="d-flex align-items-center">
                                <Button className="btn-icon btn-3 btn-sm btn-outline-success rounded-circle" color="danger"  type="button" onClick={()=>{editarCategoria(cat)}}>
                                <span className="btn-inner--icon">
                                <i className="fas fa-edit"/>
                                </span>
                                </Button>
                            </div>
                        </td>
                        :
                        <></>
                      }
                        {Permisos && tienePermiso("Eliminar-General")?
                        <td className="align-items-center">
                            <div className="d-flex align-items-center">
                                <Button className="btn-icon btn-3 btn-sm btn-outline-danger rounded-circle" color="danger"  type="button" onClick={()=>{confirmarEliminacion(cat.id)}}>
                                    <span className="btn-inner--icon" style={{padding:'0rem'}}>
                                        <i style={{fontSize:'1.5rem'}} className="ni ni-fat-remove"/>
                                    </span>
                                </Button>
                            </div>
                        </td>
                        :
                        <></>
                      }
                    </tr>

                    )
                })
                :
                <p>Cargando...</p>
                }
                </tbody>
            </Table>
        </>
    )
}