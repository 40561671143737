import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap'
import { putSolicitudesCambios } from 'services/PedidoCambiosService';

const ModalEditSolicitudes = props => {
    const {ModalEditarSolicitud, setModalEditarSolicitud, idSolicitud, NombreCambio, traerSolicitudes} = props;
    const [Importe, setImporte] = useState("");
    const [Fecha, setFecha] = useState("");
    const [Alert, setAlert] = useState(null)

    const cambiarFecha = (e) => {
        setFecha(e.target.value)
    }
    const cambiarImporte = (e) => {
        setImporte(e.target.value)
    }
    const editar = async() => {
        let obj = {
            id: idSolicitud,
            fechaAplicacion: Fecha,
            cambios : JSON.stringify({nombre : NombreCambio, importe : Importe}),
        };
        let sd = await putSolicitudesCambios(obj)
        if (sd !== null) {
            successAlert(setAlert, "Solicitud Editada!");
            setFecha("");
            setImporte("")
            traerSolicitudes()
            setModalEditarSolicitud(false)
        } else {
            warningAlert(setAlert, "No se pudo editar la solicitud!")
        }
    }
  return (
    <>
    {Alert}
    <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={ModalEditarSolicitud}
        toggle={() => setModalEditarSolicitud(false)}
        >
        <div>
        <Card>
            <CardHeader className="pb-3">
            <div className="text-center"><b>Editar Solicitud</b>
            <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setModalEditarSolicitud(false)}
        >
            <span aria-hidden={true}>×</span>
        </button>
            </div>
            </CardHeader>  
            <CardBody>
            <div className=" mt-5 mb-3">
            <Form role="form">
            <FormGroup>
                <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Importe:
                </label>
                <Input
                    type="number"
                    value={Importe} 
                    onChange={cambiarImporte}
                    />
            </FormGroup>
            <FormGroup>
                <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Fecha de Aplicacion:
                </label>
                <Input
                    id="example-date-input"
                    type="date"
                    value={Fecha} onChange={cambiarFecha}
                    />
            </FormGroup>
            </Form>                     
            </div>                    
            </CardBody>
            <CardFooter>
                <div className="text-center">
                <Button
                    className=""
                    color="primary"
                    type="button"
                    onClick={()=>editar()}
                    
                    >
                    Actualizar
                </Button>
            
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalEditarSolicitud(false)}
                >
                    Cancelar
                </Button>                    
                </div>
                </CardFooter>                
        </Card>
        </div>
        </Modal>
    
    </>
  )
}

export default ModalEditSolicitudes