import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody, CardHeader, Table } from 'reactstrap';
import ModalAgregarRep from '../components/ModalAgregarReporte';

const Reportes = props=> {
    const {idEmpls}=props;

    const [ModalAgregarReporte, setModalAgregarReporte]= useState(false);
    
   
   useEffect(() => { 
       
    });

    return (
      <>
      <Card style={{width:"100%"}} className="mt-3">
        
        <CardBody>
        <Button className="btn-info btn-md  mb-2 float-left" onClick={()=>setModalAgregarReporte(true)}>
            Agregar Reporte
          </Button>
          <Table className="align-items-center" responsive>
            <thead className="thead-light text-center">
              <tr>
              <th scope="col">CREADO</th>
              <th scope="col">CLASIFICACION</th>
              <th scope="col">DESCRIPCION</th>
              <th scope="col">FOTO</th>
              </tr>
            </thead>
            <tbody>
            <tr>
                    <th scope="row">
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            21/01/2022
                          </span>
                    </div>
                    </th>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            <Badge className=" badge-sm" color="success">
                              BUENO
                            </Badge>
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            se porto bien
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                          <Button className="btn-info btn-sm ">Ver Foto</Button>
                          </span>
                    </div>
                    </td>
                    </tr>
            </tbody>
          </Table>
        </CardBody>
         
      </Card>

      <ModalAgregarRep
      ModalAgregarReporte={ModalAgregarReporte}
      setModalAgregarReporte={setModalAgregarReporte}
      />

      </>
    )
}


export default Reportes
