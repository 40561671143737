import SubirFotoComponent from 'pages/components/SubirFoto';
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap';
import { postArchivo } from 'services/FileServices';
import { putEditarVehiculo } from 'services/VehiculosServices';

const ModalEditarVeh =(props)=> {
    const {ModalEditarVehiculo, setModalEditarVehiculo, traerVehiculo, idVehiculo}= props;

    const [Marca, setMarca] = useState("");
    const [Modelo, setModelo] = useState("");
    const [Patente, setPatente] = useState("");
    const [Año, setAño] = useState("");
    const [Chasis, setChasis] = useState("");
    const [Motor, setMotor] = useState("");
    const [Alert, setAlert] = useState(null);
    const [Foto, setFoto] = useState(null);

    

    const cambiarMarca = event => {
        setMarca(event.target.value);
      };
      const cambiarModelo = event => {
        setModelo(event.target.value);
      };
      const cambiarPatente = event => {
        setPatente(event.target.value);
      };
      const cambiarAño = event => {
        setAño(event.target.value);
      };
      const cambiarChasis = event => {
        setChasis(event.target.value);
      };
      const cambiarMotor = event => {
        setMotor(event.target.value);
      };

      
      const actualizarVehiculo= async()=>{
        let vehiculoAux = {}
        Object.assign(vehiculoAux)
        vehiculoAux.id= idVehiculo;
        vehiculoAux.marca = Marca;
        vehiculoAux.modelo = Modelo;
        vehiculoAux.patente = Patente;
        vehiculoAux.año = Año;
        vehiculoAux.nroChasis = Chasis;
        vehiculoAux.nroMotor = Motor;
        vehiculoAux.foto = Foto
        let valido = await putEditarVehiculo(vehiculoAux);
        if(valido){
          successAlert(setAlert,'Vehículo Actualizado');
          setMarca("")
          setModelo("")
          setPatente("")
          setAño("")
          setChasis("")
          setMotor("")
          setModalEditarVehiculo(false);
          traerVehiculo()
        }else{
          warningAlert(setAlert,'No se pudo Editar el Vehículo')
      }
      
      }
      
  useEffect(() => {

}, [])

    return (
       <>
       {Alert}
       
       <Modal
       className="modal-dialog-centered"
       size="sm"
       isOpen={ModalEditarVehiculo}
       toggle={() => {traerVehiculo();setModalEditarVehiculo(false)}}
     >
     <div className="modal-body p-0">
       <Card className="bg-secondary shadow border-0">
         <CardHeader className="bg-transparent pb-5">
           <div className="text-center"><b>Editar Vehículo</b>
           <button
             aria-label="Close"
             className="close"
             data-dismiss="modal"
             type="button"
             onClick={() => {traerVehiculo();setModalEditarVehiculo(false)}}
           >
             <span aria-hidden={true}>×</span>
           </button>
           </div>
           
           <div className="text-muted mt-2 mb-3">
           <Form role="form">
           <FormGroup className="mb-3" >Marca:
               <InputGroup className="input-group-alternative mt-2">
                 <InputGroupAddon addonType="prepend">
                   <InputGroupText>
                   </InputGroupText>
                 </InputGroupAddon>
                 <Input 
                 type="text" 
                 placeholder="Marca..."
                 value={Marca} 
                 onChange={cambiarMarca}
                 />
               </InputGroup>
             </FormGroup>
             <FormGroup className="mb-3" >Modelo:
               <InputGroup className="input-group-alternative mt-2">
                 <InputGroupAddon addonType="prepend">
                 <InputGroupText>
                   </InputGroupText>                                        
                 </InputGroupAddon>
                 <Input 
                 placeholder="Modelo..." 
                 type="text" 
                 value={Modelo} 
                 onChange={cambiarModelo}
                 />
               </InputGroup>
             </FormGroup>
           <FormGroup className="mb-3" >Patente:
               <InputGroup className="input-group-alternative mt-2">
                 <InputGroupAddon addonType="prepend">
                   <InputGroupText>
                   </InputGroupText>
                 </InputGroupAddon>
                 <Input 
                 placeholder="Patente..." 
                 type="text" 
                 value={Patente} 
                 onChange={cambiarPatente}
                 />
               </InputGroup>
             </FormGroup>
             <FormGroup > Año:
               <InputGroup className="input-group-alternative mt-2">
                 <InputGroupAddon addonType="prepend">
                   <InputGroupText>
                   </InputGroupText>
                 </InputGroupAddon>
                 <Input 
                 placeholder="Año..." 
                 type="number" 
                 value={Año} 
                 onChange={cambiarAño}
                 />
               </InputGroup>
             </FormGroup>
             <FormGroup > Número de Chasis:
               <InputGroup className="input-group-alternative mt-2">
                 <InputGroupAddon addonType="prepend">
                   <InputGroupText>
                   </InputGroupText>
                 </InputGroupAddon>
                 <Input 
                 placeholder="Numero de Chasis..." 
                 type="text" 
                 value={Chasis} 
                 onChange={cambiarChasis}
                 />
               </InputGroup>
             </FormGroup>
             <FormGroup > Número de Motor:
               <InputGroup className="input-group-alternative mt-2">
                 <InputGroupAddon addonType="prepend">
                   <InputGroupText>
                   </InputGroupText>
                 </InputGroupAddon>
                 <Input 
                 placeholder="Numero de Motor..." 
                 type="text" 
                 value={Motor} 
                 onChange={cambiarMotor}
                 />
               </InputGroup>
             </FormGroup>
            </Form>                     
            <SubirFotoComponent setFoto={setFoto}/>
             <div className="text-center">
               <Button
                 className="my-4"
                 color="primary"
                 type="button"
                 onClick={()=>actualizarVehiculo()}
               >
                 Editar
               </Button>
           
               <Button
                 color="secondary"
                 data-dismiss="modal"
                 type="button"
                 onClick={() => setModalEditarVehiculo(false)}
               >
                 Cerrar
               </Button>                    
             </div>
           </div>               
         </CardHeader>                  
       </Card>
     </div>
     </Modal>
     
               
       </>
    )
}


export default ModalEditarVeh
