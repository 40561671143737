import React, { useState } from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import classnames from "classnames";
import LoadingComponent from 'pages/components/Loading';
import PropTypes from 'prop-types'


export default function PaginacionDatosClientes({ traerClientes, setClientes, getClientes }) {
    const [paginado, setPaginado] = useState(1);
    const [visible, setVisible] = useState(false);
    const [estado, setEstado] = useState(1);

    const toggleNavs = (e, state, index) => {
        e.preventDefault();
        setEstado(index);
    };

    const paginar = async (accion) => {
        setVisible(true)
        if (accion === "ATRAS") {
            if (paginado >= 1) {
                setPaginado(pagAnt => {
                    pagAnt = pagAnt - 1;
                    setPaginado(pagAnt)
                    setEstado(pagAnt  === 1 || pagAnt  === 2 || pagAnt  === 3 ? pagAnt : null);
                })
            }

        } else if (accion === "1") {
            setPaginado(1);
            setEstado(1);


        } else if (accion === "2") {
            setPaginado(2);
            setEstado(2);


        } else if (accion === "3") {
            setPaginado(3)
            setEstado(3);


        } else if (accion === "ADELANTE") {
            setPaginado(pagAnt => {
                pagAnt = pagAnt + 1;
                setPaginado(pagAnt)
                setEstado(pagAnt  === 1 || pagAnt  === 2 || pagAnt  === 3 ? pagAnt : null);
            })
        }
        await PaginarClientes()
        setVisible(false)
    }

    const PaginarClientes = async()=>{
        let params = null;
        if (paginado >= 1) {
            params = { page: paginado }
        }
        let result = await getClientes(params)

        if (result) {
           await  setClientes(result.itemsClientes);

        } else { alert("no se pudo cambiar de pagina"); traerClientes({page:1}) }
    }


    return (
        <>
            <LoadingComponent Visible={visible} />

            <nav aria-label="Page navigation example">
                <Pagination
                    className="pagination justify-content-center flex-column flex-sm-row"
                    id="tabs-text"
                    role="tablist"
                >
                    {(paginado - 1) > 0?
                    <PaginationItem>
                        <PaginationLink

                            onClick={() => paginar("ATRAS")}
                            tabIndex="-1"
                            role="tab"
                        >
                            <i className="fa fa-angle-left" />
                            <span className="sr-only">Atras</span>
                        </PaginationLink>
                    </PaginationItem>
                    :
                    <></>
                     }
                    <PaginationItem >
                        <PaginationLink
                            aria-selected={estado === 1}
                            className={estado === 1 ? "mb-sm-3 mb-md-0 bg-default" : "mb-sm-3 mb-md-0"}
                            onClick={e => { toggleNavs(e, "navPills", 1); paginar("1") }}
                            role="tab"
                        >
                            1
                        </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                        <PaginationLink
                            aria-selected={estado === 2}
                            className={estado === 2 ? "mb-sm-3 mb-md-0 bg-default" : "mb-sm-3 mb-md-0"}
                            onClick={e => { toggleNavs(e, "navPills", 2); paginar("2") }}
                            role="tab"
                        >
                            2
                        </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                        <PaginationLink
                            aria-selected={estado === 3}
                            className={estado === 3 ? "mb-sm-3 mb-md-0 bg-default" : "mb-sm-3 mb-md-0"}
                            onClick={e => { toggleNavs(e, "navPills", 3); paginar("3") }}
                            role="tab"
                        >
                            3
                        </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                        <PaginationLink onClick={() => paginar("ADELANTE")} role="tab">
                            <i className="fa fa-angle-right" />
                            <span className="sr-only">Adelante</span>
                        </PaginationLink>
                    </PaginationItem>
                </Pagination>
            </nav>
        </>
    )
}

PaginacionDatosClientes.propTypes = {
    traerClientes: PropTypes.func,
    getClientes: PropTypes.func,
    setClientes: PropTypes.any
}