import { urlGlobal2 } from './GlobalService.js';



export const getRetirosCaja = async (cierreCajaId,params=null) => {
    
    let url = new URL(`${urlGlobal2}/retiroscaja/${cierreCajaId}`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
        
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}




export async function postRetirosCaja(retCa){
    let url = `${urlGlobal2}/retiroscaja`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(retCa)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }
