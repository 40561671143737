import React, { useEffect, useState }  from 'react'
import { Input } from 'reactstrap'
import { getCategoriasMovimientos } from 'services/CategoriaMovimientosService'

const SelectorCategoriaEfectivo = ({CategoriaEfectivo, setCategoriaEfectivo}) => {
  const [CategoriasMovimientos, setCategoriasMovimientos] = useState([])

  const traerCategorias = async() => {
    let cat = await getCategoriasMovimientos()
    if (cat !== null && cat !== '') {
      setCategoriasMovimientos(cat.categorias)
    }
  }
  const cambiarCategoria = (e) => {
      setCategoriaEfectivo(e.target.value)
  }

  useEffect(() => {
    traerCategorias()
  }, [])
  
  return (
    <>
    <Input type='select' onChange={cambiarCategoria} value={CategoriaEfectivo}>
        <option value=''>Categoria</option>
        {CategoriasMovimientos?
        CategoriasMovimientos.map((c)=>{
          return (
            <option key={c.id} value={c.id}>{c.nombre}</option>
          )
        })
        :
        <></>
        }
    </Input>
    </>
  )
}

export default SelectorCategoriaEfectivo