
import { successAlert } from 'pages/components/SweetAlert';
import React, {  useState } from 'react'
import { Button, Card, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap';
import { warningAlert } from 'pages/components/SweetAlert';
import { postDeposito } from 'services/DepositosService';
import SubirComprobante from './SubirComprobante';

const ModalIngDeposito=(props)=>{

    const {ModalIngresarDeposito, setModalIngresarDeposito, CierreCajaId}=props;
    const [ComprobanteId, setComprobanteId] = useState("")

    const [Importe, setImporte] = useState("");
    const [Operacion, setOperacion] = useState("");
    const [Comentario, setComentario] = useState("");
    const [alertSweet, setAlert] = useState(null);

    const AgregarDeposito = async()=>{
    
        let deposito = {
        importe: Importe,
        numeroOperacion: Operacion,
        comentario: Comentario,
        comprobanteId: ComprobanteId,
        cierreCajaId: CierreCajaId

        }
      let result = await postDeposito(deposito)
      if(result != null){
        setImporte("")
        setOperacion("")
        setComentario("")
        successAlert(setAlert, "Deposito Ingresado!", "")
        setModalIngresarDeposito(false)
    }else{
      warningAlert(setAlert, 'No se pudo agregar el Deposito')
    }
  }

  const cambiarImporte = event => {
    setImporte(event.target.value);
  };
  const cambiarOperacion = event => {
    setOperacion(event.target.value);
  };
  const cambiarComentario = event => {
    setComentario(event.target.value);
  };

    return(
        <>
        {alertSweet}
        <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={ModalIngresarDeposito}
                            toggle={() => setModalIngresarDeposito(false)}
                          >
                          <div className="modal-body p-0">
                            <Card className="bg-secondary shadow border-0">
                              <CardHeader className="bg-transparent pb-5">
                                <div className="text-center"><b>Ingresar Deposito</b>
                                <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => setModalIngresarDeposito(false)}
                                >
                                  <span aria-hidden={true}>×</span>
                                </button>
                                </div>
                                <div className="text-muted mt-2 mb-3">
                                <Form role="form">
                                <FormGroup className="mb-3 " >Importe:
                                        <Input
                                          placeholder="Importe..."
                                          type="number"
                                          value={Importe} 
                                          onChange={cambiarImporte}
                                        />
                                  </FormGroup>
                                  <FormGroup > Numero de Operación:
                                          <Input 
                                          placeholder="Numero de Operacion..." 
                                          type="text"
                                          value={Operacion} 
                                          onChange={cambiarOperacion} />
                                  </FormGroup>
                                  <FormGroup > Comentario:
                                    <Input 
                                    placeholder="Comentario..." 
                                    className=" form-control-alternative textarea-autosize"
                                    type="textarea"
                                    value={Comentario} onChange={cambiarComentario}
                                    />
                                </FormGroup>
                                <SubirComprobante setComprobanteId={setComprobanteId} />
                                  <div className="text-center">
                                    <Button
                                      className="my-4"
                                      color="primary"
                                      type="button"
                                      onClick={()=>AgregarDeposito()}
                                    >
                                      Ingresar Deposito
                                    </Button>
                                
                                    <Button
                                      color="secondary"
                                      data-dismiss="modal"
                                      type="button"
                                      onClick={() => { setModalIngresarDeposito(false)}}
                                    >
                                      Cerrar
                                    </Button>                    
                                  </div>
                                </Form>                     
                                </div>                    
                              </CardHeader>                  
                            </Card>
                          </div>
                          </Modal>
        </>
    )
}
export default ModalIngDeposito


