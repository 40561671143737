import React, { useEffect, useState } from 'react'
import { Input } from 'reactstrap'
import { getTiposMateriales } from 'services/TiposMaterialesService';

const SelectorTiposMateriales = props => {
    const {TipoValor, CambiarTipoValor, idAlmacen}=props;
    const [Materiales, setMateriales] = useState([])

    const cambiarValor =(e) => {
        CambiarTipoValor(e.target.value)
    }
    const traerMateriales = async()=> {
        let mar = await getTiposMateriales()
        if (mar) {
            setMateriales(mar.tiposMateriales)
        }
    }

    useEffect(() => {
        traerMateriales()
    }, [])
    
    
  return (
    <>
    <Input type='select' onChange={cambiarValor} value={TipoValor}>
        <option value={''}>Tipo Materiales</option>
        {Materiales?
        Materiales.map((c, index)=>{
            return(
                <option value={c.id} key={index}>{c.nombre}</option>
            )
        })
    :
    <option value={''}>Cargando...</option>}

    </Input>
    </>
  )
}

export default SelectorTiposMateriales