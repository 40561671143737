import { urlGlobal2 } from './GlobalService.js';


export const getValores = async(params=null) => {
    let url = new URL(`${urlGlobal2}/valores`)
    try{
        if (params != null) {
            url.search = new URLSearchParams(params).toString();   
        }
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}

export async function postValores(valor){
    let url = `${urlGlobal2}/valores`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(valor)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }


  export async function deleteValores(id){
    let url = `${urlGlobal2}/valores/${id}`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'DELETE';
        const resp = await fetch(url, {headers, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }