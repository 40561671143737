import React from "react";
import EnEspera from "../subVistas/EnEspera";
import Grafico from "../subVistas/Grafico";
import Mapa from "../subVistas/Mapa";



function ContenedorSubVistasEspera(props){
    const {vista} = props;

    if(vista === "EnEspera"){
        return (
            <>
                <EnEspera/>
            </>
        )
    }else if(vista === "Mapa"){
        return (
            <>
                <Mapa/>
            </>
        )
    }else if(vista === "Grafico"){
        return (
            <>
                <Grafico/>
            </>
        )
    }else{
        <></>
    }  
}



export default ContenedorSubVistasEspera;