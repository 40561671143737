import { urlGlobal2 } from './GlobalService.js';

export const getVehiculos = async(params=null ) =>{
    let url = new URL(`${urlGlobal2}/vehiculos`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}

export async function postVehiculo(vehiculo){
    let url = `${urlGlobal2}/vehiculos`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(vehiculo)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }

  export async function putEditarVehiculo(vlo){
    let url = `${urlGlobal2}/vehiculos`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'PUT';
        let body = JSON.stringify(vlo)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }
  export async function deleteVehiculo(id){
    let url = `${urlGlobal2}/vehiculos/${id}`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'DELETE';
        const resp = await fetch(url, {headers, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }


// Arreglos Mecanicos


    export async function postArregloMecanicoVehiculo(arrMecvehiculo){
        let url = `${urlGlobal2}/arreglosmecanicosvehiculos`;
        try {
            let token = localStorage.getItem('token');
            let headers = {'Content-Type':'application/json','token':token}
            let method= 'POST';
            let body = JSON.stringify(arrMecvehiculo)
            const resp = await fetch(url, {headers, body, method});
            const data = await resp.json()
            return data;
        } catch (error) {
            
            return null;
        }
    }


// Observaciones

    export async function postObservacionVehiculo(obsMecvehiculo){
        let url = `${urlGlobal2}/observacionesvehiculos`;
        try {
            let token = localStorage.getItem('token');
            let headers = {'Content-Type':'application/json','token':token}
            let method= 'POST';
            let body = JSON.stringify(obsMecvehiculo)
            const resp = await fetch(url, {headers, body, method});
            const data = await resp.json()
            return data;
        } catch (error) {
            
            return null;
        }
    }