import { urlGlobal2 } from './GlobalService.js';

export const getMovimientos = async(params=null) =>{
    let url = new URL(`${urlGlobal2}/movimientos`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
        
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}

export async function postMovimiento(movimiento){
    let url = `${urlGlobal2}/movimientos`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(movimiento)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }

export async function putEditarMovimiento(mov){
let url = `${urlGlobal2}/movimientos`;
try {
    let token = localStorage.getItem('token');
    let headers = {'Content-Type':'application/json','token':token}
    let method= 'PUT';
    let body = JSON.stringify(mov)
    const resp = await fetch(url, {headers, body, method});
    const data = await resp.json()
    return data;
} catch (error) {
    
    return null;
}
}