import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import { confirmAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react'
import { Button, Table, UncontrolledTooltip } from 'reactstrap';
import EtiquetaAsignados from './EtiquetaAsignado';
import { useHistory } from 'react-router';
import { deleteRoles } from 'services/RolesService';

export default function TableRoles(props){
    const {Roles, traerRoles}=props;
    
    const [alertSweet, setAlert] = useState([]);
    const history = useHistory();

    const irPermisosRol = (idRol)=>{
        history.replace(`/admin/permisosrol/${idRol}`)
      }

    const confirmarEliminar= async(id)=>{
        confirmAlert(setAlert, eliminarRol, id , "Deseas eliminar este Rol?")
    }

    const eliminarRol = async(id)=>{
        let result = await deleteRoles(id)
        if(result != null){
                successAlert(setAlert, "Rol Eliminado!", "")
                traerRoles()
                return
        }else{
            warningAlert(setAlert,'No se pudo eliminar el Rol')
        }
    };
    
    
      useEffect(() => {
        traerRoles();
      }, [])

    return(
        <>
        {alertSweet}
        <Table className="align-items-center" responsive>
            <thead className="thead-light text-center mt-5">
            <tr>
                <th scope="col">NOMBRE</th>
                <th scope="col">ES ADMIN</th>
                <th scope="col">PERMISOS</th>
                <th scope="col">ELIMINAR</th>
            </tr>
            </thead>
            <tbody>
            {Roles?
            Roles.map((rl, index)=>{
                return (
                <tr key={index}>
                    <td>
                        <div className="text-center">
                            <span className="mb-0 text-sm">
                                <b>{rl.nombre}</b>
                            </span>
                        </div>
                    </td>
                    <td>
                        <div className="text-center">
                            <EtiquetaAsignados Asignado={rl.isAdmin}/>
                        </div>
                    </td>
                    <td>
                        <div className="d-flex align-items-center text-center ">
                            <div className=" text-center">
                                <Button className="btn-icon btn-3 btn-md btn-outline-success "  type="button" onClick={()=>irPermisosRol(rl.id)} >
                                
                                    <span className="btn-inner--icon">Ver    
                                        <i className="far fa-eye"></i>
                                    </span>
                                    
                                </Button>
                            </div>
                        </div>
                    </td>
                    <td>
                    <div className=" text-center">
                        <Button id="tooltip2" className="btn-icon btn-3 btn-sm btn-outline-danger rounded-circle"  type="button" onClick={()=>{confirmarEliminar(rl.id)}}>
                                <span className="btn-inner--icon">
                                <i className="ni ni-fat-remove"/>
                                </span>    
                        </Button>
                        <UncontrolledTooltip
                        delay={0}
                        placement="bottom"
                        target="tooltip2"
                        >
                        Eliminar Rol
                        </UncontrolledTooltip>
                        </div>
                    </td>
                        
                </tr>

                )
            })
            :
            <p>Cargando...</p>
            }
            </tbody>
        </Table>
        
        </>
        
    )
}