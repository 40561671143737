import SelectorGenerico from 'pages/components/SelectorGenerico';
import { successAlert } from 'pages/components/SweetAlert';
import SelectorPrioridad from 'pages/private/casos/components/SelectorPrioridad';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap';
import { getCategoriasCasos } from 'services/CasosServices';
import { postCasos } from 'services/CasosServices';
import classnames from "classnames";
import { warningAlert } from 'pages/components/SweetAlert';

const ModalAgregarCasoClt = (props) => {
    const {ModalAgregarCasoCliente, setModalAgregarCasoCliente, traerCasosCliente, setAlert, idClient}=props;
    const [enunciado, setEnunciado] = useState("");
    const [categoria, setCategoria] = useState("");
    const [prioridad, setPrioridad] = useState("");
    const [archivo, setArchivo] = useState("");
    const [CategoriasCasos, SetCategoriasCasos] = useState([]); 
    const [radios, setRadios] = useState(null);

    const traerCategoriasCasos = async() => {
        let can = await getCategoriasCasos();
        if (can != null && can != '') {
          SetCategoriasCasos(can);
        }
      }

    const cambiarEnunciado = event => {
        setEnunciado(event.target.value);
      };
      
      const cambiarArchivo = event => {
        setArchivo(event.target.value);
      };


    const AgregarCasoCliente = async()=>{
    
        let casos = {
          enunciado: enunciado,
          clienteId:idClient,
          categoriaId: categoria,
          prioridad: prioridad,
          color: radios,
          archivo: archivo,
        }
      let result = await postCasos(casos)
      if(result != null){
        setEnunciado("")
        setCategoria("")
        setPrioridad("")
        setRadios("")
        setArchivo("")
        
      
        setModalAgregarCasoCliente(false)
        successAlert(setAlert, "Caso Agregado!", "")
        traerCasosCliente()
      
      }else{
        warningAlert(setAlert, 'No se pudo agregar caso')
      }
      }
      useEffect(() => {
        traerCategoriasCasos();
      }, [])
  return(
      <>
      <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={ModalAgregarCasoCliente}
                            toggle={() => setModalAgregarCasoCliente(false)}
                          >
                          <div className="modal-body p-0">
                            <Card className="bg-secondary shadow border-0">
                              <CardHeader className="bg-transparent pb-5">
                                <div className="text-center"><b>Nuevo Caso</b>
                                <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => setModalAgregarCasoCliente(false)}
                                >
                                  <span aria-hidden={true}>×</span>
                                </button>
                                </div>
                                <div className="text-muted mt-2 mb-3">
                                <Form role="form">
                                <FormGroup className="mb-3 ">Enunciado:
                                        <Input
                                          value={enunciado} 
                                          onChange={cambiarEnunciado}
                                          className="form-control-lg"
                                          placeholder="Descripcion del caso"
                                          type="textarea"
                                        />
                                </FormGroup>
                                  <FormGroup>
                                    <label htmlFor="exampleFormControlSelect1" >Categoria:</label>
                                    {CategoriasCasos?
                                        <SelectorGenerico Valor={categoria} setValor={setCategoria} arrayElementos={CategoriasCasos}/>
                                        :<></>}
                                  </FormGroup>
                                  <FormGroup >
                                    <label htmlFor="exampleFormControlSelect1" >Prioridad:</label>
                                    
                                    <SelectorPrioridad Valor={prioridad} setValor={setPrioridad}/>
                                    
                                  </FormGroup>   
                                  <FormGroup className="mb-0">
                    <label className="form-control-label d-block mb-3">
                      Identificador del Caso
                    </label>
                    <ButtonGroup
                      className="btn-group-toggle btn-group-colors event-tag"
                      data-toggle="buttons"
                    >
                      <Button
                        className={classnames("bg-danger", {
                          active: radios === "bg-danger",
                        })}
                        color=""
                        type="button"
                        onClick={() => setRadios("bg-danger")}
                      />
                          <Button
                            className={classnames("bg-warning", {
                              active: radios === "bg-warning",
                            })}
                            color=""
                            type="button"
                            onClick={() => setRadios("bg-warning")}
                          />
                      <Button
                        className={classnames("bg-yellow", {
                          active: radios === "bg-yellow",
                        })}
                        color=""
                        type="button"
                        onClick={() => setRadios("bg-yellow")}
                      />
                          <Button
                            className={classnames("bg-default", {
                              active: radios === "bg-default",
                            })}
                            color=""
                            type="button"
                            onClick={() => setRadios("bg-default")}
                          />
                          <Button
                            className={classnames("bg-primary", {
                              active: radios === "bg-primary",
                            })}
                            color=""
                            type="button"
                            onClick={() => setRadios("bg-primary")}
                          />
                      <Button
                        className={classnames("bg-info", {
                          active: radios === "bg-info",
                        })}
                        color=""
                        type="button"
                        onClick={() => setRadios("bg-info")}
                      />
                      <Button
                        className={classnames("bg-success", {
                          active: radios === "bg-success",
                        })}
                        color=""
                        type="button"
                        onClick={() => setRadios("bg-success")}
                      />
                    </ButtonGroup>
                  </FormGroup>
                  </Form>                     
                  <Form value={archivo} onChange={cambiarArchivo}>
                    <div className="custom-file">
                      <input
                        className="custom-file-input"
                        id="customFileLang"
                        lang="en"
                        type="file"
                      />
                      <label className="custom-file-label" htmlFor="customFileLang">
                        Archivo
                      </label>
                    </div>
                  </Form>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={()=>AgregarCasoCliente()}
                    >
                      Agregar
                    </Button>
                
                    <Button
                      color="secondary"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => setModalAgregarCasoCliente(false)}
                    >
                      Cerrar
                    </Button>                    
                  </div>
                </div>                    
              </CardHeader>                  
            </Card>
          </div>
          </Modal>
                                                       
      </>
  )
};

export default ModalAgregarCasoClt;
