import { warningAlert } from 'pages/components/SweetAlert'
import React, { useEffect, useState } from 'react'
import { Button, CardBody, CardFooter, CardHeader, Col, Input, Row, Table } from 'reactstrap'
import { cierreCajaByFecha } from 'services/CierreCajaService'
import { getEgresos } from 'services/EgresosService'
import moment from 'moment';

const Resumen = props => {
    const [Desde, setDesde] = useState("")
    const [Hasta, setHasta] = useState("")
    const [Alert, setAlert] = useState(null)
    const [Todos, setTodos] = useState([])
    const [Total, setTotal] = useState("")

    const cambiarDesde = event => {
        setDesde(event.target.value);
    };

    const cambiarHasta = event => {
    setHasta(event.target.value);
    };

    const filtrar = async()=>{
        let params = {fechaInicio: Desde, fechaFin: Hasta}
        let egresos = await getEgresos(params);
        let cierreZrt = await cierreCajaByFecha(params, 1);
        let cierreBrd = await cierreCajaByFecha(params, 2);
        if (!Desde || !Hasta) {
            warningAlert(setAlert, 'Seleccione ambas fechas!')
        }else if (Desde >=Hasta) {
            warningAlert(setAlert, 'La fecha final es menor a la fecha inicial seleccionada!')
        }
        obtenertTotal(egresos, cierreZrt, cierreBrd)
    }

    const resumenDia = (cierreZrt, cierreBrd, egresos, dia, Fecha) => {
        let CierreZarate = buscarTotalDia(cierreZrt, dia);
        let CierreBaradero = buscarTotalDia(cierreBrd, dia);
        let CierreEgresos = buscarTotalDia(egresos, dia, true);
        let Total = CierreZarate + CierreBaradero - CierreEgresos;
        return {Fecha,CierreZarate,CierreBaradero,CierreEgresos,Total}
    }

    const obtenertTotal = (egresos, cierreZrt, cierreBrd) =>{
        let todos = [];
        let total = 0;
        let diaInicial = moment(`${Desde} 00:00`, 'YYYY-MM-DD h:m').toDate();
        let diaFinal = moment(`${Hasta} 23:59`, 'YYYY-MM-DD h:m').toDate();
        for (let i = diaInicial.getDate(); i < diaFinal.getDate(); i++) {
            diaInicial.setDate(i)
            let resumenDelDia = resumenDia(cierreZrt, cierreBrd, egresos, i, new Date(diaInicial));
            total = total + resumenDelDia.Total
            todos.push(resumenDelDia)
        }
        let resumenDelDia = resumenDia(cierreZrt, cierreBrd, egresos, diaFinal.getDate(), diaFinal);
        total = total + resumenDelDia.Total
        todos.push(resumenDelDia)
        setTodos(todos);
        setTotal(total);
    }

    const buscarTotalDia = (arr, i, egresos=false) =>{
        let totalDia = 0;
        arr.map(e => {
            let dia = new Date(e.fecha)
            if (egresos) dia = new Date(e.creado);  
            if(dia.getDate() === i){
            totalDia = totalDia + e.importe 
            }
        })
    return totalDia
    }

    useEffect(() => {
    }, [])
    
  return (
    <>
    {Alert}
    <CardHeader>
            <Row>
                <Col>
                <Row>
                <Col>
                    <label>Desde:</label>
                    <Input
                    id="example-date-input"
                    type="date"
                    value={Desde} onChange={cambiarDesde}
                    />
                </Col>
                <Col>
                    <label>Hasta:</label>
                    <Input
                    id="example-date-input"
                    type="date"
                    value={Hasta} onChange={cambiarHasta}
                    />
                </Col>
                <Col>
                <div className='mt-2'>
                <Button className='btn-outline-success float-left mt-4'onClick={()=>filtrar()}>
                    Filtrar
                </Button>
                </div>
                </Col>
                </Row>
                </Col>
            </Row>
        </CardHeader>
        <CardBody>
        <Table className="align-items-center" responsive>
            <thead className="thead-light text-center">
                <tr>
                    <th scope="col">FECHA</th>
                    <th scope="col">CIERRE ZARATE</th>
                    <th scope="col">CIERRE BARADERO</th>
                    <th scope="col">EGRESOS</th>
                </tr>
            </thead>
            <tbody>
            {Todos?
            Todos.map((t, index) => {
                return(
                <tr key={index} >
                <th scope="row">
                <div className="text-center">
                      <span className="mb-0 text-sm">
                     {moment(t.Fecha).format('DD/MM/YYYY')}
                      </span>
                </div>
                </th>
                <td>
                <div className="text-center">
                      <span className="mb-0 text-sm">
                     $ {t.CierreZarate}
                      </span>
                </div>
                </td>
                <td>
                <div className="text-center">
                      <span className="mb-0 text-sm">
                   $ {t.CierreBaradero}
                      </span>
                </div>
                </td>
                <td>
                <div className="text-center">
                      <span className="mb-0 text-sm">
                    $ {t.CierreEgresos}
                      </span>
                </div>
                </td>
                </tr>
                )
                })
                :
                <></>
            }
            </tbody>
            </Table>
        </CardBody>
        <CardFooter>
        {Total != ""?
            <h3 className='float-right'>Total: $ {Total}</h3>
            :
            <></>
            }
        </CardFooter>
    </>
  )
}

export default Resumen