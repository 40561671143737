import React, { useState } from 'react'
import { Button, Col, Input, Row } from 'reactstrap'
const NavBarInstalaciones = props => {
  const [Desde, setDesde] = useState([]);
  const [Hasta, setHasta] = useState([]);

  const cambiarDesde = event => {
    setDesde(event.target.value);
  };
  const cambiarHasta = event => {
  setHasta(event.target.value);
  };

  return (
    <>
            <Row>
              <Col>
              <Row>
                <Col>
                  <label>Desde:</label>
                    <Input
                      id="example-date-input"
                      type="date"
                      value={Desde} onChange={cambiarDesde}
                      />
                </Col>
                <Col>
                  <label>Hasta:</label>
                    <Input
                    id="example-date-input"
                    type="date"
                    value={Hasta} onChange={cambiarHasta}
                    />
                </Col>
                </Row>
              </Col>
              <Col>
              <Button className='btn-md btn-default float-right'>
                Resumen
              </Button>
              </Col>
            </Row>
   </>
  )
}


export default NavBarInstalaciones
