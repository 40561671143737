import React from "react";
import { FormGroup, Input } from "reactstrap";

function SelectorServicios(props){
    const {Valor, setValor, arrayElementos} = props;
    
    const cambiarValor = event => {
        setValor(event.target.value);
    };

    return(
        <>
            <FormGroup>
                    <Input id="exampleFormControlSelect1" type="select" 
                        value={Valor}
                        onChange={cambiarValor}
                    >
                        <option value={''}>Opciones..</option>
                        {arrayElementos?
                        
                        arrayElementos.map((ele, index) => {
                            return(
                                <option key={index} value={ele.id}>{ele.name} ({ele.fullAddress})</option>

                            )
                        })
                        :
                        <option value={''}>Cargando..</option>
                        }
                    </Input>
            </FormGroup>
        </>
    );
};

export default SelectorServicios;