import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, Navbar, Row, Table } from 'reactstrap';
import LoadingComponent from 'pages/components/Loading';
import Paginacion from 'pages/components/Paginacion';
import { getDocumentosClientes, deleteDocumentoCliente } from 'services/DocumentosClienteService';
import ModalAgregarDocumento from '../components/ModalAgregarDocumento'; // Importar el modal
import { successAlert, warningAlert, confirmAlert } from 'pages/components/SweetAlert';
import { getSignedUrl } from 'services/FileServices';

const Documentos = (props) => {
    const { idClient } = props;
    const [documentos, setDocumentos] = useState([]);
    const [visible, setVisible] = useState(false);
    const [modalAgregarDocumento, setModalAgregarDocumento] = useState(false);
    const [alert, setAlert] = useState("");

    const traerDocumentos = async () => {
        setVisible(true);
        let docs = await getDocumentosClientes(idClient);
        if (docs != null && docs.length > 0) {
            setDocumentos(docs);
        } else {
            setDocumentos([]);
        }
        setVisible(false);
    };

    const descargarDocumento = async (key, name) => {
        try {
            let result = await getSignedUrl(key);
            
            if(result && result.ok) {
                let urlPrefirmada = result.url;
                const link = document.createElement('a');
                link.href = urlPrefirmada;
                link.download = name; 
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            
        } catch (error) {
            warningAlert(setAlert, 'Error al descargar', 'Error del servidor al realizar la descarga, comuniquese con un administrador');
        }
    }

    const eliminarDocumento = async (id)=>{
        
        let result = await deleteDocumentoCliente(id);
        if(result && result.ok) {
            successAlert(setAlert, 'Ok', result.mensaje);
            await traerDocumentos();
            return
        }
        warningAlert(setAlert, 'Error', 'No se ha podido eliminar el documento')
    }
    
    const confirmarEliminacion = async (id) => {
        confirmAlert(setAlert, eliminarDocumento, id,"¿Estas seguro que deseas eliminar el documento?")
    };

    useEffect(() => {
        traerDocumentos();
    }, [idClient]);

    return (
        <>
            {alert}
            <LoadingComponent Visible={visible} />
            <Card style={{ width: "100%" }} className="mt-3">
                <h1 className="ml-4 mt-3">
                    <i className="fas fa-file-alt"> Documentos</i>
                </h1>
                <Navbar className="navbar-horizontal navbar-light ml-3" expand="lg">
                    <Row>
                        <Col lg={{ size: "auto" }}>
                            <Button
                                className="btn-md btn-outline-info float-right"
                                onClick={() => setModalAgregarDocumento(true)}
                            >
                                Agregar Documento
                            </Button>
                        </Col>
                    </Row>
                </Navbar>

                <CardBody>
                    <Table responsive>
                        <thead className="text-center bg-light">
                            <tr>
                                <th scope="col">NOMBRE</th>
                                <th scope="col">DESCRIPCIÓN</th>
                                <th scope="col">DESCARGAR</th>
                                <th scope="col">ELIMINAR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {documentos.length > 0 ? (
                                documentos.map((doc, index) => (
                                    <tr key={index} className="text-center">
                                        <td>{doc.nombre}</td>
                                        <td>{doc.descripcion}</td>
                                        <td>
                                            <Button
                                                className="btn-icon btn-3 btn-sm btn-outline-info rounded-circle"
                                                type="button"
                                                onClick={() => descargarDocumento(doc.key, doc.name)}
                                            >
                                                <i className="fas fa-download"></i>
                                            </Button>
                                        </td>
                                        <td>
                                        <Button
                                            className="btn-icon btn-3 btn-sm btn-outline-danger rounded-circle"
                                            type="button"
                                            onClick={() => confirmarEliminacion(doc.id)}
                                            >
                                            <i className="fas fa-trash-alt"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3">No hay documentos disponibles.</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <Paginacion ZonaEsp={null} traerObjetos={traerDocumentos} setObjetos={setDocumentos} getObjetos={getDocumentosClientes} />
                </CardBody>
            </Card>

            <ModalAgregarDocumento
                ModalAgregarDocumento={modalAgregarDocumento}
                setModalAgregarDocumento={setModalAgregarDocumento}
                idClient={idClient}
                traerDocumentos={traerDocumentos}
            />
        </>
    );
};

export default Documentos;
