import { urlGlobal2 } from './GlobalService.js';

export const getGruposPermisosConPermisos = async(params=null ) =>{
    let url = new URL(`${urlGlobal2}/permisos`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        return null;
    }
}

// export const getRol = async(params=null, id ) =>{
//     let url = new URL(`${urlGlobal2}/roles/${id}`)
//     if (params != null) {
//         url.search = new URLSearchParams(params).toString();
//     }
//     try{
//         let token = localStorage.getItem('token');
//         let headers = {'Content-Type':'application/json', token}
//         let method = 'GET';
//         let resp = await fetch(url,{method, headers});
//         let data = await resp.json();
//         return data;
//     }
//     catch(error){
//         alert(error.message);
//         return null;
//     }
// }

export const getGruposPermisos = async(params=null) =>{
    let url = new URL(`${urlGlobal2}/grupospermisos`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}

export const postGrupoPermisos = async(permiso) =>{
    let url = new URL(`${urlGlobal2}/grupospermisos`)
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'POST';
        let body = JSON.stringify(permiso)
        let resp = await fetch(url,{method, headers, body});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}

export const postPermisos = async(permiso) =>{
    let url = new URL(`${urlGlobal2}/permisos`)
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'POST';
        let body = JSON.stringify(permiso)
        let resp = await fetch(url,{method, headers, body});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}

// // PUT**********

export async function putPermisos(req){
    let url = `${urlGlobal2}/permisos`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'PUT';
        let body = JSON.stringify(req)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }


  export async function deletePermiso(id){
    let url = `${urlGlobal2}/permisos/${id}`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'DELETE';
        const resp = await fetch(url, {headers, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }