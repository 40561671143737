import React, { useState } from 'react';
import { Button, Card, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap';
import { postArchivo } from 'services/FileServices';
import { postDocumentoCliente } from 'services/DocumentosClienteService';
import { successAlert, warningAlert } from 'pages/components/SweetAlert';

const ModalAgregarDocumento = ({ ModalAgregarDocumento, setModalAgregarDocumento, idClient, traerDocumentos }) => {
    const [archivo, setArchivo] = useState(null);
    const [nombre, setNombre] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState("");

    const MAX_FILE_SIZE = 5 * 1024 * 1024;

    const VALID_FILE_TYPES = [
        'image/jpeg', 'image/png', 'application/pdf', 'application/msword', 
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel', 'application/vnd.openxmlformats.officedocument.spreadsheetml.sheet', 
        'text/plain', 'text/csv'
    ];

    const resetForm = () => {
        setArchivo(null);
        setNombre("");
        setDescripcion("");
        setAlert("");
    };

    const handleArchivoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (!VALID_FILE_TYPES.includes(file.type)) {
                warningAlert(setAlert, 'Formato no válido', 'Solo se permiten imágenes, documentos (PDF, Word), CSV, Excel y TXT.');
                setArchivo(null);
            } else if (file.size > MAX_FILE_SIZE) {
                warningAlert(setAlert, 'Tamaño de archivo demasiado grande', 'El archivo no puede ser mayor de 5 MB.');
                setArchivo(null);
            } else {
                setArchivo(file);
            }
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            if (!VALID_FILE_TYPES.includes(file.type)) {
                warningAlert(setAlert, 'Formato no válido', 'Solo se permiten imágenes, documentos (PDF, Word), CSV, Excel y TXT.');
                setArchivo(null);
            } else if (file.size > MAX_FILE_SIZE) {
                warningAlert(setAlert, 'Tamaño de archivo demasiado grande', 'El archivo no puede ser mayor de 5 MB.');
                setArchivo(null);
            } else {
                setArchivo(file);
            }
        }
    };

    const subirDocumento = async () => {
        if (!archivo || !nombre || !descripcion) {
            warningAlert(setAlert, 'Campos incompletos', 'Por favor, complete todos los campos.');
            return;
        }

        if (nombre.length > 50) {
            warningAlert(setAlert, 'Nombre demasiado largo', 'El nombre del documento no puede superar los 50 caracteres.');
            return;
        }

        if (descripcion.length > 500) {
            warningAlert(setAlert, 'Descripción demasiado larga', 'La descripción del documento no puede superar los 500 caracteres.');
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append('archivo', archivo);
        
        const result = await postArchivo(formData, 's3');
        console.log("result", result);

        if (result && result.ok) {
            const documento = {
                clienteId: idClient,
                nombre: nombre,
                descripcion: descripcion,
                key: result.key,
            };

            const documentoResult = await postDocumentoCliente(documento);

            if (documentoResult && documentoResult.ok) {
                successAlert(setAlert, 'Documento agregado', 'El documento fue subido y asociado al cliente.');
                setModalAgregarDocumento(false);
                traerDocumentos();
            } else {
                warningAlert(setAlert, 'Error al agregar documento', 'Hubo un error al agregar el documento.');
            }
        } else {
            warningAlert(setAlert, 'Error al subir archivo', 'Hubo un error al subir el archivo.');
        }

        setLoading(false);
    };

    return (
        <>
            {alert}
            <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={ModalAgregarDocumento}
                toggle={() => {
                    resetForm();
                    setModalAgregarDocumento(false);
                }}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent pb-5">
                            <div className="text-center mb-3">
                                <b>Agregar Documento</b>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => {
                                        resetForm();
                                        setModalAgregarDocumento(false);
                                    }}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <Form role="form">
                                <FormGroup className="mb-3">
                                    <Input
                                        placeholder="Nombre del documento..."
                                        type="text"
                                        value={nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                        maxLength={50}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <textarea
                                        placeholder="Descripción del documento..."
                                        value={descripcion}
                                        onChange={(e) => setDescripcion(e.target.value)}
                                        rows={3}
                                        className="form-control"
                                        maxLength={500}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <div
                                        className="dropzone"
                                        onDrop={handleDrop}
                                        onDragOver={handleDragOver}
                                        style={{
                                            border: '2px dashed #ccc',
                                            padding: '20px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <p>Arrastra el archivo aquí o selecciona un archivo</p>
                                        <Input
                                            type="file"
                                            onChange={handleArchivoChange}
                                            style={{ display: 'none' }}
                                            id="file-input"
                                            aria-label="Seleccionar archivo"
                                        />
                                        <Button
                                            className="my-2"
                                            color="primary"
                                            type="button"
                                            onClick={() => document.getElementById('file-input').click()}
                                        >
                                            Seleccionar archivo
                                        </Button>
                                    </div>
                                </FormGroup>

                                {archivo && (
                                    <div className="mt-3">
                                        <strong>Archivo seleccionado:</strong>
                                        <p>{archivo.name}</p>
                                        <p>Tamaño: {(archivo.size / 1024).toFixed(2)} KB</p>
                                    </div>
                                )}

                                {archivo && !VALID_FILE_TYPES.includes(archivo.type) && (
                                    <p className="text-danger">Tipo de archivo no válido. Solo se permiten imágenes, documentos (PDF, Word), CSV, Excel y TXT.</p>
                                )}
                                {archivo && archivo.size > MAX_FILE_SIZE && (
                                    <p className="text-danger">El archivo excede el tamaño máximo permitido de 5 MB.</p>
                                )}

                                <div className="text-center">
                                    <Button
                                        className="my-4"
                                        color="primary"
                                        type="button"
                                        onClick={subirDocumento}
                                        disabled={loading || !archivo}
                                    >
                                        {loading ? 'Subiendo...' : 'Subir Documento'}
                                    </Button>
                                    <Button
                                        color="secondary"
                                        data-dismiss="modal"
                                        type="button"
                                        onClick={() => {
                                            resetForm();
                                            setModalAgregarDocumento(false);
                                        }}
                                    >
                                        Cerrar
                                    </Button>
                                </div>
                            </Form>
                        </CardHeader>
                    </Card>
                </div>
            </Modal>
        </>
    );
};

export default ModalAgregarDocumento;
