import React, { useState } from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import LoadingComponent from 'pages/components/Loading';
import classnames from "classnames";
import PropTypes from "prop-types";


export default function PaginacionAddModalTm(props){
    const {traerObjetos, setObjetos, getObjetos,setBuscar,setQuery} = props
    const [Paginado, setPaginado] = useState(1);
    const [Visible, setVisible] = useState(false);
    const [estado, setState] = useState(null);

    const toggleNavs = (e, state, index) => {
      e.preventDefault();
     setState(index);
    };

    const paginar = async(accion)=>{
      let pag = Paginado;
      setVisible(true)
        if(accion === "ATRAS") {
          if(Paginado >= 1){
            pag = pag-1;
            setPaginado(pagAnt=>{
              pagAnt = pagAnt-1;
              setPaginado(pagAnt)
            })
          }
          
        }else if(accion === "1"){
          pag=1;
          setPaginado(1);
          
        }else if(accion === "2"){
          pag=2;
          setPaginado(2);
          
        }else if(accion === "3"){
          pag=3;
          setPaginado(3)
          
        }else if(accion === "ADELANTE"){
          pag = pag+1;
          setPaginado(pagAnt=>{
            pagAnt = pagAnt+1;
            setPaginado(pagAnt)
          })
        }
        let params = {page:pag, limit:5}
        let result = await getObjetos(params)
        setBuscar(false)
        setQuery("")
        if(result != null){
            setObjetos(result.tiposMateriales);
        }else{alert("no se pudo cambiar de pagina"); traerObjetos()}
        setVisible(false)
      } 
    

    return(
      <>
      <LoadingComponent Visible={Visible}/>
      
        <nav aria-label="Page navigation example" className="mt-3">
            <Pagination
            className="pagination justify-content-center flex-column flex-sm-row"
            id="tabs-text"
            role="tablist"
            >
            <PaginationItem>
                <PaginationLink
                
                onClick={() => paginar("ATRAS")}
                tabIndex="-1"
                role="tab"
                >
                <i className="fa fa-angle-left" />
                <span className="sr-only">Atras</span>
                </PaginationLink>
            </PaginationItem>
            <PaginationItem > 
                <PaginationLink
                aria-selected={estado === 1}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: estado === 1
                })}
                 onClick={e => {toggleNavs(e, "navPills", 1);paginar("1")}}
                 role="tab"
                 >
                1
                </PaginationLink>
            </PaginationItem>
            <PaginationItem>
                <PaginationLink  
                aria-selected={estado === 2}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: estado === 2
                })}
                onClick={e =>{toggleNavs(e, "navPills", 2);paginar("2")} }
                role="tab"
                >
                2
                </PaginationLink>
            </PaginationItem>
            <PaginationItem>
                <PaginationLink 
                aria-selected={estado === 3}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: estado === 3
                })}
                onClick={e => {toggleNavs(e, "navPills", 3);paginar("3")}}
                role="tab"
                >
                3
                </PaginationLink>
            </PaginationItem>
            <PaginationItem>
                <PaginationLink onClick={() => paginar("ADELANTE")} role="tab">
                <i className="fa fa-angle-right" />
                <span className="sr-only">Adelante</span>
                </PaginationLink>
            </PaginationItem>
            </Pagination>
        </nav>
        </>
    )
}
PaginacionAddModalTm.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
PaginacionAddModalTm.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};