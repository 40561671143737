import { successAlert } from 'pages/components/SweetAlert';
import { confirmAlert } from 'pages/components/SweetAlert'
import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { putEstadoAsignacion } from 'services/ServiciosServices';
import ModalAsignarAtm from './ModalAsignarAtm';


function EtiquetaAsignados(props){
    const {Asignado, CambioEquipo, setAlert, Servicio, traerCE} = props
    const [ModalAsignar, setModalAsignar] = useState(false)

    const ConfirmarCambio=()=>{
        confirmAlert(setAlert, CambiarEstado, null,"Estas seguro que deseas cambiar de estado ?")
    }
    const CambiarEstado=async()=>{
        let cha = await putEstadoAsignacion(CambioEquipo.servicio);
            if (cha !== null && cha !=='') {
                traerCE()  
                successAlert(setAlert, "Estado Cambiado!", "")
            }
        }
    
    
    return(
        <>
        <ModalAsignarAtm ModalAsignar={ModalAsignar}
            setModalAsignar={setModalAsignar}
            CambioEquipo={CambioEquipo}
            Asignado={Asignado}
            Servicio={Servicio}
            traerCE={traerCE}
        />
        
        {Asignado?
            <Button onClick={() => ConfirmarCambio()} className="btn-icon btn-3 btn-sm btn-outline-success  "  type="button" >
                <span className="btn-inner--icon">    
                SI
                </span>
                
            </Button>
        :
            <Button onClick={()=>{setModalAsignar(true)}}  className="btn-icon btn-3 btn-sm btn-outline-danger "  type="button">
                <span className="btn-inner--icon">    
                NO
                </span>
                
            </Button>
    }
        </>
    )
}

export default EtiquetaAsignados;