import Buscador from 'pages/components/Buscador'
import SelectorEmpleados from 'pages/components/SelectorEmpleados'
import { successAlert } from 'pages/components/SweetAlert'
import { warningAlert } from 'pages/components/SweetAlert'
import React from 'react'
import { Badge, Button, CardBody, CardFooter, Form, FormGroup, Input, Table } from 'reactstrap'
import { getObras } from 'services/ObrasService'
import { postRegistroRetiro } from 'services/RegistrarService'
import { getTiposMaterialesObras } from 'services/TiposMaterialesService'
import { getTiposMaterialesClientes } from 'services/TiposMaterialesService'
import BuscadorMaterialCliente from '../BuscadorMaterialesClientes'
import BuscadorMaterialesObras from './BuscadorMaterialesObras'
import BuscadorAobras from './BuscarAobras'

const RetirarAObra = ({Error, ObraSeleccionada, setObraSeleccionada, Cantidad, setCantidad, setError, Empleado, setEmpleado, setAlert, Obra, setObra, setMaterSeleccionado, Materiales, setMateriales, MaterialSeleccionado, setMaterialSeleccionado, setModalRegistrarRetiro, traerMateriales, reset, Obras, setObras, MaterlSeleccionado}) => {


      const cambiarImporte = (e)=> {
        setCantidad(e.target.value)
    }
    const establecerObra = ()=> {
        setObraSeleccionada(true)
        traerMaterialesObra()
    }
    const establecerMaterial = ()=> {
        setMaterSeleccionado(true)
    }

    const validarEntregar = (ev)=>{
        if(!ev.tipoMaterialId){
          return false;
        }
        if(!ev.cantidad){
          return false;
        }
        if(!ev.empleadoId){
            return false;
        }
        if(!ev.obraId){
        return false;
        }
        return true;
      }

    const validarMinimo = () => {
    if (Cantidad <=0) {
        setError({message : "La Cantidad a registrar debe ser mayor a 0!"})
        return false
    }
    return true
    }
    const Agregar = async() =>{
        let stock = {
            tipoMaterialId : parseInt(MaterialSeleccionado.idTipoMaterial),
            cantidad : parseInt(Cantidad),
            obraId : parseInt(Obra),
            empleadoId : parseInt(Empleado)
        }
        if (validarEntregar(stock)===true && validarMinimo(Cantidad) === true) {
            let agg = await postRegistroRetiro(stock)
            if (agg) {
                successAlert(setAlert, "Retiro registrado correctamente!")
                setCantidad("")
                setObra("")
                setEmpleado("")
                setObraSeleccionada(false)
                setMaterSeleccionado(false)
                setModalRegistrarRetiro(false)
                traerMateriales()
            } else {
                warningAlert(setAlert, "Error al almacenar el retiro")
            }
        } else {
            if (Error.message) {
                warningAlert(setAlert, `${Error.message}`)
            } else {
                warningAlert(setAlert, "Complete todos los campos!")
    
            }
        }
    }

    const traerMaterialesObra = async () => {
        let mater = await getTiposMaterialesObras(null, Obra)
        if (mater) {
            setMateriales(mater.tmo)
        }
    }
    const recetObras = async() => {
        setObras("");
    }
  return (
    <>
                {!ObraSeleccionada?
            <>
            <CardBody>
           <div className=" mt-5 mb-3">
           <Form role="form">
           <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Busque y Seleccione una Obra:
            </label>
           <BuscadorAobras setObjetos={setObras} traerObjetos={recetObras}/>
                   {Obras?
                   <Table className='text-center'>
                       <thead>
                           <tr>
                               <th>ID</th>
                               <th>NOMBRE</th>
                               <th>DNI</th>
                               <th>SELECCIONAR</th>
                           </tr>
                       </thead>
                       <tbody>
                           {Obras?
                           Obras.map(o=>{
                               return(
                                   <tr key={o.id}>
                                       <th>{o.id}</th>
                                       <td>{o.nombre}</td>
                                       <td>
                                            <Input
                                                onClick={()=>setObra(o.id)}
                                                className="custom-control-input"
                                                id={`customRadio${o.id}`}
                                                name="customRadio"
                                                type="radio"
                                            />
                                            <label className="custom-control-label" htmlFor={`customRadio${o.id}`}>
                                            </label>
                       </td>
                                   </tr>
                               )
                           })
                       :
                       <>
                       
                       </>}
                       </tbody>
                   </Table>
                   :
                   <Badge
                   className='mt-3'
                   color="info"
                   href="#pablo"
                   onClick={e => e.preventDefault()}
                   >
                   Busque para ver las ObraS
                   </Badge>}
           </Form>                     
           </div>                    
           </CardBody>
           <CardFooter>
               <div className="text-center">
               <Button
                   className=""
                   color="primary"
                   type="button"
                   onClick={()=>establecerObra()}
                   
                   >
                   Confirmar
               </Button>
           
               <Button
                   color="secondary"
                   data-dismiss="modal"
                   type="button"
                   onClick={() => reset()}
               >
                   Cerrar
               </Button>                    
               </div>
               </CardFooter>  
           </>
            :
            <>
            {!MaterlSeleccionado?
            <>
            <CardBody>
            <div className=" mt-5 mb-3">
            <Form role="form">
            <FormGroup>
               <BuscadorMaterialesObras setMateriales={setMateriales} ObraId={parseInt(Obra)} traerMateriales={traerMateriales}/>
            </FormGroup>
            <FormGroup>
               <Table responsive className='text-center'>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Codigo</th>
                        <th>Cantidad</th>
                        <th>Seleccionar</th>
                    </tr>
                </thead>
                <tbody>
                {Materiales.length?
                    Materiales.map((m, index)=>{
                        return(
                    <tr key={index}>
                        <th>{m.tiposMateriale?.nombre}</th>
                        <td>{m.tiposMateriale?.codigoProducto}</td>
                        <td>{m.cantidad}</td>
                        <td>
                            <Input
                                onClick={()=>setMaterialSeleccionado(m)}
                                className="custom-control-input"
                                id={`customRadio${index}`}
                                name="customRadio"
                                type="radio"
                            />
                            <label className="custom-control-label" htmlFor={`customRadio${index}`}>
                            </label>
                        </td>
                    </tr>

                        )
                    })
                :
                <>
                <tr>
                    <td>

                <h5>La Obra no tiene ningun Material Asignado!</h5>
                    </td>
                </tr>
                </>}
                </tbody>
               </Table>
            </FormGroup>
            </Form>                     
            </div>                    
            </CardBody>
            <CardFooter>
                <div className="text-center">
                <Button
                    className=""
                    color="primary"
                    type="button"
                    onClick={()=>establecerMaterial()}
                    
                    >
                    Confirmar
                </Button>
            
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => reset()}
                >
                    Cerrar
                </Button>                    
                </div>
                </CardFooter>
                 </>
            :
            <>
             <CardBody>
            <div className=" mt-5 mb-3">
            <Form role="form">
                <label>Empleado:</label>
                <SelectorEmpleados valor={Empleado} cambiarValor={setEmpleado}/> 
            <FormGroup>
                <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Cantidad:
                </label>
                <Input
                    type="number"
                    onChange={cambiarImporte}
                    />
            </FormGroup>
            </Form>                     
            </div>                    
            </CardBody>
            <CardFooter>
                <div className="text-center">
                <Button
                    className=""
                    color="primary"
                    type="button"
                    onClick={()=>Agregar()}
                    
                    >
                    Registrar Retiro
                </Button>
            
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => reset()}
                >
                    Cerrar
                </Button>                    
                </div>
                </CardFooter>  
            </>}
              
            </>
            }
    </>
  )
}

export default RetirarAObra