import React, { useEffect, useState } from 'react'
import SelectorCategorias from 'pages/components/SelectorCategoria'
import { Button, Card, CardHeader, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, Row } from 'reactstrap'
import { postEquipos, putEquipoEm } from 'services/EquiposService'
import {warningAlert, successAlert} from "../../../components/SweetAlert"
import LoadingComponent from 'pages/components/Loading'
import BarcodeScanner from '../../../components/BarcodeScanner'
import { validarCaracteresMac } from 'validators/validators-equipos'
import { validarNuevoEquipo } from 'validators/validators-equipos'
 

export default function ModalAgregar(props){
    const {
        ModalAgregarEquipo, setModalAgregarEquipo, traerEquipos, EmpleadoId
      } = props

        const [Macaddress, setMacaddress] = useState("");
        const [Categoria, setCategoria] = useState(null);
        const [alertSweet, setAlert] = useState(null);
        const [Visible, setVisible] = useState(false);
        const [equipoEscaneado,setEquipoEscaneado] = useState(null);
        const [equiposEscaneadosAnteriormente,setEquiposAnteriores] = useState([]);
        const [escaneandoEquipos,setEscaneandoEquipos] = useState(false);


        const cambiarMacaddress = event => {
          setMacaddress(event.target.value);
        };

    

        const reset = () => {
          setMacaddress("")
          setCategoria("")
          setModalAgregarEquipo(false)
        }


        const comenzarAEscanear = () => {
          if(!Categoria){
            warningAlert(setAlert,"Seleccione una categoria")
            return;
          }

          if(escaneandoEquipos){
            traerEquipos();
          }
          setEscaneandoEquipos(state => {setEscaneandoEquipos(!state)})
        }




      const estabaEscaneado = () => {
        if(escaneandoEquipos && equiposEscaneadosAnteriormente.includes(Macaddress)){
          return true;
        }
        else{
          let aux = equiposEscaneadosAnteriormente;
          aux.push(Macaddress);
          setEquiposAnteriores(aux)
          return false;
        }
      } 



        const agregarEquipo = async() => {
          
          try{
            // Esto es para no repetir equipos por si se escanea 2 veces
            if(estabaEscaneado()){return}
           


            setVisible(true)
            
          
            let equip = {
                estadoId: 1,
                macAddress: Macaddress,
                categoriaId: parseInt(Categoria)
            }
            if(!validarCaracteresMac(Macaddress).ok){
              warningAlert(setAlert,validarCaracteresMac(Macaddress).mensaje);
              return;
            }
  
            if(!validarNuevoEquipo(equip).ok){
              warningAlert(setAlert,validarCaracteresMac(equip).mensaje)
              return;
            }
            let newEquip = await postEquipos(equip);
            if(newEquip.ok){
              successAlert(setAlert,'Equipo Agregado');
              traerEquipos();
              reset();
            }
            else{
              warningAlert(setAlert,"Error al crear el equipo")
            }
          }
          catch(error){
            warningAlert(setAlert,error.message)
          }
          finally{
            setVisible(false)
          }
         

     
          
        }

        useEffect(() => {
          if(equipoEscaneado !== null && equipoEscaneado !== '' && equipoEscaneado !== ""){
            setMacaddress(equipoEscaneado);
            agregarEquipo();
          }
        },[equipoEscaneado])


    return(
      <>
        {alertSweet}
        <LoadingComponent Visible={Visible}/>
        
        {
          escaneandoEquipos?
          <BarcodeScanner setEquipoEscaneado={setEquipoEscaneado}></BarcodeScanner>
          :null
        }
        
        <Modal
          className="modal-dialog-centered"
          size="md"
          isOpen={ModalAgregarEquipo}
          toggle={() => setModalAgregarEquipo(false)}
        >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-center"><b>Agregar Equipo</b>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalAgregarEquipo(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
              </div>
              <div className="text-muted mt-2 mb-3">
              <Form role="form">
                <FormGroup className="mb-3">MacAddress:
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      <i className="fas fa-satellite-dish"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="macAddress..." type="text"  onChange={cambiarMacaddress} value={Macaddress} disabled={escaneandoEquipos}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <label htmlFor="exampleFormControlSelect1">Categoria:</label>
                  <SelectorCategorias Categoria={Categoria} setCategoria={setCategoria} tipoValue={"id"}/>
                </FormGroup>
                  <Row>
                      <Col md={4}>
                      
                      </Col>


                      <Col md={4}>
                      <Row>
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={()=>agregarEquipo()}
                  >
                    Agregar Manualmente
                  </Button>
                  </Row>
                  <Row>

                    {
                      escaneandoEquipos?
                      <Button
                      className="my-4"
                      color="success"
                      type="button"
                      onClick={()=>comenzarAEscanear()}
                    >
                      Terminar de Escanear
                    </Button>

                      :

                      <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={()=>comenzarAEscanear()}
                    >
                      Comenzar a Escanear
                    </Button>

                    }
                   
                    </Row>
                    {
                      escaneandoEquipos?
                      <div>
                        <h2>{equiposEscaneadosAnteriormente.length} Equipos Escaneados</h2>
                        <h2>{equiposEscaneadosAnteriormente}</h2>
                      </div>
                      
                    
                      :null
                    }
                    
                      
                      </Col>

                      <Col md={4}></Col>

                  </Row>
                
                  
                 
                 
              </Form>                     
              </div>                    
            </CardHeader>                  
          </Card>
        </div>
        </Modal>
      </>
    )
}