import React, { useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap'
import { postTiposMantenimientos } from 'services/TiposMantenimientosService';
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';


const ModalAggTipoMan = props => {
    const { ModalAgregarTipoMantenimiento, setModalAgregarTipoMantenimiento, traerTiposMan} = props;
    const [Alert, setAlert] = useState(null);
    const [Nombre, setNombre] = useState("");
    const [Costo, setCosto] = useState("");

    const cambiarNombre = event => {
      setNombre(event.target.value);
    };
    const cambiarCosto = event => {
      setCosto(event.target.value);
    };
    const AgregarTipoMant = async()=>{
      let tipoMan = {
        nombre: Nombre,
        costo: Costo
      }
      if (validarAggTipoMan(tipoMan)==true) {
        let result = await postTiposMantenimientos(tipoMan)
        if(result != null){
          if(result.ok){
            successAlert(setAlert,'Tipo de Mantenimiento Agregado');
            recetearTipoMan();
            setModalAgregarTipoMantenimiento(false);
            traerTiposMan();
          }else{
            warningAlert(setAlert,'No se pudo agregar el Tipo de Mantenimiento')
          }
        }else{
          warningAlert(setAlert,'No se pudo agregar el Tipo de Mantenimiento')
        }
        }else{
          warningAlert(setAlert,'Complete todos los campos!')
        }
    }

  const validarAggTipoMan = (tm)=>{
  if(!tm.nombre){
      return false;
  }
  if(!tm.costo){
      return false;
  }
  return true;
  }
  const recetearTipoMan = () => {
  setNombre("")
  setCosto("")
  }
  return (
   <>
   {Alert}
   <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={ModalAgregarTipoMantenimiento}
          toggle={() => setModalAgregarTipoMantenimiento(false)}
        >
            <CardHeader>
              <div className="text-center"><b>Agregar Tipo de Mantenimiento</b>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalAgregarTipoMantenimiento(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
              </div>
            </CardHeader>
            <CardBody>
              <Form role="form">
              <FormGroup className="mb-3">Nombre:
                    <Input 
                    placeholder="Nombre..." 
                    type="text"
                    value={Nombre}
                    onChange={cambiarNombre} />
                </FormGroup>
                <FormGroup className="mb-3">Precio:
                <Input 
                placeholder="Precio..." 
                type="number"
                value={Costo}
                onChange={cambiarCosto} />
                </FormGroup>
              </Form>  
            </CardBody>
            <CardFooter>
                <div className="text-center">
                  <Button
                    color="primary"
                    type="button"
                    onClick={()=>{AgregarTipoMant()}}
                    >
                    Agregar
                  </Button>
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalAgregarTipoMantenimiento(false)}
                    >
                    Cerrar
                  </Button>                    
                </div>
            </CardFooter>   
        </Modal>
   
   </>
  )
}

export default ModalAggTipoMan