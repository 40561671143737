import SelectorPlanes from 'pages/components/SelectorPlanes';
import SelectorZonas from 'pages/components/SelectorZonas';
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap';
import { getTiposPlanes } from 'services/ServiciosServices';
import { postServicio } from 'services/ServiciosServices';
import { getZonas } from 'services/ZonasServices';

const ModalAggServicios = (props) => {

    const {idClient, setAlert, ModalAgregarServicio, setModalAgregarServicio, datos}=props;
    const [Nombre, setNombre] = useState(datos.firstName);
    const [Apellido, setApellido] = useState(datos.lastName);
    const [Direccion, setDireccion] = useState('');
    const [Ciudad, setCiudad] = useState('');
    const [Latitud, setLatitud] = useState('');
    const [Longitud, setLongitud] = useState('');
    const [Mac, setMac] = useState('');
    const [PlanId, setPlanId] = useState(null);
    const [Planes, setPlanes] = useState(null);
    const [Zona, setZona] = useState("");
    const [Zonas, setZonas] = useState("");



    const cambiarNombre = event => {
        setNombre(event.target.value);
    };
    const cambiarApellido = event => {
    setApellido(event.target.value);
    };
    const cambiarDireccion = event => {
    setDireccion(event.target.value);
    };
    const cambiarCiudad = event => {
    setCiudad(event.target.value);
    };
    const cambiarLatitud = event => {
    setLatitud(event.target.value);
    };
    const cambiarLongitud = event => {
    setLongitud(event.target.value);
    };
    const cambiarMac = event => {
    setMac(event.target.value);
    };

    const AgregarServicio = async() => {
        let servicio = {
            firstName: Nombre,
            lastName: Apellido,
            clientId: parseInt(idClient),
            planId: parseInt(PlanId),
            street1: Direccion,
            city: Ciudad,
            addressGpsLat: parseInt(Latitud),
            addressGpsLon: parseInt(Longitud),
            macEquipo: Mac,
            zonaId: parseInt(Zona)
        };
        let srv = await postServicio(servicio);
        if (srv.ok) {
          successAlert(setAlert, "Servicio Agregado!");
          reset()
          window.location.reload()
        }else{
          warningAlert(setAlert, "No se pudo agregar el servicio!")
        }
      }

      const reset =()=>{
        setNombre('');
        setApellido('');
        setDireccion('');
        setCiudad('');
        setLongitud('');
        setLatitud('');
        setMac('');
        setZona('');
        setPlanId('');
        setModalAgregarServicio(false)
      }

    useEffect( () => {
        getTiposPlanes().then(data => setPlanes(data))
        getZonas().then(data => setZonas(data))
    },[])

  return(
      <>
      <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={ModalAgregarServicio}
                            toggle={() => reset()}
                          >
                          <div className="modal-body p-0">
                            <Card className="bg-secondary shadow border-0">
                              <CardHeader className="bg-transparent pb-5">
                                <div className="text-center"><b>Agregar Servicio</b>
                                <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => reset()}
                                >
                                  <span aria-hidden={true}>×</span>
                                </button>
                                </div>
                                <div className="text-muted mt-2 mb-3">
                                <Form role="form">
                                <FormGroup className="mb-3 ">Nombre:
                                        <Input
                                          value={Nombre} 
                                          onChange={cambiarNombre}
                                          className="form-control-lg"
                                          placeholder="Nombre"
                                          type="text"
                                        />
                                </FormGroup>
                                <FormGroup className="mb-3 ">Apellido:
                                        <Input
                                          value={Apellido} 
                                          onChange={cambiarApellido}
                                          className="form-control-lg"
                                          placeholder="Apellido"
                                          type="text"
                                        />
                                </FormGroup>
                                <FormGroup className="mb-3 ">Direccion:
                                        <Input
                                          value={Direccion} 
                                          onChange={cambiarDireccion}
                                          className="form-control-lg"
                                          placeholder="Direccion"
                                          type="text"
                                        />
                                </FormGroup>
                                <FormGroup className="mb-3 ">Ciudad:
                                        <Input
                                          value={Ciudad} 
                                          onChange={cambiarCiudad}
                                          className="form-control-lg"
                                          placeholder="Ciudad"
                                          type="text"
                                        />
                                </FormGroup>
                                <FormGroup className="mb-3 ">Latitud:
                                        <Input
                                          value={Latitud} 
                                          onChange={cambiarLatitud}
                                          className="form-control-lg"
                                          placeholder="Latitud"
                                          type="number"
                                        />
                                </FormGroup>
                                <FormGroup className="mb-3 ">Longitud:
                                        <Input
                                          value={Longitud} 
                                          onChange={cambiarLongitud}
                                          className="form-control-lg"
                                          placeholder="Longitud"
                                          type="number"
                                        />
                                </FormGroup>
                                <FormGroup className="mb-3 ">Mac Equipo:
                                        <Input
                                          value={Mac} 
                                          onChange={cambiarMac}
                                          className="form-control-lg"
                                          placeholder="Mac"
                                          type="text"
                                        />
                                </FormGroup>
                                <FormGroup className="mb-3 ">Plan:

                                    <SelectorPlanes Valor={PlanId} setValor={setPlanId} arrayElementos={Planes}/>
                                </FormGroup>
                                <FormGroup className="mb-3 ">Zona:
                                    <SelectorZonas Valor={Zona} setValor={setZona} Zonas={Zonas}/>
                                </FormGroup>
                  </Form>                     
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={()=>AgregarServicio()}
                    >
                      Agregar
                    </Button>
                
                    <Button
                      color="secondary"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => reset()}
                    >
                      Cerrar
                    </Button>                    
                  </div>
                </div>                    
              </CardHeader>                  
            </Card>
          </div>
          </Modal>

      </>
  )
};

export default ModalAggServicios;
