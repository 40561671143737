import { warningAlert } from 'pages/components/SweetAlert'
import { successAlert } from 'pages/components/SweetAlert'
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap'
import { putEditarTipoMantenimiento } from 'services/TiposMantenimientosService'


const ModalEditTipoMan = props => {
    const { ModalEditarTipoMantenimiento, setModalEditarTipoMantenimiento, traerTiposMan, TipoSelect} = props
    const [Alert, setAlert] = useState(null);
    const [Nombre, setNombre] = useState("");
    const [Costo, setCosto] = useState("");

    const cambiarNombre = event => {
      setNombre(event.target.value);
    };
    const cambiarCosto = event => {
      setCosto(event.target.value);
    };

    const actualizarTipoMan= async()=>{
      let tipoManAux = {}
      Object.assign(tipoManAux, TipoSelect)
      tipoManAux.nombre = Nombre;
      tipoManAux.costo = Costo
      let valido = await putEditarTipoMantenimiento(tipoManAux);
      if(valido){
        successAlert(setAlert,'Tipo de Mantenimiento Editado');
        recetearTipoMan()
        setModalEditarTipoMantenimiento(false);
        traerTiposMan()
      }else{
        warningAlert(setAlert,'No se pudo Editar El Mantenimiento')
    }
    }
    const recetearTipoMan = () => {
      setNombre("")
      setCosto("")
    }
    const establecerTipoMan= () => {
        setNombre(TipoSelect.nombre)
        setCosto(TipoSelect.costo)
    }
    useEffect(() => {
      establecerTipoMan()
      }, [])
    
  return (
   <>
   {Alert}
   <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={ModalEditarTipoMantenimiento}
          toggle={() => setModalEditarTipoMantenimiento(false)}
        >
            <CardHeader>
              <div className="text-center"><b>Editar Tipo de Mantenimiento</b>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalEditarTipoMantenimiento(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
              </div>
            </CardHeader>   
            <CardBody>
              <Form role="form">
                <FormGroup className="mb-3">Nombre:
                    <Input 
                    placeholder="Nombre..." 
                    type="text"
                    value={Nombre}
                    onChange={cambiarNombre}  />
                </FormGroup>
                <FormGroup className="mb-3">Precio:
                <Input 
                    placeholder="Precio..." 
                    type="number"
                    value={Costo}
                    onChange={cambiarCosto} />
                </FormGroup>
              </Form>   
            </CardBody>
            <CardFooter>
                <div className="text-center">
                  <Button
                    color="primary"
                    type="button"
                    onClick={()=>{actualizarTipoMan()}}
                  >
                    Actualizar
                  </Button>
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalEditarTipoMantenimiento(false)}
                  >
                    Cerrar
                  </Button>                    
                </div>        
            </CardFooter>
        </Modal>
   
   </>
  )
}

export default ModalEditTipoMan