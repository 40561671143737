

export const validarCaracteresMac = (Macaddress) => {
    if(Macaddress.toString().length !== 12){
        return {
            ok:false,
            mensaje:'Mac no valida , no hay 12 caracteres'
        }
    }

    let cadena = "ABCDEF0123456789";
    for (let i = 0; i < Macaddress.length; i++) {
      const c = Macaddress[i];
      if(cadena.indexOf(c) == -1){
        return {
            ok:false,
            mensaje:'Los caracteres no son de A-E Y 0-9'
        }
      }
    }
    return {ok:true}
}



export const validarNuevoEquipo = (equipo) => {

    let {estadoId,macAddress,categoriaId} = equipo;

    if(!estadoId || estadoId === ""){
        return {ok:false,mensaje:"No tiene estado"}
    }
    if(!macAddress || macAddress === ""){
        return {ok:false,mensaje:"No tiene macAddress"}
    }
    if(!categoriaId || categoriaId === ""){
        return {ok:false,mensaje:"No tiene categoria"}
    }

    return {ok:true}

}


