import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Table } from 'reactstrap'
import { getTiposMaterialesEmpleados } from 'services/TiposMaterialesService'

const MaterialesEmpleados = ({EmpleadoId}) => {
    const [Materiales, setMateriales] = useState([])

    const traerTiposMaterialesEmpleado = async() => {
        let tipos = await getTiposMaterialesEmpleados({idEmpleado: EmpleadoId})
        if (tipos) {
            setMateriales(tipos.tme)
        }
    }
    useEffect(() => {
        traerTiposMaterialesEmpleado()
    }, [])
    
  return (
    <>
    <Card>
        <CardHeader>
        <h1>Materiales Empleados</h1>
        </CardHeader>
        <CardBody>
            <Table className='text-center' responsive>
                <thead className='bg-secondary'>
                    <tr>
                        <th>Tipo Material</th>
                        <th>Codigo</th>
                        <th>Cantidad</th>
                        <th>Unidad</th>
                    </tr>
                </thead>
                <tbody>
                    {Materiales?
                    Materiales.map((m)=>{
                        return(
                            <tr key={m.idTipoMaterial}>
                                <th>{m.tiposMateriale?.nombre}</th>
                                <td>{m.tiposMateriale?.codigoProducto}</td>
                                <td>{m.cantidad}</td>
                                <td>{m.tiposMateriale?.unidad}</td>
                            </tr>

                        )
                    })
                :
                <>
                <tr>
                    <td>
                        <p>Este Empleado  no tiene asignado ningun Material!!</p>
                    </td>
                </tr>
                </>
                }
                </tbody>
            </Table>
        </CardBody>
    </Card>
    
    </>
  )
}

export default MaterialesEmpleados