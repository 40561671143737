import React, { useEffect, useState } from 'react'
import { Badge, Button, Table, UncontrolledTooltip } from 'reactstrap'
import moment from 'moment';
import ModalAgregarEs from '../components/ModalAgregarEspera';
import { getRolPermisos } from 'services/RolService';
import EtiquetaEsperaAsignada from './EtiquetaEspAsignada';
import ModalRechazarEspera from './ModalRechazarEspera';


export default function TableEsperas(props){
    const {Esperas, filtrarEsperasEstado, filtrarPorZona, valorZonas, ContadorEsperas} = props
    const [alertSweet, setAlert] = useState(null);
    const [ModalAgregarEspera , setModalAgregarEspera] = useState(false);
    const [EspSeleccionada, setEspSeleccionada] = useState(null);
    const [Permisos, setPermisos] = useState([]);
    const [ModalEsperaRechazada, setModalEsperaRechazada] = useState(false);



    const obtenerFormatoFecha = (fechaIngreso)=>{
        let fecha = new Date(fechaIngreso);
        let dia = fecha.getDate();
        let mes = fecha.getMonth();
        let ano = fecha.getFullYear();
        let fechaFormateada = `${dia+1}/${mes+1}/${ano}`;
        return fechaFormateada;
    }
    const obtenerFormatoHora = (creado)=>{
        let fecha = new Date(creado);
        
        let horaFormateada = moment(fecha).fromNow();
        return horaFormateada;
    }
    
    const editarEspera = (espe)=>{
      setEspSeleccionada(espe);
      setModalAgregarEspera(true); 
    }
    const RechEspera = (espe)=>{
      setEspSeleccionada(espe);
      setModalEsperaRechazada(true); 
    }
    const traerPermisos= async()=>{

      let permisos= await getRolPermisos();
      if(permisos !=null && permisos!=''){
        setPermisos(permisos)
      }
    }
    const tienePermiso=(nombrePermiso)=>{
      return Permisos.includes(nombrePermiso);
    } 
    useEffect(() => {
      traerPermisos();
    }, [])

    
//     useEffect(() => {
//       Contador()
      
//     }, [])

return(
    <>
    {alertSweet}
            <Table responsive>
                   <thead className="text-center bg-light">
                   <tr>
                    <th scope="col">ESPERA ID</th>
                    <th scope="col">CONTRATO</th>
                    <th scope="col">AGENDADO</th>
                    <th scope="col">NOMBRE</th>
                    <th scope="col">DIRECCION</th>
                    <th scope="col">TELEFONO</th>
                    <th scope="col">SE COMUNICO POR</th>
                    <th scope="col">USUARIO</th>
                    <th scope="col">TURNO</th>
                    <th scope="col">OBSERVACIONES</th>
                    {Permisos && tienePermiso('Editar-Espera')?
                    <th scope="col">EDITAR</th>
                    :
                    <></>
                    }
                    <th scope="col" />
                    
                  </tr>
                   </thead>
                   <tbody>
                         {ContadorEsperas>0?
                         <>
                         {Esperas?
                  Esperas.map((ecl, index)=>{
                    return(
                         <tr key={index} className="text-center" >
                    
                        <th scope="row">
                        <div className=" text-center" >
                              {ecl.id}
                          </div>
                        </th>
                        <td>
                        <div className=" text-center">
                              <Badge  color="danger" className="badge-sm" pill>{obtenerFormatoHora(ecl.creado)}</Badge>
                              {obtenerFormatoFecha(ecl.creado)}
                        </div>
                        </td>
                        <td>
                            <div className="text-center">
                                <EtiquetaEsperaAsignada  
                                setAlert={setAlert} 
                                Esperas={ecl} 
                                Asignado={ecl.Asignado} 
                                filtrarEsperasEstado={filtrarEsperasEstado}
                                EspSeleccionada={EspSeleccionada}
                                />
                            </div>
                        </td>
                        <td>
                        <div className=" text-center">
                              {ecl.apellido}, {ecl.nombre}
                        </div>
                        </td>
                        <td>
                        <div style={{maxWidth:"20rem", wordWrap:"normal"}}>
                        <p style={{minWidth:"18rem",maxWidth:"19rem", whiteSpace:"initial", overflow:"hidden"}}>
                              {ecl.direccion}
                        </p>
                        
                        </div>
                        </td>
                        <td>
                        <div className=" text-center" >
                              {ecl.telefono}
                        </div>
                        
                        </td>
                        <td>
                        <div className=" text-center" >
                              {ecl.formaContacto}
                        </div>
                        </td>
                        <td>
                        <div className=" text-center" >
                              {ecl.usuarioContacto}
                        </div>
                        </td>
                        <td>
                        <div className=" text-center" >
                              {obtenerFormatoFecha(ecl.turno)}
                        </div>
                        </td>
                        <td style={{maxWidth:"20rem", wordWrap:"normal"}}>
                                    <p style={{minWidth:"18rem",maxWidth:"19rem", whiteSpace:"initial", overflow:"hidden"}}>
                                    {ecl.comentario}
                                    </p>
                        </td>
                        {Permisos && tienePermiso('Editar-Espera')?
                        <td>
                        <div className=" text-center">
                        <Button className="btn-icon btn-3 btn-sm btn-outline-default rounded-circle"  id={`tooltip${ecl.id}`} type="button" onClick={()=>{editarEspera(ecl)}}>
                              <i className="fas fa-edit"></i>
                        </Button>
                        <UncontrolledTooltip
                        delay={0}
                        placement="bottom"
                        target={`tooltip${ecl.id}`}
                        >
                        Editar Espera
                        </UncontrolledTooltip>
                        </div>
                        </td>
                        :
                        <></>
                        }
                        {Permisos && tienePermiso('Eliminar-Espera')?
                        <td>
                        <div className=" text-center">
                        <Button id={`tooltip2${ecl.id}`} className="btn-icon btn-3 btn-sm btn-outline-danger rounded-circle"  type="button" onClick={()=>{RechEspera(ecl)}}>
                              <span className="btn-inner--icon">
                              <i className="ni ni-fat-remove"/>
                              </span>    
                        </Button>
                        <UncontrolledTooltip
                        delay={0}
                        placement="bottom"
                        target={`tooltip2${ecl.id}`}
                        >
                        Eliminar Espera
                        </UncontrolledTooltip>
                        </div>
                        </td>
                        :
                        <></>
                        }
                      </tr> 
                     
                        )
                        }
                        )
                        :
                        <>
                        -
                        </>
                        }
                         </>
                        :
                         <>
                         <tr>
                        <td>
                              <div>
                        <h3>No hay esperas pendientes en esta zona!</h3>

                              </div>
                        </td>
                        
                        </tr>
                        </>
                         }
                        </tbody> 

             </Table>
             {EspSeleccionada!=null?
             <ModalAgregarEs 
             ModalAgregarEspera={ModalAgregarEspera} 
             setModalAgregarEspera={setModalAgregarEspera}
             EsperaSelec={EspSeleccionada}
             Editar={true}
             filtrarPorZona={filtrarPorZona}
             valorZonas={valorZonas}
             />
            :
            <></>
            }
            {EspSeleccionada!=null?
             <ModalRechazarEspera
             ModalEsperaRechazada={ModalEsperaRechazada}
             setModalEsperaRechazada={setModalEsperaRechazada}
             EsperaSelec={EspSeleccionada}
             filtrarPorZona={filtrarPorZona}
             valorZonas={valorZonas}
              />
            :
            <></>
            }
             </>
)
        }