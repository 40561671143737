import React, { useState } from 'react'
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap'


const ModalCantidadAEntregar = ({ModalCantidad, setModalCantidad, Cantidad, setCantidad,agregarTmConCantidad,TMSelec}) => {
    const [Alert, setAlert] = useState(null);
    const [Error, setError] = useState({})
    
    const cambiarCantidad = (e)=> {
        setCantidad(e.target.value)
    }
    

    const Confirmar = async() =>{
        agregarTmConCantidad()        
    }

  return (
    <>
        {Alert}
        <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={ModalCantidad}
        toggle={() => setModalCantidad(false)}
        >
        <div>
        <Card>
            <CardHeader className="pb-3">
            <div className="text-center"><b>Cantidad:</b>
            <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setModalCantidad(false)}
        >
            <span aria-hidden={true}>×</span>
        </button>
            </div>
            </CardHeader>  
            <CardBody>
            <div className=" mt-5 mb-3">
            <Form role="form">
            <FormGroup>
                <Input
                    type="number"
                    onChange={cambiarCantidad}
                    />
            </FormGroup>
            </Form>                     
            </div>                    
            </CardBody>
            <CardFooter>
                <div className="text-center">
                <Button
                    className=""
                    color="primary"
                    type="button"
                    onClick={()=>Confirmar()}
                    
                    >
                    Confirmar
                </Button>
            
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalCantidad(false)}
                >
                    Cerrar
                </Button>                    
                </div>
                </CardFooter>                
        </Card>
        </div>
        </Modal></>
  )
}

export default ModalCantidadAEntregar