import React, { useEffect, useState } from 'react'
import Buscador from '../components/Buscador';
import LoadingComponent from 'pages/components/Loading';
import Paginacion from 'pages/components/Paginacion';
import SelectorZonas from 'pages/components/SelectorZonas';
import { Button, Card, CardBody, Col, Navbar, NavbarBrand, Row} from 'reactstrap'
import { getEsperas } from 'services/EsperaServices';
import { getRolPermisos } from 'services/RolService';
import { getZonas } from 'services/ZonasServices';
import ModalAgregarEs from '../components/ModalAgregarEspera';
import TableEsperas from '../components/TableEsperas';

const EnEspera = () => {
    const [ModalAgregarEspera , setModalAgregarEspera] = useState(false);
    const [Esperas, setEsperas] = useState([]); 
    const [Visible, setVisible] = useState(false);
    const [Zonas, setZonas] = useState([]);
    const [valorZonas, setSelectorZonas] = useState("");
    const [Permisos, setPermisos] = useState([]);
    const [Contador, setContador]= useState("");
    const [ContadorEsperas, setContadorEsperas]= useState("");
    

    const filtrarEsperasEstado = async(est) => {
      setVisible(true)
      let params = {estado:est, order:'ASC', page:0}
      let espe = await getEsperas(params);
      if (espe != null && espe != '') {
        setEsperas(espe);
        ContadorE(espe)
      };
      setVisible(false)
    }
    const contadorEsperas = async(est) => {
      let params = {estado:est}
      let espeT = await getEsperas(params);
      if (espeT != null && espeT != '') {
        setContador(espeT.length);
      }
    }

    const traerZonas = async() => {
      let zns = await getZonas();
      if (zns != null && zns != '') {
          setZonas(zns);
      }
    }
    const filtrarPorZona = async(zon) => {
      setVisible(true)
        let params = {zonaId:zon, estado:1}
        let esp = await getEsperas(params);
        if (esp != null && esp != '') {
            setEsperas(esp);
            ContadorE(esp)
        }else{
          setContadorEsperas(0);
          setEsperas(0)
        }
      setVisible(false)
    }

    const ContadorE=(Espe)=>{
      let contador = Espe.length
      if (contador) {
        setContadorEsperas(contador)
      }
}

    const filtrarZona= (valor) =>{
      setSelectorZonas(valor)
      filtrarPorZona(valor);
      
   }
   const traerPermisos= async()=>{

    let permisos= await getRolPermisos();
    if(permisos !=null && permisos!=''){
      setPermisos(permisos)
    }
  }
  const tienePermiso=(nombrePermiso)=>{
    return Permisos.includes(nombrePermiso);
  } 

    useEffect(() => {
      filtrarEsperasEstado(1);
      traerZonas();
      traerPermisos();
      contadorEsperas(1)
    }, [])
    return (
        <>
        <LoadingComponent Visible={Visible}/>
        
        <Card style={{width:"100%"}} className="mt-3">
            {valorZonas?
          <h1 className="ml-4 mt-3">
              <i className="far fa-clock">En Espera: {ContadorEsperas} </i>
          </h1>
            :
            <h1 className="ml-4 mt-3">
              <i className="far fa-clock">En Espera: {Contador} </i>
          </h1>
          }
            <Navbar className="navbar-horizontal navbar-light ml-3" expand="lg">
                <Row>
                  <Col lg={{size: "auto"}}>
                    <NavbarBrand>
                      <Buscador traerObjetos={filtrarEsperasEstado} setObjetos={setEsperas} getObjetos={getEsperas}/>
                    </NavbarBrand>
                  </Col>
                  <Col>
                    <SelectorZonas Valor={valorZonas} setValor={filtrarZona} Zonas={Zonas}/>
                  </Col>
                  <Col>
                  {Permisos && tienePermiso('Agregar-Espera')?
                    <Button className=" btn-md btn-outline-info  float-right" onClick={()=>setModalAgregarEspera(true)}>
                        Agregar Espera <i className="fas fa-user-plus"></i>
                    </Button>
                    :
                    <></>
                    }
                  </Col>
                </Row>
            </Navbar>
            <CardBody>
                <TableEsperas 
                  Esperas={Esperas}
                  filtrarEsperasEstado={filtrarEsperasEstado}
                  filtrarPorZona={filtrarPorZona}
                  valorZonas={valorZonas}
                  ContadorEsperas={ContadorEsperas}
                />
                <Paginacion ZonaEsp={valorZonas} traerObjetos={filtrarEsperasEstado} setObjetos={setEsperas} getObjetos={getEsperas}/>
            </CardBody>
        </Card>
      <ModalAgregarEs 
        ModalAgregarEspera={ModalAgregarEspera} 
        setModalAgregarEspera={setModalAgregarEspera}
        filtrarEsperasEstado={filtrarEsperasEstado}
        Editar={false}
      />
       </>
    )
}

export default EnEspera
