import React, { useState } from 'react'
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, Modal } from 'reactstrap'
import {warningAlert, successAlert} from "../../../components/SweetAlert"
import LoadingComponent from 'pages/components/Loading'
import SubirFotoComponent from 'pages/components/SubirFoto'


export default function ModalAgregarComentario(props){
    const {
      ModalAggComentario, setModalAggComentario, TituloAgg, vehiculoId, traerVehiculo, post
      } = props

        const [Titulo, setTitulo] = useState("");
        const [Comentario, setComentario] = useState("");
        const [alertSweet, setAlert] = useState(null);
        const [Visible, setVisible] = useState(false);
        const [foto, setFoto] = useState(null)  

        const cambiarTitulo = event => {
          setTitulo(event.target.value);
        };
        const cambiarComentario = event => {
          setComentario(event.target.value);
        };

        const validar = () => {
          let dev = true;
          if (!Titulo || !Comentario) {
            if (!Titulo){dev = false; warningAlert(setAlert,"Debe escribir un titulo")}
            if (!Comentario){dev = false; warningAlert(setAlert,"Debe escribir un comentario")}
          }
          return dev
        }

        const agregarComentario = async() => {
          setVisible(true)
          if (validar()) {
            let coment = {
              titulo:Titulo,
              comentario:Comentario,
              archivo:foto,
              fecha:new Date(),
              vehiculoId
            }

            let newComent = await post(coment);
            let resp = `${TituloAgg} Agregado`;
            if (newComent && newComent.ok) {
              successAlert(setAlert, resp);
              traerVehiculo();
              setModalAggComentario(false)
              setTitulo("")
              setComentario("")
            }
            else{
              let resp = `Error al agregar ${TituloAgg}`
              warningAlert(setAlert, resp);
            }
          }else{
            let resp = `Error al agregar ${TituloAgg}, complete bien los datos`
            warningAlert(setAlert, resp);
          }
          setVisible(false)
        }

        

    return(
      <>
        {alertSweet}
        <LoadingComponent Visible={Visible}/>
        <Modal
          className="modal-dialog-centered"
          size="md"
          isOpen={ModalAggComentario}
          toggle={() => setModalAggComentario(false)}
        >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-center"><b>Agregar {TituloAgg}</b>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalAggComentario(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
              </div>
              <div className="text-muted mt-2 mb-3">
              <Form role="form">
                <FormGroup className="mb-3">
                  <h2>Titulo:</h2>
                  <InputGroup className="input-group-alternative">
                    <Input placeholder="Titulo.." type="text"  onChange={cambiarTitulo} value={Titulo}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <h2>Comentario:</h2>
                  <InputGroup className="input-group-alternative">
                    <Input placeholder="Comentario.." type="text"  onChange={cambiarComentario} value={Comentario}/>
                  </InputGroup>
                </FormGroup>
                </Form>                     
                <SubirFotoComponent setFoto={setFoto}/>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={()=>agregarComentario()}
                  >
                    Agregar
                  </Button>
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalAggComentario(false)}
                  >
                    Cerrar
                  </Button>                    
                </div>
              </div>                    
            </CardHeader>                  
          </Card>
        </div>
        </Modal>
      </>
    )
}