import React, { useRef } from "react";
import { Card } from "reactstrap";
import FacturaComoponent from './FacturaComponent'
import '../styles/stylesFactura.css'


  
export default class ImprimirFactura extends React.PureComponent{


    

    render(){
        return (
          <>
            <Card 
            style={{    
            paddingTop: 50,
            paddingBottom:50,
            paddingLeft: 20,
            paddingRight: 20,  
          }}
            className="cardGeneral mt-4" /*id utilizado para cambiar css al imprimir*/
            >
                <FacturaComoponent tamanio={this.props.tamanio} /* volvemos a pasar la variable tamanio */ 
                  factura={this.props.factura}
                  cae={this.props.cae}
                  tipo={this.props.tipo}
                  datos={this.props.datos}
                  tamanioTotales={this.props.tamanioTotales}
                  countTamanioTotales={this.props.countTamanioTotales}
                />
            </Card>
    
          </>
        );

    }
  }
