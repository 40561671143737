import { successAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react'
import { Button, ButtonGroup, FormGroup, Input, Modal } from 'reactstrap';
import classnames from "classnames";
import { postEventos } from 'services/EventosService';
import { putEditarEspera } from 'services/EsperaServices';
import SelectorEmpleados from 'pages/components/SelectorEmpleados';
import { warningAlert } from 'pages/components/SweetAlert';


 const ModalAggEvento = (props) => {
     const {createCalendar, ModalAgregarEvento, setModalAgregarEvento, Espera }=props;

     const [Radios, setRadios] = useState("");
     const [Titulo, setTitulo] = useState("");
     const [Alert, setAlert] = useState("");
     const [Descripcion, setDescripcion] = useState("");
     const [Hora, setHora] = useState("");
     const [Turno, setTurno] = useState("");
     const [Empleado, setEmpleado] = useState("");

const addNewEvent= async()=>{
  let sepF = Turno.split('-');
  let fecha = new Date(parseInt(sepF[0]), parseInt(sepF[1])-1, parseInt(sepF[2]));
  let evento = {
    title: Titulo,
    start: fecha,
    end: fecha,
    className: Radios,
    hora: Hora,
    comentario: Descripcion,
    esperaId: Espera.id,
    empleadoId: parseInt(Empleado),
    tipoId: 1
   };
   if (validarAggEvento(evento)===true) {
     let ev = await postEventos(evento);
         if (ev) {
          cambiarAsignado()
          setTitulo("");
          setRadios("");
          successAlert(setAlert, "Evento Creado!", "");
          setModalAgregarEvento(false);
          createCalendar()
           
         }
    
   } else {
    warningAlert(setAlert,'Complete todos los campos!')
   }
       
}

const cambiarAsignado=async ()=>{
  let evn= { 
    id: Espera.id,
    Asignado : Turno }

  let evnt =await putEditarEspera (evn)
  if(evnt){
    
  }
}

    const cambiarTitulo = event => {
        setTitulo(event.target.value);
    };
    const cambiarDescripcion = event => {
      setDescripcion(event.target.value);
    };
    const cambiarHora = event => {
      setHora(event.target.value);
    };
    const cambiarTurno = event => {
      setTurno(event.target.value);
    };

    const validarAggEvento = (ev)=>{

      if(!ev.title){
        return false;
      }
      if(!ev.start){
        return false;
      }
      if(!ev.end){
        return false;
      }
      if(!ev.comentario){
        return false;
      }
      if(!ev.empleadoId){
        return false;
      }
      return true;
    }

    

    return(
        <>
        {Alert}
        {Espera?
        
        <Modal
          isOpen={ModalAgregarEvento}
          toggle={() => setModalAgregarEvento(false)}
          className="modal-dialog-centered modal-secondary modal-sm"
        >
          <div className="modal-body">
            <form className="new-event--form">
              <FormGroup>
                <h1> <u>Espera ID: {Espera.id}</u></h1>
              </FormGroup>
              <FormGroup>
                <label className="form-control-label">Titulo del Evento</label>
                <Input
                  className="form-control-alternative new-event--title"
                  placeholder="Titulo del Evento..."
                  type="text"
                  onChange={cambiarTitulo}
                />
              </FormGroup>

              <FormGroup>
              <h4>Apellido y Nombre:</h4>{Espera.apellido}, {Espera.nombre}<br/>
              <h4>Dirección:</h4>{Espera.direccion}<br/>
              <h4>Teléfono:</h4>{Espera.telefono}<br/>
              <h4>Ubicación:</h4> Lat: {Espera.lat}, Lng;{Espera.lng}
              <h4>Plan a Instalar:</h4> {Espera.planId}
              </FormGroup>
              <FormGroup>
                  <label>Instalador:</label>
                  <SelectorEmpleados valor={Empleado} cambiarValor={setEmpleado} titulo={false}/>
              </FormGroup>
              <FormGroup>
                <label className="form-control-label">Observaciones:</label>
                <Input
                placeholder='Ej: Porton negro...'
                type="textarea"
                onChange={cambiarDescripcion}
                  /> 
              </FormGroup>
              <FormGroup>
                <label className="form-control-label">Turno:</label>
                <Input
                  type="date"
                  value={Turno} 
                  onChange={cambiarTurno}
                />
              </FormGroup>
              <FormGroup>
                <label className="form-control-label">Horario Estimado</label>
                <Input
                  type="time"
                  value={Hora}
                  onChange={cambiarHora}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <label className="form-control-label d-block mb-3">
                  Identificador
                </label>
                <ButtonGroup
                  className="btn-group-toggle btn-group-colors event-tag"
                  data-toggle="buttons"
                >
                  <Button
                    className={classnames("bg-info", {
                      active: Radios === "bg-info"
                    })}
                    color=""
                    type="button"
                    onClick={() => setRadios("bg-info")}
                  />
                  <Button
                    className={classnames("bg-warning", {
                      active: Radios === "bg-warning"
                    })}
                    color=""
                    type="button"
                    onClick={() => setRadios("bg-warning" ) }
                  />
                  <Button
                    className={classnames("bg-danger", {
                      active: Radios === "bg-danger"
                    })}
                    color=""
                    type="button"
                    onClick={() => setRadios("bg-danger" )}
                  />
                  <Button
                    className={classnames("bg-success", {
                      active: Radios === "bg-success"
                    })}
                    color=""
                    type="button"
                    onClick={() => setRadios("bg-success")}
                  />
                  <Button
                    className={classnames("bg-primary", {
                      active: Radios === "bg-primary"
                    })}
                    color=""
                    type="button"
                    onClick={() => setRadios("bg-primary")}
                  />
                </ButtonGroup>
              </FormGroup>
            </form>
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={()=>addNewEvent()}
            >
              Añadir Evento
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setModalAgregarEvento(false)}
            >
              Cerrar
            </Button>
          </div>
        </Modal>
        :
        <>-</>
        }
        </>
    )
  };
  export default ModalAggEvento