/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Login from "pages/public/Login.js";
import AuditarDeposito from "pages/private/auditarDeposito/AuditarDeposito.js";
import Empleados from 'pages/private/empleados/Empleados.js';
import StockMultiple from "pages/private/stockMultiple/StockMultiple";
import Clientes from 'pages/private/clientes/Clientes.js';
import PerfilClientes from 'pages/private/perfilClientes/PerfilClientes.js';
import PerfilEmpleados from "pages/private/perfilEmpleados/PerfilEmpleados";
import Casos from "pages/private/casos/Casos";
import CasoParticular from "pages/private/casos/casoParticular/CasoParticular.js";
import CategoriasEquipos from "pages/private/categoriasEquipos/CategoriasEquipos";
import Dashboard from "pages/private/dashboard/Dashboard";
import EquiposGeneral from "pages/private/equipos/EquiposGeneral";
import VistaServicios from "pages/private/perfilClientes/subvistas/serviciosCliente/VistaServicios.js";
import EquiposEmpleados from "pages/private/equiposEmpleados/EquiposEmpleados.js";
import CambiosEquipos from "pages/private/cambiosDeEquipos/CambiosEquipos.js";
import Servicios from "pages/private/servicios/Servicios.js";
import RetirosEquipos from "pages/private/retiroEquipos/RetiroDeEquipos.js";
import FacturaCliente from "pages/private/facturaCliente/FacturaCliente.js";
import ListaDeEspera from "pages/private/listaDeEspera/ListaDeEspera.js";
import Vehiculos from "pages/private/vehiculos/Vehiculos.js";
import VehiculoParticular from "pages/private/vehiculos/subVistas/VehiculoParticular.js";
import PapelesVehiculo from "pages/private/vehiculos/subVistas/PapelesVehiculo.js";
import Home from "pages/private/Home/Home";
import Google from "maps/Google";
import CierreCaja from "pages/private/cierreCaja/CierreCaja";
import Contadores from "pages/private/contadores/Contadores";
import ControlPagos from "pages/private/controlPagos/ControlPagos";
import ControlPagoEspecifico from "pages/private/controlPagos/pagoEspecifico/ControlPagoEspecifico";
import Agenda from "pages/private/agenda/Agenda";



import { Caes } from "pages/private/caes/Caes";
import PermisosRol from "pages/private/dashboard/subVistas/roles/subVistas/PermisosRol";
import { PuntosDeVenta } from "pages/private/PuntosDeVenta/PuntosDeVenta";
import { ReportesFacturacion } from "pages/private/ReportesFacturacion/ReportesFacturacion";
import { ClientesFiscales } from "pages/private/ClientesFiscales/ClientesFiscales";
import ContadorInstalaciones from "pages/private/contadorInstalaciones/ContadorInstalaciones";
import Zonas from "pages/private/Zonas/Zonas";
import TiposMantenimiento from "pages/private/tiposMantenimiento/TiposMantenimiento";
import Egresos from "pages/private/egresos/Egresos";
import Precios from "pages/private/precios/Precios";
import EquiposExcel from "pages/private/equiposExcel/EquiposExcel";
import Stock from "pages/private/stock/Stock";
import MaterialesVista from "pages/private/stock/MaterialesVista";
import DetalleMaterial from "pages/private/stock/DetalleMaterial";
import Tendidos from "pages/private/stock/Tendidos";
import Historico from "pages/private/historico/Historico";
import StockEmpleado from "pages/private/stockEmpleado/StockEmpleado";
import Almacenes from "pages/private/almacenes/Almacenes";
import Movimientos from "pages/private/movimientos/Movimientos";
import ControlMovimientos from "pages/private/controlMovimientos/ControlMovimientos";
import Obras from "pages/private/obras/Obras";
import ObraParticular from "pages/private/obras/obraParticular/ObraParticular";
import { FacturacionAutomatica } from "pages/private/facturacionAutomatica/FacturacionAutomatica";
import { DatosClientes } from "pages/private/datosClientes/DatosClientes";

const routes = [
  // #################################### Private Routes #####################################

  // <<<<<<<<<<<<< Inicio Rutas Sidebar >>>>>>>>>>>>>>

  {
    visible: true,
    path: "/auditarDeposito",
    name: "Auditar Deposito",
    icon: "ni ni-archive-2 text-green",
    component: AuditarDeposito,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/agenda",
    name: "Agenda",
    icon: "fas fa-user-clock",
    component: Agenda,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/almacenes",
    name: "Almacenes",
    icon: "ni ni-archive-2 text-green",
    component: Almacenes,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/cambiosEquipos",
    name: "Cambios de Equipos",
    icon: "fas fa-sync-alt",
    component: CambiosEquipos,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/casos",
    name: "Casos",
    icon: "fas fa-exclamation-triangle",
    component: Casos,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/categoriasEquipos",
    name: "Categorias Equipos",
    icon: "fas fa-exclamation-triangle",
    component: CategoriasEquipos,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/cierreCaja/:puntoVentaId",
    name: "Cierre De Caja",
    icon: "fa-solid fa-vault",
    component: CierreCaja,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/clientes",
    name: "Clientes",
    icon: "fas fa-user-friends",
    component: Clientes,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/contadores/:idCierreCaja",
    name: "Contadores",
    icon: "fa-solid fa-vault",
    component: Contadores,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/contadorInstalaciones",
    name: "Contador Instalaciones",
    icon: "ni ni-archive-2 text-green",
    component: ContadorInstalaciones,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/controlPagos",
    name: "Control Pagos",
    icon: "fa-solid fa-vault",
    component: ControlPagos,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/datosClientes",
    name: "Datos Clientes",
    icon: "fas fa-chart-line",
    component: DatosClientes,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/egresos",
    name: "Egresos",
    icon: "fa-solid fa-vault",
    component: Egresos,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/equiposExcel",
    name: "Equipos Excel",
    icon: "ni ni-archive-2 text-green",
    component: EquiposExcel,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/equiposGeneral",
    name: "Equipos General",
    icon: "fas fa-satellite-dish",
    component: EquiposGeneral,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/empleados",
    name: "Empleados",
    icon: "fas fa-user-tie",
    component: Empleados,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/facturacionEfectivo",
    name: "Facturacion Efectivo",
    icon: "fas fa-user-tie",
    component: Empleados,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/dashboard",
    name: "Dashboard",
    icon: "fas fa-chart-line",
    component: Dashboard,
    layout: "/admin",
  },

  {
    visible: true,
    path: "/historico",
    name: "Historico",
    icon: "fas fa-user-friends",
    component: Historico,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/listaDeEspera",
    name: "Lista de Espera",
    icon: "fas fa-user-clock",
    component: ListaDeEspera,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/movimientos",
    name: "Movimientos",
    icon: "fa-solid fa-vault",
    component: Movimientos,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/obras",
    name: "Obras",
    icon: "fas fa-user-clock",
    component: Obras,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/precios",
    name: "Precios",
    icon: "fas fa-wifi",
    component: Precios,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/facturacionAutomatica",
    name: "Programacion De Facturacion",
    icon: "fas fa-wifi",
    component: FacturacionAutomatica,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/retirosServicios",
    name: "Retiros De Servicios",
    icon: "fas fa-reply-all",
    component: RetirosEquipos,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/servicios",
    name: "Servicios",
    icon: "fas fa-wifi",
    component: Servicios,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/stockMultiple",
    name: "Stock Multiple",
    icon: "ni ni-archive-2 text-green",
    component: StockMultiple,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/tiposMantenimiento",
    name: "Tipos de Mantenimiento",
    icon: "fas fa-sync-alt",
    component: TiposMantenimiento,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/vehiculos",
    name: "Vehiculos",
    icon: "fas fa-truck",
    component: Vehiculos,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/vistasMateriales",
    name: "Vistas",
    icon: "ni ni-archive-2 text-green",
    component: MaterialesVista,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/vistasDetalle",
    name: "Vistas",
    icon: "ni ni-archive-2 text-green",
    component: DetalleMaterial,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/vistasTendido",
    name: "Vistas",
    icon: "ni ni-archive-2 text-green",
    component: Tendidos,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/puntosdeventa",
    name: "PuntosDeVenta",
    icon: "fas fa-truck",
    component: PuntosDeVenta,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/caes",
    name: "Caes",
    icon: "fas fa-truck",
    component: Caes,
    layout: "/admin",
  },

  {
    visible: true,
    path: "/reportes-facturacion",
    name: "Reportes Facturacion",
    icon: "fas fa-truck",
    component: ReportesFacturacion,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/fiscales",
    name: "Clientes Fiscales",
    icon: "fas fa-truck",
    component: ClientesFiscales,
    layout: "/admin",
  },
  {
    visible: true,
    path: "/listadoZonas",
    name: "Zonas",
    icon: "ni ni-archive-2 text-green",
    component: Zonas,
    layout: "/admin",
  },



  // <<<<<<<<<<<<< Fin Rutas Sidebar >>>>>>>>>>>>>>
  {
    visible: false,
    path: "/casoParticular/:idCaso",
    name: "CasoParticular",
    icon: "fas fa-user-friends",
    component: CasoParticular,
    layout: "/admin",
  },
  {
    visible: false,
    path: "/controlMovimientos/:idPuntoVenta",
    name: "Control Movimientos",
    icon: "fas fa-user-clock",
    component: ControlMovimientos,
    layout: "/admin",
  },
  {
    visible: false,
    path: "/control-pagos/especifico/:Metodo/:idPago",
    name: "ControlPagoEspecifico",
    icon: "fas fa-user-friends",
    component: ControlPagoEspecifico,
    layout: "/admin",
  },
  {
    visible: false,
    path: "/equiposEmpleados/:idEmpleado",
    name: "EquiposEmpleados",
    icon: "fas fa-user-friends",
    component: EquiposEmpleados,
    layout: "/admin",
  },
  {
    visible: false,
    path: "/facturaCliente/:idFactura",
    name: "FacturaCliente",
    icon: "fas fa-user-friends",
    component: FacturaCliente,
    layout: "/admin",
  },
  {
    visible: false,
    path: "/google",
    name: "GoogleMaps",
    icon: "ni ni-map-big text-primary",
    component: Google,
    layout: "/admin",
  },
  {
    visible: false,
    path: "/home",
    name: "Home",
    icon: "fas fa-home",
    component: Home,
    layout: "/admin",
  },
  {
    visible: false,
    path: "/obraParticular/:idObra",
    name: "Obra Particular",
    icon: "fas fa-user-clock",
    component: ObraParticular,
    layout: "/admin",
  },
  {
    visible: false,
    path: "/papelesVehiculo/:idVehiculo",
    name: "PapelesVehiculo",
    icon: "fas fa-truck",
    component: PapelesVehiculo,
    layout: "/admin",
  },
  {
    visible: false,
    path: "/perfilClientes/:id",
    name: "PerfilClientes",
    icon: "fas fa-user-friends",
    component: PerfilClientes,
    layout: "/admin",
  },
  {
    visible: false,
    path: "/perfilEmpleados/:idEmpleado",
    name: "PerfilEmpleados",
    icon: "fas fa-user-friends",
    component: PerfilEmpleados,
    layout: "/admin",
  },
  {
    visible: false,
    path: "/permisosrol/:idRol",
    name: "PermisosRol",
    icon: "fas fa-user-friends",
    component: PermisosRol,
    layout: "/admin",
  },
  {
    visible: false,
    path: "/serviciosCliente/:idClient/:idServicio",
    name: "VistaServicios",
    icon: "fas fa-user-friends",
    component: VistaServicios,
    layout: "/admin",
  },
  {
    visible: false,
    path: "/stock/:idAlmacen",
    name: "Stock",
    icon: "ni ni-archive-2 text-green",
    component: Stock,
    layout: "/admin",
  },
  {
    visible: false,
    path: "/stockEmpleado/:idMaterial",
    name: "StockEmpleado",
    icon: "ni ni-archive-2 text-green",
    component: StockEmpleado,
    layout: "/admin",
  },
  {
    visible: false,
    path: "/vehiculoParticular/:idVehiculo",
    name: "Vehiculo Particular",
    icon: "fas fa-truck",
    component: VehiculoParticular,
    layout: "/admin",
  },

  //Para agregar una nueva ruta en la navbar usar el menu service desde la api2




  // #################################### Public Routes #####################################

  {
    visible: false,
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
  },
];

export default routes;
