import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react';
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, 
  InputGroupAddon, InputGroupText, Modal } from 'reactstrap';
import { postRoles } from 'services/RolesService';


const ModalAddRol = (props) => {

    const {ModalAgregarRol, setModalAgregarRol, traerRoles}=props;
    const [alertSweet, setAlert] = useState(null);
    const [nombre, setNombre] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);

    const cambiarNombre = event => {
        setNombre(event.target.value);
    };
    const cambiarIsAdmin = event => {
      setIsAdmin(event.target.value);
    };

    const AgregarRoles = async()=>{
    
        let rol = {
          nombre,
          isAdmin
        }
      let result = await postRoles(rol)
      if(result != null){
        setNombre("")
        setIsAdmin(false)
        setModalAgregarRol(false)
        successAlert(setAlert, "Rol agregado correctamente!", "")
        traerRoles()
    }else{
      warningAlert(setAlert, 'No se pudo agregar Rol')
    }
    }
  return(
      <>
      {alertSweet}
      <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={ModalAgregarRol}
                            toggle={() => setModalAgregarRol(false)}
                          >
                          <div className="modal-body p-0">
                            <Card className="bg-secondary shadow border-0">
                              <CardHeader className="bg-transparent pb-5">
                                <div className="text-center"><b>Agregar Rol</b>
                                <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => setModalAgregarRol(false)}
                                >
                                  <span aria-hidden={true}>×</span>
                                </button>
                                </div>
                                <div className="text-muted mt-2 mb-3">
                                <Form role="form">
                                  <FormGroup className="mb-3">Nombre:
                                    <InputGroup className="input-group-alternative mt-2">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                        <i className="fas fa-user"></i>
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input placeholder="Nombre" type="text" value={nombre} onChange={cambiarNombre}/>
                                    </InputGroup>
                                  </FormGroup>
                                  <FormGroup>
                                        <h4 className=" ls-1 text-primary ">
                                          Es Admin:
                                        </h4>
                                        <Input id="exampleFormControlSelect1" type="select" 
                                            value={isAdmin}
                                            onChange={cambiarIsAdmin}
                                        >
                                            <option value={false}>No</option>
                                            <option value={true}>Si</option>
                                        </Input>
                                </FormGroup>
                                  <div className="text-center">
                                    <Button
                                      className="my-4"
                                      color="primary"
                                      type="button"
                                      onClick={()=>AgregarRoles()}
                                    >
                                      Agregar
                                    </Button>
                                
                                    <Button
                                      color="secondary"
                                      data-dismiss="modal"
                                      type="button"
                                      onClick={() => setModalAgregarRol(false)}
                                    >
                                      Cerrar
                                    </Button>                    
                                  </div>
                                </Form>                     
                                </div>                    
                              </CardHeader>                  
                            </Card>
                          </div>
                          </Modal>
      </>
  )
};

export default ModalAddRol;
