import SelectorGenerico from 'pages/components/SelectorGenerico';
import React, { useState } from 'react';
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap';

const ModalAgregarGrafico=(props)=>{
    const {ModalAgregarGrafic, setModalAgregarGrafic, traerGraficos, setAlert}=props;

    const [TipoGraficoId, setTipoGraficoId] = useState("");
    const [Valor, setValor] = useState("");
    const [Unidad, setUnidad] = useState("");
    const [Fecha, setFecha] = useState("");

    const cambiarTipoGraficoId = event => {
        setTipoGraficoId(event.target.value);
      };
      const cambiarValor = event => {
        setValor(event.target.value);
      };;
      const cambiarUnidad = event => {
        setUnidad(event.target.value);
      };;
      const cambiarFecha = event => {
        setFecha(event.target.value);
      };

    return(
        <>
            <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={ModalAgregarGrafic}
                toggle={() => setModalAgregarGrafic(false)}
                >
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent pb-5">
                            <div className="text-center"><b>Agregar Grafico</b>
                                <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => setModalAgregarGrafic(false)}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <div className="text-muted mt-2 mb-3">
                                <Form role="form">
                                    <FormGroup>
                                        <label htmlFor="exampleFormControlSelect1">Tipo De Sueldo:</label>                                       
                                        <SelectorGenerico/>
                                    </FormGroup>
                                    <FormGroup value={TipoGraficoId} onChange={cambiarTipoGraficoId}> TipoGraficoId:
                                        <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                            
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input placeholder="TipoGraficoId..." type="text" />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup value={Valor} onChange={cambiarValor}> Valor:
                                        <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                            
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input placeholder="Valor..." type="number" />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup value={Unidad} onChange={cambiarUnidad}> Unidad:
                                        <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                            
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input placeholder="Valor..." type="text" />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <label
                                        className="mb-3"
                                        htmlFor="example-date-input"
                                        >
                                        Fecha:
                                        </label>
                                        <Input
                                        defaultValue={new Date().getFullYear() + "-11-23"}
                                        id="example-date-input"
                                        type="date"
                                        value={Fecha} onChange={cambiarFecha}
                                        />
                                    </FormGroup>
                                    <div className="text-center">
                                        <Button
                                        className="my-4"
                                        color="primary"
                                        type="button"
                                        onClick={()=>{}}
                                        >
                                        Agregar
                                        </Button>
                                    
                                        <Button
                                        color="secondary"
                                        data-dismiss="modal"
                                        type="button"
                                        onClick={() => setModalAgregarGrafic(false)}
                                        >
                                        Cerrar
                                        </Button>                    
                                    </div>
                                </Form>                     
                            </div>                    
                        </CardHeader>                  
                    </Card>
                </div>
            </Modal>
        </>
    )
}
export default ModalAgregarGrafico