import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';

const ReportComponent=(props)=>{
        return(
        <>
            <Card style={{width:"100%"}}>
        <CardBody >
            <Table className="align-items-center" responsive>
            <thead className="thead-light text-center">
                <tr>
                    <th scope="col">FECHA</th>
                    <th scope="col">PUNTO VENTA</th>
                    <th scope="col">CIERRE ID</th>
                    <th scope="col">IMPORTE</th>
                    <th scope="col">EGRESOS</th>
                </tr>
            </thead>
            <tbody>
                <tr >
                <th scope="row">
                <div className="text-center">
                      <span className="mb-0 text-sm">
                     111
                      </span>
                </div>
                </th>
                <td>
                <div className="text-center">
                      <span className="mb-0 text-sm">
                     111
                      </span>
                </div>
                </td>
                <td>
                <div className="text-center">
                      <span className="mb-0 text-sm">
                   11111
                      </span>
                </div>
                </td>
                <td>
                <div className="text-center">
                      <span className="mb-0 text-sm">
                     1111
                      </span>
                </div>
                </td>
                <td>
                <div className="text-center">
                    111111
                </div>
                </td>
                </tr>
            </tbody>
            </Table>

        </CardBody>
    </Card>
        </>)
   }
export default ReportComponent