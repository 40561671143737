import React, { useState } from 'react'
import { Button } from 'reactstrap'


function EtiquetaAsignados(props){
    const {Asignado} = props

    return(
        <>
        {Asignado?
            <Button className="btn-icon btn-3 btn-sm btn-outline-success  "  type="button" >
                <span className="btn-inner--icon">    
                SI
                </span>
                
            </Button>
        :
            <Button  className="btn-icon btn-3 btn-sm btn-outline-danger "  type="button">
                <span className="btn-inner--icon">    
                NO
                </span>
                
            </Button>
    }
        </>
    )
}

export default EtiquetaAsignados;