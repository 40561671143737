import React, { useState } from 'react'
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';


export default function BuscadorDatosClientes(props) {
    const { traerObjetos, setObjetos, getObjetos } = props
    const [query, setQuery] = useState("");

    const cambiarQuery = event => {
        setQuery(event.target.value);
    };

    const Buscar = async () => {
        let params = { dni: parseInt(query) }
        let result = await getObjetos(params)
        if (result) {
            setObjetos(result.itemCliente);
        } else { alert("no se pudo buscar"); traerObjetos() }
    }

    const atras = () => {
        traerObjetos()
        setQuery("");
    }

    const keyDownFunction = (event) => {
        if (event.keyCode === 13) {
            Buscar();
        }
    }


    return (
        <InputGroup>
            <Input placeholder="Buscar" type="text"
                value={query}
                onChange={cambiarQuery}
                onKeyDown={(e) => keyDownFunction(e)}
            />
            <InputGroupAddon addonType="append">
                <Button className="ml-3" color="success" outline type="button" onClick={() => Buscar()}>
                    Buscar
                </Button>
                <Button className="ml-3" color="success" outline type="button" onClick={() => atras()}>
                    Atras
                </Button>
            </InputGroupAddon>
        </InputGroup>
    )
}