import jwt_decode from "jwt-decode";
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Card, Table } from 'reactstrap';
import { putEditarEspera } from 'services/EsperaServices';
import { getPuntosVentas } from "../../../services/PuntosVentaService";
import ModalSacarEsperas from "./components/ModalSacarEsperas";


const Movimientos = () => {
    const [puntosDeVenta, setPuntosDeVenta] = useState([]);
    const history = useHistory();
    const [PuntoVentaId, setPuntoVentaId] = useState(null);
    const [Admin, setAdmin] = useState(false);
    const [ModalSacarEspera, setModalSacarEspera] = useState(false)
    const [User, setUser] = useState(null)

    const irMovimientosPV = (id) => {
        history.push(`/admin/controlMovimientos/${id}`)
    }
    const guardarId = () => {
        let token = localStorage.getItem('token');
        let decod = jwt_decode(token)
        setPuntoVentaId(decod.usuario.puntoVentaId)
        setAdmin(decod.usuario.admin)
        setUser(decod.usuario.usuario)
        return decod
    }


    useEffect(() => {
        getPuntosVentas().then(data => setPuntosDeVenta(data));
        guardarId()
    }, [])

   

    return (
        <>

            <Card>

                <Table className="align-items-center" responsive>
                    <thead className="thead-light text-center">
                        <tr>

                            <th scope="col">ID</th>
                            <th scope="col">NOMBRE</th>
                            <th scope="col">DIRECIÓN</th>
                            <th scope="col">VER</th>

                        </tr>
                    </thead>
                    <tbody>
                        {puntosDeVenta && puntosDeVenta.length !== 0 ?
                            puntosDeVenta.map(pv => {
                                if (pv.id === PuntoVentaId || Admin) {
                                    return (
                                        <tr key={pv.id}>
                                            <td>
                                                <div className="text-center">
                                                    <span className="mb-0 text-sm">
                                                        <b>{pv.id}</b>
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="text-center">
                                                    <span className="mb-0 text-sm">
                                                        {pv.nombre}
                                                    </span>
                                                </div>
                                            </td>

                                            <td>
                                                <div className="text-center">
                                                    <span className="mb-0 text-sm">
                                                        {pv.direccion}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className=" text-center" >
                                                    <Button className="btn-md btn-outline-info" onClick={() => { irMovimientosPV(pv.id) }}>
                                                        Ver Movimientos
                                                    </Button>
                                                </div>
                                            </td>
                                            {User === 'manuela' || User === 'alex' ?
                                            <td>
                                                <div className=" text-center" >
                                                    <Button className="btn-md btn-outline-info" onClick={() =>( setModalSacarEspera(true), guardarId())}>
                                                        sacar instalaciones
                                                    </Button>
                                                </div>
                                            </td>
                                            :
                                            <></>}


                                        </tr>

                                    )
                                } else {
                                    return (
                                        <></>
                                    )
                                }

                            })
                            :
                            <tr>
                                <td>
                                    <p>Cargando...</p>
                                </td>
                            </tr>
                        }
                    </tbody>
                </Table>



            </Card>
            <ModalSacarEsperas ModalSacarEspera={ModalSacarEspera} setModalSacarEspera={setModalSacarEspera} User={User}/>




        </>
    )
}

export default Movimientos