import React from 'react'
import { Badge, Button } from 'reactstrap'


function EtiquetaMetodo(props){
    const {Estado} = props
    if(Estado == "6efe0fa8-36b2-4dd1-b049-427bffc7d369"){
        return(
            <>
            Efectivo
            </>
        )
    }else if(Estado == "ed676524-e5bd-410e-ab3c-c84446cce4bc"){
        return(
            <>
            Pagos360
            </>
        )
    }else if(Estado == "f11a289b-10cf-451e-a060-df5c06cf246e"){
        return(
            <>
            Decidir
            </>
        )
    }else{
        return <></>
    }
}

export default EtiquetaMetodo;