import React, { useEffect, useState } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Container,
    Navbar
  } from "reactstrap";
import { useHistory } from "react-router";
import { getCasos } from "services/CasosServices.js";
import { getCategoriasCasos } from "services/CasosServices.js";
import SelectorGenerico from 'pages/components/SelectorGenerico';
import { GuardInPages } from 'guards/Guard';
import LoadingComponent from 'pages/components/Loading';
import ModalAgregarCa from './components/ModalAgregarCaso';
import TableCasos from './components/TableCasos';
import SelectorEstadoCaso from './components/SelectorEstadoCaso';
  
 function CasosComponent() {
    const history = useHistory();
    const [Casos, SetCasos] = useState([]); 
    const [Cantidad, SetCantidad] = useState([]); 
    const [CategoriasCasos, SetCategoriasCasos] = useState([]); 
    const [Valor, setValor] = useState("");
    const [valorCatCaso, setSelectorCatCaso] = useState("");
    const [ModalAgregarCasos, setModalAgregarCasos] = useState(false);
    const [Visible, setVisible] = useState(false);
    
    const filtrarCasosEstado = async(est) => {
      setVisible(true);
      let params = {estado:est, page:1}
      let cass = await getCasos(params);
      if (cass !== null && cass !== '') {
        SetCasos(cass);
      }
      setVisible(false)
    }
    const filtrar = async() => {
      setVisible(true);
      let params = {estado: Valor, categoriaId: valorCatCaso, page: 1}
      let filtro =  await getCasos(params);
      if (filtro !== null && filtro !== '') {
        SetCasos(filtro);
      }
      setVisible(false)
    }
    
    const traerCantidad = async() => {
      let params = {count:"si"}
      let can = await getCasos(params);
      if (can !== null && can !== '') {
        SetCantidad(can);
      }
    }
    const traerCategoriasCasos = async() => {
      let can = await getCategoriasCasos();
      if (can !== null && can !== '') {
        SetCategoriasCasos(can);
      }
    }
    useEffect(() => {
      GuardInPages(history)
      filtrarCasosEstado(1);
      traerCantidad();
      traerCategoriasCasos();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <>
        <LoadingComponent Visible={Visible}/>
        <Container style={{padding:"0%"}}>
        {Cantidad?  
        <Row>
          <Col>
            <Card className="card-stats mt-4 " style={{height:"7rem", width:"100%"}}>

              <CardBody >
                <Row >
                  <div className="col">
                    <CardTitle className="text-uppercase text-muted mb-0" onClick={()=>filtrarCasosEstado(1)}>
                      CASOS<br/>
                      NO RESUELTOS
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">{Cantidad.noresueltos}</span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-red text-white rounded-circle shadow">
                    <i className="fas fa-user-times"></i>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card className="card-stats mt-4 " style={{height:"7rem", width:"100%"}} >

              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle className="text-uppercase text-muted mb-0" onClick={()=>filtrarCasosEstado(2)}>
                      CASOS <br/>
                      RESUELTOS
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">{Cantidad.resueltos}</span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                    <i className="fas fa-user-check"></i>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
            :
            <p>Cargando..</p>
              }
       <Card>
        <Navbar
          className="navbar-horizontal navbar-light "
          expand="lg"
        >
          <Container>
        <Row className='mt-4'>
            
                <label className='mt-2 mr-2'>Area:</label>

                {CategoriasCasos?
                <SelectorGenerico 
                Valor={valorCatCaso} setValor={setSelectorCatCaso} arrayElementos={CategoriasCasos}/>
                :<></>}

                <label className='mt-2 mr-2 ml-2'>Estado:</label>
                <SelectorEstadoCaso Valor={Valor} setValor={setValor}/>
                <div>

                <Button className='btn-outline-info btn-md px-3 ml-4' onClick={()=>filtrar()}>Filtrar</Button>
                </div>
        </Row>
                      <div>
                          <Button className=" btn-md btn-outline-success " onClick={()=>setModalAgregarCasos(true)}>
                              Agregar Caso
                          </Button>
                      </div>
          </Container>
        </Navbar>
        
        <TableCasos
        Casos={Casos}
        filtrarCasosEstado={filtrarCasosEstado}
        SetCasos={SetCasos}
        getCasos={getCasos}
        />

        </Card>
        </Container>
        <ModalAgregarCa
        setModalAgregarCasos={setModalAgregarCasos}
        ModalAgregarCasos={ModalAgregarCasos}
        filtrarCasosEstado={filtrarCasosEstado}
        />
      </>
    )
    }

export default CasosComponent;

