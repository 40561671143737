import React, { useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap'
import {warningAlert, successAlert, confirmAlert} from "../../../components/SweetAlert"
import LoadingComponent from 'pages/components/Loading'
import { postPagos } from 'services/ClientesServices'
import { postObservaciones } from 'services/ObservacionesService'


export default function ModalAObservacion(props){
    const {
        ModalAgregarObservacion, setModalAgregarObservacion, idClient, traerObservacionesCliente
      } = props
      
      const [Alert, setAlert] = useState(null);
      const [Visible, setVisible] = useState(false);
      const [Observacion, setObservacion] = useState("");
     
      const cambiarObservacion = (e) => {
        setObservacion(e.target.value)
      }
      const confirmar = ()=>{
        confirmAlert(setAlert, AgregarObservacion, null, "Seguro que desea agregar una Observacion?")
      }
      const AgregarObservacion= async()=> {
        setVisible(true)
        let observacion = {
          clienteId: idClient,
          nota: Observacion
        }
        let resp = await postObservaciones(observacion);
        if(resp){
        setObservacion("");
        successAlert(setAlert,"Observacion agregada correctamente");
        setModalAgregarObservacion(false);
        traerObservacionesCliente()
        
        }
        setVisible(false)
  
      }
      
    

    return(
      <>
        {Alert}
        <LoadingComponent Visible={Visible}/>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={ModalAgregarObservacion}
          toggle={() => setModalAgregarObservacion(false)}
        >
        <div className="modal-body p-0">
          <Card className=" shadow border-0">
            <CardHeader className=" pb-5">
              <div className="text-center"><b>Agregar Observación</b>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalAgregarObservacion(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
              </div>
              </CardHeader> 
              <CardBody>
              <div className=" mt-2 mb-3">
              <Form role="form">
              
              <FormGroup className="mb-3 text-area">Observación:
                  
                    <Input 
                    placeholder="Observacion..." 
                    type="textarea" 
                    onChange={cambiarObservacion} 
                    value={Observacion}
                    className=" form-control-alternative textarea-autosize"
                     />
                </FormGroup> 
              </Form>                     
              </div>  
              </CardBody>
              <CardFooter>
                  <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={()=>confirmar()}
                  >
                    Agregar
                  </Button>
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalAgregarObservacion(false)}
                  >
                    Cerrar
                  </Button>                    
                </div>

              </CardFooter>
                                
                            
          </Card>
        </div>
        </Modal>
      </>
    )
}