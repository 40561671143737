import React, { useState } from 'react'
import {Button, ButtonGroup, Table} from 'reactstrap'
import moment from 'moment';
import EtiquetaPrioridad from './EtiquetaPrioridad';
import EtiquetaEstado from './EtiquetaEstado';
import ModalCambiarCo from './ModalCambiarColor';
import { useHistory } from 'react-router';
import Paginacion from 'pages/components/Paginacion';

const TableCasos=(props)=>{

    const {Casos, filtrarCasosEstado, getCasos, SetCasos }=props;
    const [Caso, setCaso] = useState({});
    const [ModalCambiarColor, setModalCambiarColor] = useState(false);
    const history = useHistory();

    const obtenerFormatoFecha = (creado)=>{
        let fecha = new Date(creado);
        let dia = fecha.getDate();
        let mes = fecha.getMonth();
        let ano = fecha.getFullYear();
        let fechaFormateada = `${dia}/${mes+1}/${ano}`;
        return fechaFormateada;
    }
  
    const obtenerFormatoHora = (creado)=>{
      let fecha = new Date(creado);
      
      let horaFormateada = moment(fecha).fromNow();
      return horaFormateada;
  }
  const irCaso = (idCaso)=>{
    history.replace(`/admin/casoParticular/${idCaso}`)

  }

    return (
        <>
        <Table className="align-items-center" responsive>
          <thead className="thead-light text-center">
            <tr>
              <th scope="col">COLOR PERSONAL</th>
              <th scope="col">CLIENTE ID</th>
              <th scope="col">AREA</th>
              <th scope="col">PRIORIDAD</th>
              <th scope="col">ESTADO</th>
              <th scope="col">FECHA CREACION</th>
              <th scope="col">TIEMPO CREACION</th>
              <th scope="col"></th>
              <th scope="col" />
              
            </tr>
          </thead>
          <tbody>
          {Casos?                      
            Casos.map((caso, index) => {
              return (
                  <tr key={index}>
                    <th scope="row">
                      <div className=" text-center">
                      <ButtonGroup
                      className="btn-group-toggle btn-group-colors event-tag "
                      data-toggle="buttons"
                    >
                      <Button
                        className={caso.color}
                        color=""
                        type="button"
                        onClick={()=>{setModalCambiarColor(true);setCaso(caso)}}
                        
                      />
                    </ButtonGroup>
                      </div>
                       
                    </th>
                    <td >
                    <div className=" text-center">
                    <span className="mb-0 text-sm">
                            {caso.clienteId}
                    </span>
                    </div>
                          
                    </td>
                    <td>
                    <div className=" text-center">
                    <span className="mb-0 text-sm">
                            {caso.categoriasCaso?
                            caso.categoriasCaso.nombre
                      :
                      <>-</>
                      }
                    </span>
                    </div>
                          
                    </td>
                    <td>
                    <div className=" text-center">
                    <EtiquetaPrioridad Prioridad={caso.prioridad}/>
                    </div>
                        
                    </td>
                    <td>
                    <div className=" text-center">
                    {caso.estado?
                      <EtiquetaEstado Estado={caso.estado}/>
                      :
                      <p>Sin estado</p>
                      }
                    </div>
                      
                    </td>
                    <td>
                    <div className=" text-center">
                    <span className="mb-0 text-sm">
                          {obtenerFormatoFecha(caso.creado)}
                    </span>
                    </div>
                          
                    </td>
                    <td>
                    <div className=" text-center">
                    <span className="mb-0 text-sm">
                          {obtenerFormatoHora(caso.creado)}
                    </span>
                    </div>
                          
                    </td>
                    <td>
                      <div className="d-flex align-items-center text-center ">
                        <div className=" text-center">
                          <Button className="btn-icon btn-3 btn-md btn-outline-success "  type="button" onClick={()=>irCaso(caso.id)} >
                          
                              <span className="btn-inner--icon">Ver    
                              <i className="far fa-eye"></i>
                              </span>
                              
                          </Button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  
              )
                })
              
            :
            <p>Cargando..</p>
              }
              </tbody>
        </Table>
        <Paginacion traerObjetos={filtrarCasosEstado} setObjetos={SetCasos} getObjetos={getCasos}/>
        <ModalCambiarCo
        setModalCambiarColor={setModalCambiarColor}
        ModalCambiarColor={ModalCambiarColor}
        filtrarCasosEstado={filtrarCasosEstado}
        Caso={Caso}
        />
        </>
    )

}
export default TableCasos