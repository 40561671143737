import React from 'react'
import { Table } from 'reactstrap'

export const TableDatosClientes = ({ Clientes }) => {

    return (
        <Table responsive className='text-center table table-hover '>
            <thead className='table-info'>
                <tr>
                    <th>ID</th>
                    <th>DNI</th>
                    <th>NOMBRE</th>
                </tr>
            </thead>
            <tbody>
                {Clientes && Array.isArray(Clientes) ?
                    Clientes.map((cliente) => {
                        return (
                            <tr key={cliente.id}>
                                <th>{cliente.id}</th>
                                <th>{cliente.userIdent}</th>
                                <td>{cliente.name}</td>
                            </tr>
                        )
                    })
                    :
                    <>
                        {Clientes ?
                            <tr key={Clientes.id}>
                                <th>{Clientes.id}</th>
                                <th>{Clientes.userIdent}</th>
                                <td>{Clientes.name}</td>
                            </tr>

                            :
                            <>
                            <td>Cliente no existente!</td>
                            </>
                        }
                    </>
                }
            </tbody>
        </Table>
    )
}
