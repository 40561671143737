import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, Col, Form, FormGroup, Input, InputGroup, Modal, Row } from 'reactstrap'
import {warningAlert, successAlert} from "../../../components/SweetAlert"
import LoadingComponent from 'pages/components/Loading'
import { postCategorias } from 'services/CategoriasService'
import { confirmAlert } from 'pages/components/SweetAlert';

export default function ModalAgregarCategoriaEquipos(props){
    const {
        VerModalAgregarCategoriaEquipos, setVerModalAgregarCategoriaEquipos, traerCategoriasEquipos
      } = props

        const [CategoriaEquipo, setCategoriaEquipo] = useState("");
        const [PuntoReposicion, setPuntoReposicion] = useState(0);
        const [FormatoEscaneo, setFormatoEscaneo] = useState("");
        const [Precio, setPrecio] = useState(0);
        const [PagoEnCuotas, setPagoEnCuotas] = useState(false);
        const [CantidadCuotas, setCantidadCuotas] = useState(0);
        const [TieneMac, setTieneMac] = useState(false);
        const [alertSweet, setAlert] = useState(null);
        const [Visible, setVisible] = useState(false);

        const cambiarCategoriaEquipo = event => {
          setCategoriaEquipo(event.target.value);
        };

        const cambiarPuntoReposicion = event => {
          setPuntoReposicion(event.target.value);
        };

        const cambiarFormatoEscaneo = event => {
          setFormatoEscaneo(event.target.value);
        };

        const cambiarPrecio = event => {
          setPrecio(event.target.value);
        };

        const cambiarPagoEnCuotas = (estadoActual) => {
          setPagoEnCuotas(!estadoActual);
        };

        const cambiarCantidadCuotas = event => {
          setCantidadCuotas(event.target.value);
        };

        const cambiarTieneMac=(estadoActual) => {
          setTieneMac(!estadoActual);
        };

        const reset = () => {
          setCategoriaEquipo("")
          setPuntoReposicion(0)
          setFormatoEscaneo("")
          setPrecio(0)
          setPagoEnCuotas(false)
          setCantidadCuotas(0)
          setTieneMac(false)
        }

        const confirmarAgregar= async(id)=>{
          confirmAlert(setAlert, agregarCategoriaEquipo, null , "Seguro que deseas agregar esta Categoria?")
        }

        const validarNuevaCategoria = (catEquip) => {
          // if(catEquip.nombre === ''){
          //   return {result:false,mensaje:'El nombre de la categoria no puede estar vacio'}
          // }
          // if(catEquip.puntoReposicion === 0){
          //   return {result:false,mensaje:'El punto de reposicion no puede ser 0'}
          // }
          // if(catEquip.formatoEscaneo === ''){
          //   return {result:false,mensaje:'El formato de escaneo no puede estar vacio'}
          // }
          // if(catEquip.precio === 0){
          //   return {result:false,mensaje:'El precio no puede ser 0'}
          // }
          // if(catEquip.pagoEnCuotas === true && catEquip.cantidadCuotas === 0){
          //   return {result:false,mensaje:'Si el pago es en cuotas, la cantidad de cuotas no puede ser 0'}
          // }
          return {result:true,mensaje:"Todo OK"}
        }

        const agregarCategoriaEquipo = async() => {
          try{
            setVisible(true)
            let catEquip = {
              nombre: CategoriaEquipo,
              puntoReposicion: PuntoReposicion,
              formatoEscaneo: FormatoEscaneo,
              precio: Precio,
              pagoEnCuotas: PagoEnCuotas,
              cantidadCuotas: CantidadCuotas,
              tieneMac: TieneMac
          }
          let validacion = validarNuevaCategoria(catEquip)
          if(validacion.result !== true){
            warningAlert(setAlert,validacion.mensaje)
            return;
          }
          let respuesta = await postCategorias(localStorage.getItem('token'), catEquip)
          if(respuesta != ''){
            successAlert(setAlert,'Categoria Equipo agregada correctamente')
          }else{
            warningAlert(setAlert,'Error al agregar Categoria Equipo')
          }
          }
          catch(error){
            warningAlert(setAlert,error.message)
          }
          finally{
            setVisible(false)
            reset()
            traerCategoriasEquipos();
            setVerModalAgregarCategoriaEquipos(false)
          }
        }

        useEffect(() => {
        },[])

        

    return(
      <>
        {alertSweet}
        <LoadingComponent Visible={Visible}/>
        <Modal
          className="modal-dialog-centered"
          size="md"
          isOpen={VerModalAgregarCategoriaEquipos}
          toggle={() => setVerModalAgregarCategoriaEquipos(false)}
        >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-center"><b>Agregar Categoria Equipo</b>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setVerModalAgregarCategoriaEquipos(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
              </div>
              <div className="text-muted mt-2 mb-3">
              <Form role="form">
                <FormGroup className="mb-3">Nombre Categoria:
                  <InputGroup className="input-group-alternative">
                    <Input placeholder="Nombre.." type="text"  onChange={cambiarCategoriaEquipo} value={CategoriaEquipo}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">Cantidad Minima en Stock:
                  <InputGroup className="input-group-alternative">
                    <Input placeholder="Punto Reposicion.." type="number"  onChange={cambiarPuntoReposicion} value={PuntoReposicion}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">Formato Escaneo:
                  <InputGroup className="input-group-alternative">
                    <Input placeholder="Formato.." type="text"  onChange={cambiarFormatoEscaneo} value={FormatoEscaneo}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">Precio:
                  <InputGroup className="input-group-alternative">
                    <Input placeholder="Precio.." type="number"  onChange={cambiarPrecio} value={Precio}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="customCheck1"
                        type="checkbox"
                        isChecked={PagoEnCuotas}
                        onChange={()=>cambiarPagoEnCuotas(PagoEnCuotas)}
                      />
                      <label className="custom-control-label" htmlFor="customCheck1">
                      Pago en Cuotas
                      </label>
                    </div>
                </FormGroup>
                <FormGroup className="mb-3">Cantidad Cuotas:
                  <InputGroup className="input-group-alternative">
                    <Input placeholder="Cantidad Cuotas.." type="number"  onChange={cambiarCantidadCuotas} value={CantidadCuotas}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="customCheck2"
                        type="checkbox"
                        isChecked={TieneMac}
                        onChange={()=>cambiarTieneMac(TieneMac)}
                      />
                      <label className="custom-control-label" htmlFor="customCheck2">
                      Tiene Mac
                      </label>
                    </div>
                </FormGroup>
                  <Row>
                      <Col md={4}>
                      </Col>
                      <Col md={4}>
                      <Row>
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={()=>confirmarAgregar()}
                        >
                          Agregar Categoria
                        </Button>
                      </Row>
                      </Col>
                      <Col md={4}></Col>
                  </Row>
              </Form>                     
              </div>                    
            </CardHeader>                  
          </Card>
        </div>
        </Modal>
      </>
    )
}