import React from 'react'
import { Input, InputGroup,  } from 'reactstrap';

const BuscadorSideBar = ({traerMenu, setMenu, Menu, Show}) => {
    
    const Buscar = (e)=>{
        let query = e.target.value
        let busqueda =   Menu.filter(m=>m.name.toLowerCase().includes(query.toLowerCase()))
        if (busqueda) {
            setMenu(busqueda)
        }
        if (!query) {
            traerMenu()
        }
    }
  return (
    <>
                <InputGroup>
                    <Input placeholder="Buscar" type="text" 
                        onChange={Buscar}
                        className="m-2"
                    />
                </InputGroup>
    </>
  )
}

export default BuscadorSideBar