import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { getTotalContadores } from 'services/ContadoresService'
import TableControlEfectivo from './components/TableControlEfectivo'

const ControlMovimientos = props => {
  const idPuntoVenta = props.match.params.idPuntoVenta
  const [Total, setTotal] = useState([])

  const traerTotal = async() => {
    let total = await getTotalContadores({actual : true, tipoId : 1})
    if (total !== null & total !== '') {
        setTotal(total.contador)
    }

}
  useEffect(() => {
    traerTotal()
  }, [])
  
  return (
    <>
    <Card>
        <CardHeader>
        <h1>Control de Movimientos</h1> <br/>
        <h1>Total: ${Total?.cantidad}</h1>
        </CardHeader>
        <CardBody>
            <TableControlEfectivo idPuntoVenta={idPuntoVenta}/>
        </CardBody>
    </Card>
    </>
  )
}

export default ControlMovimientos