import Buscador from 'pages/components/Buscador';
import LoadingComponent from 'pages/components/Loading';
import React, { useEffect, useState } from 'react'
import {
    Button,Card,CardBody,CardTitle,Row,Col,Container,NavbarBrand,Navbar} from "reactstrap";
import { getNoAsignados } from 'services/CambiosServices';
import { getCambiosEq } from 'services/CambiosServices';
import ModalBuscar from './components/ModalBuscar';
import ModalEditar from './components/ModalEditar';
import TableCambios from './components/TableCambios';


const CambioEquipos = () => {

    const [CambiosEquipos, setCambiosEquipos] = useState([]); 
    const [NoAsignados, SetNoAsignados] = useState([]);
    const [ModalEditarE, setModalEditarE] = useState(false);
    const [ModalBuscarIP, setModalBuscarIP] = useState(false);
    const [Visible, setVisible] = useState(false);

    const traerCambiosEq = async() => {
        setVisible(true)
        let params = {paginate:"si"}
        let camb = await getCambiosEq(params);
        if (camb !== null && camb !== '') {
          setCambiosEquipos(camb);
        }
        setVisible(false)
    }
    const traerNoAsignados = async() => {
      
      let NoA = await getNoAsignados();
      if (NoA !== null && NoA !== '') {
        SetNoAsignados(NoA);
      }
    }
    

    useEffect(() => {
        traerNoAsignados();
        traerCambiosEq();
        
      }, [])
    return (
     <>
     <LoadingComponent Visible={Visible}/>
     {NoAsignados?  
     <Row>
       <Col>
        <Card className="card-stats mt-4 ml-3" style={{height:"7rem", width:"100%"}}>

          <CardBody >
            <Row >
              <div className="col">
                <CardTitle className="text-uppercase text-muted mb-0">
                  EQUIPOS<br/>
                  NO ASIGNADOS
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">{NoAsignados.length}</span>
                    
                    
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape bg-red text-white rounded-circle shadow">
                <i className="fas fa-user-times"></i>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
       </Col>
       <Col>
       </Col>
       <Col>
       </Col>
            
      </Row>
      :
      <p>Cargando..</p>
        }
      <Card style={{width:"100%"}}>
      <Navbar
          className="navbar-horizontal navbar-light "
          expand="lg"
      >
          <Container>
            <NavbarBrand>
              <Buscador />
            </NavbarBrand>
              <Button className="btn-md btn-outline-success" onClick={()=>setModalBuscarIP(true)}>
                Buscar IP
              </Button>
          </Container>
      </Navbar>
      <TableCambios CambiosEquipos={CambiosEquipos}
      setModalEditarE={setModalEditarE} 
      traerCambiosEq={traerCambiosEq}
      />
      </Card>
      <ModalEditar 
      ModalEditarE={ModalEditarE} 
      setModalEditarE={setModalEditarE}
      />
      <ModalBuscar 
      ModalBuscarIP={ModalBuscarIP} 
      setModalBuscarIP={setModalBuscarIP}
      />
      
    </>
    )
}

export default CambioEquipos;