import { urlGlobal2 } from './GlobalService';


export async function postControles(token, control){
    let estado = false;
    try{
      let body = JSON.stringify(control);
      let headers = {'Content-Type':'application/json', 'token':token};
      let url = `${urlGlobal2}/controles`
      let method = 'POST';
      const resp = await fetch(url,{headers,body,method});
      const data = await resp.json();
      estado = data.ok;
    }
    catch(error) {
      alert(error)
    }
    return estado;
}