import React, { useEffect, useState } from 'react';
import { Button, Table } from 'reactstrap';
import { getUsuarios, deleteUsuario } from 'services/UsuariosService';
import ModalAggUsuario from './ModalAggUsuario';
import ModalEditUsuario from './ModalEditUsuario';
import { successAlert, warningAlert } from 'pages/components/SweetAlert';

const TableUsuarios = (props) => {
    const { ModalAgregarUsuario, setModalAgregarUsuario } = props;
    const [Usuarios, setUsuarios] = useState([]);
    const [ModalEditarUsuario, setModalEditarUsuario] = useState(false);
    const [UsSeleccionado, setUsSeleccionado] = useState(null);

    const traerUsuarios = async () => {
        let usuarios = await getUsuarios();
        if (usuarios != null && usuarios !== "") {
            setUsuarios(usuarios);
        }
    };

    const editarUsuario = (us) => {
        setUsSeleccionado(us);
        setModalEditarUsuario(true);
    };

    const eliminarUsuario = async (id) => {
        const result = await deleteUsuario(id);
        if (result) {
            successAlert('Usuario eliminado con éxito');
            traerUsuarios();
        } else {
            warningAlert('Error al eliminar el usuario');
        }
    };

    useEffect(() => {
        traerUsuarios();
    }, []);

    return (
        <>
            <Table className="align-items-center" responsive>
                <thead className="thead-light text-center">
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">NOMBRE DE USUARIO</th>
                        <th scope="col">NOMBRE DE EMPLEADO</th>
                        <th scope="col">APELLIDO</th>
                        <th scope="col">ROL</th>
                        <th scope="col">Punto De Venta ID</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {Usuarios ?
                        Usuarios.map((us, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <div className="text-center">
                                            <span className="mb-0 text-sm">
                                                <b> {us.id}</b>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <span className="mb-0 text-sm">
                                                {us.usuario}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <span className="mb-0 text-sm">
                                                {us.empleado?.nombre}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <span className="mb-0 text-sm">
                                                {us.empleado?.apellido}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <span className="mb-0 text-sm">
                                                {us.role?.nombre}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <span className="mb-0 text-sm">
                                                {us.puntoVentaId}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <Button className="btn-icon btn-3 btn-sm btn-outline-success" type="button" onClick={() => editarUsuario(us)}>
                                                <span className="btn-inner--icon">
                                                    <i className="fas fa-edit" />
                                                </span>
                                            </Button>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <Button className="btn-icon btn-3 btn-sm btn-outline-danger" type="button" onClick={() => eliminarUsuario(us.id)}>
                                                <span className="btn-inner--icon">
                                                    <i className="ni ni-fat-remove" />
                                                </span>
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                        : <>Cargando...</>
                    }
                </tbody>
            </Table>
            <ModalAggUsuario
                ModalAgregarUsuario={ModalAgregarUsuario}
                setModalAgregarUsuario={setModalAgregarUsuario}
                traerUsuarios={traerUsuarios}
            />
            {UsSeleccionado != null ?
                <ModalEditUsuario
                    ModalEditarUsuario={ModalEditarUsuario}
                    setModalEditarUsuario={setModalEditarUsuario}
                    UsuarioSelec={UsSeleccionado}
                    traerUsuarios={traerUsuarios}
                />
                : <></>
            }
        </>
    );
}

export default TableUsuarios;
