import React, { useState } from 'react'
import { Button, Table } from 'reactstrap'
import ModalEditFacturacion from './ModalEditFacturacion'

export const TableProgramacionPagos = ({ ModalEditarFacturacion, setModalEditarFacturacion, traerFacturaciones, Facturaciones }) => {

    const [FacturacionSelect, setFacturacionSelect] = useState({})

   
    const editarFacturacion = (Fact) => {
        setFacturacionSelect(Fact);
        setModalEditarFacturacion(true)
    }



    return (
        <>
            <Table responsive className='text-center'>
                <thead className='bg-info'>
                    <tr>
                        <th>ID</th>
                        <th>NOMBRE</th>
                        <th>DNI</th>
                        <th>CLIENTE ID</th>
                        <th>DIA FACTURACION</th>
                        <th>FACTURA</th>
                        <th>ACCION</th>
                    </tr>
                </thead>
                <tbody>
                    {Facturaciones.length ?
                        Facturaciones.map((f) => {
                            return (

                                <tr key={f._id}>
                                    <td>{f._id}</td>
                                    <td>{f.lastName}, {f.name}</td>
                                    <td>{f.identifier}</td>
                                    <td>{f.clientId}</td>
                                    <td>{f.autoInvoiceDay}</td>
                                    <td>{f.invoiceType}</td>
                                    <td>
                                        <Button className='btn btn-outline-success btn-sm' onClick={() => editarFacturacion(f)}><i className='fas fa-solid fa-list'></i></Button>
                                    </td>
                                </tr>
                            )
                        })
                        :
                        <>
                            <tr>
                                <td>
                                    <h3>Cargando...</h3>
                                </td>
                            </tr>
                        </>
                    }
                </tbody>
            </Table>

            <ModalEditFacturacion
                ModalEditarFacturacion={ModalEditarFacturacion}
                setModalEditarFacturacion={setModalEditarFacturacion}
                FacturacionSelect={FacturacionSelect}
                setFacturacionSelect={setFacturacionSelect}
                traerFacturaciones={traerFacturaciones}
            />
        </>
    )
}
