
import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, Form, FormGroup, Input,  Modal } from 'reactstrap';
import { postMovimiento } from 'services/MovimientosService';
import { successAlert } from 'pages/components/SweetAlert';
import { warningAlert } from 'pages/components/SweetAlert';
import SelectorCategoriaEfectivo from './SelectorCategoriaEfectivo';
import SelectorTipoCategoria from './SelectorTipoCategoria';
import SubirComprobante from 'pages/private/contadores/components/SubirComprobante';
import jwt_decode from "jwt-decode";
import { postComprobante } from 'services/ComprobantesService';

const ModalAggEgresoEfectivo = ({ModalAgregarEgresoEfectivo, setModalAgregarEgresoEfectivo, idPuntoVenta, traerMovimientos}) => {
    const [Operacion, setOperacion] = useState("");
    const [Concepto, setConcepto] = useState("");
    const [ComprobanteId, setComprobanteId] = useState(null)
    const [Importe, setImporte] = useState("")
    const [Alert, setAlert] = useState(null)
    const [Emisor, setEmisor] = useState('')
    const [Receptor, setReceptor] = useState('')
    const [CategoriaEfectivo, setCategoriaEfectivo] = useState("")
    const [Tipo, setTipo] = useState('')
    const [Fecha, setFecha] = useState('')
    const [TkDecod, setTkDecod] = useState(null)

    const cambiarReceptor = event => {
    setReceptor(event.target.value);
    };
    const cambiarEmisor = event => {
    setEmisor(event.target.value);
    };
    const cambiarImporte = event => {
    setImporte(event.target.value);
    };
    const cambiarOperacion = event => {
    setOperacion(event.target.value);
    };
    const cambiarConcepto = event => {
    setConcepto(event.target.value);
    };
    const cambiarFecha = event => {
    setFecha(event.target.value);
    };
    const guardarId = () => {
      let token = localStorage.getItem('token');
      let decod = jwt_decode(token)
      setTkDecod(decod)
      return decod
  }
    const agregarMovimiento = async() => {
      let sepF = Fecha.split('-');
      let fecha = new Date(parseInt(sepF[0]), parseInt(sepF[1])-1, parseInt(sepF[2]));
      let idComprobante = await postComprobante({controlado:false, archivo: ComprobanteId})
      let mov = {
        puntoVentaId:parseInt(idPuntoVenta),
        nroOperacion : Operacion,
        categoriaId : parseInt(CategoriaEfectivo),
        tipo: Tipo,
        concepto : Concepto,
        emisor : Emisor,
        receptor : Receptor,
        importe : parseInt(Importe),
        controlado : false,
        fecha : fecha,
        comprobanteId : idComprobante.comprobante.id,
        usuarioId: TkDecod.usuario?.identificador
      }
      
      let aggMov = await postMovimiento(mov)
      if (aggMov.ok) {
        successAlert(setAlert, "Movimiento Agregado Correctamente")
        reset()
        traerMovimientos()
        setModalAgregarEgresoEfectivo(false)
      } else {
        warningAlert(setAlert, "Error al agregar el Movimiento")
      }
    }
    const reset = () => {
      setEmisor('')
      setReceptor('')
      setOperacion('')
      setConcepto('')
      setTipo('')
      setCategoriaEfectivo('')
      setImporte('')
    }
    useEffect(() => {
      guardarId()
    }, [])
    
  return (
    <>
    {Alert}
            <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={ModalAgregarEgresoEfectivo}
                            toggle={() => setModalAgregarEgresoEfectivo(false)}
                          >
                          <div className="modal-body p-0">
                            <Card className="bg-secondary shadow border-0">
                              <CardHeader className="bg-transparent pb-5">
                                <div className="text-center"><b>Agregar Movimiento</b>
                                <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => setModalAgregarEgresoEfectivo(false)}
                                >
                                  <span aria-hidden={true}>×</span>
                                </button>
                                </div>
                                <div className="text-muted mt-2 mb-3">
                                <Form role="form">
                                <FormGroup className="mb-3 " >Emisor:
                                        <Input
                                          placeholder="Emisor..."
                                          type="text"
                                          value={Emisor} 
                                          onChange={cambiarEmisor}
                                        />
                                  </FormGroup>
                                  <FormGroup className="mb-3 " >Receptor:
                                        <Input
                                          placeholder="Receptor..."
                                          type="text"
                                          value={Receptor} 
                                          onChange={cambiarReceptor}
                                        />
                                  </FormGroup>
                                  <FormGroup className="mb-3 " >Fecha:
                                        <Input
                                          type="date"
                                          value={Fecha} 
                                          onChange={cambiarFecha}
                                        />
                                  </FormGroup>
                                <FormGroup className="mb-3 " >Importe:
                                        <Input
                                          placeholder="Importe..."
                                          type="number"
                                          value={Importe} 
                                          onChange={cambiarImporte}
                                        />
                                  </FormGroup>
                                  <FormGroup > Factura:
                                          <Input 
                                          placeholder="Factura..." 
                                          type="text"
                                          value={Operacion} 
                                          onChange={cambiarOperacion} />
                                  </FormGroup>
                                  <FormGroup >
                                    <label htmlFor="exampleFormControlSelect1" >Categoria:</label>
                                    <SelectorCategoriaEfectivo CategoriaEfectivo={CategoriaEfectivo} setCategoriaEfectivo={setCategoriaEfectivo}/>
                                  </FormGroup>
                                  <FormGroup >
                                    <label htmlFor="exampleFormControlSelect1" >Tipo:</label>
                                    <SelectorTipoCategoria Tipo={Tipo} setTipo={setTipo}/>
                                  </FormGroup>
                                  <FormGroup > Concepto:
                                    <Input 
                                    placeholder="Concepto..." 
                                    className=" form-control-alternative textarea-autosize"
                                    type="textarea"
                                    value={Concepto} onChange={cambiarConcepto}
                                    />
                                </FormGroup>
                                <SubirComprobante setComprobanteId={setComprobanteId} />
                                  <div className="text-center">
                                    <Button
                                      className="my-4"
                                      color="primary"
                                      type="button"
                                      onClick={()=>agregarMovimiento()}
                                    >
                                      Agregar
                                    </Button>
                                
                                    <Button
                                      color="secondary"
                                      data-dismiss="modal"
                                      type="button"
                                      onClick={() => { setModalAgregarEgresoEfectivo(false)}}
                                    >
                                      Cerrar
                                    </Button>                    
                                  </div>
                                </Form>                     
                                </div>                    
                              </CardHeader>                  
                            </Card>
                          </div>
                          </Modal>
    </>
  )
}

export default ModalAggEgresoEfectivo