import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap'
import ModalConsultarCierreCaja from './components/ModalConsultarCierre'
import TableCierreCaja from './components/TableCaja'
import jwt_decode from "jwt-decode";
import { warningAlert } from 'pages/components/SweetAlert';
import { cierreCajaByFecha } from 'services/CierreCajaService';
import ReportComponent from './components/ReporteComponent';
import { getPagosPorPuntoVentaApi1 } from 'services/PagosService';

const CierreCaja = props => {
    const [Vista, setVista] = useState("CierreCaja")
    const [ModalCierreCaja, setModalCierreCaja] = useState(false)
    const [TkDecod, setTkDecod] = useState(null)
    const [TotalCaja, setTotalCaja] = useState('')
    const [Resumen, setResumen] = useState([])
    const [Desde, setDesde] = useState("");
    const [Hasta, setHasta] = useState("");
    const [Alert, setAlert] = useState(null);
    const [Reporte, setReporte] = useState([])
    const [puntoVentaId,setPuntoVentaId] = useState(null);
    

    const cambiarDesde = event => {
        setDesde(event.target.value);
      };
    const cambiarHasta = event => {
    setHasta(event.target.value);
    };


    const guardarId = () => {
        let token = localStorage.getItem('token');
        let decod = jwt_decode(token)
        setTkDecod(decod)
        return decod
    }

      const transformDate=(hoy)=>{
    
        var mes = hoy.getMonth() + 1
        if(hoy.getDate() >= 10){var dia = `${hoy.getDate()}`}else{var dia = `0${hoy.getDate()}`}
        
        
        if(mes >= 10){
            return `${hoy.getFullYear()}-${mes}-${dia}`;
        }
        else{
            return `${hoy.getFullYear()}-0${mes}-${dia}`;
        }
        
        
    }

    const consultarCierre = async()=>{
        let dsde = "";
        let hsta = "";
        if(Desde || Hasta){
            if (Desde == null || Hasta == null) {
                warningAlert(setAlert,'Seleccione ambas fechas');
            return
            }else{
                dsde = transformDate(new Date(Desde));
                hsta = transformDate(new Date(Hasta));
            }
        }else{
            dsde = transformDate(new Date());
            hsta = transformDate(new Date());
        }
        //let pagos = await getPagosPorPuntoVenta(TkDecod?.usuario?.puntoVentaId, dsde, hsta)
        let pagos = await getPagosPorPuntoVentaApi1(TkDecod?.usuario?.puntoVentaId)
        generarResumenCaja(pagos.data)
    }
    
    
    const generarResumenCaja = (pagos)=>{
        let total = 0;
        pagos.forEach(p => {
            total+=p.total;
        })
        setResumen(pagos);// Esto no iria si se usa la func generarResumenCaja comentada
        setTotalCaja(total);
        setModalCierreCaja(true);
      }

    const generarReporte = async() => {
        if(Desde === "" || Hasta === ""){
            warningAlert(setAlert,'Seleccione ambas fechas');
            return
          }else if(Hasta >= Desde ){
            let params = {fechaInicio: Desde, fechaFin: Hasta}
            let data = await cierreCajaByFecha( params, puntoVentaId);
            setReporte(data);
              
            } else{
            warningAlert(setAlert,'La fecha final es menor a la inicial')
          }
    }
    

    useEffect(() => {
        
        setPuntoVentaId(props.match.params.puntoVentaId);
        guardarId()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if(Vista === "CierreCaja"){
        return (
          <>
          <Card>
              <CardHeader>
                  <Row>
                      <Col>
                      <Row>
                          <Button className="btn-md btn-info mt-3" onClick={()=>{consultarCierre()}}>
                           Consultar Cierre
                          </Button>
                          <Button className="btn-md btn-info mt-3" onClick={()=>{setVista("Resumen")}}>
                           Consultar Resumen
                          </Button>
                      </Row>
                      </Col>
                      <Col>
                      </Col>
                  </Row>
              </CardHeader>
              <CardBody>
                  {
                      puntoVentaId?
                      <TableCierreCaja puntoVentaId={puntoVentaId}/>
                      :null
                  }
                  
              </CardBody>
          </Card>
          <ModalConsultarCierreCaja
          TotalCaja={TotalCaja} 
          Resumen={Resumen} 
          ModalCosultarCierre={ModalCierreCaja} setModalCosultarCierre={setModalCierreCaja}/>
          </>
        )
    }else{
        return (
        <>
        {Alert}
            <Card className='bg-secondary'>
                <CardHeader>
                    <Row>
                        <Col>
                        <Row>
                            {/* <Button className="btn-md btn-info mt-3" onClick={()=>{consultarCierre()}}>
                            Consultar Cierre
                            </Button> */}
                            <Button className="btn-md btn-info mt-3" onClick={()=>{setVista("CierreCaja")}}>
                            Atras
                            </Button>
                        </Row>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <label>Desde:</label>
                        <Input
                        id="example-date-input"
                        type="date"
                        value={Desde} onChange={cambiarDesde}
                        />
                        </Col>
                        <Col>
                        <label>Hasta:</label>
                        <Input
                        id="example-date-input"
                        type="date"
                        value={Hasta} onChange={cambiarHasta}
                        />
                        </Col>
                        <Col>
                            <Button style={{marginTop:"2rem"}} className="btn-md btn-info" onClick={()=>generarReporte()}>
                                Generar Reporte
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                {Reporte?
                <ReportComponent
                Reporte={Reporte}
                />
                :
                <>-</>
                }
                </CardBody>
            </Card>
            <ModalConsultarCierreCaja
            TotalCaja={TotalCaja} 
            Resumen={Resumen} 
            ModalCosultarCierre={ModalCierreCaja} setModalCosultarCierre={setModalCierreCaja}
            />
        </>
        )
    }

}


export default CierreCaja