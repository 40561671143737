import React from 'react'
import { Input } from 'reactstrap'

const SelectorEstadoEfectivo = ({Estado, setEstado}) => {
    const cambiarEstado = (e) => {
        setEstado(e.target.value)
    }

  return (
    <>
    <Input type='select' onChange={cambiarEstado} value={Estado} >
        <option value=''>Estado</option>
        <option value={true}>CONTROLADO</option>
        <option value={false}>NO CONTROLADO</option>
    </Input>
    </>
  )
}

export default SelectorEstadoEfectivo