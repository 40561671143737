import { createSlice } from '@reduxjs/toolkit';

export const caesSlice = createSlice({
  name: 'caes',
  initialState: {
    loading:false,
    queryParams:{}
  },
  reducers: {
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setQueryParams, setLoading } = caesSlice.actions;