import { confirmAlert } from 'pages/components/SweetAlert'
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap'
import { getCategoriasValores } from 'services/CategoriaValoresService'
import { deleteValores } from 'services/ValoresService'
import { getValores } from 'services/ValoresService'
import ModalAggCategorias from './ModalAggCategorias'
import ModalAggValores from './ModalAggValores'
import SelectorCategoriaValor from './SelectorCategoriaValor'

const TableValores = props => {
    const [ModalAgregarValores, setModalAgregarValores] = useState(false)
    const [ModalAgregarCategorias, setModalAgregarCategorias] = useState(false);
    const [Valores, setValores] = useState([]);
    const [Categorias, setCategorias] = useState([])
    const [Alert, setAlert] = useState(null)
    const [CategoriaId, setCategoriaId] = useState("")

const traerValores = async()=> {
    let valores = await getValores();
    if (valores) {
        setValores(valores.valores)
    } else {
        alert("error al cargar los valores")
    }
}
const traerValoresFiltrado = async () => {
    let params ={}
    if (CategoriaId !== '') {
        params.categoriaId = parseInt(CategoriaId)
    }
    let valores = await getValores(params)
    if (valores) {
        setValores(valores.valores)
    }
}


const traerCategorias = async() => {
    let categorias = await getCategoriasValores();
    if (categorias !== null) {
        setCategorias(categorias)
    }
}

const eliminarValor = async (id) => {
    await deleteValores(id)
    traerValores()
}

const confirmarEliminar = (valorId) => {
    confirmAlert(setAlert, eliminarValor, valorId , 'Eliminar valor?' )
}

useEffect(() => {
    traerValores();
    traerCategorias()
}, [])

  return (
    <>
    {Alert}
    <Card>
        <CardHeader>
            <Row className='mb-3 mt-3'>
                <Col><h1>Valores</h1></Col>
                <Col></Col>
                <Col><Button className='btn-md btn-outline-info float-right' onClick={()=> setModalAgregarValores(true)}>Agregar Valores</Button></Col>
                <Col><Button className='btn-md btn-outline-info float-left' onClick={()=> setModalAgregarCategorias(true)}>Agregar Categoria</Button></Col>
            </Row>
        </CardHeader>
        <CardBody>
            <Row className='mb-3'>
                <Col><SelectorCategoriaValor valor={CategoriaId} CambiarCatValor={setCategoriaId} ID={true}/></Col>
                <Col><Button className='btn-md btn-outline-success float-left' onClick={()=>traerValoresFiltrado()}>Filtrar</Button></Col>
                <Col></Col>
                <Col></Col>
            </Row>    
            <Table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>NOMBRE</th>
                        <th>IMPORTE</th>
                        <th>CATEGORIA</th>
                        <th>ELIMINAR</th>
                    </tr>
                </thead>
                <tbody>
                    {Valores?
                    Valores.map((v, index)=> {
                        return(
                    <tr key={index}>
                        <th>{v.id}</th>
                        <td>{v.nombre}</td>
                        <td>{v.importe}</td>
                        <td>{v.categoriasValore?.nombre}</td>
                        <td><Button className='btn-sm btn-outline-danger' onClick={()=> confirmarEliminar(v.id)}>Eliminar</Button></td>
                    </tr>

                        )
                    })
                :
                <></>
                }
                </tbody>
            </Table>
        </CardBody>
    </Card>
    <ModalAggValores
    ModalAgregarValores={ModalAgregarValores}
    setModalAgregarValores={setModalAgregarValores}
    traerValores={traerValores}
    Categorias={Categorias}
    />
    <ModalAggCategorias
    ModalAgregarCategorias={ModalAgregarCategorias}
    setModalAgregarCategorias={setModalAgregarCategorias}
    traerValores={traerValores}
    />
    </>
  )
}

export default TableValores