import React , { useEffect, useState } from "react";
import '../../../assets/estilos/style.css';
import { useHistory } from "react-router";

// reactstrap components
import {
    Container,
    Row,
  } from "reactstrap";
import GnetNavbar from "./components/NavBarEmpleados";
import ContenedorSubVistas from "./ContenedorSubVistas";
import { GuardInPages } from "guards/Guard";
const PerfilEmpleados = props => {
    const history = useHistory()

    const [vista, setVista] = useState("Rendiciones");
    const [idEmpl, setId] = useState(null);
    useEffect(() => {
      GuardInPages(history)
      const empleadoId=props.match.params.idEmpleado;
      setId(empleadoId);
    }, [])

    
        return (
          <>
            {idEmpl?
              <Container className="mt-5" fluid>
                 <Row>
                       {/* llamo a la nav con Botones varios */}
                       <GnetNavbar setVista={setVista} idEmpls={idEmpl}/>
                 </Row>
                 <Row>
                   <ContenedorSubVistas vista={vista} idEmpls={idEmpl}/>
                 </Row>
             </Container>
              :
            null
            }
          </>
           
        )
      }//fin else loading

export default PerfilEmpleados