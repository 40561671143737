import { useEffect, useState } from "react"
import { Table } from "reactstrap";
import {getClientesFiscales} from "../../../services/ClientesServices";


export const ClientesFiscales = () => {
  const [Clientes,setClientes] = useState([]);
    
  
  useEffect(() => {
    getClientesFiscales().then(data => setClientes(data));
  },[])
  
  return (
    <>

    <Table className="align-items-center" responsive >
            <thead className="thead-light  text-center">
            <tr>
              <th scope="col">ID</th>
              <th scope="col">CLIENTE</th>
          
             
              
            </tr>
            </thead>
        {Clientes?
        Clientes.map((c, index)=>{
            return(
                <tr key={index} className="text-center" >
                    <th scope="row">
                    <div className=" text-center">
                          <span className="mb-0 text-sm">
                            {c.id}
                          </span>
                      </div>
                    </th>

                    <td>
                    <div className=" text-center">
                          <span className="mb-0 text-sm">
                            {c.firstName} {c.lastName}
                          </span>
                    </div>
                    </td>
                
                </tr>
                
                
                
                   
                
            )
        })
        :
        <>-</>
        }
        </Table>
       
    </>
  )
}

