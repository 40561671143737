import { urlGlobal2 } from './GlobalService.js';

export const getEsperas = async(params=null) =>{
    let url = new URL(`${urlGlobal2}/esperas`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}
export const getEsperasBuscador = async(valor) => {
    //aca puedo filtrar por ej: DNI, nombre, apellido, telefono, mail, etc...
    let token = localStorage.getItem('token');
    let headers = {'Content-Type':'application/json','token':token};
    const nResp = await fetch(`${urlGlobal2}/esperaas?query_result=${valor}`,{headers,method:'GET'});
    const nData = await nResp.json();
    return nData;
}
export const getEsperasPorZona = async(params=null, zonaId) =>{
    let url = new URL(`${urlGlobal2}/esperas/filtrarPorZona/${zonaId}`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}
export async function postEspera(esperas){
    let url = `${urlGlobal2}/esperas`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(esperas)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }
  export async function deleteEspera(id){
    let url = `${urlGlobal2}/esperas/${id}`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'DELETE';
        const resp = await fetch(url, {headers, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }
  export async function putEditarEspera(acce){
    let url = `${urlGlobal2}/esperas`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'PUT';
        let body = JSON.stringify(acce)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }