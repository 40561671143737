import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import { confirmAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react'
import { Badge } from 'reactstrap'
import { putEditarVehiculo } from 'services/VehiculosServices';


function EtiquetaEstadoVe(props){
    const {Estado, id, traerVehiculo } = props;
    const [alertSweet, setAlert] = useState(null); 

    const ConfirmarCambioEstado2 =()=>{
        confirmAlert(setAlert, CambiarAEstado2, null,"Cambiar vehículo a Reparación?")
    };

    const ConfirmarCambioEstado1 =()=>{
        confirmAlert(setAlert, CambiarAEstado1, null,"Cambiar vehículo a Activo?")
    };

    const CambiarAEstado1 = async() => {
        let vehicu = {
          id: id,
          estadoId:1,
        };
        let con = await putEditarVehiculo(vehicu)
        if (con != null && con != '') {
          successAlert(setAlert, "Vehículo Activo!")
          traerVehiculo ()
        }else{
          warningAlert(setAlert, "No se pudo cambiar del estado del Vehiculo!")
        }
      }

    const CambiarAEstado2 = async() => {
        let vehicu = {
          id: id,
          estadoId:2,
        };
        let con = await putEditarVehiculo(vehicu)
        if (con != null && con != '') {
          successAlert(setAlert, "Vehículo en Reparación!")
          traerVehiculo ()
        }else{
          warningAlert(setAlert, "No se pudo cambiar del estado del Vehículo!")
        }
      }
    if(Estado == 1){
        return(
        <>
        {alertSweet}
            <Badge className="badge-md" color="success" onClick={()=>ConfirmarCambioEstado2()}>Activo</Badge>
        </>
        )
    }else if(Estado == 2){
        return(
            <>
            {alertSweet}
            <Badge className="badge-md" color="danger" onClick={()=>ConfirmarCambioEstado1()}>En Reparacion</Badge>
            
            </>
        )
    }else{
        return <></>
    }
}

export default EtiquetaEstadoVe;