import React, { useEffect, useState } from "react";
import { getPagosCliente } from "services/PagosService";
import CardPagos from "../components/CardPagos";
import ImprimirPago from "../components/ImprimirPago";

function Pagos(props){

  const {idClient, datos} = props;
  const [PagosCliente, setPagosCliente]= useState([]);
  const [Vista, setVista] = useState("pagos")
  const [IdPago, setIdPago] = useState("")

  const traerPagosCliente = async() => {
    let cas = await getPagosCliente(null, idClient);
    if (cas != null && cas != '') {
      setPagosCliente(cas.reverse());
    }
  }

  useEffect(() => {
    traerPagosCliente();
  }, [])

  if (Vista == "pagos") {
    return (
        <>
          <CardPagos idClient={idClient} PagosCliente={PagosCliente} traerPagosCliente={traerPagosCliente} setVista={setVista} setIdPago={setIdPago}/>
        </>
    )
  } if(Vista == "pago"){
    return (
      <>
        <ImprimirPago 
        PagosCliente={PagosCliente}
        IdPago={IdPago}
        idClient={idClient}
        datos={datos}
        />
      </>
    );
  }else {
    return <></>
  }
}


export default Pagos;