import React, { useEffect, useState } from 'react'
import { Card, Table } from 'reactstrap';





const TableRetiros=(props)=>{
    const {RetirosEquipos} = props;
 
    const obtenerFormatoFecha = (fechaIngreso)=>{
        let fecha = new Date(fechaIngreso);
        let dia = fecha.getDate();
        let mes = fecha.getMonth();
        let ano = fecha.getFullYear();
        let fechaFormateada = `${dia}/${mes+1}/${ano}`;
        return fechaFormateada;
    }
    
      useEffect(() => {
        
      }, [])

    return(
        <>
        <Table className="align-items-center " responsive>
            <thead className="bg-light text-center">
            <tr >
                <th scope="col">SERVICIO ID</th>
                <th scope="col">CLIENTE ID</th>
                <th scope="col">FECHA DE CREACION</th>
                <th scope="col">FECHA DE REALIZACION</th>
                <th scope="col">COMENTARIO</th>
            </tr>
            </thead>
            <tbody>
            {RetirosEquipos?
            RetirosEquipos.map((re, index)=>{
                return (
                <tr key={index}>
                
                    <td>
                    <div className="text-center">
                            <span className="mb-0 text-sm">
                                <b>{re.serviceId}</b>
                            </span>
                        </div>
                    </td>
                    <td>
                    <div className="text-center">
                            <span className="mb-0 text-sm">
                                {re.clientId}
                            </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                            <span className="mb-0 text-sm">
                            {obtenerFormatoFecha(re.creado)}
                            </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                            <span className="mb-0 text-sm">
                            {obtenerFormatoFecha(re.fechaRealizado)}
                            </span>
                    </div>
                    </td>
                    <td>
                        <div className="text-center">
                          {re.comentario}
                        </div>
                        
                    </td>
                </tr>

                )
             })
             :
            <p>Cargando...</p>
            } 
            </tbody>
        </Table>
        </>
        
    )
}
export default TableRetiros