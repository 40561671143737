import { urlGlobal2 } from './GlobalService.js';
import axios, {post, get} from 'axios' 

export async function postArchivo(formD, destination = 'cloudinary') {
    let url = `${urlGlobal2}/files`;

    if (destination === 's3') {
        url += `?destination=s3`;
    }

    try {
        let token = localStorage.getItem('token');
        let headers = { 'Content-Type': 'multipart/form-data', token };
        let { data } = await post(url, formD, { headers });
        return data;
    } catch (error) {
        return null;
    }
}

export const getSignedUrl = async (key) => {
    try {
        let token = localStorage.getItem('token');
        let headers = { 'Content-Type': 'multipart/form-data', token };
        let { data } = await get(`${urlGlobal2}/files/generate-signed-url?key=${encodeURIComponent(key)}`, { headers });

        if (data.ok) {
            return data;
        } else {
            console.error(data.mensaje);
        }
    } catch (error) {
        console.error('Error obteniendo la URL pre-firmada:', error);
    }
};
