import React, { useEffect, useState } from "react";
import { FormGroup, Input } from "reactstrap";

function SelectorTipoSueldo(props){
    const {Valor, setValor} = props;
    
    const cambiarValor = event => {
        setValor(event.target.value);
    };

    return(
        <>
            <FormGroup>
                    <Input id="exampleFormControlSelect1" type="select" 
                        value={Valor}
                        onChange={cambiarValor}
                    >
                        <option value={''}>Opciones..</option>
                        <option value={'FIJO'}>FIJO</option>
                        <option value={'POR INSTALACION'}>POR INSTALACIÓN</option>
                        <option value={'POR COMISION'}>POR COMISIÓN</option>
                    </Input>
            </FormGroup>
        </>
    );
};

export default SelectorTipoSueldo;