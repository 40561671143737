
import { confirmAlert } from 'pages/components/SweetAlert';
import { successAlert, warningAlert } from 'pages/components/SweetAlert';
import { useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, InputGroup, Modal } from 'reactstrap';
import { deleteCae } from 'services/CaesServices';
import { postCaes } from 'services/CaesServices';
const XLSX = require('xlsx');


const ModalAddFromExcel = ({ ModalAgregarCaeDesdeExcel, setModalAgregarCaeDesdeExcel, ActionType, setActionType }) => {
    const [Alert, setAlert] = useState(null)


    const validar = () => {
        if (document.getElementById('inputFile').files[0] === undefined) {
            return false;
        }
        return true;
    };

    const addCae = async () => {

        if (validar() === false) {
            warningAlert(setAlert, "Seleccione un archivo!")
        } else {
            ActionType === 'add' ? confirmarImportacion() : confirmarEliminacion()
        }
    }




    const recetearCae = () => {
        setModalAgregarCaeDesdeExcel(false)
        setActionType('add')
    }


    const confirmarImportacion = async () => {
        confirmAlert(setAlert, importarExcel, null, "Importar Caes desde Excel?", "Se importaran todas las caes existentes en el archivo seleccionado")
    }

    const confirmarEliminacion = async () => {
        confirmAlert(setAlert, importarExcel, null, "Eliminar Caes desde Excel?", "Se Eliminaran todas las caes existentes en el archivo seleccionado")
    }
    const importarExcel = () => {
        const input = document.getElementById('inputFile');

        const file = input.files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });

            // Puedes especificar la hoja que deseas leer, por ejemplo, la primera hoja (índice 0)
            const sheet_name_list = workbook.SheetNames;
            const sheet = workbook.Sheets[sheet_name_list[0]];

            // Convierte el contenido de la hoja en un objeto JSON
            const json_data = XLSX.utils.sheet_to_json(sheet);

            // Muestra los datos en la consola o en el elemento de salida
            ActionType === 'add' ? addFromExcel(json_data) : removeFromExcel(json_data)

        };

        reader.readAsBinaryString(file);
    }


    const addFromExcel = async (data) => {
        try {
            data.forEach(cae => {
                postCaes({
                    tipo: cae.tipo,
                    importe: cae.importe,
                    fecha: cae.fecha,
                    fechaFacturacion: cae.fechaFacturacion,
                    nroFcatura: cae.nroFcatura,
                    cae: cae.cae
                })
            })
            successAlert(setAlert, "Archivo importado con éxito!")

        } catch (error) {
            warningAlert(setAlert, "Error al agregar Caes desde Excel", "Compruebe que el formato de las caes sea el correcto")
        }
    }

    const removeFromExcel = async (data) => {

        //Esperando especificaciones sobre con que campo eliminar


        // try {
        //     data.forEach(cae => {
        //         deleteCae({
        //             nroFcatura: cae.nroFcatura
        //         })
        //     })
        //     successAlert(setAlert, "Caes eliminados con éxito!")

        // } catch (error) {
        //     warningAlert(setAlert, "Error al eliminar Caes desde Excel", "Compruebe que el formato de las caes sea el correcto")
        // }
        
        successAlert(setAlert, "Eliminar")
    }



    return (
        <>
            {Alert}
            <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={ModalAgregarCaeDesdeExcel}
                toggle={() => (setModalAgregarCaeDesdeExcel(false), recetearCae())}
            >
                <div className="modal-body p-0">
                    <Card className=" shadow border-0">
                        <CardHeader className="bg-transparent pb-5">

                            <div className="text-center">
                                {ActionType === 'add' ?
                                    <b>Agregar caes</b>
                                    :
                                    <b>Eliminar caes</b>
                                }
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => (setModalAgregarCaeDesdeExcel(false), recetearCae())}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Form role="form">
                                <FormGroup>Seleccione un archivo excel:
                                    <InputGroup className="input-group-alternative mt-2">
                                        <Input type="file" id='inputFile' />
                                    </InputGroup>
                                </FormGroup>

                            </Form>
                        </CardBody>
                        <CardFooter>
                            <div className="text-center">
                                <Button
                                    color="primary"
                                    type="button"
                                    onClick={() => addCae()}
                                >
                                    {ActionType === 'add' ?
                                        "Importar"
                                        :
                                        "Importar y Eliminar"
                                    }

                                </Button>
                                <Button
                                    color="secondary"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => (setModalAgregarCaeDesdeExcel(false), recetearCae())}
                                >
                                    Cancelar
                                </Button>
                            </div>
                        </CardFooter>

                    </Card>
                </div>
            </Modal>
        </>
    )
}


export default ModalAddFromExcel
