import SelectorGenerico from 'pages/components/SelectorGenerico'
import React, { useState } from 'react'
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Row} from 'reactstrap'
import GraficoCasos from './components/GraficoCasos'
import GraficoComponent from './components/GraficoComponent'
import GraficoPerdidas from './components/GraficoPerdidas'
import GraficoClientesEsperas from './components/GraficosEsperas'
import ModalAgregarGrafico from './components/ModalAggGrafico'

const Graficos =()=> {
    const [ModalAgregarGrafic, setModalAgregarGrafic] = useState(false)
    const [TipoGraficoSelec, setTipoGraficoSelec] = useState(null)
    const [tiposGraficos, setTiposGraficos] = useState(null)
    const [fechaInicio, setfechaInicio] = useState(null)
    const [fechaFin, setfechaFin] = useState(null) // formato: "aaaa-mm-dd"

    const cambiarFechaFin = event => {
      setfechaFin(event.target.value);
    };

    const cambiarFechaInicio = event => {
      setfechaInicio(event.target.value);
    };
    return (
        <>
          <Container fluid>
            <Row className="mt-2">
                  <Col>
                    <Card className="mt-3">
                      <CardBody>
                        <Row>
                          <Col>
                            <FormGroup>
                                  <label
                                  className="mb-3"
                                  htmlFor="example-date-input"
                                  >
                                  Tipo de Grafico:
                                  </label>
                                  <SelectorGenerico/>
                              </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                                <label
                                className="mb-3"
                                htmlFor="example-date-input"
                                >
                                Fecha Incio:
                                </label>
                                <Input
                                defaultValue={new Date().getFullYear() + "-11-23"}
                                id="example-date-input"
                                type="date"
                                value={fechaInicio} onChange={cambiarFechaInicio}
                                />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                                <label
                                className="mb-3"
                                htmlFor="example-date-input"
                                >
                                Fecha Fin:
                                </label>
                                <Input
                                defaultValue={new Date().getFullYear() + "-11-23"}
                                id="example-date-input"
                                type="date"
                                value={fechaFin} onChange={cambiarFechaFin}
                                />
                            </FormGroup>
                          </Col>
                          <Col>
                            <Button className=" btn-md btn-outline-success  float-right mt-4" type="button" onClick={()=>{setModalAgregarGrafic(true)}}>
                                Filtrar <i class="ni ni-chart-bar-32"></i>    
                            </Button>
                          </Col>
                          <Col>
                          </Col>
                          <Col>
                          </Col>
                          <Col>
                            <Button className=" btn-md btn-outline-info  float-right mt-4" type="button" onClick={()=>{setModalAgregarGrafic(true)}}>
                                Agregar <i class="ni ni-chart-bar-32"></i>    
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
            </Row>
            <Row>
              <Col>
                <Card className="mt-3">
                  
                  {/*
                    TipoGraficoSelec?
                    <GraficoComponent tipoGraficoId={tg.id} fechaInicio={fechaInicio} fechaFin={fechaFin}/>
                    :
                    tiposGraficos?
                      tiposGraficos.map((tg, index) => {
                        return <GraficoComponent key={index} tipoGraficoId={tg.id} fechaInicio={fechaInicio} fechaFin={fechaFin}/>   
                      })
                      :
                      <></>
                  */}
                </Card>
              </Col>
            </Row>
          </Container>
         <ModalAgregarGrafico ModalAgregarGrafic={ModalAgregarGrafic} setModalAgregarGrafic={setModalAgregarGrafic} traerGraficos={null} setAlert={null}/>
       </>
    )
}


export default Graficos
