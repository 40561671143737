import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Form, FormGroup, Input, Alert, Modal } from 'reactstrap';
import classnames from "classnames";
import { successAlert } from 'pages/components/SweetAlert';
import { warningAlert } from 'pages/components/SweetAlert';
import { putEditarEvento } from 'services/EventosService';
import { confirmAlert } from 'pages/components/SweetAlert';
import { deleteEvento } from 'services/EventosService';
import SelectorEmpleados from '../../../components/SelectorEmpleados';
import SelectorTiposMantenimientos from 'pages/components/SelectorTiposMantenimientos';
const ModalEditMan = (props) => {
    const {createCalendar, ModalEditarMantenimiento, setModalEditarMantenimiento, Evento, MantenimientoEdit}= props;
    const [Radios, setRadios] = useState("");
    const [Descripcion, setDescripcion] = useState("");
    const [Titulo, setTitulo] = useState("");
    const [AlertM, setAlert] = useState("");
    const [Tipo, setTipo] = useState("");
    const [Hora, setHora] = useState("");
    const [Turno, setTurno] = useState("");
    const [Empleado, setEmpleado] = useState("");


    const EditarMantenimiento = async() => {

        let evento = {
            id: Evento.id,
            comentario: Evento.eventDescription,
            tipoMantenimientoId: parseInt(Tipo),
            clienteId:Evento.clienteId
        }
        if(Radios !== ""){
          evento.className = Radios
        }
        if(Titulo !== ""){
          evento.title = Titulo
        }
        if(Empleado !== ""){
          evento.empleadoId = Empleado
        }
        if(Tipo !== ""){
            evento.tipoMantenimientoId = Tipo
          }
        if(Descripcion !== ""){
          evento.comentario = Descripcion
        }
        if(Turno !== ""){
          evento.start = Turno
        }
        if(Hora !== ""){
          evento.hora = Hora
        }
        let eved = await putEditarEvento(evento)
        if (eved !== null && eved !== '') {
          recetearMantenimiento();
          successAlert(setAlert, "Evento Editado!")
          setModalEditarMantenimiento(false)
          createCalendar();
        }else{
          warningAlert(setAlert, "No se pudo editar el Evento!")
        }
      }

    const cambiarDescripcion = event => {
        setDescripcion(event.target.value);
      };
    const cambiarTitulo = event => {
        setTitulo(event.target.value);
    };
    const cambiarHora = event => {
      setHora(event.target.value);
    };
    const cambiarTurno = event => {
      setTurno(event.target.value);
    };

    const confirmarEliminar= async(id)=>{
        confirmAlert(setAlert, eliminarEvento, id , "Deseas eliminar este Evento?")
      }
      const eliminarEvento = async(id)=>{
        
      let result = await deleteEvento(id)
      if(result !== null){
              successAlert(setAlert, "Evento Eliminado!", "");
              setModalEditarMantenimiento(false);
              createCalendar()
              return
    }else{
        warningAlert(setAlert,'No se pudo eliminar el Mantenimiento')
    }
    };

    const recetearMantenimiento=()=>{
      setTitulo("");
      setRadios("");
      setTurno("");
      setHora("");
      setDescripcion("");
      setTipo("");
      setEmpleado("");
    }
    const convertDate=(fecha) =>{
      let date = new Date(fecha);
      var yyyy = date.getFullYear().toString();
      var mm = (date.getMonth()+1).toString();
      var dd  = date.getDate().toString();
  
      var mmChars = mm.split('');
      var ddChars = dd.split('');
  
      return yyyy + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + (ddChars[1]?dd:"0"+ddChars[0]);
    }

     useEffect(() => {
    }, []);

  return (
      <>
      {AlertM}
      {Evento?
      
      <Modal
          isOpen={ModalEditarMantenimiento}
          toggle={() => setModalEditarMantenimiento(false)}
          className="modal-dialog-centered modal-secondary modal-sm"
        >
          <div className="modal-body">
            <Form className="edit-event--form">
              <FormGroup>
                <label className="form-control-label">Titulo del evento</label>
                <Input
                  className="form-control-alternative edit-event--title"
                  placeholder="Event Title"
                  type="text"
                  defaultValue={Evento.eventTitle}
                  onChange={cambiarTitulo}
                />
              </FormGroup>
                {MantenimientoEdit?
                <>
                {MantenimientoEdit.completado?
                <Alert className="alert-default">
                  <strong>Evento Finalizado!</strong> 
                </Alert>
                :<></>}
                <FormGroup >
                <h4>Cliente Id:</h4>{MantenimientoEdit.id}<br/>
                <h4>Apellido y Nombre:</h4>{MantenimientoEdit.lastName}, {MantenimientoEdit.firstName}<br/>
                <h4>Dirección:</h4>{Evento.direccion}<br/>
                <h4>Teléfono:</h4>{MantenimientoEdit.contacts?.phone}<br/>
                <h4>Tecnico:</h4> {Evento.empleado?.nombre} {Evento.empleado?.apellido}<br/>
                <h4>Tipo de Mantenimiento</h4>{Evento.tipoMantenimiento?.nombre}<br/>
                </FormGroup>
                </>
              
              :
              <>-</>
              }
              <FormGroup>
                <label className="form-control-label">Cambiar Tipo de Mantenimiento</label>
                <SelectorTiposMantenimientos valor={Tipo} cambiarValor={setTipo}/>
              </FormGroup>
              <FormGroup>
                  <label>Tecnico:</label>
                  <SelectorEmpleados valor={Empleado} cambiarValor={setEmpleado} titulo={false}/>
              </FormGroup>
              <FormGroup>
                <label className="form-control-label">Turno:</label>
                <Input
                  type="date"
                  defaultValue={convertDate(Evento.turno)} 
                  onChange={cambiarTurno}
                />
              </FormGroup>
              <FormGroup>
                <label className="form-control-label">Horario Estimado</label>
                <Input
                  type="time"
                  defaultValue={Evento.hora}
                  onChange={cambiarHora}
                />
              </FormGroup>
              <FormGroup>
                <label className="form-control-label d-block mb-3">
                  Identificador: 
                </label>
                <ButtonGroup
                  className="btn-group-toggle btn-group-colors event-tag mb-0"
                  data-toggle="buttons"
                >
                  <Button
                    className={classnames("bg-info", {
                      active: Radios=== "bg-info"
                    })}
                    color=""
                    type="button"
                    onClick={() => setRadios("bg-info" )}
                  />
                  <Button
                    className={classnames("bg-warning", {
                      active: Radios === "bg-warning"
                    })}
                    color=""
                    type="button"
                    onClick={() => setRadios("bg-warning")}
                  />
                  <Button
                    className={classnames("bg-danger", {
                      active: Radios === "bg-danger"
                    })}
                    color=""
                    type="button"
                    onClick={() => setRadios("bg-danger")}
                  />
                  <Button
                    className={classnames("bg-success", {
                      active: Radios === "bg-success"
                    })}
                    color=""
                    type="button"
                    onClick={() =>
                        setRadios("bg-success")
                    }
                  />
                  <Button
                    className={classnames("bg-primary", {
                      active: Radios === "bg-primary"
                    })}
                    color=""
                    type="button"
                    onClick={() => {setRadios("bg-primary")}}
                  />
                </ButtonGroup>
              </FormGroup>
              <FormGroup>
                <label className="form-control-label">Descripcion:</label>
                <Input
                  className="form-control-alternative edit-event--description textarea-autosize"
                  placeholder="Descripcion del Evento"
                  type="textarea"
                  defaultValue={Evento.eventDescription}
                  onChange={cambiarDescripcion}
                />
                <i className="form-group--bar" />
              </FormGroup>
              <input className="edit-event--id" type="hidden" />
            </Form>
          </div>
          <div className="modal-footer">
            <Button color="primary" 
            onClick={()=>EditarMantenimiento()}
            >
              Editar
            </Button>
            <Button
              color="danger"
              onClick={() =>confirmarEliminar(Evento.id)}
            >
              Eliminar
            </Button>
            <Button
              className="ml-auto"
              color="link"
              onClick={() => setModalEditarMantenimiento(false)}
            >
              Cerrar
            </Button>
          </div>
        </Modal>
        :
        <></>
        }
      </>
  )
}

export default ModalEditMan