import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap'
import LoadingComponent from 'pages/components/Loading'
import SelectorAccessPoint from './SelectorAccessPoint'
import { getAccessPoint } from 'services/CambiosServices'
import { getIp } from 'services/CambiosServices'


export default function ModalBuscar(props){
    const {ModalBuscarIP, setModalBuscarIP} = props
    const [APoint, setAPoint] = useState("");
    const [AccessPoint, SetAccessPoint] = useState([]);
    const [Ip, setIp] = useState(""); 
    const [query, setQuery] = useState("");
    const [Visible, setVisible] = useState(false);

    const cambiarQuery = event => {
      setQuery(event.target.value);
    };
    const cambiarApoint = event => {
      setAPoint(event.target.value);
    };

    const traerAccessPoint = async() => {
      let acp = await getAccessPoint();
      if (acp !== null && acp !== '') {
        SetAccessPoint(acp);
      }
    }
    const BuscarIp = async()=>{
      setVisible(true)
      let params = {ssid:APoint, mac:query}
      let result = await getIp(params)
      if(result !== null){
          setIp(result.result.address);
          
      }else{alert("no se pudo buscar"); }
      setVisible(false)
      }

    const  cancelarBusqueda = () => {
      setIp("");
      setAPoint("")
      setQuery("")
      setModalBuscarIP(false)
    }

    useEffect(() => {
      traerAccessPoint();
    }, [])
    return(
      <>
      <LoadingComponent Visible={Visible}/>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={ModalBuscarIP}
          toggle={() => setModalBuscarIP(false)}
        >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-center"><b>Buscar IP</b>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalBuscarIP(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
              </div>
              <div className="text-muted mt-2 mb-3">
              <Form role="form">
                <FormGroup className="mb-3" >MacAddress:
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      <i className="fas fa-satellite-dish"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                    placeholder="macAddress (con : )" 
                    type="text"
                    value={query} 
                    onChange={cambiarQuery} 
                     />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mt-3">Access Point:
                                        <SelectorAccessPoint Valor={APoint} setValor={setAPoint} arrayElementos={AccessPoint} onChange={cambiarApoint} />    
                </FormGroup>
                <FormGroup className="mb-3">IP:
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      <i className="fas fa-network-wired"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="IP..." type="text"
                    readOnly={true} value={Ip}></Input> 
                    
                  </InputGroup>
                </FormGroup>
                
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={()=>BuscarIp()}
                  >
                    Buscar
                  </Button>
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => cancelarBusqueda()}
                  >
                    Cancelar
                  </Button>                    
                </div>
              </Form>                     
              </div>                    
            </CardHeader>                  
          </Card>
        </div>
        </Modal>
      </>
    )
}