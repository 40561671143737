import React, { useState } from 'react'
import { warningAlert } from 'pages/components/SweetAlert';
import {Button, Card, CardBody, CardFooter, CardHeader, Modal, Table } from 'reactstrap'
import LoadingComponent from 'pages/components/Loading';
import ModalCantidadAEntregar from '../ModalCantidadAEntregar'
import EntregarAEmpleado from './EntregarAEmpleado';

const ModalRegisterUso = ({ModalRegistrarUso, setModalRegistrarUso, traerMateriales, NoAgregados, setNoAgregados, reset}) => {
    
    const [Empleado, setEmpleado] = useState("")
    const [Obra, setObra] = useState("")
    const [Cliente, setCliente] = useState("")
    const [Alert, setAlert] = useState(null)
    const [EmpleadoSeleccionado, setEmpleadoSeleccionado] = useState(false)
    const [ObraSeleccionada, setObraSeleccionada] = useState(false)
    const [MaterlSeleccionado, setMaterSeleccionado] = useState(false);
    const [Clientes, setClientes] = useState([]);
    const [Error, setError] = useState({});
    const [TMsSeleccionados, setTMsSeleccionados] = useState([]);
    const [TMSelec, setTMSelec] = useState(null);
    const [Cantidad, setCantidad] = useState(0);
    const [ModalCantidad, setModalCantidad] = useState(false);
    const [Loading, setLoading] = useState(null);
    const [EntregarA, setEntregarA] = useState("")


    const validarCantidad = () => {
        let mensaje = "";
        if (Cantidad <=0) {
            mensaje = "La Cantidad debe ser mayor a 0!"
            return {res:false, mensaje}
        }
        if (Cantidad > TMSelec.cantidad) {
            mensaje = `La cantidad a entregar no puede ser mayor a la cantidad de tipo de material en stock (${TMSelec.cantidad})`
            return {res:false, mensaje}
        }
        return {res:true,mensaje}
    }

    const agregarTmConCantidad = () => {
        setModalCantidad(false)
        let valid = validarCantidad();
        if(valid.res === true) {
            let aux = []
            TMsSeleccionados.map(tmMap=>{
                aux.push(tmMap)
            })
            aux.push({idTipoMaterial:TMSelec.idTipoMaterial, cantidad:parseInt(Cantidad),tipoMaterial:TMSelec});
            setTMsSeleccionados(aux)
        }else{
            warningAlert(setAlert, `${valid.mensaje}`)
            setModalCantidad(true)
        }
        setCantidad(0)
    }



    const resetModal = () => {
        setNoAgregados(null)
        setCliente("")
        setEmpleadoSeleccionado(""); 
        setEmpleado("");  
        setMaterSeleccionado(false)
        setEntregarA("")
        setClientes("")
        setObra("")
    }

  return (
    <>
        {Alert}
        <LoadingComponent Visible={Loading}/>
        <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={ModalRegistrarUso}
        toggle={() => (setModalRegistrarUso(false), resetModal())}
        >
        <div>
        {NoAgregados?
        <Card>
            <CardHeader className="pb-3">
                <div className="text-center"><b>Materiales no Entregados</b></div>
            </CardHeader>
            <CardBody>
                <Table className="align-items-center table-flush" hover responsive>
                    <thead className="thead-light">
                    <tr>
                        <th>Material</th>
                        <th>Error</th>
                    </tr>
                    </thead>
                    <tbody>
                    {NoAgregados.map((mt, index) => {
                        return (
                        <tr key={index}>
                            <td className="table-user">
                            <b>{mt.material.tiposMateriale.nombre}</b>
                            </td>
                            <td>
                            {mt.mensaje}
                            </td>
                        </tr>
                        )
                    })}
                    
                    </tbody>
                </Table>
            </CardBody>  
            <CardFooter>
                <div className="text-center">
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => {resetModal();reset();}}
                >
                    Cerrar
                </Button>                    
                </div>
                </CardFooter>                
        </Card>
        :
        <Card>
            <CardHeader className="pb-3">
            <div className="text-center"><b>Registrar Uso</b>
            <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => (resetModal(), reset())}
        >
            <span aria-hidden={true}>×</span>
        </button>
            </div>
            </CardHeader>  
            <EntregarAEmpleado
            EmpleadoSeleccionado={EmpleadoSeleccionado}
            setEmpleadoSeleccionado={setEmpleadoSeleccionado}
            MaterlSeleccionado={MaterlSeleccionado}
            setMaterSeleccionado={setMaterSeleccionado}
            TMsSeleccionados={TMsSeleccionados}
            setTMsSeleccionados={setTMsSeleccionados}
            Cliente={Cliente}
            setCliente={setCliente}
            Clientes={Clientes}
            setClientes={setClientes}
            setAlert={setAlert}
            Error={Error}
            setError={setError}
            Empleado={Empleado}
            setEmpleado={setEmpleado}
            reset={reset}
            resetModal={resetModal}
            Cantidad={Cantidad}
            setCantidad={setCantidad}
            setLoading={setLoading}
            setTMSelec={setTMSelec}
            TMSelec={TMSelec}
            setModalCantidad={setModalCantidad}
            ModalCantidad={ModalCantidad}
            traerMateriales={traerMateriales}
            agregarTmConCantidad={agregarTmConCantidad}
            setNoAgregados={setNoAgregados}
            EntregarA={EntregarA}
            setEntregarA={setEntregarA}
            Obra={Obra}
            setObra={setObra}
            />
        </Card>
        }
        </div>
        </Modal>
        <ModalCantidadAEntregar ModalCantidad={ModalCantidad} 
            setModalCantidad={setModalCantidad} 
            Cantidad={Cantidad} setCantidad={setCantidad}
            agregarTmConCantidad={agregarTmConCantidad}
            TMSelec={TMSelec}
        />   
        </>
  )
}

export default ModalRegisterUso