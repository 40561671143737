import React from 'react'
import { useLocation } from 'react-router'
import { Card, CardBody, Col, Row } from 'reactstrap'
import TableMaterialesObra from './components/TableMaterialesObra'

const ObraParticular = props => {
  const idObra = props.match.params.idObra
  const location = useLocation()
  return (
    <>
    <Card className=''>
      <CardBody>
        <TableMaterialesObra idObra={idObra} nombreObra={location.state?.nombreObra}/>
      </CardBody>
    </Card>
    
    
    </>
  )
}

export default ObraParticular