import React from 'react'
import { useHistory } from 'react-router';
import { Button, Table } from 'reactstrap'

const TablePagos = (props) => {
  const {Pagos}=props;
  const history = useHistory ();
  
  const transformNumber=(number)=>{
    let nString = number.toString();

    return nString.substring(0,nString.length - 2);

  }
  const obtenerFormatoFecha = (fechaIngreso)=>{
    let fecha = new Date(fechaIngreso);
    let dia = fecha.getDate();
    let mes = fecha.getMonth();
    let ano = fecha.getFullYear();
    let fechaFormateada = `${dia}/${mes+1}/${ano}`;
    return fechaFormateada;
  };
  const verPagoEspecifico=(solicitud)=>{
    if(!solicitud.gateway){
      solicitud.gateway = 'decidir'
    }
    history.replace(`/admin/control-pagos/especifico/${solicitud.gateway}/${solicitud.id}`)
  }

  
  return (
      <>
       <Table className="align-items-center" responsive>
          <thead className="thead-light text-center">
            <tr>
              <th scope="col">ID</th>
              <th scope="col">OPERACION</th>
              <th scope="col">IMPORTE</th>
              <th scope="col">FECHA</th>
              <th scope="col">CODIGO DE BARRA</th>
            </tr>
          </thead>
          {Pagos?
          Pagos.map((pg, index)=>{
            return(

              <tbody>
                  <tr key={index}>
                    <th scope="row">
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            {pg.id}
                          </span>
                    </div>
                    </th>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            {pg.site_transaction_id}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            $ {transformNumber(pg.amount)}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                            <span className="mb-0 text-sm">
                           {obtenerFormatoFecha(pg.date)}
                            </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                            <span className="mb-0 text-sm">
                            {pg.barcode}
                            </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                            <Button className="btn-md btn-info" onClick={()=>verPagoEspecifico(pg)}>
                              Más
                            </Button>
                    </div>
                    </td>
                  </tr>
                  
                  
          </tbody>

            )
          })
          :
          <p>Cargando..</p>
          }
          
        </Table>
      </>
  )
}

export default TablePagos