import LoadingComponent from "pages/components/Loading";
import React, { useEffect, useState } from "react";
import { Card, Table } from "reactstrap";
import { getTiposMaterialesEmpleados } from "services/TiposMaterialesService";


function Materiales({idEmpls}){
  const [Materiales, setMateriales] = useState([])
  const [Visible, setVisible] = useState(false)

  const traerMateriales = async() => {
    setVisible(true)
    let materiales = await getTiposMaterialesEmpleados(null, idEmpls)
    if (materiales) {
      setMateriales(materiales.tme)
    }
    setVisible(false)
  }

  useEffect(() => {
    traerMateriales()
  }, [])
  
    return (
        <>
      <LoadingComponent Visible={Visible}/>
<Card style={{width:"100%"}} className="text-center mt-4">
  <Table responsive>
    <thead className="bg-info ">
      <th>NOMBRE</th>
      <th>CODIGO</th>
      <th>CANTIDAD</th>
    </thead>
    <tbody>
      {Materiales?
      Materiales.map((m, index)=>{
        return(
          <tr key={index}>
            <th>{m.tiposMateriale?.nombre}</th>
            <td>{m.tiposMateriale?.codigoProducto}</td>
            <td>{m.cantidad} {m.tiposMateriale?.unidad}</td>
          </tr>
        )
      })
    :
    <>
    <tr>
      <td>
        Este empleado no tiene asignado ningun material!
      </td>
    </tr>
    </>
    }
    </tbody>
  </Table>
</Card>
        </>
    )
}


export default Materiales;