import React, { useEffect, useState } from 'react'
import { Button, Col, Input, Row, Table } from 'reactstrap'
import ModalAggEgresoEfectivo from './ModalAggEgresoEfectivo'
import SelectorCategoriaEfectivo from './SelectorCategoriaEfectivo'
import SelectorEstadoEfectivo from './SelectorEstadoEfectivo'
import { getMovimientos } from 'services/MovimientosService'
import SelectorTipoCategoria from './SelectorTipoCategoria'
import Paginacion from 'pages/components/Paginacion'
import LoadingComponent from 'pages/components/Loading'
import { confirmAlert } from 'pages/components/SweetAlert'
import { putEditarMovimiento } from 'services/MovimientosService'
import { successAlert } from 'pages/components/SweetAlert'
import { warningAlert } from 'pages/components/SweetAlert'
import ModalVerFotoComponent from 'pages/components/ModalVerFoto'

const TableControlEfectivo = ({idPuntoVenta}) => {

    const [ModalAgregarEgresoEfectivo, setModalAgregarEgresoEfectivo] = useState(false)
    const [FechaInicio, setFechaInicio] = useState("")
    const [FechaFin, setFechaFin] = useState("")
    const [CategoriaEfectivo, setCategoriaEfectivo] = useState("")
    const [Estado, setEstado] = useState("")
    const [Movimientos, setMovimientos] = useState([])
    const [Tipo, setTipo] = useState('')
    const [Visible, setVisible] = useState(false)
    const [Alert, setAlert] = useState(null)
    const [ModalVerFoto, setModalVerFoto] = useState(false)
    const [Foto, setFoto] = useState('')

    const cambiarFecha = (e) => {
        setFechaInicio(e.target.value)
    }
    const cambiarFechaFin = (e) => {
        setFechaFin(e.target.value)
    }

    const traerMovimientos = async() => {
        setVisible(true)
        let params = {
            page:0,
            puntoVentaId : idPuntoVenta,
            controlado : Estado,
            tipo: Tipo,
            categoriaId: CategoriaEfectivo,
            fechaInicio: FechaInicio,
            fechaFin: FechaFin
        }
        let mov = await getMovimientos(params)
        if (mov !==null && mov !== '') {
            setMovimientos(mov.movimientos)
        }
        setVisible(false)
    }

    const obtenerFormatoFecha = (fechaIngreso)=>{
        let fecha = new Date(fechaIngreso);
        let dia = fecha.getDate();
        let mes = fecha.getMonth();
        let ano = fecha.getFullYear();
        let fechaFormateada = `${dia}/${mes+1}/${ano}`;
        return fechaFormateada;
    }

    const etiquetaControlado = (mov) => {
        return mov.controlado === true ? <Button className='btn btn-outline-success btn-sm' onClick={()=>confirmarCambiarEstado(mov)}>SI</Button> : <Button className='btn btn-outline-danger btn-sm' onClick={()=>confirmarCambiarEstado(mov)}>NO</Button>
    }

    const confirmarCambiarEstado= async(mov)=>{
        confirmAlert(setAlert, cambiarEstado, mov , `Deseas cambiar el estado de este Movimiento a ${mov.controlado === true? "No Controlado" : "Controlado"}?`)
        }
        const cambiarEstado = async(mov)=>{
        let movi = {
            id : mov.id,
            controlado : mov.controlado === true ? false : true
        }
        let result = await putEditarMovimiento(movi)
        if(result != null){
                successAlert(setAlert, "Movimiento Actualizado!", "");
                traerMovimientos()
                return
        }else{
        warningAlert(setAlert, 'No se pudo Actualizar el Movimiento')
        }
        };
    const abrirModalFoto = (img)=>{
        if (!img) {
            
            warningAlert(setAlert, 'Este Movimiento no posee comprobante actualemnte!')

        } else {

            setFoto(img);
            setModalVerFoto(true)
    
        }
    }
    useEffect(() => {
        traerMovimientos()
    }, [])
    
  return (
    <>
    {Alert}
    <LoadingComponent Visible={Visible}/>
    <Row className='b-2'>
        <Col><label>Desde:</label></Col>
        <Col><label>Hasta:</label></Col>
        <Col><label>Categoria:</label></Col>
        <Col><label>Estado</label></Col>
        <Col><label>Tipo</label></Col>
        <Col></Col>
        <Col></Col>
        <Col></Col>
    </Row>
    <Row className='mb-4'>
        <Col><Input type='date' value={FechaInicio} onChange={cambiarFecha}/></Col>
        <Col><Input type='date' value={FechaFin} onChange={cambiarFechaFin}/></Col>
        <Col><SelectorCategoriaEfectivo CategoriaEfectivo={CategoriaEfectivo} setCategoriaEfectivo={setCategoriaEfectivo}/></Col>
        <Col><SelectorEstadoEfectivo Estado={Estado} setEstado={setEstado}/></Col>
        <Col><SelectorTipoCategoria Tipo={Tipo} setTipo={setTipo}/></Col>
        <Col><Button className='btn btn-outline-info btn-md' onClick={()=>traerMovimientos()}>Filtrar</Button></Col>
        <Col></Col>
        <Col><Button className='btn btn-md btn-outline-info' onClick={()=>setModalAgregarEgresoEfectivo(true)}>Agregar</Button></Col>
    </Row>
    <Table responsive className='text-center'>
        <thead className='bg-info'>
            <tr>
                <th>FECHA</th>
                <th>CONTROLADO</th>
                <th>EMISOR</th>
                <th>RECEPTOR</th>
                <th>FACTURA</th>
                <th>CATEGORIA</th>
                <th>CONCEPTO</th>
                <th>TIPO</th>
                <th>IMPORTE</th>
                <th>SALDO</th>
                <th>COMPROBANTE</th>
            </tr>
        </thead>
        <tbody>
            {Movimientos.length?
            Movimientos.map(m=>{
                return(
                    <tr key={m.id}>
                        <th>{obtenerFormatoFecha(m.fecha)}</th>
                        <td>{etiquetaControlado(m)}</td>
                        <td>{m.emisor}</td>
                        <td>{m.receptor}</td>
                        <td>{m.nroOperacion}</td>
                        <td>{m.categoriasMovimiento?.nombre}</td>
                        <td>{m.concepto}</td>
                        <td>{m.tipo}</td>
                        <td>{m.importe}</td>
                        <td>{m.saldo}</td>
                        <td><Button className='btn btn-sm btn-outline-success' onClick={()=>{abrirModalFoto(m.comprobante?.archivo)}}>{m.comprobante === null ? 'Sin comprobante' : 'Ver'}</Button></td>
                        {/* <td><a href={m.comprobante?.archivo} download><Button className='btn btn-sm btn-outline-success' >Descargar</Button></a></td> */}
                    </tr>
                )
            })
        :
        <>
            <tr>
                <td>
                    No se encontraron registros de movimientos!
                </td>
            </tr>
        </>}
            
        </tbody>
    </Table>
    <Paginacion traerObjetos={traerMovimientos} setObjetos={setMovimientos} getObjetos={getMovimientos}/>
    <ModalAggEgresoEfectivo 
    ModalAgregarEgresoEfectivo={ModalAgregarEgresoEfectivo}
    setModalAgregarEgresoEfectivo={setModalAgregarEgresoEfectivo}
    idPuntoVenta={idPuntoVenta}
    traerMovimientos={traerMovimientos}
    />
    <ModalVerFotoComponent ModalVerFoto={ModalVerFoto} setModalVerFoto={setModalVerFoto} foto={Foto}/>
    </>
  )
}

export default TableControlEfectivo