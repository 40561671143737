import React , { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  NavItem,
  NavLink,
  Nav,
  Card,
  CardBody,
} from "reactstrap";

function NavBarEspera(props) {
  const {setVista} = props
  const [estado, setState] = useState(null);

 useEffect(() => {    
  });
  
  const toggleNavs = (e, state, index) => {
    e.preventDefault();
   setState(index);
  };
  return (
    <>
     <Card className=" border-0 mb-0 " style={{ width:"100%"}}>
       <CardBody>
      <Nav
          className="nav-fill flex-column flex-sm-row nav-pills"
          id="tabs-text"
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={estado === 1}
              className={"mb-sm-3 mb-md-0"}
              active={ estado===1}
              onClick={e => {toggleNavs(e, "navPills", 1); setVista("EnEspera")}}
              role="tab"
            >
              En Espera
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={estado === 2}
              active={ estado===2}
              className={"mb-sm-3 mb-md-0"}
              onClick={e => {toggleNavs(e, "navPills", 2); setVista("Mapa")}}
              role="tab"
            >
              Mapa
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={estado === 3}
              active={ estado===3}
              className={"mb-sm-3 mb-md-0"}
              onClick={e => {toggleNavs(e, "navPills", 3); setVista("Grafico")}}
              role="tab"
            >
              Gráfico
            </NavLink>
          </NavItem>
        </Nav>
        </CardBody>
        </Card>
    </>
  );
}

NavBarEspera.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
NavBarEspera.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default NavBarEspera;
