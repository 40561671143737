import React from 'react'
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap'

export default function ModalEditar(props){
    const {ModalEditarE, setModalEditarE} = props;

    return(
      <>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={ModalEditarE}
          toggle={() => setModalEditarE(false)}
        >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-center"><b>Cambio de Equipo</b>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalEditarE(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
              </div>
              <div className="text-muted mt-2 mb-3">
              <Form role="form">
                <FormGroup className="mb-3">MacAddress:
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      <i className="fas fa-satellite-dish"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="macAddress..." type="text"  />
                    {/* onChange={cambiarMacaddress} value={Macaddress} */}
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">Usuario:
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      <i class="fas fa-user"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Usuario..." type="text"  />
                    {/* onChange={cambiarMacaddress} value={Macaddress} */}
                  </InputGroup>
                </FormGroup>
                
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    // onClick={()=>agregarEquipo()}
                  >
                    Agregar
                  </Button>
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalEditarE(false)}
                  >
                    Cerrar
                  </Button>                    
                </div>
              </Form>                     
              </div>                    
            </CardHeader>                  
          </Card>
        </div>
        </Modal>
      </>
    )
}