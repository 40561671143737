import React, { useEffect, useState } from 'react'
import BarcodeScanner from 'pages/components/BarcodeScanner';
import {getEquipos,putEquipoEm} from '../../../../services/EquiposService';
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import { GetAuthUser } from 'services/AuthService';
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap';
export default function ModalEntregarEquipos(props) {
    const {EntregarEquiposModal,setModalEntregarEquipos,setAlertS,setEquipos,empleadoId} = props;
    const [mac,setMac] = useState("");
  


  const EntregarEquipo = async () => {
      let data = {
        macAddress:mac,
        empleadoId
      }

      let eq = await putEquipoEm(data);
      if(eq.ok){
        setEquipos(eqs => {
          let data = [...eqs];
          data.push(eq.equipo);
          setEquipos(data);

        })
        successAlert(setAlertS, "Equipo entregado");
       
      }
      else{
        warningAlert(setAlertS,eq.mensaje);
      }
  }





  

  const cambiarMac = event => {
    setMac(event.target.value);
  };
    

  return (
    <>
    
         <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={EntregarEquiposModal}
            toggle={() => setModalEntregarEquipos(false)}
            >
            <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                <div className="text-center"><b>Entregar Equipos</b>
                <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setModalEntregarEquipos(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
                                </div>
                                <div className="text-muted mt-2 mb-3">
                                <Form role="form">
                                <FormGroup value={mac} onChange={cambiarMac}> Ingresa la mac:
                                    <InputGroup className="input-group-alternative">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                        
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input placeholder="Nombre..." type="text" />
                                    </InputGroup>
                                  </FormGroup>
                                 
                                
                            
                              
                            
                                  <div className="text-center">
                                    <Button
                                      className="my-4"
                                      color="primary"
                                      type="button"
                                      onClick={()=>EntregarEquipo()}
                                    >
                                      Agregar
                                    </Button>
                                                 
                                  </div>
                                </Form>                     
                                </div>                    
                              </CardHeader>                  
                            </Card>
                          </div>
                          </Modal>
    
    


        {/** Este componente se encarga de hagarrar todos los elmentos que ese scanean */}
        
        {
          /*
            <BarcodeScanner setEquipos={setEquipos}></BarcodeScanner>  
          */
        }
        
    </>
  )
}
