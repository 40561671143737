
import LoadingComponent from 'pages/components/Loading'
import { confirmAlert } from 'pages/components/SweetAlert'
import { warningAlert } from 'pages/components/SweetAlert'
import { successAlert } from 'pages/components/SweetAlert'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap'
import { getRolPermisos } from 'services/RolService'
import { deleteTMA } from 'services/TiposMaterialesService'
import { getTiposMaterialesAlmacenes } from 'services/TiposMaterialesService'
import BuscadorStock from './components/BuscadorStock'
import ModalAggStock from './components/ModalAggStock'
import ModalAggTipoMaterial from './components/ModalAggTipoMaterial'
import ModalCantidadAEntregar from './components/ModalCantidadAEntregar'
import ModalEditMaterial from './components/ModalEditMaterial'
import ModalEditStock from './components/ModalEditStock'
import ModalMaterialEntregar from './components/ModalMaterialEntregar'
import ModalRecibir from './components/ModalRecibir'
import ModalRegisterRetiro from './components/modalRegistrarRetiro/ModalRegisterRetiro'
import ModalRegisterUso from './components/modalRegistrarUso/ModalRegisterUso'
import PaginacionStock from './components/PaginacionStock'
import { getAlmacenes } from 'services/AlmacenesService'
import moment from 'moment'
const XLSX = require('xlsx');


const Stock = props => {
    const history = useHistory()
    const idAlmacen = props.match.params.idAlmacen;
    const [Materiales, setMateriales] = useState([]);
    const [Alert, setAlert] = useState(null);
    const [ModalAgregarStock, setModalAgregarStock] = useState(false);
    const [ModalAgregarTipoMaterial, setModalAgregarTipoMaterial] = useState(false);
    const [ModalRecibirMaterial, setModalRecibirMaterial] = useState(false);
    const [ModalEntregarMaterial, setModalEntregarMaterial] = useState(false);
    const [Visible, setVisible] = useState(false);
    const [ModalRegistrarUso, setModalRegistrarUso] = useState(false);
    const [ModalRegistrarRetiro, setModalRegistrarRetiro] = useState(false)
    const [ModalEditarMaterial, setModalEditarMaterial] = useState(false);
    const [ModalEditarStock, setModalEditarStock] = useState(false)
    const [MaterialSeleccionado, setMaterialSeleccionado] = useState(null)
    const [Permisos, setPermisos] = useState([]);
    const [TMsSeleccionados, setTMsSeleccionados] = useState([]);
    const [TMSelec, setTMSelec] = useState(null);
    const [Cantidad, setCantidad] = useState(0);
    const [ModalCantidad, setModalCantidad] = useState(false);
    const [NoEntregados, setNoEntregados] = useState(null)
    const [NoAgregados, setNoAgregados] = useState(null)
    const [Almacenes, setAlmacenes] = useState([])

    const traerPermisos = async () => {
        setVisible(true)
        let permisos = await getRolPermisos();
        if (permisos !== null && permisos !== '') {
            setPermisos(permisos)
        }
        setVisible(false)
    }
    const tienePermiso = (nombrePermiso) => {
        return Permisos.includes(nombrePermiso);
    }

    const traerMateriales = async () => {
        setVisible(true)
        let materiales = await getTiposMaterialesAlmacenes({ page: 1, limit: 10, idAlmacen: idAlmacen });
        if (materiales !== null) {
            setMateriales(materiales.tma)
        }
        setVisible(false)
    }
    const editarTipoMaterial = (tipo) => {
        setMaterialSeleccionado(tipo)
        setModalEditarMaterial(true)
    }
    const editarStock = (tipo) => {
        setMaterialSeleccionado(tipo)
        setModalEditarStock(true)
    }

    const confirmarEliminar = async (m) => {
        let msj = `${m.tiposMateriale?.nombre} ¿Está seguro que desea eliminar el tipo de material del almacen?`;
        let id = m.idTipoMaterial
        confirmAlert(setAlert, eliminarTipoMaterialAlmacen, id, msj)
    }

    const eliminarTipoMaterialAlmacen = async (idTipoMaterial) => {
        let idalmc = parseInt(idAlmacen)
        let params = { idAlmacen: idalmc, idTipoMaterial };

        let res = await deleteTMA(params);
        if (res !== null) {
            if (res.ok) {
                successAlert(setAlert, res.mensaje)
                await traerMateriales();
            } else {
                warningAlert(setAlert, res.mensaje)
            }
        }
    }

    const irHistorico = (id, nombre) => {
        history.replace({
            pathname: '/admin/historico',
            state: {
                tipoMaterialId: id,
                nombre: nombre,
                idAlmacen: idAlmacen
            }
        })
    }
    const irMateriales = (id) => {
        history.replace({
            pathname: `/admin/stockEmpleado/${id}`,
            state: {
                idAlmacen: idAlmacen
            }
        })
    }


    const reset = () => {
        setNoEntregados(null)
        setCantidad(0);
        setTMsSeleccionados([]);
        setTMSelec(null);
        setModalEntregarMaterial(false);
        setModalAgregarStock(false);
        setModalRegistrarUso(false)
    }

    const validarCantidad = () => {
        let mensaje = "";
        if (Cantidad <= 0) {
            mensaje = "La Cantidad debe ser mayor a 0!"
            return { res: false, mensaje }
        }
        if (Cantidad > TMSelec.cantidad) {
            mensaje = `La cantidad a entregar no puede ser mayor a la cantidad de tipo de material en stock (${TMSelec.cantidad})`
            return { res: false, mensaje }
        }
        return { res: true, mensaje }
    }

    const agregarTmConCantidad = () => {
        setModalCantidad(false)
        let valid = validarCantidad();
        if (/*valid.res === true*/true) {
            let aux = []
            TMsSeleccionados.map(tmMap => {
                aux.push(tmMap)
            })
            aux.push({ idTipoMaterial: TMSelec.idTipoMaterial, cantidad: parseInt(Cantidad), tipoMaterial: TMSelec });
            setTMsSeleccionados(aux)
        } else {
            warningAlert(setAlert, `${valid.mensaje}`)
            setModalCantidad(true)
        }
        setCantidad(0)
    }

    const includeTM = (id) => {
        let aux = false;
        TMsSeleccionados.map(tm => {
            if (tm.idTipoMaterial == id) {
                aux = true
            }
        })
        return aux
    }

    const actualizaCheck = (tm) => {
        let resInclude = includeTM(tm.idTipoMaterial)
        if (resInclude) {
            let aux = []
            TMsSeleccionados.map(tmMap => {
                if (tmMap.idTipoMaterial != tm.idTipoMaterial) {
                    aux.push(tmMap)
                }
            })
            setTMsSeleccionados(aux)
        } else {
            setTMSelec(tm)
            setModalCantidad(true)
        }
    }


    const confirmarExportacion = async () => {
        confirmAlert(setAlert, exportTableToExcel, null, "Expotar a Excel?", "Se exportaran todos los materiales de este almacen")
    }



    const exportTableToExcel = async (tableID, filename = '') => {
        // Crea un array de datos de ejemplo
        setVisible(true)
        const data = [["Tipo Material", "Codigo Porducto", "Stock Minimo", "Cantidad Stock", "Cantidad en Empleados", "Total", "Unidad"]];

        let mat = { tma: [1] }; //lo inicializo con este calor para poder utilizar la letiable

        for (let i = 1; mat.tma.length > 0; i++) {
            mat = await getTiposMaterialesAlmacenes({ page: i, limit: 10, idAlmacen: idAlmacen });

            for (let index = 0; index < mat.tma?.length; index++) {
                let mtrls = mat.tma[index]
                let material = [mtrls.tiposMateriale?.nombre, mtrls.tiposMateriale?.codigoProducto, mtrls.stockMinimo, mtrls.cantidad, mtrls.tiposMateriale?.cantEmpleados, mtrls.tiposMateriale?.total, mtrls.tiposMateriale?.unidad]

                data.push(material)
            }
        }

        // Crea un objeto Workbook de SheetJS
        // Crea un objeto Workbook de SheetJS
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(data);

        // Agrega la hoja de cálculo al libro de trabajo
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja 1');

        // Convierte el objeto Workbook a un archivo Excel en formato XLSX
        let blob = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Crea un objeto Blob
        let blobObject = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Crea un objeto URL para el Blob
        let url = URL.createObjectURL(blobObject);

        // Crea un enlace de descarga y establece su atributo "href" para el objeto URL
        let a = document.createElement("a");
        a.href = url;
        a.download = `Stock-${nombreAlmacen()}-(${obtenerFormatoFecha(moment().format())})`;

        // Simula un clic en el enlace para iniciar la descarga
        a.click();

        // Limpia el objeto URL creado
        URL.revokeObjectURL(url);
        setVisible(false)
    }
    const obtenerFormatoFecha = (fechaIngreso)=>{
        let fecha = new Date(fechaIngreso);
        let dia = fecha.getDate();
        let mes = fecha.getMonth();
        let ano = fecha.getFullYear();
        let fechaFormateada = `${dia}/${mes+1}/${ano}`;
        return fechaFormateada;
    }

    const irAlmacenes = () => {
        history.replace({
            pathname: `/admin/almacenes`
        })
    }
    const traerAlmacenes = async() => {
        let alm = await getAlmacenes();
        if (alm) {
            setAlmacenes(alm.almacenes)
        }
    }

    const nombreAlmacen = () => {
        let nombre = ''
        for (let i = 0; i < Almacenes.length; i++) {
            if (Almacenes[i].id == idAlmacen) {
                nombre= Almacenes[i].nombre
            }
            
        }
        return nombre
    }
    useEffect(() => {
        traerMateriales();
        traerPermisos();
        traerAlmacenes()
    }, [])

    return (
        <>
            {Alert}
            <LoadingComponent Visible={Visible} />
            <Card>
                <CardHeader className='mt-0'>
                    <Row className='ml-4 mt-4 mb-0 mr-4 bg-secondary ' style={{ height: "4rem" }} >
                        <Col className='d-flex  flex-row align-items-center'>
                            <Button className='btn btn-outline-default btn-md mr-5' onClick={() => irAlmacenes()}><i className='fas fa-arrow-left'></i></Button>
                            <BuscadorStock setMateriales={setMateriales} getTiposMaterialesAlmacenes={getTiposMaterialesAlmacenes} traerMateriales={traerMateriales} AlmacenId={idAlmacen} />
                        </Col>
                        <Col className='d-flex align-items-center justify-content-around'>
                            <h1>{nombreAlmacen()}</h1>
                            <Button className='btn-md btn-outline-info' onClick={() => confirmarExportacion()}>Exportar a Excel</Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Table responsive id='tblData' >
                        <thead >
                            <tr className='text-center' >
                                <th><Button className='btn-md btn-outline-info' onClick={() => setModalEntregarMaterial(true)}>Entregar Material</Button></th>
                                <th><Button className='btn-md btn-outline-info' onClick={() => setModalAgregarTipoMaterial(true)}>Agregar Tipo de Material</Button></th>
                                <th><Button className='btn-md btn-outline-info' onClick={() => setModalAgregarStock(true)}>Agregar Stock</Button></th>
                                <th><Button className='btn-md btn-outline-info' onClick={() => setModalRecibirMaterial(true)}>Recibir Material</Button></th>
                                <th><Button className='btn-md btn-outline-info' onClick={() => setModalRegistrarUso(true)}>Registrar Uso</Button></th>
                                <th><Button className='btn-md btn-outline-info' onClick={() => setModalRegistrarRetiro(true)}>Registrar Retiro</Button></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <thead className='bg-info'>
                            <tr className='text-center'>
                                <th>{TMsSeleccionados && TMsSeleccionados.length > 0 ?
                                    <Button color="default" size="sm" type="button" onClick={() => { reset() }}>
                                        Deseleccionar todos
                                    </Button>
                                    :
                                    <></>
                                }</th>
                                <th>Tipo de Material</th>
                                <th>Codigo Producto</th>
                                <th>Stock Minimo</th>
                                <th>Cantidad Stock</th>
                                <th>Cantidad en Empleados</th>
                                <th>Total</th>
                                <th>Unidad</th>
                                {Permisos && tienePermiso('Editar-Tipos-Materiales') ?
                                    <>
                                        <th>Editar</th>
                                    </>
                                    :
                                    <></>
                                }
                                {Permisos && tienePermiso('Editar-Stock-Material') ?
                                    <>
                                        <th>Editar Stock</th>
                                    </>
                                    :
                                    <></>
                                }
                                {Permisos && tienePermiso('Eliminar-Tipos-Materiales') ?
                                    <>
                                        <th>Eliminar</th>
                                    </>
                                    :
                                    <></>
                                }
                            </tr >
                        </thead>
                        <tbody>
                            {Materiales.length ?
                                Materiales.map((m, index) => {
                                    return (
                                        <tr key={index} className='text-center'>
                                            <th className='d-flex justify-content-center'>
                                                <label className="custom-toggle">
                                                    <input type="checkbox" onChange={() => actualizaCheck(m)} checked={includeTM(m.idTipoMaterial)} />
                                                    <span
                                                        className="custom-toggle-slider rounded-circle"
                                                        data-label-off="No"
                                                        data-label-on="Si"
                                                    />
                                                </label>
                                            </th>
                                            <td>
                                                <Button style={{ width: "100%" }} className="btn btn-outline-default rounded px-2 py-1" onClick={() => irHistorico(m.idTipoMaterial, m.tiposMateriale?.nombre)}>{m.tiposMateriale?.nombre}</Button>
                                            </td>
                                            <td>
                                                {m.tiposMateriale?.codigoProducto}
                                            </td>
                                            <td >
                                                {m.stockMinimo}
                                            </td>
                                            <td>
                                                {m.cantidad <= m.stockMinimo ?
                                                    <Button className='btn-sm btn-outline-warning py-0' >{m.cantidad}!</Button>
                                                    :
                                                    <>{m.cantidad}</>

                                                }
                                            </td>
                                            <td>
                                                <Button className='btn-sm btn-outline-success' onClick={() => irMateriales(m.idTipoMaterial)}>{m.tiposMateriale?.cantEmpleados}</Button>
                                            </td>
                                            <td>
                                                {m.tiposMateriale?.total}
                                            </td>
                                            <td>
                                                {m.tiposMateriale?.unidad}
                                            </td>
                                            {Permisos && tienePermiso('Editar-Tipos-Materiales') ?
                                                <>
                                                    <td>
                                                        <Button className='btn-sm btn-outline-success' onClick={() => editarTipoMaterial(m)}>Editar</Button>
                                                    </td>
                                                </>
                                                :
                                                <></>
                                            }
                                            {Permisos && tienePermiso('Editar-Stock-Material') ?
                                                <>
                                                    <td>
                                                        <Button className='btn-sm btn-outline-success' onClick={() => editarStock(m)}>Editar stock</Button>
                                                    </td>
                                                </>
                                                :
                                                <></>
                                            }
                                            {Permisos && tienePermiso('Eliminar-Tipos-Materiales') ?
                                                <>
                                                    <td>
                                                        <Button className='btn-sm btn-outline-danger' onClick={() => confirmarEliminar(m)}>Eliminar</Button>
                                                    </td>
                                                </>
                                                :
                                                <></>
                                            }
                                        </tr>
                                    )
                                })
                                :
                                <>
                                    <tr>
                                        <th>
                                            No se han encontrado ese tipo de Materiales en este Almacen!
                                        </th>
                                    </tr>
                                </>}

                        </tbody>
                    </Table>
                    <PaginacionStock traerObjetos={traerMateriales} setObjetos={setMateriales} getObjetos={getTiposMaterialesAlmacenes} idAlmacen={idAlmacen} />
                </CardBody>
            </Card>
            <ModalAggStock
                ModalAgregarStock={ModalAgregarStock}
                setModalAgregarStock={setModalAgregarStock}
                Materiales={TMsSeleccionados}
                traerMateriales={traerMateriales}
                reset={reset}
                idAlmacen={idAlmacen}
                NoAgregados={NoEntregados}
                setNoAgregados={setNoEntregados}
            />
            <ModalAggTipoMaterial
                ModalAgregarTipoMaterial={ModalAgregarTipoMaterial}
                setModalAgregarTipoMaterial={setModalAgregarTipoMaterial}
                traerMateriales={traerMateriales}
                idAlmacen={idAlmacen}
                setMateriales={setMateriales}
                Materiales={Materiales}
            />
            <ModalRecibir
                ModalRecibirMaterial={ModalRecibirMaterial}
                setModalRecibirMaterial={setModalRecibirMaterial}
                traerMateriales={traerMateriales}
                idAlmacen={idAlmacen}
            />
            <ModalMaterialEntregar
                ModalEntregarMaterial={ModalEntregarMaterial}
                setModalEntregarMaterial={setModalEntregarMaterial}
                Materiales={TMsSeleccionados}
                traerMateriales={traerMateriales}
                reset={reset}
                idAlmacen={idAlmacen}
                NoEntregados={NoEntregados}
                setNoEntregados={setNoEntregados}
            />
            <ModalCantidadAEntregar ModalCantidad={ModalCantidad}
                setModalCantidad={setModalCantidad}
                Cantidad={Cantidad} setCantidad={setCantidad}
                agregarTmConCantidad={agregarTmConCantidad}
                TMSelec={TMSelec}
            />
            <ModalRegisterUso
                ModalRegistrarUso={ModalRegistrarUso}
                setModalRegistrarUso={setModalRegistrarUso}
                Materiales={Materiales}
                traerMateriales={traerMateriales}
                NoAgregados={NoAgregados} setNoAgregados={setNoAgregados}
                reset={reset}
            />
            <ModalRegisterRetiro
                ModalRegistrarRetiro={ModalRegistrarRetiro}
                setModalRegistrarRetiro={setModalRegistrarRetiro}
                Materiales={Materiales}
                traerMateriales={traerMateriales}
            />
            {MaterialSeleccionado !== null ?
                <ModalEditMaterial
                    MaterialSeleccionado={MaterialSeleccionado}
                    ModalEditarMaterial={ModalEditarMaterial}
                    setModalEditarMaterial={setModalEditarMaterial}
                    traerMateriales={traerMateriales}
                />
                :
                <></>}
            {MaterialSeleccionado !== null ?
                <ModalEditStock
                    MaterialSeleccionado={MaterialSeleccionado}
                    ModalEditarStock={ModalEditarStock}
                    setModalEditarStock={setModalEditarStock}
                    traerMateriales={traerMateriales}
                    idAlmacen={idAlmacen}
                />
                :
                <></>}
        </>
    )
}

export default Stock