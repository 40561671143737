import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { getById } from 'services/DecidirService';
import { buscarSolicitud360 } from 'services/Pagos360Service';
import ModalAsignarD from '../components/ModalAsignarD';
import ModalAsignarP from '../components/ModalAsignarP';
import TablePagoEspe from '../components/TablePagoEspecifico';

const ControlPagoEspecifico = props => {
    
    const [ModalAsignarPagoP, setModalAsignarPagoP] = useState(false)
    const [ModalAsignarPagoD, setModalAsignarPagoD] = useState(false)
    const [PagoPar, setPagoPar] = useState(null);
    const history = useHistory ();
    const [Metodo, setMetodo] = useState(null);
    
    const obtenerFormatoFecha = (fechaIngreso)=>{
        let fecha = new Date(fechaIngreso);
        let dia = fecha.getDate();
        let mes = fecha.getMonth();
        let ano = fecha.getFullYear();
        let fechaFormateada = `${dia}/${mes+1}/${ano}`;
        return fechaFormateada;
    }
    
    
    const traerCPago = async() => {
        let idPago=props.match.params.idPago;
        let metodo=props.match.params.Metodo;
        setMetodo(metodo)
        if (metodo=='decidir') {
            buscarSolicitudDecidir(idPago)
        } else  {
            buscarSolicitudPagos360(idPago)
        }
    }

    const buscarSolicitudDecidir=async(id)=>{
        let data= await getById(null, id);
        setPagoPar(data)
    }
    const buscarSolicitudPagos360=async(id)=>{
        let data= await buscarSolicitud360(null, id)
        if (data != null && data != '') {
        setPagoPar(trasformSolicitud(data))
    }
        
        
        
    }
    const trasformSolicitud=(solicitud)=>{
        let p= {
          id:solicitud.id,
          date:solicitud.created_at,
          amount:solicitud.first_total,
          request_result:solicitud.request_result,
          barcode:solicitud.barcode,
          channel:solicitud.channel
        }
        return p;   
    }
    const transformNumber=(number)=>{
        let nString = number.toString();
    
        return nString.substring(0,nString.length - 2);
    
    }
      
    const Atras=() =>{
        history.replace(`/admin/controlPagos`)
    }

    useEffect(() => {
    traerCPago();
    }, [])
  return (
      <>
      <Card>
          <CardHeader>
              <Row>
                  <Col>
                  <Row>
                      <Button className="btn-md btn-outline-success" onClick={()=>Atras()}>
                        Atras
                      </Button>
                      {Metodo=="pagos360"?
                      <Button className="btn-md btn-outline-success" onClick={()=> setModalAsignarPagoP(true)}>
                      Asignar Pago
                    </Button>
                      :
                      <Button className="btn-md btn-outline-success" onClick={()=> setModalAsignarPagoD(true)}>
                        Asignar Pago
                      </Button>
                      }
                      
                  </Row>
                  </Col>
              </Row>
          </CardHeader>
          <CardBody>
              {PagoPar?
                <>
                    {Metodo=="pagos360"?
                        <Row>
                            <Col>
                            <Row className='mt-4 ml-9'><b className='mr-1'>ID: </b>  {PagoPar.id}</Row>
                            <Row className='mt-4 ml-9'><b className='mr-1'>Importe:</b> $ {PagoPar.amount}</Row>
                            <Row className='mt-4 ml-9'><b className='mr-1'>Fecha:</b> {obtenerFormatoFecha(PagoPar.date)}</Row>
                            </Col>
                            <Col>
                            <Row className='mt-4 mr-9'><b className='mr-1'>Código de Barra:</b> {PagoPar.barcode}</Row>
                                <Row></Row>
                            </Col>
                        </Row>
                    :
                        <Row>
                            <Col>
                            <Row className='mt-4 ml-9'><b className='mr-1'>ID: </b>  
                            {PagoPar.data?.id}</Row>
                            <Row className='mt-4 ml-9'><b className='mr-1'>Importe:</b> $ {transformNumber(PagoPar.data?.amount)}</Row>
                            <Row className='mt-4 ml-9'><b className='mr-1'>Fecha:</b> {obtenerFormatoFecha(PagoPar.data?.date)}</Row>
                            </Col>
                            <Col>
                            <Row className='mt-4 mr-9'><b className='mr-1'>Código de Barra:</b> {PagoPar.data?.barcode}</Row>
                            <Row className='mt-4 mr-9'><b className='mr-1'>Código de Operación:</b> {PagoPar.data?.site_transaction_id}</Row>
                            <Row></Row>
                            </Col>
                        </Row>
                        
                    }
                    {Metodo=="pagos360"?
                    <ModalAsignarP
                    ModalAsignarPago={ModalAsignarPagoP}
                    setModalAsignarPago={setModalAsignarPagoP}
                    idPago={PagoPar.id}
                    Metodo={Metodo}
                    />
                    :
                    <ModalAsignarD
                    ModalAsignarPago={ModalAsignarPagoD}
                    setModalAsignarPago={setModalAsignarPagoD}
                    idPago={PagoPar.data?.id}
                    Metodo={Metodo}
                    />
                    }  
                    
                </>
              :
              <>
              -
              </>}
              <TablePagoEspe Pago={PagoPar}/>
          </CardBody>
      </Card>
      
      
      </>
  )
}


export default ControlPagoEspecifico