import { array } from 'prop-types';
import React, { useEffect, useState } from 'react'
import { Button, Media, Table } from 'reactstrap';
import { deleteEquipo } from 'services/EquiposService';
import { getRolPermisos } from 'services/RolService';
import { confirmAlert, successAlert, warningAlert } from "../../../components/SweetAlert";

export default function TableEquipos(props){
    const {Equipos, traerEquipos,EquiposSeleccionados, setEquiposSeleccionados, setModalEntregarEquipo} = props
    const [alertSweet, setAlert] = useState(null);
    const [ArrayEqErrores, setArrayEqErrores] = useState([])
    const [Permisos, setPermisos] = useState([]);

    const actualizaCheck = (equipo) => {
        setEquiposSeleccionados(equiposAnt => {
          if(equiposAnt.includes(equipo.id)){
            let aux = []
            equiposAnt.map(id=>{
              if(id != equipo.id){
                aux.push(id)
              }
            })
            setEquiposSeleccionados(aux)
          }
          else{
            equiposAnt.push(equipo.id);
            setEquiposSeleccionados(equiposAnt)
          }
        })
      }

      const eliminarEquipo = async(id=null)=>{
          if(id){
              let remEq = await deleteEquipo(id);
              if(remEq){traerEquipos();successAlert(setAlert, "Equipo eliminado correctamente")}
          }else{
              if(EquiposSeleccionados.length != 0){
                  EquiposSeleccionados.map(async(idEq)=>{
                      let remEq = await deleteEquipo(idEq);
                      if(!remEq){
                          setArrayEqErrores(equiposAnt => {
                              equiposAnt.push(idEq);
                              setArrayEqErrores(equiposAnt)
                          })
                      }
                  })
                  if(ArrayEqErrores.length == 0){
                    traerEquipos();
                    successAlert(setAlert, "Equipos eliminados correctamente")  
                  }else{
                    let eqs = ""
                    ArrayEqErrores.map((eq)=>{
                        eqs += `- ${eq}\n`
                    })
                    warningAlert(setAlert, "Los equipos con los siguientes ids no se eliminaron:", eqs)
                  }
              }
          }
      }

    const confirmarEliminacion = (idEquip=null) => {
        if (idEquip) {
            confirmAlert(setAlert, eliminarEquipo, idEquip, "¿Seguro que desea eliminar el equipo?")
        } else {
            confirmAlert(setAlert, eliminarEquipo, null, "¿Seguro que desea eliminar los equipos seleccionados?")
        }
    }
    const traerPermisos= async()=>{

        let permisos= await getRolPermisos();
        if(permisos !=null && permisos!=''){
          setPermisos(permisos)
        }
    }
    const tienePermiso=(nombrePermiso)=>{
        return Permisos.includes(nombrePermiso);
    }
    useEffect(() => {
        traerPermisos();
      }, [])

    return(
        <>
            {alertSweet}
            <Table className="align-items-center" responsive>
                <thead className="thead-light">
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">MACADRESS</th>
                    <th scope="col">CATEGORIA/NOMBRE</th>
                    <th scope="col">ESTADO DEL EQUIPO</th>
                    <th scope="col">EMPLEADO</th>
                    <th scope="col">CLIENTE ID</th>
                    <th scope="col">SELECCIONAR</th>
                    {Permisos && tienePermiso('Eliminar-Equipos')?
                    <th scope="col">
                        <div className="d-flex align-items-center">
                            <Button className="btn-icon btn-3 btn-sm btn-outline-danger" color="danger" type="button" onClick={()=>confirmarEliminacion()}>
                                    <span className="btn-inner--icon">
                                        Eliminar Equipos
                                    </span>
                            </Button>
                        </div>
                    </th>
                    :
                    <></>
                  }
                  {Permisos && tienePermiso('Editar-Equipos')?
                    <th scope="col">
                        <div className="d-flex align-items-center">
                            <Button className="btn-icon btn-3 btn-sm btn-outline-info" color="primary" type="button" onClick={()=>setModalEntregarEquipo(true)}>
                                <span className="btn-inner--icon">
                                    Entregar Equipos
                                </span>
                            </Button>
                        </div>
                    </th>
                    :
                    <></>
                  }
                </tr>
                </thead>
                <tbody>
                {Equipos?
                Equipos.map((eq, index)=>{
                    return (
                    <tr key={index}>
                        <td>
                            <Media className="align-items-center">
                                <span className="mb-0 text-sm">
                                    {eq.id}
                                </span>
                            </Media>
                        </td>
                        <td>
                        <Media>
                                <span className="mb-0 text-sm">
                                    {eq.macAddress}
                                </span>
                        </Media>
                        </td>
                        <td>
                        <Media>
                                <span className="mb-0 text-sm">
                                    {eq.categoria?.nombre
                                    }
                                </span>
                        </Media>
                        </td>
                        <td>
                        <Media>
                                <span className="mb-0 text-sm">
                                    {eq.estado?.nombre
                                    }
                                </span>
                        </Media>
                        </td>
                        <td>
                        <Media>
                                <span className="mb-0 text-sm">
                                    {eq.empleado?.nombre
                                    }
                                </span>
                        </Media>
                        </td>
                        <td>
                        <Media>
                                <span className="mb-0 text-sm">
                                    {eq.clienteId}
                                </span>
                        </Media>
                        </td>
                        <td>
                            <label className="custom-toggle">
                                <input type="checkbox"  onClick={()=>actualizaCheck(eq)}/>
                                <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Si"
                                />
                            </label>
                        </td>
                        {Permisos && tienePermiso('Eliminar-Equipos')?
                        <td className="align-items-center">
                            <div className="d-flex align-items-center">
                                <Button className="btn-icon btn-3 btn-sm btn-outline-danger rounded-circle" color="danger"  type="button" onClick={()=>{confirmarEliminacion(eq.id)}}>
                                    <span className="btn-inner--icon" style={{padding:'0rem'}}>
                                        <i style={{fontSize:'1.5rem'}} className="ni ni-fat-remove"/>
                                    </span>
                                </Button>
                            </div>
                        </td>
                        :
                        <></>
                      }
                    </tr>

                    )
                })
                :
                <p>Cargando...</p>
                }
                </tbody>
            </Table>
        </>
    )
}