
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap';
import { putZona } from 'services/ZonasServices';
import { postZonas } from 'services/ZonasServices';

const ModalAggZona = (props) => {
    const { ModalAgregarZona, setModalAgregarZona, ZonaSelect, Editar } = props;

    const [Nombre, setNombre] = useState("");
    const [Color, setColor] = useState("");
    const [Alert, setAlert] = useState(null)

    const cambiarNombre = event => {
        setNombre(event.target.value);
    };
    const cambiarColor = event => {
        setColor(event.target.value);
    };

    const AgregarZona = async () => {

        let zona = {
            nombre: Nombre.toUpperCase(),
            color: Color.split("#")[1].toUpperCase()
        }

        if (validarAggZona(zona) == true) {
            let result = await postZonas(zona)
            if (result != null) {
                if (result.ok) {
                    successAlert(setAlert, 'Zona Agregada');
                    recetearZona()
                    setModalAgregarZona(false)
                } else {
                    warningAlert(setAlert, result.mensaje)
                }
            } else {
                warningAlert(setAlert, 'No se pudo agregar la Zona')
            }
        } else {
            warningAlert(setAlert, 'Complete todos los campos!')
        }
    }

    const actualizarZona = async () => {
        let esperaAux = {

        }

        let valido = await putZona(esperaAux);

        if (valido) {
            successAlert(setAlert, 'Zona Actualizada');
            recetearZona()
            setModalAgregarZona(false);
        } else {
            warningAlert(setAlert, 'No se pudo Editar la Zona')
        }
    }

    const recetearZona = () => {
        setNombre("")
        setColor("")
    }


    const establecerZona = () => {
        if (Editar) {
            setNombre(ZonaSelect.nombre)
            setColor(ZonaSelect.color)
        }
    }
    const validarAggZona = (es) => {
        if (!es.nombre) {
            return false;
        }
        if (!es.color) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        establecerZona();
    }, [ZonaSelect])

    return (
        <>
            {Alert}
            <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={ModalAgregarZona}
                toggle={() => (setModalAgregarZona(false), recetearZona())}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent pb-5">

                            <div className="text-center">
                                {Editar ?
                                    <b>Editar Zona</b>
                                    :
                                    <b>Agregar Zona</b>
                                }
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => (setModalAgregarZona(false), recetearZona())}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <div className="mt-2 mb-3">
                                <Form role="form">
                                    <FormGroup className="mb-3" >Nombre:
                                        <InputGroup className="input-group-alternative mt-2">
                                            <Input placeholder="Nombre" type="text" value={Nombre} onChange={cambiarNombre} />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup className="mb-3" >Color:
                                        <InputGroup className="input-group-alternative mt-2">
                                            <Input type="color" value={Color} onChange={cambiarColor} />
                                        </InputGroup>
                                    </FormGroup>
                                    <div className="text-center">
                                        {Editar ?
                                            <Button
                                                className="my-4"
                                                color="primary"
                                                type="button"
                                                onClick={() => actualizarZona()}
                                            >
                                                Editar
                                            </Button>
                                            :
                                            <Button
                                                className="my-4"
                                                color="primary"
                                                type="button"
                                                onClick={() => AgregarZona()}
                                            >
                                                Agregar
                                            </Button>
                                        }


                                        <Button
                                            color="secondary"
                                            data-dismiss="modal"
                                            type="button"
                                            onClick={() => (setModalAgregarZona(false), recetearZona())}
                                        >
                                            Cerrar
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </CardHeader>
                    </Card>
                </div>
            </Modal>
        </>
    )
}


export default ModalAggZona
