import React from 'react'
import { Card, CardBody, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from "classnames";

const NavSelector = props => {
    const {Tabs, setTabs} = props;
  return (
    <>
    <Card style={{width:"100%"}}>
            <CardBody>
                <div className="nav-wrapper">
                    <Nav
                        className="nav-fill flex-column flex-md-row"
                        id="tabs-icons-text"
                        pills
                        role="tablist"
                        style={{width:"100%"}}
                    >
                        <NavItem>
                        <NavLink
                            aria-selected={Tabs === 1}
                            className={classnames("mb-sm-3 mb-md-0", {
                            active: Tabs === 1
                            })}
                            onClick={e => setTabs(1)}
                            role="tab"
                        >
                            Solicitudes
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink
                            aria-selected={Tabs === 2}
                            className={classnames("mb-sm-3 mb-md-0", {
                            active: Tabs === 2
                            })}
                            onClick={e => setTabs(2)}
                            role="tab"
                        >
                            Valores
                        </NavLink>
                        </NavItem>
                    </Nav>
                    </div>
            </CardBody>
        </Card>
    </>
  )
}

export default NavSelector