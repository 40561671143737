import React , { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getClienteEsp,findInUnms,datosServicios,updateFiscal} from "services/ClientesServices";
import '../../../assets/estilos/style.css';

// reactstrap components
import {
    Container,
    Row
  } from "reactstrap";
import GnetNavbar from "components/Navbars/GNetNavbar";
import ContenedorSubvistas from "./ContenedorSubvistas";
import LoadingComponent from "pages/components/Loading";
import { GuardInPages } from "guards/Guard";



const PerfilClientes = props => {
    const history = useHistory()

    const [loading, setLoading] = useState(true);
    const [datos, setdatos] = useState([]);
    const [planCliente, setPlanCliente] = useState([]);
    const [idCliente, setId] = useState(null);
    const [vista, setVista] = useState("General");

    //Al poner la funcion aca, esta se llama sola
    useEffect(() => {
        GuardInPages(history)
        setLoading(true);
        const unId=props.match.params.id;
        setId(unId);

        findInUnms(unId).then(datos => {
          setdatos(datos);
          
          datosServicios(unId).then(planCliente => {
            setPlanCliente(planCliente);
            setLoading(false); //fin carga de datoss
          })
        });
      }, [])

      



          return (
            <>
               <LoadingComponent Visible={loading}/>
               {!loading?
                <Container className="mt-5" fluid>
                    <Row>
                          {/* llamo a la nav con Botones varios */}
                          <GnetNavbar setVista={setVista}/>
                    </Row>
                    <Row>
                      <ContenedorSubvistas vista={vista} datos={datos} planCliente={planCliente} idClient={idCliente}/>
                    </Row>
                </Container>
                :
               <></>
               } 
            </>
           
        )

}


export default PerfilClientes