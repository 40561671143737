import React, { useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import ModalVerFotoComponent from './ModalVerFoto'

function ComentariosComponent(props) {
    const {ArrayObjs, Titulo, accionBoton}=props;
    const [ModalVerFoto, setModalVerFoto] = useState(false)
    const [Foto, setFoto] = useState('')
    const abrirModalFoto = (img)=>{
        setFoto(img);
        setModalVerFoto(true)
    }

    return (
        <>
            <Row>
                <Card className="text-center" style={{width:"100%"}}>
                    <CardHeader className="text-center border-2">
                        <Row>
                        <Col lg={11}>
                            <h3>{Titulo}</h3> 
                        </Col>
                        <Col lg={1}>
                            
                            <Button className="btn-sm mr-2 btn-success" onClick={()=>{accionBoton()}}>
                                <i className="fas fa-plus" ></i>
                            </Button>
                        </Col>
                        </Row>
                    </CardHeader>
                
                </Card>
            </Row>
                {ArrayObjs?

                    ArrayObjs.map((coment, index) => {
                        return (
                        <Row key={index}>
                            <Col>
                                <Card style={{width:"100%"}} className="text-center" >
                                    <CardHeader>
                                        <h2>{coment.titulo}</h2>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="mt-3 mb-3 text-md">
                                            <p>{coment.comentario}</p>
                                        </div> 
                                        <br/>
                                        <div>
                                            <img 
                                            onClick={()=>{abrirModalFoto(coment.archivo)}}
                                            src={coment.archivo}
                                            alt="">
                                            </img>
                                        </div>                                   
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        )
                    }
                    )
                    :
                    <>
                    </>
                
                }
            <ModalVerFotoComponent ModalVerFoto={ModalVerFoto} setModalVerFoto={setModalVerFoto} foto={Foto}/>
        </>
    );
}

export default ComentariosComponent;