import React, { useEffect, useState } from 'react'
import { Badge, Button, Table } from 'reactstrap'
import { getMateriales } from 'services/MaterialesService'
import { getTiposMaterialesEnEmpleados } from 'services/TiposMaterialesService'

const TableStockEmpleado = ({ Materiales }) => {

  
  return (
    <>
    <Table responsive>
        <thead className='bg-info'>
            <tr className='text-center'>
                <th>Tipo de Material</th>
                <th>Cantidad</th>
                <th>Empleado</th>
            </tr >
        </thead>
        <tbody>
            {Materiales.length?
            Materiales.map((m, index)=>{
                return (
                    <tr key={index} className='text-center'>
                    <th>
                        <Badge color='primary cursor-pointer' >{m.tiposMateriale?.nombre}</Badge>
                    </th>
                    <td>
                        {m.cantidad}
                    </td>
                    <td>
                        {m.empleado?.nombre}, {m.empleado?.apellido}
                    </td>
                </tr>  
                )
            })
        :
        <>
        <tr>
            <td>
                Este Empleado no tiene ningun Material Asignado!
            </td>
        </tr>
        </>
        }
        </tbody>
    </Table>
    </>
  )
}

export default TableStockEmpleado