import React  from 'react'
import {Card, CardBody, CardHeader} from 'reactstrap'
import NavBarInstalaciones from './components/NavBarInstalaciones';
import TableInstalaciones from './components/TableInstalaciones';

const ContadorInstalaciones = props => {

  return (
    <div>
      <Card className='mt-3 ml-3 mr-3'>
          <CardHeader>
            <NavBarInstalaciones/>
          </CardHeader>
          <CardBody>
            <TableInstalaciones/>
          </CardBody>
      </Card>
    </div>
  )
}


export default ContadorInstalaciones
