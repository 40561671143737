import React, { useEffect, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { getAlmacenes } from "services/AlmacenesService";


function SelectorAlmacenes(props){
    const {Valor, setValor} = props;
    const [Almacenes, setAlmacenes] = useState([]);
    
    const traerAlmacenes = async() => {
        let alm = await getAlmacenes();
        if (alm) {
            setAlmacenes(alm.almacenes)
        }
    }

    const cambiarValor = event => {
        setValor(event.target.value);
    };

    useEffect(() => {
        traerAlmacenes();
    }, [])
    

    return(
        <>
            <FormGroup>
                    <Input id="exampleFormControlSelect1" type="select" 
                        value={Valor}
                        onChange={cambiarValor}
                    >
                        <option value={''}>Opciones..</option>
                        {Almacenes?
                        
                        Almacenes.map((ele, index) => {
                            return(
                                <option key={index} value={ele.id}>{ele.nombre}</option>

                            )
                        })
                        :
                        <option value={''}>Cargando..</option>
                        }
                    </Input>
            </FormGroup>
        </>
    );
};

export default SelectorAlmacenes;