import { confirmAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Table } from 'reactstrap';
import { deleteEmpleado } from 'services/EmpleadosService';
import { getRolPermisos } from 'services/RolService';
import ModalAgregarEmpl from './ModalAgregarEmpleado';
import ModalEditEmpleado from './ModalEditEmpleado';
import { warningAlert } from 'pages/components/SweetAlert';

const TableEmpleados=(props)=>{
    const {Empleados, traerEmpleados, setAlert}=props;
    const history = useHistory ();
    const [ModalAgregarEmpleado, setModalAgregarEmpleado] = useState(false);
    const [ModalEditarEmpleado, setModalEditarEmpleado] = useState(false);
    const [Permisos, setPermisos] = useState([]);
    const [EmSeleccionado, setEmSeleccionado] = useState(null);
    



    const obtenerFormatoFecha = (fechaIngreso)=>{
        let fecha = new Date(fechaIngreso);
        let dia = fecha.getDate();
        let mes = fecha.getMonth();
        let ano = fecha.getFullYear();
        let fechaFormateada = `${dia}/${mes+1}/${ano}`;
        return fechaFormateada;
    };
    
    const irPerfil = (id)=>{
        history.replace(`/admin/perfilEmpleados/${id}`)
        }
    const irEquiposEmpleados = (idEmpl) => {
        history.replace(`/admin/equiposEmpleados/${idEmpl}`)
        }    

    const confirmarEliminar= async(id)=>{
    confirmAlert(setAlert, eliminarEmpleado, id , "Deseas eliminar este Empleado?")
    }
    const eliminarEmpleado = async(id)=>{
    
    let result = await deleteEmpleado(id)
    if(result != null){
            successAlert(setAlert, "Empleado Eliminado!", "");
            traerEmpleados()
            return
    }else{
      warningAlert(setAlert, 'No se pudo eliminar empleado')
    }
    };

    const traerPermisos= async()=>{

      let permisos= await getRolPermisos();
      if(permisos !=null && permisos!=''){
        setPermisos(permisos)
      }
    }

    const tienePermiso=(nombrePermiso)=>{
      return Permisos.includes(nombrePermiso);
    }
    const editarEmpleado = (emple)=>{
      setEmSeleccionado(emple);
      setModalEditarEmpleado(true); 
    }

    useEffect(() => {
      traerPermisos();
    }, [])

    return(
        <>
        
        <Table className="align-items-center" responsive>
          <thead className="thead-light text-center">
            <tr>
              <th scope="col">ID</th>
              <th scope="col">NOMBRE</th>
              <th scope="col">APELLIDO</th>
              {Permisos && tienePermiso('Ver-DatosPerssonales-Empleados')?
                <>
                  <th scope="col">INGRESO</th>
                  <th scope="col">SUELDO MENSUAL</th>
                  <th scope="col">TIPO DE SUELDO</th>
                </>
                :
                <></>
              }
              <th scope="col">EDITAR</th>
              <th scope="col">PERFIL</th>
              <th scope="col">EQUIPOS</th>
              {Permisos && tienePermiso('Agregar-Empleados')?
              <th scope="col">              
                      <div className="d-flex align-items-center">
                        <div>
                          <Button className="btn-icon btn-3 btn-sm btn-outline-info" color="primary" type="button" onClick={()=>setModalAgregarEmpleado(true)}>
                              <span className="btn-inner--icon">
                              <i className="fas fa-user-plus"></i>
                              </span>
                          </Button>
                          
                        </div>
                      </div>
              </th>
              :
              <></>
            }
              
            </tr>
          </thead>
          <tbody>

          {Empleados?                      
            Empleados.map((em, index) => {
              return (
                  <tr key={index}>
                    <th scope="row">
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            {em.id}
                          </span>
                    </div>
                    </th>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            {em.nombre}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            {em.apellido}
                          </span>
                    </div>
                    </td>
                    {Permisos && tienePermiso('Ver-DatosPerssonales-Empleados')?
                      <>
                        <td>
                        <div className="text-center">
                              <span className="mb-0 text-sm">
                              {obtenerFormatoFecha(em.ingreso)}
                              </span>
                        </div>
                        </td>
                        <td>
                        <div className="text-center">
                              <span className="mb-0 text-sm">
                                {em.sueldoMensual}
                              </span>
                        </div>
                        </td>
                        <td>
                        <div className="text-center">
                              <span className="mb-0 text-sm">
                                {em.tiposueldo}
                              </span>
                        </div>
                        </td>
                      </>
                      :
                      <></>
                    }
                    <td>
                    <div className="text-center">
                          <Button className="btn-icon btn-3 btn-sm btn-outline-success"  type="button" onClick={()=>{editarEmpleado(em)}} >
                              <span className="btn-inner--icon">
                              <i className="fas fa-edit"/>
                              </span>
                              
                          </Button>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                          <Button className="btn-icon btn-3 btn-sm btn-outline-info rounded-circle "type="button" onClick={()=>irPerfil(em.id)}>
                              <span className="btn-inner--icon">
                              <i className="fas fa-user"></i>                              
                              </span>                                                           
                          </Button>    
                    </div>
                    </td>
                    
                    <td>                 
                    <div className="text-center">
                          <Button onClick={()=>{irEquiposEmpleados(em.id)}} className="btn-icon btn-3 btn-sm btn-outline-default rounded-circle" type="button">
                              <span className="btn-inner--icon">
                              <i className="ni ni-settings"/>
                              </span>
                              
                          </Button>
                    </div>
                    </td>
                    {Permisos && tienePermiso('Eliminar-Empleados')?
                    <td>
                    <div className="text-center">
                          <Button className="btn-icon btn-3 btn-sm btn-outline-danger rounded-circle"  type="button" onClick={()=>{confirmarEliminar(em.id)}}>
                              <span className="btn-inner--icon">
                              <i className="ni ni-fat-remove"/>
                              </span>
                              
                          </Button>
                    </div>
                    </td>
                    :
                    <></>
                  }
                  </tr>
                  
                  
                )

              })
            :
            <p>Cargando..</p>
            }
          </tbody>
        </Table>
        <ModalAgregarEmpl
        ModalAgregarEmpleado={ModalAgregarEmpleado}
        setModalAgregarEmpleado={setModalAgregarEmpleado}
        traerEmpleados={traerEmpleados}
        setAlert={setAlert}
        />

        {EmSeleccionado!=null?
        <ModalEditEmpleado
        ModalEditarEmpleado={ModalEditarEmpleado}
        setModalEditarEmpleado={setModalEditarEmpleado}
        traerEmpleados={traerEmpleados}
        EmpleadoSeleccionado={EmSeleccionado}
        />
        :
        <></>
        }
        </>
    )

}
export default TableEmpleados