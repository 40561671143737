import { urlGlobal2 } from './GlobalService';

export async function getAlmacenes(params=null){
    try {
      let url = new URL(`${urlGlobal2}/almacenes`)
      if (params != null) {
        url.search = new URLSearchParams(params).toString();
      }
      let token = localStorage.getItem('token');
      let method = 'GET';
      let headers = {'Content-Type':'application/json','token':token};
      let equipos = null;
      const resp = await fetch(url, {method,headers});
      const data = await resp.json();
      return data;    
    } catch (error) {
      alert(error)
    }
  }
  export async function postAlmacen(ev){
    let url = `${urlGlobal2}/almacenes`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(ev)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        return null;
    }
  }

export async function agregarStockAlmacen(sc){
    let url = `${urlGlobal2}/almacenes/agregarstock`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(sc)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        return null;
    }
  }
  export async function recibirMaterial(sc){
    let url = `${urlGlobal2}/almacenes/recibirmaterial`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(sc)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        return null;
    }
  }
  export async function entregarMaterial(sc){
    let url = `${urlGlobal2}/almacenes/entregarmaterial`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(sc)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        return null;
    }
  }
  export async function postTiposMaterialesExistente(ev){
    let url = `${urlGlobal2}/almacenes/addtipmaterialtoalmacen`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(ev)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        return null;
    }
  }

  export async function postManyTMsExistente(tms){
    let url = `${urlGlobal2}/almacenes/addmanytma`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(tms)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        return null;
    }
  }

export async function putEditarStock(sc){
    let url = `${urlGlobal2}/almacenes/editarstock`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'PUT';
        let body = JSON.stringify(sc)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        return null;
    }
  }