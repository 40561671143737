import React, { useEffect, useState } from "react";
import {
  Button,
  Media,
  Table,
  Card,
  CardBody,
} from "reactstrap";
import { getFacturas, postFiscalizarFacturas } from "services/FacturasService";
import EtiquetaEstadoFacturas from "../components/EtiquetaEstadoFacturas";
import moment from 'moment';
import { confirmAlert, successAlert, warningAlert } from "../../../components/SweetAlert";
import { useHistory } from "react-router";
import ModalSelec from "pages/private/facturacionAutomatica/components/ModalSelec";
import LoadingComponent from "pages/components/Loading";


function FacturasComponent(props) {
  const { idClient, datosCliente } = props;
  const [Facturas, SetFacturas] = useState([]);
  const [alertSweet, setAlert] = useState(null);
  const [ModalSeleccionar, setModalSeleccionar] = useState(false)
  const [Visible, setVisible] = useState(false)
  const [Mostrar, setMostrar] = useState(true)
  const history = useHistory();

  const traerFacturas = async () => {
    setVisible(true)
    let fact = await getFacturas(null, idClient);
    if (fact != null && fact != '') {
      SetFacturas(fact.reverse());
    }
    setVisible(false)
  };



  const getAttribute = (attributes, key) => {
    let at = attributes.find(a => {
      return a.key === key
    })
    if (!at) { return null }
    return at
  }

  const findAttribute = (key, attributes) => {
    let a = "";
    if (attributes && attributes.length > 0) {
      attributes.map(at => {
        if (at.key === key) { a = at.value }
      })
    }

    return a;
  }


  const puedeFiscalizar = (invoice) => {
    let ok = true;
    if (getAttribute(invoice.attributes, 'cae') !== null) {
      ok = false;
    }
    // if (findAttribute('fiscal', datosCliente.attributes) !== "1") {
    //   ok = false;
    // }
    // if (findAttribute('tipofactura', datosCliente.attributes) !== "B") {
    //   ok = false;
    // }

    return ok;
  }



  const fiscalizarFacturas = async (fact) => {
    let dataFiscalizar = {
      invoiceId: fact.id,
      invoiceType: "B",
      clientIdentifier: datosCliente.userIdent
    }
    let resFiscalizacion = await postFiscalizarFacturas(dataFiscalizar);
    try {

      if (!resFiscalizacion) {
        warningAlert(setAlert, "No se pudo fiscalizar factura")
        return
      } else {
        if (!resFiscalizacion.ok) {
          warningAlert(setAlert, resFiscalizacion.mensaje)
          return
        }
        await traerFacturas();
        successAlert(setAlert, resFiscalizacion.mensaje);
        //window.location.reload();
      }
    } catch (error) {
      warningAlert(setAlert, `${error.message}`)
    }
  }

  const confirmarFiscalizacion = (fact) => {
    confirmAlert(setAlert, fiscalizarFacturas, fact, "¿Seguro que desea fiscalizar la factura?")
  }
  const irFactura = (idFactura) => {
    history.replace(`/admin/FacturaCliente/${idFactura}`)

  }

  useEffect(() => {
    traerFacturas();
  }, [])

  return (
    <>
      <LoadingComponent Visible={Visible} />
      {alertSweet}
      <Card className="mt-4" style={{ width: "100%" }}>
        <CardBody >

          <Table className="text-center" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">ID</th>
                <th scope="col">NUMERO DE FACTURA</th>
                <th scope="col">TOTAL</th>
                <th scope="col">CANTIDAD DEBIDA</th>
                <th scope="col">FECHA DE CREACION</th>
                <th scope="col">FECHA DE VENCIMIENTO</th>
                <th scope="col">VENCIDO</th>
                <th scope="col">ETIQUETA</th>
                <th scope="col"><Button className="btn btn-outline-success btn-sm float-right" onClick={() => setModalSeleccionar(true)}>Agregar Facturacion</Button></th>


              </tr>
            </thead>
            <tbody>
              {Facturas ?
                Facturas.map((fact, index) => {
                  return (

                    <tr key={index}>
                      <th>
                        <Media>
                          <span className="mb-0 text-sm">
                            {fact.id}
                          </span>
                        </Media>
                      </th>
                      <td scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                              {fact.number} {fact.status ?
                                <EtiquetaEstadoFacturas Estado={fact.status} />
                                :
                                <p>Sin estado</p>
                              }
                            </span>
                          </Media>
                        </Media>
                      </td>
                      <td>
                        <Media>
                          <span className="mb-0 text-sm">
                            ${fact.total}
                          </span>
                        </Media>
                      </td>
                      <td>
                        <Media>
                          <span className="mb-0 text-sm">
                            ${fact.amountToPay}
                          </span>
                        </Media>
                      </td>
                      <td>
                        <Media>
                          <span className="mb-0 text-sm">
                            {moment(fact.createdDate).format('DD/MM/YYYY')}
                          </span>
                        </Media>
                      </td>
                      <td>
                        <Media>
                          <span className="mb-0 text-sm">
                            {moment(fact.dueDate).format('DD/MM/YYYY')}

                          </span>
                        </Media>
                      </td>
                      <td>
                        <Media>
                          <span className="mb-0 text-sm">
                            {moment(fact.dueDate).fromNow()}

                          </span>
                        </Media>
                      </td>

                      <td>
                        <span className="mb-0 text-sm">
                          {fact.items[0].label}
                        </span>

                      </td>
                      <td>
                        <div>
                          <Button onClick={() => { irFactura(fact.id) }} className="mb-3 btn-outline-success btn-sm">Ver</Button>
                        </div>
                      </td>

                      {puedeFiscalizar(fact) ?
                        <td>
                          <div style={{ float: 'right' }}>
                            <Button onClick={() => { confirmarFiscalizacion(fact) }} className="mb-3 btn-outline-info btn-sm">Fiscalizar</Button>
                          </div>
                        </td>
                        :
                        <></>}
                    </tr>
                  )
                })

                :
                <p>Cargando..</p>
              }
            </tbody>

          </Table>
        </CardBody>
      </Card>
      <ModalSelec
        ModalSeleccionar={ModalSeleccionar}
        setModalSeleccionar={setModalSeleccionar}
        traerFacturas={traerFacturas}
      />

    </>
  )
}


export default FacturasComponent;