import { successAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap';
import { postEmpleados } from 'services/EmpleadosService';
import SelectorTipoSueldo from './SelectorTipoSueldo';
import { warningAlert } from 'pages/components/SweetAlert';

const ModalAgregarEmpl = (props) => {
  const { ModalAgregarEmpleado, setModalAgregarEmpleado, traerEmpleados, setAlert } = props;

  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [dni, setDni] = useState("");
  const [sueldo, setSueldo] = useState("");
  const [ingreso, setIngreso] = useState("");
  const [tipo, setTipo] = useState("");
  const [foto, setFoto] = useState("");

  const cambiarNombre = event => {
    setNombre(event.target.value);
  };
  const cambiarApellido = event => {
    setApellido(event.target.value);
  };
  const cambiarTelefono = event => {
    setTelefono(event.target.value);
  };
  const cambiarEmail = event => {
    setEmail(event.target.value);
  };
  const cambiarDni = event => {
    setDni(event.target.value);
  };
  const cambiarSueldo = event => {
    setSueldo(event.target.value);
  };
  const cambiarIngreso = event => {
    setIngreso(event.target.value);
  };
  const cambiarFoto = event => {
    setFoto(event.target.value);
  };

  const AgregarEmpleado = async () => {
    let sepF = ingreso.split('-');
    let fecha = new Date(parseInt(sepF[0]), parseInt(sepF[1]) - 1, parseInt(sepF[2]));
    let empl = {
      nombre: nombre,
      apellido: apellido,
      telefono: telefono,
      email: email,
      dni: dni,
      sueldoMensual: sueldo,
      ingreso: fecha,
      tiposueldo: tipo,
      foto: foto,
    }
    if (validarAggEmpleado(empl)) {
      let result = await postEmpleados(empl)
      if (result != null) {

        setNombre("")
        setApellido("")
        setTelefono("")
        setEmail("")
        setDni("")
        setSueldo("")
        setIngreso("")
        setTipo("")
        setFoto("")
        setModalAgregarEmpleado(false)
        successAlert(setAlert, "Empleado agregado correctamente!", "")

        traerEmpleados()
        return
      } else {
        warningAlert(setAlert, 'No se pudo agregar empleado')
      }
    } else {
      warningAlert(setAlert, 'Complete todos los campos')
    }

  }
  const validarAggEmpleado = (empl) => {
    if (!empl.nombre) {
      //this.sweetAlertService.alertError('Ingrese un nombre');
      return false;
    }
    if (!empl.apellido) {
      //this.sweetAlertService.alertError('Ingrese un apellido');
      return false;
    }
    if (!empl.telefono) {
      //this.sweetAlertService.alertError('Ingrese un telefono');
      return false;
    }
    if (!empl.dni) {
      //this.sweetAlertService.alertError('Ingrese un Dni');
      return false;
    }
    if (!empl.sueldoMensual) {
      //this.sweetAlertService.alertError('Ingrese un sueldo Mensual');
      return false;
    }
    if (!empl.ingreso) {
      //this.sweetAlertService.alertError('Ingresa la fecha de ingreso');
      return false;
    }
    if (!empl.tiposueldo) {
      //this.sweetAlertService.alertError('Ingresa la fecha de ingreso');
      return false;
    }
    return true;
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={ModalAgregarEmpleado}
        toggle={() => setModalAgregarEmpleado(false)}
      >
        <div className="modal-body p-0">
          <CardHeader>
            <div className="text-center"><b>Agregar Empleado</b>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalAgregarEmpleado(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
          </CardHeader>
          <CardBody>

            <Form role="form">
              <FormGroup value={nombre} onChange={cambiarNombre}> Nombre:
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>

                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Nombre..." type="text" />
                </InputGroup>
              </FormGroup>
              <FormGroup value={apellido} onChange={cambiarApellido}> Apellido:
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>

                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Apellido..." type="text" />
                </InputGroup>
              </FormGroup>
              <FormGroup className="mb-3" value={telefono} onChange={cambiarTelefono}>Telefono:
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i class="fas fa-phone-alt"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Telefono..." type="number" />
                </InputGroup>
              </FormGroup>
              <FormGroup value={email} onChange={cambiarEmail}> Email:
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Email (opcional)" type="email" />
                </InputGroup>
              </FormGroup>
              <FormGroup className="mb-3" value={dni} onChange={cambiarDni}>DNI:
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i class="fas fa-address-card"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="DNI" type="number" />
                </InputGroup>
              </FormGroup>
              <FormGroup className="mb-3" value={sueldo} onChange={cambiarSueldo}>Sueldo Mensual:
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i class="fas fa-money-check-alt"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Sueldo Mensual" type="number" />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <label
                  className="mb-3"
                  htmlFor="example-date-input"
                >
                  Ingreso:
                </label>
                <Input
                  id="example-date-input"
                  type="date"
                  value={ingreso} onChange={cambiarIngreso}
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="exampleFormControlSelect1">Tipo De Sueldo:</label>

                <SelectorTipoSueldo Valor={tipo} setValor={setTipo} />

              </FormGroup>
            </Form>
            <Form value={foto} onChange={cambiarFoto}> Foto:
              <div className="custom-file">
                <input
                  className="custom-file-input"
                  id="customFileLang"
                  lang="en"
                  type="file"
                />
                <label className="custom-file-label" htmlFor="customFileLang">
                  Foto
                </label>
              </div>
            </Form>
          </CardBody>
          <CardFooter>
            <div className="text-center">
              <Button
                color="primary"
                type="button"
                onClick={() => AgregarEmpleado()}
              >
                Agregar
              </Button>

              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalAgregarEmpleado(false)}
              >
                Cerrar
              </Button>
            </div>
          </CardFooter>
        </div>
      </Modal>
    </>
  )
}
export default ModalAgregarEmpl