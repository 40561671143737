import React, { useState } from 'react'
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, Modal, Table } from 'reactstrap'
import { entregarMaterial } from 'services/AlmacenesService';
import SelectorEmpleados from 'pages/components/SelectorEmpleados';
import LoadingComponent from 'pages/components/Loading';
import SelectorAlmacenes from './SelectorAlmacenes';
import EntregarAComponent from './EntregarAComponent';

const ModalMaterialEntregar = ({ModalEntregarMaterial, setModalEntregarMaterial, Materiales,idAlmacen, traerMateriales,reset,NoEntregados, setNoEntregados}) => {
    const [Empleado, setEmpleado] = useState("")
    const [Almacen, setAlmacen] = useState("")
    const [EntregarA, setEntregarA] = useState("")
    const [Alert, setAlert] = useState(null);
    const [Loading, setLoading] = useState(null);
    

    const validarEntregar = (ev)=>{
        if(!ev.tipoMaterialId){
          return false;
        }
        if(!ev.cantidad){
          return false;
        }
        if(!ev.empleadoId && !ev.almacenReceptorId){
            return false;
        }
        return true;
    }
    
    const entregarTodos = async() =>{
        setLoading(true)
        if(!Materiales || Materiales.length===0){
            warningAlert(setAlert,"No seleccionó ningún material")
            return
        }
        if(EntregarA === "Empleado"){
            if(Empleado===""){
                warningAlert(setAlert,"Seleccione un empleado")
                setLoading(false)
                return
            }   
        }
        if(EntregarA === "Almacen"){
            if(Almacen===""){
                warningAlert(setAlert,"Seleccione un Almacen")
                setLoading(false)
                return
            }   
        }
        let fallaron = []
        await Promise.all(Materiales.map(async (material) => {
            let dev = await Entregar(material.idTipoMaterial, material.cantidad)
            if(dev.res===false){
                fallaron.push({material:material.tipoMaterial, mensaje:dev.mensaje})
            }
        }))
        if(fallaron.length===0){
            setLoading(false)
            successAlert(setAlert,"Materiales entregados correctamente")
            setEntregarA("")
            reset()
            traerMateriales()
        }else{
            setNoEntregados(fallaron);
            setLoading(false)
            warningAlert(setAlert,"Algunos materiales no se entregaron")
            
        }
    }

    const Entregar = async(tipoMaterialId, cantidad) =>{
        let material = {
            tipoMaterialId : parseInt(tipoMaterialId), 
            cantidad : parseInt(cantidad), 
            empleadoId : null, 
            almacenId: parseInt(idAlmacen),
            almacenReceptorId: null
        }
        if(EntregarA === "Empleado"){
            material.empleadoId = parseInt(Empleado)
        }
        if(EntregarA === "Almacen"){
            material.almacenReceptorId = parseInt(Almacen)
        }
        if (validarEntregar(material)===true) {
            let entregar = await entregarMaterial(material)
            if (entregar) {
                if(entregar.ok){
                    return {res:true, mensaje:"Material entregado correctamente!"}
                }else{
                    return {res:false, mensaje:"Verifique que alcance la cantidad"}
                }
            } else {
                return {res:false, mensaje:"No se obtuvo respuesta del servidor"}
            }
        } else {
                return {res:false, mensaje:"Campos incompletos"}
        }
    }

    const cerrar = () =>{
        setEntregarA("");
        setModalEntregarMaterial(false);
    }
        
    
  return (
    <>
        {Alert}
        <LoadingComponent Visible={Loading}/>
        <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={ModalEntregarMaterial}
        toggle={() => {cerrar()}}
        >
        {NoEntregados?
        <Card>
            <CardHeader className="pb-3">
                <div className="text-center"><b>Materiales no Entregados</b></div>
            </CardHeader>
            <CardBody>
                <Table className="align-items-center table-flush" hover responsive>
                    <thead className="thead-light">
                    <tr>
                        <th>Material</th>
                        <th>Error</th>
                    </tr>
                    </thead>
                    <tbody>
                    {NoEntregados.map((mt, index) => {
                        return (
                        <tr key={index}>
                            <td className="table-user">
                            <b>{mt.material.tiposMateriale.nombre}</b>
                            </td>
                            <td>
                            {mt.mensaje}
                            </td>
                        </tr>
                        )
                    })}
                    
                    </tbody>
                </Table>
            </CardBody>  
            <CardFooter>
                <div className="text-center">
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => {setEntregarA("");reset()}}
                >
                    Cerrar
                </Button>                    
                </div>
                </CardFooter>                
        </Card>
        :
        <div>
        <Card>
            <CardHeader className="pb-3">
            <div className="text-center"><b>Entregar  Material</b>
            <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => cerrar()}
        >
            <span aria-hidden={true}>×</span>
        </button>
            </div>
            </CardHeader>
            {EntregarA === ""? 
                <CardBody>
                    <EntregarAComponent cambiarEstado={setEntregarA}/>
                </CardBody>
            :
                <>
                    {EntregarA === "Empleado"?
                        <CardBody>
                            <div className=" mt-5 mb-3">
                                <Form role="form">
                                    <label>Empleado:</label>
                                    <SelectorEmpleados valor={Empleado} cambiarValor={setEmpleado}/>
                                </Form>                     
                            </div>                    
                        </CardBody>
                    
                    :
                        <CardBody>
                        <div className=" mt-5 mb-3">
                            <Form role="form">
                                <label>Almacenes:</label>
                                <SelectorAlmacenes valor={Almacen} setValor={setAlmacen}/>
                            </Form>                     
                        </div>                    
                        </CardBody>}
                        <CardFooter>
                            <div className="text-center">
                            <Button
                                className=""
                                color="primary"
                                type="button"
                                onClick={()=>entregarTodos()}
                                >
                                Entregar
                            </Button>
                        
                            <Button
                                color="secondary"
                                data-dismiss="modal"
                                type="button"
                                onClick={() =>{setEntregarA("");setModalEntregarMaterial(false)}}
                            >
                                Cerrar
                            </Button>                    
                            </div>
                            </CardFooter>                
                </>
            
            }  
        </Card>
        </div>
        }
        </Modal></>
  )
}


export default ModalMaterialEntregar