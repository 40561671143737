import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { TableProgramacionPagos } from './components/TableProgramacionPagos'
import { getFacturaciones } from 'services/FacturacionService';
import LoadingComponent from 'pages/components/Loading';
import ModalAggFacturacionManual from './components/ModalAggFacturacionManual';
import ModalAggFacturacion from './components/ModalAggFacturacion';

export const FacturacionAutomatica = () => {
  const [ModalAgregarFacturacion, setModalAgregarFacturacion] = useState(false);
  const [ModalEditarFacturacion, setModalEditarFacturacion] = useState(false)
  const [refresh, setRefresh] = useState(false)
 

  const [modalManual, setModalManual] = useState(false);
  const [modalAutomatica, setModalAutomatica] = useState(false);


  const [Facturaciones, setFacturaciones] = useState([])
  const [Visible, setVisible] = useState(false)

  const traerFacturaciones = async () => {
    setVisible(true)
    let facturaciones = await getFacturaciones();
    if (facturaciones !== null && facturaciones !== "") {
        setFacturaciones(facturaciones)
    }
    setVisible(false)
}


  useEffect(() => {
      traerFacturaciones()
  }, [])

  useEffect(() => {
      if (refresh) {
          traerFacturaciones()
          setRefresh(false)
      }
      
  }, [refresh])





  return (
    <>
    <LoadingComponent Visible={Visible}/>
      <Card>
        <CardHeader>
          <Row>
            <Col md="2">
              <h1>
                Facturación
              </h1>
            </Col>
            <Col md="6"></Col>
            <Col md="2">
              <Button className='btn btn-outline-success float-right' onClick={() => setModalAutomatica(true)}>Programar Facturación</Button>
            </Col>
            <Col md="2">
              <Button className='btn btn-outline-success float-right' onClick={() => setModalManual(true)}>Facturación Manual</Button>
            </Col>

          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <h3>
              Facturación Automática
            </h3>
          </Row>
          <TableProgramacionPagos
            ModalAgregarFacturacion={ModalAgregarFacturacion}
            setModalAgregarFacturacion={setModalAgregarFacturacion}
            ModalEditarFacturacion={ModalEditarFacturacion}
            setModalEditarFacturacion={setModalEditarFacturacion}
            traerFacturaciones={traerFacturaciones}
            Facturaciones={Facturaciones}
          />
        </CardBody>
      </Card>
      
      
      <ModalAggFacturacionManual setModalSeleccionar={setModalManual} isOpen={modalManual}/>
     
      <ModalAggFacturacion isOpen={modalAutomatica} setModalSeleccionar={setModalAutomatica} setRefresh={setRefresh}/>
        
      
                                  
                                  
    </>
  )
}
