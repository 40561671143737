import React, { useEffect, useState } from 'react'
import { Input } from 'reactstrap'
import { getCategoriasValores } from 'services/CategoriaValoresService'

const SelectorCategoriaValor = props => {
    const {CatValor, CambiarCatValor, ID, setCategoriaId}=props;
    const [Categorias, setCategorias] = useState([])

    const cambiarValor =(e) => {
      if (ID) {
        CambiarCatValor(e.target.value)
      } else {
        CambiarCatValor(e.target.value)
        let devl = "-"
        Categorias.map((c)=>{
            if (c.nombre === e.target.value){
                devl = c.id
            }
    })
        setCategoriaId(devl);
      }
     
    }
    const traerCategorias = async() => {
        let categorias = await getCategoriasValores();
        if (categorias !== null) {
            setCategorias(categorias)
        }
    }
    useEffect(() => {
      traerCategorias()
    }, [])
    
  return (
    <>
    <Input type='select' onChange={cambiarValor} value={CatValor}>
        <option value={''}>Categoria</option>
        {Categorias?
        Categorias.map((c, index)=>{
            if(ID){
              return(
                <option value={c.id} key={index}>{c.nombre}</option>
              )
            }else{
              return(
                    <option value={c.nombre} key={index}>{c.nombre}</option>
                )
            }
        })
    :
    <option value={''}>Cargando...</option>}

    </Input>
    </>
  )
}

export default SelectorCategoriaValor