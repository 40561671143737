
import { successAlert } from 'pages/components/SweetAlert';
import { useState } from 'react';
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, Modal } from 'reactstrap';
import { putEditarEspera } from 'services/EsperaServices';

const ModalSacarEsperas = (props) => {
    const { ModalSacarEspera, setModalSacarEspera} = props;
    const [Alert, setAlert] = useState(null)

    const [Esperas, setEsperas] = useState([]);

    const recetearEsperas = ()=>{
        setEsperas([])
        setModalSacarEspera(false)
    }

    const cambiarEsperas = (e)=>{
        let esperas = e.target.value.split(",")
        setEsperas(esperas)
        
    }
 
    const cambiarEstadoEsperas = async() => {
        Esperas.map(e => cambiarEstado(parseInt(e)))
        successAlert(setAlert, "Esperas sacadas!")
        recetearEsperas()

    }
    const cambiarEstado = async (e) => {
         await putEditarEspera({ id: e, estado: 2 })
    }

    return (
        <>
        {Alert}
            <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={ModalSacarEspera}
                toggle={() => (setModalSacarEspera(false), recetearEsperas())}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent pb-5">

                            <div className="text-center">
                             
                                    <b>Sacar Esperas (tienen que estar separadas por una coma (,))</b>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => (setModalSacarEspera(false), recetearEsperas())}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <div className="mt-2 mb-3">
                                <Form role="form">
                                    <FormGroup className="mb-3" >Esperas:
                                        <InputGroup className="input-group-alternative mt-2">
                                            <Input placeholder="Esperas" type="textarea" value={Esperas} onChange={cambiarEsperas} />
                                        </InputGroup>
                                    </FormGroup>
                                    <div className="text-center">
                                            <Button
                                                className="my-4"
                                                color="primary"
                                                type="button"
                                                onClick={() => cambiarEstadoEsperas()}
                                            >
                                                Sacar
                                            </Button>
                                        <Button
                                            color="secondary"
                                            data-dismiss="modal"
                                            type="button"
                                            onClick={() => (setModalSacarEspera(false), recetearEsperas())}
                                        >
                                            Cerrar
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </CardHeader>
                    </Card>
                </div>
            </Modal>
        </>
    )
}


export default ModalSacarEsperas
