import React from 'react'
import { useHistory } from 'react-router'
import { Button, Table } from 'reactstrap'
import ModalAggObra from './ModalAggObra'

const TableObras = ({Obras, ModalAgregarObra, setModalAgregarObra, traerObras}) => {
    const history = useHistory()

    const irMaterialesObra = (id, nom) => {
        history.replace({
           pathname:  `/admin/obraParticular/${id}`,
           state: {
               nombreObra : nom
           }
        }
        )
    }
    
  return (
    <>
    <Table responsive >
        <thead className='text-center bg-info'>
            <tr>
                <th>ID</th>
                <th>NOMBRE</th>
                <th>VER</th>
            </tr>
        </thead>
        <tbody className='text-center'>
        {Obras?
        Obras.map(o => {
            return (
            <tr key={o.id}>
                <th>{o.id}</th>
                <td>{o.nombre}</td>
                <td><Button className='btn btn-sm btn-outline-success' onClick={()=>irMaterialesObra(o.id, o.nombre)}>Ver <i className='fas fa-eye'></i></Button></td>
            </tr>

            )
        })
            :
            <></>
        }
        </tbody>
    </Table>
    <ModalAggObra ModalAgregarObra={ModalAgregarObra} setModalAgregarObra={setModalAgregarObra} traerObras={traerObras}/>
    </>
  )
}

export default TableObras