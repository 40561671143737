import React from "react";
import { FormGroup, Input } from "reactstrap";


function SelectorZonas(props){
    const {Valor, setValor, Zonas} = props;
    
    const cambiarValor = event => {
      setValor(event.target.value);
  };

    return(
        <>
        <FormGroup>
          <Input id="exampleFormControlSelect1" type="select"
            value={Valor}
            onChange={cambiarValor}
          >
            <option value={""}>Zonas</option>
            {Zonas?
            
            Zonas.map((zn, index) => {
              return (
                  <option key={index} value={zn.id}>{zn.nombre}</option>
                )

              })
            :
            <option value={""}>Cargando..</option>
            }
          </Input>
        </FormGroup>
</>
    );
};

export default SelectorZonas;