import moment from 'moment';
import { confirmAlert, successAlert, warningAlert } from 'pages/components/SweetAlert';
import { useState } from 'react';
import { Button, Card, CardBody, Container, Table } from 'reactstrap';
import { deleteCae } from 'services/CaesServices';
import ModalAddCaes from './ModalAddCaes';
import ModalAddFromExcel from './ModalAddFromExcel';


export const TableCaes = ({
    caes
}) => {
    const [ModalAgregarCae, setModalAgregarCae] = useState(false)
    const [Alert, setAlert] = useState(null)
    const [ModalAgregarCaeDesdeExcel, setModalAgregarCaeDesdeExcel] = useState(false)
    const [ActionType, setActionType] = useState('add')
    const confirmarEliminar= async(id, cae)=>{
        confirmAlert(setAlert, eliminarCae, id , "Deseas eliminar esta Cae?", `cae: ${cae}`)
      }

      const eliminarCae = async(id)=>{
        // esperando especificaciones sobre la api 


        // let elim = await deleteCae(id)
        // if (elim) {
        //     successAlert(setAlert, "Cae Eliminada")
        // } else {
        //     warningAlert(setAlert, "Error al eliminar Cae")
        // }
        successAlert(setAlert, "Eliminar")
      }




    return (
        <>
        {Alert}

            <Table className="align-items-center" responsive>
                <thead className="thead-light text-center">
                    {/* <tr>
                        <Button className='btn btn-sm btn-outline-success mb-3' onClick={()=>setModalAgregarCae(true)}>Agregar CAE</Button>
                        <Button className='btn-sm btn-outline-info mb-3' onClick={() => setModalAgregarCaeDesdeExcel(true)}>
                            Importar
                        </Button>
                        <Button className='btn-sm btn-outline-info mb-3' onClick={() => (setActionType('delete'),setModalAgregarCaeDesdeExcel(true))}>
                            Importar Y Eliminar
                        </Button>
                    </tr> */}
                    <tr>

                        <th scope="col">CAE</th>
                        <th scope="col">N° FACTURA</th>
                        <th scope="col">IMPORTE</th>
                        <th scope="col">TIPO</th>
                        <th scope="col">FECHA</th>
                        <th scope="col">ASIGNADO</th>

                    </tr>
                </thead>
                <tbody>
                    {caes && caes.length ?
                        caes.map((sc, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <div className="text-center">
                                            <span className="mb-0 text-sm">
                                                <b>{sc.cae}</b>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <span className="mb-0 text-sm">
                                                <b>{sc.nroFactura}</b>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <span className="mb-0 text-sm">
                                                {sc.importe}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <span className="mb-0 text-sm">
                                                {sc.tipo}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <span className="mb-0 text-sm">
                                                {moment(sc.fechaFacturacion).format('DD/MM/YYYY')}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <span className="mb-0 text-sm">
                                                {sc.facturaId ? sc.facturaId : 'No asignado'}
                                            </span>
                                        </div>
                                    </td>
                                    {/* <td>
                                        <div className="text-center">
                                            <Button className='btn btn-sm btn-outline-danger' onClick={()=>confirmarEliminar(sc._id, sc.cae)}>Eliminar</Button>
                                        </div>
                                    </td> */}



                                </tr>

                            )
                        })
                        : null

                    }

                    {
                        caes && caes.length == 0 ?
                            <tr>
                                <td>
                                    <Container fluid className="justify-content-center">
                                        <Card className="text-center" style={{ width: "50rem", border: "1px solid #f39c12", borderRadius: "10px" }}>
                                            <CardBody>
                                                <p className="h4">No se encontraron Caes</p>
                                            </CardBody>
                                        </Card>
                                    </Container>
                                </td>
                            </tr>
                            : null
                    }



                </tbody>
            </Table>
            <ModalAddCaes ModalAgregarCae={ModalAgregarCae} setModalAgregarCae={setModalAgregarCae} />
            <ModalAddFromExcel ModalAgregarCaeDesdeExcel={ModalAgregarCaeDesdeExcel} setModalAgregarCaeDesdeExcel={setModalAgregarCaeDesdeExcel} ActionType={ActionType} setActionType={setActionType}/>

            {
                /*
                <Paginacion traerObjetos={GetCaes} setObjetos={setCaes} getObjetos={getCaes} Query={Query} setQuery={setQuery}/>
                */
            }


        </>

    )
}


