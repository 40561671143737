import LoadingComponent from 'pages/components/Loading'
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { getObras } from 'services/ObrasService'
import TableObras from './components/TableObras'

const Obras = () => {
    const [Obras, setObras] = useState([])
    const [ModalAgregarObra, setModalAgregarObra] = useState(false)
    const [Visible, setVisible] = useState(false)

    const traerObras = async() => {
      setVisible(true)
        let obras = await getObras();
        if (obras) {
            setObras(obras.obras)
        }
      setVisible(false)
    }

    useEffect(() => {
        traerObras()
    }, [])
    

  return (
    <>
    <LoadingComponent Visible={Visible}/>
    <Card>
        <CardHeader>
          <Row>
            <Col>
            <h1>Obras</h1>
            </Col>
            <Col></Col>
            <Col></Col>
            <Col>
              <Button className='btn btn-outline-success btn-md float-right' onClick={()=>setModalAgregarObra(true)}>Agregar Obra</Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
            <TableObras Obras={Obras} ModalAgregarObra={ModalAgregarObra} setModalAgregarObra={setModalAgregarObra} traerObras={traerObras}/>
        </CardBody>
    </Card>
    </>
  )
}

export default Obras