import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Container,
    NavbarBrand,
    Navbar
  } from "reactstrap";
  import { getClientes } from "services/ClientesServices";
  import { useHistory } from "react-router";
import Buscador from "pages/components/Buscador";
import { GuardInPages } from "guards/Guard";
import LoadingComponent from "pages/components/Loading";
import TableClts from "./components/TableClientes";
import ModalAgregarClt from "./components/ModalAgregarCliente";
  

function Clientes(){
    const history = useHistory ();
    const [Clientes, SetClientes] = useState([]); 
    const [ModalAgregarCliente, setModalAgregarCliente] = useState(false);
    const [Visible, setVisible] = useState(false);

    const traerClientes = async() => {
      setVisible(true)
      let clients = await getClientes();
      if (clients !== null && clients !=='') {
        SetClientes(clients);
      }
      setVisible(false)
    }
    
  
    useEffect(() => {
      GuardInPages(history)
      traerClientes()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <>
        <LoadingComponent Visible={Visible}/>
        
        <Card>
        <Navbar
          className="navbar-horizontal navbar-light bg-secondary"
          expand="lg"
        >
          <Container>
            <NavbarBrand>
              <Buscador traerObjetos={traerClientes} setObjetos={SetClientes} getObjetos={getClientes} />
            </NavbarBrand>
          </Container>                
                      <div className="d-flex align-items-center">
                        <div>
                          <Button className="btn-icon btn-3 btn-md btn-outline-info mr-6" color="primary" type="button" onClick={()=>setModalAgregarCliente(true)}>
                              <span className="btn-inner--icon">
                              <i className="fas fa-user-plus"></i>
                              </span>
                          </Button>
                         
                        </div>
                      </div>
        </Navbar>
        <TableClts
        Clientes={Clientes}
        />
        </Card>
        <ModalAgregarClt
        ModalAgregarCliente={ModalAgregarCliente}
        setModalAgregarCliente={setModalAgregarCliente}
        traerClientes={traerClientes}
        />
                          
      </>
    )
  } 
export default Clientes;