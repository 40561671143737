import React, { useState } from 'react'
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap'
import { agregarStockAlmacen } from 'services/AlmacenesService';
import LoadingComponent from 'pages/components/Loading';
import TablaNoAgregados from './TablaNoAgregados';
import TablaAddStock from './TablaAddStock';

const ModalAggStock = ({ModalAgregarStock, setModalAgregarStock, Materiales, traerMateriales, idAlmacen, reset, NoAgregados, setNoAgregados}) => {
    const [Alert, setAlert] = useState(null);
    const [Loading, setLoading] = useState(false)

    const validarAggStock = (ev)=>{
        if(!ev.tipoMaterialId){
          return false;
        }
        if(!ev.cantidad){
          return false;
        }
        return true;
      }


      const agregarTodos = async() =>{
        if(!Materiales || Materiales.length===0){
            warningAlert(setAlert,"No seleccionó ningún material")
            return
        }
        setLoading(true)
        let fallaron = []
        await Promise.all(Materiales.map(async (material) => {
            let dev = await Agregar(material.idTipoMaterial, material.cantidad)
            if(dev.res===false){
                fallaron.push({material:material.tipoMaterial, mensaje:dev.mensaje})
            }
        }))
        if(fallaron.length===0){
            setLoading(false)
            successAlert(setAlert,"Materiales entregados correctamente")
            reset()
            traerMateriales()
        }else{
            setNoAgregados(fallaron);
            setLoading(false)
            warningAlert(setAlert,"Algunos materiales no se entregaron")
            
        }
    }

    const Agregar = async(materialId,cantidad) =>{
        let stock = {
            tipoMaterialId : materialId,
            cantidad : parseInt(cantidad),
            almacenId : idAlmacen
        }
        if (validarAggStock(stock) === true) {
            let agg = await agregarStockAlmacen(stock)
            if (agg) {
                setModalAgregarStock(false)
                return {res:true, mensaje:"Stock almacenado correctamente!"}
            } else {
                return {res:false, mensaje:"Error al almacenar el Stock"}
            }
            
        } else {
                return {res:false, mensaje:"Campos incompletos!"}
        }
    }

  return (
    <>
        {Alert}
        <LoadingComponent loading={Loading} />
        <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={ModalAgregarStock}
        toggle={() => setModalAgregarStock(false)}
        >
            {NoAgregados?
                <div>
                <Card>
                    <CardHeader className="pb-3">
                    <div className="text-center"><b>Agregar Stock</b>
                    <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalAgregarStock(false)}
                >
                    <span aria-hidden={true}>×</span>
                </button>
                    </div>
                    </CardHeader>  
                    <CardBody>
                        <TablaNoAgregados
                            Titulo="Materiales No Agregados"
                            Mensaje="Error"
                            Materiales={NoAgregados}
                        />                 
                    </CardBody>
                    <CardFooter>
                        <div className="text-center">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => reset()}
                        >
                            Cerrar
                        </Button>                    
                        </div>
                        </CardFooter>                
                </Card>
                </div>
            :
            <div>
            <Card>
                <CardHeader className="pb-3">
                <div className="text-center"><b>Agregar Stock</b>
                <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalAgregarStock(false)}
            >
                <span aria-hidden={true}>×</span>
            </button>
                </div>
                </CardHeader>  
                <CardBody>
                    {Materiales.length>0?
                    <TablaAddStock
                        Titulo="Materiales Seleccionados"
                        Mensaje="Stock a Agregar"
                        Materiales={Materiales}
                    />
                :
                <h2 className="text-center">No hay materiales seleccionados</h2>}                 
                </CardBody>
                <CardFooter>
                    <div className="text-center">
                    <Button
                        className=""
                        color="primary"
                        type="button"
                        onClick={()=>agregarTodos()}
                        
                        >
                        Agregar
                    </Button>
                
                    <Button
                        color="secondary"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModalAgregarStock(false)}
                    >
                        Cerrar
                    </Button>                    
                    </div>
                    </CardFooter>                
            </Card>
            </div>
            }
        </Modal></>
  )
}

export default ModalAggStock