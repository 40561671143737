import React, {  useState } from 'react'
import { Button, ButtonGroup, Card, CardBody, CardImg, Col, Row} from 'reactstrap'
import { useHistory } from 'react-router'
import { postArregloMecanicoVehiculo, postObservacionVehiculo} from 'services/VehiculosServices';
import EtiquetaEstadoVe from '../components/EtiquetaEstadoVehiculo';
import ModalEditarVeh from '../components/ModalEditarVehiculo';
import ComentariosComponent from '../../../components/Comentarios'
import NavVehiculoParticular from '../components/NavVehiculoParticular'
import ModalAgregarComentario from '../components/ModalAggComentario';

const PerfilVehiculoParticular = (props) => {
    const {VehiculoPar, traerVehiculo, setVista} = props;

    const history= useHistory();
    const [ModalEditarVehiculo, setModalEditarVehiculo] = useState(false);
    const [Tabs, setTabs] = useState(1)
    const [ModalAggComentario, setModalAggComentario] = useState(false);
    const [TituloAgg, setTituloAgg] = useState("")

    const irGeneral = () => {
        history.replace(`/admin/Vehiculos`)
    
    }
    const irPapeles = () => {
        setVista("Tramites")
    }


    const accionBotonArreglos = () => {
        setTituloAgg("Arreglo Mecanico")
        setModalAggComentario(true)
    }

    const accionBotonObservaciones = () => {
        setTituloAgg("Observacion")
        setModalAggComentario(true)
    }





    return (
        <>
            {VehiculoPar?
                <>
                    <Card>
                        
                            <Row className="ml-3 mt-3" >
                                <Col>
                                <Row>
                                <Button className="btn-md btn-default ml-2"  onClick={()=>irGeneral()}>
                                    <i className="fas fa-arrow-left"></i>
                                </Button>
                                <h2 className="ml-2 mt-1">Vehiculo <EtiquetaEstadoVe traerVehiculo={traerVehiculo} Estado={VehiculoPar.estadoId} id={VehiculoPar.id}/></h2>
                                </Row>
                                </Col>
                                <Col>
                                <ButtonGroup className="float-right">

                                <Button className="btn-md mr-2 btn-default" onClick={()=>setModalEditarVehiculo(true)}>
                                    Editar
                                </Button>
                                
                                <Button className="btn-md btn-default mr-3 "  onClick={()=>irPapeles()}>
                                    Tramite del Vehiculo
                                </Button>
                                </ButtonGroup>
                                
                                </Col>
                            </Row>
                            
                        
                        <CardBody>

                        <Row>
                            <Col>
                            <CardImg src={VehiculoPar.foto}>
                                
                            </CardImg>
                            </Col>
                            <Col>
                            <Row className="mt-3">
                                <Col>
                                <b>PATENTE</b>
                                </Col>
                                <Col>
                                {VehiculoPar.patente}
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                <b>MARCA</b>
                                </Col>
                                <Col>
                                {VehiculoPar.marca}
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                <b>MODELO</b>
                                </Col>
                                <Col>
                                {VehiculoPar.modelo}
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                <b>AÑO</b>
                                </Col>
                                <Col>
                                {VehiculoPar.año}
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                <b>NUMERO DE MOTOR</b>
                                </Col>
                                <Col>
                                {VehiculoPar.nroMotor}
                                </Col>
                            </Row>
                            <Row className="mt-3 mb-3">
                                <Col>
                                <b>NUMERO DE CHASIS</b>
                                </Col>
                                <Col>
                                {VehiculoPar.nroChasis}
                                </Col>
                            </Row>
                            </Col>
                        </Row>
                        </CardBody>
                    </Card>
                    <Row>
                        <NavVehiculoParticular Tabs={Tabs} setTabs={setTabs}/>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md="10">
                        {Tabs === 1 ? 
                            <ComentariosComponent ArrayObjs={VehiculoPar.arreglosmecanicosVehiculos} Titulo={"Arreglos Mecanicos"} accionBoton={accionBotonArreglos}/>
                        :
                            <ComentariosComponent ArrayObjs={VehiculoPar.observacionesVehiculos} Titulo={"Observaciones"} accionBoton={accionBotonObservaciones}/>
                        }
                        </Col>
                        <Col></Col>
                    </Row>
                    <ModalAgregarComentario 
                        ModalAggComentario={ModalAggComentario} 
                        setModalAggComentario={setModalAggComentario} 
                        post={TituloAgg=="Arreglo Mecanico"?postArregloMecanicoVehiculo:postObservacionVehiculo}
                        TituloAgg={TituloAgg} 
                        vehiculoId={VehiculoPar.id} 
                        traerVehiculo={traerVehiculo}
                    />
                    <ModalEditarVeh
                        ModalEditarVehiculo={ModalEditarVehiculo}
                        setModalEditarVehiculo={setModalEditarVehiculo}
                        traerVehiculo={traerVehiculo}
                        idVehiculo={VehiculoPar.id}
                    />
                </>
            :
                <p>Cargando..</p>
            }
        </>
    );
}
 
export default PerfilVehiculoParticular;