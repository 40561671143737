import React, { useState } from 'react'
import {Button} from 'reactstrap'
import { putRendicion } from 'services/RendicionesServices';


const EtiquetaControl=(props)=>{
    const {Controlado, rendicionId, traerRendiciones, idEmpls} = props;

    const CambiarAControlado = async() => {
        let rendicion = {
          id: rendicionId,
          controlado: true
        };
        let con = await putRendicion(rendicion)
        if (con != null && con != '') {
            traerRendiciones()
        }
      }
      const CambiarANoControlado = async() => {
        let rendicion = {
          empleadoId: idEmpls,
          id: rendicionId,
          controlado: false
        };
        let con = await putRendicion(rendicion)
        if (con != null && con != '') {
            traerRendiciones()
        }
      }


    if(Controlado == true){
        return(
        <>
        
            <Button className="btn-icon btn-3 btn-sm btn-outline-success  "  type="button" onClick={() => CambiarANoControlado()}>
                              <span className="btn-inner--icon">    
                              <i class="fas fa-check"></i>
                              </span>  
            </Button>
                          
             </>
        )
    }else if(Controlado == false){
        return(
          <>
          
            <Button className="btn-icon btn-3 btn-sm btn-outline-danger " onClick={() => CambiarAControlado()}>
                              <span className="btn-inner--icon">    
                              <i class="fas fa-times"></i>
                              </span>
                              
                          </Button>
          </>
        )
    }else{
        return <></>
    }
}

export default EtiquetaControl;