import React, { useEffect, useState } from "react";
// import Imagen from 'assets/img/logoGlobalNet.png';
// import Imagen2 from 'assets/img/logoGlobalNet2.png';
//import Imagen1 from 'assets/img/logoGlobalNet4.png';
// import { Picture } from 'react-responsive-picture';
import '../styles/PagoComponentStyles.css';
import { Card, CardBody, CardHeader, CardImg, Col, Container, Row, Table } from "reactstrap";
import { getClientes } from "services/ClientesServices";
import { findPaymentInCrm } from "services/PagosService";
import { getFacturas } from "services/FacturasService";
import { getMetodosPagos } from "services/MetodosPagosService";


const PagoComponent = (props)=> {
  const { Pago, setPago, Factu, setFactu, IdPago, idClient, datos, setNombreFactura}= props;
  const tamanio= 10;
  const tamanioTotales = 10
  const countTamanioTotales =10
  const [FacturaId, setFacturaId] = useState("")
  const [subTotal, setSubTotal] = useState(0);
  const [Metodos, setMetodos] = useState([]);

  const traerPago=async()=>{
    let pago = await findPaymentInCrm(null, IdPago)
    if (pago !=null && pago !="") {
      setPago(pago)
    }

  }
  const traerFacts=async()=>{
    let facts = await getFacturas(null, idClient)
    if (facts !=null && facts !="") {
      setFactu(facts); 
    }
  }
  const BuscarInvoice=(paymentCovers) => {
    let dev = "-"
    paymentCovers.map((pay)=>{
        if (pay.paymentId == Pago.id){
            dev = pay.invoiceId
        }
       
      });

return buscarEtiquetaFactura(dev);
}
const BuscarAmount=(paymentCovers) => {
  
  let dev = "-"
  paymentCovers.map((pay)=>{
      if (pay.paymentId == Pago.id){
          dev = pay.invoiceId
      }
     
    });

return buscarAmountFactura(dev);
}
const BuscarItems=(items) => {
  let devv = "-"
  items.map((it)=>{
      if (items){
          devv = it.label
      }
     
    })
  return devv
}

  const buscarEtiquetaFactura=(invoice)=>{
    let f_label= "";
    Factu.map((f) => {
      if(f.id ==  invoice){
        f_label = `${BuscarItems(f.items)}`
        setNombreFactura(f_label)
      }
    })
    return f_label;
  }
  const buscarAmountFactura=(invoice)=>{
    let factura="";
    Factu.map((f) => {
      if(f.id ==  invoice){
        factura = `${f.subtotal}`
      }
    })
    return factura;
  }

  const obtenerFormatoFecha = (date)=>{
    let meses = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
    let fechaFormateada="-" 
    
    if(date){
      let fecha = new Date(date)
      let mes = meses[fecha.getMonth()]; 
      fechaFormateada = `${fecha.getDate()} de ${mes} de ${fecha.getFullYear()}`;
    }
    return fechaFormateada;
}
const ObtenerMetodo =()=>{
  let meto ="";

  Metodos.map(m=>{
    if (m.id==Pago.methodId) {
      meto = `${m.name}`;
      
    }
  })
return meto
}
const traerMetodos=async()=>{
  let met = await getMetodosPagos();
  if (met !=null && met !="") {
  setMetodos(met);
  }
}
  
 
  
  useEffect(() => {
    traerPago();
    traerFacts();
    traerMetodos();

  },[]);
  
  
  return (
    <>
    <Container  style={{paddingLeft: 6, paddingRight: 6}}>
        <Row>
         <Col style={{padding: 0}} xs={tamanio} sm={6}>
         <Card className="facturaHead"
          style={{
          border: "2px solid #000",
          borderRadius: "0px",
          margin:0,
          height: "8rem"
          }}>
            <Container className=" mr-3 mt-4">
              <CardImg
              alt="..."
              src={require("assets/img/logo-Global-Net1.png").default}
              top
            />
            </Container>
            
            </Card>
          </Col>       
          <Col  style={{padding: 0}} xs={tamanio} sm={6}>
          <Card
          style={{
            border: "2px solid #000",
            borderRadius: "0px",
            margin:0,
            height: "8rem"
            }}>
              <Container className="ml-2 mt-3">
                  <Row  xs={12} sm={12}>
                    <h3>
                        Comp. Nro: {Pago.id} <br/>
                        
                    </h3>                          
                    </Row>
                    <Row xs={12} sm={12}>
                    <h3>
                    Fecha de Facturación:{obtenerFormatoFecha(Pago.createdDate)}
                    </h3>                          
                    </Row>
                    <Row xs={12} sm={12}>
                    <h3>
                        CUIT: 30-71636210-4
                    </h3>                          
                    </Row>
                    </Container>
          </Card>
          </Col>
        </Row>
        <Row>
          <Col  style={{padding: 0}} xs={tamanio} sm={6}>
            <Card style={{
            border: "2px solid #000",
            borderRadius: "0px",
            margin:0,
            height: "100%"
            }}>
              <Container className="ml-2 mt-2">
                <Row xs={12} sm={12}>
                    <h3>
                    Global Net
                    </h3>                          
                </Row>
                <Row  xs={12} sm={12}>
                    <h3>
                    Independencia 936
                    </h3>                          
                </Row>
                <Row  xs={12} sm={12}>
                    <h3>
                    Zarate, Buenos Aires
                    </h3>                          
                </Row>
              </Container>
            </Card> 
          </Col>
           <Col  style={{padding: 0}} xs={tamanio} sm={6}>
            <Card  style={{
              border: "2px solid #000",
              borderRadius: "0px",
              margin:0,
              height: "9rem"}}>
            <Container className="ml-2 mt-2">
                  
                  <Row  xs={12} sm={12}>
                        <h3>
                        {datos.firstName} {datos.lastName}
                        </h3>                          
                  </Row>
                  <Row xs={12} sm={12}>
                        <h3>
                            {datos.userIdent}
                        </h3>                          
                  </Row>
                  <Row  xs={12} sm={12}>
                        <h3>
                          {datos.street1} {datos.city}
                        </h3>                          
                  </Row>
                </Container>
                    
            
            </Card>
          </Col>
        </Row>
        <Row>
        <Col style={{padding: 0}} xs={tamanio} sm={12}>
        <Card style={{
            border: "2px solid #000",
            borderRadius: "0px",
            margin:0,
            }}>
        <Table style={{width:"100%"}}>
        <thead className=" table-primary tableHead text-center">
            <tr>
            <th className="float-left">METODO DE PAGO</th>
            </tr>
        </thead>
        <tbody>
            <tr className="text-center" ><th scope="row">
              <Row>

              <Col>
                <span className="float-left">
                  {ObtenerMetodo()}
                </span>
              </Col>
              <Col>
              <span className="float-right">
                ${Pago.amount}
                </span>
              </Col>
              </Row>
                  </th>
          </tr>
          </tbody>

        </Table>
        </Card>
        </Col>
        </Row>
        <Row>
        <Col style={{padding: 0}} xs={tamanio} sm={12}>
        <Card style={{
            border: "2px solid #000",
            borderRadius: "0px",
            margin:0,
            }}>
        <Table style={{width:"100%"}}>
        <thead className=" table-primary tableHead text-center">
            <tr>
            <th className="float-left">ORDENES ALCANZADAS CON EL PAGO</th>
            </tr>
        </thead>
        <tbody>
            <tr className="text-center" ><th scope="row">
              <Row>
                <Col>
                        <span className="float-left">
                          {Pago.paymentCovers?
                          BuscarInvoice(Pago.paymentCovers)
                          :
                          <></>}
                        </span>
                </Col>
                <Col>
                <span className="float-right">
                SubTotal: ${Pago.paymentCovers?
                          BuscarAmount(Pago.paymentCovers)
                          :
                          <></>}
                        </span>
                </Col>
              </Row>
              <Row className="float-right mr-1">
                  Total : $ {Pago.amount}
              </Row>
                  </th>
          </tr>
        </tbody>

        </Table>
        </Card>
        </Col>
        </Row>
        </Container>
      </>
    );
  }



 
export default PagoComponent;