import LoadingComponent from 'pages/components/Loading';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { Button, Table } from 'reactstrap'
import { getCierreCaja } from 'services/CierreCajaService'
import ControladoCpt from './ControladoComponent';
import ModalEditarCierreCaja from './ModalEditarCierre';
import moment from 'moment';
const TableCierreCaja = (props) => {
  const history = useHistory ();
  const [Cierre, setCierre] = useState([]);
  const [Visible, setVisible] = useState(false);
  const [alertSweet, setAlert] = useState(null);
  const [ModalEditCierre, setModalEditCierre] = useState(false);
  const [CierreSeleccionado, setCierreSeleccionado] = useState(null);


  const traerCierresCaja = async() => {
    setVisible(true)
    let cierres = await getCierreCaja(props.puntoVentaId);
    if (cierres !== null && cierres !== '') {
        setCierre(cierres);
    }
    setVisible(false)
  };



  const irContadores = (id) => {
    history.replace(`/admin/contadores/${id}`)
  }
  const ActualizarCierre = (ci)=>{
    setCierreSeleccionado(ci);
    setModalEditCierre(true); 
  }


  useEffect(() => {
      traerCierresCaja()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  return (
    <>
    {alertSweet}
    <LoadingComponent Visible={Visible}/>
    <Table className="align-items-center" responsive>
    <thead className="thead-light text-center">
            <tr>
              <th scope="col">ID</th>
              <th scope="col">DINERO INGRESADO</th>
              <th scope="col">DINERO ACUMULADO</th>
              <th scope="col">FECHA</th>
              <th scope="col">CONTROLADO</th>
              <th scope="col">VER</th>
              <th scope="col">EDITAR</th>
            </tr>
    </thead>
    <tbody>
      

          {Cierre && Cierre.length > 0?                      
            Cierre.map((cc, index) => {
              return (
                  <tr key={index}>
                    <th scope="row">
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            {cc.id}
                          </span>
                    </div>
                    </th>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            $ {cc.importe}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            $ {cc.dineroAcumulado}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                            <span className="mb-0 text-sm">
                            
                            {moment(cc.fecha).format('DD/MM/YYYY')}
                            </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                    <ControladoCpt  setAlert={setAlert} Cierre={cc} Controlado={cc.controlado} traerCierresCaja={traerCierresCaja}/>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                          <Button className="btn-icon btn-3 btn-sm btn-outline-info" type="button" onClick={()=>irContadores(cc.id)}>
                              <span className="btn-inner--icon">
                              <i className="fas fa-eye"></i>                              
                              </span>                                                           
                          </Button>    
                    </div>
                    </td>
                    
                    <td>                 
                    <div className="text-center">
                          <Button className="btn-icon btn-3 btn-sm btn-outline-default" type="button" onClick={()=>{ActualizarCierre(cc)}}>
                              <span className="btn-inner--icon">
                              <i className="ni ni-settings"/>
                              </span>
                              
                          </Button>
                    </div>
                    </td>
                    
                  </tr>
                  
                  
                )

              })
            :
            <tr>
                  <td>
                      <p>Cargando...</p>
                  </td>
            </tr>
            }
    </tbody>

    </Table>
    {CierreSeleccionado !=null?
    <ModalEditarCierreCaja
    CierreSelect={CierreSeleccionado}
    ModalEditCierre={ModalEditCierre}
    setModalEditCierre={setModalEditCierre}
    setAlert={setAlert}/>
    :
    <>-</>}
    </>
  )
}
export default TableCierreCaja