import SelectorGenerico from 'pages/components/SelectorGenerico';
import SelectorZonas from 'pages/components/SelectorZonas';
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap';
import { getPuntosVentas } from 'services/PuntosVentaService';
import { putAsignarRolUsuario } from 'services/RolesService';
import { getRoles } from 'services/RolesService';
import { putAsignarPuntoVenta } from 'services/UsuariosService';
import { putResetearContraseña } from 'services/UsuariosService';
import { putEditarUsuario } from 'services/UsuariosService';

const ModalEditUsuario =(props)=> {
    const {ModalEditarUsuario, setModalEditarUsuario, traerUsuarios, UsuarioSelec}= props;
    const [Rol, setRol]= useState("");
    const [UNMS, setUNMS]= useState("");
    const [Contraseña, setContraseña]= useState("");
    const [Punto, setPunto]= useState("");
    const [PuntosVenta, setPuntosVenta] = useState([]);
    const [ChatId, setChatId]= useState("");
    const [Alert, setAlert]= useState("");
    const [ Roles, setRoles]= useState([]);
    

    const cambiarChatId = event => {
        setChatId(event.target.value);
    };
    const cambiarContraseña = event => {
        setContraseña(event.target.value);
    };
    const cambiarUNMS = event => {
        setUNMS(event.target.value);
    };


      const actualizarUsuario= async()=>{
        let usuarioAux = {}
        Object.assign(usuarioAux, UsuarioSelec)
        usuarioAux.chatId = ChatId;
        usuarioAux.unmsId = UNMS;
        usuarioAux.puntoVentaId = Punto;
        let valido = await putEditarUsuario(usuarioAux);
        if(valido){
          successAlert(setAlert,'Usuario Actualizado');
          recetearUsuario()
          setModalEditarUsuario(false);
          traerUsuarios()
        }else{
          warningAlert(setAlert,'No se pudo Editar el Usuario')
      }
      
      }
      const actualizarContraseña= async()=>{
        let usuarioAux = {}
        Object.assign(usuarioAux, UsuarioSelec)
        usuarioAux.usuario = UsuarioSelec.id;
        usuarioAux.password = Contraseña
        let valido = await putResetearContraseña(usuarioAux);
        if(valido){
          successAlert(setAlert,'Usuario Actualizado');
          setModalEditarUsuario(false);
          traerUsuarios()
        }else{
          warningAlert(setAlert,'No se pudo Editar el Usuario')
      }
      
      }
      const actualizarRol= async()=>{
        let rq = {id:UsuarioSelec.id,roleId:parseInt(Rol)}
        let valido = await putAsignarRolUsuario(rq);
        if(valido){
          successAlert(setAlert,'Usuario Actualizado');
          setModalEditarUsuario(false);
          traerUsuarios()
        }else{
          warningAlert(setAlert,'No se pudo Editar el Usuario')
      }
      
      }
      const actualizarPuntoVenta= async()=>{
        let usuarioAux = {}
        Object.assign(usuarioAux, UsuarioSelec)
        usuarioAux.usuario = UsuarioSelec.id;
        usuarioAux.puntoVentaId = Punto
        let valido = await putAsignarPuntoVenta(usuarioAux);
        if(valido){
          successAlert(setAlert,'Usuario Actualizado');
          setModalEditarUsuario(false);
          traerUsuarios()
        }else{
          warningAlert(setAlert,'No se pudo Editar el Usuario')
      }
      
      }
      const establecerUsuario= () => {
          setChatId(UsuarioSelec.apellido)
          setUNMS(UsuarioSelec.telefono)
          setContraseña(UsuarioSelec.direccion)
          setPunto(UsuarioSelec.formaContacto)
          setRol(UsuarioSelec.usuarioContacto)
      }
      const recetearUsuario = () => {
        setChatId("")
        setUNMS("")
        setContraseña("")
        setPunto("")
        setRol("")
      }
      const traerRoles=async()=>{
        let roles= await getRoles();
        if (roles !=null && roles !="") {
            setRoles(roles);
        }
    }
    
    const traerPuntosVenta=async()=>{
      let pv= await getPuntosVentas();
      if (pv !=null && pv !="") {
        setPuntosVenta(pv);
      }
    }
    
  useEffect(() => {
    traerPuntosVenta();
    traerRoles();
    establecerUsuario();

}, [])

    return (
       <>
       {Alert}
       
       <Modal
       className="modal-dialog-centered"
       size="sm"
       isOpen={ModalEditarUsuario}
       toggle={() => setModalEditarUsuario(false)}
     >
     <div className="modal-body p-0">
       <Card className="bg-secondary shadow border-0">
         <CardHeader className="bg-transparent pb-5">
           <div className="text-center"><b>Editar Usuario</b>
           <button
             aria-label="Close"
             className="close"
             data-dismiss="modal"
             type="button"
             onClick={() => setModalEditarUsuario(false)}
           >
             <span aria-hidden={true}>×</span>
           </button>
           </div>
           <div className="text-muted mt-2 mb-3">
           <Form role="form">
           <FormGroup className="mb-3">
                 <h1>Usuario id: {UsuarioSelec.id}</h1>
             </FormGroup>
             <FormGroup className="mb-3" >Contraseña:
                 <Input 
                 placeholder="contraseña..." 
                 type="text" 
                 value={Contraseña} 
                 onChange={cambiarContraseña}
                 />
             </FormGroup>
           <FormGroup className="mb-3" >Punto Venta:
                  <SelectorGenerico 
                  value={Punto} 
                  setValor={setPunto} 
                  arrayElementos={PuntosVenta}
                  />
             </FormGroup>
             <FormGroup > Rol:
                <SelectorGenerico 
                value={Rol} 
                setValor={setRol} 
                arrayElementos={Roles}
                />
            </FormGroup>
             <FormGroup > Chat Id:
                 <Input 
                 placeholder="chat id..." 
                 type="text" 
                 value={ChatId} 
                 onChange={cambiarChatId}
                 />
             </FormGroup>
             <FormGroup > unmsId:
                 <Input 
                 placeholder="unmsId..." 
                 type="text" 
                 value={UNMS} 
                 onChange={cambiarUNMS}
                 />
             </FormGroup>
             <div className="text-center">
               <Button
                 className="my-4 btn-sm"
                 color="primary"
                 type="button"
                 onClick={()=>actualizarRol()}
               >
                 Modificar <br/>
                  Rol
               </Button>
               <Button
                 className="my-4 btn-sm"
                 color="primary"
                 type="button"
                 onClick={()=>actualizarContraseña()}
               >
                 Reestablecer<br/> 
                 Contraseña
               </Button>
               <Button
                 className="my-4 btn-sm"
                 color="primary"
                 type="button"
                 onClick={()=>actualizarPuntoVenta()}
               >
                 Asignar <br/>
                 Punto de Venta
               </Button>
               <Button
                 color="secondary"
                 data-dismiss="modal"
                 type="button"
                 onClick={() => actualizarUsuario()}
               >
                 Editar Usuario
               </Button> 
           
               <Button
                 color="secondary"
                 data-dismiss="modal"
                 type="button"
                 onClick={() => setModalEditarUsuario(false)}
               >
                 Cerrar
               </Button>                    
             </div>
           </Form>                     
           </div>    
         </CardHeader>                  
       </Card>
     </div>
     </Modal>
     
               
       </>
    )
}


export default ModalEditUsuario
