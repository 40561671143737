import { useEffect, useState } from "react";
import { Col, Container, Card, FormGroup, Button, Input, Navbar, Row, Table } from "reactstrap";
import { getMetodosPagos, getGruposMetodosPagos } from "../../../services/MetodosPagosService";
import { getPagos } from "../../../services/PagosService";
import LoadingComponent from 'pages/components/Loading';
import { warningAlert } from 'pages/components/SweetAlert';
import PaginacionFacturacion from "./components/PaginacionFacturacion";
import { ModalDiferencias } from "./components/ModalDiferencias";
import { getCaes } from "services/CaesServices";
export const ReportesFacturacion = () => {
    // resumen = conteo de montos
    const [mediosdepago, setMediosDePago] = useState([]);
    const [gruposMediosPago, setGruposMediosPago] = useState([]);
    const [fecha, setFecha] = useState({});
    const [medioPagoSeleccionado, setMedioPagoSeleccionado] = useState([]);
    const [Alert, setAlert] = useState(null);
    const [resumenPagosPaginado, setResumenPagosPaginado] = useState([])
    const [Visible, setVisible] = useState(false);
    const [total, setTotal] = useState(0);
    const [desHabilitarGrupo, setDeshabilitarGrupo] = useState(false);
    const [desHabilitarMedio, setDeshabilitarMedio] = useState(false);
    const [Catidad, setCatidad] = useState(0)
    const [Pagina, setPagina] = useState(0)
    const [Paginas, setPaginas] = useState(0)
    const [resumenPagos, setResumenPagos] = useState([])
    const [defaultModal, setdefaultModal] = useState(false);
    const [Caes, setCaes] = useState(null);


    const handleGetCaes = async () => {
        let queryParams = {
            fechaInicioFacturacion: fecha.desde,
            fechaFinFacturacion: fecha.hasta,
        }
        try {
            setVisible(true);
            const caes = await getCaes(queryParams);
            setCaes(caes);
        }
        catch (error) {
            alert("Algo salio mal");
        } finally {
            setVisible(false);
        }


    }

    const abrirModalDiferencias = async() => {
        setdefaultModal(true)
    }

    const compararPorMonto = (a, b) => {
        return b.monto - a.monto;
      }

  
    
    const GenerarResumenPagos = (pagos) => {
        let cantidadPagos = pagos.length
        setCatidad(cantidadPagos)
        let conteoMontos = [];
        let total = 0
        for (let i = 0; i < cantidadPagos; i++) {
            const pago = pagos[i];
            const monto = pago.amount;
            let encontrado = false;
            for (let j = 0; j < conteoMontos.length; j++) {
                if (conteoMontos[j].monto === monto) {
                    total += monto
                    conteoMontos[j].cantidad += 1;
                    encontrado = true;
                    break;
                }
            }

            if (!encontrado) {
                total += monto
                conteoMontos.push({ monto: monto, cantidad: 1 });
            }
        }
        conteoMontos.sort(compararPorMonto);

        setTotal(total)
        setResumenPagos(conteoMontos)
        let conteoMontosCpy = [...conteoMontos]
        dividirEnPaginas(conteoMontosCpy)
    }

    const dividirEnPaginas = (conteoMontos) => {
        let arregloOriginal = conteoMontos,
            arregloDeArreglos = [];
        
        while (arregloOriginal.length > 0) {
            arregloDeArreglos.push(arregloOriginal.slice(0, 20));
            if (arregloOriginal.length <= 20) {
                break;
            }        
            arregloOriginal = arregloOriginal.slice(20);
        }

        establecerPaginas(arregloDeArreglos)
        setResumenPagosPaginado(arregloDeArreglos)

    }

    const establecerPaginas = (arregloDeArreglos) => {
        let pages = []
        let pagina = -1
        for (let i = 0; i < arregloDeArreglos.length; i++) {
            pages.push(pagina + 1)
            pagina++

        }
        setPaginas(pages)
    }

    const cambiarFechaInicio = (event) => {
        setFecha(old => {
            setFecha({ desde: event.target.value, hasta: old.hasta })
        })
    }

    const cambiarFechaFin = (event) => {
        setFecha(old => {
            setFecha({ desde: old.desde, hasta: event.target.value })
        })
    }

    const cambiarMedioDePago = (e) => {
        if (e.target.value) {
            setDeshabilitarGrupo(true)
            setMedioPagoSeleccionado([e.target.value])
        }
        else {
            setDeshabilitarGrupo(false)
        }
    }


    const seleccionarGrupoMedioPago = (e) => {

        if (e.target.value) {
            setDeshabilitarMedio(true)
            let uids = gruposMediosPago.find(grupo => grupo.nombre === e.target.value).uids;
            setMedioPagoSeleccionado(uids);
        }
        else {
            setDeshabilitarMedio(false)
        }

    }

    const BuscarPagos = async () => {
        try {
            if (medioPagoSeleccionado.length === 0) {
                warningAlert(setAlert, 'Seleccione un medio de pago')
                return
            }

            if (!fecha.desde || !fecha.hasta) {
                warningAlert(setAlert, 'Seleccione ambas fechas')
                return
            }

            setVisible(true)
            let params = {
                from: fecha.desde,
                to: fecha.hasta,
                methodIds: medioPagoSeleccionado
            }
            await handleGetCaes();
            console.log("params", params)
            getPagos(params).then(data => {
                GenerarResumenPagos(data);
            });

        } catch (error) {
            alert(error.message)
        }finally{
            setVisible(false)
        }

    }


    useEffect(() => {
        //setVisible(true);
        getMetodosPagos().then(mp => {
            setMediosDePago(mp)
            setVisible(false);
        }).catch(error => setVisible(false))

        getGruposMetodosPagos().then(data => {
            setGruposMediosPago(data);
        }).catch(error => console.log(error))

    }, [])



    return (
        <>
            {Alert}
            <LoadingComponent Visible={Visible} />
            <div className="d-flex justify-content-center flex-column align-items-center">
                <Card className="mt-3">
                    <Navbar
                        className="navbar-horizontal navbar-light bg-secondary"
                        expand="lg"
                    >
                        <Container fluid>

                            <Row className="mt-2 d-flex justify-content-center align-items-center">


                                <Col>
                                    <FormGroup>
                                        <label
                                            className="mb-3"
                                            htmlFor="example-date-input"
                                        >
                                            Fecha Incio:
                                        </label>
                                        <Input

                                            id="example-date-input"
                                            type="date"
                                            onChange={cambiarFechaInicio}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <label
                                            className="mb-3"
                                            htmlFor="example-date-input"
                                        >
                                            Fecha Fin:
                                        </label>
                                        <Input

                                            id="example-date-input"
                                            type="date"
                                            onChange={cambiarFechaFin}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <label
                                            className="mb-3"
                                            htmlFor="example-date-input"
                                        >
                                            Grupo
                                        </label>
                                        <Input id="exampleFormControlSelect1" type="select" onChange={seleccionarGrupoMedioPago} disabled={desHabilitarGrupo}>
                                            <option value={""}>Opciones...</option>
                                            {gruposMediosPago && gruposMediosPago.length !== 0 ?

                                                gruposMediosPago.map((gp, index) => {
                                                    return (
                                                        <option key={gp.nombre} value={gp.nombre}>{gp.nombre}</option>
                                                    )

                                                })
                                                :
                                                <option value={""}>Cargando..</option>
                                            }
                                        </Input>
                                    </FormGroup>

                                </Col>


                                <Col>
                                    <FormGroup>
                                        <label
                                            className="mb-3"
                                            htmlFor="example-date-input"
                                        >
                                            Medio
                                        </label>
                                        <Input id="exampleFormControlSelect1" type="select" value={medioPagoSeleccionado} onChange={cambiarMedioDePago} disabled={desHabilitarMedio}>
                                            <option value={""}>Opciones...</option>
                                            {mediosdepago && mediosdepago.length !== 0 ?

                                                mediosdepago.map((em, index) => {
                                                    return (
                                                        <option key={index} value={em.id}>{em.name}</option>
                                                    )

                                                })
                                                :
                                                <option value={""}>Cargando..</option>
                                            }
                                        </Input>
                                    </FormGroup>

                                </Col>
                                <Col className="mt-3">
                                <Button onClick={() => BuscarPagos()} className="btn-md btn-outline-success" disabled={Visible} style={{height:"50%"}}>
                                    Buscar
                                </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Navbar>
                    <Container className="d-flex align-items-center flex-column" fluid>
                        {resumenPagos && resumenPagos.length > 0 ?
                            <Row className="mt-2 d-flex justify-content-center align-items-center">
                                <Col>
                                <Button onClick={() => abrirModalDiferencias()} color="warning" outline type="button">
                                    Ver Diferencia
                                </Button>
                                </Col>    
                            </Row>
                        :null}
                        <Row style={{ width: "100%" }} className="text-center mt-3">
                            <Col><h1>Total : $ {total}</h1></Col>
                            <Col><h1>Catidad Total:  {Catidad}</h1></Col>
                        </Row>

                        <Table className="text-center" responsive>
                            <thead className="thead-light text-center">
                                <tr>
                                    <th scope="col">Monto</th>
                                    <th scope="col">Cantidad</th>
                                </tr>
                            </thead>
                            <tbody>
                                {resumenPagosPaginado && resumenPagosPaginado.length > 0 ?
                                    resumenPagosPaginado[Pagina].map((cc, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>$ {cc.monto}</td>
                                                <td>{cc.cantidad}</td>
                                            </tr>
                                        )

                                    })
                                    : null
                                }
                            </tbody>

                        </Table>
                        <PaginacionFacturacion Pagina={Pagina} setPagina={setPagina} Paginas={Paginas} />
                    </Container>
                </Card>
            </div>
            {defaultModal && Caes && resumenPagos && (resumenPagos.length > 0 || Caes.length > 0) ?
                <ModalDiferencias defaultModal={defaultModal} resumenPagos={resumenPagos} Caes={Caes} setdefaultModal={setdefaultModal}/>
                :
                <></>
            }
        </>
    );
}