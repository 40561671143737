import React, { useState } from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from "prop-types";


export default function PaginacionFacturacion({ Paginas, Pagina, setPagina }) {

    const [estado, setState] = useState(null);

    const toggleNavs = (e, state, index) => {
        e.preventDefault();
        setState(index);
    };



    return (
        <>

            <nav aria-label="Page navigation example">
                <Pagination
                    className="pagination justify-content-center flex-column flex-sm-row"
                    id="tabs-text"
                    role="tablist"
                >
                    {Paginas[Pagina - 1] >= 0 ?
                        <PaginationItem>
                            <PaginationLink

                                onClick={() => setPagina(Pagina - 1)}
                                tabIndex="-1"
                                role="tab"
                            >
                                <i className="fa fa-angle-left" />
                                <span className="sr-only">Atras</span>
                            </PaginationLink>
                        </PaginationItem>
                        :
                        <></>
                    }
                    {Paginas ?
                        Paginas.map((p, index) => {
                            return (
                                <PaginationItem key={index}>
                                    <PaginationLink
                                        aria-selected={estado === p}
                                        className={Pagina === p ? "mb-sm-3 mb-md-0 bg-default text-white" : "mb-sm-3 mb-md-0 "}
                                        onClick={e => { toggleNavs(e, "navPills", p); setPagina(p) }}
                                        role="tab"
                                    >
                                        {p}
                                    </PaginationLink>
                                </PaginationItem>

                            )
                        })
                        :
                        <></>
                    }
                    {Paginas[Pagina + 1] ?
                        <PaginationItem>
                            <PaginationLink onClick={() => setPagina(Pagina + 1)} role="tab">
                                <i className="fa fa-angle-right" />
                                <span className="sr-only">Adelante</span>
                            </PaginationLink>
                        </PaginationItem>
                        :
                        <></>
                    }
                </Pagination>
            </nav>
        </>
    )
}
PaginacionFacturacion.defaultProps = {
    toggleSidenav: () => { },
    sidenavOpen: false,
    theme: "dark",
};
PaginacionFacturacion.propTypes = {
    toggleSidenav: PropTypes.func,
    sidenavOpen: PropTypes.bool,
    theme: PropTypes.oneOf(["dark", "light"]),
};