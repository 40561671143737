import React from 'react'
import {Table } from 'reactstrap'

const TablePagoEspe = (props) => {
  const {Pago}=props;
  
  const transformNumber=(number)=>{
    let nString = number.toString();

    return nString.substring(0,nString.length - 2);

  }
  const obtenerFormatoFecha = (fechaIngreso)=>{
    let fecha = new Date(fechaIngreso);
    let dia = fecha.getDate();
    let mes = fecha.getMonth();
    let ano = fecha.getFullYear();
    let fechaFormateada = `${dia}/${mes+1}/${ano}`;
    return fechaFormateada;
  };
  const BuscarId=(request) => {
    let devl = "-"
    request.map((rec)=>{
            devl = rec.id
    })
    return devl;
    }
    const BuscarMedio=(request) => {
        let devl = "-"
        request.map((rec)=>{
                devl = rec.channel
    })
    return devl;
    }
    const BuscarImporte=(request) => {
        let devl = "-"
        request.map((rec)=>{
                devl = rec.amount
    })
    return devl;
    }
    const BuscarFechaPago=(request) => {
        let devl = "-"
        request.map((rec)=>{
                devl = obtenerFormatoFecha(rec.paid_at)
    })
    return devl;
    }

  
  return (
      <>
       <Table className="align-items-center mt-4" responsive>
          <thead className="thead-light text-center">
            <tr>
              <th scope="col">ID</th>
              <th scope="col">IMPORTE</th>
              <th scope="col">MEDIO DE PAGO</th>
              <th scope="col">FECHA DEL PAGO</th>
            </tr>
          </thead>
          {Pago?

              <tbody>
                  <tr>
                    <th scope="row">
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            {Pago.request_result?
                            BuscarId(Pago.request_result)
                        :
                        <p>Cargando...</p>
                        }
                          </span>
                    </div>
                    </th>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                          {Pago.request_result?
                            BuscarImporte(Pago.request_result)
                        :
                        <p>Cargando...</p>
                        }
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                          {Pago.request_result?
                            BuscarMedio(Pago.request_result)
                        :
                        <p>Cargando...</p>
                        }
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                            <span className="mb-0 text-sm">
                            {Pago.request_result?
                            BuscarFechaPago(Pago.request_result)
                        :
                        <p>Cargando...</p>
                        }
                            </span>
                    </div>
                    </td>
                  </tr>
                  
                  
          </tbody>

            
          :
          <p>Cargando..</p>
          }
          
        </Table>
        
      </>
  )
}

export default TablePagoEspe