import { urlGlobal2 } from './GlobalService';

  export const getRegistroMateriales = async(params=null) =>{
    let url = new URL(`${urlGlobal2}/registromateriales`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}
