import React, { useEffect, useState } from 'react'
import { CardTitle, Col, Row } from 'reactstrap'

const CardDineroUtilizado = props => {
    const {depositos, egresos}= props;
    const [ImporteEgreso, setImporteEgreso] = useState(null);
    const [ImporteDeposito, setImporteDepositos] = useState(null);
    const calcularImporte=()=>{
        let importeEgreso=0;
        let importeDeposito=0;
        
        egresos.forEach(egreso => {
          importeEgreso = importeEgreso + egreso.importe
      });
      depositos.forEach(depo => {
        importeDeposito = importeDeposito + depo.importe
      });
      setImporteEgreso(importeEgreso);
      setImporteDepositos(importeDeposito);
      }
      useEffect(() => {
        calcularImporte()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
  return (
      <>
      <Row >
                  <Col >
                    <CardTitle className=" h5  text-muted mb-0">
                      DINERO UTILIZADO
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">{ImporteEgreso+ImporteDeposito}</span>
                  </Col>
                  <Col >
                    <div className="icon icon-shape bg-red text-white rounded-circle shadow">
                    <i className="fas fa-user-times"></i>
                    </div>
                  </Col>
                </Row>
      </>
  )
}

export default CardDineroUtilizado