import { Card, CardBody, CardFooter, CardHeader, NavbarBrand } from 'reactstrap'
import { TableDatosClientes } from './components/TableDatosClientes'
import { React, useState, useEffect } from 'react'
import PaginacionDatosClientes from './components/PaginacionDatosClientes'
import LoadingComponent from 'pages/components/Loading'
import { getItemsClientes } from 'services/ItemsClientesService'
import BuscadorDatosClientes from './components/BuscadorDatosClientes'

export const DatosClientes = () => {
    const [Clientes, setClientes] = useState([])
    const [Visible, setVisible] = useState(false)

    const traerClientes = async () => {
        setVisible(true)
        let clientes = await getItemsClientes({page:1})
        if (clientes) {
            setClientes(clientes.itemsClientes)
        }
        setVisible(false)
    }


    useEffect(() => {
        traerClientes()
    }, [])

    return (
        <>
            <LoadingComponent Visible={Visible} />
            <Card style={{ width: '100%', padding: '3rem' }}>
                <CardHeader>
                    <h1>Datos Clientes</h1>
                    <NavbarBrand>
                        <BuscadorDatosClientes traerObjetos={traerClientes} setObjetos={setClientes} getObjetos={getItemsClientes} />
                    </NavbarBrand>
                </CardHeader>
                <CardBody>
                    <TableDatosClientes Clientes={Clientes} />
                </CardBody>
                <CardFooter>
                    <PaginacionDatosClientes traerClientes={traerClientes} getClientes={getItemsClientes} setClientes={setClientes} />
                </CardFooter>
            </Card>
        </>
    )
}
