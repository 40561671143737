import { urlGlobal2 } from './GlobalService.js';

export const getServicioCrm = async(params=null, id) =>{
    let url = new URL(`${urlGlobal2}/servicios/findInCrm/${id}`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
        
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        return null;
    }
}
export const getServiciosClientes = async(params=null) =>{
    let url = new URL(`${urlGlobal2}/servicios`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
        
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        return null;
    }
}
export const getTiposPlanes = async(params=null) =>{
    let url = new URL(`${urlGlobal2}/planes/all`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
        
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}
export async function postServicio(servi){
    let url = `${urlGlobal2}/servicios`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(servi)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }

  export async function putPlan(srv){
    let url = `${urlGlobal2}/servicios/changeServiceType`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'PUT';
        let body = JSON.stringify(srv)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
}
export async function putServicio(srv){
    let url = `${urlGlobal2}/servicios`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'PUT';
        let body = JSON.stringify(srv)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
}

export async function putAsignar(srva){
    let url = `${urlGlobal2}/servicios/asignar`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'PUT';
        let body = JSON.stringify(srva)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
}
export async function putEstadoAsignacion(serv){
    let url = `${urlGlobal2}/servicios/asignar`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'PUT';
        let body = JSON.stringify(serv)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }

  export async function putActualizarMac(accm){
    let url = `${urlGlobal2}/servicios/update/macEquipo`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'PUT';
        let body = JSON.stringify(accm)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }
  export async function putActualizarUsuario(accu){
    let url = `${urlGlobal2}/servicios/update/radiusUsername`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'PUT';
        let body = JSON.stringify(accu)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }
  export async function putEliminarServicio( idServicio){
    let url = `${urlGlobal2}/servicios/end/${idServicio}`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'PUT';
        let body = JSON.stringify(idServicio)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }
// export async function deleteServicio(idServicio){
//     let url = `${urlGlobal}/servicios/end/${idServicio}`;
//     try {
//         let token = localStorage.getItem('token');
//         
//         let headers = {'Content-Type':'application/json','token':token}
//         let method= 'DELETE';
//         const resp = await fetch(url, {headers, method});
//         const data = await resp.json()
//         return data;
//     } catch (error) {
//         
//         return null;
//     }
//   }