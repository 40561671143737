import React, { useState } from 'react'
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, Modal, Table } from 'reactstrap'
import { entregarMaterial } from 'services/AlmacenesService';
import SelectorEmpleados from 'pages/components/SelectorEmpleados';
import LoadingComponent from 'pages/components/Loading';

const TablaAddStock = ({Titulo,Mensaje, Materiales}) => {
    
  return (
    <>
        <Card>
            <CardHeader className="pb-3">
                <div className="text-center"><b>{Titulo}</b></div>
            </CardHeader>
            <CardBody>
                <Table className="align-items-center table-flush" hover responsive>
                    <thead className="thead-light">
                    <tr>
                        <th>Material</th>
                        <th>{Mensaje}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Materiales.map((mt, index) => {
                        return (
                        <tr key={index}>
                            <td className="table-user">
                            <b>{mt.tipoMaterial.tiposMateriale.nombre}</b>
                            </td>
                            <td>
                            {mt.cantidad}
                            </td>
                        </tr>
                        )
                    })}
                    
                    </tbody>
                </Table>
            </CardBody>               
        </Card>
    </>
  )
}


export default TablaAddStock