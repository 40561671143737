import React from 'react'


function EtiquetaPrioridad(props){
    const {Prioridad} = props
    if(Prioridad === "1"){
        return(
            <span className="mb-0 text-sm">Alta</span>
        )
    }else if(Prioridad === "2"){
        return(
            <span className="mb-0 text-sm">Media</span>
        )
    }else if(Prioridad === "3"){
        return(
            <span className="mb-0 text-sm">Baja</span>
        )
    }else{
        return <span className="mb-0 text-sm">-</span>
    }
}

export default EtiquetaPrioridad;