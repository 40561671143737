import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Table,Card } from 'reactstrap';
import {getPuntosVentas} from "../../../services/PuntosVentaService";
import Resumen from './components/Resumen';

export const PuntosDeVenta = () => {

    const [puntosDeVenta,setPuntosDeVenta] = useState([]);
    const history = useHistory();

    const redirigirCierreCaja = (id) => {
        history.push(`/admin/cierreCaja/${id}`)
    }

    useEffect(() => {
        getPuntosVentas().then(data => setPuntosDeVenta(data));
    },[])

    return(
        <>

        <Card>

        <Table className="align-items-center" responsive>
                <thead className="thead-light text-center">
                <tr>
                    
                    <th scope="col">ID</th>
                    <th scope="col">NOMBRE</th>
                    <th scope="col">DIRECIÓN</th>
                    
                </tr>
                </thead>
                <tbody>
                {puntosDeVenta && puntosDeVenta.length !== 0?
                puntosDeVenta.map((pv, index)=>{
                    return (
                    <tr key={index}>
                        <td>
                        <div className="text-center">
                                <span className="mb-0 text-sm">
                                    <b>{pv.id}</b>
                                </span>
                            </div>
                        </td>
                        <td>
                        <div className="text-center">
                                <span className="mb-0 text-sm">
                                    {pv.nombre}
                                </span>
                        </div>
                        </td>

                        <td>
                        <div className="text-center">
                                <span className="mb-0 text-sm">
                                    {pv.direccion}
                                </span>
                        </div>
                        </td>
                        <td>
                            <div className=" text-center" >
                                    <Button className="btn-sm btn-outline-info" onClick={()=>{redirigirCierreCaja(pv.id)}}>
                                    Ver Cierre de Caja
                                    </Button>
                            </div>
                        </td>           
                    
                        
                    
                    </tr>

                    )
                })
                :
                <tr>
                    <td>
                        <p>Cargando...</p>
                    </td>
                </tr>
                }
                </tbody>
            </Table>


            
        </Card>
        <Card>
            <Resumen/>
        </Card>

                
        
        
        </>
    )
}