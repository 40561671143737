import { urlGlobal2 } from './GlobalService.js';




export const getCaes = async(params=null) => {
    let url = new URL(`${urlGlobal2}/facturacion/caes`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
        
    }

    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}


const compararPorImporte = (a, b) => {
    return b.importe - a.importe;
  }


export const GenerateResume = (data) => {
    if(data.length === 0 ){ return []}
    
    let resume = [];


    data.map( d => {
        let encontro = false;
        resume.map(r => {
            if(r.tipo === d.tipo && r.importe === d.importe){
                r.cantidad = r.cantidad+1
                encontro = true;
            }
        })
        if(!encontro){
            resume.push(
                {
                    importe:d.importe,
                    tipo:d.tipo,
                    cantidad:1
                }
            )
        }
      


    })
    resume.sort(compararPorImporte);

    return resume;


}

export async function postCaes(cae){
    let url = `${urlGlobal2}/facturacion/caes`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(cae)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        return null;
    }
  }


  export async function deleteCae(idCae){
    let url = `${urlGlobal2}/facturacion/caes/${idCae}`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'DELETE';
        const resp = await fetch(url, {headers, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        return null;
    }
  }
