
import LoadingComponent from 'pages/components/Loading';
import React, { useEffect, useState } from 'react'
import {
    Card,
  } from "reactstrap";
import { getRetirosEq } from 'services/RetirosServices';
import TableRetiros from './components/TableRetiro';


const RetiroEquipos = () => {

    const [RetirosEquipos, setRetirosEq] = useState([]); 
    const [Visible, setVisible] = useState(false);

    const traerRetirosEq = async() => {
      setVisible(true)
      let camb = await getRetirosEq();
      if (camb != null && camb != '') {
        setRetirosEq(camb);
      }
      setVisible(false)
  }
  

    useEffect(() => {
      traerRetirosEq();
        
      }, [])
    return (
     <>
     <LoadingComponent Visible={Visible}/>
      <Card>
        <TableRetiros 
        RetirosEquipos={RetirosEquipos}
        />
      </Card>
      
    </>
    )
}

export default RetiroEquipos;