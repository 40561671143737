import { Button, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

function EntregarAComponent(props) {
    const {cambiarEstado} = props;
    return ( 
        <Card>
            <CardHeader className="pb-3">
                <div className="text-center"><b>Entregar a:</b></div>
            </CardHeader>
            <CardBody>
                <Container>
                    <Row>
                        <Col>
                            <Button
                                block
                                size="lg"
                                color="info"
                                type="button"
                                onClick={() => {cambiarEstado("Empleado")}}
                            >
                                Empleado
                            </Button> 
                        </Col>
                        <Col>
                        <Button
                                block
                                size="lg"
                                color="warning"
                                type="button"
                                onClick={() => {cambiarEstado("Almacen")}}
                            >
                                Almacen
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </CardBody>                  
        </Card>
     );
}

export default EntregarAComponent
