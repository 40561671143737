import { urlGlobal2 } from './GlobalService';

  export const getTiposMantenimientos = async(params=null) =>{
    let url = new URL(`${urlGlobal2}/tiposmantenimientos`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}
  export async function postTiposMantenimientos(ev){
    let url = `${urlGlobal2}/tiposmantenimientos`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(ev)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }
  export async function putEditarTipoMantenimiento(eved){
    let url = `${urlGlobal2}/tiposmantenimientos`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'PUT';
        let body = JSON.stringify(eved)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }

  export async function deleteTipoMantenimiento(id){
    let url = `${urlGlobal2}/tiposmantenimientos/${id}`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'DELETE';
        const resp = await fetch(url, {headers, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }