import React, { useState } from 'react'
import SelectorCategorias from 'pages/components/SelectorCategoria'
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap'
import { postEquipos } from 'services/EquiposService'
import {warningAlert, successAlert, confirmAlert} from "../../../components/SweetAlert"
import LoadingComponent from 'pages/components/Loading'
import { postAsignarEquipos } from 'services/EquiposService'
import { AsignarServicio } from 'services/ServiciosServices'
import { putAsignar } from 'services/ServiciosServices'
import SelectorEmpleados from 'pages/components/SelectorEmpleados'
import { postCambioEquipos } from 'services/CambiosServices'


export default function ModalRCambios(props){
    const {
        ModalRealizarCambios, setModalRealizarCambios, idClient, idServicio
      } = props
      
      const [Alert, setAlert] = useState(null);
      const [Visible, setVisible] = useState(false);
      const [MacAnterior, setMacAnterior] = useState("");
      const [MacNueva, setMacNueva] = useState("");
     
      const cambiarMacAnterior = (e) => {
        setMacAnterior(e.target.value)
      }
      const cambiarMacNueva = (e) => {
        setMacNueva(e.target.value)
      }
      
       
      const confirmarRCambio = ()=>{
        confirmAlert(setAlert, ReaizarCambio, null, "Seguro que desea relizar un Cambio de Equipo?")
      }
      const ReaizarCambio= async()=> {
        setVisible(true)
        let equipoCambiar = {
          equipoanterior: MacAnterior,
          equiponuevo: MacNueva,
          clientId: idClient,
          servicioId:idServicio,
          
        }
        let resp = await postCambioEquipos(equipoCambiar);
        if(resp){
        setMacNueva("");
        setMacAnterior("");
        successAlert(setAlert,"Equipo asignado correctamente");
        setModalRealizarCambios(false);
        
        }
        setVisible(false)
  
      }
      
    

    return(
      <>
        {Alert}
        <LoadingComponent Visible={Visible}/>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={ModalRealizarCambios}
          toggle={() => setModalRealizarCambios(false)}
        >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-center"><b>Cambio de Equipo</b>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalRealizarCambios(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
              </div>
              <div className="text-muted mt-2 mb-3">
              <Form role="form">
              
              <FormGroup className="mb-3">Mac Anterior:
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      <i class="fas fa-arrow-left text-danger"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Mac Anterior..." type="text" onChange={cambiarMacAnterior} value={MacAnterior} />
                    
                  </InputGroup>
                </FormGroup> 

                <FormGroup className="mb-3">Mac Nueva:
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      <i class="fas fa-arrow-right text-success"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Mac Nueva..." type="text" onChange={cambiarMacNueva} value={MacNueva} />
                    
                  </InputGroup>
                </FormGroup> 

                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={()=>confirmarRCambio()}
                  >
                    Realizar Cambio
                  </Button>
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalRealizarCambios(false)}
                  >
                    Cerrar
                  </Button>                    
                </div>
              </Form>                     
              </div>                    
            </CardHeader>                  
          </Card>
        </div>
        </Modal>
      </>
    )
}