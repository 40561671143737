import React , { useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  NavItem,
  NavLink,
  Nav,
  Card,
  CardBody,
} from "reactstrap";

function GnetNavbar(props) {
  const {setVista} = props
  const [estado, setState] = useState(null);
 useEffect(() => {    
  });
  
  const toggleNavs = (e, state, index) => {
    e.preventDefault();
   setState(index);
  };
  

  return (
    <>
     <Card className=" border-0 mb-0 " style={{ width:"100%"}}>
       <CardBody>
      <Nav
          className="nav-fill flex-column flex-sm-row"
          id="tabs-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={estado === 1}
              className={classnames("mb-sm-3 mb-md-0", {
                active: estado === 1
              })}
              onClick={e => {toggleNavs(e, "navPills", 1); setVista("General")}}
              role="tab"
            >
              Vista General
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={estado === 3}
              className={classnames("mb-sm-3 mb-md-0", {
                active: estado === 3
              })}
              onClick={e => {toggleNavs(e, "navPills", 3); setVista("Equipos")}}
              role="tab"
            >
              Equipos
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={estado === 4}
              className={classnames("mb-sm-3 mb-md-0", {
                active: estado === 4
              })}
              onClick={e => {toggleNavs(e, "navPills", 4); setVista("Pagos")}}
              role="tab"
            >
              Pagos
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={estado === 5}
              className={classnames("mb-sm-3 mb-md-0", {
                active: estado === 5
              })}
              onClick={e => {toggleNavs(e, "navPills", 5); setVista("Facturas")}}
              role="tab"
            >
              Facturas
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={estado === 6}
              className={classnames("mb-sm-3 mb-md-0", {
                active: estado === 6
              })}
              onClick={e => {toggleNavs(e, "navPills", 6); setVista("Observaciones")}}
              role="tab"
            >
              Observaciones
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={estado === 7}
              className={classnames("mb-sm-3 mb-md-0", {
                active: estado === 7
              })}
              onClick={e => {toggleNavs(e, "navPills", 7); setVista("Casos")}}
              role="tab"
            >
              Casos
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              aria-selected={estado === 8}
              className={classnames("mb-sm-3 mb-md-0", {
                active: estado === 8
              })}
              onClick={e => {toggleNavs(e, "navPills", 8); setVista("Cuotas")}}
              role="tab"
            >
              Cuotas
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              aria-selected={estado === 9}
              className={classnames("mb-sm-3 mb-md-0", {
                active: estado === 9
              })}
              onClick={e => {toggleNavs(e, "navPills", 9); setVista("Documentos")}}
              role="tab"
            >
              Documentos
            </NavLink>
          </NavItem>
        </Nav>
        </CardBody>
        </Card>
    </>
  );
}

GnetNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
GnetNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default GnetNavbar;
