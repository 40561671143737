import { successAlert } from 'pages/components/SweetAlert';
import { confirmAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap';
import { postRetiro } from 'services/RetirosServices';

const ModalGenRetiro = (props) => {

    const {ModalGenerarRetiro, setModalGenerarRetiro, setAlert, idClient, idServicio}=props;
    const [Comentario, setComentario] = useState("");


    const cambiarComentario = (event) => {
        setComentario(event.target.value)
    }

    const confirmar = ()=>{
    confirmAlert(setAlert, generarRetiro, null, "Desea generar un retiro?")
    }  

    const generarRetiro= async()=>{
        let retiro = {
          clientId: idClient,
          serviceId: idServicio,
          comentario: Comentario,
          
         };
         let ret = await postRetiro(retiro);
             if (ret) {
               setComentario("")
               successAlert(setAlert, "Retiro Generado!", "")
               setModalGenerarRetiro(false)
               
             }
      }
  return(
      <>
      <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={ModalGenerarRetiro}
          toggle={() => setModalGenerarRetiro(false)}
        >
        <div className="modal-body p-0">
          <Card className=" shadow border-0">
            <CardHeader className=" pb-5">
              <div className="text-center"><b>Generar Retiro</b>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalGenerarRetiro(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
              </div>
              </CardHeader> 
              <CardBody>
              <div className=" mt-2 mb-3">
              <Form role="form">
              
              <FormGroup className="mb-3 text-area" value={Comentario} onChange={cambiarComentario} >Comentario:
                  
                    <Input 
                    placeholder="Comentario..." 
                    type="textarea" 
                    className=" form-control-alternative textarea-autosize"
                     />
                </FormGroup> 
              </Form>                     
              </div>  
              </CardBody>
              <CardFooter>
                  <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={()=>confirmar()}
                  >
                    Agregar
                  </Button>
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalGenerarRetiro(false)}
                  >
                    Cerrar
                  </Button>                    
                </div>

              </CardFooter>
                                
                            
          </Card>
        </div>
        </Modal>
      </>
  )
}

export default ModalGenRetiro;
