import React from 'react'
import { Button, Card, CardBody, CardFooter, Col, Container, Row } from 'reactstrap';

export default function TipoEquipos(props) {
    const {setVistaMuestra} = props;

    return (
        <Container className="mt-6" fluid>
            <Row>
                <Col></Col>
                <Col>
                    <Card className="card-pricing border-0 text-center mb-4" >
                    <CardBody className="px-lg-7">
                    <h4 className=" ls-1 text-primary py-3 mb-0">
                        Seleccione tipo de equipo 
                    </h4>
                    </CardBody>
                    <CardFooter className="footerO bg-transparent">
                        <Button className="mb-3" color="primary" type="button" onClick={()=> {setVistaMuestra("escanear")}}>
                            Con Mac
                        </Button>
                        <Button className="mb-3" color="primary" type="button" onClick={()=> {setVistaMuestra("sinMac")}}>
                            Sin Mac
                        </Button>
                    </CardFooter>
                </Card>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}
