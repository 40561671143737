import React, { useEffect, useState } from 'react'
import {
  Card,
  CardHeader,
  Collapse
} from "reactstrap";
import Permisos from './permisos';


const TableGruposPermisos=(props)=> {
    const {traerGPermisos, GPermisos} = props
    const [openedCollapses, setOpenedCollapses] = useState([])

  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  const collapsesToggle = collapse => {
    if (openedCollapses.includes(collapse)) {
      setOpenedCollapses([])
    } else {
      setOpenedCollapses([collapse]);
    }
  };


  useEffect(() => {
    traerGPermisos()
  }, []);

  return (
      <>
        <div className="mt-5" style={{width:"100%"}}>
        {GPermisos?
            GPermisos.map((gp, index)=>{
            return (
                <div className="accordion" style={{width:"100%"}} key={index}>
                <Card className="card-plain">
                    <CardHeader
                    role="tab"
                    onClick={() => collapsesToggle(index)}
                    aria-expanded={openedCollapses.includes(
                        index
                    )}
                    >
                    <h4 className="mb-0">{gp.nombre}</h4>
                    </CardHeader>
                    <Collapse
                    role="tabpanel"
                    isOpen={openedCollapses.includes(index)}
                    >
                    <Permisos permisos={gp.permisos} traerGP_C_P={traerGPermisos}/>
                    </Collapse>
                </Card>
                </div>  
            )
            })
            :
            <></>
        }
        </div>
      </>
  )
}

export default TableGruposPermisos
