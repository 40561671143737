import React from 'react'
import { useHistory } from 'react-router'
import { Button, Table } from 'reactstrap'

const TableAllStores = ({Materiales}) => {
    const history = useHistory()


    const irHistorico = (id, nombre, almacenID) => {
        history.replace({
            pathname: '/admin/historico',
            state:{
                tipoMaterialId: id,
                nombre: nombre,
                idAlmacen : almacenID,
                Almacenes: true
        }
        })
    }
    const irMateriales =(id, almacenID) => {
        history.replace({
            pathname : `/admin/stockEmpleado/${id}`,
            state : {
                idAlmacen : almacenID,
                Almacenes: true
            }
        })
    }
    


  return (
    <>
    <div className='mt-5'>
        <h1>Materiales: </h1>
            <Table responsive>
            <thead className='bg-info'>
                        <tr className='text-center'>
                            <th>Tipo de Material</th>
                            <th>Codigo Producto</th>
                            <th>Stock Minimo</th>
                            <th>Cantidad Stock</th>
                            <th>Cantidad en Empleados</th>
                            <th>Total</th>
                            <th>Unidad</th>
                        </tr >
                    </thead>
                    <tbody>
                        {Materiales.length?
                        Materiales.map((m, index)=> {
                            return (
                        <tr key={index} className='text-center'>
                            <td>
                                <Button style={{width:"100%"}} className="btn btn-outline-default rounded px-2 py-1" onClick={()=>irHistorico(m.idTipoMaterial, m.tiposMateriale?.nombre, m.idAlmacen)}>{m.tiposMateriale?.nombre}</Button>
                            </td>
                            <td>
                                {m.tiposMateriale?.codigoProducto}
                            </td>
                            <td >
                                {m.stockMinimo}
                            </td>
                            <td>
                                {m.cantidad <= m.stockMinimo?
                                <Button className='btn-sm btn-outline-warning py-0' >{m.cantidad}!</Button>
                                :
                            <>{m.cantidad}</>
                                
                                }
                            </td>
                            <td>
                                <Button className='btn-sm btn-outline-success' onClick={()=>irMateriales(m.idTipoMaterial, m.idAlmacen)}>{m.tiposMateriale?.cantEmpleados}</Button>
                            </td>
                            <td>
                                {m.tiposMateriale?.total}
                            </td>
                            <td>
                                {m.tiposMateriale?.unidad}
                            </td>
                        </tr>  
                            )
                        })
                    :
                    <>
                    <tr>
                        <th>
                        No se han encontrado ese tipo de Materiales en este Almacen!
                        </th>
                    </tr>
                    </>}

                    </tbody>
            </Table>
            </div>
    </>
  )
}

export default TableAllStores