import React from "react";
import Graficos from "../subVistas/graficos/Graficos";
import GruposPermisos from "../subVistas/grupospermisos/GruposPermisos";
import RolesComponent from "../subVistas/roles/Roles";
import Usuarios from "../subVistas/usuarios/Usuarios";



function ContenedorSubVistasDashboard(props){
    const {vista} = props;

    if(vista === "Usuarios"){
        return (
            <>
                <Usuarios/>
            </>
        )
    }else if(vista === "Roles"){
        return (
            <>
                <RolesComponent/>
            </>
        )
    }else if(vista === "GruposPermisos"){
        return (
            <>
                <GruposPermisos/>
            </>
        )
    }else if(vista === "Graficos"){
        return (
            <>
                <Graficos/>
            </>
        )
    }else{
        <></>
    }  
}



export default ContenedorSubVistasDashboard;