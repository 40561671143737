import { confirmAlert } from 'pages/components/SweetAlert';
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Form, FormGroup, Input, InputGroup, Modal } from 'reactstrap';
import { deleteFacturacion } from 'services/FacturacionService';
import { putFacturacion } from 'services/FacturacionService';

const ModalEditFacturacion = (props) => {
    const { ModalEditarFacturacion, setModalEditarFacturacion, FacturacionSelect, setFacturacionSelect,traerFacturaciones } = props;
    const [FacturacionId, setFacturacionId] = useState("");
    const [Nombre, setNombre] = useState("");
    const [Apelliddo, setApelliddo] = useState("");
    const [Dni, setDni] = useState("");
    const [ClienteId, setClienteId] = useState("");
    const [DiaFacturacion, setDiaFacturacion] = useState(1);
    const [TipoFactura, setTipoFactura] = useState("");

    const [Alert, setAlert] = useState(null)


    const cambiarDia = event => {
        setDiaFacturacion(event.target.value);
    };
    const cambiarTipoFactura = event => {
        setTipoFactura(event.target.value);
    };


    const actualizarFacturacion = async () => {
        let FactUpdate = {
            name: Nombre,
            lastName: Apelliddo,
            identifier: Dni,
            clientId: parseInt(ClienteId),
            autoInvoiceDay: parseInt(DiaFacturacion),
            invoiceType: TipoFactura
        }
        if (validarFacturacion(FactUpdate) == true) {
            if (validarDia(FactUpdate.autoInvoiceDay)) {
                let valido = await putFacturacion(FacturacionId, FactUpdate);
                if (valido.ok) {
                    successAlert(setAlert, 'Facruracion Actualizada');
                    recetearFacturacion()
                    setModalEditarFacturacion(false);
                    traerFacturaciones()
                } else {
                    warningAlert(setAlert, 'No se pudo Editar la Facturacion')
                }
            } else {
                warningAlert(setAlert, 'El dia de facturacion debe ser un numero entre 1 y 28!')
            }
        } else {
            warningAlert(setAlert, 'Complete todos los campos!')
        }
    }

    const recetearFacturacion = () => {
        setDiaFacturacion({});
        setTipoFactura({});
        setFacturacionSelect({});
    }


    const establecerFacturacion = () => {
            setNombre(FacturacionSelect.name)
            setApelliddo(FacturacionSelect.lastName)
            setDni(FacturacionSelect.identifier)
            setClienteId(FacturacionSelect.clientId)
            setDiaFacturacion(FacturacionSelect.autoInvoiceDay)
            setTipoFactura(FacturacionSelect.invoiceType)
            setFacturacionId(FacturacionSelect._id)
        
    }
    const validarFacturacion = (fact) => {
        if (!fact.autoInvoiceDay) {
            return false;
        }
        if (!fact.invoiceType) {
            return false;
        }
        return true;
    }
    const validarDia = (dia) => {
        if (dia >= 1 && dia <= 28) {
            return true
        }
        return false
    }

    const confirmarEliminar = async (FactId) => {
        confirmAlert(setAlert, eliminarFacturacion, FactId, "Deseas eliminar esta Facturacion?")
    }

    const eliminarFacturacion = async (FactId) => {

        let result = await deleteFacturacion(FactId)
        if (result !== null) {
            successAlert(setAlert, "Facturacion Eliminada!", "");
            setModalEditarFacturacion(false);
            traerFacturaciones()
        } else {
            warningAlert(setAlert, 'No se pudo eliminar la Facturacion!')
        }
    };





    useEffect(() => {
        establecerFacturacion();
    }, [FacturacionSelect])

    return (
        <>
            {Alert}
            <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={ModalEditarFacturacion}
                toggle={() => (setModalEditarFacturacion(false), recetearFacturacion())}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="pb-5">

                            <div className="text-center">
                                <b>Editar Facturacion</b>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => (setModalEditarFacturacion(false), recetearFacturacion())}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className="mt-2 mb-3">
                                <Form role="form">
                                    <FormGroup className="mb-3" >
                                        Cliente: {Apelliddo}, {Nombre} ({Dni})
                                    </FormGroup>
                                    <FormGroup className="mb-3" >Dia de Facturacion:
                                        <InputGroup className="input-group-alternative mt-2">
                                            <Input  type="number" value={DiaFacturacion} onChange={cambiarDia} min="1" max="28" />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup className="mb-3" >Tipo Factura:
                                        <InputGroup className="input-group-alternative mt-2">
                                            <Input type="select" value={TipoFactura} onChange={cambiarTipoFactura}>
                                                <option value="">Tipo de Factura</option>
                                                <option value="A">A</option>
                                                <option value="B">B</option>
                                            </Input>
                                        </InputGroup>
                                    </FormGroup>
                                    <div className="text-center">

                                        <Button
                                            className="my-4"
                                            color="primary"
                                            type="button"
                                            onClick={() => actualizarFacturacion()}
                                        >
                                            Editar
                                        </Button>
                                        <Button
                                            className="my-4"
                                            color="danger"
                                            type="button"
                                            onClick={() => confirmarEliminar(FacturacionId)}
                                        >
                                            Eliminar
                                        </Button>
                                        <Button
                                            color="secondary"
                                            data-dismiss="modal"
                                            type="button"
                                            onClick={() => (setModalEditarFacturacion(false), recetearFacturacion())}
                                        >
                                            Cancelar
                                        </Button>


                                    </div>
                                </Form>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        </>
    )
}


export default ModalEditFacturacion
