import React, { useEffect, useState } from 'react'
import SelectorCategorias from 'pages/components/SelectorCategoria';
import { Button, Card, CardBody, CardFooter, Col, Container, Row } from 'reactstrap';
import { successAlert } from 'pages/components/SweetAlert';
import { warningAlert } from 'pages/components/SweetAlert';
import { postEquipos } from 'services/EquiposService';
import { getEstados } from 'services/EstadosService';
import ModalEntregar from 'pages/components/ModalEntregrar';
import { getEquipos } from 'services/EquiposService';
import { postEquiposMany } from 'services/EquiposService';

export default function ContainerCategoria(props) {
    const {setAlert, setEquiposErrorAgg, EquiposErrorAgg, setNoAgregadosModal, equipos, tieneMac} = props;
    const [Categoria, setCategoria] = useState("");
    const [estadoId, setEstadoId] = useState("");
    const [ModalEntregarEquipo, setModalEntregarEquipo] = useState(false);
    const [EquiposEntregar, setEquiposEntregar] = useState([]);
    const [EquiposNoEntregar, setEquiposNoEntregar] = useState(false);

    const equiposConMac = async() => {
        let arrayEquipos = [...equipos];
        
        let equiposNoAgregados = []

        arrayEquipos.map(async(eq)=>{
            let equip;
            equip = {
                macAddress: eq,
                estadoId,
                categoriaId: Categoria
            }
            
            let result = await postEquipos(equip);
            if(result){
                if(!(result.ok)){
                    let equipNoAgg = {
                        mac: equip.macAddress,
                        aviso: result.mensaje
                    }
                    equiposNoAgregados.push(equipNoAgg)
                }
            }else{
                let equipNoAgg = {
                    mac: equip.macAddress,
                    aviso: "Error al agregar equipo"
                }
                equiposNoAgregados.push(equipNoAgg)
            }
        })
        setEquiposErrorAgg(equiposNoAgregados);
        if(equiposNoAgregados.length == 0){
            successAlert(setAlert, "Los equipos se agregaron correctamente");
        } else {
            warningAlert(setAlert, "No se pudieron agregar los equipos, revisar equipos no agregados")
        }
        setNoAgregadosModal(false)
    }

    const equiposSinMac = async() => {
        let equiposAgregar = [];
        equipos.map(eqs=>{
            for (let index = 0; index < eqs.cantidad; index++) {
                let equip = {
                    estadoId,
                    categoriaId: eqs.categoria.id
                }
                equiposAgregar.push(equip)
            }
        })

        

        let result = await postEquiposMany(equiposAgregar);
        if (result?.ok) {
            successAlert(setAlert, "Los equipos se agragaron correctamente");
        } else {
            warningAlert(setAlert, "No se pudieron agregar los equipos, revisar equipos no agregados")
        }
    }

    const agregarEquiposSistema = async() => {
        if(tieneMac){
            await equiposConMac()
        }else{
            await equiposSinMac()
        }
        
    }


    const equiposEntregar = async()=>{
        let arrayEquipos = [...equipos];

        let equipsEntregar = []

        let equipsNoEntregados = []

        await Promise.all(
            arrayEquipos.map(async(eq)=>{
                let query = {query_result:eq}
                let result = await getEquipos(query);
                if(result){
                    if(result.length == 1){
                            equipsEntregar.push(result[0].id)
                    }else{
                        let equipNoAgg = {
                            mac: eq,
                            aviso: "Error al entregar equipo"
                        }
                        equipsNoEntregados.push(equipNoAgg)
                    }
                }else{
                    let equipNoAgg = {
                        mac: eq,
                        aviso: "Error al entregar equipo"
                    }
                    equipsNoEntregados.push(equipNoAgg)
                }   
            })
          )
        setEquiposNoEntregar(true);
        setEquiposErrorAgg(equipsNoEntregados);
        setEquiposEntregar(equipsEntregar);
        setModalEntregarEquipo(true)
    }


    const buscarIdEstado = (nombre, estadosObtenidos) => {
        estadosObtenidos.forEach(item =>{
            if (item.nombre == nombre) {
                setEstadoId(item.id)
            }
        });
    }

    const traerEstados = async()=>{
        let estadosObtenidos = await getEstados();
        buscarIdEstado("STOCK", estadosObtenidos);
    }


    useEffect(()=>{
        traerEstados()
    }, [])

    return (
        <Container className="mt-6" fluid>
            <Row>
                <Col></Col>
                <Col>
                    <Card className="card-pricing border-0 text-center mb-4">
                        {tieneMac?
                        <CardBody className="px-lg-7">
                            <h4 className=" ls-1 text-primary ">
                                Seleccione Categoria
                            </h4>
                            <SelectorCategorias Categoria={Categoria} setCategoria={setCategoria} tipoValue={"id"}/>
                        </CardBody>
                        :
                        <></>
                        }
                        <CardFooter className="footerO bg-transparent">
                            <Button className="mb-3" color="primary" type="button" onClick={()=> agregarEquiposSistema()}>
                            Agregar equipos al sistema
                            </Button>
                            {EquiposErrorAgg.length == 0?
                            <></>
                            :
                            <Button className="mb-3" color="primary" type="button" onClick={()=> setNoAgregadosModal(true)}>
                            No agregados
                            </Button>
                            }
                        </CardFooter>
                    </Card>
                </Col>
                <Col></Col>
            </Row>
            <Row>
                <Col></Col>
                <Col>
                    <Card className="card-pricing border-0 text-center mb-4">
                    <CardFooter className="footerO bg-transparent">
                        <Button className="mb-3" color="primary" type="button" onClick={()=> equiposEntregar()}>
                            Entregar/Recibir equipos
                        </Button>
                        {EquiposErrorAgg.length == 0 && EquiposNoEntregar?
                            <></>
                            :
                            <Button className="mb-3" color="primary" type="button" onClick={()=> setNoAgregadosModal(true)}>
                            No Entregados/Recibidos
                            </Button>
                        }
                        <ModalEntregar
                        ModalEntregarEquipo={ModalEntregarEquipo} 
                        setModalEntregarEquipo={setModalEntregarEquipo}
                        EquiposSeleccionados={EquiposEntregar}
                        />
                    </CardFooter>
                    </Card>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}
