import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react';
import { Button, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap';
import { postCliente } from 'services/ClientesServices';

const ModalAgregarClt = (props) => {

    const {ModalAgregarCliente, setModalAgregarCliente, traerClientes}=props;
    const [alertSweet, setAlert] = useState(null);
    const [nombre, setNombre] = useState("");
    const [apellido, setApellido] = useState("");
    const [telefono, setTelefono] = useState("");
    const [email, setEmail] = useState("");
    const [dni, setDni] = useState("");
    const [Direccion, setDireccion] = useState("")

    const cambiarNombre = event => {
        setNombre(event.target.value);
    };
    const cambiarApellido = event => {
    setApellido(event.target.value);
    };
    const cambiarTelefono = event => {
    setTelefono(event.target.value);
    };
    const cambiarEmail = event => {
    setEmail(event.target.value);
    };
    const cambiarDni = event => {
    setDni(event.target.value);
    };
    const cambiarDireccion = event => {
    setDireccion(event.target.value);
    };

    const AgregarCliente = async()=>{
    
        let client = {
          userIdent: dni,
          firstName: nombre,
          lastName: apellido,
          email: email,
          phone: telefono,
          street1: Direccion
        }
      if(validar(client) === true){
        let result = await postCliente(client)
        if(result != null){
          setNombre("")
          setApellido("")
          setTelefono("")
          setEmail("")
          setDni("")
          setModalAgregarCliente(false)
          successAlert(setAlert, "Cliente agregado correctamente!", "")
          traerClientes()
      }else{
        warningAlert(setAlert, 'No se pudo agregar cliente')
      }

      } else {
        warningAlert(setAlert,"Complete todos los campos!")
      }
    }
    
    const validar = (e)=> {
      if (e.userIdent === ""){
        return false
      } else if(e.firstName === "") {
        return false
      } else if (e.lastName === "") {
        return false
      } else if (e.email === "") {
        return false 
      } else if (e.phone === "") {
        return false 
      } else if (e.street1 === "") {
        return false
      } else {
        return true
      }
    }
  return(
      <>
      {alertSweet}
      <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={ModalAgregarCliente}
                            toggle={() => setModalAgregarCliente(false)}
                            >
                          <div className="modal-body p-0">
                              <CardHeader>
                                <div className="text-center"><b>Agregar Cliente</b>
                                <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => setModalAgregarCliente(false)}
                                  >
                                  <span aria-hidden={true}>×</span>
                                </button>
                                </div>
                              </CardHeader>         
                              <CardBody>
                                <Form role="form">
                                <FormGroup className="mb-3" value={nombre} onChange={cambiarNombre}>Nombre:
                                    <InputGroup className="input-group-alternative mt-2">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                        <i className="fas fa-user"></i>
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input placeholder="Nombre" type="text" />
                                    </InputGroup>
                                  </FormGroup>
                                  <FormGroup className="mb-3" value={apellido} onChange={cambiarApellido}>Apellido:
                                    <InputGroup className="input-group-alternative mt-2">
                                      <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="fas fa-user"></i>
                                        </InputGroupText>                                        
                                      </InputGroupAddon>
                                      <Input placeholder="Apellido" type="text" />
                                    </InputGroup>
                                  </FormGroup>
                                <FormGroup className="mb-3" value={telefono} onChange={cambiarTelefono}>Telefono:
                                    <InputGroup className="input-group-alternative mt-2">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                        <i className="fas fa-phone-alt"></i>
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input placeholder="Telefono..." type="numero" />
                                    </InputGroup>
                                  </FormGroup>
                                  <FormGroup className="mb-3" >Direccion:
                                    <InputGroup className="input-group-alternative mt-2">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                        <i className="fas fa-map"></i>
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input 
                                      placeholder="Direccion..." 
                                      type="text"
                                      value={Direccion} 
                                      onChange={cambiarDireccion}
                                       />
                                    </InputGroup>
                                  </FormGroup>
                                  <FormGroup value={email} onChange={cambiarEmail}> Email:
                                    <InputGroup className="input-group-alternative mt-2">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                        <i className="ni ni-email-83" />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input placeholder="Email" type="Email" />
                                    </InputGroup>
                                  </FormGroup>
                                  <FormGroup className="mb-3" value={dni} onChange={cambiarDni}>DNI:
                                    <InputGroup className="input-group-alternative mt-2">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                        <i className="fas fa-address-card"></i>
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input placeholder="DNI" type="numero" />
                                    </InputGroup>
                                  </FormGroup>
                                </Form>           
                              </CardBody>     
                              <CardFooter>
                                  <div className="text-center">
                                    <Button
                                      color="primary"
                                      type="button"
                                      onClick={()=>AgregarCliente()}
                                      >
                                      Agregar
                                    </Button>
                                
                                    <Button
                                      color="secondary"
                                      data-dismiss="modal"
                                      type="button"
                                      onClick={() => setModalAgregarCliente(false)}
                                    >
                                      Cerrar
                                    </Button>                    
                                  </div>
                              </CardFooter>    
                          </div>
                          </Modal>
      </>
  )
};

export default ModalAgregarClt;
