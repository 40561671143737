import React, { useEffect, useState } from "react";
import {FormGroup,Input} from "reactstrap";
import { verificarToken } from "services/AuthService";
import { getTiposMantenimientos } from "services/TiposMantenimientosService";


function SelectorTiposMantenimientos(props) {
    const {valor, cambiarValor, titulo} = props;
    const [Tipo, setTipo] = useState([]);

    const cambiarTipo = (e) => {
      cambiarValor(e.target.value);
  };

    useEffect(() => {
      traerTipo();
    }, [])
    
    const traerTipo = async() => {
        let tk = null; 
        try {
          tk = localStorage.getItem('token');
        } catch (error) {
          return
        }
        let tkValido = await verificarToken(tk);
        if(!tkValido){
            return
        }
        let tipo = await getTiposMantenimientos(tk);
        if (tipo !== null && tipo !== '') {
          setTipo(tipo);
        }
      
    }

    return (
      <>
                  <FormGroup>
                    {titulo?
                    <label htmlFor="exampleFormControlSelect1">Tipo de Mantenimiento:</label>
                    :
                    null
                    }
                    {Tipo?
                    <Input id="exampleFormControlSelect1" type="select"
                      value={valor}
                      onChange={cambiarTipo}
                    >
                      <option value={""}>Opciones...</option>
                      {Tipo.length !== 0 ?
                      
                      Tipo.map((tm, index) => {
                        return (
                            <option key={index} value={tm.id}>{tm.nombre} ( $ {tm.costo} )</option>
                          )

                        })
                      :
                      <option value={""}>Cargando..</option>
                      }
                    </Input>
                    
                    :<></>}
                  </FormGroup>
      </>
    );
  }
  
  export default SelectorTiposMantenimientos;