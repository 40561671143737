import React from 'react'
import { Alert } from 'reactstrap'


function EtiquetaArchivo(props){
    const {Estado} = props
    if(Estado === 1){
        return(
            <Alert color="info">         
                <span className="alert-inner--text">
                    <strong>Subiendo...</strong> 
                </span>
            </Alert>

        )
    }else if(Estado === 2){
        return(
            <Alert color="success">
                <span className="alert-inner--text">
                    <strong>Subido Correctamente!</strong>
                </span>
            </Alert>

        )
    }else if(Estado === 3){
        return(
            <Alert color="danger">
                <span className="alert-inner--text">
                    <strong>Error al Subir</strong>
                </span>
            </Alert>

        )
    }else{
        return <></>
    }
}

export default EtiquetaArchivo;