import React, { useEffect, useState } from 'react'
import { Card, Table } from 'reactstrap'
import { getVehiculos } from 'services/VehiculosServices'
import TableVehiculos from './components/TableVehiculos';
import LoadingComponent from 'pages/components/Loading';


const Vehiculos = () => {

    const [Vehiculos, SetVehiculos] = useState([]); 
    const [Visible, setVisible] = useState(false);
    

    const traerVehiculos= async()=>{
        setVisible(true)
        let vehi= await getVehiculos();
        if (vehi != null && vehi != '') {
            SetVehiculos(vehi);
          }
          setVisible(false)
    }
    
    useEffect(() => {
        traerVehiculos()
      }, [])
    return (
        <>
        <LoadingComponent Visible={Visible}/>
        <Card>
        <TableVehiculos
        Vehiculos={Vehiculos}
        traerVehiculos={traerVehiculos}
        />
        </Card>
        
        </>
    )
}



export default Vehiculos
