import React, { useEffect, useState } from 'react'
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Modal, Row, Table } from 'reactstrap'
import { postTiposMateriales } from 'services/TiposMaterialesService';
import { postTiposMaterialesExistente } from 'services/AlmacenesService';
import { getTiposMateriales } from 'services/TiposMaterialesService';
import BuscadorTiposMateriales from './BuscadorTiposMateriales';
import PaginacionAddModalTm from './PaginacionAddModalTm';
import ModalStockMinimo from './ModalStockMinimo';
import { postManyTMsExistente } from 'services/AlmacenesService';

const ModalAggTipoMaterial = ({ModalAgregarTipoMaterial, setModalAgregarTipoMaterial, traerMateriales, idAlmacen, setMateriales, Materiales}) => {
    const [Alert, setAlert] = useState(null)
    const [Nombre, setNombre] = useState("");
    const [Codigo, setCodigo] = useState("");
    const [Minimo, setMinimo] = useState(0);
    const [ModaladdStockMinimo, setModaladdStockMinimo] = useState(false);
    const [Unidad, setUnidad] = useState("");
    const [TiposMateriales, setTiposMateriales] = useState(null);
    const [Error, setError] = useState({})
    const [Existente, setExistente] = useState(true);
    const [MaterialSeleccionado, setMaterialSeleccionado] = useState("");
    const [Buscar, setBuscar] = useState(false)
    const [TMsSeleccionados, setTMsSeleccionados] = useState([])
    const [TMSelec, setTMSelec] = useState(null)
    const [query, setQuery] = useState("");
    
    const cambiarNombre = (e)=> {
        setNombre(e.target.value)
    }
    const cambiarCodigo = (e)=> {
        setCodigo(e.target.value)
    }
    const cambiarMinimo = (e)=> {
        setMinimo(e.target.value)
    }
    const cambiarUnidad = (e)=> {
        setUnidad(e.target.value)
    }

    const traerTiposMateriales = async() => {
        let params = {page:1,limit:5}
        let materiales = await getTiposMateriales(params)
        if (materiales) {
            setTiposMateriales(materiales.tiposMateriales)
        }
   
    }
    

    const Agregar = async() =>{
        let tipoMaterial = {
            idAlmacen: idAlmacen,
            nombre: Nombre,
            codigoProducto: Codigo,
            stock : 0,
            stockMinimo: Minimo,
            unidad: Unidad
          }
          if (validarAggTipo(tipoMaterial)===true && validarMinimo(Minimo) === true) {
        let agg = await postTiposMateriales(tipoMaterial)
        if (agg) {
            reset()
            successAlert(setAlert, "Tipo de Materiales agregado correctamente!")
            traerMateriales()
        } else {
            warningAlert(setAlert, "Error al agregar el Tipo de Material")
        }
    } else {
        if (Error.message) {
            warningAlert(setAlert, `${Error.message}`)
        } else {
            warningAlert(setAlert, "Complete todos los campos!")

        }
    }
    }

    const AgregarExistente = async() =>{
        let body = {
            tms: TMsSeleccionados
        }

          
        if (TMsSeleccionados.length > 0) {
            let agg = await postManyTMsExistente(body)
            if (agg) {
                if (agg.ok) {
                    reset()
                    successAlert(setAlert, agg.mensaje)
                    traerMateriales()
                } else {
                    warningAlert(setAlert, agg.mensaje)
                }
            } else {
                warningAlert(setAlert, "Error al agregar el Tipo de Material")
            }
        } else {
            warningAlert(setAlert, "No selecciono ningun tipo de material!")
        }
    }

    const includeTM = (id) => {
        let aux = false;
        TMsSeleccionados.map(tm=>{
            if(tm.idTipoMaterial == id){
                aux = true
            }
        })
        return aux
    }

    const agregarTmConStockMinimo = () => {
        let aux = []
        TMsSeleccionados.map(tmMap=>{
            aux.push(tmMap)
        })
        aux.push({idTipoMaterial:TMSelec, stockMinimo:Minimo, idAlmacen});
        setTMsSeleccionados(aux)
        setMinimo(0)
    }


    const actualizaCheck = (tm) => {
        let resInclude = includeTM(tm.id)
        if(resInclude){
            let aux = []
            TMsSeleccionados.map(tmMap=>{
                if(tmMap.idTipoMaterial != tm.id){
                  aux.push(tmMap)
                }
            })
            setTMsSeleccionados(aux)
        }else{
          setTMSelec(tm.id)
          setModaladdStockMinimo(true)
        }
      }


    const reset = () => {
        setCodigo("")
        setError("")
        setMinimo(0)
        setNombre("")
        setUnidad("")
        setMaterialSeleccionado("")
        setTMsSeleccionados([])
        setExistente(true)
        setBuscar(false)
        traerMateriales()
        setModalAgregarTipoMaterial(false)
    }
    const validarMinimo = () => {
        if (Minimo <=0) {
            setError({message : "El stock minimo debe ser mayor a 0!"})
            return false
        }
        return true
    }
    const validarAggTipo = (ev)=>{
        if(!ev.nombre){
          return false;
        }
        if(!ev.codigoProducto){
          return false;
        }
        if(!ev.stockMinimo){
          return false;
        }
        if(!ev.unidad){
          return false;
        }
        return true;
      }

      useEffect(() => {
        traerTiposMateriales()
    }, [])

  return (
    <>
        {Alert}
        <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={ModalAgregarTipoMaterial}
        toggle={() =>reset()}
        >
        <div>
            {
                Existente?
                <Card>
                <CardHeader className="pb-3">
                <div className="text-center"><b>Agregar Tipo de Material Existente</b>
                <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => reset()}
            >
                <span aria-hidden={true}>×</span>
            </button>
                </div>
                </CardHeader>  
                <CardBody>
                <div className="  mb-3">
                <BuscadorTiposMateriales setObjetos={setTiposMateriales} getObjetos={getTiposMateriales} setBuscar={setBuscar} query={query} setQuery={setQuery}/>
                <>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>NOMBRE</th>
                            <th>CODIGO</th>
                            <th>SELECCIONAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        <>
                        {TiposMateriales?
                            <>
                            {
                            TiposMateriales.map((m, index)=>{
                                return(
                                    <tr key={index}>
                                        <td>
                                            {m?.nombre}
                                        </td>
                                        <td>
                                            {m.codigoProducto}
                                        </td>
                                        <td>
                                            <label className="custom-toggle">
                                                <input type="checkbox" onChange={()=>actualizaCheck(m)}  checked={includeTM(m.id)}/>
                                                <span
                                                className="custom-toggle-slider rounded-circle"
                                                data-label-off="No"
                                                data-label-on="Si"
                                                />
                                            </label>
                                        </td>
                                    </tr>
                                    
                                )
                            })
                            }
                            {Buscar?
                            <Badge
                            className='mt-3'
                            color="warning"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                            >
                                Sólo se muestran las 5 primeras coincidencias
                            </Badge>
                            :
                            <></>
                            }
                            </>
                        :
                        <tr>
                            <td>
                                No se encontraron resultados
                            </td>
                        </tr>
                        }
                        </>
                        
                    </tbody>
                </Table>
                <PaginacionAddModalTm setQuery={setQuery} traerObjetos={traerTiposMateriales} setObjetos={setTiposMateriales} getObjetos={getTiposMateriales} setBuscar={setBuscar}/>
                </>                   
                </div>                    
                </CardBody>
                <CardFooter>
                    <div className="text-center">
                        <Row>
                            <Col>
                                <Button
                                size="sm"
                                    className=""
                                    color="primary"
                                    type="button"
                                    onClick={()=>AgregarExistente()}
                                    style={{width: "100%"}}
                                    >
                                    Agregar
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    size="sm"
                                    color="danger"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => reset()}
                                    style={{width: "100%"}}
                                >
                                    Cerrar
                                </Button>                    
                            </Col> 
                        </Row>
                        <Row className='mt-3'>
                            <Col>
                                <Button
                                block
                                size="sm"
                                color="success"
                                type="button"
                                onClick={()=>setExistente(false)}
                                
                                >
                                    Nuevo Tipo Material
                                </Button>
                            </Col>

                        </Row>
                
                    </div>
                    </CardFooter>                
            </Card>
                :
<Card>
            <CardHeader className="pb-3">
            <div className="text-center"><b>Agregar Tipo de Material</b>
            <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => reset()}
        >
            <span aria-hidden={true}>×</span>
        </button>
            </div>
            </CardHeader>  
            <CardBody>
            <div className=" mt-5 mb-3">
            <Form role="form">
            <FormGroup>
                <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Nombre:
                </label>
                <Input
                    type="text"
                    onChange={cambiarNombre}
                    />
            </FormGroup>
            <FormGroup>
                <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Codigo:
                </label>
                <Input
                    type="text"
                    onChange={cambiarCodigo}
                    />
            </FormGroup>
            <FormGroup>
                <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Stock Minimo:
                </label>
                <Input
                    type="number"
                    onChange={cambiarMinimo}
                    />
            </FormGroup>
            <FormGroup>
                <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Unidad:
                </label>
                <Input
                    type="text"
                    onChange={cambiarUnidad}
                    />
            </FormGroup>
            </Form>                     
            </div>                    
            </CardBody>
            <CardFooter>
                <div className="text-center">
                <Button
                    className=""
                    color="primary"
                    type="button"
                    onClick={()=>Agregar()}
                    
                    >
                    Agregar
                </Button>
            
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => reset()}
                >
                    Cerrar
                </Button>                    
                </div>
                </CardFooter>                
        </Card>
            }
        
        </div>
        </Modal>
        <ModalStockMinimo ModaladdStockMinimo={ModaladdStockMinimo} 
        setModaladdStockMinimo={setModaladdStockMinimo} 
        StockMinimo={Minimo} setStockMinimo={setMinimo}
        agregarTmConStockMinimo={agregarTmConStockMinimo}/>
        </>

        
  )
}

export default ModalAggTipoMaterial