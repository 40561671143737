import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { css as cssE } from "@emotion/react";
import { PulseLoader, RingLoader } from 'react-spinners';
import styled, { css } from "styled-components";

const override = cssE`
border-color: red;
`;

const override2 = cssE`
border-color: red;
`;


const tx ={ 
color: "#0070C2",
marginBottom: "3rem"
}

const divCss = {
    width:250,
    display: 'block',
    margin: '0 auto',
    top: '32%',
    left: '42%',
    position: 'fixed',
}

const rowAtm = {
    marginBottom: "2rem",
    display: "flex",
    justifyContent: "center"
}



const DarkBackground = styled.div`
  display: none; /* Oculto por defecto */
  position: fixed; /* Permanecer en su lugar */
  z-index: 9999; /* Quedarse desde arriba */
  left: 0;
  top: 0;
  width: 100%; /* Ancho completo */
  height: 100%; /* Altura completa  */
  overflow: auto; /* Habilite el desplazamiento si es necesario */
  background-color: rgb(0, 0, 0); /* Color */
  background-color: rgba(0, 0, 0, 0.85); /* Opacidad */

  ${props =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

export default function LoadingComponent(props){
    const {Visible} = props

    return(
        <DarkBackground disappear={Visible}>
            <>
                <div style={divCss}>
                    <Container>
                        <Row style={rowAtm}>
                            <RingLoader color={"#0070C2"} loading={Visible}  css={override}  size = { 150 }/>
                        </Row>
                        <Row>
                            <Col>
                                <h1 style={tx}> CARGANDO</h1>
                            </Col>
                            <Col>
                            <Row style={{paddingTop:"0.5rem"}}>
                                <PulseLoader color={"#0070C2"} loading={Visible}  css={override2} size = { 8 } />
                                
                            </Row>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </>
        </DarkBackground>


    )
}