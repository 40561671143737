import React, { useEffect, useState } from 'react'
import SelectorCategorias from 'pages/components/SelectorCategoria'
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap'
import { postEquipos } from 'services/EquiposService'
import {warningAlert, successAlert} from "../../../components/SweetAlert"
import LoadingComponent from 'pages/components/Loading'
import { putActualizarMac } from 'services/ServiciosServices'
import { putActualizarUsuario } from 'services/ServiciosServices'


export default function ModalEditarServicio(props){
    const {
      ServSelec, traerServiciosClientes, ModalEditarServ, setModalEditarServ, setAlert
      } = props
      const [Macaddress, setMacaddress] = useState("");

      const cambiarMacaddress = event => {
        setMacaddress (event.target.value);
      };
   
      const actualizarMac= async()=>{
        let servAux = {}
        Object.assign(servAux, ServSelec)
        servAux.macEquipo = Macaddress;
        let valido = await putActualizarMac(servAux);
        if(valido){
          setMacaddress("");
          successAlert(setAlert, " Mac Actualizada!", "");
          setModalEditarServ(false);
          traerServiciosClientes()
        }
      
      }

    return(
      <>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={ModalEditarServ}
          toggle={() => setModalEditarServ(false)}
        >
        <div className="modal-body p-0">
          <Card className=" shadow border-0">
            <CardHeader className=" pb-5">
              <div className="text-center"><b>Editar Servicio</b>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalEditarServ(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
              </div>    
            </CardHeader>  
            <CardBody>
            <div className=" mt-2 mb-3">
              <Form role="form">
                <FormGroup className="mb-3">MacAddress:
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      <i className="fas fa-satellite-dish"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="macAddress..." type="text" onChange={cambiarMacaddress} value={Macaddress} />
                    
                  </InputGroup>
                  
                </FormGroup>
              </Form>                     
              </div>                  
            </CardBody> 
            <CardFooter>
            <Button className="mt-3" color="primary" onClick={()=>actualizarMac()}>
                    Editar
                  </Button>
            </CardFooter>           
          </Card>
          
        </div>
        </Modal>
      </>
    )
}