import React, { useState } from 'react'
import { Button, Card, CardBody, Container, FormGroup, Modal, Row } from 'reactstrap';
import ModalAggFacturacion from './ModalAggFacturacion';
import ModalAggFacturacionManual from './ModalAggFacturacionManual';

const ModalSelec = (props) => {
    const { ModalSeleccionar, setModalSeleccionar, traerFacturaciones, traerFacturas } = props;
    const [Seleccionado, setSeleccionado] = useState("");
    const [Selecciono, setSelecciono] = useState(false)

    const recetear = () => {
        setModalSeleccionar(false);
        setSeleccionado("");
        setSelecciono(false)

    }

    return (
        <>
            <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={ModalSeleccionar}
                toggle={() => recetear()}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        {!Selecciono && Seleccionado === "" ?
                            <CardBody className="d-flex justify-content-center align-items-center">
                                <Container>
                                    <Row className="justify-content-center mb-2">
                                        <h3>Facturación Automatica Mensual</h3>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Button
                                            color="info"
                                            data-dismiss="modal"
                                            type="button"
                                            onClick={() => (setSeleccionado("AUTOMATICA"), setSelecciono(true))}
                                            >
                                            Automatica
                                        </Button>
                                    </Row>
                                    <hr />
                                    <Row className="justify-content-center mt-5 mb-2">
                                        <h3>Crear Factura Manualmente</h3>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Button
                                            className=""
                                            color="warning"
                                            type="button"
                                            onClick={() => (setSeleccionado("MANUAL"), setSelecciono(true))}
                                        >
                                            Manual
                                        </Button>
                                    </Row>
                                </Container>
                            </CardBody>
                            :
                            <>
                                {Seleccionado === "MANUAL" ?
                                    <>
                                        <ModalAggFacturacionManual
                                            setSeleccionado={setSeleccionado}
                                            setSelecciono={setSelecciono}
                                            setModalSeleccionar={setModalSeleccionar}
                                            traerFacturaciones={traerFacturaciones}
                                            traerFacturas={traerFacturas}
                                        />
                                    </>
                                    :
                                    <ModalAggFacturacion
                                        setSeleccionado={setSeleccionado}
                                        setSelecciono={setSelecciono}
                                        setModalSeleccionar={setModalSeleccionar}
                                        traerFacturaciones={traerFacturaciones}
                                        traerFacturas={traerFacturas}
                                    />
                                }
                            </>
                        }

                    </Card>
                </div>
            </Modal>
        </>
    )
}


export default ModalSelec