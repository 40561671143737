import React, { useEffect, useState } from "react";
import {Button, Col, Row} from "reactstrap";
import { getEquipos } from "services/EquiposService";
import ModalEntregar from "../../components/ModalEntregrar";
import ModalAgregar from "./components/ModalAgregarEquipo";
import Buscador from "../../components/Buscador";
import TableEquipos from "./components/TableEquipos";
import Paginacion from "../../components/Paginacion";
import { GuardInPages } from "guards/Guard";
import { useHistory } from "react-router";
import { getRolPermisos } from "services/RolService";
import LoadingComponent from "pages/components/Loading";
import SelectorCategorias from "pages/components/SelectorCategoria";
import SelectorGenerico from "pages/components/SelectorGenerico";
import { getEstados } from "services/EstadosService";
import ModalAgregarCategoria from "./components/ModalAddCategoriaEquipo";
import jwt_decode from "jwt-decode";


export default function EquiposGeneral() {
  const [Equipos, setEquipos] = useState([]); 
  const [ModalAgregarEquipo, setModalAgregarEquipo] = useState(false);
  const [ModalAgregarCategoriaEquipo, setModalAgregarCategoriaEquipo] = useState(false);
  const [ModalEntregarEquipo, setModalEntregarEquipo] = useState(false);
  const [EquiposSeleccionados, setEquiposSeleccionados] = useState([]);
  const [Permisos, setPermisos] = useState([]);
  const [Visible, setVisible] = useState(false);
  const [Categoria, setCategoria] = useState(null);
  const [Estados, setEstados] = useState([]);
  const [valorEstado, setSelectorEstado] = useState("");
  const history = useHistory()

  const traerEquipos = async() => {
      let params = {paginate:"si", page:1, categoriaId: Categoria, estadoId: valorEstado}
      let equips = await getEquipos(params);
      if (equips != null && equips != '') {
        setEquipos(equips);
      }
  }
  const traerPermisos= async()=>{
    setVisible(true)

    let permisos= await getRolPermisos();
    if(permisos !=null && permisos!=''){
      setPermisos(permisos)
    }
    setVisible(false)
  }

  const tienePermiso=(nombrePermiso)=>{
    return Permisos.includes(nombrePermiso);
  }

  const esAdmin=()=>{
    let token = localStorage.getItem('token');
    let decod = jwt_decode(token)
    return decod.usuario.admin;
  }

  const traerEstados = async()=>{
    let estadosObtenidos = await getEstados();
    if (estadosObtenidos != null && estadosObtenidos != '') {
      setEstados(estadosObtenidos)
    }  
  }
  
  useEffect(() => {
    traerEstados();
    GuardInPages(history);
    traerEquipos();
    traerPermisos();
  }, [])
  
  return(
    <>
    <LoadingComponent Visible={Visible}/>
    <Row className="m-4">
      <Col className="col col-lg-5">
      <Buscador traerObjetos={traerEquipos} setObjetos={setEquipos} getObjetos={getEquipos}/></Col>
      <Col className="col col-sm"><SelectorCategorias titulo={"Categoria"} Categoria={Categoria} setCategoria={setCategoria} tipoValue={"id"}/></Col>
      <Col className="col col-sm"><SelectorGenerico  titulo={"Estado"} Valor={valorEstado} setValor={setSelectorEstado} arrayElementos={Estados}/></Col>
      <Col className="col col-sm"><Button className="btn btn-md btn-outline-info" onClick={()=>traerEquipos()}>Filtrar</Button></Col>
      <Col>{Permisos && tienePermiso('Agregar-Equipos')?
            <Button className="btn-icon btn-3 btn-md mr-6 float-right" color="primary" type="button" onClick={()=>setModalAgregarEquipo(true)}>
                <span className="btn-inner--icon">
                <i className="fas fa-plus"></i>
                </span>
            </Button>
            :
            <></>
  }</Col>
    <Col>{Permisos && esAdmin()?
              <Button className="btn-icon btn-3 btn-md mr-6 float-right" color="primary" type="button" onClick={()=>setModalAgregarCategoriaEquipo(true)}>
                  <span className="btn-inner--icon">
                  Nueva Categoria
                  </span>
              </Button>
              :
              <></>
    }</Col>
    </Row>

              
            
          
      
      <TableEquipos Equipos={Equipos} traerEquipos={traerEquipos}
      EquiposSeleccionados={EquiposSeleccionados}
      setEquiposSeleccionados={setEquiposSeleccionados}
      setModalEntregarEquipo={setModalEntregarEquipo}/>

      <Paginacion traerObjetos={traerEquipos} setObjetos={setEquipos} getObjetos={getEquipos}/>

      <ModalAgregar
      ModalAgregarEquipo={ModalAgregarEquipo} setModalAgregarEquipo={setModalAgregarEquipo}
      traerEquipos={traerEquipos}
      />

      <ModalEntregar 
      ModalEntregarEquipo={ModalEntregarEquipo} 
      setModalEntregarEquipo={setModalEntregarEquipo}
      EquiposSeleccionados={EquiposSeleccionados}
      traerEquipos={traerEquipos}
      setEquiposSeleccionados={setEquiposSeleccionados}
      />
      <ModalAgregarCategoria
      ModalAgregarCategoriaEquipo={ModalAgregarCategoriaEquipo} 
      setModalAgregarCategoriaEquipo={setModalAgregarCategoriaEquipo} 
      traerEquipos={traerEquipos}
      />
    </>
  )
}