import React from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components

/*
*  Documentacion de componente:
*      - Componente para utilizar en componentes funcionales, para usarlo en clases se debe adaptar.
*      - Se debe importar la funcion de la alerta deseada en nuestro componente.
*      - Se debe crear un estado para llamar a las alertas (ej: const [alertSweet, setAlert] = useState(null);)
*      - Al llamar a la funcion de la alerta deseada se le debe pasar obligatoriamente la funcion para cambiar el estado (ej: warningAlert(setAlert, "titulo!", "descripcion"))
*        Nota: el titulo y la descripcion son parametros opcionales que tienen valor por defecto
*      - Se debe agregar dentro del return de nuestro componente funcional el estado donde vive la alert (ej: {alertSweet})  
*      
*      - Ejemplo completo:
*
*          import {warningAlert} from './components/SweetAlert';
*
*          function miComponente(){
*          const [alertSweet, setAlert] = useState(null);
*
*          return (
*            <>
*              {alertSweet}
*              <Card>
*                <Button onClick={()=>warningAlert(setAlert)}></Button>
*              </Card>
*            </>
*          ); 
*        }
*
*        export default miComponente;
*
*
*      - Nota:
*        - La funcion hideAlert no es una alerta, se puede utilizar para cerrar la alerta, el unico parametro que recibe es la funcion para cambiar el estado donde vive la alerta 
*
*/

export const hideAlert = (callback) => {
    callback(null);
};


export const basicAlert = (callback, title="Alerta", descripcion="") => {
    callback(
        <ReactBSAlert
            style={{ display: "block", marginTop: "-100px" }}
            title={title}
            onConfirm={() => callback(null)}
            onCancel={() => callback(null)}
            btnSize=""
        >
            {descripcion}
        </ReactBSAlert>
    );
};
export const infoAlert = (callback, title="Información", descripcion="") => {
    callback(
      <ReactBSAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => callback(null)}
        onCancel={() => callback(null)}
        confirmBtnBsStyle="info"
        confirmBtnText="Ok"
        btnSize=""
      >
        {descripcion}
      </ReactBSAlert>
    );
};
export const successAlert = (callback, title="Confirmacion", descripcion="") => {
    callback(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => callback(null)}
        onCancel={() => callback(null)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        {descripcion}
      </ReactBSAlert>
    );
};
export const warningAlert = (callback, title="Precaución", descripcion="") => {
    callback(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => callback(null)}
        onCancel={() => callback(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Ok"
        btnSize=""
      >
        {descripcion}
      </ReactBSAlert>
    );
};
export const questionAlert = (callback, title="???", descripcion="") => {
    callback(
      <ReactBSAlert
        custom
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        customIcon={
          <div
            className="swal2-icon swal2-question swal2-animate-question-icon"
            style={{ display: "flex" }}
          >
            <span className="swal2-icon-text">?</span>
          </div>
        }
        onConfirm={() => callback(null)}
        onCancel={() => callback(null)}
        confirmBtnBsStyle="default"
        confirmBtnText="Ok"
        btnSize=""
      >
        {descripcion}
      </ReactBSAlert>
    );
};



export const confirmAlert = (callback, callbackRespuesta, parametro=null, title="???", descripcion="") => {
  callback(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title={title}
      onCancel={() => {callback(null)}}
      onConfirm={() => {callback(null); callbackRespuesta(parametro)}}
      showCancel
      confirmBtnBsStyle="danger"
      confirmBtnText="Sí!"
      cancelBtnBsStyle="secondary"
      cancelBtnText="Cancelar"
      btnSize=""
    >
      {descripcion}
    </ReactBSAlert>
  );
};