
import { successAlert } from 'pages/components/SweetAlert';
import React, {  useState } from 'react'
import { Button, Card, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap';
import { warningAlert } from 'pages/components/SweetAlert';
import { postRetirosCaja } from 'services/RetirosCajasService';
import {ValidateDatoRetiro} from '../../../../validators/validators';

const ModalAggRetiro=(props)=>{

    const {ModalAgregarRetiro, setModalAgregarRetiro,CierreCajaId}=props;

    const [Importe, setImporte] = useState("");
    const [Retirado, setRetirado] = useState("");
    const [Comentario, setComentario] = useState("");
    const [alertSweet, setAlert] = useState(null);

    const AgregarRetiro = async()=>{
    
        let retiro = {
            importe:parseInt(Importe),
            retiradoPor: Retirado,
            comentario: Comentario,
            cierreCajaId:CierreCajaId
        }
        
       
      
      if(!ValidateDatoRetiro(retiro).ok){
        warningAlert(setAlert,ValidateDatoRetiro(retiro).mensaje)
        return;
      }

      
      let result = await postRetirosCaja(retiro)
      if(result != null){
        setImporte("")
        setRetirado("")
        setComentario("")
        successAlert(setAlert, "Retiro Ingresado!", "")
        setModalAgregarRetiro(false)
    }else{
      warningAlert(setAlert, 'No se pudo agregar el Retiro')
    }
  
  }

  const cambiarImporte = event => {
    setImporte(event.target.value);
  };
  const cambiarComentario = event => {
    setComentario(event.target.value);
  };
  const cambiarRetirado = event => {
    setRetirado(event.target.value);
  };

    return(
        <>
        {alertSweet}
        <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={ModalAgregarRetiro}
                            toggle={() => setModalAgregarRetiro(false)}
                          >
                          <div className="modal-body p-0">
                            <Card className="bg-secondary shadow border-0">
                              <CardHeader className="bg-transparent pb-5">
                                <div className="text-center"><b>Ingresar Retiro</b>
                                <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => setModalAgregarRetiro(false)}
                                >
                                  <span aria-hidden={true}>×</span>
                                </button>
                                </div>
                                <div className="text-muted mt-2 mb-3">
                                <Form role="form">
                                <FormGroup className="mb-3 " >Importe:
                                        <Input
                                          placeholder="Importe..."
                                          type="number"
                                          value={Importe} 
                                          onChange={cambiarImporte}
                                        />
                                  </FormGroup>
                                  <FormGroup > Retirado Por:
                                          <Input 
                                          placeholder="Retirado por.." 
                                          type="text"
                                          value={Retirado} 
                                          onChange={cambiarRetirado} />
                                  </FormGroup>
                                  <FormGroup > Comentario:
                                    <Input 
                                    placeholder="Comentario..." 
                                    className=" form-control-alternative textarea-autosize"
                                    type="textarea"
                                    value={Comentario} onChange={cambiarComentario}
                                    />
                                </FormGroup>
                                  <div className="text-center">
                                    <Button
                                      className="my-4"
                                      color="primary"
                                      type="button"
                                      onClick={()=>AgregarRetiro()}
                                    >
                                      Agregar
                                    </Button>
                                
                                    <Button
                                      color="secondary"
                                      data-dismiss="modal"
                                      type="button"
                                      onClick={() => { setModalAgregarRetiro(false)}}
                                    >
                                      Cerrar
                                    </Button>                    
                                  </div>
                                </Form>                     
                                </div>                    
                              </CardHeader>                  
                            </Card>
                          </div>
                          </Modal>
        </>
    )
}
export default ModalAggRetiro


