import {configureStore} from '@reduxjs/toolkit';
import { caesSlice } from './slices/caesSlice';





export const store = configureStore({
    reducer:{
       caes: caesSlice.reducer,
    }
});