import LoadingComponent from 'pages/components/Loading';
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react'
import { Button, Card, CardBody, CardHeader, Form, FormGroup, Input, InputGroup, Modal, Table } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { postFacturacion } from 'services/FacturacionService';


const ModalAggFacturacion = ({
    isOpen,
    setModalSeleccionar,
    setRefresh
}) => {
   
    const [Alert, setAlert] = useState(null)

    const validationSchema = Yup.object().shape({
        autoInvoiceDay: Yup.number()
            .min(1, 'El dia de facturacion debe ser un numero entre 1 y 28!')
            .max(28, 'El dia de facturacion debe ser un numero entre 1 y 28!')
            .required('Required'),
        invoiceType: Yup.string()
            .oneOf(['A', 'B'], 'Invalid Tipo Factura')
            .required('Complete todos los campos!'),
        clientId: Yup.string()
        .required('El ID del cliente es obligatorio.'),
        name: Yup.string()
        .required('El nombre es obligatorio.'),
        lastName: Yup.string()
            .required('El apellido es obligatorio.'),
        identifier: Yup.string()
            .required('El identificador del usuario es obligatorio.'),
    });

    const formik = useFormik({
        initialValues: {
            autoInvoiceDay: 1,
            invoiceType: "",
            clientId: null,
            name: "",
            lastName: "",
            identifier: ""
        },
        validationSchema: validationSchema,
        onSubmit: async(values) => {
            try{ 
                const result = await postFacturacion(values)
                if(!result.ok) throw new Error(result.message)

                successAlert(setAlert, 'Cliente Agregado');
                resetearFacturacion();
                setRefresh(true)
            }catch(error){
                warningAlert(setAlert, 'Error al agregar Cliente');
            }
           
        }
    });
    
    const resetearFacturacion = () => {
        formik.resetForm();
        setModalSeleccionar(false)
    }




    return (
        <>
            {Alert}
            <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={isOpen}
                toggle={() => {}}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent pb-5">
                            <div className="text-center">
                                <b>Agregar Facturacion</b>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => resetearFacturacion()}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className="mt-2 mb-3">
                                <Form role="form" onSubmit={formik.handleSubmit}>
                                        <>
                                        <FormGroup className="mb-3">
                                            ID del Cliente:
                                            <InputGroup className="input-group-alternative mt-2">
                                                <Input
                                                    placeholder="ID del Cliente"
                                                    type="number"
                                                    name="clientId"
                                                    value={formik.values.clientId}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </InputGroup>
                                            {formik.errors.clientId && formik.touched.clientId && (
                                                <div>{formik.errors.clientId}</div>
                                            )}
                                        </FormGroup>

                                  <FormGroup className="mb-3">
                                        Nombre:
                                        <InputGroup className="input-group-alternative mt-2">
                                            <Input
                                                placeholder="Nombre"
                                                type="text"
                                                name="name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </InputGroup>
                                        {formik.errors.name && formik.touched.name && (
                                            <div>{formik.errors.name}</div>
                                        )}
                                    </FormGroup>

                                    <FormGroup className="mb-3">
                                        Apellido:
                                        <InputGroup className="input-group-alternative mt-2">
                                            <Input
                                                placeholder="Apellido"
                                                type="text"
                                                name="lastName"
                                                value={formik.values.lastName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </InputGroup>
                                        {formik.errors.lastName && formik.touched.lastName && (
                                            <div>{formik.errors.lastName}</div>
                                        )}
                                    </FormGroup>

                                    <FormGroup className="mb-3">
                                        DNI o CUIT:
                                        <InputGroup className="input-group-alternative mt-2">
                                            <Input
                                                placeholder="Identificador"
                                                type="text"
                                                name="identifier"
                                                value={formik.values.identifier}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </InputGroup>
                                        {formik.errors.identifier && formik.touched.identifier && (
                                            <div>{formik.errors.identifier}</div>
                                        )}
                                    </FormGroup>

                                            
                                            <FormGroup className="mb-3">
                                                Dia de Facturacion:
                                                <InputGroup className="input-group-alternative mt-2">
                                                    <Input
                                                        placeholder="Dia"
                                                        type="number"
                                                        name="autoInvoiceDay"
                                                        value={formik.values.autoInvoiceDay}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        min="1" max="28"
                                                    />
                                                </InputGroup>
                                                {formik.errors.autoInvoiceDay && formik.touched.autoInvoiceDay && (
                                                    <div>{formik.errors.autoInvoiceDay}</div>
                                                )}
                                            </FormGroup>
                                            <FormGroup className="mb-3">
                                                Tipo Factura:
                                                <InputGroup className="input-group-alternative mt-2">
                                                    <Input
                                                        type="select"
                                                        name="invoiceType"
                                                        value={formik.values.invoiceType}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    >
                                                        <option value="">Tipo de Factura</option>
                                                        <option value="A">A</option>
                                                        <option value="B">B</option>
                                                    </Input>
                                                </InputGroup>
                                                {formik.errors.invoiceType && formik.touched.invoiceType && (
                                                    <div>{formik.errors.invoiceType}</div>
                                                )}
                                            </FormGroup>

    
                                            <div className="text-center">
                                                <Button
                                                    className="my-4"
                                                    color="primary"
                                                    type="submit"
                                                >
                                                    Agregar
                                                </Button>
                                                <Button
                                                    color="secondary"
                                                    data-dismiss="modal"
                                                    type="button"
                                                    onClick={() => resetearFacturacion()}
                                                >
                                                    Cancelar
                                                </Button>
                                            </div>
                                        </>
                                    
                                </Form>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        </>
    )
    
}


export default ModalAggFacturacion
