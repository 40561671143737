import { successAlert } from 'pages/components/SweetAlert';
import { warningAlert } from 'pages/components/SweetAlert';
import { confirmAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react'
import { Button, Table } from 'reactstrap'
import { deleteTipoMantenimiento } from 'services/TiposMantenimientosService';
import { getTiposMantenimientos } from 'services/TiposMantenimientosService';
import ModalAggTipoMan from './ModalAggTipoMan'
import ModalEditTipoMan from './ModalEditTipoMan';

const TableMantenimientos = props => {
    const {ModalAgregarTipoMantenimiento, setModalAgregarTipoMantenimiento, 
       }=props;
    const [TiposMantenimientos, setTiposMantenimientos] = useState([]);
    const [Alert, setAlert] = useState(null);
    const [ModalEditarTipoMantenimiento, setModalEditarTipoMantenimiento] = useState(false);
    const [TipoSeleccionado, setTipoSeleccionado] = useState(null)

    const traerTiposMan=async()=>{
        let tipos= await getTiposMantenimientos();
        if (tipos !==null && tipos !=="") {
            setTiposMantenimientos(tipos)
        }
    }
    const confirmarEliminar= async(mant)=>{
    confirmAlert(setAlert, eliminarTipoMan, mant , "Deseas eliminar a -"+`${mant.nombre}`+"- de Tipos de Mantenimiento?")
    }

    const eliminarTipoMan = async(mant)=>{
    let params = mant.id;
    let result = await deleteTipoMantenimiento(params)
    if(result != null){
            successAlert(setAlert, "Tipo de Mantenimiento Eliminado!", "");
            traerTiposMan()
            return
    }else{
         warningAlert(setAlert,'No se pudo eliminar el Tipo de Mantenimiento')
    }
    };

    const editarTipoMan = (tman)=>{
        setTipoSeleccionado(tman);
        setModalEditarTipoMantenimiento(true); 
    }

    useEffect(() => {
        traerTiposMan()
    }, [])
    
  return (
    <>
    {Alert}
        <Table className="align-items-center" responsive>
            <thead className="thead-light text-center">
                <tr>
                    <th scope="col">TIPO DE MANTENIMIENTO</th>
                    <th scope="col">PRECIO</th>
                    <th scope="col">EDITAR</th>
                    <th scope="col">ELIMINAR</th>
                </tr>
            </thead>
            <tbody>
    {TiposMantenimientos?
    TiposMantenimientos.map((tm, index)=>{
        return(
                <tr key={index}>
                    <td>
                    <div className="text-center">
                            <span className="mb-0 text-sm">
                                <b>{tm.nombre}</b>
                            </span>
                        </div>
                    </td>
                    <td>
                    <div className="text-center">
                            <span className="mb-0 text-sm">
                                <b>${tm.costo}</b>
                            </span>
                        </div>
                    </td>
                    <td>
                    <div className="text-center">
                        <Button className="btn-icon btn-3 btn-sm btn-outline-success rounded-circle" onClick={()=>{editarTipoMan(tm)}} >
                                <i className="fas fa-edit"></i>
                        </Button>
                        </div>
                    </td>
                    <td>
                    <div className="text-center">
                        <Button className="btn-icon btn-3 btn-sm btn-outline-danger rounded-circle" onClick={()=>{confirmarEliminar(tm)}}>
                                <i className="ni ni-fat-remove"/>
                        </Button>
                        </div>
                    </td>
                </tr>
            )
            })
            :
            <>-</>}
            </tbody>
    </Table>
    
    <ModalAggTipoMan 
    ModalAgregarTipoMantenimiento={ModalAgregarTipoMantenimiento}
    setModalAgregarTipoMantenimiento={setModalAgregarTipoMantenimiento}
    traerTiposMan={traerTiposMan}
    />
    {TipoSeleccionado!=null?
    <ModalEditTipoMan 
    ModalEditarTipoMantenimiento={ModalEditarTipoMantenimiento}
    setModalEditarTipoMantenimiento={setModalEditarTipoMantenimiento}
    TipoSelect={TipoSeleccionado}
    traerTiposMan={traerTiposMan}
    />
    :
    <></>
    }
    
    </>
  )
}

export default TableMantenimientos