import Buscador from 'pages/components/Buscador';
import LoadingComponent from 'pages/components/Loading';
import React, { useEffect, useState } from 'react'
import { Button,Card, CardBody,CardTitle,Row,Col } from "reactstrap";
import { getNoAsignados } from 'services/CambiosServices';
import TableServicios from './components/TableServicios';
import { getServiciosClientes } from 'services/ServiciosServices';
import ModalBuscar from '../cambiosDeEquipos/components/ModalBuscar';
import Paginacion from 'pages/components/Paginacion';
import SelectorServiciosAsignados from './components/SelectorServiciosAsignados';
import SelectorPlanes from 'pages/components/SelectorPlanes';
import { getTiposPlanes } from 'services/ServiciosServices';


const Servicios = () => {

    const [ServiciosClientes, setServiciosClientes] = useState([]); 
    const [NoAsignados, SetNoAsignados] = useState([]);
    const [ModalBuscarIP, setModalBuscarIP] = useState(false);
    const [Visible, setVisible] = useState(false);
    const [Asignado, setAsignado] = useState('');
    const [Planes, setPlanes] = useState([]);
    const [PlanId, setPlanId] = useState("");

    const traerServiciosClientes= async() => {
        setVisible(true)
        let params = {paginate:"si", page: 0, asignado: Asignado, servicePlanId: PlanId}
        let camb = await getServiciosClientes(params);
        if (camb != null && camb != '') {
            setServiciosClientes(camb);
        }
        setVisible(false)
    }
    const traerNoAsignados = async() => {
      
      let NoA = await getNoAsignados();
      if (NoA != null && NoA != '') {
        SetNoAsignados(NoA);
      }
    }
    const VerNoAsignados = async() => {
      setVisible(true)
      let NoA = await getNoAsignados();
      if (NoA != null && NoA != '') {
        setServiciosClientes(NoA);
      }
      setVisible(false)
    }
    const traerTiposPlanes = async() => {
      let pln = await getTiposPlanes();
      if (pln != null && pln != '') {
          setPlanes(pln);
      }
  }
    
    

    useEffect(() => {
        traerNoAsignados();
        traerServiciosClientes();
        traerTiposPlanes()
        
      }, [])
    return (
     <>
     <LoadingComponent Visible={Visible}/>
     {NoAsignados?  
     <Row>
       <Col>
       <Card className="card-stats mt-4 ml-5" style={{height:"7rem", width:"100%"}}>

              <CardBody >
                <Row >
                  <div className="col">
                    <CardTitle className="text-uppercase text-muted mb-0">
                      EQUIPOS<br/>
                      NO ASIGNADOS
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">{NoAsignados.length}</span>
                        
                        
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-red text-white rounded-circle shadow">
                    <i className="fas fa-user-times"></i>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
       </Col>
       <Col></Col>
       <Col></Col>
     </Row>
            
          
      :
      <p>Cargando..</p>
        }
      <Card>
        <Row className='m-4'>
          <Col className='col col-lg-4'><Buscador traerObjetos={traerServiciosClientes} setObjetos={setServiciosClientes} getObjetos={getServiciosClientes}/></Col>
          <Col><SelectorServiciosAsignados Asignado={Asignado} setAsignado={setAsignado}/></Col>
          <Col><SelectorPlanes Valor={PlanId} setValor={setPlanId} arrayElementos={Planes} titulo={"Plan..."}/></Col>
          <Col>
          <Button className='btn btn-md btn-outline-info' onClick={()=>traerServiciosClientes()}>Filtrar</Button>
          </Col>
          <Col>
          <Button className="btn-md btn-outline-success float-right" onClick={()=>setModalBuscarIP(true)}>
                Buscar IP
              </Button>
          </Col>
        </Row>
            {/* <Button className="btn-md btn-outline-success" onClick={()=>VerNoAsignados()}>
                Ver No Asignados
              </Button>
              <Button className="btn-md btn-outline-success" onClick={()=>traerServiciosClientes()}>
                Ver Todos
              </Button> */}
              
      <TableServicios 
        ServiciosClientes={ServiciosClientes}
        traerServiciosClientes={traerServiciosClientes}
      />
      <Paginacion traerObjetos={traerServiciosClientes} setObjetos={setServiciosClientes} getObjetos={getServiciosClientes}/>
      </Card>
      <ModalBuscar 
      ModalBuscarIP={ModalBuscarIP} 
      setModalBuscarIP={setModalBuscarIP}
      />
    </>
    )
}

export default Servicios;