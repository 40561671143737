import React from "react";
import Rendiciones from "./subVistas/Rendiciones";
import Equipos from "./subVistas/Equipos";
import Materiales from "./subVistas/Materiales";
import PagosSueldos from "./subVistas/PagosSueldos";
import VerCuenta from "./subVistas/VerCuenta";
import Reportes from "./subVistas/Reportes";




function ContenedorSubVistas(props){
    const {vista, idEmpls} = props;
    
    if(vista === "Reportes"){
        return (
            <>
                <Reportes idEmpls={idEmpls}/>
            </>
        )
    }
    if(vista === "Rendiciones"){
        return (
            <>
                <Rendiciones idEmpls={idEmpls}/>
            </>
        )
    }else if(vista === "Equipos"){
        return (
            <>
                <Equipos idEmpls={idEmpls}/>
            </>
        )
    }else if(vista === "Materiales"){
        return (
            <>
                <Materiales idEmpls={idEmpls}/>
            </>
        )
    }else if(vista === "PagosSueldos"){
        return (
            <>
                <PagosSueldos idEmpls={idEmpls}/>
            </>
        )
    }else if(vista === "VerCuenta"){
        return (
            <>
                <VerCuenta idEmpls={idEmpls}/>
            </>
        )
    }else{
        <></>
    }  
}



export default ContenedorSubVistas;