import { successAlert } from 'pages/components/SweetAlert'
import React, { useState } from 'react'
import { Button, ButtonGroup, Card, CardHeader, Form, FormGroup, Modal } from 'reactstrap'
import { putCaso } from 'services/CasosServices';
import classnames from "classnames";


const ModalCambiarCo=(props)=>{

    const {ModalCambiarColor, setModalCambiarColor, filtrarCasosEstado, Caso}=props;

    const [alertSweet, setAlert] = useState(null);
    const [radiosAcc, setRadiosAcc] = useState(null);

    const actualizarRadius = async() => {
        let casoAux = {};
        Object.assign(casoAux, Caso)
              casoAux.color = radiosAcc;
            
        let ter = await putCaso(casoAux);
            if (ter !== null && ter !== '') {
              successAlert(setAlert, "Caso Editado!", "")
              setModalCambiarColor(false)
              filtrarCasosEstado(1)
              
                
            }
  
      }

    return(
        <>
        {alertSweet}
        <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={ModalCambiarColor}
                            toggle={() => setModalCambiarColor(false)}
                          >
                          <div className="modal-body p-0">
                            <Card className="bg-secondary shadow border-0">
                              <CardHeader className="bg-transparent pb-5">
                                <div className="text-center"><b>Editar Identificador del Caso</b>
                                <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => setModalCambiarColor(false)}
                                >
                                  <span aria-hidden={true}>×</span>
                                </button>
                                </div>
                                <div className="text-muted mt-2 mb-3">
                                <Form role="form">  
                                  <FormGroup className="mb-3 mt-5">
                                  <div className="text-center ">
                                  <label className="form-control-label d-block mb-3">
                                    Identificador del Caso:
                                  </label>
                                  <ButtonGroup
                                    className="btn-group-toggle btn-group-colors event-tag"
                                    data-toggle="buttons"
                                  >
                                    <Button
                                      className={classnames("bg-danger", {
                                        active: radiosAcc === "bg-danger",
                                      })}
                                      color=""
                                      type="button"
                                      onClick={() => setRadiosAcc("bg-danger")}
                                    />
                                        <Button
                                          className={classnames("bg-warning", {
                                            active: radiosAcc === "bg-warning",
                                          })}
                                          color=""
                                          type="button"
                                          onClick={() => setRadiosAcc("bg-warning")}
                                        />
                                        <Button
                                          className={classnames("bg-yellow", {
                                            active: radiosAcc === "bg-yellow",
                                          })}
                                          color=""
                                          type="button"
                                          onClick={() => setRadiosAcc("bg-yellow")}
                                        />
                                        <Button
                                          className={classnames("bg-default", {
                                            active: radiosAcc === "bg-default",
                                          })}
                                          color=""
                                          type="button"
                                          onClick={() => setRadiosAcc("bg-default")}
                                        />
                                        <Button
                                          className={classnames("bg-primary", {
                                            active: radiosAcc === "bg-primary",
                                          })}
                                          color=""
                                          type="button"
                                          onClick={() => setRadiosAcc("bg-primary")}
                                        />
                                    <Button
                                      className={classnames("bg-info", {
                                        active: radiosAcc === "bg-info",
                                      })}
                                      color=""
                                      type="button"
                                      onClick={() => setRadiosAcc("bg-info")}
                                    />
                                    <Button
                                      className={classnames("bg-success", {
                                        active: radiosAcc === "bg-success",
                                      })}
                                      color=""
                                      type="button"
                                      onClick={() => setRadiosAcc("bg-success")}
                                    />
                                  </ButtonGroup>
                                  </div>
                                </FormGroup>
                                                       
                              
                                  <div className="text-center ">
                                    <Button
                                      className="mt-3 mb-3"
                                      color="primary"
                                      type="button"
                                      onClick={()=>actualizarRadius()}
                                      
                                      
                                    >
                                      Editar
                                    </Button>
                                
                                    <Button
                                      color="secondary"
                                      data-dismiss="modal"
                                      type="button"
                                      onClick={() => setModalCambiarColor(false)}
                                    >
                                      Cancelar
                                    </Button>                    
                                  </div>
                                </Form>                     
                                </div>                    
                              </CardHeader>                  
                            </Card>
                          </div>
                          </Modal>
        </>
    )
}
export default ModalCambiarCo