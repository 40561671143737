import SelectorZonas from 'pages/components/SelectorZonas';
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap';
import { postReporte } from 'services/ReportesService';

const ModalAgregarRep =(props)=> {
    const {ModalAgregarReporte, setModalAgregarReporte}= props;

    const [descripcion, setDescripcion] = useState("");
    const [Zona, setZona] = useState("");
    const [Alert, setAlert] = useState(null);
    


    const cambiarDescripcion = event => {
        setDescripcion(event.target.value);
      };

//       const AgregarReporte = async()=>{
    
//         let reporte = {
//           descripcion: descripcion,
//           clasificacion: clasificacion,
          
//         }
//       let result = await postReporte(reporte)
//       if(result != null){
//         successAlert(setAlert,'Reporte Agregado');
//         setDireccion("")
//         setModalAgregarReporte(false)

//     }else{
//         warningAlert(setAlert,'No se pudo agregar el Reporte')
//     }
//   }

  useEffect(() => {
 

}, [])

    return (
       <>
       {Alert}
        <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={ModalAgregarReporte}
            toggle={() => setModalAgregarReporte(false)}
          >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-transparent pb-5">

                    <div className="text-center">
                      <b>Agregar Reporte</b>
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => setModalAgregarReporte(false)}
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                    </div>
                    <div className="text-muted mt-2 mb-3">
                    <Form role="form">
                    <FormGroup className="mb-3 text-area">Descripcion:
                  
                        <Input 
                        placeholder="Descripcion..." 
                        type="textarea" 
                        onChange={cambiarDescripcion} 
                        value={descripcion}
                        className=" form-control-alternative textarea-autosize"
                        />
                    </FormGroup> 
                      <FormGroup>
                      <label htmlFor="exampleFormControlSelect2">
              Clasificacion
            </label>
            <Input id="exampleFormControlSelect2" multiple="" type="select">
              <option>Opciones</option>
              <option>BUENO</option>
              <option>MALO</option>
            </Input>
                      </FormGroup>
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                        //   onClick={() => AgregarReporte()}
                        >
                          Agregar
                        </Button>
                        <Button
                          color="secondary"
                          data-dismiss="modal"
                          type="button"
                          onClick={() => setModalAgregarReporte(false)}
                        >
                          Cerrar
                        </Button>                    
                      </div>
                    </Form>                     
                    </div>                    
                  </CardHeader>                  
                </Card>
              </div>
        </Modal>
       </>
    )
}


export default ModalAgregarRep
