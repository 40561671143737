import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from "reactstrap";
import { getObservacionesCliente } from "services/ObservacionesService";
import ModalAObservacion from "../components/ModalAgregarObservacion";


function Observaciones(props){

  const {idClient} = props;
  const [Observaciones, setObservaciones] = useState([]);
  const [ModalAgregarObservacion, setModalAgregarObservacion] = useState(false);


  const traerObservacionesCliente = async() => {
    let observ = await getObservacionesCliente(null, idClient);
    if (observ != null && observ != '') {
      setObservaciones(observ);
    }
  }
  const obtenerFormatoFecha = (creado)=>{
    let fecha = new Date(creado);
    let dia = fecha.getDate();
    let mes = fecha.getMonth();
    let ano = fecha.getFullYear();
    let fechaFormateada = `${dia}/${mes+1}/${ano}`;
    return fechaFormateada;
  } 

  useEffect(() => {
    traerObservacionesCliente();
  }, [])

    return (
        <>
        <div>
            <Button className="btn-md btn-info mt-4 mb-0 float-left" onClick={()=>setModalAgregarObservacion(true)}>
                Agregar Observación <br/>
              </Button>
          </div>
          {Observaciones?  
               Observaciones.map((obs, index) => {
                return (
        <Card key={index} className="text-center mt-4 mb-2" style={{width:"100%"}}>
          
              
          <CardHeader>
          {obtenerFormatoFecha(obs.creado)}
          </CardHeader>
          <CardBody >
            <b>{obs.nota}</b>
          </CardBody>
          <CardFooter className="text-muted">
          Creado por: {obs.usuario?.usuario}
          </CardFooter>
        </Card>
          )
           })
      
          :
          <p>Cargando..</p>
            }
            <ModalAObservacion
            ModalAgregarObservacion={ModalAgregarObservacion}
            setModalAgregarObservacion={setModalAgregarObservacion}
            idClient={idClient}
            traerObservacionesCliente={traerObservacionesCliente}
            />
        </>
    )
}


export default Observaciones;