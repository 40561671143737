import EtiquetaEstado from 'pages/private/casos/components/EtiquetaEstado';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Button, ButtonGroup, Table } from 'reactstrap';
import Categoria from './Categoria';
import ModalCambCo from './ModalCambColor';

const TableCasosClt = (props) => {

    const {Caso, traerCasosCliente, setAlert}=props;
    const [ModalCambiarColorCa, setModalCambiarColorCa]= useState(false);
    const [CasoP, setCasoP] = useState({});

    const history = useHistory ();


    const obtenerFormatoFecha = (creado)=>{
        let fecha = new Date(creado);
        let dia = fecha.getDate();
        let mes = fecha.getMonth();
        let ano = fecha.getFullYear();
        let fechaFormateada = `${dia}/${mes+1}/${ano}`;
        return fechaFormateada;
    }
    const irCaso = (idCaso)=>{
        history.replace(`/admin/casoParticular/${idCaso}`)
    
      }
  return(
      <>
       <Table className="align-items-center" responsive>
        <thead className="thead-light text-center">
            <tr>
              <th scope="col">COLOR PERSONAL</th>
              <th scope="col">AREA</th>
              <th scope="col">NUMERO DE RECLAMO</th>
              <th scope="col">CREADO POR</th>
              <th scope="col">ESTADO</th>
              <th scope="col">FECHA DE CREACION</th>
              <th scope="col">CLIENTE ID</th>
              <th scope="col">VER</th>
              <th scope="col" />
              </tr>
            </thead>
            <tbody>
            {Caso?  
               Caso.map((Caso, index) => {
                return (
                    <tr key={index} className="text-center">                   
                  
                  <th scope="row">
                  <div className=" text-center">
                        <ButtonGroup
                      className="btn-group-toggle btn-group-colors event-tag ml-4"
                      data-toggle="buttons"
                    >
                      <Button
                        className={Caso.color}
                        color=""
                        type="button"
                        onClick={()=>{setModalCambiarColorCa(true);setCasoP(Caso)}}
                        
                      />
                    </ButtonGroup>
                    </div>
                    </th>
                    
                    <td>
                    <div className=" text-center">
                          <span className="mb-0 text-sm">
                            {Caso.categoriaId?
                            <Categoria categoriaId={Caso.categoriaId}/>
                            :
                            <p>Sin Categoria</p>
                            }
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className=" text-center">
                          <span className="mb-0 text-sm">
                            {Caso.id}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className=" text-center">
                          <span className="mb-0 text-sm">
                            {Caso.usuario?.usuario}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className=" text-center">
                          <span className="mb-0 text-sm">
                            {Caso.estado?
                            <EtiquetaEstado Estado={Caso.estado}/>
                            :
                      <p>Sin estado</p>
                          }
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className=" text-center">
                          <span className="mb-0 text-sm">
                            {obtenerFormatoFecha(Caso.creado)}
                            
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className=" text-center">
                          <span className="mb-0 text-sm">
                            {Caso.clienteId}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className=" text-center">
                          <Button className="btn-icon btn-3 btn-md btn-outline-success "  type="button" onClick={()=>irCaso(Caso.id)}>
                          
                              <span className="btn-inner--icon">Ver    
                              <i className="far fa-eye"></i>
                              </span>
                              
                          </Button>
                       </div>
                    </td>
                  </tr>
              )
            })
          
        :
        <p>Cargando..</p>
          }
         </tbody>       
              
            
          
          </Table>
          <ModalCambCo
        setModalCambiarColorCa={setModalCambiarColorCa}
        ModalCambiarColorCa={ModalCambiarColorCa}
        traerCasosCliente={traerCasosCliente}
        CasoP={CasoP}
        setAlert={setAlert}
        
        />
      </>
  )
};
export default TableCasosClt;
