import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Card,
  CardBody,
  Input,
  InputGroupAddon,
  InputGroup,
  Container,
  NavbarBrand,
  Navbar
  
} from "reactstrap";
  import { getCasos } from "services/CasosServices.js";
import ModalAgregarCasoClt from "../components/ModalAgregarCasoCliente";
import TableCasosClt from "../components/TableCasosCliente";
function Casos(props){
  const {idClient} = props;
  const [ModalAgregarCasoCliente, setModalAgregarCasoCliente] = useState(false);
  const [Caso, SetCasosCliente] = useState([]);
  const [alertSweet, setAlert] = useState(null);;


/* Falta ruta  */
// const BuscarCaso = async()=>{
//   let params = {query_result:query}
//   let result = await getCasosCliente(params)
//   if(result != null){
//     SetCasosCliente(result);
//   }else{alert("no se pudo buscar"); traerCasosCliente()}
// }

  const traerCasosCliente = async() => {
    let params = {clienteId:idClient}
    let cas = await getCasos(params);
    if (cas != null && cas != '') {
      SetCasosCliente(cas);
    }
  }
  
  
  useEffect(() => {
    traerCasosCliente();
  }, [])


    return (
        <>
        {alertSweet}
        <Card className="mt-4" style={{ width:"100%"}}>
          <CardBody>
          <Navbar
          className="navbar-horizontal navbar-light bg-secondary"
          expand="lg"
        >
          <Container>
            <NavbarBrand>
              <InputGroup>
           <Input placeholder="Buscar" type="text" 
            //  value={query} 
            //  onChange={cambiarQuery}
           />
           <InputGroupAddon addonType="append">
             <Button className="ml-3" color="success" outline type="button" >
             {/* onClick={()=>BuscarCaso()} */}
             
               Buscar
             </Button>
             <Button className="ml-3" color="success" outline type="button"  >
             {/* onClick={()=>atras()} */}
             
               Atras
             </Button>
           </InputGroupAddon>
         </InputGroup>
            </NavbarBrand>
          </Container>
                          <Button className=" btn-md btn-outline-success " type="button" onClick={()=>setModalAgregarCasoCliente(true)} >
                          
                              <span>
                              Agregar Caso
                              </span>
                          </Button>
        </Navbar>
       <TableCasosClt
       Caso={Caso}
       setAlert={setAlert}
       traerCasosCliente={traerCasosCliente}
       />
          </CardBody>
        </Card>
        <ModalAgregarCasoClt
        traerCasosCliente={traerCasosCliente}
        setModalAgregarCasoCliente={setModalAgregarCasoCliente}
        ModalAgregarCasoCliente={ModalAgregarCasoCliente}
        setAlert={setAlert}
        idClient={idClient}
        />
        
        </>
    )
}
export default Casos;