import React, { useEffect, useRef, useState } from 'react'
import { getEsperas } from 'services/EsperaServices';
import Marcador from './Marcadores';
import {Button} from "reactstrap";


const Maps = () => {


  const [Esperas, setEsperas] = useState([]); 

  const filtrarEstadoEspera = async(est) => {
      let params = {estado:est}
      let espe = await getEsperas(params);
      if (espe != null && espe != '') {
        setEsperas(espe);
      }
      return espe
   
  }


  const crearMarcador = (espera, google, map) => {
    let lat = espera.lat;
    let lng = espera.lng;
    let latAntena = -34.10116558586446 ;
    let lngAntena =-59.02007028235467;
    const UbicacionEspera = new google.maps.LatLng(lat, lng);
    const UbicacionAntena = new google.maps.LatLng(latAntena, lngAntena);
    const marker = new google.maps.Marker({
      position: UbicacionEspera,
      map: map,
      animation: google.maps.Animation.DROP,
      title: "Argon Dashboard PRO React!",
    });
    
    const image = {
      url: "http://maps.google.com/mapfiles/kml/pal3/icon63.png",
      // This marker is 20 pixels wide by 32 pixels high.
      size: new google.maps.Size(35, 45),
      // The origin for this image is (0, 0).
      origin: new google.maps.Point(0, 0),
      // The anchor for this image is the base of the flagpole at (0, 32).
      anchor: new google.maps.Point(0, 32),
    };
    // Shapes define the clickable region of the icon. The type defines an HTML
    // <area> element 'poly' which traces out a polygon as a series of X,Y points.
    // The final coordinate closes the poly by connecting to the first coordinate.
    const shape = {
      coords: [1, 1, 1, 20, 18, 20, 18, 1],
      type: "poly",
    };
    const markerAntena = new google.maps.Marker({
      position: UbicacionAntena,
      map: map,
      animation: google.maps.Animation.DROP,
      title: "Argon Dashboard PRO React!",
      type: "info",
      icon: image,
      shape: shape
    });
    const contenedorAntena =
      `<div id="info_content" className="info-window-content text-center" ><h2>ANTENA</h2>`;
    const infoAntena = new google.maps.InfoWindow({
      content: contenedorAntena,
    });
    google.maps.event.addListener(markerAntena, "click", function () {
      infoAntena.open(map, markerAntena);
    });
    const contentString =
      `
      <script>
        let nombre = ()=>{console.log("hora")}
      </script>
      <div id="info_content" className="info-window-content text-center" ><h2>ID: ${espera.id} </br>
      ${espera.nombre} ${espera.apellido}</h2>` +
      `<p> ${espera.direccion}</p></div>`+
      `<p>Telefono: ${espera.telefono}</p>`
      +`<Button onClick="nombre()">Cerrar</Button>`

        const infowindow = new google.maps.InfoWindow({
          content: contentString,
        });
        google.maps.event.addListener(marker, "click", function () {
          infowindow.open(map, marker);
        });
  }

  const mapRef = React.useRef(null);

  const inicializar = async() => {
    let espe = await filtrarEstadoEspera(1)
    let google = window.google;
    let map = mapRef.current;
    const mapOptions = {
      zoom: 14,
      center: {lat: -34.09627609601492, lng: -59.02480293512182},
      scrollwheel: true,
      zoomControl: true,
    };
    
    map = new google.maps.Map(map, mapOptions);

    espe.map(e => {
      crearMarcador(e, google, map)
    })
  }


  React.useEffect(() => {

    inicializar()
    
  }, []);
  return (
    <>
    <div
      style={{ height: `600px` }}
      className="map-canvas"
      id="map-default"
      ref={mapRef}
    />
    
   

    </>
    
  );
};
export default Maps
