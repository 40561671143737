
import { successAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react'
import { useHistory } from 'react-router';
import { Button } from 'reactstrap'



function EtiquetaEsperaAsignada(props){
    const {Asignado, Esperas} = props
    const history = useHistory();
    const [Alert, setAlert] = useState("")


    const irAgenda=(idEsp)=>{

        history.replace({
            pathname: '/admin/agenda',
            state:{
                idEspera: idEsp
            }
        })
    }
    const Alerta =()=>{
        successAlert(setAlert, "Esta espera ya esta Agendada", obtenerFormatoFecha(Asignado) )
    }

    const obtenerFormatoFecha = (fechaIngreso)=>{
        let fecha = new Date(fechaIngreso);
        let dia = fecha.getDate();
        let mes = fecha.getMonth();
        let ano = fecha.getFullYear();
        let fechaFormateada = `${dia+1}/${mes+1}/${ano}`;
        return fechaFormateada;
    }
    
    return(
        <>
        {Alert}
     
        
        {Asignado?
            <Button onClick={()=>Alerta()} className="btn-icon btn-3 btn-sm btn-outline-success  "  type="button" >
               
                <span className="btn-inner--icon">    
                SI
                </span>
                
            </Button>
        :
            <Button  onClick={()=>irAgenda(Esperas.id)} className="btn-icon btn-3 btn-sm btn-outline-danger "  type="button">
                
                <span className="btn-inner--icon">    
                NO
                </span>
                
            </Button>

            
    }
     
        </>
    )
}

export default EtiquetaEsperaAsignada;