import SelectorCiudades from 'pages/components/SelectorCiudades'
import { successAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap'
import { getCiudades } from 'services/CiudadesService';
import { putServicio } from 'services/ServiciosServices';

const ModalEditServicio = props => {

    const {ModalEditarServicio, setModalEditarServicio, traerServicio, Servicio, setAlert}=props;
    const [direccion, setDireccion] = useState([]);
    const [Ciudad, setCiudad] = useState("");
    const [Ciudades, setCiudades] = useState([]);

    const cambiarDireccion = event => {
        setDireccion(event.target.value);
      };

      const traerCiudades = async() => {
        let cds = await getCiudades();
        if (cds != null && cds != '') {
            setCiudades(cds);
        }
    }


    const actualizarServicio = async() => {
        let servAcc = {
         id: Servicio.id,
         addressGpsLat: Servicio.addressGpsLat,
         addressGpsLon: Servicio.addressGpsLon,
         city: Ciudad,
         fullAdress: direccion
        };
        let acc = await putServicio(servAcc);
            if (acc) {
              successAlert(setAlert, "Servicio Editado!", "")
              setModalEditarServicio(false)
              traerServicio()
            }
  
      }
      useEffect(() => {
       
        traerCiudades();

      }, [])
  return (
    <>
        <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={ModalEditarServicio}
            toggle={() => setModalEditarServicio(false)}
            >
            
            <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalEditarServicio(false)}
                >
                    <span aria-hidden={true}>×</span>
                </button>
                <div className="text-center"><b>Editar Servicio</b>
                
                </div>
                </CardHeader>  
                <CardBody>
                <div className="text-muted mt-2 mb-3">
                <Form role="form">
                <FormGroup>
                    <label>Ciudad:</label>
                    <SelectorCiudades Valor={Ciudad} setValor={setCiudad} arrayElementos={Ciudades}/>
                    </FormGroup>
                <FormGroup >
                    
                    Direccion:
                <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                    <i class="fas fa-map-marked-alt"></i>
                    </InputGroupText>
                    </InputGroupAddon>
                    <Input
                    placeholder="Direccion..." 
                    type="text"
                    value={direccion}
                    onChange={cambiarDireccion}
                    />
                    </InputGroup>
                    </FormGroup>

                    <div className="text-center">
                    <Button
                        className="my-4"
                        color="primary"
                        type="button"
                        onClick={()=>actualizarServicio()}
                    >
                        Editar
                    </Button>
                
                    <Button
                        color="secondary"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModalEditarServicio(false)}
                    >
                        Cerrar
                    </Button>                    
                    </div>
                </Form>                     
                </div>                    
                </CardBody>              
            </Card>
            
        </Modal>
    </>
  )
}

export default ModalEditServicio