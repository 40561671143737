import { urlGlobal2 } from './GlobalService';



// *** CATEGORIAS *** *** CATEGORIAS *** *** CATEGORIAS *** *** CATEGORIAS ***

export async function getCategorias(token){
  let output = null;
  let categorias = null;
  await fetch(`${urlGlobal2}/categorias`, {
    method: 'GET',
    headers: {
      'token':token
    },
  })
  .then(response => output = response.json())
  .then(data => {
    categorias = data;
  })
  .catch((error) => {alert(error); categorias = null});
  return categorias;
}

export async function postCategorias(token, categorias){
  let output = null;
  let categoriaCreado = null;
  await fetch(`${urlGlobal2}/categorias`, {
      method: 'POST',
      headers: {
        'Content-Type':'application/json',
        'token':token
      },
      body:JSON.stringify(categorias)
  })
      .then(response => output = response.json())
      .then(data => {
        if(data.ok){
          categoriaCreado = data.mensaje;
        }else{
          categoriaCreado = '';
        }
      })
      .catch((error) => alert(error));
  return categoriaCreado;
}


export async function putCategorias(cat){
  let url = `${urlGlobal2}/categorias`;
  try {
      let token = localStorage.getItem('token');
      
      let headers = {'Content-Type':'application/json','token':token}
      let method= 'PUT';
      let body = JSON.stringify(cat)
      const resp = await fetch(url, {headers, body, method});
      const data = await resp.json()
      return data;
  } catch (error) {
      
      return null;
  }
}


export async function deleteCategoria(id){
  let url = `${urlGlobal2}/categorias/${id}`;
  try {
      let token = localStorage.getItem('token');
      
      let headers = {'Content-Type':'application/json','token':token}
      let method= 'DELETE';
      const resp = await fetch(url, {headers, method});
      const data = await resp.json()
      return data;
  } catch (error) {
      
      return null;
  }
}