import React from 'react'
import { Card, CardBody, CardHeader, Container, Table } from 'reactstrap'

const DetalleMaterial = props => {
  return (
    <>
    <Container className='mt-6'>
    <Card>
        <CardHeader>
        <h1>Detalle Material</h1>
        <Table>
            <thead className='bg-info'>
                <tr className='text-center'>
                    <th>ID</th>
                    <th>PRODUCTO</th>
                    <th>FECHA STOCK</th>
                    <th>LUGAR STOCK</th>
                    <th>FECHA ENTREGA A EMPLEADO</th>
                    <th>EMPLEADO</th>
                    <th>FECHA DESTINO</th>
                    <th>DESTINO</th>
                </tr >
            </thead>
            <tbody>
                <tr className='text-center'>
                    <th>
                        1
                    </th>
                    <td>
                        Caños
                    </td>
                    <td>
                        09/10/2022
                    </td>
                    <td>
                        Oficina Zarate
                    </td>
                    <td>
                    14/10/2022
                    </td>
                    <td>
                        12
                    </td>
                    <td>
                    15/10/2022
                    </td>
                    <td>
                    Dir123
                    </td>
                </tr >
            </tbody>
        </Table>
        </CardHeader>
        <CardBody>

        </CardBody>
    </Card>
    </Container>
    </>
  )
}

export default DetalleMaterial