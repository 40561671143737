import React, { useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Row,
  Col,
  Badge,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
// import AvisoCongestion from "./AvisoCongestion";

const TableZonas = props => {
    const {Zonas}= props;
    const [Collapses, setCollapses] = useState({openedCollapses:[]})

    const obtenerCantidad=(antenas)=>{
      let suma= antenas.map(a=>a.clientesActuales).reduce((prev, curr)=> prev+curr, 0);
      return suma
    }
   const collapsesToggle = collapse => {
    let openedCollapses = Collapses.openedCollapses;
    if (openedCollapses==collapse) {
        setCollapses({
        openedCollapses: []
      });
    } else {
        setCollapses({
        openedCollapses: [collapse]
      });
    }
  };

  const obtenerAcccessPoints=(AccP)=>{
      
      return(
          AccP.map((ap, index)=>{
                if (ap.clientesActuales<=25) {
                return(
                    <Row key={index} style={{width:"100%"}} className="bg-green mb-2">
                        <Col>
                        <h5 className="mt-1">{ap.nombre}</h5>
                        </Col>
                        <Col>
                        <h5 className="float-right mt-1">Clientes: {ap.clientesActuales}</h5>
                        </Col>
    
                    </Row>
                )
            } else if (ap.clientesActuales>25 && ap.clientesActuales<29) {
                return(
                            <Row key={index} style={{width:"100%"}} className="bg-yellow mb-2">
                                <Col>
                                <h5 className="mt-1">{ap.nombre}</h5>
                                </Col>
                                <Col>
                                <h5 className="float-right mt-1">Clientes: {ap.clientesActuales}</h5>
                                </Col>
            
                            </Row>
                        )
            }else if (ap.clientesActuales>=29) {
    
                return(
                    <Row key={index} style={{width:"100%"}} className="bg-red mb-2">
                        <Col>
                        <h5 className="mt-1">{ap.nombre}</h5>
                        </Col>
                        <Col>
                        <h5 className="float-right mt-1">Clientes: {ap.clientesActuales}</h5>
                        </Col>
    
                    </Row>
                )
    
                }}))}
    return (
      <>
      {Zonas?
      Zonas.map((zn, index)=>{
        return(
        <div className="accordion" key={index}>
          <Card className="card-plain">
            <CardHeader
              role="tab"
              onClick={() =>collapsesToggle(zn.nombre)}
              aria-expanded={Collapses.openedCollapses==zn.nombre}
            >
                <Row>
                    <Col>
                        <h5 className="mb-0">{zn.nombre}</h5>
                    </Col>
                    <Col>
                    <Row className="float-right mr-3">
                        {/* <AvisoCongestion Congestion={zn.equiposantenas}/> */}
                        <Badge color="primary" className="float-right mr-3" pill>{obtenerCantidad(zn.equiposantenas)}</Badge>
                    </Row>
                    </Col>
                </Row>
            </CardHeader>
            <Collapse
              role="tabpanel"
              isOpen={Collapses.openedCollapses==zn.nombre}
            >
              <CardBody>
              <ListGroup flush>
          <ListGroupItem className="checklist-entry flex-column align-items-start py-4 px-4">
                {obtenerAcccessPoints(zn.equiposantenas)}
          </ListGroupItem>
        </ListGroup>
              </CardBody>
            </Collapse>
          </Card>
        </div>

        )
      })
    :
    <>Cargando...</>}
      </>
    );
}

export default TableZonas;
