import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Modal,
  Table
} from "reactstrap";




function EscanerMacs(props) {
  const {continuarScanEquipos, equipos, setEquipos} = props;
  const [defaultModal, setdefaultModal] = useState(false);
  let [escaneado, setEscaneado] = useState('');
  const [errorMAC, setErrorMAC] = useState('');

  const onDown = event => {
    
    if (event.code == "Enter") {
      if(escaneado){
        escaneado = (escaneado.replace(/:/gi, "")).replace(/ /g, "");
        var cadena = "ABCDEF0123456789";
        if (cadena.indexOf(escaneado[escaneado.length -1]) > -1 || escaneado == '') {
          setEquipos(equiposAnt => {
              let aux = [];
              aux.push(...equiposAnt);
              let esta = equiposAnt.includes(escaneado);
              if (!esta) {
                aux.push(escaneado);
                setEquipos(aux)
              }else{
                setEquipos(aux)
              }
            })  
        }
        else{
            setErrorMAC(true);
        }
      }
      setEscaneado(()=>{
        escaneado = '';
      });
      return
    }
    if (event.key != "Shift") {
      setEscaneado(()=>{
        escaneado += event.key 
      });
    }

  }
  
  // Bind and unbind events
  useEffect(() => {
    window.addEventListener("keydown", onDown)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  


    return (
      <>
              <Card className="card-pricing border-0 text-center mb-4" >
                <CardBody className="px-lg-7">
                  <h4 className=" ls-1 text-primary py-3 mb-0">
                    Escanee las Macs 
                  </h4>
                  <div className="display-2" >{equipos.length}</div>
                  <div className="display-2" >{errorMAC?"Error con la MAC escaneada":""}</div>
                </CardBody>
                <CardFooter className="footerO bg-transparent">
                  <Button className="mb-3" color="primary" type="button" onClick={()=> {continuarScanEquipos()}}>
                    Continuar
                  </Button>
                  <Button className="mb-3" color="primary" type="button" onClick={()=> {setdefaultModal(true)}}>
                    Ver Equipos
                  </Button>
                  <Modal
                      className="modal-dialog-centered"
                      isOpen={defaultModal}
                      toggle={() => setdefaultModal(false)}
                    >
                      <div className="modal-header">
                        {equipos.length == 0?
                        <h6 className="modal-title" id="modal-title-default">
                          No hay Equipos Escaneados
                        </h6>
                        :
                        <h6 className="modal-title" id="modal-title-default">
                          Equipos Escaneados:
                        </h6>
                        }
                        <button
                          aria-label="Close"
                          className="close"
                          data-dismiss="modal"
                          type="button"
                          onClick={() => setdefaultModal(false)}
                        >
                          <span aria-hidden={true}>×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        {equipos.length == 0?
                        <></>
                        :
                        <Table className="align-items-center table-flush" hover responsive>
                          <thead className="thead-light">
                            <tr>
                              <th>Controlado</th>
                              <th>Equipos</th>
                            </tr>
                          </thead>
                          <tbody>
                            {equipos.map((eq, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <label className="custom-toggle">
                                      <input type="checkbox" />
                                      <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Si"
                                      />
                                    </label>
                                  </td>
                                  <td className="table-user">
                                    <b>{eq}</b>
                                  </td>
                                </tr>

                              )

                            })}
                            
                          </tbody>
                        </Table>
                        }
                      </div>
                      <div className="modal-footer">
                        <Button
                          className="ml-auto"
                          color="link"
                          data-dismiss="modal"
                          type="button"
                          onClick={() => setdefaultModal(false)}
                        >
                          Cerrar
                        </Button>
                      </div>
                    </Modal>
                </CardFooter>
              </Card>

      </>
    );
  }


  
  
  export default EscanerMacs;