import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {Button,Card,CardFooter,Container,Row,Col,CardBody,FormGroup,Input,Modal,Table} from "reactstrap";
import './stylesAuditar.css'
import SelectorEmpleados from "../../components/SelectorEmpleados"
import EscanerMacs from "../../components/EscanerMacs"
import CategoriaXCantidad from "../../components/CategoriaXCantidad"
import CardBoton from "../../components/CardBoton"
import { getEquipos } from "services/EquiposService";
import jwt_decode from "jwt-decode";
import { postControles } from "services/ControlesService";
import {successAlert, warningAlert} from '../../components/SweetAlert';
import { GuardInPages } from "guards/Guard";

function AuditarDeposito() {
  const history = useHistory()

  const [vistaMuestra, setVistaMuestra] = useState("empleado");
  const [Empleado, setEmpleado] = useState("");
  const [equipos, setEquipos] = useState([]);
  const [categorias, setCategorias] = useState("");
  const [equiposEmpleado, setEquiposEmpleado] = useState([]);
  const [observaciones, setObservaciones] = useState("");
  const [token, setToken] = useState("");
  const [alertSweet, setAlert] = useState(null);
  const [equiposFalt, setEquipsFalt] = useState(null);
  const [defaultModal, setdefaultModal] = useState(false);
  const [controlado, setControlado] = useState(false);

  const continuarEmpleado = () => {
    if(Empleado == "" || !Empleado){
      warningAlert(setAlert, "Debe seleccionar un empleado!")
    }else{
      traerEquiposEmpleado();
      setVistaMuestra("escaner")
    }
  }

  const continuarScanEquipos = () => {
    setVistaMuestra("cantidadXcategoria")
  }

  const traerEquiposEmpleado = async() => {
    let params = {empleadoId:Empleado}
    let equips = await getEquipos(params);
    if (equips != null && equips != '') {
      setEquiposEmpleado(equips);
    }
}

  const controlar = async() => {
    let equipsEmpleado = [...equiposEmpleado];
    let equips = [...equipos];
    let equiposIds = [];
    let equiposFaltantesMacs = [];
    equipsEmpleado.map((eqEm)=>{
      if (!equips.includes(eqEm.macAddress)) {
        equiposIds.push(eqEm.id);
        equiposFaltantesMacs.push(eqEm.macAddress)
      }
    })
    setEquipsFalt(equiposFaltantesMacs);
    let decod = jwt_decode(token)
    let resultado = equiposIds.length == 0
    let control = {
      equiposIds,
      resultado,
      empleadoId: Empleado,
      auditor: decod.usuario.empleadoId,
      otrosmateriales: categorias,
      observaciones: observaciones
    }
    let respControl = await postControles(token, control);
    if (respControl) {
      successAlert(setAlert, "Control creado")
    }
    setControlado(true)
  }

  const cambiarObservacion = event => {
    setObservaciones(event.target.value);
  };

  const accionContinuar = () => {
    setVistaMuestra("controlar");
  }


  useEffect(() => {
    GuardInPages(history)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  

  if (vistaMuestra === "empleado") {


    return(
      <Container className="mt-6" fluid>
        {alertSweet}
        <Row>
          <Col></Col>
          <Col>
              <Card className="card-pricing border-0 text-center mb-4">
                <CardBody className="px-lg-7">
                  <h4 className="text-uppercase ls-1 text-primary py-3 mb-0">
                    Seleccione un empleado
                  </h4>
                  <SelectorEmpleados valor={Empleado} cambiarValor={setEmpleado} titulo={false}/>
                </CardBody>
                <CardFooter className="footerO bg-transparent">
                  <Button className="mb-3" color="primary" type="button" onClick={()=> {continuarEmpleado()}}>
                    Continuar
                  </Button>
                </CardFooter>
              </Card>
          </Col>
          <Col></Col>
        </Row>
      </Container>

    );
  }
  else if (vistaMuestra === "escaner") {
    return(
      <Container className="mt-6" fluid>
        <Row>
          <Col></Col>
          <Col>
            <EscanerMacs equipos={equipos} setEquipos={setEquipos} continuarScanEquipos={continuarScanEquipos}/>
          </Col>
          <Col></Col>
        </Row>
      </Container>
      
      );
  }
  else if(vistaMuestra === "cantidadXcategoria"){
    return(
      <Container className="mt-6" fluid>
        <Row>
          <Col></Col>
          <Col>
            <CategoriaXCantidad 
              categoriasGuardadas={categorias} 
              setCategoriasGuardadas={setCategorias}
              accionContinuar={accionContinuar}
              tipoValue={"nombre"}
            />
          </Col>
          <Col></Col>
        </Row>
      </Container>
      
      );
  }else if(vistaMuestra === "controlar"){
    
    return(
        <>
          {alertSweet}
          <Container className="mt-6" fluid>
            <Row>
              <Col></Col>
              <Col>
                <Card className="card-pricing border-0 text-center mb-4">
                  <CardBody className="px-lg-7">
                    <h4 className=" ls-1 text-primary ">
                      Observaciones
                    </h4>
                    <FormGroup >
                        <Input
                          value={observaciones}
                          onChange={cambiarObservacion}
                          id="example-number-input"
                          type="text"
                        />
                    </FormGroup>
                    
                  </CardBody>
                </Card>
              </Col>
              <Col></Col>
            </Row>
            <Row>
            <Col></Col>
              <Col>
                <CardBoton titulo={"Controlar Deposito"} accion={controlar} />
              </Col>
              <Col></Col>
            </Row>
            <Row>
            <Col></Col>
              <Col>
                <Card className="card-pricing border-0 text-center mb-4">
                  <CardFooter className="footerO bg-transparent">
                      {controlado?
                        <Button className="mb-3" color="primary" type="button" onClick={()=> {setdefaultModal(true)}}>
                          Ver Equipos Faltantes
                        </Button>
                      :
                      <></>
                      }
                  </CardFooter>
                </Card>
              </Col>
              <Col></Col>
            </Row>
          </Container>

          <Modal
                className="modal-dialog-centered"
                isOpen={defaultModal}
                toggle={() => setdefaultModal(false)}
              >
                <div className="modal-header">
                  {equiposFalt == null?
                  <h6 className="modal-title" id="modal-title-default">
                    No hay Equipos faltantes
                  </h6>
                  :
                  <h6 className="modal-title" id="modal-title-default">
                    Equipos faltantes:
                  </h6>
                  }
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setdefaultModal(false)}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  {equiposFalt == null?
                  <></>
                  :
                  <Table className="align-items-center table-flush" hover responsive>
                    <thead className="thead-light">
                      <tr>
                        <th>Controlado</th>
                        <th>Equipos</th>
                      </tr>
                    </thead>
                    <tbody>
                      {equiposFalt.map((eq, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <label className="custom-toggle">
                                <input type="checkbox" />
                                <span
                                  className="custom-toggle-slider rounded-circle"
                                  data-label-off="No"
                                  data-label-on="Si"
                                />
                              </label>
                            </td>
                            <td className="table-user">
                              <b>{eq}</b>
                            </td>
                          </tr>
                        )
                      })}
                      
                    </tbody>
                  </Table>
                  }
                </div>
                <div className="modal-footer">
                  <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setdefaultModal(false)}
                  >
                    Cerrar
                  </Button>
                </div>
              </Modal>

        </>
      
      );
  }
  else return (
    <>
      
    </>
  );
}

export default AuditarDeposito;
