import LoadingComponent from 'pages/components/Loading';
import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader } from "reactstrap";
import { TableCaes } from './components/TableCaes';
import { getCaes } from "../../../services/CaesServices";
import { ModalCaes } from './components/ModalCaes';
import { NavbarCaes } from './components/NavbarCaes';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'store/slices/caesSlice';

export const Caes = () => {
    const [caes, setCaes] = useState(null);
    const [defaultModal, setdefaultModal] = useState(false);
    const [fecha, setFecha] = useState({});
    const dispatch = useDispatch();

    const [Query, setQuery] = useState({});
    const {
        loading,
        queryParams
    } = useSelector((state) => state.caes);


    const handleGetCaes = async () => {

        try {
            dispatch(setLoading(true));
            const caes = await getCaes(queryParams);
            setCaes(caes);
        }
        catch (error) {
            alert("Algo salio mal");
        } finally {
            dispatch(setLoading(false));
        }


    }

    useEffect(() => {
        if (Object.keys(queryParams).length !== 0) {
            handleGetCaes();
        }
        return;
    }, [queryParams])

    return (
        <>
            <LoadingComponent Visible={loading} />
            <Card>
                <CardHeader>
                    <NavbarCaes
                        fecha={fecha}
                        setFecha={setFecha}
                        setdefaultModal={setdefaultModal}
                        Query={Query} setQuery={setQuery}
                    >
                    </NavbarCaes>
                </CardHeader>
                <CardBody>
                    <TableCaes Query={Query} setQuery={setQuery} caes={caes} />
                </CardBody>
            </Card>
            {
                caes && caes.length !== 0 && defaultModal ? 
                    <ModalCaes defaultModal={defaultModal} caes={caes} setdefaultModal={setdefaultModal}/>
                
                :
                    <></>            
            }
        </>
    )
}

