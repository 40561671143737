import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React from 'react';
import { Button, Col, Media, Row, Table } from 'reactstrap';
import { putEquipo } from 'services/EquiposService';

const TableEquiposEmpls = (props) => {
    const {Equipos, traerEquiposEpleado, setAlertS}=props;

    const obtenerFormatoFecha = (fechaIngreso)=>{
        let fecha = new Date(fechaIngreso);
        let dia = fecha.getDate();
        let mes = fecha.getMonth();
        let ano = fecha.getFullYear();
        let fechaFormateada = `${dia}/${mes+1}/${ano}`;
        return fechaFormateada;
    };
    const marcarComoRevisar = async(equipo) => {
        let equipRev = {};
        Object.assign(equipRev, equipo)
        equipRev.estadoId = 4;
        equipRev.empleadoId = null;
        let res = await putEquipo(equipRev)
        if (res != null && res != '') {
          successAlert(setAlertS, "Equipo marcado para revisar!")        
          traerEquiposEpleado()
        }else{
          warningAlert(setAlertS, "No se pudo marcar para revisar!")
        }
      }
  return(
      <>
      <Table className="text-center" responsive>
          <thead className="thead-light text-center">
            <tr>
              <th scope="col">MacADDRESS</th>
              <th scope="col">CATEGORIA/NOMBRE</th>
              <th scope="col">ULTIMA MODIFICACION</th>
              <th scope="col">MARCAR COMO REVISAR</th>
            </tr>
          </thead>
          <tbody>

          {Equipos?                      
            Equipos.map((eq, index) => {
              return (
                  <tr key={index}>
                    <th scope="row">
                        <div className="text-center">
                            <span className="mb-0 text-sm">
                            {eq.macAddress}
                           </span>
                        </div>
                    </th>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            {eq.categoria?.nombre}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            {obtenerFormatoFecha(eq.ultimaModificacion)}
                          </span>
                    </div>
                    </td>                                 
                    <td  className="align-items-center">
                    <div className="text-center">
                          <Button onClick={()=>{marcarComoRevisar()}} className="btn-icon btn-3 btn-sm btn-outline-success "  type="button">
                              <span className="btn-inner--icon">
                              <i className="fas fa-edit"></i>
                              </span>
                              
                          </Button>
                    </div>
                    </td>
                  </tr>
                  
                  
                )

              })
            :
            <p>Cargando..</p>
            } 
          </tbody>
            </Table>
      </>
  )
};

export default TableEquiposEmpls;
