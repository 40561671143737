import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap'
import { postCategoriaValores } from 'services/CategoriaValoresService';

const ModalAggCategorias = props => {
    const {ModalAgregarCategorias, setModalAgregarCategorias, traerValores} = props;
    const [Nombre, setNombre] = useState("");
    const [Alert, setAlert] = useState(null)

    const cambiarNombre = (e) => {
       setNombre(e.target.value)
    }
    const Agregar = async() => {
        let obj = {nombre : Nombre}
        let categoria = await postCategoriaValores(obj)
        if (categoria !== null) {
            setNombre("")
            successAlert(setAlert, "Categoria Creada!")
            setModalAgregarCategorias(false)
            traerValores()
        } else {
            warningAlert(setAlert, `Error al crear la categoria ${Nombre}`)
        }
    }
  return (
    <>
    {Alert}
        <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={ModalAgregarCategorias}
        toggle={() => setModalAgregarCategorias(false)}
        >
        <div>
        <Card>
            <CardHeader className="pb-3">
            <div className="text-center"><b>Agregar Categoria</b>
            <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setModalAgregarCategorias(false)}
        >
            <span aria-hidden={true}>×</span>
        </button>
            </div>
            </CardHeader>  
            <CardBody>
            <div className=" mt-5 mb-3">
            <Form role="form">
            <FormGroup>
                <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Nombre:
                </label>
                <Input
                placeholder='Nombre de la categoria'
                    type="text"
                    value={Nombre} 
                    onChange={cambiarNombre}
                    />
            </FormGroup>
            </Form>                     
            </div>                    
            </CardBody>
            <CardFooter>
                <div className="text-center">
                <Button
                    className=""
                    color="primary"
                    type="button"
                    onClick={()=>Agregar()}
                    
                    >
                    Agregar
                </Button>
            
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalAgregarCategorias(false)}
                >
                    Cerrar
                </Button>                    
                </div>
                </CardFooter>                
        </Card>
        </div>
        </Modal>
    </>
  )
}
export default ModalAggCategorias