import React, { useState } from 'react'
import { Button, Card, CardHeader, Col, Row } from 'reactstrap'
import TableMantenimientos from './components/TableMantenimientos'

const TiposMantenimiento = props => {
    const [ModalAgregarTipoMantenimiento, setModalAgregarTipoMantenimiento] = useState(false);
    
  return (
    <>
    <Card className='' style={{width:"100&"}}>
        <CardHeader>
            <Row>
                <Col><h2 className='ml-3 float-left'>Tipos de Mantenimientos</h2></Col>
                <Col>
                    <Button className='btn-md btn-default mr-3 float-right' onClick={()=>setModalAgregarTipoMantenimiento(true)}>
                    Agregar Tipo Mantenimiento
                    </Button>
                </Col>
            </Row>
        </CardHeader>
        <TableMantenimientos
        ModalAgregarTipoMantenimiento={ModalAgregarTipoMantenimiento}
        setModalAgregarTipoMantenimiento={setModalAgregarTipoMantenimiento}
        />
    </Card>
    </>
  )
}

export default TiposMantenimiento