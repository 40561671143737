import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import { confirmAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react'
import { Button, Table, UncontrolledTooltip } from 'reactstrap';
import { deleteCambio } from 'services/CambiosServices';
import { getRolPermisos } from 'services/RolService';
import EtiquetaAsignados from './EtiquetaAsignado';

export default function TableCambios(props){
    const {CambiosEquipos, traerCambiosEq} = props
    const [alertSweet, setAlert] = useState(null);
    const [Permisos, setPermisos] = useState([]);
    

    const obtenerFormatoFecha = (fechaIngreso)=>{
        let fecha = new Date(fechaIngreso);
        let dia = fecha.getDate();
        let mes = fecha.getMonth();
        let ano = fecha.getFullYear();
        let fechaFormateada = `${dia}/${mes+1}/${ano}`;
        return fechaFormateada;
    }
    const confirmarEliminar= async(id)=>{
        confirmAlert(setAlert, eliminarCambio, id , "Deseas eliminar este Cambio de Equipo?")
      }
      const eliminarCambio = async(id)=>{
        
      let result = await deleteCambio(id)
      if(result !== null){
              successAlert(setAlert, "Cambio Eliminado!", "")
              traerCambiosEq()
              return
    }else{
        warningAlert(setAlert,'No se pudo eliminar el Cambio')
    }
    };
    const traerPermisos= async()=>{

        let permisos= await getRolPermisos();
        if(permisos !== null && permisos!== ''){
          setPermisos(permisos)
        }
      }
    const tienePermiso=(nombrePermiso)=>{
    return Permisos.includes(nombrePermiso);
    } 
    
      useEffect(() => {
        traerPermisos();
      }, [])

    return(
        <>
        {alertSweet}
        <Table className="align-items-center" responsive>
            <thead className="thead-light text-center">
            <tr>
                <th scope="col">SERVICIO ID</th>
                <th scope="col">CLIENTE ID</th>
                <th scope="col">USUARIO</th>
                <th scope="col">MAC ANTERIOR</th>
                <th scope="col">MAC NUEVA</th>
                <th scope="col">FECHA</th>
                <th scope="col">ASIGNADO</th>
            </tr>
            </thead>
            <tbody>
            {CambiosEquipos?
            CambiosEquipos.map((ce, index)=>{
                return (
                <tr key={index}>
                    <td>
                    <div className="text-center">
                            <span className="mb-0 text-sm">
                                <b>{ce.servicioId}</b>
                            </span>
                        </div>
                    </td>
                    <td>
                    <div className="text-center">
                            <span className="mb-0 text-sm">
                                {ce.clientId}
                            </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                            <span className="mb-0 text-sm">
                                {ce.servicio?.radiusUsername
                                }
                            </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                            <span className="mb-0 text-sm">
                                {ce.equipoanterior
                                }
                            </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                            <span className="mb-0 text-sm">
                                {ce.equiponuevo
                                }
                            </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                            <span className="mb-0 text-sm">
                                {obtenerFormatoFecha(ce.servicio?.creado)}
                            </span>
                    </div>
                    </td>
                    <td>
                        <div className="text-center">
                            <EtiquetaAsignados traerCE={traerCambiosEq} setAlert={setAlert} Asignado={ce.servicio?.asignado} Servicio={ce.servicio} CambioEquipo={ce}/>
                        </div>
                    </td>
                    {Permisos && tienePermiso('Eliminar-Cambios-Equipos')?
                        <td>
                        <div className=" text-center">
                        <Button id="tooltip2" className="btn-icon btn-3 btn-sm btn-outline-danger rounded-circle"  type="button" onClick={()=>{confirmarEliminar(ce.id)}}>
                              <span className="btn-inner--icon">
                              <i className="ni ni-fat-remove"/>
                              </span>    
                        </Button>
                        <UncontrolledTooltip
                        delay={0}
                        placement="bottom"
                        target="tooltip2"
                        >
                        Eliminar Cambio
                        </UncontrolledTooltip>
                        </div>
                        </td>
                        :
                        <></>
                        }
                </tr>

                )
            })
            :
            <p>Cargando...</p>
            }
            </tbody>
        </Table>
        
        </>
        
    )
}