export const ValidarDatoEgreso = (egreso) => {
    let mensaje = null;

    if(!egreso.importe){
        mensaje = "El egreso no tiene importe";
    }

    if(!egreso.numeroOperacion){
        mensaje = "El egreso no tiene nro de Operacion";
    }


    if(!egreso.tipoEgresoId){
        mensaje = "El egreso no tiene tipo";
    }

    if(!egreso.cierrecajasId){
        mensaje = "El egreso no tiene cierre de caja Id";
    }


    return {
        ok:mensaje === null,
        mensaje
    }
   
}


export const ValidateDatoRetiro = (retiro) => {
    let mensaje = null;
    if(!retiro.importe){
        mensaje = "Este retiro no tiene importe";
    }

    if(!retiro.retiradoPor){
        mensaje = "Este retiro no especifica quien lo retiro";
    }

    return {
        ok:mensaje === null,
        mensaje
    }


}

