import React from "react";


function Categoria(props){
    const {categoriaId} = props;

    if(categoriaId == 1){
        return (
            <>
                -
            </>
        )
    }else if(categoriaId == 2){
        return (
            <>
                Facturacion
            </>
        )
    }else if(categoriaId == 3){
        return (
            <>
               Administracion
            </>
        )
    }else if(categoriaId == 4){
        return (
            <>
                Gerencia
            </>
        )
    }else if(categoriaId == 5){
        return (
            <>
                Tecnica
            </>
        )
    }else{
        <></>
    }  
}



export default Categoria;