import React , { useEffect, useState } from "react";
import '../../../assets/estilos/style.css';
import {
    Button,
    Container,
    NavbarBrand,
    Navbar,
    Card
  } from "reactstrap";
import Buscador from "pages/components/Buscador";

import TableEquiposEmpls from "./components/TableEquiposEmpleados";
import { getEquipos } from "services/EquiposService";
import ButtonGroups from "./components/ButtonGroups";
import ModalEntregarEquipos from "./components/ModalEntregarEquipos";
import ModalRecibirEquipos from "./components/ModalRecibirEquipos";
import NavBarEquipos from "./components/NavBarEquipos";
import MaterialesEmpleados from "../stock/MaterialesEmpleados";
import { getRolPermisos } from "services/RolService";

const EquiposEmpleados = (props) => {
    const [Equipos, setEquipos] = useState([])
    const [EmpleadoId, setEmpleadoId] = useState('')
    const [ModalAgregarEquipo, setModalAgregarEquipo]= useState(false);
    const [AlertS, setAlertS] = useState(null);
    const [EntregarEquiposModal,setModalEntregarEquipos] = useState(false);
    const [RecibirEquiposModal,setModalRecibirEquipos] = useState(false);
    const [entregandoEquipos,setEntregandoEquipos] = useState(false);
    const [vista, setVista] = useState("Equipos");
    const [Permisos, setPermisos] = useState([]);
   

    const traerEquiposEpleado = async() => {
        let idEmpl = props.match.params.idEmpleado;
        let params={empleadoId: idEmpl};
        setEmpleadoId(idEmpl)
        let equips = await getEquipos(params);
        if (equips != null && equips != '') {
            setEquipos(equips);
        }
      }
      const traerPermisos= async()=>{
        let permisos= await getRolPermisos();
        if(permisos !== null && permisos!== ''){
          setPermisos(permisos)
        }
      }
    const tienePermiso=(nombrePermiso)=>{
    return Permisos.includes(nombrePermiso);
    } 



    useEffect(() => {
      traerPermisos()
      traerEquiposEpleado()
  }, [])

        return (
          <>
          {AlertS}
          <Card>
            {
              Permisos && tienePermiso("Ver-Equipos") && tienePermiso("Ver-Tipos-Materiales")?
              <NavBarEquipos  setVista={setVista}/>
              :
              <></>
            }
            {tienePermiso("Ver-Equipos")?
            <>
            {vista === "Equipos"?
            <>
            <Navbar
            className="navbar-horizontal navbar-light bg-secondary"
            expand="lg"
          >
          
          
            <ButtonGroups 
              setModalEntregarEquipos={setModalEntregarEquipos}
              setModalRecibirEquipos={setModalRecibirEquipos}
              entregandoEquipos={entregandoEquipos}
              setEntregandoEquipos={setEntregandoEquipos}
              equipos={Equipos}
              empleadoId={EmpleadoId}
            ></ButtonGroups>

          
         
            </Navbar>
            <TableEquiposEmpls
            traerEquiposEpleado={traerEquiposEpleado}
            Equipos={Equipos}
            setAlertS={setAlertS}
            />


            <ModalEntregarEquipos EntregarEquiposModal={EntregarEquiposModal} 
            setModalEntregarEquipos={setModalEntregarEquipos}
            setAlertS={setAlertS} traerEquiposEpleado={traerEquiposEpleado}
            setEquipos={setEquipos}
            empleadoId={EmpleadoId}
            ></ModalEntregarEquipos>
    
            <ModalRecibirEquipos RecibirEquiposModal={RecibirEquiposModal}
            setModalRecibirEquipos={setModalRecibirEquipos}
            equipos={Equipos}
            traerEquiposEpleado={traerEquiposEpleado}
            setEquipos={setEquipos}
            ></ModalRecibirEquipos>
            </>
            :
            <>
            {tienePermiso("Ver-Tipos-Materiales")?
            <>
            <MaterialesEmpleados EmpleadoId={EmpleadoId}/>
            </>
            :
            <></>
            }
          </>
            }
            </>
            :
            <>
            {tienePermiso("Ver-Tipos-Materiales")?
            <>
            <MaterialesEmpleados EmpleadoId={EmpleadoId}/>
            </>
            :
            <></>
            }
          </>
            }
            
            
            </Card>
          </>
           
        )
      }


export default EquiposEmpleados;