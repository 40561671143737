
import { useEffect, useState } from "react";
import {Button,Table,Modal} from "reactstrap";
import {GenerateResume} from "../../../../services/CaesServices";
import { useSelector } from "react-redux";

export const ModalCaes = ({
  caes,
  defaultModal,
  setdefaultModal,
}) => {
    const [resume,setResume] = useState([]);
    const {fechaInicioFacturacion,fechaFinFacturacion} = useSelector((state) => state.caes);
    const [fecha,setFecha] = useState({});

    useEffect(() => {
        if(caes){
            let r = GenerateResume(caes);
            setResume(r);
           
        }  
    }, [caes])


    useEffect(() => {
      if(fechaInicioFacturacion && fechaFinFacturacion){
        setFecha({
          fechaInicioFacturacion,
          fechaFinFacturacion
        })
      }


    }, [fechaInicioFacturacion,fechaFinFacturacion])



    return(
        <Modal
        className="modal-dialog-centered"
        isOpen={defaultModal}
        toggle={() => setdefaultModal(false)}
      >
        <div className="modal-header">
          {/* <p>Mes : {fecha?fecha:"No selecciono un mes"}</p> */}
          <h6 className="modal-title" id="modal-title-default">
            Resumen de Caes
          </h6>


        </div>
        <div className="modal-body">
         
          
          <Table className="align-items-center table-flush" hover responsive>
            <thead className="thead-light">
              <tr>
                <th>Importe</th>
                <th>Tipo</th>
                <th>Cantidad</th>
              </tr>
            </thead>
            <tbody>
                  {
                      resume?
                      
                      resume.map((rs, index) =>{

                          return(
                          <tr key={index}>
                              <td className="table-user">
                              <b>$ {rs.importe}</b>
                              </td>
                              <td className="table-user">
                              <b>{rs.tipo}</b>
                              </td>
                              <td className="table-user">
                              <b>{rs.cantidad}</b>
                              </td>
                          </tr>
                          )
                     
                      })
                     
                      :null


                  }
                 

                

             
              
            </tbody>
          </Table>
          
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setdefaultModal(false)}
          >
            Cerrar
          </Button>
        </div>
      </Modal>
    )
}