import LoadingComponent from 'pages/components/Loading';
import Paginacion from 'pages/components/Paginacion';
import React, { useState } from 'react'
import { Table } from 'reactstrap';

const TableEgresos = props => {
    const {Egresos, getEgresos, traerEgresos, setEgresos} = props;
    const [alertSweet, setAlert] = useState(null);
    const [Visible, setVisible] = useState(false);

    const obtenerFormatoFecha = (fechaIngreso)=>{
        let fecha = new Date(fechaIngreso);
        let dia = fecha.getDate();
        let mes = fecha.getMonth();
        let ano = fecha.getFullYear();
        let fechaFormateada = `${dia}/${mes+1}/${ano}`;
        return fechaFormateada;
        }
  return (
    <>
    {alertSweet}
    <LoadingComponent Visible={Visible}/>
    <Table className="align-items-center" responsive>
    <thead className="thead-light text-center">
            <tr >
              <th scope="col">ID</th>
              <th scope="col">IMPORTE</th>
              <th scope="col">CREADO</th>
              <th scope="col">TIPO DE EGRESO</th>
              <th scope="col">CIERRE DE CAJA</th>
              <th scope="col">USUARIO</th>
              <th scope="col">COMENTARIO</th>
            </tr>
    </thead>
    <tbody>
    {Egresos?
    Egresos.map((eg, index)=>{
        return(
                  <tr key={index}>
                    <th scope="row">
                    <div className="text-center">
                            {eg.id}
                    </div>
                    </th>
                    <td>
                    <div className="text-center">
                           $ {eg.importe}
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                           {obtenerFormatoFecha(eg.creado)}
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                        {eg.tipoEgresoId}
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                        {eg.cierrecajasId}
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                        {eg.usuarioId}
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                        {eg.comentario}
                    </div>
                    </td>
                  </tr>
                    )
                })
            :
            <>--</>
            }
    </tbody>
    </Table>
    <Paginacion getObjetos={getEgresos} 
    setObjetos={setEgresos} 
    traerObjetos={traerEgresos}
    />
    
    </>
  )
}

export default TableEgresos