
import { successAlert, warningAlert } from 'pages/components/SweetAlert';
import { useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, InputGroup, Modal } from 'reactstrap';
import { postCaes } from 'services/CaesServices';

const ModalAddCaes = (props) => {
    const { ModalAgregarCae, setModalAgregarCae } = props;
    const [Alert, setAlert] = useState(null)
    const [Cae, setCae] = useState({
        tipo: null,
        importe: null,
        fecha: null,
        fechaFacturacion: null,
        nroFactura: null,
        cae: null
    })

    const handleChange = (campo, nuevoValor) => {
        setCae(prevState => ({
            ...prevState,
            [campo]: campo === 'importe' ? parseInt(nuevoValor) : nuevoValor
        }));
    };
    const validar = () => {
        for (const campo in Cae) {
            if (Cae[campo] === null || Cae[campo] === '') {
                return false;
            }
        }
        return true;
    };

    const addCae = async () => {

        if (validar() === false) {
            warningAlert(setAlert, "Complete todos los campos")
        } else {
            let add = await postCaes(Cae)
            if (add) {
                successAlert(setAlert, "Cae agregada correctamente!")
                recetearCae()
            } else {
                warningAlert(setAlert, "Error al agregar la Cae")
            }
        }
    }




    const recetearCae = () => {
        setCae({
            tipo: null,
            importe: null,
            fecha: null,
            fechaFacturacion: null,
            nroFactura: null,
            cae: null

        })
        setModalAgregarCae(false)
    }




    return (
        <>
            {Alert}
            <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={ModalAgregarCae}
                toggle={() => (setModalAgregarCae(false), recetearCae())}
            >
                <div className="modal-body p-0">
                    <Card className=" shadow border-0">
                        <CardHeader className="bg-transparent pb-5">

                            <div className="text-center">

                                <b>Agregar Cae</b>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => (setModalAgregarCae(false), recetearCae())}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Form role="form">
                                <FormGroup>Tipo de Factura:
                                    <InputGroup className="input-group-alternative mt-2">
                                        <Input placeholder="Tipo" type="select" value={Cae.tipo} onChange={(e) => handleChange("tipo", e.target.value)}>
                                            <option value={null}>Seleccione el tipo de factura</option>
                                            <option value={"A"}>A</option>
                                            <option value={"B"}>B</option>
                                        </Input>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>Importe:
                                    <InputGroup className="input-group-alternative mt-2">
                                        <Input placeholder="Importe" type="number" min={0} value={Cae.importe} onChange={(e) => handleChange("importe", e.target.value)} />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>Fecha:
                                    <InputGroup className="input-group-alternative mt-2">
                                        <Input type="date" value={Cae.fecha} onChange={(e) => handleChange("fecha", e.target.value)} />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>Fecha de Facuración:
                                    <InputGroup className="input-group-alternative mt-2">
                                        <Input type="date" value={Cae.fechaFacturacion} onChange={(e) => handleChange("fechaFacturacion", e.target.value)} />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>Número de Factura:
                                    <InputGroup className="input-group-alternative mt-2">
                                        <Input placeholder="Numero de Factura" type="text" value={Cae.nroFactura} onChange={(e) => handleChange("nroFactura", e.target.value)} />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>Cae:
                                    <InputGroup className="input-group-alternative mt-2">
                                        <Input placeholder="Cae" type="text" value={Cae.cae} onChange={(e) => handleChange("cae", e.target.value)} />
                                    </InputGroup>
                                </FormGroup>

                            </Form>
                        </CardBody>
                        <CardFooter>
                            <div className="text-center">
                                <Button
                                    color="primary"
                                    type="button"
                                    onClick={() => addCae()}
                                >
                                    Agregar
                                </Button>
                                <Button
                                    color="secondary"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => (setModalAgregarCae(false), recetearCae())}
                                >
                                    Cancelar
                                </Button>
                            </div>
                        </CardFooter>

                    </Card>
                </div>
            </Modal>
        </>
    )
}


export default ModalAddCaes
