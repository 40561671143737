import { urlGlobal2 } from './GlobalService.js';

export const getDocumentosClientes = async (clienteId, params = null) => {
  let url = new URL(`${urlGlobal2}/documentosCliente/${clienteId}`);
  if (params != null) {
    url.search = new URLSearchParams(params).toString();
  }
  try {
    let token = localStorage.getItem('token');
    let headers = { 'Content-Type': 'application/json', token };
    let method = 'GET';
    let resp = await fetch(url, { method, headers });
    let data = await resp.json();
    return data;
  } catch (error) {
    alert(error.message);
    return null;
  }
};

export async function postDocumentoCliente(documento) {
  let url = `${urlGlobal2}/documentosCliente`;
  try {
    let token = localStorage.getItem('token');
    let headers = { 'Content-Type': 'application/json', token };
    let method = 'POST';
    let body = JSON.stringify(documento);
    const resp = await fetch(url, { headers, body, method });
    const data = await resp.json();
    return data;
  } catch (error) {
    return null;
  }
}

export async function putDocumentoCliente(documento) {
  let url = `${urlGlobal2}/documentosCliente/${documento.id}`;
  try {
    let token = localStorage.getItem('token');
    let headers = { 'Content-Type': 'application/json', token };
    let method = 'PUT';
    let body = JSON.stringify(documento);
    const resp = await fetch(url, { headers, body, method });
    const data = await resp.json();
    return data;
  } catch (error) {
    return null;
  }
}

export async function deleteDocumentoCliente(id) {
  let url = `${urlGlobal2}/documentosCliente/${id}`;
  try {
    let token = localStorage.getItem('token');
    let headers = { 'Content-Type': 'application/json', token };
    let method = 'DELETE';
    const resp = await fetch(url, { headers, method });
    const data = await resp.json();
    return data;
  } catch (error) {
    return null;
  }
}
