import Buscador from 'pages/components/Buscador'
import SelectorEmpleados from 'pages/components/SelectorEmpleados'
import { successAlert } from 'pages/components/SweetAlert'
import { warningAlert } from 'pages/components/SweetAlert'
import React from 'react'
import { Badge, Button, CardBody, CardFooter, Form, FormGroup, Input, Table } from 'reactstrap'
import { getClientes } from 'services/ClientesServices'
import { postRegistroRetiro } from 'services/RegistrarService'
import { getTiposMaterialesClientes } from 'services/TiposMaterialesService'
import BuscadorMaterialCliente from '../BuscadorMaterialesClientes'

const RetirarACliente = ({Error, ClienteSeleccionado, setClienteSeleccionado, Cantidad, setCantidad, setError, Empleado, setEmpleado, setAlert, Cliente, setCliente, setMaterSeleccionado, Materiales, setMateriales, MaterialSeleccionado, setMaterialSeleccionado, setModalRegistrarRetiro, traerMateriales, reset, Clientes, setClientes, MaterlSeleccionado}) => {


      const cambiarImporte = (e)=> {
        setCantidad(e.target.value)
    }
    const establecerCliente = ()=> {
        setClienteSeleccionado(true)
        traerMaterialesCliente()
    }
    const establecerMaterial = ()=> {
        setMaterSeleccionado(true)
    }

    const validarEntregar = (ev)=>{
        if(!ev.tipoMaterialId){
          return false;
        }
        if(!ev.cantidad){
          return false;
        }
        if(!ev.empleadoId){
            return false;
        }
        if(!ev.clienteId){
        return false;
        }
        return true;
      }

    const validarMinimo = () => {
    if (Cantidad <=0) {
        setError({message : "La Cantidad a registrar debe ser mayor a 0!"})
        return false
    }
    return true
    }
    const Agregar = async() =>{
        let stock = {
            tipoMaterialId : parseInt(MaterialSeleccionado.idTipoMaterial),
            cantidad : parseInt(Cantidad),
            clienteId : parseInt(Cliente),
            empleadoId : parseInt(Empleado)
        }
        if (validarEntregar(stock)===true && validarMinimo(Cantidad) === true) {
            let agg = await postRegistroRetiro(stock)
            if (agg) {
                successAlert(setAlert, "Retiro registrado correctamente!")
                setCantidad("")
                setCliente("")
                setEmpleado("")
                setClienteSeleccionado(false)
                setMaterSeleccionado(false)
                setModalRegistrarRetiro(false)
                traerMateriales()
            } else {
                warningAlert(setAlert, "Error al almacenar el retiro")
            }
        } else {
            if (Error.message) {
                warningAlert(setAlert, `${Error.message}`)
            } else {
                warningAlert(setAlert, "Complete todos los campos!")
    
            }
        }
    }

    const traerMaterialesCliente = async () => {
        let mater = await getTiposMaterialesClientes(null, Cliente)
        if (mater) {
            setMateriales(mater.tmc)
        }
    }
    const recetClientes = async() => {
        setClientes("");
    }
  return (
    <>
                {!ClienteSeleccionado?
            <>
            <CardBody>
           <div className=" mt-5 mb-3">
           <Form role="form">
           <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Busque y Seleccione cliente:
            </label>
           <Buscador getObjetos={getClientes} setObjetos={setClientes} traerObjetos={recetClientes}/>
                   {Clientes?
                   <Table className='text-center'>
                       <thead>
                           <tr>
                               <th>ID</th>
                               <th>NOMBRE</th>
                               <th>DNI</th>
                               <th>SELECCIONAR</th>
                           </tr>
                       </thead>
                       <tbody>
                           {Clientes?
                           Clientes.map((cl, index)=>{
                               return(
                                   <tr key={index}>
                                       <th>{cl.id}</th>
                                       <td>{cl.firstName} {cl.lastName}</td>
                                       <td>{cl.userIdent}</td>
                                       <td>
                                            <Input
                                                onClick={()=>setCliente(cl.id)}
                                                className="custom-control-input"
                                                id={`customRadio${index}`}
                                                name="customRadio"
                                                type="radio"
                                            />
                                            <label className="custom-control-label" htmlFor={`customRadio${index}`}>
                                            </label>
                       </td>
                                   </tr>
                               )
                           })
                       :
                       <>
                       
                       </>}
                       </tbody>
                   </Table>
                   :
                   <Badge
                   className='mt-3'
                   color="info"
                   href="#pablo"
                   onClick={e => e.preventDefault()}
                   >
                   Busque para ver los clientes
                   </Badge>}
           </Form>                     
           </div>                    
           </CardBody>
           <CardFooter>
               <div className="text-center">
               <Button
                   className=""
                   color="primary"
                   type="button"
                   onClick={()=>establecerCliente()}
                   
                   >
                   Confirmar
               </Button>
           
               <Button
                   color="secondary"
                   data-dismiss="modal"
                   type="button"
                   onClick={() => reset()}
               >
                   Cerrar
               </Button>                    
               </div>
               </CardFooter>  
           </>
            :
            <>
            {!MaterlSeleccionado?
            <>
            <CardBody>
            <div className=" mt-5 mb-3">
            <Form role="form">
            <FormGroup>
               <BuscadorMaterialCliente setMateriales={setMateriales} ClienteId={parseInt(Cliente)} traerClientes={traerMateriales}/>
            </FormGroup>
            <FormGroup>
               <Table responsive className='text-center'>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Codigo</th>
                        <th>Cantidad</th>
                        <th>Seleccionar</th>
                    </tr>
                </thead>
                <tbody>
                {Materiales.length?
                    Materiales.map((m, index)=>{
                        return(
                    <tr key={index}>
                        <th>{m.tiposMateriale?.nombre}</th>
                        <td>{m.tiposMateriale?.codigoProducto}</td>
                        <td>{m.cantidad}</td>
                        <td>
                            <Input
                                onClick={()=>setMaterialSeleccionado(m)}
                                className="custom-control-input"
                                id={`customRadio${index}`}
                                name="customRadio"
                                type="radio"
                            />
                            <label className="custom-control-label" htmlFor={`customRadio${index}`}>
                            </label>
                        </td>
                    </tr>

                        )
                    })
                :
                <>
                <tr>
                    <td>

                <h5>El Cliente no tiene ningun Material Asignado!</h5>
                    </td>
                </tr>
                </>}
                </tbody>
               </Table>
            </FormGroup>
            </Form>                     
            </div>                    
            </CardBody>
            <CardFooter>
                <div className="text-center">
                <Button
                    className=""
                    color="primary"
                    type="button"
                    onClick={()=>establecerMaterial()}
                    
                    >
                    Confirmar
                </Button>
            
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => reset()}
                >
                    Cerrar
                </Button>                    
                </div>
                </CardFooter>
                 </>
            :
            <>
             <CardBody>
            <div className=" mt-5 mb-3">
            <Form role="form">
                <label>Empleado:</label>
                <SelectorEmpleados valor={Empleado} cambiarValor={setEmpleado}/> 
            <FormGroup>
                <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Cantidad:
                </label>
                <Input
                    type="number"
                    onChange={cambiarImporte}
                    />
            </FormGroup>
            </Form>                     
            </div>                    
            </CardBody>
            <CardFooter>
                <div className="text-center">
                <Button
                    className=""
                    color="primary"
                    type="button"
                    onClick={()=>Agregar()}
                    
                    >
                    Registrar Retiro
                </Button>
            
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => reset()}
                >
                    Cerrar
                </Button>                    
                </div>
                </CardFooter>  
            </>}
              
            </>
            }
    </>
  )
}

export default RetirarACliente