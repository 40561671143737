import SelectorEmpleados from 'pages/components/SelectorEmpleados';
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { getTiposMaterialesEnEmpleados } from 'services/TiposMaterialesService';
import TableStockEmpleado from './components/TableStockEmpleado'

const StockEmpleado = props => {
  const idMaterial = props.match.params.idMaterial;
  const location = useLocation();
  const history = useHistory();
  const [Empleado, setEmpleado] = useState("")
  const [Materiales, setMateriales] = useState([])
  const irAtras = () => {
    let AlmacenId = location.state.idAlmacen
    let Almacenes = location.state.Almacenes
    if (Almacenes === true) {
      history.replace(`/admin/almacenes`)
      
    } else {
      history.replace(`/admin/stock/${AlmacenId}`)
    }
  }
  
  const traerMateriales = async() => {
    let materiales = await getTiposMaterialesEnEmpleados({ idTipoMaterial: idMaterial, idEmpleado: Empleado});
    if (materiales) {
        setMateriales(materiales.tme)
    }
  }

  useEffect(() => {
    traerMateriales()
  }, [])
  return (
    <>
    <Card>
        <CardHeader>
        <Row>
          <Col>
          <Row>
            <Button className='btn btn-md btn-default' onClick={()=>irAtras()}>Atras</Button>
            <h1>Cantidad por Empleado</h1>
          </Row>
          </Col>
          <Col>
          <SelectorEmpleados cambiarValor={setEmpleado} valor={Empleado}/>
          </Col>
          <Col>
          <Button className='btn btn-outline-info btn-md' onClick={()=>traerMateriales()}>Filtrar</Button>
          </Col>
          <Col>
          </Col>

        </Row>
        </CardHeader>
        <CardBody>
          <TableStockEmpleado Materiales={Materiales}/>
        </CardBody>
    </Card>
    </>
  )
}


export default StockEmpleado