import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react';
import { Button, Card, CardHeader, Col, Input, Modal, Row } from 'reactstrap';
import { putEditarCliente } from 'services/ClientesServices';

const ModalEditarClt = (props) => {

    const {idClient, setAlert, ModalEditarCliente, setModalEditarCliente}=props;
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [dni, setDNI] = useState('');
    const [telefono, setTelefono] = useState('');
    const [mail, setMail] = useState('');


    const cambiarNombre = event => {
        setNombre(event.target.value);
      };
      const cambiarApellido = event => {
        setApellido(event.target.value);
      };
      const cambiarDNI = event => {
        setDNI(event.target.value);
      };
      const cambiarTelefono = event => {
        setTelefono(event.target.value);
      };
      const cambiarMail = event => {
        setMail(event.target.value);
      };

    const EditarCliente = async() => {
        let cliente = {
            id: idClient,
            firstName: nombre,
            lastName: apellido,
            userIdent: dni,
            phone: telefono,
            email: mail
        };
        let clt = await putEditarCliente(cliente)
        if (clt != null && clt != '') {
          successAlert(setAlert, "Cliente Editado!")
        }else{
          warningAlert(setAlert, "No se pudo editar Cliente!")
        }
      }
  return(
      <>
      <Modal className="modal-dialog-centered"
          size="sm"
          isOpen={ModalEditarCliente}
          toggle={() => setModalEditarCliente(false)}>
      <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
        
        <div className="align-items-center"><b>Editar Cliente</b>
          <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalEditarCliente(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
        </div>
        
            
            
                <Row className="mt-4">
                    <Col lg="12" md="12" sm="12">
                        <span className="h3 font-weight-bold mb-0">Nombre:</span>
                    </Col>
                </Row>    
                <Row>
                    <Col lg="12" md="12" sm="12">
                        <Input onChange={cambiarNombre} value={nombre} type="text"/>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col lg="12" md="12" sm="12">
                        <span className="h3 font-weight-bold mb-0">Apellido:</span>
                    </Col>
                </Row>    
                <Row>
                    <Col lg="12" md="12" sm="12">
                        <Input onChange={cambiarApellido} value={apellido} type="text"/>
                    </Col>
                </Row>  
                <Row className="mt-4">
                    <Col lg="12" md="12" sm="12">
                        <span className="h3 font-weight-bold mb-0">DNI: (solamente números)</span>
                    </Col>
                </Row>    
                <Row>
                    <Col lg="12" md="12" sm="12">
                        <Input onChange={cambiarDNI} value={dni} type="number"/>
                    </Col>
                </Row>  
                <Row className="mt-4">
                    <Col lg="12" md="12" sm="12">
                        <span className="h3 font-weight-bold mb-0">Teléfono: (solamente números)</span>
                    </Col>
                </Row>    
                <Row>
                    <Col lg="12" md="12" sm="12">
                        <Input onChange={cambiarTelefono} value={telefono} type="number"/>
                    </Col>
                </Row>  
                <Row className="mt-4">
                    <Col lg="12" md="12" sm="12">
                        <span className="h3 font-weight-bold mb-0">Mail:</span>
                    </Col>
                </Row>    
                <Row>
                    <Col lg="12" md="12" sm="12">
                        <Input onChange={cambiarMail} value={mail} type="text"/>
                    </Col>
                </Row>                
            
                <div className="text-center">
                <Button className="my-4 " color="secondary" type="button" onClick={() => setModalEditarCliente(false)}>
                    Cerrar
                </Button>
                <Button className="my-4 " color="success" type="button" onClick={()=>EditarCliente()}>
                    Guardar
                </Button>
                </div>
            
            </CardHeader>            
          </Card>
        </div>
        </Modal>

      </>
  )
};

export default ModalEditarClt;
