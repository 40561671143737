import React, { useEffect, useState } from "react";
import {FormGroup,Input} from "reactstrap";
import { verificarToken } from "services/AuthService";
import { getEmpleados } from "../../services/EmpleadosService";


function SelectorEmpleados(props) {
    const {valor, cambiarValor, titulo} = props;
    const [empleados, setEmpleados] = useState([]);

    const cambiarEmpleado = (e) => {
      cambiarValor(e.target.value);
  };

    useEffect(() => {
      traerEmpleado();
    }, [])
    
    const traerEmpleado = async() => {
        let tk = null; 
        try {
          tk = localStorage.getItem('token');
        } catch (error) {
          return
        }
        let tkValido = await verificarToken(tk);
        if(!tkValido){
            return
        }
        let empls = await getEmpleados(tk);
        if (empls !== null && empls !== '') {
          setEmpleados(empls);
        }
      
    }

    return (
      <>
                  <FormGroup>
                    {titulo?
                    <label htmlFor="exampleFormControlSelect1">Empleados:</label>
                    :
                    null
                    }
                    {empleados?
                    <Input id="exampleFormControlSelect1" type="select"
                      value={valor}
                      onChange={cambiarEmpleado}
                    >
                      <option value={""}>Opciones...</option>
                      {empleados.length !== 0 ?
                      
                      empleados.map((em, index) => {
                        return (
                            <option key={index} value={em.id}>{em.nombre} {em.apellido} ({em.dni})</option>
                          )

                        })
                      :
                      <option value={""}>Cargando..</option>
                      }
                    </Input>
                    
                    :<></>}
                  </FormGroup>
      </>
    );
  }
  
  export default SelectorEmpleados;