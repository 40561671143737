import React from 'react';
import { useHistory } from 'react-router';
import { Button, Table } from 'reactstrap';
import EtiquetaRegistrado from './EtiquetaRegistrado';

const TableClts = (props) => {

    const {Clientes}=props;
    const history = useHistory ();


    const irPerfil = (id)=>{
        history.replace(`/admin/perfilClientes/${id}`)
    }
  
    const obtenerFormatoFecha = (fechaIngreso)=>{
    let fecha = new Date(fechaIngreso);
    let dia = fecha.getDate();
    let mes = fecha.getMonth();
    let ano = fecha.getFullYear();
    let fechaFormateada = `${dia}/${mes+1}/${ano}`;
    return fechaFormateada;
    }
  return(
      <>
      <Table className="align-items-center table-hover" responsive>
          <thead className="thead-light text-center">
            <tr>
              <th scope="col">ID</th>
              <th scope="col">DNI</th>
              <th scope="col">NOMBRE</th>
              <th scope="col">APELLIDO</th>
              <th scope="col">DIRECCION</th>
              <th scope="col">CREADO</th>
              <th scope="col">REGISTRADO</th>
              <th scope="col"></th>
              <th scope="col" />
              
            </tr>
          </thead>
          <tbody>
          {Clientes?                      
            Clientes.map((cl, index) => {
              return (
                
                  <tr key={index} className="text-center" >
                    
                    <th scope="row">
                    <div className=" text-center"  onClick={()=>irPerfil(cl.id)} >
                          <span className="mb-0 text-sm">
                            {cl.id}
                          </span>
                      </div>
                    </th>
                    <td>
                    <div className=" text-center"  onClick={()=>irPerfil(cl.id)}>
                          <span className="mb-0 text-sm">
                            {cl.userIdent}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className=" text-center"  onClick={()=>irPerfil(cl.id)}>
                          <span className="mb-0 text-sm">
                            {cl.firstName}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className=" text-center"  onClick={()=>irPerfil(cl.id)}>
                          <span className="mb-0 text-sm">
                            {cl.lastName}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className=" text-center" style={{maxWidth:"20rem", wordWrap:"normal"}} onClick={()=>irPerfil(cl.id)}>
                    <p style={{minWidth:"18rem",maxWidth:"19rem", whiteSpace:"initial", overflow:"hidden"}}>
                            {cl.servicios?
                            cl.servicios[0]?.fullAddress
                            :
                            "--"
                            }  
                    </p>
                    </div>
                    </td>
                    <td>
                    <div className=" text-center"  onClick={()=>irPerfil(cl.id)}>
                    
                          <span className="mb-0 text-sm">
                            {obtenerFormatoFecha(cl.creado)}
                          </span>
                    
                    </div>
                    </td>
                    <td>
                      <div className=" text-center">
                        
                          
                          <EtiquetaRegistrado Registrado={cl.generoUsuario} clientId={cl.id}/>
                          
                        
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div>
                          <Button className="btn-icon btn-3 btn-md btn-outline-success "  type="button" onClick={()=>irPerfil(cl.id)}>
                              <span className="btn-inner--icon">Ver    
                              <i className="far fa-eye"></i>
                              </span>
                              
                          </Button>
                        </div>
                      </div>
                    </td>
                  </tr>
                 
                )

              })
            :
            <p>Cargando..</p>
            }
            </tbody>
        </Table>
      </>
  )
};



export default TableClts;
