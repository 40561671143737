import { urlGlobal2 } from './GlobalService.js';

export const getMetodosPagos = async(params=null ) =>{
    let url = new URL(`${urlGlobal2}/metodosPagos`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}




export const getGruposMetodosPagos = async() =>{

    let url = new URL(`${urlGlobal2}/metodosPagos/grupos`)
    
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}
