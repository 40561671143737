import SubirFotoComponent from 'pages/components/SubirFoto';
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react';
import { Button, Card, CardFooter, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap';
import { putEditarTramite } from 'services/TramitesService';

const ModalActVencimiento = props => {

    const {ModalActualizarVencimiento, setModalActualizarVencimiento, traerTramites, TramiteSelec}=props;
    const [Vencimiento, setVencimiento] = useState("");
    const [Inicio, setInicio] = useState("");
    const [Alert, setAlert] = useState(null);
    const [Foto, setFoto] = useState(null);  

    const cambiarVencimiento = event => {
        setVencimiento (event.target.value);
      };
    const cambiarInicio = event => {
      setInicio (event.target.value);
    };
   
    const actualizarTramite= async()=>{
      let tramiteAux = {}
      Object.assign(tramiteAux, TramiteSelec)
      tramiteAux.fechaInicio = Inicio;
      tramiteAux.fechaVencimiento = Vencimiento;
      tramiteAux.archivo = Foto;
      let valido = await putEditarTramite(tramiteAux);
      if(valido){
        successAlert(setAlert,'Tramite Actualizado');
        setVencimiento("")
        setInicio("")
        setModalActualizarVencimiento(false);
        traerTramites();
      }else{
        warningAlert(setAlert,'No se pudo Actualizar el Tramite')
    }
    
    }
    
  return (
    <>
    {Alert}
    <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={ModalActualizarVencimiento}
            toggle={() => setModalActualizarVencimiento(false)}
            >
            <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                <div className="text-center"><b>Actualizar Tramite</b>
                <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setModalActualizarVencimiento(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
                                </div>
                                <div className="text-muted mt-5 mb-3">
                                <Form role="form">
                                <FormGroup>
                                    <label
                                      className="mb-3"
                                      htmlFor="example-date-input"
                                      >
                                      Nuevo Inicio:
                                    </label>
                                    <Input
                                      defaultValue={new Date().getFullYear() + "-11-23"}
                                      id="example-date-input"
                                      type="date"
                                      value={Inicio} onChange={cambiarInicio}
                                    />
                                  </FormGroup>
                                
                                  <FormGroup>
                                    <label
                                      className="mb-3"
                                      htmlFor="example-date-input"
                                      >
                                      Nuevo Vencimiento:
                                    </label>
                                    <Input
                                      defaultValue={new Date().getFullYear() + "-11-23"}
                                      id="example-date-input"
                                      type="date"
                                      value={Vencimiento} onChange={cambiarVencimiento}
                                    />
                                  </FormGroup>
                                  <SubirFotoComponent setFoto={setFoto}/>
                                </Form>                     
                                </div>                    
                              </CardHeader>  
                              <CardFooter>
                                  <div className="text-center">
                                    <Button
                                      className=""
                                      color="primary"
                                      type="button"
                                      onClick={()=>actualizarTramite()}
                                      
                                    >
                                      Actualizar
                                    </Button>
                                
                                    <Button
                                      color="secondary"
                                      data-dismiss="modal"
                                      type="button"
                                      onClick={() => setModalActualizarVencimiento(false)}
                                    >
                                      Cancelar
                                    </Button>                    
                                  </div>
                                  </CardFooter>                
                            </Card>
                          </div>
                          </Modal>
    
    </>
  )
}

export default ModalActVencimiento