import React, { useEffect, useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Row,
  Col,
  Button,
  Container,
  Navbar
} from "reactstrap";
import LoadingComponent from 'pages/components/Loading';
import { getGruposPermisosConPermisos } from 'services/PermisosService';
import ModalAgregarGP from './components/ModalAgregarGP';
import Permisos from './components/permisos';
import ModalAgregarEditarP from './components/ModalAgregarEditarP';
import Buscador from 'pages/components/Buscador';
import TableGruposPermisos from './components/TableGrupoPermisos';

const GruposPermisos=()=> {
  const [Visible, setVisible] = useState(false);
  const [ModalAddGP, setModalAddGP] = useState(false);
  const [ModalAddP, setModalAddP] = useState(false);
  const [openedCollapses, setOpenedCollapses] = useState([])
  const [GPermisos, setGPermisos] = useState([]);
  const [Ok, setOk] = useState(null);


  const traerGPermisos=async()=>{
      setVisible(true)
      let gPermisos= await getGruposPermisosConPermisos();
      if (gPermisos !=null && gPermisos !="") {
          setGPermisos(gPermisos);
      }
      setVisible(false)
  }


//   useEffect(() => {
//     if(!ModalAddP){setOk(null)}
// })

  return (
      <>
            <LoadingComponent Visible={Visible}/>
            <Card style={{width:"100%"}}>
                <Navbar
                    className="navbar-horizontal navbar-light "
                    expand="lg"
                >
                      <Row style={{width:"100%", margin:"0rem"}}>
                        <Col>
                          <Buscador traerObjetos={traerGPermisos} setObjetos={setGPermisos} getObjetos={getGruposPermisosConPermisos}/>
                        </Col>
                        <Col></Col>
                        <Col>
                          <Row>
                            <Col>
                              <Button className="btn-md btn-outline-success" onClick={()=>setModalAddGP(true)}>
                                  Agregar Grupo Permisos
                              </Button>
                            </Col>
                            <Col>
                              <Button className="btn-md btn-outline-success" onClick={()=>setModalAddP(true)}>
                                  Agregar Permiso
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                </Navbar>
                <TableGruposPermisos traerGPermisos={traerGPermisos} GPermisos={GPermisos}/>
                <ModalAgregarGP setModalAddGP={setModalAddGP} ModalAddGP={ModalAddGP} traerGP={traerGPermisos}/>
                {ModalAddP?
                <ModalAgregarEditarP ModalAddP={ModalAddP} setModalAddP={setModalAddP} traerGP_C_P={traerGPermisos}/>
                  :
                <></>
                }
            </Card>
      </>
  )
}

export default GruposPermisos
