import { urlGlobal2 } from "./GlobalService";

export const getCategoriasValores = async(params=null) =>{
  let url = new URL(`${urlGlobal2}/categoriasvalores`)
  if (params != null) {
      url.search = new URLSearchParams(params).toString();
  }
  try{
      let token = localStorage.getItem('token');
      let headers = {'Content-Type':'application/json', token}
      let method = 'GET';
      let resp = await fetch(url,{method, headers});
      let data = await resp.json();
      return data;
  }
  catch(error){
      alert(error.message);
      return null;
  }
}
export async function postCategoriaValores(categoria){
  let url = `${urlGlobal2}/categoriasvalores`;
  try {
      let token = localStorage.getItem('token');
      let headers = {'Content-Type':'application/json','token':token}
      let method= 'POST';
      let body = JSON.stringify(categoria)
      const resp = await fetch(url, {headers, body, method});
      const data = await resp.json()
      return data;
  } catch (error) {
      
      return null;
  }
}