import React, { useRef, useState, useEffect } from "react";
import ReactToPrint from 'react-to-print';
import ImprimirFactura from './subComponents/ImprimirFactura' 
import {
  useParams
} from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import LoadingComponent from "pages/components/Loading";
import './styles/FacturaComponentStyles.css';
import { getDatosFactura } from "services/FacturasService";
import { findInUnms } from "services/ClientesServices";
import FacturaComoponent from './subComponents/FacturaComponent'
import moment from "moment";
import {descargarFactura} from "../../../services/FacturasService";
// ..factura..

//by importing 
import  jsPDFInvoiceTemplate ,  {  OutputType ,  jsPDF  }  from  "jspdf-invoice-template" ; 



// ..factura..


function DescargarFactura(props) {
    //const Cae = true
 

    const condcionesFacturacion = {
      A:"Responsable Inscripto",
      B:"Consumidor Final"
    } 

    

    const componentRef = useRef();
    const [tamanio, setTamanio] = useState(12);
    const [tamanioTotales, setTamanioTotales] = useState(12);
    const [countTamanioTotales, setCountTamanioTotales] = useState(12);
    const [Factura, setFactura] = useState(null);
    const [Visible, setVisible] = useState(false);
    const [idFactura, setId] = useState(null);
    const [Cliente, setCliente] = useState(null);
    const [Cae, setCae] = useState(null)
    const [TipoFactura, setTipoFactura] = useState(null)
    const [FechaFacturacion, setFechaFacturacion] = useState(null)
    const [subTotal, setSubTotal] = useState(0);

    const SearchByKey = (attributes,key) => {
      let value = null;
      attributes.forEach(a => {
        if(a.key == key){ value = a.value}
      })

      return value;
    }


    function esFormatoAAAAMMDD(fecha) {
      return moment(fecha, 'YYYY-MM-DD', true).isValid();
    }
    
    function convertirFecha(fecha) {
      if (esFormatoAAAAMMDD(fecha)) {
        return moment(fecha, 'YYYY-MM-DD').format('DD-MM-YYYY');
      } 
      return fecha;
    }

    const establecerFechaFacturación = (fact) => {
      let fecha = SearchByKey(fact.attributes,'fechafacturacion')
      fecha = convertirFecha(fecha)
      setFechaFacturacion(fecha)
    }

    const traerDatosFactura = async() => {
      const facturaId=props.match.params.idFactura;
      setId(facturaId);
      let fact = await getDatosFactura(facturaId);
      if (fact != null && fact != '') {
        setCae(SearchByKey(fact.attributes,'cae'))
        setTipoFactura(SearchByKey(fact.attributes,'tipofactura'))
        establecerFechaFacturación(fact)
        traerCliente(fact.clientId)
        setFactura(fact);
      }
     }
     const traerCliente = async(id) => {
      let datos = await findInUnms(id)
      
      if(datos){
        setCliente(datos);
      }
      }
   

      const cambiarTamanio = (tam, tam2, tam3) => { // cambia tamaño de las cols resposive en la factura al imprimir
        setTamanio(tam);
        setTamanioTotales(tam2);
        setCountTamanioTotales(tam3);
      }
        
    


    useEffect(() => {
      traerDatosFactura();
    },[]);



    const obtDirec = ()=>{
      let direc = ""
      if(Cliente.street1){
        direc += `${Cliente.street1}`
      }if(Cliente.city){
        direc += `${Cliente.city}`
      }
      return direc
    }



    const obtMatrisYSubTotal = () => {
      let matris = []
      let subTotal = 0
      if (Factura.items) {
        Factura.items.map((i, index)=>{
          let indx = index+1
          let label = i.label?i.label:""
          let cantidad = i.quantity?i.quantity:""
          let precio = i.price?i.price:""
          let total = ""
          if(i.total){
            total = i.total
            subTotal = subTotal + i.total
          }
          let lista = [indx, `${label}`, cantidad, precio, total]
          matris.push(lista)
        })
      }
      if(matris.length == 0){
        matris.push([])
      }
      setSubTotal(subTotal);
      let dev = {
        matris,
        subTotal
      }
      return dev
    }


    const guardarPdf = () => {
      descargarFactura(Factura.id);
    }
  
    return (
      <>
      {
        Factura && Cliente? 
        <>
          <Container fluid>
            <Row className="mt-5">
              <Col></Col>
              <Col>
                <Card className="text-center">
                      <CardBody>
                          <Button block size="lg" color="primary" type="button" onClick={()=>guardarPdf()}>Descargar Pdf</Button>
                      </CardBody>
                  </Card>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col></Col>
              <Col md="8">
                <Card
                  style={{    
                  paddingTop: 50,
                  paddingBottom:50,
                  paddingLeft: 20,
                  paddingRight: 20,  
                }}
                  className="cardGeneral mt-4" /*id utilizado para cambiar css al imprimir*/
                  >
                      <FacturaComoponent
                        Factura={Factura}
                        Cae={Cae}
                        Tipo={TipoFactura}
                        Datos={Cliente}
                        FechaFacturacion={FechaFacturacion}
                      />
                </Card>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </>
        
        :
          <LoadingComponent Visible={Visible}/>
      }
       

      </>
    );
  }

export default DescargarFactura;
