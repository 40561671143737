import React, { useEffect, useState } from "react";
import {
    Card,
  } from "reactstrap";
import { getEmpleados } from "services/EmpleadosService";
import { verificarToken } from "services/AuthService";
import { useHistory } from "react-router";
import { GuardInPages } from "guards/Guard";
import LoadingComponent from "pages/components/Loading";
import TableEmpleados from "./components/TableEmpleados";


function Empleados() {
  const history = useHistory ();
  const [Empleados, SetEmpleados] = useState([]);
  const [alertSweet, setAlert] = useState(null);
  const [Visible, setVisible] = useState(false);

  const traerEmpleados = async() => {
    setVisible(true)
    let tk = null; 
    try {
      tk = localStorage.getItem('token');
    } catch (error) {
      return
    }
    let tkValido = await verificarToken(tk);
    if(!tkValido){
        return
    }
    let empls = await getEmpleados(tk);
    if (empls != null && empls != '') {
      SetEmpleados(empls);
    }
    setVisible(false)
  }
    
  useEffect(() => {
    GuardInPages(history)
    traerEmpleados()
  }, [])
  
      return(

      <>
      <LoadingComponent Visible={Visible}/>
      {alertSweet}
      <Card className="ml-3 mr-3">
        <TableEmpleados
        Empleados={Empleados}
        traerEmpleados={traerEmpleados}
        setAlert={setAlert}
        />
        </Card>
        
      </>
      )
  }
  
  
  export default Empleados;