import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap';
import { postCliente } from 'services/ClientesServices';
import { postPagoFaltante } from 'services/PagosService';

const ModalAsignarD = (props) => {

    const {ModalAsignarPago, setModalAsignarPago, idPago, Metodo}=props;
    const [alertSweet, setAlert] = useState(null);
    const [Cliente, setCliente] = useState("");

    let metodosPago =['f11a289b-10cf-451e-a060-df5c06cf246e','ed676524-e5bd-410e-ab3c-c84446cce4bc'];

    const cambiarCliente = event => {
        setCliente(event.target.value);
    };

    const AsignarPago = async()=>{

      let methodId = metodosPago[1];
      if(Metodo == 'decidir'){
        methodId = metodosPago[0];
      }
      if(!Cliente){
        warningAlert(setAlert,'Seleccione un Cliente');
        return;
      }
      let solicitud = {
        pagoId:idPago,
        methodId,
        clientId: parseInt(Cliente)
      }
      
      let pago = await postPagoFaltante(solicitud);
    
      if(pago != null){
        setCliente("")
        setModalAsignarPago(false)
        successAlert(setAlert,'Pago Asignado')
      }else{
        warningAlert(setAlert,'No se pudo asignar el pago')
    }
    }

  return(
      <>
      {alertSweet}
      <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={ModalAsignarPago}
                            toggle={() => setModalAsignarPago(false)}
                          >
                          <div className="modal-body p-0">
                            <Card className="bg-secondary shadow border-0">
                              <CardHeader className="bg-transparent pb-5">
                                <div className="text-center"><b>Asignar Pago</b>
                                <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => setModalAsignarPago(false)}
                                >
                                  <span aria-hidden={true}>×</span>
                                </button>
                                </div>
                              </CardHeader> 
                              <CardBody>
                              <div className="text-muted mt-2 mb-3">
                                <Form role="form">
                                <FormGroup className="mb-3" value={Cliente} onChange={cambiarCliente}>ID del Cliente:
                                    <InputGroup className="input-group-alternative mt-3">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                        <i class="fas fa-user"></i>
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input placeholder="ID del Cliente..." type="number" />
                                    </InputGroup>
                                  </FormGroup>
                                  </Form>                     
                                </div>   
                                </CardBody>
                                <CardFooter>
                                <div className="text-center">
                                    <Button
                                      className="my-4"
                                      color="primary"
                                      type="button"
                                      onClick={()=>AsignarPago()}
                                    >
                                      Asignar Pago
                                    </Button>
                                
                                    <Button
                                      color="secondary"
                                      data-dismiss="modal"
                                      type="button"
                                      onClick={() => setModalAsignarPago(false)}
                                    >
                                      Cancelar
                                    </Button>                    
                                  </div>
                                </CardFooter>

                                  
                                     
                              
                                            
                                               
                            </Card>
                          </div>
                          </Modal>
      </>
  )
};

export default ModalAsignarD;
