import React, { useEffect, useState } from 'react'
import { Pie } from "react-chartjs-2";
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import { getEsperas } from 'services/EsperaServices';
import { getZonas } from 'services/ZonasServices';
import LoadingComponent from 'pages/components/Loading';
import ModalAggZona from '../components/ModalAggZona';
const Grafico = props => {
  const [Grafico, setGrafico] = useState("");
  const [Visible, setVisible] = useState(true);
  const [ModalAgregarZona, setModalAgregarZona] = useState(false)
  const [Editar, setEditar] = useState(false)

  const obtenerCantidad = async (zona) => {
    let params = { zonaId: zona, estado: 1 };
    let esp = await getEsperas(params);
    let esperas = esp.length
    return esperas
  }

  const traerZonas = async () => {
    setVisible(true)
    let zonas = await getZonas();
    let labels = [];
    let data = [];
    let colors = [];
    if (zonas) {

      for (let i = 0; i < zonas.length; i++) {
        labels[i] = zonas[i].nombre;
        data[i] = await obtenerCantidad(zonas[i].id);
        colors[i] = '#' + zonas[i].color;

      }
      let grafico = {
        labels,
        data,
        colors
      }
      setGrafico(grafico);
    }
    setVisible(false)
  }

  const Data = {
    datasets: [{
      label: ' Esperas Por Zona',
      data: Grafico.data,
      backgroundColor: Grafico.colors
    }],
    labels: Grafico.labels
  }

  useEffect(() => {
    traerZonas();
  }, [])

  return (
    <>
      <LoadingComponent Visible={Visible} />
      {!Visible ?
        <Card style={{ width: "100%" }} className="mt-3 ml-1 mr-1" fluid="true">
          <CardHeader className='text-center  align-items-center d-flex justify-content-around'>
            <Col></Col>
            <Col>
            <h5 className="h3 mb-0"> Esperas por Zonas</h5>
            </Col>
            <Col>
            <Row className='  justify-content-end' >
            <Button className='btn btn-outline-success' onClick={()=>setModalAgregarZona(true)}><i className='fas fa-plus'></i></Button>
            {/* <Button className='btn btn-outline-info'><i className='fas fa-pen'></i></Button> */}
            </Row>
            </Col>
          </CardHeader>
          <CardBody style={{ height: "60rem" }}>
            <Container fluid>
              <div className="chart">
                <Pie
                  data={Data}
                />
              </div>

            </Container>
          </CardBody>
        </Card>
        :
        <></>
      }
      <ModalAggZona ModalAgregarZona={ModalAgregarZona} setModalAgregarZona={setModalAgregarZona} Editar={Editar}/>
    </>
  )
}

export default Grafico
