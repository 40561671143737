import SelectorZonas from 'pages/components/SelectorZonas';
import { warningAlert } from 'pages/components/SweetAlert';
import { confirmAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react'
import { Button, FormGroup, Input, Modal } from 'reactstrap';
import { putEditarEspera } from 'services/EsperaServices';


 const ModalRechazarEspera = (props) => {
    const {ModalEsperaRechazada, setModalEsperaRechazada, EsperaSelec, filtrarPorZona, valorZonas }=props;

    const [Alert, setAlert] = useState("");
    const [Descripcion, setDescripcion] = useState("");

    const confirmarEliminar= async()=>{
        confirmAlert(setAlert, RechazarEspera, EsperaSelec.id , "Deseas rechazar esta Espera?")
      }

    const RechazarEspera= async(id)=>{
    let espera = {
        id: id,
        estado: 3,
        comentario: Descripcion,
    };
    let ev = await putEditarEspera(espera);
        if (ev) {
            setDescripcion("")
            successAlert(setAlert, "Espera Rechazada!", "");
            filtrarPorZona(valorZonas)
            setModalEsperaRechazada(false);
            
        }else{
            warningAlert(setAlert,'No se pudo eliminar la Espera')
        }
        
    }
    const cambiarDescripcion = event => {
        setDescripcion(event.target.value);
    };

    useEffect(() => {
        }, [])
    

    return(
        <>
        {Alert}
        <Modal
          isOpen={ModalEsperaRechazada}
          toggle={() => setModalEsperaRechazada(false)}
          className="modal-dialog-centered modal-secondary modal-sm"
        >
          <div className="modal-body">
            <form className="new-event--form">
              <FormGroup>
                <label className="form-control-label">Motivo:</label>
                <Input
                  className="form-control-alternative new-event--title mt-3"
                  placeholder="Motivo por el que no se pudo instalar..."
                  type="textarea"
                  onChange={cambiarDescripcion}
                />
              </FormGroup>
              
            </form>
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={()=>confirmarEliminar()}
            >
              Rechazar
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setModalEsperaRechazada(false)}
            >
              Cerrar
            </Button>
          </div>
        </Modal>
        </>
    )
  };
  export default ModalRechazarEspera