import LoadingComponent from 'pages/components/Loading';
import React, { useEffect, useState } from 'react'
import {Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { getZonas } from 'services/ZonasServices'
import TableZonas from './components/TableZonas';

const Zonas = props => {

    const [Zonas, setZonas]= useState([]);
    const [Visible, setVisible] = useState(null)

    const traerZonas= async()=>{
        setVisible(true)
        let zonas= await getZonas();
        if (zonas !== null && zonas !== "") {
            setZonas(zonas)
        }
        setVisible(false)
    }
    useEffect(() => {
        traerZonas()
      }, [])
  return (
      <>
      <LoadingComponent Visible={Visible}/>
      <Card style={{width:"100%"}} className="mt-3 ml-3 mr-3">
          <CardHeader>
            <Row>
                <Col> <h2 className='float-left'>Listado de Zonas</h2></Col>
                <Col>
                    {/* <Button className='btn-default btn-md float-right mr-3'>
                        Sincronizar Ahora
                    </Button> */}
                </Col>
            </Row>
             
          </CardHeader>
          <CardBody className='bg-secondary'>
              <TableZonas Zonas={Zonas}/>
          </CardBody>
      </Card>
      </>
  )
}


export default Zonas
