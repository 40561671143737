// *** equipos *** *** equipos *** *** equipos *** *** equipos ***

import { urlGlobal2 } from './GlobalService';
// GET * GET * GET * GET

export async function getEquipos(params=null){
  try {
    let url = new URL(`${urlGlobal2}/equipos`)
    if (params != null) {
      url.search = new URLSearchParams(params).toString();
    }
    let token = localStorage.getItem('token');
    let method = 'GET';
    let headers = {'Content-Type':'application/json','token':token};
    let equipos = null;
    const resp = await fetch(url, {method,headers});
    const data = await resp.json();
    return data;    
  } catch (error) {
    alert(error)
  }
}

  // POST * POST * POST * POST

  export async function postEquipos(equipo){
    try {
      let url = new URL(`${urlGlobal2}/equipos`)
      let token = localStorage.getItem('token');
      let method = 'POST';
      let body = JSON.stringify(equipo)
      let headers = {'Content-Type':'application/json','token':token};
      const resp = await fetch(url, {method,headers,body});
      const data = await resp.json();
      return data;    
    } catch (error) {
      alert(error)
      return null
    }
  }

  export async function postEquiposMany(equipos){
    try {
      let url = new URL(`${urlGlobal2}/equipos/many`)
      let token = localStorage.getItem('token');
      let method = 'POST';
      let body = JSON.stringify(equipos)
      let headers = {'Content-Type':'application/json','token':token};
      const resp = await fetch(url, {method,headers,body});
      const data = await resp.json();
      return data;    
    } catch (error) {
      return null
    }

  }


export async function postEquiposCliente(token, equipos){
  const resp = await fetch(`${urlGlobal2}/equipos/entregarCliente`, {
      method: 'POST',
      headers: {
      'Content-Type':'application/json',
      'token':token
      },
      body:JSON.stringify(equipos)
  });
  const {data} = await resp.json()
  return data;
}

export async function putEquipoCliente(token, cbEquipoObj){
  let output = null;
  let equipoActualizado = null;
  await fetch(`${urlGlobal2}/cambiosequipos`, {
      method: 'POST',
      headers: {
        'Content-Type':'application/json',
        'token':token
      },
      body:JSON.stringify(cbEquipoObj)
  })
      .then(response => output = response.json())
      .then(data => {
          equipoActualizado = data;
      })
      .catch((error) => alert(error));
  return equipoActualizado;
}


export async function generarFacturaRouter(request){
  let url = `${urlGlobal2}/router/generarFactura`;
  try {
      let token = localStorage.getItem('token');
      
      let headers = {'Content-Type':'application/json','token':token}
      let method= 'POST';
      let body = JSON.stringify(request)
      const resp = await fetch(url, {headers, body, method});
      const data = await resp.json()
      return data;
  } catch (error) {
      
      return null;
  }
}
export async function postAsignarEquipos(asignado){
  try {
    
    let url = new URL(`${urlGlobal2}/equipos/asignarEquiposAtm`)
    let token = localStorage.getItem('token');
    let method = 'POST';
    let body = JSON.stringify(asignado)
    let headers = {'Content-Type':'application/json','token':token};
    const resp = await fetch(url, {method,headers,body});
    const data = await resp.json();
    return data;    
    
  } catch (error) {
    alert(error)
    return null
  }
}

// PUT * PUT * PUT * PUT

export async function putEquipo(equipo){
  let url = `${urlGlobal2}/equipos`;
  try {
      let token = localStorage.getItem('token');
      let headers = {'Content-Type':'application/json','token':token}
      let method= 'PUT';
      let body = JSON.stringify(equipo)
      const resp = await fetch(url, {headers, body, method});
      const data = await resp.json()
      return data;
  } catch (error) {
      
      return null;
  }
}

export async function putEquipoEm(equipo){
  let url = `${urlGlobal2}/equipos/entregar`;
  try {
      let token = localStorage.getItem('token');
      let headers = {'Content-Type':'application/json','token':token}
      let method= 'PUT';
      let body = JSON.stringify(equipo)
      const resp = await fetch(url, {headers, body, method});
      const data = await resp.json()
      return data;
  } catch (error) {
      
      return null;
  }
}

export async function putEstadoEquipos(equipo){
  try {
    let url = new URL(`${urlGlobal2}/equipos/changeState`)
    let token = localStorage.getItem('token');
    let method = 'PUT';
    let body = JSON.stringify(equipo)
    let headers = {'Content-Type':'application/json','token':token};
    const resp = await fetch(url, {method,headers,body});
    const data = await resp.json();
    return data;    
  } catch (error) {
    alert(error)
    return null
  }
}


export async function putEquipoRecibir(macAddress){
  try {
    let url = new URL(`${urlGlobal2}/equipos/recibir`)
    let token = localStorage.getItem('token');
    let method = 'PUT';
    let body = JSON.stringify({macAddress})
    let headers = {'Content-Type':'application/json','token':token};
    const resp = await fetch(url, {method,headers,body});
    const data = await resp.json();
    return data;    
  } catch (error) {
    alert(error)
    return null
  }
}


// DELETE * DELETE * DELETE * DELETE

export async function deleteEquipo(id){
  let url = `${urlGlobal2}/equipos/${id}`;
  try {
      let token = localStorage.getItem('token');
      
      let headers = {'Content-Type':'application/json','token':token}
      let method= 'DELETE';
      const resp = await fetch(url, {headers, method});
      const data = await resp.json()
      return data;
  } catch (error) {
      
      return null;
  }
}