import React, { useRef, useState, useEffect } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import LoadingComponent from "pages/components/Loading";
import '../styles/PagoComponentStyles.css';
import { getDatosFactura } from "services/FacturasService";
import { findInUnms } from "services/ClientesServices";
import moment from "moment";
//by importing 
import  jsPDFInvoiceTemplate ,  {  OutputType ,  jsPDF  }  from  "jspdf-invoice-template" ; 
import PagoComponent from "./PagoComponent";
import jwt_decode from "jwt-decode";
import { getMetodosPagos } from "services/MetodosPagosService";

export default function ImprimirPago(props) { 
  const{PagosCliente, IdPago, idClient, datos}=props;
  const [Pago, setPago] = useState("");
  const [Factu, setFactu] = useState([]);
  const [NombreFactura, setNombreFactura] = useState("");
  const [Metodos, setMetodos] = useState([]);
  const [Metodo, setMetodo] = useState("");
  const [Punto, setPunto] = useState("")
  const pago = true
  const Cliente = true
  const [Visible, setVisible] = useState(false);

  const obtDirec = ()=>{
    let direc = ""
    if(datos.street1){
      direc += `${datos.street1}`
    }if(datos.city){
      direc += `, ${datos.city}`
    }
    return direc
  }

  const obtenerFormatoFecha = (date)=>{
    let meses = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
    let fechaFormateada="-" 
    
    if(date){
      let fecha = new Date(date)
      let mes = meses[fecha.getMonth()]; 
      fechaFormateada = `${fecha.getDate()} de ${mes} de ${fecha.getFullYear()}`;
    }
    return fechaFormateada;
}
const BuscarAmount=(paymentCovers) => {
  let dev = "-"
  paymentCovers.map((pay)=>{
      if (pay.paymentId == Pago.id){
          dev = pay.invoiceId
      }
     
    });

return buscarAmountFactura(dev);
}
const buscarAmountFactura=(invoice)=>{
  let factura="";
  Factu.map((f) => {
    if(f.id ==  invoice){
      factura = `${f.subtotal}`
    }
  })
  return factura;
}
const traerMetodos=async()=>{
  let met = await getMetodosPagos();
  if (met !=null && met !="") {
  setMetodos(met);
  }
}

const ObtenerMetodo =()=>{
  let meto ="";

  Metodos.map(m=>{
    if (m.id==Pago.methodId) {
      meto = `${m.name}`;
      setMetodo(meto);
      
    }
  })
return meto
}

const PuntoDeVenta=()=>{
let token = localStorage.getItem('token');
let decod = jwt_decode(token);
let punto=decod.usuario.puntoVentaId;

if (punto) {
  setPunto(punto)
  
}
}
  useEffect(() => {
    PuntoDeVenta();
    traerMetodos();
  },[]);


    const guardarPdf = () => {
      var propsObj = {
          outputType: OutputType.Save,
          returnJsPDFDocObject: true,
          fileName:`Recibo: ${Pago.id}`,
          orientationLandscape: false,
          compress: true,
          logo: {
              src: "https://global-proyecto-bucket.s3.us-west-2.amazonaws.com/comprobantes/bfb879e2-e908-4532-be5e-72337210d5e6.png",
              type: 'PNG',
              width: 75.33, 
              height: 26.66,
              margin: {
                  top: 0, 
                  left: 0 
              }
          },
          business: Punto==1?
           {
            name: "Global Net",
            address: "Independencia 936",
            phone: "Zarate,Buenos Aires",
          }:{
            name: "Global Net",
            address: "",
            phone: "Baradero,Buenos Aires",
          },
          contact: {
            name: `${datos.firstName} ${datos.lastName}`,
            address:` ${datos.userIdent}`,
            phone: `${obtDirec()}`,
        },
          invoice: {
            label: "Recibo:",
            num: `${Pago.id}`,
            invDate:  `Creado: ${obtenerFormatoFecha(Pago.createdDate)}`,
            invGenDate: `Metodo de pago: ${ObtenerMetodo()}`,
            headerBorder: true,
            tableBodyBorder: false,
            header: [
              {
                title: "ORDENES ALCANZADAS CON EL PAGO:", 
                style: { 
                  width: 175
                } 
              }, 
              { 
                title: "#",
                style: { 
                  width: 20
                } 
              }, 
            ],
              table: Array.from(Array(1), (item, index)=>([
                  `${NombreFactura}` ,
                  `$`+ `${Pago.amount}`
              ])),
              row1: {
                  col1: 'Total:',
                  col2: `$ ${BuscarAmount(Pago.paymentCovers)}`,
                  style: {
                      fontSize: 16 
                  }
              },
              invTotalLabel: "SubTotal:",
              invTotal: `$ ${Pago.amount}`,
            },
          footer: {},
      };
      let pdfObject = jsPDFInvoiceTemplate(propsObj)}
  
    return (
      <>
      {
        pago && Cliente? 
        <>
          <Container fluid>
            <Row className="mt-5">
              <Col></Col>
              <Col>
                <Card className="text-center">
                      <CardBody>
                          <Button block size="lg" color="primary" type="button" onClick={()=>guardarPdf()}>Descargar Pago</Button>
                      </CardBody>
                  </Card>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col></Col>
              <Col md="8">
                 <Card
                  style={{    
                  paddingTop: 50,
                  paddingBottom:50,
                  paddingLeft: 20,
                  paddingRight: 20,  
                }}
                  className="cardGeneral mt-4"
                  >
                      <PagoComponent
                      PagosCliente={PagosCliente}
                      IdPago={IdPago}
                      idClient={idClient}
                      datos={datos}
                      Pago={Pago}
                      setPago={setPago}
                      setFactu={setFactu}
                      Factu={Factu}
                      setNombreFactura={setNombreFactura}
                      Metodo={Metodo}
                        //Factura={Factura}
                        //Cae={Cae}
                        //Tipo={TipoFactura}
                        //Datos={Cliente}
                        //FechaFacturacion={FechaFacturacion}
                      />
                </Card> 
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </>
        
        :
          <LoadingComponent Visible={Visible}/>
      }
       

      </>
    );
  }

