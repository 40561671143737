import React, { useEffect, useState } from 'react'
import { Input } from 'reactstrap'
import { getAlmacenes } from 'services/AlmacenesService';

const SelectorAlmacenes = props => {
    const {idAlmacen, cambiarIdAlmacen}=props;
    const [Almacenes, setAlmacenes] = useState([])

    const cambiarValor =(e) => {
        cambiarIdAlmacen(e.target.value)
    }
    const traerAlmacenes = async() => {
        let alm = await getAlmacenes();
        if (alm !== null) {
            setAlmacenes(alm.almacenes)
        }
    }
    useEffect(() => {
        traerAlmacenes()
    }, [])
    
  return (
    <>
    <Input type='select' onChange={cambiarValor} value={idAlmacen}>
        <option value={''}>Almacenes</option>
        {Almacenes?
        Almacenes.map((c, index)=>{
            return(
                <option value={c.id} key={index}>{c.nombre}</option>
            )
        })
    :
    <option value={''}>Cargando...</option>}

    </Input>
    </>
  )
}

export default SelectorAlmacenes