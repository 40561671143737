import { urlGlobal, urlGlobal2 } from './GlobalService.js';

/*---------------GET--------------*/

export const getClientes = async(params=null) =>{
    let url = new URL(`${urlGlobal}/clientes`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}

export const getClientesFiscales = async() =>{
    try{
        let token = localStorage.getItem('token');
        let url = new URL(`${urlGlobal2}/clientes/fiscales`)

        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}

export const getClienteFiltrado = async(valor) => {
    //aca puedo filtrar por ej: DNI, nombre, apellido, telefono, mail, etc...
    let token = localStorage.getItem('token');
    let headers = {'Content-Type':'application/json','token':token};
    const nResp = await fetch(`${urlGlobal}/clientes?query_result=${valor}`,{headers,method:'GET'});
    const nData = await nResp.json();
    return nData;
}

export const getClienteEsp = async(clienteId) => {
    let token = localStorage.getItem('token');
    let headers = {'Content-Type':'application/json','token':token};
    const nResp = await fetch(`${urlGlobal}/clientes/findById/${clienteId}`,{headers,method:'GET'});
    const nData = await nResp.json();

    return nData;
}
export const datosServicios = async(clienteId) => {
    let token = localStorage.getItem('token');
    let headers = {'Content-Type':'application/json','token':token};
    const nResp = await fetch(`${urlGlobal}/servicios/cliente/incrm/${clienteId}`,{headers,method:'GET'});
    const nData = await nResp.json();

    return nData;
}
export const getDatos = async() => {
    let token = localStorage.getItem('token');
    let headers = {'Content-Type':'application/json','token':token};
    const nResp = await fetch(`${urlGlobal}/clientes/datos`,{headers,method:'GET'});
    const nData = await nResp.json();

    return nData;
}


export const getFacturas = async() => {
    let token = localStorage.getItem('token');
    let headers = {'Content-Type':'application/json','token':token};
    const nResp = await fetch(`${urlGlobal}/clientes/facturas`,{headers,method:'GET'});
    const nData = await nResp.json();

    return nData;
}


export const getPagos = async() => {
    let token = localStorage.getItem('token');
    let headers = {'Content-Type':'application/json','token':token};
    const nResp = await fetch(`${urlGlobal}/clientes/pagos`,{headers,method:'GET'});
    const nData = await nResp.json();

    return nData;

}

export const getDebt = async(clientId) => {
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token};
        const nResp = await fetch(`${urlGlobal2}/clientes/getDebt/${clientId}`,{headers,method:'GET'});
        const nData = await nResp.json();
        return nData;
    }catch(error){
        
        return null
    }
}

/*---------------POST--------------*/
export async function postCliente(clientes){
    let url = `${urlGlobal}/clientes/addManual`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(clientes)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }
  export async function postPagos(pagos){
    let url = `${urlGlobal}/clientes/pagos`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(pagos)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }
  export async function generateSolicitud(solicitudPago){
    let url = `${urlGlobal2}/solicitudes-pago`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(solicitudPago)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }
  /*---------------PUT--------------*/



export const findInUnms = async(clienteId) => {
    let token = localStorage.getItem('token');
    let headers = {'Content-Type':'application/json','token':token};
    const nResp = await fetch(`${urlGlobal}/clientes/findInUnms/${clienteId}`,{headers,method:'GET'});
    const nData = await nResp.json();
    return nData; 
}



export const updateFiscal = async(cliente) => {
    cliente.id = parseInt(cliente.id);
    let body = JSON.stringify(cliente);
    let token = localStorage.getItem('token');
    let headers = {'Content-Type':'application/json','token':token};
    const resp = await fetch(`${urlGlobal2}/clientes/makeFiscal`,{headers,body,method:'PUT'});
    const nData = await resp.json();
    return nData;
}



//   export const updateCliente = async(cliente) => {
//     cliente.id = parseInt(cliente.id);
//     let body = JSON.stringify(cliente);
//     let token = localStorage.getItem('token');
//     let headers = {'Content-Type':'application/json','token':token};
//     // const resp = await fetch(`${urlGlobal}/clientes/makeFiscal`,{headers,body,method:'PUT'});
//     // const nData = await resp.json();
//     // return nData;
// }
export async function putContraseña(contraseña){
    let url = `${urlGlobal}/clientes/updatePassword`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'PUT';
        let body = JSON.stringify(contraseña)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }

  export async function putEditarCliente(ecl){
    let url = `${urlGlobal}/clientes`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'PUT';
        let body = JSON.stringify(ecl)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }


  export const ReconectarCliente = async(clientId) => {
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        
        let body = JSON.stringify({clientId:parseInt(clientId)})
        
       
        const resp = await fetch(`${urlGlobal2}/clientes/reconectar`, {headers, body,method:'PUT'});
        const data = await resp.json()
        return data;
    
    }
    catch(error){
        
        return {ok:false,mensaje:error.message}
    }
  }

  /*---------------DELETE--------------*/

  export async function deletePago(id){
    let url = `${urlGlobal}/clientes/pagos/${id}`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'DELETE';
        const resp = await fetch(url, {headers, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }