import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react';
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, 
  InputGroupAddon, InputGroupText, Modal } from 'reactstrap';
import { postGrupoPermisos } from 'services/PermisosService';


const ModalAgregarGP = (props) => {

    const {ModalAddGP, setModalAddGP, traerGP}=props;
    const [alertSweet, setAlert] = useState(null);
    const [nombre, setNombre] = useState("");

    const cambiarNombre = event => {
        setNombre(event.target.value);
    };

    const AgregarGP = async()=>{
    
        let gp = {
          nombre
        }
      let result = await postGrupoPermisos(gp)
      if(result != null){
        setNombre("")
        setModalAddGP(false)
        successAlert(setAlert, "Grupo Permisos agregado correctamente!", "")
        traerGP()
    }else{
      warningAlert(setAlert, 'No se pudo agregar Grupo Permisos')
    }
    }
  return(
      <>
      {alertSweet}
      <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={ModalAddGP}
                            toggle={() => setModalAddGP(false)}
                          >
                          <div className="modal-body p-0">
                            <Card className="bg-secondary shadow border-0">
                              <CardHeader className="bg-transparent pb-5">
                                <div className="text-center"><b>Agregar Grupo Permisos</b>
                                <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => setModalAddGP(false)}
                                >
                                  <span aria-hidden={true}>×</span>
                                </button>
                                </div>
                                <div className="text-muted mt-2 mb-3">
                                <Form role="form">
                                  <FormGroup className="mb-3" value={nombre} onChange={cambiarNombre}>Nombre:
                                    <InputGroup className="input-group-alternative mt-2">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                        <i className="fas fa-user"></i>
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input placeholder="Nombre" type="text" />
                                    </InputGroup>
                                  </FormGroup>
                                  <div className="text-center">
                                    <Button
                                      className="my-4"
                                      color="primary"
                                      type="button"
                                      onClick={()=>AgregarGP()}
                                    >
                                      Agregar
                                    </Button>
                                
                                    <Button
                                      color="secondary"
                                      data-dismiss="modal"
                                      type="button"
                                      onClick={() => setModalAddGP(false)}
                                    >
                                      Cerrar
                                    </Button>                    
                                  </div>
                                </Form>                     
                                </div>                    
                              </CardHeader>                  
                            </Card>
                          </div>
                          </Modal>
      </>
  )
};

export default ModalAgregarGP;
