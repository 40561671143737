/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { loginApi } from "services/AuthService";
import { useHistory } from "react-router";
import loginPc from "../../assets/img/loginPc.jpg"
import LoadingComponent from "pages/components/Loading";
import {warningAlert} from "../components/SweetAlert"


function Login() {
  const [focusedUser, setfocusedUser] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [usuario, setUsuario] = useState('');
  const [contrasena, setContrasena] = useState('');
  const [LoadingState, setLoadingState] = useState(false);
  const [alertSweet, setAlert] = useState(null);


  const history = useHistory ();

  const keyDownFunction =(event) => {
    if (event.keyCode === 13) {
      logear();
    }
  }

  const validacion = (user) => {
    let dev = true;
    if(!(user.usuario)){
      dev=false;
    }
    if((!user.password)){
      dev=false;
    }
    return dev;
  }

  const logear = async () => {
    setLoadingState(true)
    let user ={
      usuario: usuario,
      password: contrasena
    }
    if(validacion(user)){
      let estado = await loginApi(user);
      if(!estado){
        setLoadingState(false)
        warningAlert(setAlert, "Atencion!", "Usuario y/o contraseña incorrecto")
      }else{
        setLoadingState(false)
        history.replace('/admin/home');
      } 
    } else {
      setLoadingState(false)
      warningAlert(setAlert, "Atencion!", "Debe ingresar un usuario y una contraseña")
    }
  };

  const cambiarUsuario = event => {
    setUsuario(event.target.value);
  };

  const cambiarContrasena = event => {
    setContrasena(event.target.value);
  };

  return (
    <>
      {alertSweet}
      <LoadingComponent Visible={LoadingState}/>
      <AuthHeader
        title="Bienvenido!"
      />
      <Container className="mt--9 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardHeader className="bg-transparent pb-5" style={{backgroundImage:`url(${loginPc})`, height:'10rem'}}>

              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form" onKeyDown={(e)=>keyDownFunction(e)}>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedUser,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-user-tie"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Usuario"
                        type="text"
                        value={usuario}
                        onChange={cambiarUsuario}
                        onFocus={() => setfocusedUser(true)}
                        onBlur={() => setfocusedUser(true)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Contraseña"
                        type="password"
                        value={contrasena}
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                        onChange={cambiarContrasena}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4" color="info" type="button" onClick={()=>{logear()}}>
                      Iniciar Sesion
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
