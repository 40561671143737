import React from 'react'
import { Badge } from 'reactstrap'


function EtiquetaEstado(props){
    const {Estado} = props
    if(Estado === 1){
        return(
            <Badge className="badge-md" color="danger">No resuelto</Badge>
        )
    }else if(Estado === 2){
        return(
            <Badge className="badge-md" color="success">Resuelto</Badge>
        )
    }else{
        return <></>
    }
}

export default EtiquetaEstado;