import { urlGlobal2 } from './GlobalService.js';



//Available params {from,to,methodId}
export const getPagos = async(params=null) => {
    let url = new URL(`${urlGlobal2}/pagos`)
    try{
        if (params != null) {
            url.search = new URLSearchParams(params).toString();   
        }
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}


export const getPagosCliente = async(params=null, id) =>{
    let url = new URL(`${urlGlobal2}/pagos/${id}`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
        
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}
export const findPaymentInCrm = async(params=null, paymentId) =>{
    let url = new URL(`${urlGlobal2}/pagos/find/${paymentId}`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
        
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}


    export const getPagosPorPuntoVenta = async(puntoVentaId, fechaInicio=null, fechaFin=null) =>{
    let url = new URL(`${urlGlobal2}/pagos/byPuntoVenta/${puntoVentaId}?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`)
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}

export const getPagosPorPuntoVentaApi1 = async(puntoVentaId, fechaInicio=null, fechaFin=null) =>{
    let url = new URL(`${urlGlobal2}/pagos/byPuntoVenta/${puntoVentaId}`)
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}

export async function postPagoFaltante(pago){
    let url = `${urlGlobal2}/pagos/agregarPagoFaltante`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(pago)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }
  export async function postPagosCrm(pagos){
    let url = `${urlGlobal2}/pagos`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(pagos)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }
  