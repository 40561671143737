import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Modal, Row, Table } from 'reactstrap';
import { useHistory } from "react-router";
import { getServicioCrm } from 'services/ServiciosServices';
import { findInUnms } from 'services/ClientesServices';
import EtiquetaEstadoServ from '../../components/EtiquetaEstadoServ';
import { getTiposPlanes } from 'services/ServiciosServices';
import SelectorPlanes from 'pages/components/SelectorPlanes';
import LoadingComponent from 'pages/components/Loading';
import { successAlert } from 'pages/components/SweetAlert';
import { putPlan } from 'services/ServiciosServices';
import ModalRCambios from '../../components/ModalRCambios';
import ModalGenRetiro from '../../components/ModalGenerarRetiro';
import ModalEditServicio from '../../components/ModalEditarServicio';
import {ModalCambiarPlan} from './componentes/ModalCambiarPlan'


export const VistaServicios=(props) => {

    
    const history= useHistory()
    const [idClient, setId] = useState(null);
    const [idServicio, setServId] = useState(null);
    const [Servicio, setServicio] = useState(null); 
    const [Cliente, setCliente] = useState(null);
    const [ModalEditarServicio, setModalEditarServicio] = useState(false);
    const [ModalRealizarCambios, setModalRealizarCambios] = useState(false);
    const [ModalGenerarRetiro, setModalGenerarRetiro]= useState(false);
    const [ModalP, setModalP] = useState(false);
    
    const [alertSweet, setAlert] = useState(null); 
    const [Visible, setVisible] = useState(false);
    
    
    

    const traerServicio = async() => {
        setVisible(true)
        const ServicioId=props.match.params.idServicio;
        setServId(ServicioId);

        let sr = await getServicioCrm(null, ServicioId);
        if (sr != null && sr != '') {
        setServicio(sr);
        }
        setVisible(false)
       }
       
    const traerCliente = async() => {
    let ClienteId=props.match.params.idClient;
    setId(ClienteId);
    let datos = await findInUnms(ClienteId)
    if(datos){
      setCliente(datos);
    }
    }
    const BuscarRadius=(atributos) => {
        let dev = "-"
        atributos.map((at)=>{
            if (at.key == "radiusUsername"){
                dev = at.value
            }
           
    })
    return dev;
    }
    const BuscarMac=(atributos) => {
        let devl = "-"
        atributos.map((at)=>{
            if (at.key == "macequipo"){
                devl = at.value
            }
           
    })
    return devl;
    }
    
    

    const irGeneral = () => {
        history.replace(`/admin/perfilClientes/${idClient}`)
    
    }

    

    
  
  
      useEffect(() => {
        traerServicio();
        traerCliente();
      

      }, [])
      
    
    return (
        <>
        {alertSweet}
        <LoadingComponent Visible={Visible}/>
        {Servicio?
            <>
        <Row>
            <Col lg="5">
            <Card  style={{width:"100%"}} className=" ml-3 mt-3 mr-2">
            <CardBody  >
                
                             {/* <h2>
                                 {Servicio.name}


                             </h2> */}
                             <Row>

                                <Col>
                                    <Button className=" btn-default" onClick={()=>irGeneral()} >
                                        <i class="fas fa-arrow-left"></i>
                                    </Button>
                                </Col>                                                               
                                <Col>
                                    <Row>
                                    <Button className=" btn-outline-default btn-sm" onClick={()=>setModalP(true)}>
                                        Cambiar Tipo de Servicio
                                    </Button>
                                    </Row>
                                    <Row>
                                    
                                    </Row>
                                </Col>
                                <Col className=" ml-1">
                                <Row>
                                {Servicio.status?
                            <EtiquetaEstadoServ Estado={Servicio.status}/>
                            :
                      <p>Sin estado</p>
                          }
                                
                                <Button className="btn-sm btn-default" onClick={()=>setModalEditarServicio(true)}>
                                <i class="fas fa-edit"></i>
                                </Button>
                                </Row>
                                </Col>
                            
                            </Row>                            
            </CardBody>
            <CardBody>
                                <Row>
                                    <Col>
                                    <h3>Plan del Servicio: {Servicio.name}</h3>
                                    </Col>
                                    <Col>
                                    <h3 className="float-right">${Servicio.price}</h3>
                                    </Col>
                                </Row>
                                <Row  className="mt-2 ml-2">
                                    
                                        <Col lg="2">
                                        <Row>
                                        <div className=" text-success  ">
                                        <i class="fas fa-cloud-download-alt">
                                             {Servicio.downloadSpeed} </i>
                                        </div>
                                        </Row>
                                        <Row>
                                            Mbps
                                        </Row>
                                        </Col>
                                        <Col lg="2">
                                            <Row>
                                            <div className=" text-default ">
                                            <i class="fas fa-cloud-upload-alt">  {Servicio.uploadSpeed} </i>
                                            </div>
                                            </Row>
                                            <Row>
                                                Mbps
                                            </Row>

                                        </Col>
                                        <Col lg="8">
                                            <p className="float-right">{Servicio.servicePlanPeriod} Mes</p>
                                        
                                        </Col>

                                    
                                </Row>
                                <Row className="ml-2 ">
                                  <div style={{width:"100%"}}>
                                    <Button className="btn-outline-default ml- 3 mt-3" onClick={()=>setModalRealizarCambios(true)}>
                                       Cambio de Equipo
                                    </Button>
                                    <Button className=" btn-outline-default float-right mt-3 mr-3"  onClick={()=>setModalGenerarRetiro(true)}>
                                        Generar Retiro
                                    </Button>
                                    </div>
                                </Row>
            </CardBody>
        </Card>
        <Card  style={{width:"100%"}} className=" ml-3 mt-1 mr-1">
            <CardBody className="ml-5">
               <Row>
                <h3>
                    Configuraciones de la Facturacion
                </h3>
            
                </Row> 
              
                <Row>
               
                <Col>
          <Row>
            Periodo de<br/>
            Facturacion<br/>
            Siguiente
          </Row>
          <Row className="mt-3">
            Proximo dia<br/>
            de <br/>
            Facturacion</Row>
          <Row className="mt-3">
            Dia del<br/>
            comienzo del<br/>
            Periodo
          </Row>
          </Col>
          <Col>
          <Row>
            -- <br/>        
            --<br/>
            --
          </Row>
          <Row className="mt-3">
            --<br/>
            --<br/>
            --
          </Row>
          <Row className="mt-3">
            --<br/>
            --<br/>
            --
          </Row>
          </Col>
          </Row>
        
                
            </CardBody>
        </Card>
        <Card className="ml-3" style={{width:"100%"}}>
            <CardBody>
                <Row>
                    <Col>
                    <h2>Ubicacion:</h2>
                    </Col>
                    <Col>
                    <h3>Lat: {Servicio.addressGpsLat}</h3>
                    <h3>Lon: {Servicio.addressGpsLon}</h3>
                    </Col>
                </Row>
            </CardBody>
        </Card>

        </Col>
        <Col lg="7">
        <Card style={{width:"100%"}} className="mt-3 mr-3">
            <CardBody>
                                <Row>
                                    <Col>
                                    <h2>Ubicacion</h2>
                                    </Col>
                                    <Col>
                                    <Button className="float-right btn-sm btn-danger">
                                        No Verficada
                                    </Button>
                                    </Col>
                                </Row>
                                <Row style={{height:"12rem"}}>
                                    <h1>
                                        Aca iria el mapa
                                    </h1>
                                </Row>
            </CardBody>
       
        </Card>
        <Card>
            <CardBody>
                <Table >
                    <thead className="thead-light">
                        <h2>Direccion</h2>
                    </thead> 
                    <tbody className="ml-5">
                        <Row className="ml-3 mt-4">
                            <Col>
                            Ciudad
                            </Col>
                            <Col>
                            <h3>{Servicio.city}</h3>
                            </Col>
                        </Row>
                        <Row className="ml-3 mt-4">
                            <Col>
                            Direccion
                            </Col>
                            <Col>
                            <h3>{Servicio.street1}</h3>
                            </Col>
                        </Row>
                        <Row className="ml-3 mt-4">
                            <Col>
                            Link
                            </Col>
                        </Row>
                        <Row className="ml-3 mt-4">
                            <Col>
                            <h3>---</h3>
                            </Col>
                        </Row>
                    </tbody>
                </Table>
            </CardBody>
        </Card>
        <Card>
            <CardBody>
                <Table >
                    <thead><h2>Atributos Personalizados</h2></thead>
                    
                    <tbody className="ml-5">
                        <Row className="ml-3 mt-5">
                            <Col>
                            Username
                            </Col>
                            <Col>
                            <h3>{Servicio.attributes?
                            BuscarRadius(Servicio.attributes)
                            :
                            <p>Cargando..</p>
                            }
                            </h3>
                            </Col>
                        </Row>
                        <Row className="ml-3 mt-2">
                            <Col>
                            Mac Equipo
                            </Col>
                            <Col>
                            <h3>{Servicio.attributes?
                            BuscarMac(Servicio.attributes)
                            :
                            <p>Cargando..</p>
                            }</h3>
                            </Col>
                        </Row>
                    </tbody>
                </Table>
            </CardBody>
        </Card>
        </Col>
        </Row>
        
        
        </>
        :
        <p>Cargando..</p>
        }
        <ModalEditServicio
        ModalEditarServicio={ModalEditarServicio}
        setModalEditarServicio={setModalEditarServicio}
        traerServicio={traerServicio}
        Servicio={Servicio}
        setAlert={setAlert}
        />


<ModalCambiarPlan ModalP={ModalP} 
      setModalP={setModalP} 
      traerServicio={traerServicio}
      Servicio={Servicio}
      idClient={idClient}
      setAlert={setAlert}
      ></ModalCambiarPlan>
                        
      <ModalRCambios 
      ModalRealizarCambios={ModalRealizarCambios} 
      setModalRealizarCambios={setModalRealizarCambios}
      idServicio={idServicio}
      idClient={idClient}
      />
      <ModalGenRetiro
      ModalGenerarRetiro={ModalGenerarRetiro}
      setModalGenerarRetiro={setModalGenerarRetiro}
      setAlert={setAlert}
      idClient={idClient}
      idServicio={idServicio}
      />
    
        </>
    );
    
    
}
export default VistaServicios;
