import React, { useEffect, useState } from 'react'
import SelectorEmpleados from 'pages/components/SelectorEmpleados'
import SelectorGenerico from 'pages/components/SelectorGenerico'
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap'
import { putEstadoEquipos } from 'services/EquiposService'
import { getEstados } from "services/EstadosService";
import { successAlert, warningAlert } from './SweetAlert'


export default function ModalEntregar(props){
    const {ModalEntregarEquipo, setModalEntregarEquipo, EquiposSeleccionados, setEquiposSeleccionados, traerEquipos} = props

    const [Estados, setEstados] = useState([]);
    const [empleado, setEmpleado] = useState("");
    const [IdCliente, setIdCliente] = useState("");
    const [valorEstado, setSelectorEstado] = useState("");
    const [alertSweet, setAlert] = useState(null);

    const cambiarIdCliente = event => {
      setIdCliente(event.target.value);
    };

    const entregarEquipos = async() => {
      if(parseInt(valorEstado) == 2){
        let equiposAEntregar = {
          equiposIds:EquiposSeleccionados,
          estadoId:parseInt(valorEstado),
          empleadoId:parseInt(empleado),
          clienteId: null
        }
        let equiposEntregados = await putEstadoEquipos(equiposAEntregar);
        if(equiposEntregados){
          successAlert(setAlert, "Equipos Entregados ") 
          setModalEntregarEquipo(false)
          setEquiposSeleccionados([])
          if(traerEquipos){
            traerEquipos();
          }
        }
        }
        else{
          warningAlert(setAlert, "No ha selecionado el estado en empleado")
          
        }
    }
    
    
    const asignarCliente = async() => {
      if(parseInt(valorEstado) == 3){
        var equiposAEntregar = {
          equiposIds:EquiposSeleccionados,
          estadoId:parseInt(valorEstado),
          clienteId:IdCliente,
          empleadoId:null
        }
        let equiposEntregados = await putEstadoEquipos(equiposAEntregar);
        if(equiposEntregados){
          successAlert(setAlert, "Equipos Entregados ") 
          setModalEntregarEquipo(false)
          setEquiposSeleccionados([])
          traerEquipos();
        }
    
      }
      else{
        warningAlert(setAlert, "No ha selecionado el estado en Cliente")
      }
    
    }

    const traerEstados = async()=>{
      let estadosObtenidos = await getEstados();
      if (estadosObtenidos != null && estadosObtenidos != '') {
        setEstados(estadosObtenidos)
      }  
    }

    useEffect(() => {
      traerEstados();
    }, [])

    return(
      <>
      {alertSweet}
        <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={ModalEntregarEquipo}
        toggle={() => setModalEntregarEquipo(false)}
      >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-center"><b>Entregar Equipo</b>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setModalEntregarEquipo(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
            </div>
            <div className="text-muted mt-2 mb-3">
            <Form role="form">
              {Estados?              
              <FormGroup>
                <label htmlFor="exampleFormControlSelect1">Estado:</label>
                <SelectorGenerico Valor={valorEstado} setValor={setSelectorEstado} arrayElementos={Estados}/>
              </FormGroup>
              :<></>}                            
              <SelectorEmpleados  valor={empleado} cambiarValor={setEmpleado} titulo={true}/>
              <FormGroup className="mb-3">Id Cliente:
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                    <i className="fas fa-user-tag"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Id cliente..." type="number"  onChange={cambiarIdCliente} value={IdCliente}/>
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={()=>entregarEquipos()}
                >
                  Entregar
                </Button>
                <Button
                  className="my-4"
                  color="secondary"
                  type="button"
                  onClick={()=>asignarCliente()}
                >
                  Asignar a Cliente
                </Button>
            
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => setModalEntregarEquipo(false)}
                >
                  Cerrar
                </Button>                    
              </div>
            </Form>                     
            </div>                    
          </CardHeader>                  
        </Card>
      </div>
      </Modal>
      </>
    )
}