import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import { confirmAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react'
import {  Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap'
import { putContraseña } from 'services/ClientesServices';


function EtiquetaRegistrado(props){
    const {Registrado, clientId} = props
    const [ModalContraseña, setModalContraseña] = useState(false);
    const [alertSweet, setAlert] = useState(null); 
    const [nuevaContraseña, setNuevaContraseña] = useState(null);

    const cambiarTextContrasena = (e) => {
      setNuevaContraseña(e.target.value)
    }

    const ConfirmarCambio = () => {
        confirmAlert(setAlert, cambiarContraseña, null,"Seguro que quiere cambiar la contraseña?")
      }
      


    const cambiarContraseña = async() => {
        let contra = {
          id: clientId,
          password: nuevaContraseña
        };
        let con = await putContraseña(contra)
        if (con !== null && con !== '') {
          successAlert(setAlert, "Contraseña Cambiada!")
        }else{
          warningAlert(setAlert, "No se pudo cambiar de contraseña!")
        }
      }
      const NoRegistrado=()=>{
        warningAlert(setAlert, "Cliente no Registrado!")
      }

    if(Registrado === true){
        return(
        <>
        {alertSweet}
            <Button className="btn-icon btn-3 btn-sm btn-outline-success  "  type="button" onClick={() => setModalContraseña(true)}>
                              <span className="btn-inner--icon">    
                              SI
                              </span>
                              
                          </Button>
                          <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={ModalContraseña}
                            toggle={() => setModalContraseña(false)}
                          >
                          <div className="modal-body p-0">
                            <Card className="bg-secondary shadow border-0">
                              <CardHeader className="bg-transparent pb-5">
                                <div className="text-center"><b>Cambiar Contraseña</b>
                                <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => setModalContraseña(false)}
                                >
                                  <span aria-hidden={true}>×</span>
                                </button>
                                </div>
                                <div className="text-muted mt-2 mb-3">
                                <Form role="form">
                                <FormGroup className="mt-5"  >
                                    Nueva Contraseña:
                                    <InputGroup className="input-group-alternative mt-2">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                        <i className="fas fa-key"></i>
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input onChange={cambiarTextContrasena} value={nuevaContraseña} placeholder="Nueva Contraseña..." type="text" />
                                    </InputGroup>
                                  </FormGroup>
                                  <div className="text-center">
                                    <Button
                                      className="my-4"
                                      color="primary"
                                      type="button"
                                      onClick={()=>ConfirmarCambio()}
                                    >
                                      Cambiar
                                    </Button>
                                
                                    <Button
                                      color="secondary"
                                      data-dismiss="modal"
                                      type="button"
                                      onClick={() => setModalContraseña(false)}
                                    >
                                      Cancelar
                                    </Button>                    
                                  </div>
                                </Form>                     
                                </div>                    
                              </CardHeader>                  
                            </Card>
                          </div>
                          </Modal>
             </>
        )
    }else if(Registrado == null){
        return(
          <>
          {alertSweet}
            <Button className="btn-icon btn-3 btn-sm btn-outline-danger " onClick={() => NoRegistrado()}>
                              <span className="btn-inner--icon">    
                             NO
                              </span>
                              
                          </Button>
          </>
        )
    }else{
        return <></>
    }
}

export default EtiquetaRegistrado;