import { urlGlobal, urlGlobal2 } from './GlobalService.js';


export const getTipoEgresos = async(params=null) => {
    let url = new URL(`${urlGlobal2}/tipoegresos`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
        
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}
export const getEgresos = async(params=null) => {
    let url = new URL(`${urlGlobal2}/egresos`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
        
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}

export async function postEgreso(egresos){
    let url = `${urlGlobal2}/egresos`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(egresos)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }
