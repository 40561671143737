import React, { useEffect, useState } from 'react'
import LoadingComponent from 'pages/components/Loading';
import PerfilVehiculoParticular from '../components/PerfilVehiculoParticular';
import { getVehiculos} from 'services/VehiculosServices';
import PapelesVehiculo from './PapelesVehiculo';



const VehiculoParticular = props => {
    const [Visible, setVisible] = useState(false);
    const [VehiculoPar, setVehiculoPar] = useState(null); 
    const [idVehiculo, setId] = useState(null);
    const [Vista, setVista] = useState("Perfil")

    const traerVehiculo = async() => {
        setVisible(true)
        let vehiculoId=props.match.params.idVehiculo;
        let params = {id:vehiculoId}
        setId(vehiculoId);
        let ve = await getVehiculos(params);
        if (ve != null && ve != '') {
        setVehiculoPar(ve);
        }
        setVisible(false)
    }
    
    useEffect(() => {
        traerVehiculo();
    }, [])
    
    return (
       <>
            <LoadingComponent Visible={Visible}/>
            {VehiculoPar?
                <>
                {Vista == "Perfil"?
                    <PerfilVehiculoParticular VehiculoPar={VehiculoPar} traerVehiculo={traerVehiculo} setVista={setVista}/>
                    :
                    <PapelesVehiculo idVehiculo={idVehiculo} setVista={setVista} setVisible={setVisible}/>    
                }
                </>
            :
            <></>
            }
           
       </>
    )
}

VehiculoParticular.propTypes = {

}

export default VehiculoParticular
