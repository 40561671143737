import { successAlert } from 'pages/components/SweetAlert';
import { confirmAlert } from 'pages/components/SweetAlert'
import React from 'react'
import {  Button } from 'reactstrap'
import { putEditarCierre } from 'services/CierreCajaService';



function ControladoCpt(props){
    const {Controlado, Cierre, setAlert, traerCierresCaja} = props

    const ConfirmarCambio=()=>{
        confirmAlert(setAlert, CambiarEstado, null,"Estas seguro que deseas cambiar de estado ?")
    }
    const CambiarEstado=async()=>{
        let cha = await putEditarCierre(Cierre);
            if (cha != null && cha != '') {
                successAlert(setAlert, "Estado Cambiado!", "")
                traerCierresCaja();
            }
        }
    
    
    return(
        <>
     
        
        {Controlado?
            <Button   onClick={() => {ConfirmarCambio()}} className="btn-icon btn-3 btn-sm btn-outline-success  "  type="button" >
               
                <span className="btn-inner--icon">    
                SI
                </span>
                
            </Button>
        :
            <Button  onClick={()=>{ConfirmarCambio()}} className="btn-icon btn-3 btn-sm btn-outline-danger "  type="button">
                
                <span className="btn-inner--icon">    
                NO
                </span>
                
            </Button>

            
    }
        </>
    )
}

export default ControladoCpt;