import { confirmAlert } from 'pages/components/SweetAlert'
import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Table } from 'reactstrap'
import { deleteSolicitudesCambios } from 'services/PedidoCambiosService'
import { getSolicitudesCambios } from 'services/PedidoCambiosService'
import ModalEditSolicitudes from './ModalEditSolicitudes'
import SelectorCategoriaValor from './SelectorCategoriaValor'
import SelectorCategoriaSolicitud from './SelectorEstadoSolicitud.js'

const TableSolicitudes = props => {
    const [Solicitudes, setSolicitudes] = useState([])
    const [ModalEditarSolicitud, setModalEditarSolicitud] = useState(false);
    const [IdSolicitud, setIdSolicitud] = useState("");
    const [NombreCambio, setNombreCambio] = useState("")
    const [Estado, setEstado] = useState("")
    const [Categoria, setCategoria] = useState("")
    const [Alert, setAlert] = useState(null)

    const traerSolicitudes = async () => {
        let solicitudes = await getSolicitudesCambios()
        if (solicitudes) {
            setSolicitudes(solicitudes.solicitudescambios)
        }
    }
    const traerSolicitudesFiltrado = async () => {
        let params ={}
        if(Estado != ""){
            params.estado = Estado;
        }
        if(Categoria != ""){
            params.categoria = Categoria;
        }
        let solicitudes = await getSolicitudesCambios(params)
        if (solicitudes) {
            setSolicitudes(solicitudes.solicitudescambios)
        }
    }

    const eliminarSolicitud = async (id) => {
        await deleteSolicitudesCambios(id)
        traerSolicitudes()
    }

    const confirmarEliminar = (solicitudId) => {
        confirmAlert(setAlert, eliminarSolicitud, solicitudId , 'Eliminar solicitud?' )
    }

    useEffect(() => {
        traerSolicitudes()
    }, [])
    
  return (
    <>
        {Alert}
        <Row className='mb-3'>
            <Col><SelectorCategoriaSolicitud Valor={Estado} setValor={setEstado}/></Col>
            <Col><SelectorCategoriaValor valor={Categoria} CambiarCatValor={setCategoria}/></Col>
            <Col><Button className='btn-md btn-outline-success float-left' onClick={()=>traerSolicitudesFiltrado()}>Filtrar</Button></Col>
            <Col></Col>
            <Col></Col>
        </Row>
        <Table responsive>
            <thead className='text-center'>
                <tr>
                    <th>NOMBRE</th>
                    <th>IMPORTE</th>
                    <th>CATEGORIA</th>
                    <th>FECHA DE IMPLEMENTACION</th>
                    <th>ESTADO</th>
                    <th>EDITAR</th>
                    <th>ELIMINAR</th>
                </tr>
            </thead>
            <tbody className='text-center'>
                {Solicitudes.length?
                Solicitudes.map((s, index) => {
                    return (
                <tr key={index}>
                    <th>{JSON.parse(s.cambios).nombre}</th>
                    <td>{JSON.parse(s.cambios).importe}</td>
                    <td>{s.categoria}</td>
                    <td>{s.fechaAplicacion}</td>
                    <td>{s.estado}</td>
                    <td><Button className='btn-sm btn-outline-success' onClick={()=> (setNombreCambio(JSON.parse(s.cambios).nombre),setIdSolicitud(s.id), setModalEditarSolicitud(true))}>Editar</Button></td>
                    <td><Button className='btn-sm btn-outline-danger' onClick={()=> confirmarEliminar(s.id)}>Eliminar</Button></td>
                </tr>
                    )
                })
            : 
            <>
            <tr>
                <td>
                    <p>No hay Solicitudes en este estado o categoria!</p>
                </td>
            </tr>
            </>
}
            </tbody>
        </Table>
        <ModalEditSolicitudes 
        ModalEditarSolicitud={ModalEditarSolicitud}
        setModalEditarSolicitud={setModalEditarSolicitud}
        idSolicitud={IdSolicitud}
        NombreCambio={NombreCambio}
        traerSolicitudes={traerSolicitudes}
        />
    </>
  )
}

export default TableSolicitudes