import LoadingComponent from 'pages/components/Loading'
import SelectorTiposMateriales from 'pages/private/stock/components/SelectorTipoMaterial'
import React, { useEffect, useState } from 'react'
import { Button, Col, Input, Row, Table } from 'reactstrap'
import { getRegistroMateriales } from 'services/RegistroMaterialesService'
import { getTiposMaterialesAlmacenes } from 'services/TiposMaterialesService'
import SelectorAlmacenes from './SelectorAlmacenes'
import SelectorEstadoHistorico from './SelectorEstadoHistorico'

const TableHistorico = ({idTipoMaterial, AlmacenId}) => {
    const [Registros, setRegistros] = useState([])
    const [idEstado, setidEstado] = useState("");
    const [Desde, setDesde] = useState("");
    const [Hasta, setHasta] = useState("");
    const [Materiales, setMateriales] = useState([])
    const [Material, setMaterial] = useState("")
    const [Visible, setVisible] = useState(false)
    const [idAlmacen, setidAlmacen] = useState("")
    const traerRegistros = async() => {
        setVisible(true)
        let params = null
        if (idTipoMaterial !== null) {
            if (AlmacenId !== null) {
            params = {tipoMaterialId:idTipoMaterial, almacenId: parseInt(AlmacenId)}
            } else {
                params = {tipoMaterialId:idTipoMaterial}
            }
        }
        let reg = await getRegistroMateriales(params);
        if (reg) {
            setRegistros(reg.registros)
        }
        setVisible(false)
    }

    const obtenerFormatoFecha = (fechaIngreso)=>{
        let fecha = new Date(fechaIngreso);
        let dia = fecha.getDate();
        let mes = fecha.getMonth();
        let ano = fecha.getFullYear();
        let fechaFormateada = `${dia}/${mes+1}/${ano}`;
        return fechaFormateada;
    }
    const filtrar = async() =>{
        setVisible(true)
        let params ={
            tipoMaterialId: Material ? parseInt(Material) : idTipoMaterial? parseInt(idTipoMaterial) : '' ,
            estadoId: idEstado,
            desde: Desde,
            hasta: Hasta,
            almacenId: idAlmacen ? idAlmacen : AlmacenId? AlmacenId : ''
        }
        let historicoFiltrado = await getRegistroMateriales( params)
        if (historicoFiltrado) {
            setRegistros(historicoFiltrado.registros)
        }
        setVisible(false)
    }
    const cambiarDesde = event => {
        setDesde(event.target.value);
    };
    const cambiarHasta = event => {
    setHasta(event.target.value);
    };
    const traerMateriales = async() => {
        let materiales = await getTiposMaterialesAlmacenes();
        if (materiales !== null) {
            setMateriales(materiales.tma)
        }
    }

    useEffect(() => {
        traerMateriales()
        traerRegistros()
    }, [])
    
  return (
    <>
    <LoadingComponent Visible={Visible}/>
    <Row>
        <Col><label>Estado:</label></Col>
        <Col><label>Desde:</label></Col>
        <Col><label>Hasta:</label></Col>
        {idTipoMaterial?
        <></>
    :
        <Col><label>Tipo Producto:</label></Col>
    }
    {AlmacenId !==null?
        <></>
    :
        <Col><label>Almacen::</label></Col>
    }
        <Col></Col>
    </Row>
    <Row className='mb-3'>
        <Col>
            <SelectorEstadoHistorico 
            idEstado={idEstado}
            cambiarIdEstado={setidEstado}
            />
        </Col>
        <Col><Input type='date' onChange={cambiarDesde}/></Col>
        <Col><Input type='date'onChange={cambiarHasta}/></Col>
        {idTipoMaterial?
        <></>
    :
        <Col><SelectorTiposMateriales CambiarTipoValor={setMaterial} TipoValor={Material} Materiales={Materiales}/></Col>
    }
    {AlmacenId !== null?
        <></>
    :
        <Col><SelectorAlmacenes idAlmacen={idAlmacen} cambiarIdAlmacen={setidAlmacen}/></Col>
    }
        <Col><Button className='btn-md btn-outline-success float-left' onClick={()=>filtrar()}>Filtrar</Button></Col>
    </Row>
    <Table className='text-center' responsive >
        <thead>
            <tr>
                <th>FECHA</th>
                <th>NOMBRE MATERIAL</th>
                <th>CANTIDAD</th>
                <th>CLIENTE</th>
                <th>EMPLEADO</th>
                <th>ALMACEN</th>
                <th>ESTADO</th>
                <th>DESCRIPCION</th>
            </tr>
        </thead>    
        <tbody>
            {
             Registros.length?
             Registros.map((r, index)=>{
                return(
            <tr key={index}>
                <th>
                {obtenerFormatoFecha(r.fecha)}
                </th>
                <td>{r.tiposMateriale?.nombre}</td>
                <td>{r.cantidad}</td>
                {r.cliente?
                <td>{r.cliente?.lastName}, {r.cliente?.firstName} ({r.cliente?.userIdent})</td>
                :
                <>
                <td>-</td></>}
                <td>{r.empleado?.apellido}, {r.empleado?.nombre} ({r.empleado?.dni})</td>
                <td>{r.almacene?.nombre}</td>
                <td>{r.estadosregistro?.nombre}</td>
                <td>{r.descripcion}</td>
                <td></td>
            </tr>

                )
             })
             :
             <>
                <tr>
                    <th>
                        No se encontraron Registros!
                    </th>
                </tr>
             </>
            }
        </tbody>
    </Table>    
    </>
  )
}

export default TableHistorico