import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Form, FormGroup, Input, Modal } from 'reactstrap'
import { putEditarCierre } from 'services/CierreCajaService'
import { successAlert } from '../../../components/SweetAlert';


export default function ModalEditarCierreCaja(props){
    const { CierreSelect, ModalEditCierre, setModalEditCierre, setAlert} = props
      const [Importe, setImporte] = useState("");
      const [Acumulado, setAcumulado] = useState("");

      const cambiarImporte = event => {
        setImporte (event.target.value);
      };
      const cambiarAcumulado = event => {
        setAcumulado (event.target.value);
      };
   
      const actualizarCierre= async()=>{
        let cierreAux = {}
        Object.assign(cierreAux, CierreSelect)
        cierreAux.importe = Importe;
        cierreAux.dineroAcumulado = Acumulado;
        let valido = await putEditarCierre(cierreAux);
        if(valido){
          setImporte("");
          setAcumulado("");
          recetearCierre();
          successAlert(setAlert, " Cierre Actualizado!", "");
          setModalEditCierre(false);
        }
      
      }
      const recetearCierre = () => {
        setImporte("");
        setAcumulado("");
        establecerCierre();


      }
      const establecerCierre= () => {
          setImporte(CierreSelect.importe)
          setAcumulado(CierreSelect.dineroAcumulado)
      }
      useEffect(() => {
        establecerCierre();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

    return(
      <>
      {CierreSelect?
      
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={ModalEditCierre}
          toggle={() => setModalEditCierre(false)}
        >
        <div className="modal-body p-0">
          <Card className=" shadow border-0">
            <CardHeader className=" pb-5">
              <div className="text-center"><b>Editar Cierre</b>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {setModalEditCierre(false);recetearCierre()}}
              >
                <span aria-hidden={true}>×</span>
              </button>
              </div>    
            </CardHeader>  
            <CardBody>
                <CardTitle><h1>ID de Cierre: {CierreSelect.id}</h1></CardTitle>
            <div className=" mt-2 mb-3">
              <Form role="form">
                <FormGroup className="mb-3">Importe:
                    <Input placeholder="Importe" type="text" onChange={cambiarImporte} value={Importe} />
                </FormGroup>
                <FormGroup className="mb-3">Dinero Acumulado:
                    <Input placeholder="Dinero Acumulado" type="text" onChange={cambiarAcumulado} value={Acumulado} />
                </FormGroup>
              </Form>                     
              </div>                  
            </CardBody> 
            <CardFooter>
            <Button className="mt-3" color="primary" onClick={()=>actualizarCierre()}>
                    Editar
                  </Button>
            </CardFooter>           
          </Card>
          
        </div>
        </Modal>
        :
        <>-</>}
      </>
    )
}