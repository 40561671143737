import { warningAlert } from 'pages/components/SweetAlert'
import { successAlert } from 'pages/components/SweetAlert'
import React, { useState } from 'react'
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, Modal } from 'reactstrap'
import { postObras } from 'services/ObrasService'

const ModalAggObra = ({ModalAgregarObra, setModalAgregarObra, traerObras}) => {
    const [Alert, setAlert] = useState(null)
    const [Nombre, setNombre] = useState('')

    const cambiarNombre = (e) => {
        setNombre(e.target.value)
    }

    const agregarObra = async() => {
        let obra = {
            nombre : Nombre
        }
        let aggObra = await postObras(obra)
        if (aggObra) {
            setNombre('')
            successAlert(setAlert, 'Obra Agregada correctamente!')
            setModalAgregarObra(false)
            traerObras()
        } else {
            warningAlert(setAlert, 'Error al agregar la Obra!')
        }
    }


  return (
    <>
    {Alert}
    <Modal
          className="modal-dialog-centered"
          size="md"
          isOpen={ModalAgregarObra}
          toggle={() => setModalAgregarObra(false)}
        >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-center"><b>Agregar Obra</b>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalAgregarObra(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
              </div>
              <div className="text-muted mt-2 mb-3">
              <Form role="form">
                <FormGroup className="mb-3">
                  <h2>Nombre:</h2>
                  <InputGroup className="input-group-alternative">
                    <Input placeholder="Nombre..." type="text"  onChange={cambiarNombre} value={Nombre}/>
                  </InputGroup>
                </FormGroup>
                </Form>                     
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={()=>agregarObra()}
                  >
                    Agregar
                  </Button>
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalAgregarObra(false)}
                  >
                    Cerrar
                  </Button>                    
                </div>
              </div>                    
            </CardHeader>                  
          </Card>
        </div>
        </Modal>
    
    </>
  )
}

export default ModalAggObra