import React, { useState } from 'react'
import SelectorCategorias from 'pages/components/SelectorCategoria'
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap'
import { postEquipos } from 'services/EquiposService'
import {warningAlert, successAlert, confirmAlert} from "../../../components/SweetAlert"
import LoadingComponent from 'pages/components/Loading'
import { updateFiscal} from "services/ClientesServices";


export default function ModalHacerFiscal(props){
    const {ModalFicalVisible,SetModalFiscalVisible, idClient} = props
    const [tipoDocumento,setTipoDocumento] = useState(null);
    const [tipoFactura,setTipoFactura] = useState(null);
    const [Alert, setAlert] = useState(null);
     
      
       
    const confirmarRCambio = ()=>{
        confirmAlert(setAlert, hacerFiscal, null, "¿Seguro que desea hacer fiscal al cliente?")
    }


    const cambiarTipoFactura = (e) => {
        if(e.target.value){
            setTipoFactura(e.target.value)
        }
    }


    const cambiarTipoDocumento = (e) => {
        if(e.target.value){
            setTipoDocumento(e.target.value)
        }
    }

    const CerrarModal = () => {
        SetModalFiscalVisible(false);
    }


    const hacerFiscal = async() => {
        try{

            if(!tipoDocumento || !tipoFactura){
                warningAlert(setAlert,"Ingrese ambos parametros");
                return;
            }
            
            let params = {clientId:parseInt(idClient),tipoFactura,tipoDocumento}
           
            let result = await updateFiscal(params)
            if(!result.ok){
                warningAlert(setAlert,result.mensaje)
            }
            

            successAlert(setAlert, "Cliente guardado correctamente", "")
              
          

        }catch(error){
            warningAlert(setAlert,error.message)
        }
    }
    
      
    

    return(
      <>
        {Alert}
        
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={ModalFicalVisible}
          toggle={() => CerrarModal()}
        >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-center"><b>Fiscalizar Cliente</b>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => CerrarModal()}
              >
                <span aria-hidden={true}>×</span>
              </button>
              </div>
              <div className="text-muted mt-2 mb-3">
              <Form role="form">
              <FormGroup className="mb-3">Tipo Factura:
                <FormGroup>
                    <Input id="exampleFormControlSelect1" type="select" onChange={cambiarTipoFactura}>
                        <option value={null}>Opciones..</option>
                        <option key={1} value={'A'}>{'A'}</option>
                        <option key={2} value={'B'}>{'B'}</option>
                    </Input>
                </FormGroup>
                </FormGroup> 
              

                <FormGroup className="mb-3">Tipo de Documento:
                <FormGroup>
                    <Input id="exampleFormControlSelect1" type="select" onChange={cambiarTipoDocumento}>
                        <option value={null}>Opciones..</option>
                        <option key={3} value={'DNI'}>{'DNI'}</option>
                        <option key={4} value={'CUIL'}>{'CUIL'}</option>
                        <option key={5} value={'CUIT'}>{'CUIT'}</option>
                    </Input>
                </FormGroup>
                </FormGroup> 

                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={()=>confirmarRCambio()}
                  >
                    Realizar Cambio
                  </Button>
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => SetModalFiscalVisible(false)}
                  >
                    Cerrar
                  </Button>                    
                </div>
              </Form>                     
              </div>                    
            </CardHeader>                  
          </Card>
        </div>
        </Modal>
      </>
    )
}