import { verificarToken } from "services/AuthService";
import { postLogOut } from "../services/AuthService";


export async function GuardRoutes(){
    let token = localStorage.getItem('token');
    let dev = await verificarToken(token);
    return dev;
}



export async function GuardInPages(history){
    let token = localStorage.getItem('token');
    if (token) {
        let dev = await verificarToken(token);
        if (!dev) {
            await postLogOut();
            history.replace(`/auth/login`)
        }
    }else{
        history.replace(`/auth/login`)
    }
}
