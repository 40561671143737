import React, { useState } from "react";
import {Button,Table,Card,CardHeader,CardBody,} from "reactstrap";
import EtiquetaMetodo from "./EtiquetaMetodo";
import ModalAPagos from "./ModaleAgregarPagos";

export default function CardPagos(props){

  const {idClient, PagosCliente, traerPagosCliente, setVista, setIdPago} = props;
  const [ModalAgregarPagos, setModalAgregarPagos]= useState(false);

  const obtenerFormatoFecha = (creado)=>{
    let fecha = new Date(creado);
    let dia = fecha.getDate();
    let mes = fecha.getMonth();
    let ano = fecha.getFullYear();
    let fechaFormateada = `${dia}/${mes+1}/${ano}`;
    return fechaFormateada;
  }


    return (
        <>
          <Card className="mt-4" style={{width:"100%"}}>
            <CardHeader>
              <Button className="btn-md btn-info mt-4 ml-4 " onClick={()=>setModalAgregarPagos(true)}>
                Nuevo Pago
              </Button>
            </CardHeader>
            <CardBody>
              <Table>
              <thead className="thead-light text-center">
              <tr>
              <th scope="col">METODO</th>
              <th scope="col">MONTO</th>
              <th scope="col">FECHA DE CREACION</th>
              <th scope="col">COMENTARIO</th>
              <th scope="col">VER</th>
              </tr>
              </thead>
              <tbody>
              {PagosCliente?  
               PagosCliente.map((pcl, index) => {
                return (
                    <tr key={index} className="text-center">                   
                    
                    <th scope="row">
                    <div className=" text-center">
                            <span className="mb-0 text-sm">
                            {pcl.methodId?
                                <EtiquetaMetodo Estado={pcl.methodId}/>
                                :
                        <p>Sin estado</p>
                            }
                            </span>
                        </div>
                        </th>
                        <td>
                        <div className=" text-center">
                            <span className="mb-0 text-sm">
                            $ {pcl.amount}
                            </span>
                        </div>
                        </td>
                        <td>
                        <div className=" text-center">
                            <span className="mb-0 text-sm">
                            {obtenerFormatoFecha(pcl.createdDate)}
                            </span>
                        </div>
                        </td>
                        <td>
                        <div className=" text-center">
                            <span className="mb-0 text-sm">
                            {pcl.note}
                            </span>
                        </div>
                        </td>
                        <td>
                        <div className=" text-center">
                            <Button className="btn-md btn-success" onClick={()=>{setIdPago(pcl.id); setVista("pago")}}>
                                Ver
                            </Button>
                        </div>
                        </td>
                    </tr>
                      )
                      }) 
                      :
                      <p>Cargando..</p>
                      }
                </tbody>  
              </Table>
              


            </CardBody>
          </Card>
          <ModalAPagos
          ModalAgregarPagos={ModalAgregarPagos}
          setModalAgregarPagos={setModalAgregarPagos}
          idClient={idClient}
          traerPagosCliente={traerPagosCliente}

          />
        </>
    )
}


