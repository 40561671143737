import React, { useState } from 'react';
import { Button, Table } from 'reactstrap';
import { useHistory } from 'react-router';
import ModalAggVehiculo from './ModalAgregarVehiculo';
import EtiquetaEstadoVe from './EtiquetaEstadoVehiculo';
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import { confirmAlert } from 'pages/components/SweetAlert';
import { deleteVehiculo } from 'services/VehiculosServices';

const TableVehiculos = props => {
    
    const {Vehiculos, traerVehiculos}=props;
    const history = useHistory ();
    const [ModalAgregarVehiculo, setModalAgregarVehiculo] = useState(false);
    const [alertSweet, setAlert] = useState(null);

    const irVehiculoParticular = (id)=>{
        history.replace(`/admin/VehiculoParticular/${id}`)
  
      }

    const confirmarEliminar= async(id)=>{
      confirmAlert(setAlert, eliminarVehiculo, id , "Deseas eliminar este Vehículo?")
    }
    const eliminarVehiculo = async(id)=>{
      
    let result = await deleteVehiculo(id)
    if(result != null){
            successAlert(setAlert, "Vehículo Eliminada!", "")
            traerVehiculos()
            return
  }else{
      warningAlert(setAlert,'No se pudo eliminar el Vehículo')
  }
  };
  return (
    <>
    {alertSweet}
    <Table className="align-items-center" responsive >
            <thead className="thead-light  text-center">
            <tr>
              <th scope="col">ID</th>
              <th scope="col">PATENTE</th>
              <th scope="col">MODELO</th>
              <th scope="col">MARCA</th>
              <th scope="col">ESTADO</th>
              <th scope="col">
                <Button className="btn-sm btn-outline-info" onClick={()=>setModalAgregarVehiculo(true)}>
                  Agregar Vehículo
                </Button>
              </th>
              <th scope="col" />
              
            </tr>
            </thead>
            <tbody>
        {Vehiculos?
        Vehiculos.map((vh, index)=>{
            return(
                <tr key={index} className="text-center" >
                    <th scope="row">
                    <div className=" text-center"  onClick={()=>irVehiculoParticular(vh.id)} >
                          <span className="mb-0 text-sm">
                            {vh.id}
                          </span>
                      </div>
                    </th>
                    <td>
                    <div className=" text-center" onClick={()=>irVehiculoParticular(vh.id)} >
                          <span className="mb-0 text-sm">
                            {vh.patente}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className=" text-center" onClick={()=>irVehiculoParticular(vh.id)}>
                          <span className="mb-0 text-sm">
                            {vh.modelo}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className=" text-center" onClick={()=>irVehiculoParticular(vh.id)}>
                          <span className="mb-0 text-sm">
                            {vh.marca}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className=" text-center" onClick={()=>irVehiculoParticular(vh.id)}>
                          <EtiquetaEstadoVe Estado={vh.estadoId}/>
                    </div>
                    </td>
                    <td>
                    <div className=" text-center" >
                            <Button className="btn-sm btn-outline-danger" onClick={()=>{confirmarEliminar(vh.id)}}>
                              Eliminar
                            </Button>
                    </div>
                    </td>
                </tr>    
            )
            })
            :
            <>-</>
            }
        </tbody>
        </Table>
        <ModalAggVehiculo
        traerVehiculos={traerVehiculos}
        ModalAgregarVehiculo={ModalAgregarVehiculo}
        setModalAgregarVehiculo={setModalAgregarVehiculo}
        />
    </>
  )
}

export default TableVehiculos