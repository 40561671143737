import LoadingComponent from "pages/components/Loading";
import { successAlert } from "pages/components/SweetAlert";
import { confirmAlert } from "pages/components/SweetAlert";
import React, { useEffect, useState } from "react";
import { Button, Table, Card, CardBody } from "reactstrap";
import { deleteRendicion } from "services/RendicionesServices";
import { getRendiciones } from "services/RendicionesServices.js";
import { getRolPermisos } from "services/RolService";
import EtiquetaControl from "../components/EtiquetaControlado";
import ModalAggRendicion from "../components/ModalAggRendicion";
import { warningAlert } from "pages/components/SweetAlert";

function  Rendiciones(props){
    const {idEmpls} = props;
    const [alertSweet, setAlert] = useState(null);
    const [Visible, setVisible] = useState(false);
    const [Rendicion, SetRendicion] = useState([]); 
    const [Permisos, setPermisos] = useState([]);
    const [ModalAgregarRendicion, setModalAgregarRendicion] = useState(false);
    
    

    const traerRendiciones = async() => {
      setVisible(true)
      let ren = await getRendiciones(null, idEmpls);
      if (ren != null && ren != '') {
        SetRendicion(ren);
      }
      setVisible(false)
    }
const obtenerFormatoFecha = (creado)=>{
    let fecha = new Date(creado);
    let dia = fecha.getDate();
    let mes = fecha.getMonth();
    let ano = fecha.getFullYear();
    let fechaFormateada = `${dia}/${mes+1}/${ano}`;
    return fechaFormateada;
}

const confirmarEliminar= async(id)=>{
  confirmAlert(setAlert, eliminarRendicion, id , "Deseas eliminar esta Rendicion?")
  }
  const eliminarRendicion = async(id)=>{
  
  let result = await deleteRendicion(id)
  if(result != null){
          successAlert(setAlert, "Rendicion Eliminada!", "");
          traerRendiciones()
          return
  }else{
    warningAlert(setAlert, 'No se pudo eliminar la rendicion')
  }
  };

  const traerPermisos= async()=>{

    let permisos= await getRolPermisos();
    if(permisos !=null && permisos!=''){
      setPermisos(permisos)
    }
  }

  const tienePermiso=(nombrePermiso)=>{
    return Permisos.includes(nombrePermiso);
  }



useEffect(()=>{
  traerRendiciones();
  traerPermisos();
}, [])


    return (
        <>
        <LoadingComponent Visible={Visible}/>
        {alertSweet}
          <Card className="mt-2" style={{width:"100%"}}>
          <CardBody>
          <Table className="align-items-center" responsive>
          <thead className=" text-center thead-light">
            <tr>
              <th scope="col">MONTO</th>
              <th scope="col">FECHA</th>
              <th scope="col">NOMBRE USUARIO</th>
              <th scope="col">COMPROBANTE</th>      
              <th scope="col">CONTROLADO</th>   
              <th scope="col"></th>   
              <th scope="col">            
                      <div className="d-flex align-items-center">
                        <div>
                          <Button className="btn-icon btn-3 btn-sm btn-outline-info float-right" color="primary" type="button" onClick={()=>setModalAgregarRendicion(true)}>
                              <span className="btn-inner--icon">
                              <i className="fas fa-plus"></i>  Nueva
                              </span>
                          </Button>
                        </div>
                      </div>
              </th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {Rendicion?                      
            Rendicion.map((rend, index) => {
              return (
                  <tr key={index}>
                    
                    <th scope="row">
                      <div className="text-center">
                      <span className="mb-0 text-sm">
                            {rend.monto}
                      </span>
                      
                      </div>
                          
                    </th>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                          {obtenerFormatoFecha(rend.creado)}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                          {rend.usuario?
                          rend.usuario.usuario
                          :
                          <>-</>
                          }
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                          {rend.comprobanteId}
                          </span>
                    </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <EtiquetaControl Controlado={rend.controlado} idEmpls ={idEmpls} rendicionId={rend.id} traerRendiciones={traerRendiciones}/>
                      </div>
                    </td>
                    {Permisos && tienePermiso('Eliminar-Rendiciones')?
                    <td>
                      <div className="text-center">
                        <Button className=" btn-sm btn-danger" onClick={()=>{confirmarEliminar(rend.id)}}>
                          Eliminar
                        </Button>
                      </div>
                    </td>
                    :
                    <></>
                  }
                  </tr>
                  
                )

               })
            :
            <p>Cargando..</p>
            } 
            </tbody>
          </Table>
          </CardBody>
        </Card>
        <ModalAggRendicion 
        ModalAgregarRendicion={ModalAgregarRendicion}
        setModalAgregarRendicion={setModalAgregarRendicion}
        idEmpls={idEmpls}
        traerRendiciones={traerRendiciones}
        />
        </>
    )
}


export default Rendiciones;