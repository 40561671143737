import { urlGlobal2 } from './GlobalService';


// *** EMPLEADOS *** *** EMPLEADOS *** *** EMPLEADOS *** *** EMPLEADOS ***

export async function getEmpleados(token){
    let empleados = null;
    await fetch(`${urlGlobal2}/empleados`, {
      method: 'GET',
      headers: {
        'token':token
      },
    })
    .then(response => response.json())
    .then(data => {
      empleados = data;
    })
    .catch((error) => {alert(error); empleados = null});
    return empleados;
  }
  export async function postEmpleados(empls){
    let url = `${urlGlobal2}/empleados`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'POST';
        let body = JSON.stringify(empls)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }

  export async function putEditarEmpleado(empl){
    let url = `${urlGlobal2}/empleados`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'PUT';
        let body = JSON.stringify(empl)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }
  export async function deleteEmpleado(id){
    let url = `${urlGlobal2}/empleados/${id}`;
    try {
        let token = localStorage.getItem('token');
        
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'DELETE';
        const resp = await fetch(url, {headers, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }