import React from 'react'
import { Button, Table } from 'reactstrap'

const TableInstalaciones = props => {

  return (
      <>
              <Table className="align-items-center" responsive>
                  <thead className="thead-light text-center">
                    <tr>
                      <th scope='col'>SELECCIONAR</th>
                      <th scope='col'>Id</th>
                      <th scope='col'>FECHA</th>
                      <th scope='col'>DINERO ACUMULADO</th>
                      <th scope='col'>INSTALACIONES AGENDADAS</th>
                      <th scope='col'>INSTALACIONES HECHAS</th>
                      <th scope='col'>VER RESUMEN</th>
                      <th scope='col'>RECALCULAR</th>
                      <th scope='col'>ELIMINAR</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                    <td>
                      <div className='text-center'>
                        <label className="custom-toggle ml-2">
                            <input type="checkbox"/>
                              <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="No"
                              data-label-on="Si"
                            />
                        </label>
                      </div>
                    </td>
                    <th>
                        <div className='text-center'>
                          <span className="mb-0 text-sm">
                            1021
                          </span>
                        </div>
                    </th>
                    <td>
                      <div className='text-center'>
                        <span className='mb-0 text-sm'>
                          12/3/22
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className='text-center'>
                        <span className='mb-0 text-sm'>
                          $ 54685
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className='text-center'>
                        <span className='mb-0 text-sm'>
                          26
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className='text-center'>
                        <span className='mb-0 text-sm'>
                          22
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className='text-center'>
                        <Button className=" btn-info btn-sm">
                          <i className='fas fa-eye'> Ver</i>
                        </Button>
                      </div>
                    </td>
                    <td>
                      <div className='text-center'>
                        <Button className="btn-sm btn-success">
                          Recalcular
                        </Button>
                      </div>
                    </td>
                    <td>
                      <div className='text-center'>
                        <Button className="btn-danger btn-sm">
                          Eliminar
                        </Button>
                      </div>
                    </td>
                    </tr>
                  </tbody>
              </Table>
    </>
  )
}


export default TableInstalaciones
