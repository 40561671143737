import React, { useEffect, useState } from 'react'
import { CardTitle, Col, Row } from 'reactstrap'

const CardDIneroFinal = props => {
    const {retiros, depositos, egresos, CierrePar}= props;
    const [ImporteEgreso, setImporteEgreso] = useState(null);
    const [ImporteDeposito, setImporteDepositos] = useState(null);
    const [ImporteRetiros, setImporteRetiros] = useState(null);
    const calcularImporte=()=>{
        let importeEgreso=0;
        let importeDeposito=0;
        let importeRetiros=0;
        
        egresos.forEach(egreso => {
          importeEgreso = importeEgreso + egreso.importe
      });
      depositos.forEach(depo => {
        importeDeposito = importeDeposito + depo.importe
      });
      retiros.forEach(retiro => {
        importeRetiros = importeRetiros + retiro.importe
      });
      setImporteEgreso(importeEgreso);
      setImporteDepositos(importeDeposito);
      setImporteRetiros(importeRetiros);
      }
      useEffect(() => {
        calcularImporte()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
  return (
      <>
      <Row >
                <Col >
                  <CardTitle className="h5  text-muted mb-0">
                    DINERO FINAL
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">{CierrePar.importe + CierrePar.dineroAcumulado - ImporteEgreso - ImporteDeposito - ImporteRetiros}</span>
                </Col>
                <Col >
                  <div className="icon icon-shape bg-red text-white rounded-circle shadow">
                  <i className="fas fa-user-times"></i>
                  </div>
                </Col>
              </Row>
      </>
  )
}

export default CardDIneroFinal