import React, { useEffect } from 'react';
import { successAlert } from 'pages/components/SweetAlert';
import { useState } from 'react';
import { Button, Card, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap';
import SubirFotoComponent from 'pages/components/SubirFoto';
import SelectorTipoTramite from 'pages/components/SelectorTipoTramite';
import { getTipoTramites } from 'services/TipoTramitesService';
import { postTramite } from 'services/TramitesService';
import { warningAlert } from 'pages/components/SweetAlert';

const ModalAggTramite = props => {
    
    const {ModalAgregarTramite, setModalAgregarTramite, traerTramites, idVehiculo}=props;
    const [alertSweet, setAlert] = useState(null);
    const [Inicio, setInicio] = useState("");
    const [Finalizacion, setFinalizacion] = useState("");
    const [TipoTramite, setTipoTramite] = useState("");
    const [foto, setFoto] = useState(null);  
    const [TipoTramites, setTipoTramites] = useState(null);  

    const cambiarInicio = event => {
        setInicio(event.target.value);
    };
    const cambiarFinalizacion = event => {
        setFinalizacion(event.target.value);
    };

    const traerTipoTramites = async() => {
        let ttra = await getTipoTramites();
        if (ttra != null && ttra != '') {
            setTipoTramites(ttra);
        }
    }


    const AgregarTramite = async()=>{
    
        let tra = {
            fechaInicio: Inicio,
            fechaVencimiento: Finalizacion,
            archivo: foto,
            tipoTramiteId: parseInt(TipoTramite),
            vehiculoId: idVehiculo,
        }
      let result = await postTramite(tra)
      if(result != null){
        setInicio("");
        setFinalizacion("");
        setTipoTramite("");
        setFoto("");

        setModalAgregarTramite(false)
        successAlert(setAlert, "Tramite agregado correctamente!", "")
        traerTramites()
    }else{
      warningAlert(setAlert, "Error al agregar el Tramite")
    }
    }
    useEffect(() => {
        traerTipoTramites();
        }, [])
  return (
      <>
          {alertSweet}
      <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={ModalAgregarTramite}
                            toggle={() => setModalAgregarTramite(false)}
                          >
                          <div className="modal-body p-0">
                            <Card className="bg-secondary shadow border-0">
                              <CardHeader className="bg-transparent pb-5">
                                <div className="text-center"><b>Agregar Tramite </b>
                                <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => setModalAgregarTramite(false)}
                                >
                                  <span aria-hidden={true}>×</span>
                                </button>
                                </div>
                                <div className="text-muted mt-4 mb-3">
                                <Form role="form">
                                <FormGroup>
                                    <label
                                      className="mb-3"
                                      htmlFor="example-date-input"
                                      >
                                      Fecha Inicio:
                                    </label>
                                    <Input
                                      id="example-date-input"
                                      type="date"
                                      value={Inicio} onChange={cambiarInicio}
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <label
                                      className="mb-3"
                                      htmlFor="example-date-input"
                                      >
                                      Fecha Finalizacion:
                                    </label>
                                    <Input
                                      id="example-date-input"
                                      type="date"
                                      value={Finalizacion} onChange={cambiarFinalizacion}
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                  <label>Tipo de Tramite:</label>
                                  <SelectorTipoTramite Valor={TipoTramite} setValor={setTipoTramite} arrayElementos={TipoTramites}/>
                                  </FormGroup>
                                  </Form>                     
                                  <SubirFotoComponent setFoto={setFoto}/>
                                  <div className="text-center">
                                    <Button
                                      className="my-4"
                                      color="primary"
                                      type="button"
                                      onClick={()=>AgregarTramite()}
                                    >
                                      Agregar
                                    </Button>
                                
                                    <Button
                                      color="secondary"
                                      data-dismiss="modal"
                                      type="button"
                                      onClick={() => setModalAgregarTramite(false)}
                                    >
                                      Cerrar
                                    </Button>                    
                                  </div>
                                </div>                    
                              </CardHeader>                  
                            </Card>
                          </div>
                          </Modal>
      
      </>
  )

}
export default ModalAggTramite