/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React , { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { useHistory } from "react-router";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,
  ListGroup,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  CardTitle
} from "reactstrap";
import { getEmpleados } from "services/EmpleadosService";

function NavBarEmpleados(props) {
  const {setVista, idEmpls} = props
  const [estado, setState] = useState(null);
  const history = useHistory();
  const [Empleado, setEmpleado] = useState(null);

  const logout = () => {
    localStorage.removeItem('token');
     //una vez que se logueo ok lo llevo a otra pagina
     history.replace('/layouts/Auth.js');
  };
  // const traerEmpleado = async() => {
    
  //   let em = await getEmpleados(null, idEmpls);
  //   if (em != null && em != '') {
  //   setEmpleado(em);
  //   }
  //  }
 

 //Al poner la funcion aca, esta se llama sola
 useEffect(() => { 
  //  traerEmpleado();   
  });
  
  const toggleNavs = (e, state, index) => {
    e.preventDefault();
   setState(index);
  };
  

  return (
    <>
     <Card className="border-0 mb-0" style={{width:"100%"}}>
       <CardBody>
        
         {/* <CardTitle><b>nombre apellido</b></CardTitle> */}
         
      <Nav
          className="nav-fill flex-column flex-sm-row"
          id="tabs-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={estado === 1}
              className={classnames("mb-sm-3 mb-md-0", {
                active: estado === 1
              })}
              onClick={e => {toggleNavs(e, "navPills", 1); setVista("Rendiciones")}}
              role="tab"
            >
              Rendiciones
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={estado === 2}
              className={classnames("mb-sm-3 mb-md-0", {
                active: estado === 2
              })}
              onClick={e => {toggleNavs(e, "navPills", 2); setVista("Reportes")}}
              role="tab"
            >
              Reportes
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={estado === 3}
              className={classnames("mb-sm-3 mb-md-0", {
                active: estado === 3
              })}
              onClick={e => {toggleNavs(e, "navPills", 3); setVista("Equipos")}}
              role="tab"
            >
              Equipos
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={estado === 4}
              className={classnames("mb-sm-3 mb-md-0", {
                active: estado === 4
              })}
              onClick={e => {toggleNavs(e, "navPills", 4); setVista("PagosSueldos")}}
              role="tab"
            >
              Pagos Sueldos
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={estado === 5}
              className={classnames("mb-sm-3 mb-md-0", {
                active: estado === 5
              })}
              onClick={e => {toggleNavs(e, "navPills", 5); setVista("Materiales")}}
              role="tab"
            >
              Materiales
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={estado === 6}
              className={classnames("mb-sm-3 mb-md-0", {
                active: estado === 6
              })}
              onClick={e => {toggleNavs(e, "navPills", 6); setVista("VerCuenta")}}
              role="tab"
            >
              Ver Cuenta
            </NavLink>
          </NavItem>        
        </Nav>
        </CardBody>
        </Card>
    </>
  );
}

NavBarEmpleados.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
NavBarEmpleados.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default NavBarEmpleados;
