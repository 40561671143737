import React from "react";
import { FormGroup, Input } from "reactstrap";

function SelectorEstadoCaso(props){
    const {Valor, setValor} = props;
    
    const cambiarValor = event => {
        setValor(event.target.value);
    };

    return(
        <>
            <FormGroup>
                    <Input id="exampleFormControlSelect1" type="select" 
                        value={Valor}
                        onChange={cambiarValor}
                    >
                        <option value={''}>Opciones..</option>
                        <option value={1}>NO RESUELTOS</option>
                        <option value={2}>RESUELTOS</option>
                    </Input>
            </FormGroup>
        </>
    );
};

export default SelectorEstadoCaso;