import { urlGlobal, urlGlobal2 } from './GlobalService.js';
import axios from 'axios';

export const getFacturas = async(params=null, id) =>{
    let url = new URL(`${urlGlobal2}/facturas/${id}`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}
export const getDatosFactura = async(id) => {
    let token = localStorage.getItem('token');
    let headers = {'Content-Type':'application/json','token':token};
    const nResp = await fetch(`${urlGlobal2}/facturas/download/${id}`,{headers,method:'GET'});
    const nData = await nResp.json();

    return nData;
}


export const descargarFactura = (id) => { 
    let token = localStorage.getItem('token');
    let headers = {'Content-Type':'application/json','token':token};
    axios(`${urlGlobal2}/facturas/imprimir/${id}`, {
        method: 'GET',
        responseType: 'blob', //Force to receive data in a Blob Format
        headers
     })
     .then(response => {
     //Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
     //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
     //Open the URL on new Window
        window.open(fileURL);
     })
     .catch(error => {
        
     });
}

/// /generarFiscal

export async function postFiscalizarFacturas(dataFiscalizar){
    let urlFiscalizar = new URL(`${urlGlobal2}/facturacion/fiscalizar`)
    try {
        let token = localStorage.getItem('token');
        let body = JSON.stringify(dataFiscalizar)
        let dev = await axios.post(
            urlFiscalizar,
            body,
            {headers:{'token':token,'Content-Type':'application/json'}
        });
        if(dev && dev.data && dev.data.ok == true){
            return {ok:true, mensaje:"Factura fiscalizada correctamente"};
        }else{
            return {ok:false, mensaje:"Error al fiscalizar la factura"};
        }
    } catch (error) {
        
        return {ok:false, mensaje:`${error.message}`};
    }
  }