import LoadingComponent from 'pages/components/Loading'
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import ModalAggEgreso from './components/ModalAggEgreso'
import TableDepositos from './components/TableDepositos'
import TableEgresos from './components/TableEgresos'
import TableRetirosPagos from './components/TableRetirosPagos'
import { getCierresCajaEgresos } from 'services/CierreCajaService'
import CardDIneroFinal from './CardDIneroFinal'
import CardDineroUtilizado from './components/CardDineroUtilizado'
import ModalIngDeposito from './components/ModalIngresarDeposito'
import ModalAggRetiro from './components/ModalAggRetiro'
import { useHistory } from 'react-router'

const CardsIcons ={height:"6.5rem"}

const Contadores = props => {
  const [ModalAgregarEgreso, setModalAgregarEgreso] = useState(false);
  const [ModalIngresarDeposito, setModalIngresarDeposito] = useState(false);
  const [ModalAgregarRetiro, setModalAgregarRetiro] = useState(false);
  const [Visible, setVisible] = useState(false);
  const [CierrePar, setCierrePar] = useState(null); 
  const history = useHistory ();

  const traerCierreEspecifico = async() => {
    setVisible(true)
    let cierreId=props.match.params.idCierreCaja;
    let ccp = await getCierresCajaEgresos(null, cierreId);
    if (ccp !== null && ccp !== '') {
    setCierrePar(ccp);
    }
    setVisible(false)
  }
  const obtenerFormatoFecha = (creado)=>{
    let fecha = new Date(creado);
    let dia = fecha.getDate();
    let mes = fecha.getMonth();
    let ano = fecha.getFullYear();
    let fechaFormateada = `${dia}/${mes+1}/${ano}`;
    return fechaFormateada;
}
const esFechaDeAyer=()=>{
  
  let hoy = new Date();
  let DIA_EN_MS = 24 * 60 * 60 * 1000;
  let ayer = new Date(hoy.getTime() - DIA_EN_MS);
  let fechaCierreCaja = new Date(CierrePar?.fecha);
  if(fechaCierreCaja.getFullYear() == ayer.getFullYear() && fechaCierreCaja.getMonth() == ayer.getMonth() && fechaCierreCaja.getDate() == ayer.getDate()){

    return true;
  }
  else{
    return false;
  }


}
const Atras=() =>{
  history.replace(`/admin/cierreCaja`)
}
  useEffect(() => {
    traerCierreEspecifico();
    esFechaDeAyer();
  }, [])
  return (
      <>
      <LoadingComponent Visible={Visible}/>
      
      <Row className="mt-3 ml-4">
        <Button className="btn-info" onClick={()=>{Atras()}}>Atrás</Button>
        {esFechaDeAyer() !=false?
     <>
        <Button className="btn-info" onClick={()=>setModalAgregarEgreso(true)}> Agregar Egreso</Button>
        <Button className="btn-info" onClick={()=>setModalIngresarDeposito(true)}>Ingresar Deposito</Button>
        <Button className="btn-info" onClick={()=>setModalAgregarRetiro(true)}>Ingresar Retiro</Button>
        </>
      :
     <>-</>}
      </Row>
      
      <Row className="ml-2 mr-2">
      <Card  className="ml-3 mr-3 mt-3" style={{width:"100%"}}>
          <CardBody>
            {CierrePar?
            <Row >
            <Col>
            <Row >
                   <div className="col">
                     <CardTitle className="text-uppercase text-muted mb-0">
                       FECHA
                     </CardTitle>
                     <span className="h2 font-weight-bold mb-0">{obtenerFormatoFecha(CierrePar.fecha)}</span>
                   </div>
                   <Col className="col-auto">
                     <div className="icon icon-shape bg-red text-white rounded-circle shadow">
                     <i className="far fa-clock"></i>
                     </div>
                   </Col>
                 </Row>
            </Col>
            <Col>
            <Row >
                   <div className="col">
                     <CardTitle className="text-uppercase text-muted mb-0">
                       ID
                     </CardTitle>
                     <span className="h2 font-weight-bold mb-0">{CierrePar.id}</span>
                   </div>
                   <Col className="col-auto">
                     <div className="icon icon-shape bg-red text-white rounded-circle shadow">
                     <i className="fas fa-user"></i>
                     </div>
                   </Col>
                 </Row>
            </Col>
        </Row>
            :
            <>
            -
            </>}
       
       </CardBody>
      </Card>
      </Row>
      <Row className="ml-2 mr-2">
          <Col>
          <Card style={CardsIcons}>
              <CardBody>
                {CierrePar?
                <CardDIneroFinal 
                retiros={CierrePar.retiroscajas}
                depositos={CierrePar.depositos}
                egresos={CierrePar.egresos}
                CierrePar={CierrePar}/>
                :
                <>-</>
                }
              
              </CardBody>
          </Card>
          </Col>
          <Col>
          <Card style={CardsIcons}>
              <CardBody>
                {CierrePar?
                <Row >
                <Col>
                    <CardTitle className="h5 text-muted mb-0">
                      DINERO 
                      INGRESADO
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">{CierrePar.importe}</span>
                </Col>
                  <Col >
                    <div className="icon icon-shape bg-red text-white rounded-circle shadow">
                    <i className="fas fa-user-times"></i>
                    </div>
                  </Col>
                </Row>
                :
                <>-</>
                }
              
              </CardBody>
          </Card>
          </Col>
          <Col>
          <Card style={CardsIcons}> 
              <CardBody>
                {CierrePar?
              <CardDineroUtilizado 
              depositos={CierrePar.depositos}
              egresos={CierrePar.egresos}/>
              :
                <>-</>}
              </CardBody>
                
          </Card>
          </Col>
          <Col>
          <Card  style={CardsIcons}>
              <CardBody>
                {CierrePar?
                <Row >
                <Col >
                  <CardTitle className="h5  text-muted mb-0">
                    DINERO ACUMULADO
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">{CierrePar.dineroAcumulado}</span>
                </Col>
                <Col >
                  <div className="icon icon-shape bg-red text-white rounded-circle shadow">
                  <i className="fas fa-user-times"></i>
                  </div>
                </Col>
              </Row>
                :
                <>-</>}
                
              </CardBody>
          </Card>
          </Col>
      </Row>
     {CierrePar?
     <TableEgresos Egresos={CierrePar.egresos}/>
     :
     <>-</>}
     {CierrePar?
     <TableDepositos Depositos={CierrePar.depositos}/>
     :
     <>-</>}
     {CierrePar?
     <TableRetirosPagos Retiros={CierrePar.retiroscajas}/>
     :
     <>-</>}

     {esFechaDeAyer() !=false?
     <>
     {CierrePar?
     <>
     <ModalAggEgreso
     ModalAgregarEgreso={ModalAgregarEgreso}
     setModalAgregarEgreso={setModalAgregarEgreso}
     CierreCajaId={CierrePar.id}/>

     <ModalIngDeposito
     ModalIngresarDeposito={ModalIngresarDeposito}
     setModalIngresarDeposito={setModalIngresarDeposito}
     CierreCajaId={CierrePar.id}/>
     
     <ModalAggRetiro
     ModalAgregarRetiro={ModalAgregarRetiro}
     setModalAgregarRetiro={setModalAgregarRetiro}
     CierreCajaId={CierrePar.id}
     />
     </>
     :
     <>-</>}
     </>
     
     :
     <>-</>}

      </>
  )
}

export default Contadores