import { successAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react';
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap';
import { postComentario } from 'services/CasosServices';
import SubirFotoComponent from 'pages/components/SubirFoto';
import { warningAlert } from 'pages/components/SweetAlert';

const ModalAgregarComen = (props) => {
    const {ModalAgregarComentario, setModalAgregarComentario,idCaso, setAlert, traerCaso }= props;
    const [mensaje, setComentario] = useState(null);
    const [foto, setFoto] = useState(null)  
    

    const cambiarComentario = event => {
        setComentario(event.target.value);
      };
    const AgregarComentario= async()=>{
    
        let comentario = {
          mensaje: mensaje,
          archivo: foto,
          casoId: idCaso,
        }
      let result = await postComentario(comentario)
      if(result != null){
        setComentario("")
        setFoto("")
        setModalAgregarComentario(false)
      
        traerCaso();
        successAlert(setAlert, "Comentario Agregado!", "");
      }else{
        warningAlert(setAlert,'No se pudo agregar comentario')
      }
    }

    
  return(
      <>
      <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={ModalAgregarComentario}
                            toggle={() =>setModalAgregarComentario(false)}
                          >
                          <div className="modal-body p-0">
                            <Card className="bg-secondary shadow border-0">
                              <CardHeader className="bg-transparent pb-5">
                                <div className="text-center"><b>Nuevo Comentario</b>
                                <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => setModalAgregarComentario(false)}
                                >
                                  <span aria-hidden={true}>×</span>
                                </button>
                                </div>
                                <div className="text-muted mt-2 mb-3">
                                <Form role="form">
                                <FormGroup className="mb-3 " value={mensaje} onChange={cambiarComentario}>Enunciado:
                                    <InputGroup className="input-group-alternative">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText >
                                        <Input
                                          className="form-control-lg"
                                          placeholder="Nuevo Comentario..."
                                          type="text"
                                        />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      
                                    </InputGroup>
                                  </FormGroup>
                                </Form>                     
                                  <SubirFotoComponent setFoto={setFoto}/>
                                  <div className="text-center">
                                    <Button
                                      className="my-4"
                                      color="primary"
                                      type="button"
                                      onClick={()=>AgregarComentario()}
                                    >
                                      Agregar
                                    </Button>
                                
                                    <Button
                                      color="secondary"
                                      data-dismiss="modal"
                                      type="button"
                                      onClick={() => setModalAgregarComentario(false)}
                                    >
                                      Cerrar
                                    </Button>                    
                                  </div>
                                </div>                    
                              </CardHeader>                  
                            </Card>
                          </div>
                          </Modal>
      </>
  )
};

export default ModalAgregarComen;
