
import { useEffect, useState } from 'react';
import { Button, Card, CardHeader, Form, FormGroup, Modal} from 'reactstrap';
import SelectorPlanes from 'pages/components/SelectorPlanes';
import { successAlert } from 'pages/components/SweetAlert';
import { putPlan } from 'services/ServiciosServices';
import { getTiposPlanes } from 'services/ServiciosServices';


export const ModalCambiarPlan = (props) => {
   
    const [PlanId, setPlanId] = useState(null);
    const [Planes, setPlanes] = useState(null);



    const actualizarPlan = async() => {
        let planAcc = {
          serviceId:props.Servicio.id,
          clientId:parseInt(props.idClient),
          planOldId:props.Servicio.servicePlanId,
          planNewId:parseInt(PlanId)
        }

       
        
       
        let pla = await putPlan(planAcc);
        if (pla) {
            //successAlert(props.setAlert, "Plan Editado!", "")
            props.setModalP(false)
            props.traerServicio()
        }
        
        
  
    }





    useEffect( () => {
        getTiposPlanes().then(data => setPlanes(data))
    },[])



    return(
        <>
            
                          <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={props.ModalP}
                            toggle={() => props.setModalP(false)}
                          >
                          <div className="modal-body p-0">
                            <Card className="bg-secondary shadow border-0">
                              <CardHeader className="bg-transparent pb-5">
                                <div className="text-center"><b>Seleccionar el Plan a Cambiar</b>
                                <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => props.setModalP(false)}
                                >
                                  <span aria-hidden={true}>×</span>
                                </button>
                                </div>
                                </CardHeader> 
                                <div className="text-muted mt-2 mb-3">
                                <Form role="form">
                                <FormGroup className="mt-3">
                                    <label htmlFor="exampleFormControlSelect1" className="mb-2 ml-3" >Categoria:</label>
                                    
                                    <SelectorPlanes Valor={PlanId} setValor={setPlanId} arrayElementos={Planes}/>
                                       
                                </FormGroup>
 
                                  <div className="text-center">
                                    <Button
                                      className="my-4"
                                      color="primary"
                                      type="button"
                                      onClick={()=>actualizarPlan(PlanId)}
                                    >
                                      Editar
                                    </Button>
                                
                                    <Button
                                      color="secondary"
                                      data-dismiss="modal"
                                      type="button"
                                      onClick={() => props.setModalP(false)}
                                    >
                                      Cerrar
                                    </Button>                    
                                  </div>
                                </Form>                     
                                </div>                    
                                               
                            </Card>
                          </div>
                          </Modal>
        
        
        </>
    );
    
}