import React, { useEffect, useState } from "react";
import {Button, Col, Row} from "reactstrap";
import TableCategoriasEquipos from "./components/TableCategoriasEquipos";
import { getRolPermisos } from "services/RolService";
import LoadingComponent from "pages/components/Loading";
import ModalAgregarCategoriaEquipos from "./components/ModalAddCategoriaEquipos";
import ModalEditarCategoriaEquipos from "./components/ModalEditarCategoriaEquipos";
import jwt_decode from "jwt-decode";
import { getCategorias } from "services/CategoriasService";


export default function CategoriasEquipos() {
  const [CategoriasEquipos, setCategoriasEquipos] = useState([]); 
  const [VerModalAgregarCategoriaEquipos, setVerModalAgregarCategoriaEquipos] = useState(false);
  const [VerModalEditarCategoriaEquipos, setVerModalEditarCategoriaEquipos] = useState(false);
  const [CategoriaEquiposSeleccionada, setCategoriaEquiposSeleccionada] = useState(null);
  const [Permisos, setPermisos] = useState([]);
  const [Visible, setVisible] = useState(false);

  const traerCategoriasEquipos = async() => {
        let tk = null; 
      try {
        tk = localStorage.getItem('token');
      } catch (error) {
        return
      }
      let cats = await getCategorias(tk);
      if (cats != null && cats != '') {
        setCategoriasEquipos(cats);
      }
  }


  const traerPermisos= async()=>{
    setVisible(true)

    let permisos= await getRolPermisos();
    if(permisos !=null && permisos!=''){
      setPermisos(permisos)
    }
    setVisible(false)
  }

  const tienePermiso=(nombrePermiso)=>{
    return Permisos.includes(nombrePermiso);
  }

  
  useEffect(() => {
    traerCategoriasEquipos();
    traerPermisos();
  }, [])
  
  return(
    <>
    <LoadingComponent Visible={Visible}/>
    <Row className="m-4">
        <Col>{Permisos && tienePermiso("Agregar-General")?
                <Button className="btn-icon btn-3 btn-md mr-6 float-right" color="primary" type="button" onClick={()=>setVerModalAgregarCategoriaEquipos(true)}>
                    <span className="btn-inner--icon">
                    Nueva Categoria
                    </span>
                </Button>
                :
                <></>
        }</Col>
    </Row>

    <TableCategoriasEquipos CategoriasEquipos={CategoriasEquipos} traerCategoriasEquipos={traerCategoriasEquipos}
      setCategoriaEquiposSeleccionada={setCategoriaEquiposSeleccionada}
      setVerModalEditarCategoriaEquipos={setVerModalEditarCategoriaEquipos}
    />

    <ModalAgregarCategoriaEquipos
      VerModalAgregarCategoriaEquipos={VerModalAgregarCategoriaEquipos} 
      setVerModalAgregarCategoriaEquipos={setVerModalAgregarCategoriaEquipos} 
      traerCategoriasEquipos={traerCategoriasEquipos}
    />
    {CategoriaEquiposSeleccionada!=null?
    <ModalEditarCategoriaEquipos
    VerModalEditarCategoriaEquipos={VerModalEditarCategoriaEquipos} 
    setVerModalEditarCategoriaEquipos={setVerModalEditarCategoriaEquipos} 
    traerCategoriasEquipos={traerCategoriasEquipos}
    CategoriaEquiposSeleccionada={CategoriaEquiposSeleccionada}
    setCategoriaEquiposSeleccionada={setCategoriaEquiposSeleccionada}
    />    
    :
    <></>
    }

               
    </>
  )
}