import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react';
import { Button, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap';
import { postAlmacen } from 'services/AlmacenesService';

const ModalCrearAlmacen=(props)=>{
    const {ModalAggAlmacen, setModalAggAlmacen, traerAlmacenes}=props;
    const [Alert, setAlert] = useState(null)
    const [nombre, setNombre] = useState("");

    const cambiarNombre = event => {
        setNombre(event.target.value);
      };

      const CrearAlmacen = async()=>{
        let almacen = {
          nombre: nombre,
          permiso: nombre.replace(' ', '-')
        }
        if (validarAggAlmacen(almacen)) {
          let result = await postAlmacen(almacen)
          if(result != null){
        
            setNombre("")
            setModalAggAlmacen(false)
            successAlert(setAlert, "Almacen creado correctamente!", "")
            
            traerAlmacenes()
            return
        }
      }
      //cambiar por sweetalert
      warningAlert(setAlert,'No se pudo agregar almacen')
  
    }
    const validarAggAlmacen = (empl)=>{
        if(!empl.nombre){
          return false;
        }
        return true;
      }

    return(
        <>
        {Alert}
        <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={ModalAggAlmacen}
            toggle={() => setModalAggAlmacen(false)}
            >
            <div className="modal-body p-0">
                <CardHeader>
                  <div className="text-center"><b>Agregar Almacen</b>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalAggAlmacen(false)}
                    >
                  <span aria-hidden={true}>×</span>
                  </button>
                  </div>
                </CardHeader>     
                <CardBody>

                                <Form role="form">
                                <FormGroup value={nombre} onChange={cambiarNombre}> Nombre:
                                    <InputGroup className="input-group-alternative">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                        
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input placeholder="Nombre..." type="text" />
                                    </InputGroup>
                                  </FormGroup>
                                  </Form>  
                                </CardBody>
                                <CardFooter>
                                  <div className="text-center">
                                    <Button
                                      color="primary"
                                      type="button"
                                      onClick={()=>CrearAlmacen()}
                                    >
                                      Agregar
                                    </Button>
                                
                                    <Button
                                      color="secondary"
                                      data-dismiss="modal"
                                      type="button"
                                      onClick={() => setModalAggAlmacen(false)}
                                    >
                                      Cerrar
                                    </Button>                    
                                  </div>
                                </CardFooter>
                          </div>
                          </Modal>
        </>
    )
}
export default ModalCrearAlmacen