import SelectorPlanes from 'pages/components/SelectorPlanes';
import SelectorZonas from 'pages/components/SelectorZonas';
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap';
import { postEspera } from 'services/EsperaServices';
import { putEditarEspera } from 'services/EsperaServices';
import { getTiposPlanes } from 'services/ServiciosServices';
import { getZonas } from 'services/ZonasServices';

const ModalAgregarEs =(props)=> {
  const {ModalAgregarEspera, setModalAgregarEspera, filtrarPorZona,valorZonas,  EsperaSelec, Editar, filtrarEsperasEstado}= props;

  const [Nombre, setNombre] = useState("");
  const [Apellido, setApellido] = useState("");
  const [Telefono, setTelefono] = useState("");
  const [Direccion, setDireccion] = useState("");
  const [Dni, setDni] = useState("");
  const [Comunico, setComunico] = useState("");
  const [Comentario, setComentario] = useState("");
  const [Usuario, setUsuario] = useState("");
  const [Turno, setTurno] = useState("");
  const [Latitud, setLatitud] = useState("");
  const [Longitud, setLongitud] = useState("");
  const [Zona, setZona] = useState("");
  const [Email, setEmail] = useState("");
  const [Alert, setAlert] = useState(null);
  const [Zonas, setZonas] = useState("");
  const [PlanId, setPlanId] = useState("");
  const [Planes, setPlanes] = useState("");
  const [EsEmpresa, setEsEmpresa] = useState(false);


  const cambiarNombre = event => {
      setNombre(event.target.value);
  };
  const cambiarEmail = event => {
    setEmail(event.target.value);
  };
  const cambiarApellido = event => {
    setApellido(event.target.value);
  };
  const cambiarTelefono = event => {
    setTelefono(event.target.value);
  };
  const cambiarDireccion = event => {
    setDireccion(event.target.value);
  };
  const cambiarComunico = event => {
    setComunico(event.target.value);
  };
  const cambiarUsuario = event => {
    setUsuario(event.target.value);
  };
  const cambiarTurno = event => {
    setTurno(event.target.value);
  };
  const cambiarComentario = event => {
    setComentario(event.target.value);
  };
  const cambiarLatitud = event => {
    setLatitud(event.target.value);
  };
  const cambiarLongitud = event => {
    setLongitud(event.target.value);
  };
  const cambiarDni = event => {
    setDni(event.target.value);
  };

  const AgregarEspera = async()=>{
    let espera = {
      formaContacto: Comunico,
      nombre: Nombre,
      email: Email,
      dni: Dni,
      estado: 1, /* por defecto */
      color: null,
      ciudad: null,
      zonaId: Zona,
      apellido: Apellido,
      telefono: Telefono,
      direccion: Direccion,
      usuarioContacto: Usuario,
      turno: Turno,
      comentario: Comentario,
      lat: Latitud,
      lng: Longitud,
      planId: PlanId,
      EsEmpresa 
    }
    if (validarAggEspera(espera)==true) {
      let result = await postEspera(espera)
      if(result != null){
        if(result.ok){
          successAlert(setAlert,'Espera Agregada');
          recetearEspera()
          setModalAgregarEspera(false)
          filtrarEsperasEstado(1)
        }else{
          warningAlert(setAlert,result.mensaje)
        }
      }else{
        warningAlert(setAlert,'No se pudo agregar la Espera')
      }
      }else{
        warningAlert(setAlert,'Complete todos los campos!')
      }
  }

  const actualizarEspera= async()=>{
    let esperaAux = {}
    Object.assign(esperaAux, EsperaSelec)
    esperaAux.nombre = Nombre;
    esperaAux.email = Email
    esperaAux.apellido = Apellido;
    esperaAux.telefono = Telefono;
    esperaAux.direccion = Direccion;
    esperaAux.formaContacto = Comunico;
    esperaAux.usuarioContacto = Usuario;
    esperaAux.turno = Turno;
    esperaAux.zonaId = Zona;
    esperaAux.lat = Latitud;
    esperaAux.lng = Longitud;
    esperaAux.dni = Dni;
    esperaAux.planId = PlanId;
    esperaAux.comentario = Comentario;
    let valido = await putEditarEspera(esperaAux);
    if(valido){
      successAlert(setAlert,'Espera Actualizada');
      recetearEspera()
      setModalAgregarEspera(false);
      filtrarPorZona(valorZonas)
    }else{
      warningAlert(setAlert,'No se pudo Editar la Espera')
  }
}

  const recetearEspera = () => {
    setNombre("")
    setEmail("")
    setApellido("")
    setTelefono("")
    setDireccion("")
    setComunico("")
    setUsuario("")
    setComentario("")
    setZona(null)
    setTurno("")
    setLatitud("")
    setLongitud("")
    setDni("")
    setPlanId(null)
  }

  const traerZonas = async() => {
    let zns = await getZonas();
    if (zns != null && zns != '') {
        setZonas(zns);
    }
  }

  const traerTiposPlanes = async() => {
    let pln = await getTiposPlanes();
    if (pln != null && pln != '') {
        setPlanes(pln);
    }
}


  const convertDate=(fecha) =>{
    let date = new Date(fecha);
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth()+1).toString();
    var dd  = date.getDate().toString();

    var mmChars = mm.split('');
    var ddChars = dd.split('');

    return yyyy + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + (ddChars[1]?dd:"0"+ddChars[0]);
  }


  const establecerEspera= () => {
    if (Editar) {
      setNombre(EsperaSelec.nombre)
      setEmail(EsperaSelec.email)
      setApellido(EsperaSelec.apellido)
      setTelefono(EsperaSelec.telefono)
      setDireccion(EsperaSelec.direccion)
      setComunico(EsperaSelec.formaContacto)
      setUsuario(EsperaSelec.usuarioContacto)
      setComentario(EsperaSelec.comentario)
      setZona(EsperaSelec.zonaId)
      setLatitud(EsperaSelec.lat)
      setLongitud(EsperaSelec.lng)
      setDni(EsperaSelec.dni)
      setPlanId(EsperaSelec.planId)
      if(EsperaSelec.turno){
        setTurno(convertDate(EsperaSelec.turno))
      }
    }
  }
  const validarAggEspera = (es)=>{

    if(!es.nombre){
      return false;
    }
    if(!es.apellido){
      return false;
    }
    if(!es.email){
      return false;
    }
    if(!es.telefono){
      return false;
    }
    if(!es.direccion){
      return false;
    }
    if(!es.formaContacto){
      return false;
    }
    if(!es.usuarioContacto){
      return false;
    }
    if(!es.comentario){
      return false;
    }
    if(!es.zonaId){
      return false;
    }
    if(!es.lat){
      return false;
    }
    if(!es.lng){
      return false;
    }
    if(!es.dni){
      return false;
    }
    if(!es.turno){
      return false;
    }
    if(!es.planId){
      return false;
    }
    return true;
  }

  useEffect(() => {
  establecerEspera();
  traerZonas();
  traerTiposPlanes()
}, [EsperaSelec])

    return (
       <>
       {Alert}
        <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={ModalAgregarEspera}
            toggle={() => {setModalAgregarEspera(false)}}
          >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-transparent pb-5">

                    <div className="text-center">
                      {Editar?
                      <b>Editar Espera</b>
                      :
                      <b>Agregar Espera</b>
                      }
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => {setModalAgregarEspera(false)}}
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                    </div>
                    <div className="mt-2 mb-3">
                    <Form role="form">
                    <FormGroup className="mb-3" >Nombre:
                        <InputGroup className="input-group-alternative mt-2">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="fas fa-user"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Nombre" type="text"  value={Nombre} onChange={cambiarNombre}/>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3" >Apellido:
                        <InputGroup className="input-group-alternative mt-2">
                          <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-user"></i>
                            </InputGroupText>                                        
                          </InputGroupAddon>
                          <Input placeholder="Apellido" type="text" value={Apellido} onChange={cambiarApellido}/>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3" >DNI:
                        <InputGroup className="input-group-alternative mt-2">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="fas fa-user"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="DNI..." type="number" value={Dni} onChange={cambiarDni}/>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3" >E-mail:
                        <InputGroup className="input-group-alternative mt-2">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="fas fa-at"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="E-mail..." type="email" value={Email} onChange={cambiarEmail}/>
                        </InputGroup>
                      </FormGroup>
                    <FormGroup className="mb-3" >Telefono:
                        <InputGroup className="input-group-alternative mt-2">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="fas fa-phone-alt"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Telefono..." type="number" value={Telefono} onChange={cambiarTelefono}/>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup > Direccion:
                        <InputGroup className="input-group-alternative mt-2">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="fas fa-map-marked-alt"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Direccion" type="text" value={Direccion} onChange={cambiarDireccion}/>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup > Se comunico por:
                        <InputGroup className="input-group-alternative mt-2">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="fas fa-phone-alt"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Se comunico por..." type="text" value={Comunico} onChange={cambiarComunico}/>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup > Usuario:
                        <InputGroup className="input-group-alternative mt-2">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="fas fa-user"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Usuario..." type="text" value={Usuario} onChange={cambiarUsuario}/>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                            <label>
                            Plan a Instalar:
                            </label>
                            <SelectorPlanes Valor={PlanId} setValor={setPlanId} arrayElementos={Planes}/>
                        </FormGroup>
                      <FormGroup > Latitud:
                        <InputGroup className="input-group-alternative mt-2">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="fas fa-map-marked-alt"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Latitud..." type="number" value={Latitud} onChange={cambiarLatitud}/>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup > Longitud:
                        <InputGroup className="input-group-alternative mt-2">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="fas fa-map-marked-alt"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Longitud..." type="number" value={Longitud} onChange={cambiarLongitud}/>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="mb-3"
                          htmlFor="example-date-input"
                          >
                          Turno:
                        </label>
                        <Input
                          id="example-date-input"
                          type="date"
                          value={Turno} onChange={cambiarTurno}
                        />
                      </FormGroup>
                      <FormGroup>
                          <label>Zona:</label>
                          <SelectorZonas Valor={Zona} setValor={setZona} Zonas={Zonas}/>
                      </FormGroup>
                      <FormGroup > Observaciones:
                        <InputGroup className="input-group-alternative mt-2">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="fas fa-comment"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input 
                          placeholder="Observaciones..." 
                          className="form-control-lg"
                          type="text"
                          value={Comentario} onChange={cambiarComentario}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <label className='mr-3' htmlFor="esEmpresa1">Es Empresa</label>
                        <input
                        autoComplete="off"
                        type="checkbox"
                        value={EsEmpresa}
                        onChange={() => setEsEmpresa(!EsEmpresa)}
                        />
                      </FormGroup>
                      <div className="text-center">
                        {Editar?
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={()=>actualizarEspera()}
                        >
                          Editar
                        </Button>
                        :
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={() => AgregarEspera()}
                        >
                          Agregar
                        </Button>
                        }

                    
                        <Button
                          color="secondary"
                          data-dismiss="modal"
                          type="button"
                          onClick={() => {setModalAgregarEspera(false)}}
                        >
                          Cerrar
                        </Button>                    
                      </div>
                    </Form>                     
                    </div>                    
                  </CardHeader>                  
                </Card>
              </div>
        </Modal>
       </>
    )
}


export default ModalAgregarEs
