import React, { useEffect, useState } from 'react'
import { Badge, CardBody, Form, Input, Table } from 'reactstrap'
import { getObras } from 'services/ObrasService';

const EntregarAObra = ({Obra,setObra}) => {

  const [Obras, setObras] = useState([])

  const traerObras = async() => {
      let obras = await getObras();
      if (obras) {
          setObras(obras.obras)
      }
  }

  useEffect(() => {
      traerObras()
  }, [])

  return (
    <>
   
             <CardBody>
            <div className=" mt-5 mb-3">
            <Form role="form">
            <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Seleccione una Obra:
            </label>
            {Obras?
                    <Table className='text-center'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>NOMBRE</th>
                                <th>SELECCIONAR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Obras?
                            Obras.map(o=>{
                                return(
                                    <tr key={o.id}>
                                        <th>{o.id}</th>
                                        <td>{o.nombre}</td>
                                        <td>
                                            <Input
                                                onClick={()=>setObra(o.id)}
                                                className="custom-control-input"
                                                id={`customRadio${o.id}`}
                                                name="customRadio"
                                                type="radio"
                                                />
                                            <label className="custom-control-label" htmlFor={`customRadio${o.id}`}>
                                            </label>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <>
                        </>}
                        </tbody>
                    </Table>
                    :
                    <Badge
                    className='mt-3'
                    color="info"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                    >
                    No hay Obras activas
                    </Badge>
                    }
            </Form>                     
            </div>                    
            </CardBody>
    </>
  )
}

export default EntregarAObra