import LoadingComponent from 'pages/components/Loading'
import React, { useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { getTiposMaterialesAlmacenes } from 'services/TiposMaterialesService'
import BuscadorStock from '../stock/components/BuscadorStock'
import TableAllStores from './components/TableAllStores'
import TableAlmacenes from './components/TableAlmacenes'

const Almacenes = props => {
  const [ModalAggAlmacen, setModalAggAlmacen] = useState(false)
  const [Visible, setVisible] = useState(false)
  const [Materiales, setMateriales] = useState([])

  const traerMateriales = async() => {
    setVisible(true)
    let materiales = await getTiposMaterialesAlmacenes({page:1, limit:10});
    if (materiales !== null) {
        setMateriales(materiales.tma)
    }
    setVisible(false)
}
  return (
    <>
    <LoadingComponent Visible={Visible}/>
    <Card>
        <CardHeader>
            <Row>
              <Col><h1>Almacenes</h1></Col>
              <Col><BuscadorStock setMateriales={setMateriales} getTiposMaterialesAlmacenes={getTiposMaterialesAlmacenes} traerMateriales={traerMateriales} /></Col>
             <Col><Button className='btn-sm btn-outline-success float-right' onClick={()=>setModalAggAlmacen(true)}>Crear Almacen</Button></Col>
            </Row>
        </CardHeader>
        <CardBody>
            <TableAlmacenes
            ModalAggAlmacen={ModalAggAlmacen}
            setModalAggAlmacen={setModalAggAlmacen}
            />
            <hr/>

            {
              Materiales.length?
              <>
              <TableAllStores Materiales={Materiales}/>
              </>
              :
              <></>
            }
        </CardBody>
    </Card>
    </>
  )
}

export default Almacenes