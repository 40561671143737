import React from 'react'
import { Card, CardBody, CardFooter, CardHeader, Modal, Table } from 'reactstrap'


export default function ModalConsultarCierreCaja(props){
    const {ModalCosultarCierre, setModalCosultarCierre, TotalCaja,
        Resumen} = props;
    const obtenerFormatoFecha = ()=>{
        let fecha = new Date();
        let dia = fecha.getDate();
        let mes = fecha.getMonth();
        let ano = fecha.getFullYear();
        let fechaFormateada = `${dia}/${mes+1}/${ano}`;
        return fechaFormateada;
    }
    return(
      <>
        <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={ModalCosultarCierre}
        toggle={() => setModalCosultarCierre(false)}
      >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
                <div className="text-center"><b>Cierre de Caja {obtenerFormatoFecha()}</b>
                    <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalCosultarCierre(false)}
                    >
                    <span aria-hidden={true}>×</span>
                    </button>
                </div>                   
            </CardHeader>    
            <CardBody>
                <Table className="align-items-center" responsive>
                    <thead className="thead-light text-center">
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">Recaudado</th>
                            </tr>
                    </thead>
                    <tbody>
                        
                        {Resumen?                      
                            Resumen.map((rs, index) => {
                            return (
                                <tr key={index}>
                                    <th scope="row">
                                    <div className="text-center">
                                        <span className="mb-0 text-sm">
                                            {rs.usuario}
                                        </span>
                                    </div>
                                    </th>
                                    <td>
                                        <div className="text-center">
                                            <span className="mb-0 text-sm">
                                                {rs.total}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                
                                
                                 )

                            })
                            :
                            <p>Cargando..</p>
                             
                        }
                    </tbody>

                </Table>
            </CardBody>
            <CardFooter>
                <h2>Total : $ {TotalCaja}</h2>
            </CardFooter>                 
        </Card>
      </div>
      </Modal>
      </>
    )
}