import React, { useEffect, useState } from 'react'
import LoadingComponent from 'pages/components/Loading';
import {Button,Card,Container,Navbar} from "reactstrap";
import TableRoles from './components/TableRoles';
import ModalAddRol from './components/ModalAgregarRol';
import { getRoles } from 'services/RolesService';


const RolesComponent=()=> {
    const [ModalAgregarRol, setModalAgregarRol] = useState(false);
    const [Roles, setRoles] = useState([]);
    const [Visible, setVisible] = useState(false);
    const traerRoles=async()=>{
        let roles= await getRoles();
        if (roles !=null && roles !="") {
            setRoles(roles);
        }
    }

    useEffect(() => {
      }, [])
    return (
        <>
            <LoadingComponent Visible={Visible}/>
            <Card style={{width:"100%"}}>
                <Navbar
                    className="navbar-horizontal navbar-light "
                    expand="lg"
                >
                    <Container>
                        <Button className="btn-md btn-outline-success" onClick={()=>setModalAgregarRol(true)}>
                            Agregar Rol
                        </Button>
                    </Container>
                    <ModalAddRol ModalAgregarRol={ModalAgregarRol} setModalAgregarRol={setModalAgregarRol} traerRoles={traerRoles}/>
                </Navbar>
                <TableRoles traerRoles={traerRoles} Roles={Roles}/>
            </Card>
       </>
       )
}

export default RolesComponent
