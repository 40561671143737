import LoadingComponent from 'pages/components/Loading';
import { warningAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row } from 'reactstrap';
import { getAllFromDate } from 'services/DecidirService';
import { getById } from 'services/DecidirService';
import { getIdFromBarcode, buscarSolicitud360 } from 'services/Pagos360Service';
import TablePagos from './components/TablePagos';

const ControlPagos = props => {
  const [Pagos, setPagos] = useState([])
  const [Buscador, setBuscador] = useState("");
  const [Metodo, setMetodo] = useState([]); 
  const [Visible, setVisible] = useState(false);
  const [alertSweet, setAlert] = useState(null);
  const [Desde, setDesde] = useState([]);
  const [Hasta, setHasta] = useState([]);

  const cambiarBuscador = event => {
    setBuscador(event.target.value);
  };
  const cambiarMetodo = event => {
    setMetodo(event.target.value);
  };
  const cambiarDesde = event => {
    setDesde(event.target.value);
  };
  const cambiarHasta = event => {
    setHasta(event.target.value);
  };

  const BuscarSolicitud =()=>{
    
    if (Metodo !== null && Metodo !=='') {
      if (Metodo=="Efectivo") {
        if (Buscador) {
          buscarPorIdPagos360()
        } else {
          buscarSolicitudPagos360()
        }
      } else if (Metodo=="Tarjeta"){
        if (Buscador) {
          buscarPorIdDecidir()
          
        } else {
          buscarSolicitudDecidir()
          
        }
      }
    } else {
      warningAlert(setAlert, "Seleccione una forma de Pago!")
    }
  }

  const trasformSolicitud = (solicitud) => {
    let p = {
      id:solicitud.id,
      date:solicitud.created_at,
      amount:solicitud.first_total,
      request_result:solicitud.request_result,
      barcode:solicitud.barcode,
      gateway:'pagos360'
    }
    return p; 
  }

  const buscarPorIdPagos360 =async ()=>{
    setVisible(true)
    let code = getIdFromBarcode(Buscador);
    if(code){
      let d = await buscarSolicitud360(null, code)
        if(d && d.ok == false){
          warningAlert(setAlert,'No se ha encontrado el pago');
        }
        else{
          setPagos([trasformSolicitud(d)]);
        }
        
    }
    setVisible(false)
  }

  const buscarPorIdDecidir=async()=>{
    setVisible(true)
    let deci = await getById(null, Buscador);
      if(deci && deci.ok == false){
        warningAlert(setAlert, 'Pago no encontrado');
      }
      else{
        setPagos(p=>{
          let aux=[]
          aux.push(...p)
          aux.push(deci.data)
          return aux
        })
      }
      setVisible(false)
  }

  const buscarSolicitudPagos360=async()=>{
    setVisible(true)

  let data = await buscarSolicitud360(Buscador)
    setPagos(p=>{
      let aux=[]
      aux.push(...p)
      aux.push(trasformSolicitud(data))
      return aux
    })
    setVisible(false)
  }

  const buscarSolicitudDecidir=async()=>{
    setVisible(true)
    let limit = 300;
    let data = await getAllFromDate(null, Desde,Hasta,limit);
    setPagos(data.results);
    setVisible(false)
  }

  return (
      <>
      {alertSweet}
      <LoadingComponent Visible={Visible}/>
      <Card>
          <CardHeader>
          <Row>
              <Col>
              <label>Numero de Pago:</label>
              <Input placeholder="Buscador..." type="text"  value={Buscador} onChange={cambiarBuscador}/>
              </Col>
              <Col>
              <label>Desde:</label>
              <Input
              id="example-date-input"
              type="date"
              value={Desde} onChange={cambiarDesde}
            />
              </Col>
              <Col>
              <label>Hasta:</label>
              <Input
              id="example-date-input"
              type="date"
              value={Hasta} onChange={cambiarHasta}
            />
              </Col>
             
          </Row>
          <Row className="mt-3">
              <Col lg={4}>
              <FormGroup>
                      <Input id="exampleFormControlSelect1" 
                          type="select" 
                          value={0}
                          onChange={cambiarMetodo}
                      >
                          <option value={''}>Formas de Pago</option>
                          <option value={"Efectivo"}>Rapipago / Pago Facil</option>
                          <option  value={"Tarjeta"}>Tarjeta</option>
                      </Input>
              </FormGroup>
              </Col>
              <Col lg={2}>
              <Button className="btn-md btn-info" onClick={()=>BuscarSolicitud()}>
                  Buscar
              </Button>
              </Col>
          </Row>

          </CardHeader>
          <CardBody>
              <TablePagos
              Pagos={Pagos}
              />
          </CardBody>
             
      </Card>

      
      </>

  )
}

export default ControlPagos