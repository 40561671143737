import { successAlert } from 'pages/components/SweetAlert';
import { warningAlert } from 'pages/components/SweetAlert';
import { confirmAlert } from 'pages/components/SweetAlert'
import React, { useState } from 'react'
import { Badge, Button } from 'reactstrap'
import { putCambioEstado } from 'services/CambiosServices';
import { putEstadoAsignacion } from 'services/ServiciosServices';
import ModalAsignarServicio from './ModalAsignarServ';



function EtiquetaServAsignado(props){
    const {Asignado, ServiciosClientes, setAlert, traerServiciosClientes} = props
    const [ModalAsignarServ, setModalAsignarServ] = useState(false)

    const ConfirmarCambio=()=>{
        confirmAlert(setAlert, CambiarEstado, null,"Estas seguro que deseas cambiar de estado ?")
    }
    const CambiarEstado=async()=>{
        let cha = await putEstadoAsignacion(ServiciosClientes);
            if (cha != null && cha != '') {
                successAlert(setAlert, "Estado Cambiado!", "")
            }
        }
    
    
    return(
        <>
     
        
        {Asignado?
            <Button   onClick={() => ConfirmarCambio()} className="btn-icon btn-3 btn-sm btn-outline-success  "  type="button" >
               
                <span className="btn-inner--icon">    
                SI
                </span>
                
            </Button>
        :
            <Button  onClick={()=>{setModalAsignarServ(true)}} className="btn-icon btn-3 btn-sm btn-outline-danger "  type="button">
                
                <span className="btn-inner--icon">    
                NO
                </span>
                
            </Button>

            
    }
     <ModalAsignarServicio ModalAsignarServ={ModalAsignarServ}
            setModalAsignarServ={setModalAsignarServ}
            ServiciosClientes={ServiciosClientes}
            Asignado={Asignado}
            traerServiciosClientes={traerServiciosClientes}
        />
        </>
    )
}

export default EtiquetaServAsignado;