import { urlGlobal2 } from './GlobalService';

export const getTiposMateriales = async(params=null) =>{
    let url = new URL(`${urlGlobal2}/tipomateriales`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}

  export const getTiposMaterialesAlmacenes = async(params=null) =>{
    let url = new URL(`${urlGlobal2}/tipomateriales/tipmatalmacenes`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}
export const getTiposMaterialesParaElSelect = async(params=null) =>{
    let url = new URL(`${urlGlobal2}/tipomateriales`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}


export const getTiposMaterialesEmpleados = async(params=null, idEmp) =>{
    let url = new URL(`${urlGlobal2}/tipomateriales/tipmatempleados?idEmpleado=${idEmp}`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}
export const getTiposMaterialesEnEmpleados = async(params=null) =>{
    let url = new URL(`${urlGlobal2}/tipomateriales/tipmatempleados`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}

export const getTiposMaterialesClientes = async(params=null, idCl) =>{
    let url = new URL(`${urlGlobal2}/tipomateriales/tipmatclientes?idCliente=${idCl}`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}

export const getTiposMaterialesObras = async(params=null, idOb) =>{
    let url = new URL(`${urlGlobal2}/tipomateriales/tipmatobras?idObra=${idOb}`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}

export async function postTiposMateriales(ev){
  let url = `${urlGlobal2}/tipomateriales`;
  try {
      let token = localStorage.getItem('token');
      
      let headers = {'Content-Type':'application/json','token':token}
      let method= 'POST';
      let body = JSON.stringify(ev)
      const resp = await fetch(url, {headers, body, method});
      const data = await resp.json()
      return data;
  } catch (error) {
      
      return null;
  }
}
export async function putTipoMateriales(sc){
    let url = `${urlGlobal2}/tipomateriales`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'PUT';
        let body = JSON.stringify(sc)
        const resp = await fetch(url, {headers, body, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }


  export async function deleteTMA(params){
    let url = new URL(`${urlGlobal2}/tipomateriales/borrartma`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json','token':token}
        let method= 'DELETE';
        const resp = await fetch(url, {headers, method});
        const data = await resp.json()
        return data;
    } catch (error) {
        
        return null;
    }
  }