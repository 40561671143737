import React, { useEffect, useState } from "react";
import { Card, Media, Table } from "reactstrap";
import { getEquipos } from "services/EquiposService";


function Equipos(props){
    const {idClient} = props
    const [EquiposClient, setEquipos] = useState([])

    const traerEquiposCliente = async() => {
      let params={clienteId: idClient};
      let equips = await getEquipos(params);
      if (equips != null && equips != '') {
          setEquipos(equips);
      }
    }

    useEffect(() => {
      traerEquiposCliente()
    }, [])
    return (
        <>
          <Card style={{width:"100%"}}>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">MacADDRESS</th>
                  <th scope="col">CATEGORIA</th>
                </tr>
              </thead>
              <tbody>

              {EquiposClient?                      
                EquiposClient.map((eq, index) => {
                  return (
                      <tr key={index}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {eq.macAddress}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>
                        <Media>
                              <span className="mb-0 text-sm">
                                {eq.categoria?.nombre}
                              </span>
                        </Media>
                        </td>
                      </tr>
                      
                      
                    )

                  })
                :
                <p>Cargando..</p>
                } 
              </tbody>
            </Table>
            </Card>
        </>
    )
}


export default Equipos;