import { warningAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react'
import { Button, CardBody, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap'
import {read,utils} from 'xlsx';

const ModalSeleccionarExcel =({setEquipos, ModalExcel, setModalExcel, seleccionarTodos})=> {
    const [equiposTemp,setEquiposTemp] = useState([]);
    const [excelFile, setExcelFile] = useState(null);
    const [Alert, setAlert] = useState(null)

    let modalStyle = {
        display: 'block',
        backgroundColor: 'rgba(0,0,0,0.6)'

    }
    const handleFie = (e) => {
        let selectedFile = e.target.files[0];
        let types = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
        if(selectedFile){
             if(!types.includes(selectedFile.type)) alert('El archivo no es valido'); 
             else{
                 let reader = new FileReader();
                 reader.readAsArrayBuffer(selectedFile);
                 reader.onload = (e) => {
                     setExcelFile(e.target?.result)
                 }
             }
             
     
     
        }else{
            warningAlert(setAlert, "Archivo no selecionado!")
        }
      }
      const confirmar = () => {
        if(equiposTemp.length === 0){
            warningAlert(setAlert, "Seleccione un Archivo!")
            return;
        }
        setEquipos(equiposTemp)
        seleccionarTodos(equiposTemp)
        setModalExcel(false)
    }

    useEffect(() => {
        if(!excelFile) return;
        
        const workShop = read(excelFile, {type: 'buffer'});
        const worksheet = workShop.Sheets[workShop.SheetNames[0]];
        const data = utils.sheet_to_json(worksheet);
        let equipos = [];
        data.forEach((item) => {
            if(item){
                equipos.push(item)
            }
            
        })
        setEquiposTemp(equipos)
    
     }, [excelFile])
    return (
    <>
    {Alert}
     <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={ModalExcel}
                            toggle={() => setModalExcel(false)}
                          >
                          <div className="modal-body p-0">
                              <CardHeader>
                                <div className="text-center"><b>Seleccionar Archivo</b>
                                <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => setModalExcel(false)}
                                  >
                                  <span aria-hidden={true}>×</span>
                                </button>
                                </div>
                              </CardHeader>
                                <CardBody>

                                <Form role="form">
                                  <FormGroup>
                                    <Input type={'file'} onChange={handleFie}/>
                                  </FormGroup>
                              </Form>                          
                              </CardBody>
                                  <div className="text-center">
                                    <Button
                                      className="my-4"
                                      color="primary"
                                      type="button"
                                      onClick={()=>confirmar()}
                                      >
                                      Confirmar
                                    </Button>
                                
                                    <Button
                                      color="secondary"
                                      data-dismiss="modal"
                                      type="button"
                                      onClick={() => { setModalExcel(false)}}
                                    >
                                      Cerrar
                                    </Button>                    
                                  </div>
                                </div>                    
                          </Modal>
    </>
  )
}
export default ModalSeleccionarExcel