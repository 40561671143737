import LoadingComponent from 'pages/components/Loading';
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import { confirmAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { deleteTramite } from 'services/TramitesService';
import { getTramites } from 'services/TramitesService';
import ModalActVencimiento from '../components/ModalActualizarVencimiento';
import ModalAggTramite from '../components/ModalAggTramite';


const PapelesVehiculo = props => {
    const {idVehiculo,setVista, setVisible} = props;
    const [Tramites, setTramites] = useState(null)
    const [ModalActualizarVencimiento, setModalActualizarVencimiento] = useState(false);
    const [ModalAgregarTramite, setModalAgregarTramite] = useState(false);
    const [alertSweet, setAlert] = useState(null); 
    const [TramiteSelec, setTramiteSeleccionado] = useState(null);
    const irVehiculoParticular = (id)=>{
        setVista("Perfil")
      }

    const obtenerFormatoFecha = (fechaIngreso)=>{
        let fecha = new Date(fechaIngreso);
        let dia = fecha.getDate();
        let mes = fecha.getMonth();
        let ano = fecha.getFullYear();
        let fechaFormateada = `${dia}/${mes+1}/${ano}`;
        return fechaFormateada;
    }

    const traerTramites = async() => {
        setVisible(true)
        let params = {vehiculoId:idVehiculo}
        let trmts = await getTramites(params);
        if (trmts != null && trmts != '') {
            setTramites(trmts);
        }
        setVisible(false)
    }
    const confirmarEliminar= async(id)=>{
        confirmAlert(setAlert, eliminarTramite, id , "Deseas eliminar este Tramite?")
      }
      const eliminarTramite = async(id)=>{
        
      let result = await deleteTramite(id)
      if(result != null){
              successAlert(setAlert, "Tramite Eliminado!", "")
              traerTramites();
              
              return
    }else{
        warningAlert(setAlert,'No se pudo eliminar el Tramite')
    }
    };
    const actualizarTramite = (trami)=>{
        setTramiteSeleccionado(trami);
        setModalActualizarVencimiento(true); 
      }
      const download = async e => {
        try {
            let response = await fetch(e.target.href, {
              method: "GET",
              headers: {}
            })
    
            response.arrayBuffer().then(function(buffer) {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "image.png"); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
        } catch (error) {
            warningAlert(setAlert, "Error al descargar archivo")
        }
      };
    useEffect(() => {
        traerTramites();
    }, [])

  return (
   <>
   {alertSweet}
       {Tramites?
       <>
        <Card className="mt-3 ml-3 mr-3">            
            <Row className="ml-3 mt-3 mb-3" style={{height:"100%"}}>
                <Col>
                <Row>
                <Button className="btn-md btn-default ml-2"  onClick={()=>irVehiculoParticular()}>
                <i className="fas fa-arrow-left"></i>
                </Button>
                <h2 className="ml-2 mt-1">Papeles del Vehículo</h2>
                </Row>
                </Col>
                <Col>
                    <Button className="btn-md btn-default mr-3 float-right" onClick={()=>setModalAgregarTramite(true)} >
                        Agregar Tramite
                    </Button>
                </Col>
            </Row>
        </Card>
        <Row className="ml-1 mr-1 mt-3" style={{height:"100%"}}>
            {Tramites.map((t, index) => { 
                return (
                    <Col md="6" key={index}>
                    <Card style={{minHeight:"22rem"}}>
                        <CardHeader>
                            <Row>
                                <Col lg={10}>
                                <h1>{t.tipotramite?.nombre}</h1>
                                </Col>
                                <Col lg={2}>
                                <Button className="btn-icon btn-3 btn-sm btn-danger mr-1"  type="button" onClick={()=>{confirmarEliminar(t.id)}} >
                                <span className="btn-inner--icon">
                                <i className="ni ni-fat-remove"/>
                                </span>
                                </Button>
                                
                                </Col>
                            </Row>
                        </CardHeader>
                            
                        <CardBody>
                            <Row>
                                <Col lg={9}>
                                
                            <Row>
                                <Col>
                                <h2>Inicio:</h2>
                                </Col>
                                <Col>
                                <Row>
                                    <Col>
                                        <h3>
                                            {obtenerFormatoFecha(t.fechaInicio)}
                                        </h3>
                                    </Col>
                                    <Col>
                                    </Col>
                                </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <h2>Vencimiento:</h2>
                                </Col>
                                <Col>
                                <Row>
                                    <Col>
                                        <h3>
                                            {obtenerFormatoFecha(t.fechaVencimiento)}
                                        </h3>
                                    </Col>
                                    <Col>
                                    </Col>
                                </Row>
                                </Col>
                            </Row>
                            
                                
                                </Col>
                                <Col lg={3}>
                                        <Button className="btn-sm btn-success mt-3" onClick={()=>actualizarTramite(t)}>Actualizar</Button>
                                    </Col>
                            </Row>
                            {t.archivo?
                                <Row>
                                    <Col></Col>
                                    <Col>
                                        <div className="text-center mt-4">
                                        <a
                                            href={t.archivo}
                                            download
                                            onClick={e => download(e)}
                                        >
                                            <Button className="btn-md btn-info" >
                                                
                                                <span className="btn-inner--icon">    
                                                <i className="fas fa-download"></i> Descargar Archivo
                                                </span>
                                            </Button>
                                            </a>
                                        </div>
                                    </Col>
                                    <Col></Col>
                                </Row>
                                :
                                <></>
                            }
                        </CardBody>

                    </Card>
                    </Col>
                );
             })}
        </Row>
       </>
       :
       <>Cargando...</>
       }
       {TramiteSelec!=null?
       <ModalActVencimiento 
       ModalActualizarVencimiento={ModalActualizarVencimiento}
       setModalActualizarVencimiento={setModalActualizarVencimiento}
       traerTramites={traerTramites}
       TramiteSelec={TramiteSelec}
       />
       :
        <></>
        }
       <ModalAggTramite
       ModalAgregarTramite={ModalAgregarTramite}
       setModalAgregarTramite={setModalAgregarTramite}
       traerTramites={traerTramites}
       idVehiculo={idVehiculo}
       />
   </>
  )
}

export default PapelesVehiculo