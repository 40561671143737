import React from 'react'
import { Input } from 'reactstrap'

const SelectorEstadoSolicitud = props => {
    const {Valor, setValor} = props;
    
    const cambiarValor = event => {
        setValor(event.target.value);
    };
    
  return (
    <>
    <Input type='select'value={Valor} onChange={cambiarValor} className={'float-right'}>
        <option value={''}>Estado</option>
        <option value={'PENDIENTE'}>PENDIENTE</option>
        <option value={'APLICADA'}>APLICADA</option>
        <option value={'NO PROGRAMADA'}>NO PROGRAMADA</option>
    </Input>
    </>
  )
}
export default SelectorEstadoSolicitud