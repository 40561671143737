import React from 'react'
import { Input } from 'reactstrap'

const SelectorTipoCategoria = ({Tipo, setTipo}) => {

    const cambiarTipo = (e) => {
        setTipo(e.target.value)
    }
  return (
    <>
    <Input type='select' onChange={cambiarTipo} value={Tipo} >
        <option value=''>Tipo</option>
        <option value='INGRESO'>INGRESO</option>
        <option value='EGRESO'>EGRESO</option>
    </Input>
    </>
  )
}

export default SelectorTipoCategoria