import React from "react";
import {
  Button,
  Card,
  CardBody
} from "reactstrap";



function CardBoton(props) {
  const {titulo, accion} = props;

    return (
      <>
              <Card className="card-pricing border-0 text-center mb-4">
                <CardBody className="px-lg-7">
                    <h4 className=" ls-1 text-primary ">
                        {titulo}
                    </h4>
                    <Button className="mb-3" color="primary" type="button" onClick={()=>{accion()}}>
                        Controlar Deposito
                    </Button>
                </CardBody>
              </Card>

      </>
    );
  }
  
  export default CardBoton;