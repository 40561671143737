import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap'
import { putEditarStock } from 'services/AlmacenesService';

const ModalEditStock = ({MaterialSeleccionado, ModalEditarStock, setModalEditarStock, traerMateriales, idAlmacen}) => {
    const [Alert, setAlert] = useState(null);
    const [Cantidad, setCantidad] = useState("");
    const [Descripcion, setDescripcion] = useState("");
    const [Error, setError] = useState({});
    const [Minimo, setMinimo] = useState("");

    const cambiarMinimo = (e)=> {
        setMinimo(e.target.value)
    }

    const validarCantidad = () => {
        if (Cantidad <=0) {
            setError({message : "El stock minimo debe ser mayor a 0!"})
            return false
        }
        return true
    }

    const validarMinimo = () => {
        if (Minimo <=0) {
            setError({message : "El stock minimo debe ser mayor a 0!"})
            return false
        }
        return true
    }

    const validarEditar = (ev)=>{
        if(!ev.cantidad){
          return false;
        }
        if(!ev.stockMinimo){
            return false;
          }
        return true;
      }
    const cambiarCantidad = (e)=> {
        setCantidad(e.target.value)
    }
    const cambiarDescripcion = (e)=> {
        setDescripcion(e.target.value)
    }
    const editar = async()=> {
        let material = {
            tipoMaterialId: parseInt(MaterialSeleccionado.idTipoMaterial),
            cantidad: parseInt(Cantidad),
            almacenId: parseInt(idAlmacen),
            descripcion: Descripcion,
            stockMinimo: parseInt(Minimo)
        }
        if (validarCantidad()===true && validarEditar(material) && validarMinimo()===true) {
        let editar = await putEditarStock(material)
        if (editar) {
            successAlert(setAlert,"Tipo de Material Actualizado!")
            reset()
            traerMateriales()
        }
        } else {
            if (Error.message) {
                warningAlert(setAlert, `${Error.message}`)
            } else {
                warningAlert(setAlert, "Complete todos los campos!")
    
            }
        }
    }
    const reset =()=>{
        setCantidad("");
        setDescripcion("")
        setMinimo("")
        setModalEditarStock(false)
        setError({})
    }

    const establecerTMA=()=>{
        setCantidad(MaterialSeleccionado.cantidad);
        setMinimo(MaterialSeleccionado.stockMinimo);
    }

    useEffect(() => {
        establecerTMA()
    }, [ModalEditarStock])
    

    
  return (
    <>
    {Alert}
        <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={ModalEditarStock}
        toggle={() => reset()}
        >
        <div>
        <Card>
            <CardHeader className="pb-3">
            <div className="text-center"><b>Editar Stock</b>
            <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => reset()}
        >
            <span aria-hidden={true}>×</span>
        </button>
            </div>
            </CardHeader>  
            <CardBody>
            <div className=" mt-5 mb-3">
            <Form role="form">
            <FormGroup>
                <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Stock:
                </label>
                <Input
                    value={Cantidad}
                    type="number"
                    onChange={cambiarCantidad}
                    />
            </FormGroup>
            <FormGroup>
                <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Stock Minimo:
                </label>
                <Input
                    type="number"
                    value={Minimo}
                    onChange={cambiarMinimo}
                    />
            </FormGroup>
            <FormGroup>
                <label
                    className="mb-3"
                    htmlFor="example-date-input"
                    >
                    Descripcion:
                </label>
                <Input
                    type="text"
                    onChange={cambiarDescripcion}
                    />
            </FormGroup>
            </Form>                     
            </div>                    
            </CardBody>
            <CardFooter>
                <div className="text-center">
                <Button
                    className=""
                    color="primary"
                    type="button"
                    onClick={()=>editar()}
                    
                    >
                    Actualizar
                </Button>
            
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => reset()}
                >
                    Cancelar
                </Button>                    
                </div>
                </CardFooter>                
        </Card>
        </div>
        </Modal>
    </>
  )
}

export default ModalEditStock