import React, { useEffect} from 'react'
import {Card, Modal } from 'reactstrap';


const ModalVerFotoComponent =(props)=> {
    const {ModalVerFoto, setModalVerFoto, foto}= props;

    const verFoto = (img) => {
      return img.split(`'`)[1]
    }
    useEffect(() => {

    }, [])

    return (
       <>       
       <Modal
       className="modal-dialog-centered"
       size="lg"
       isOpen={ModalVerFoto}
       toggle={() => {setModalVerFoto(false)}}
       style={{width:"50rem"}}
     >
     <div className="modal-body p-0">
       <Card className="bg-secondary shadow border-0" >
        {foto.includes('<img')?
        <img style={{width:"100%"}} src={verFoto(foto)} alt="not found" />
        : 
            <img style={{width:"100%"}}
                src={foto}
                alt="">
            </img>                
        }
       </Card>
     </div>
     </Modal>
     
               
       </>
    )
}


export default ModalVerFotoComponent
