import { urlGlobal2 } from './GlobalService.js';

export const getAllFromDate = async(params=null,from, to ) =>{
    let url = new URL(`${urlGlobal2}/decidir/pagos?from=${from}&to=${to}`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}
export const getById = async(params=null,id) =>{
    let url = new URL(`${urlGlobal2}/decidir/pagos/${id}`)
    if (params != null) {
        url.search = new URLSearchParams(params).toString();
    }
    try{
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'application/json', token}
        let method = 'GET';
        let resp = await fetch(url,{method, headers});
        let data = await resp.json();
        return data;
    }
    catch(error){
        alert(error.message);
        return null;
    }
}