import SelectorGenerico from 'pages/components/SelectorGenerico';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, CardBody, CardHeader, Form, FormGroup, Input, Modal } from 'reactstrap';
import { getCategoriasCasos } from 'services/CasosServices';
import { postCasos } from 'services/CasosServices';
import SelectorPrioridad from './SelectorPrioridad';
import classnames from "classnames";
import SubirFotoComponent from 'pages/components/SubirFoto';
import { warningAlert } from 'pages/components/SweetAlert';





const ModalAgregarCa=(props)=>{

    const {ModalAgregarCasos, setModalAgregarCasos, filtrarCasosEstado}=props;

    const [enunciado, setEnunciado] = useState("");
    const [clienteId, setClienteId] = useState("");
    const [categoria, setCategoria] = useState("");
    const [prioridad, setPrioridad] = useState("");
    const [radios, setRadios] = useState(null);
    const [CategoriasCasos, SetCategoriasCasos] = useState([]);
    const [alertSweet, setAlert] = useState(null);
    const [foto, setFoto] = useState(null)  

    const traerCategoriasCasos = async() => {
        let can = await getCategoriasCasos();
        if (can !== null && can !== '') {
          SetCategoriasCasos(can);
        }
      }

    const AgregarCasos = async()=>{
    
        let casos = {
          enunciado: enunciado,
          clienteId:clienteId,
          categoriaId: categoria,
          prioridad: prioridad,
          color: radios,
          archivo: foto,
        }
      let result = await postCasos(casos)
      if(result != null){
        setEnunciado("")
        setClienteId("")
        setCategoria("")
        setPrioridad("")
        setRadios("")
        setModalAgregarCasos(false)
  
        successAlert(setAlert, "Caso Agregado!", "")
        setModalAgregarCasos(false)
        filtrarCasosEstado(1)
  
    }else{
      warningAlert(setAlert,'No se pudo agregar caso' )
    }
  }

  const cambiarEnunciado = event => {
    setEnunciado(event.target.value);
  };
  const cambiarClienteId = event => {
    setClienteId(event.target.value);
  };


  useEffect(() => {
    traerCategoriasCasos();
  }, [])

    return(
        <>
        {alertSweet}
        <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={ModalAgregarCasos}
                            toggle={() => setModalAgregarCasos(false)}
                          >
                          <div className="modal-body p-0">
                              <CardHeader>
                                <div className="text-center"><b>Nuevo Caso</b>
                                <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => setModalAgregarCasos(false)}
                                  >
                                  <span aria-hidden={true}>×</span>
                                </button>
                                </div>
                              </CardHeader>
                                <CardBody>

                                <Form role="form">
                                <FormGroup className="mb-3 ">Enunciado:
                                        <Input
                                          value={enunciado} 
                                          onChange={cambiarEnunciado}
                                          className="form-control-lg"
                                          placeholder="Descripcion del caso"
                                          type="textarea"
                                        />
                                  </FormGroup>
                                  <FormGroup > ID del Cliente:
                                      <Input 
                                      value={clienteId} 
                                      onChange={cambiarClienteId}
                                      placeholder="" 
                                      type="number" />
                                  </FormGroup>
                                  <FormGroup>
                                    <label htmlFor="exampleFormControlSelect1" >Categoria:</label>
                                    
                                    <SelectorGenerico Valor={categoria} setValor={setCategoria} arrayElementos={CategoriasCasos}/>
                                       
                                  </FormGroup>
                                  <FormGroup >
                                    <label htmlFor="exampleFormControlSelect1" >Prioridad:</label>
                                    
                                    <SelectorPrioridad Valor={prioridad} setValor={setPrioridad}/>
                                    
                                  </FormGroup>   
                                  <FormGroup className="mb-0">
                                  <label className="form-control-label d-block mb-3">
                                    Identificador del Caso
                                  </label>
                                  <ButtonGroup
                                    className="btn-group-toggle btn-group-colors event-tag"
                                    data-toggle="buttons"
                                  >
                                    <Button
                                      className={classnames("bg-danger", {
                                        active: radios === "bg-danger",
                                      })}
                                      color=""
                                      type="button"
                                      onClick={() => setRadios("bg-danger")}
                                      />
                                        <Button
                                          className={classnames("bg-warning", {
                                            active: radios === "bg-warning",
                                          })}
                                          color=""
                                          type="button"
                                          onClick={() => setRadios("bg-warning")}
                                          />
                                    <Button
                                      className={classnames("bg-yellow", {
                                        active: radios === "bg-yellow",
                                      })}
                                      color=""
                                      type="button"
                                      onClick={() => setRadios("bg-yellow")}
                                      />
                                        <Button
                                          className={classnames("bg-default", {
                                            active: radios === "bg-default",
                                          })}
                                          color=""
                                          type="button"
                                          onClick={() => setRadios("bg-default")}
                                          />
                                        <Button
                                          className={classnames("bg-primary", {
                                            active: radios === "bg-primary",
                                          })}
                                          color=""
                                          type="button"
                                          onClick={() => setRadios("bg-primary")}
                                          />
                                    <Button
                                      className={classnames("bg-info", {
                                        active: radios === "bg-info",
                                      })}
                                      color=""
                                      type="button"
                                      onClick={() => setRadios("bg-info")}
                                      />
                                    <Button
                                      className={classnames("bg-success", {
                                        active: radios === "bg-success",
                                      })}
                                      color=""
                                      type="button"
                                      onClick={() => setRadios("bg-success")}
                                    />
                                  </ButtonGroup>
                                </FormGroup>
                              </Form>                          
                                  <SubirFotoComponent setFoto={setFoto}/>
                              </CardBody>
                                  <div className="text-center">
                                    <Button
                                      className="my-4"
                                      color="primary"
                                      type="button"
                                      onClick={()=>AgregarCasos()}
                                      >
                                      Agregar
                                    </Button>
                                
                                    <Button
                                      color="secondary"
                                      data-dismiss="modal"
                                      type="button"
                                      onClick={() => { setModalAgregarCasos(false)}}
                                    >
                                      Cerrar
                                    </Button>                    
                                  </div>
                                </div>                    
                          </Modal>
        </>
    )
}
export default ModalAgregarCa


