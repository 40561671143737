import LoadingComponent from 'pages/components/Loading';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { Button, Table } from 'reactstrap'
import { getAlmacenes } from 'services/AlmacenesService'
import { getRolPermisos } from 'services/RolService';
import ModalCrearAlmacen from './ModalCrearAlmacen';

const TableAlmacenes = ({ModalAggAlmacen, setModalAggAlmacen}) => {
    const [Almacenes, setAlacenes] = useState([]);
    const [Permisos, setPermisos] = useState([]);
    const [Visible, setVisible] = useState(false)
    const history = useHistory()
    const traerAlmacenes = async() => {
        let alm = await getAlmacenes();
        if (alm) {
            setAlacenes(alm.almacenes)
        }
    }
    // const irAlmacen = (id) => {
    //     history.replace(`/admin/stock/${id}`)
    // }
    const irAlmacen = (id, nom) => {
        history.replace({
           pathname:  `/admin/stock/${id}`,
           state: {
               nombreAlmacen : nom
           }
        }
        )
    }
    const traerPermisos= async()=>{
        setVisible(true)
        let permisos= await getRolPermisos();
        if(permisos !== null && permisos!== ''){
          setPermisos(permisos)
        }
        setVisible(false)
      }
    const tienePermiso=(nombrePermiso)=>{
    return Permisos.includes(nombrePermiso);
    } 
    useEffect(() => {
        traerAlmacenes();
        traerPermisos()
    }, [])
    
  return (
    <>
    <LoadingComponent Visible={Visible}/>
            <Table className='text-center'>
        <thead className='bg-info'>
            <tr>
                <th>id</th>
                <th>NOMBRE</th>
                <th>AGREGAR TIPO MATERIAL</th>
            </tr>
        </thead>
        <tbody>
            {Almacenes?
            Almacenes.map((a)=>{
                return(
                    <>
                    {Permisos && tienePermiso(a.permiso.nombre)?
                    <tr key={a.id}>
                        <th>
                            {a.id}
                        </th>
                        <td>
                            {a.nombre}
                        </td>
                        <td>
                            <Button className=' btn btn-sm btn-outline-success' onClick={()=>irAlmacen(a.id, a.nombre)}>
                                Ver Stock
                            </Button>
                        </td>
                    </tr>
                    :
                    <></>
                    }
                    </>
                )
            })
        :
        <></>}
        </tbody>
    </Table>
    
    <ModalCrearAlmacen
    ModalAggAlmacen={ModalAggAlmacen}
    setModalAggAlmacen={setModalAggAlmacen}
    traerAlmacenes={traerAlmacenes}
    />
    </>
  )
}

export default TableAlmacenes