import { urlGlobal2 } from './GlobalService';
import { validateUser } from '../utils/validation'

// *** LOGIN *** *** LOGIN *** *** LOGIN *** *** LOGIN ***

export async function loginApi(user){
    let token = '';
    let estado = false;
    try{
      let body = JSON.stringify(user);
      let headers = {'Content-Type':'application/json'};
      let url = `${urlGlobal2}/auth/login`
      const resp = await fetch(url,{headers,body,method:'POST'});
      const data = await resp.json();
      token = data.token;
      estado = validateUser(token);
      if(estado)localStorage.setItem('token',token);
    }
    catch(error) {
      alert(error)
    }
    return estado;
  }




export async function verificarToken(tk){
  let estado = false;
  estado = validateUser(tk);
  if(estado){
    try {
      const resp = await fetch(`${urlGlobal2}/auth/verificarToken`, {
      method: 'GET',
      headers: {
        'Content-Type':'application/json',
        'token':tk
      }
      });
      const data = await resp.json();
      estado = data.ok
      
    } catch (error) {
      alert()
    }
    
  }
  
  return estado;
}


export const GetAuthUser = async() => {
  try {
    let url = new URL(`${urlGlobal2}/auth/verificarToken`)
    
    let token = localStorage.getItem('token');
    let method = 'GET';
    let headers = {'Content-Type':'application/json','token':token};
   
    const resp = await fetch(url, {method,headers});
    const data = await resp.json();
    return data;    
  } catch (error) {
    alert(error)
  }
}



  export async function postLogOut(){
    try {
      let url = new URL(`${urlGlobal2}/auth/logout`)
      let token = localStorage.getItem('token');
      let method = 'POST';
      let headers = {'Content-Type':'application/json','token':token};
      const resp = await fetch(url, {method,headers});
      const data = await resp.json();
      localStorage.removeItem('token');
      return data;    
    } catch (error) {
      alert(error)
      localStorage.removeItem('token');
      return null
    }
  }
