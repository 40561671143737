import React, { useEffect, useState } from "react";
// import Imagen from 'assets/img/logoGlobalNet.png';
// import Imagen2 from 'assets/img/logoGlobalNet2.png';
//import Imagen1 from 'assets/img/logoGlobalNet4.png';
// import { Picture } from 'react-responsive-picture';
import '../styles/FacturaComponentStyles.css'
import { Card, CardBody, CardHeader, CardImg, Col, Container, Row, Table } from "reactstrap";


const FacturaComponent = (props)=> {
  const {Cae, Factura, Tipo, Datos, FechaFacturacion}= props;
  const tamanio= 10;
  const tamanioTotales = 10
  const countTamanioTotales =10
  const [subTotal, setSubTotal] = useState(0);

  const obtenerFormatoFecha = (date)=>{
    let fechaFormateada = "-"
    if(date){
      let fecha = new Date(date);
      let dia = fecha.getDate();
      let mes = fecha.getMonth();
      let ano = fecha.getFullYear();
      fechaFormateada = `${dia}/${mes+1}/${ano}`;
    }
    return fechaFormateada;
}

  const obtenerSubTotal = ()=>{
    let subT = 0
    Factura?.items?.forEach(i => {
      subT = subT + i.total
    });
    setSubTotal(subT);
    return subT
  }
  
 
  
  useEffect(() => {
    obtenerSubTotal()

  },[]);
  
  
  return (
    <>
    <Container  style={{paddingLeft: 6, paddingRight: 6}}>
        <Row>
         <Col style={{padding: 0}} xs={tamanio} sm={6}>
         <Card className="facturaHead"
          style={{
          border: "2px solid #000",
          borderRadius: "0px",
          margin:0,
          height: "8rem"
          }}>
            <Container className=" mr-3 mb-3">
              <CardImg
              alt="..."
              src={require("assets/img/logo-Global-Net1.png").default}
              top
            />
            </Container>
            
            </Card>
          </Col>       
          <Col  style={{padding: 0}} xs={tamanio} sm={6}>
          <Card
          style={{
            border: "2px solid #000",
            borderRadius: "0px",
            margin:0,
            height: "8rem"
            }}>
                  <h1 className="mt-3">
                    {Cae
                      ?
                      (`Factura ${Tipo}`)
                      :
                      "Orden de Servicio"
                    }
                  </h1>
          </Card>
          </Col>
        </Row>
        <Row>
          <Col  style={{padding: 0}} xs={tamanio} sm={6}>
            <Card style={{
            border: "2px solid #000",
            borderRadius: "0px",
            margin:0,
            height: "100%"
            }}>
              <Container className="ml-3 mt-3">
                <Row xs={12} sm={12}>
                    <h3>
                    {Cae?
                    "Global Radio S.A."
                    :
                    "Global Net"}
                    </h3>                          
                </Row>
                <Row  xs={12} sm={12}>
                    <h3>
                    {Cae?
                    "Condicion Frente al IVA: Responsable Inscripto"
                    :
                    "Independencia 936"}
                    </h3>                          
                </Row>
              </Container>
            </Card> 
          </Col>
           <Col  style={{padding: 0}} xs={tamanio} sm={6}>
            <Card  style={{
              border: "2px solid #000",
              borderRadius: "0px",
              margin:0,
              height: "9rem"}}>
            <Container className="ml-3 mt-3">
                    <Row  xs={12} sm={12}>
                    <h3>
                        Comp. Nro: {Factura.number}
                    </h3>                          
                    </Row>
                    <Row xs={12} sm={12}>
                    <h3>
                       {FechaFacturacion?`Fecha de Facturación: ${FechaFacturacion}`:`Fecha de vencimiento ${obtenerFormatoFecha(Factura.dueDate)}`}
                    </h3>                          
                    </Row>
                    <Row xs={12} sm={12}>
                    {Cae?
                    <h3>
                        CUIT: 30-71636210-4
                    </h3>                          
                    
                    :
                    null}
                    </Row>
            </Container>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col   style={{padding: 0}} xs={12} sm={12}>
            <Card style={{
              border: "2px solid #000",
              borderRadius: "0px",
              margin:0,
              }}>
                <Container className="ml-3 mt-3">
                  <Col xs={tamanio} sm={6}>
                  
                  <Row  xs={12} sm={12}>
                        <h3>
                        {Datos.firstName} {Datos.lastName}
                        </h3>                          
                  </Row>
                  <Row xs={12} sm={12}>
                        <h3>
                            {Datos.userIdent}
                        </h3>                          
                  </Row>
                  </Col>
                  <Col xs={tamanio} sm={6}>
                  <Row  xs={12} sm={12}>
                        <h3>
                          {Datos.street1}{Datos.city? ",":null} {Datos.city}
                        </h3>                          
                  </Row>
                  <Row xs={12} sm={12}>
                        <h3>
                        {Tipo?"Condicion Frente al IVA:":null} 
                        {Tipo?
                        (Tipo == "A" ? "Responsable Inscripto":"Consumidor Final")
                        :
                        null
                        }
                        </h3>                          
                  </Row>
                  </Col>
                </Container>
            </Card>
          </Col>
        </Row>
             
            <Row>
            <Col style={{padding: 0}} xs={tamanio} sm={12}>
            <Card style={{
                border: "2px solid #000",
                borderRadius: "0px",
                margin:0,
                }}>
            <Table className="table" style={{minWidth: 700}}>
            <thead className=" table-primary tableHead text-center">
                <tr>
                <th scope="col">ITEM</th>
                <th scope="col" >CANTIDAD</th>
                <th scope="col" align="right">PRECIO UNITARIO</th>
                <th scope="col" align="right">SUBTOTAL</th>
                </tr>
            </thead>
            {Factura.items?
            Factura.items.map((i, index)=>{
              return(

                <tr className="text-center" key={index}>
                      
                      <th scope="row">
                      <div className=" text-center" >
                            <span className="mb-0 text-sm">
                              {i.label}
                            </span>
                        </div>
                      </th>
                      <td>
                      <div className=" text-center">
                            <span className="mb-0 text-sm">
                              {i.quantity}
                            </span>
                      </div>
                      </td>
                      <td>
                      <div className=" text-center">
                            <span className="mb-0 text-sm">
                              $ {i.price}
                            </span>
                      </div>
                      </td>
                      <td>
                      <div className=" text-center">
                            <span className="mb-0 text-sm">
                              $ {i.total}
                            </span>
                      </div>
                      </td>
              </tr>
              )
            })
            :
            <></>
            }
            </Table>
            </Card>
            </Col>
            </Row>
            <Row>
            <Col xs={12} sm={12} style={{marginBottom:0,
              border: "2px solid #000",
              borderRadius: "0px",
              margin:0,
              padding: 0
              }}>
            <Card 
              style={{marginTop:0, paddingTop:0}} 
              >
            <Row>
              <Col>
              </Col>
              <Col lg="8">
              <Row>
              <Col className="col" style={{marginBottom:0, padding: 0}}>
                
                  <Container className="ml-3 mt-3">
              
                   <Row>
                    
                      <Col className="float-left"  style={{padding: 0}} xs={countTamanioTotales} sm={8}>                  
                                <h2>
                                Subtotal:
                                </h2>
                      </Col>
                      <Col className="float-right"  style={{padding: 0}} xs={countTamanioTotales} sm={4}>                     
                                <h3>
                                   $ {subTotal}
                                </h3> 
                      </Col>
                   </Row>
                    
                  </Container>
              </Col>
            </Row>
            <Row>
              <Col  sm={12}  style={{padding: 0}}>
                  <Container className="ml-3 mt-3">
                    <Row>
                      <Col xs={tamanioTotales} sm={8} style={{padding: 0}}>
                                  <h1>Total:</h1> 
                      </Col>
                      <Col  xs={tamanioTotales} sm={4}  style={{padding: 0}}>
                                  <h2>${Factura.total}</h2>  
                      </Col>
                    </Row>
                    
                  </Container>
              </Col>
            </Row>
            </Col>
            </Row>
            </Card>
            </Col>
            </Row>
            
            
              
                 
              
                
                

            {Cae
              ?
              <Row>
              <Col xs={12} sm={12}   style={{padding: 0}}>
              <Card className="facturaHeader" style={{
                border: "2px solid #000",
                borderRadius: "0px",
                margin:0}}>
                  <Container className="ml-1">
                  <Col  xs={tamanio} sm={6}  style={{padding: 0}}>                                             
                                <h3>CAE:</h3> 
                  </Col>
                  <Col xs={tamanio} sm={6}   style={{padding: 0}}>                                                                    
                                <h3>{Cae}</h3>
                  </Col>
                 </Container> 
              </Card>
              </Col>
              </Row>

              :
              null
            }
           
            
            </Container>
      </>
    );
  }



  export default FacturaComponent;
